import { useState, useContext } from 'react'
import {
  getAllReference,
  getProductService,
  updateBulkLink,
  updateLink,
} from '../../../services/productService'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import { Context } from '../../../context/AuthContext'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Tooltip,
} from '@mui/material'
import { styled } from '@mui/system'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import HelpIcon from '@mui/icons-material/Help'
import ViewElearning from '../../ViewElearning'
import { colorTableDefault } from '../../TemplateDefault'
import PermissionComponent from '../../../permissions'

const LinkProduct = () => {
  const token = localStorage.getItem('token')
  const [refSelected, setRefSelected] = useState()
  const [refOptions, setRefOptions] = useState([])
  const [removeVerific, setRemoveVerific] = useState(false)
  const [open, setOpen] = useState(false)
  const [linkEdit, setLinkEdit] = useState()
  const [subCompanyId, setSubCompanyId] = useState('')
  const { subCompany, userRoles, nameUrl } = useContext(Context)
  const [indexEdit, setIndexEdit] = useState()
  const [referenceOptions, setReferenceOptions] = useState([])
  const [initialRef, setInitialRef] = useState()
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const vertical = 'top'
  const horizontal = 'center'
  const [errorReferencia, setErrorReferencia] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: 'white',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      //backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleVerific = async () => {
    if (refOptions) {
      let control = refOptions

      if (!removeVerific) {
        let data = []
        control.forEach((ctrl, index) => {
          ctrl.link = control[0].link
          data.push({
            productId: ctrl.productId,
            link: control[0].link,
            companyId: subCompanyId,
          })
        })
        await updateBulkLink(token, nameUrl, data)
        try {
          console.log('ok')
        } catch (e) {
          console.log(e)
        }
      }
      setRefOptions(control)
    }
    setRemoveVerific(!removeVerific)
  }

  const handleEditLink = async () => {
    let control = refOptions
    if (removeVerific && indexEdit === 0) {
      let data = []
      control.forEach((ctrl, index) => {
        ctrl.link = linkEdit
        data.push({
          productId: refOptions[index].productId,
          link: linkEdit,
          companyId: subCompanyId,
        })
      })
      await updateBulkLink(token, nameUrl, data)
      try {
        console.log('ok')
      } catch (e) {
        console.log(e)
      }
    } else {
      refOptions[indexEdit].link = linkEdit
      const data = {
        productId: refOptions[indexEdit].productId,
        link: linkEdit,
        companyId: subCompanyId,
      }
      await updateLink(token, nameUrl, data)
      try {
        console.log('ok')
      } catch (e) {
        console.log(e)
      }
    }

    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
    setErrorReferencia(false)
  }

  const handleOpen = (index, item) => {
    setIndexEdit(index)
    setLinkEdit(item)
    setOpen(true)
  }

  const getProducts = async e => {
    setSubCompanyId(e.target.value)
    setLoading(true)
    try {
      const response = await getAllReference(token, nameUrl, e.target.value)
      const prodsFinal = []
      response.data.map(produto => {
        if (!produto.referencia) {
          return
        }
        const obj = { value: '', label: '' }
        obj.value = produto.referencia
        obj.label = produto.referencia
        prodsFinal.push(obj)
      })
      setReferenceOptions(prodsFinal)

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const handleGetReference = async e => {
    e.preventDefault()
    //const companyId = subCompanyId
    const item = {
      limit: 50,
      offset: 1,
      Reference: refSelected,
      companyId: subCompanyId,
    }
    setLoading(true)
    try {
      const response = await getProductService(token, nameUrl, item)
      setRefOptions(response.data.result)
      setInitialRef(response.data.result)
      setLoading(false)
      if (response.data.result.length === 0) {
        setErrorReferencia(true)
      }
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const handleCloseSnack = () => {
    setErrorReferencia(false)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={errorReferencia}
          onClose={handleCloseSnack}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Essa referência não tem resultados
          </Alert>
        </Snackbar>
        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Link"
              type="text"
              fullWidth
              variant="standard"
              value={linkEdit}
              onChange={e => setLinkEdit(e.target.value)}
              inputProps={{ style: { fontSize: 17 } }}
              InputLabelProps={{ style: { fontSize: 17 } }}
            />
          </DialogContent>
          <DialogActions>
            <Button style={{ fontSize: '1em' }} onClick={handleClose}>
              Cancelar
            </Button>
            <Button style={{ fontSize: '1em' }} onClick={handleEditLink}>
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
        <ViewElearning pageName={'LinkProducts'} open={viewElearning} setOpen={setViewElearning} />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Redirecionamento por produto</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon onClick={() => setViewElearning(true)} />
          </IconButton>
        </Box>
        <Box component="form" onSubmit={handleGetReference}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
              <Select
                value={subCompanyId}
                label="Selecione a empresa"
                sx={{ width: '100%' }}
                onChange={getProducts}
              >
                <MenuItem disabled selected value="">
                  <em>Empresa</em>
                </MenuItem>
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.companyId} key={index}>
                      {item.nameCompany}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            {/* {referenceOptions && ( */}
            <>
              <Box
                width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
                sx={{ mt: 2 }}
              >
                <InputLabel id="demo-simple-select-label">Referência</InputLabel>
                <Autocomplete
                  disablePortal
                  id="lista-referencias"
                  options={referenceOptions}
                  //sx={{ width: 300 }}
                  renderInput={params => <TextField {...params} label="Referência" />}
                  onChange={(e, v) => {
                    setRefSelected(v.value)
                  }}
                />
              </Box>
            </>
            {/* )} */}
          </Box>
          <Box
            sx={{
              mt: '1rem',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <Button
              variant="contained"
              sx={{ mt: 4 }}
              width={{ xs: '80%', sm: '80%', md: '40%', lg: '40%', xl: '40%' }}
              type="submit"
              disabled={!refSelected}
            >
              Buscar
            </Button>
          </Box>
        </Box>
        {refOptions.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Produto</StyledTableCell>
                  <StyledTableCell align="left">Referencia</StyledTableCell>
                  <StyledTableCell align="left">Tamanho</StyledTableCell>
                  <StyledTableCell align="left">Cor</StyledTableCell>
                  <StyledTableCell align="left">
                    {' '}
                    Link
                    <Checkbox
                      sx={{ marginLeft: '10px', color: 'white' }}
                      checked={removeVerific}
                      onChange={() => handleVerific()}
                      label="Copiar para todos"
                    />
                    <InputLabel sx={{ color: 'white' }}>Copiar para todos</InputLabel>
                  </StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {refOptions
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell align="left">{row.name}</StyledTableCell>
                      <StyledTableCell align="left">{row.referencia}</StyledTableCell>
                      <StyledTableCell align="left">{row.size}</StyledTableCell>
                      <StyledTableCell align="left">{row.color}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row.link ? row.link : 'Insira o link'}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <PermissionComponent role="LinkProductsEDIT">
                          <Tooltip title="Editar" arrow>
                            <IconButton
                              sx={{ cursor: 'pointer' }}
                              onClick={() => handleOpen(index, row.link)}
                            >
                              <ModeEditOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={refOptions.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default LinkProduct
