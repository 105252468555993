import HelpIcon from '@mui/icons-material/Help'
import * as XLSX from 'xlsx'
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { getProductionReportService } from '../../../services/productionReportService'

import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Dialog,
  DialogTitle,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'

//icones react-icons
import { AiOutlineFileExcel } from 'react-icons/ai'
import '../../../assets/global.css'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'

const ProductionReport = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const token = localStorage.getItem('token')
  const [loteReport, setLoteReport] = useState([])
  const [loading, setLoading] = useState(false)

  const [downloadMessageCount, setDownloadMessageCount] = useState('Pronto')
  const [downloadingCount, setDownloadingCount] = useState(false)
  const [progressCount, setProgressCount] = useState(0)

  const [subCompanyId, setSubCompanyId] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [orderStatus, setOrderStatus] = useState('')
  const [qualityStatus, setQualityStatus] = useState('')

  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [viewElearning, setViewElearning] = useState(false)
  const [filterProduct, setFilterProduct] = useState('')
  const [backupData, setBackupData] = useState([])
  const [notSearch, setNotSearch] = useState(false)
  const [result, setResult] = useState([])

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const getReport = async () => {
    setLoading(true)
    const data = {
      SubCompanyId: subCompanyId,
      StartDate: startDate,
      FinishDate: endDate,
      OrderStatus: orderStatus,
      QualityStatus: qualityStatus,
    }
    try {
      const response = await getProductionReportService(token, nameUrl, data)
      const resData = response.data

      setResult(resData)

      if (resData.length === 0) {
        setLoteReport([])
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setLoteReport(resData)
        setBackupData(resData)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const downloadDataCount = async () => {
    setDownloadMessageCount('Inicializando')
    setDownloadingCount(true)
    finishDownloadCount()
  }

  const finishDownloadCount = async () => {
    let result = []
    for (let i = 0; i <= 50; i++) {
      setTimeout(() => {
        setDownloadMessageCount(`Baixando ${i} de ${page + 1}...`)
        setProgressCount((i / 100) * 100)
      }, 1000)
    }
    const data = {
      SubCompanyId: subCompanyId,
      StartDate: startDate,
      FinishDate: endDate,
      OrderStatus: orderStatus,
      QualityStatus: qualityStatus,
    }

    const resp = await getProductionReportService(token, nameUrl, data)
    result = resp.data

    for (let i = 50; i <= 100; i++) {
      setTimeout(() => {
        setDownloadMessageCount(`Baixando ${i} de ${page + 1}...`)
        setProgressCount((i / 100) * 100)
      }, 1000)
    }
    setDownloadMessageCount(`Seu arquivo está pronto.`)
    setDownloadingCount(false)
    const relatorioFinal = []

    result.map(item => {
      relatorioFinal.push({
        'Order Bihands': item.movimentationOrderNumberBihands,
        'Ordem (outro sistema)': item.movimentationOrderNumberOtherSystem,
        'Ordem (interrupção)': item.movimentationOrderNumberOtherSystemBreak,
        'Order Bihands (interrupção)': item.movimentationOrderNumberBihandsBreak,
        'Data Início': item.startDate ? new Date(item.startDate).toLocaleString() : '',
        'Data Término': item.finishDate ? new Date(item.finishDate).toLocaleString() : '',
        'Serial de Início': item.startSerial,
        'Serial de Término': item.finishSerial,
        Ciclo: item.cicle,
        Observação: item.observation,
        Cavidade: item.cavity,
        'Nome Início': item.startName,
        'Nome Término': item.finishName,
        'Nome Produto': item.productName,
        'Nome da Subempresa': item.subCompanyName,
        'Nome do Equipamento': item.equipmentName,
        'Hora de Produção': item.hoursProduction,
        'Processo Sequencial': item.sequenceProcess,
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Estoque atual')
    XLSX.writeFile(workbook, `Estoque atual.xlsx`)
    setProgressCount(0)
  }

  const handleClose = () => {
    setAlerta(false)
  }

  const handleClearFilter = () => {
    setSubCompanyId('') // Limpa o valor selecionado da filial
    setStartDate('') // Limpa a data de criação
    setEndDate('') // Limpa a data final
    setOrderStatus('') // Limpa o status do pedido
    setQualityStatus('') // Limpa o status de qualidade
  }

  useEffect(() => {
    const handleFilter = () => {
      if (filterProduct !== '') {
        setNotSearch(false)
        setLoading(true)
        const newData = backupData
        const filter = newData.filter(data =>
          data.description.toLowerCase().includes(filterProduct.toLowerCase())
        )
        setLoading(false)
        if (filter.length > 0) {
          setLoteReport(filter)
          setPage(0)
        } else {
          setNotSearch(true)
          setLoteReport(backupData)
          setPage(0)
        }
      } else {
        setLoteReport(backupData)
        setPage(0)
      }
    }
    setLoteReport([])
    handleFilter()
  }, [filterProduct])

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={downloadingCount}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessageCount}</DialogTitle>
            <LinearProgressWithLabel value={progressCount} sx={{ width: '90%' }} />
          </Box>
        </Dialog>

        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <ViewElearning pageName={'LoteReport'} open={viewElearning} setOpen={setViewElearning} />
          <Typography variant="h5">Relatório de Produção</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box
          component="form"
          onSubmit={e => {
            e.preventDefault()
            getReport()
          }}
        >
          <Box
            sx={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 2,
              display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' },
            }}
          >
            <Box
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
              sx={{ mt: 2, marginRight: { xs: 0, md: '2%' } }}
            >
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                label="Selecione a filial"
                onChange={e => setSubCompanyId(e.target.value)}
                value={subCompanyId}
                name="companySelect"
                sx={{ width: '100%' }}
              >
                {subCompany &&
                  subCompany.map((item, index) => {
                    return (
                      <MenuItem value={item.subCompanyId} key={index}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
              </Select>
            </Box>

            <Box
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
              sx={{ mt: 2, marginLeft: { xs: 0, md: '1rem' } }}
            >
              <InputLabel id="demo-simple-select-label">Data de criação</InputLabel>
              <TextField
                variant="outlined"
                value={startDate}
                onChange={e => setStartDate(e.target.value)}
                sx={{ width: '100%' }}
                name="createDate"
                type="date"
              />
            </Box>

            <Box
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
              sx={{ mt: 2, marginLeft: { xs: 0, md: '1rem' } }}
            >
              <InputLabel id="demo-simple-select-label">Data final</InputLabel>
              <TextField
                variant="outlined"
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
                sx={{ width: '100%' }}
                name="endDate"
                type="date"
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              mt: 2,
              flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' },
            }}
          >
            <Box
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
              sx={{ mt: 2, marginRight: { xs: 0, md: '2%' } }}
            >
              <InputLabel id="demo-simple-select-label">Status do pedido</InputLabel>
              <Select
                label="Status do Pedido"
                onChange={e => setOrderStatus(e.target.value)}
                value={orderStatus}
                name="orderStatusSelect"
                sx={{ width: '100%' }}
              >
                <MenuItem value={-1}>Rascunho</MenuItem>
                <MenuItem value={3}>Em andamento</MenuItem>
                <MenuItem value={1}>Aberto</MenuItem>
                <MenuItem value={2}>Concluído</MenuItem>
                <MenuItem value={-2}>Cancelado</MenuItem>
                <MenuItem value={-99}>Todos</MenuItem>
              </Select>
            </Box>

            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Status de qualidade</InputLabel>
              <Select
                label="Status de Qualidade"
                onChange={e => setQualityStatus(e.target.value)}
                value={qualityStatus}
                name="qualityStatusSelect"
                sx={{ width: '100%' }}
              >
                <MenuItem value={0}>Aprovar</MenuItem>
                <MenuItem value={1}>NC</MenuItem>
                <MenuItem value={2}>Jogar fora</MenuItem>
                <MenuItem value={3}>Refazer</MenuItem>
                <MenuItem value={-99}>Todos</MenuItem>
              </Select>
            </Box>
          </Box>

          <Button variant="contained" type="submit" sx={{ mt: 2 }}>
            Procurar
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            mb: 2,
          }}
        >
          <Typography variant="h6"></Typography>
          {loteReport.length > 0 && (
            <Button
              variant="contained"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: ' space-between',
                fontSize: '.9em',
                width: '6.5rem',
              }}
              onClick={downloadDataCount}
            >
              Excel <AiOutlineFileExcel size="2em" />
            </Button>
          )}
        </Box>

        <Box
          sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box width={{ xs: '100%', sm: '100%', md: '35%', lg: '35%', xl: '35%' }} sx={{ mt: 2 }}>
            <TextField
              variant="outlined"
              label={!notSearch ? 'Filtrar Produto' : 'Produto Não encontrado'}
              value={filterProduct}
              onChange={values => setFilterProduct(values.target.value)}
              sx={{ width: '100%' }}
              error={notSearch}
              type="text"
            />
          </Box>
          <Button variant="contained" onClick={() => handleClearFilter()} sx={{ mt: 2 }}>
            Limpar filtros
          </Button>
        </Box>
        {loteReport && (
          <>
            <TableContainer component={Paper} sx={{ mt: '2rem' }}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Order Bihands</StyledTableCell>
                    <StyledTableCell align="left">Ordem (outro sistema)</StyledTableCell>
                    <StyledTableCell align="left">Ordem (interrupção)</StyledTableCell>
                    <StyledTableCell align="left">Order Bihands (interrupção)</StyledTableCell>
                    <StyledTableCell align="left">Data Início</StyledTableCell>
                    <StyledTableCell align="left">Data Término</StyledTableCell>
                    <StyledTableCell align="left">Serial de Início</StyledTableCell>
                    <StyledTableCell align="left">Serial de Término</StyledTableCell>
                    <StyledTableCell align="left">Ciclo</StyledTableCell>
                    <StyledTableCell align="left">Observação</StyledTableCell>
                    <StyledTableCell align="left">Cavidade</StyledTableCell>
                    <StyledTableCell align="left">Nome Início</StyledTableCell>
                    <StyledTableCell align="left">Nome Término</StyledTableCell>
                    <StyledTableCell align="left">Nome Produto</StyledTableCell>
                    <StyledTableCell align="left">Nome da Subempresa</StyledTableCell>
                    <StyledTableCell align="left">Nome do Equipamento</StyledTableCell>
                    <StyledTableCell align="left">Hora de Produção</StyledTableCell>
                    <StyledTableCell align="left">Processo Sequencial</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loteReport.length > 0 &&
                    loteReport
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="left">
                            {row.movimentationOrderNumberBihands}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.movimentationOrderNumberOtherSystem}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.movimentationOrderNumberOtherSystemBreak}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.movimentationOrderNumberBihandsBreak}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.startDate ? new Date(row.startDate).toLocaleString() : ''}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.finishDate ? new Date(row.finishDate).toLocaleString() : ''}
                          </StyledTableCell>
                          <StyledTableCell align="left">{row.startSerial}</StyledTableCell>
                          <StyledTableCell align="left">{row.finishSerial}</StyledTableCell>
                          <StyledTableCell align="left">{row.cicle}</StyledTableCell>
                          <StyledTableCell align="left">{row.observation}</StyledTableCell>
                          <StyledTableCell align="left">{row.cavity}</StyledTableCell>
                          <StyledTableCell align="left">{row.startName}</StyledTableCell>
                          <StyledTableCell align="left">{row.finishName}</StyledTableCell>
                          <StyledTableCell align="left">{row.productName}</StyledTableCell>
                          <StyledTableCell align="left">{row.subCompanyName}</StyledTableCell>
                          <StyledTableCell align="left">{row.equipmentName}</StyledTableCell>
                          <StyledTableCell align="left">{row.hoursProduction}</StyledTableCell>
                          <StyledTableCell align="left">{row.sequenceProcess}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={loteReport.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ProductionReport
