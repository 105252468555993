import { useState, useEffect, useContext } from 'react'
import ReactApexChart from 'react-apexcharts'
import { getCompanyService } from '../../../../services/admServices'
import { Box, Button, CircularProgress, Pagination, Stack } from '@mui/material'
import { Context } from '../../../../context/AuthContext'

const ColumnChartBasicClient = ({ tickets, filterDate, loadingError, loading, reloadHandler }) => {
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)
  const [currentPage, setCurrentPage] = useState(1)
  const [companies, setCompanies] = useState([])
  const [chartData, setChartData] = useState({
    series: [{ name: 'Tickets', data: [] }],
    options: {
      tooltip: {
        y: {
          formatter: function (val) {
            return Math.round(val).toString()
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 4,
          borderRadiusApplication: 'end',
          dataLabels: { position: 'top' },
        },
      },
      dataLabels: { enabled: false },
      annotations: { points: [] },
    },
  })

  const handleCompanies = async () => {
    try {
      const response = await getCompanyService(token, nameUrl, 500, 0, 'desc')
      setCompanies(response.data.result)
    } catch (error) {}
  }

  useEffect(() => {
    handleCompanies()
  }, []) // Para buscar as empresas apenas uma vez quando o componente é montado

  useEffect(() => {
    const startIndex = (currentPage - 1) * 10
    const endIndex = startIndex + 10

    const chartSeries = companies
      .map(company => {
        const ticket = tickets.find(ticket => ticket.companyId === company.companyId)
        const count = ticket ? ticket.count : 0

        const companyNameParts = company.name.split(' ')
        let companyName = companyNameParts[0].charAt(0).toUpperCase() + companyNameParts[0].slice(1)
        if (companyNameParts.length > 1) {
          companyName += ` ${
            companyNameParts[1].charAt(0).toUpperCase() + companyNameParts[1].slice(1)
          }`
        }
        return {
          x: companyName,
          y: count,
        }
      })
      .sort((a, b) => b.y - a.y) // Ordena as empresas com base nos valores de count, do maior para o menor

    const paginatedChartSeries = chartSeries.slice(startIndex, endIndex)

    const annotations = paginatedChartSeries.map(dataPoint => ({
      x: dataPoint.y,
      y: dataPoint.x,
      label: {
        borderColor: '#FF4560',
        style: { color: '#fff', background: '#FF4560' },
        text: `${dataPoint.y.toString()}`,
        offsetX: dataPoint.y > 10 ? 15 + (dataPoint.y - 10) * 2 : 15,
        offsetY: 14,
      },
    }))

    setChartData(prevState => ({
      ...prevState,
      series: [{ data: paginatedChartSeries }],
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          categories: paginatedChartSeries.map(item => item.x),
        },
        annotations: { points: annotations },
        yaxis: {
          ...prevState.options.yaxis,
          max: Math.max(...paginatedChartSeries.map(item => item.y)) + 1,
        },
      },
    }))
  }, [currentPage, tickets, filterDate, companies])

  const totalPages = Math.ceil(companies.length / 10)

  const handleChangePage = (event, value) => {
    setCurrentPage(value)
  }

  return (
    <>
      {loadingError ? (
        <Box
          sx={{
            alignItems: 'center',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            minHeight: '400px',
            flexDirection: 'column',
          }}
        >
          <p>Erro ao carregar os dados.</p>
          <Button variant="contained" onClick={reloadHandler}>
            Recarregar
          </Button>
        </Box>
      ) : loading ? (
        <Box
          sx={{
            alignItems: 'center',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            minHeight: '400px',
          }}
        >
          <CircularProgress size={30} />
        </Box>
      ) : (
        <div id="chart">
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            download={false}
          />
          {totalPages > 1 && (
            <Stack direction="row" justifyContent="center" alignItems="center" mt={2}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handleChangePage}
                color="primary"
              />
            </Stack>
          )}
        </div>
      )}
    </>
  )
}

export default ColumnChartBasicClient
