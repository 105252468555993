import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  tableCellClasses,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { useContext, useEffect, useState } from 'react'
import {
  AccessAlarms,
  Api,
  Cancel,
  ChevronLeft,
  ChevronRight,
  CurrencyRuble,
  Delete,
  DeleteForever,
  Done,
  DoneAll,
  Drafts,
  Edit,
  Email,
  ExpandLess,
  ExpandMore,
  Inbox,
  Message,
  ModeEditOutline,
  Person,
  QuestionAnswer,
  ReportProblem,
  StarBorder,
} from '@mui/icons-material'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import { styled, useTheme } from '@mui/material/styles'
import ApexChart from './Components/ColumnChartBasic '
import ColumnChartBasic from './Components/ColumnChartBasic '
import PermissionComponent from '../../../permissions'
import ProductEdit from '../../Edit/ProductEdit'
import ProductCreate from '../../Create/ProductCreate'
import { Context } from '../../../context/AuthContext'
import { AiFillPlusCircle } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import { deleteProductService } from '../../../services/productService'
import { TicketCreate } from '../../Create/TicketCreate'
import { TicketEdit } from '../../Edit/TicketEdit'
import { assignTicketToAttendant, getTicketsAll } from '../../../services/helpdeskService'
import { deepOrange } from '@mui/material/colors'
import { useNavigate } from 'react-router-dom'

const drawerWidth = 240

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
)

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '8px',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export const TicketSuport = () => {
  const token = localStorage.getItem('token')
  const navigate = useNavigate()
  const { userId, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [open, setOpen] = useState(true)
  const theme = useTheme()
  const [openMenu, setOpenMenu] = useState(true)

  const [viewElearning, setViewElearning] = useState(false)
  const [ticket, setTiket] = useState([])
  const [filterRow, setFilterRow] = useState([])
  const [companyId, setCompanyId] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [register, setRegister] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [userIdAssign, setUserIdAssign] = useState('')
  const [ticketId, setTicketId] = useState('')

  const [totalActive, setTotalActive] = useState(0)
  const [totalFila, setTotalFila] = useState(0)
  const [totalCanceled, setTotalCanceled] = useState(0)
  const [totalFinished, setTotalFinished] = useState(0)
  const [totalDelay, setTotalDelay] = useState(0)
  const [totalProgress, setTotalProgress] = useState(0)
  const vertical = 'top'
  const horizontal = 'center'
  const [errorEmpresa, setErrorEmpresa] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteTicket, setDeleteTicket] = useState({ ticketName: '', ticketId: '' })
  const [loading, setLoading] = useState(false)
  const [dataAtual, setDataAtual] = useState(new Date())
  const [openDialog, setoOpenDialog] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState('')

  useEffect(() => {
    const interval = setInterval(() => {
      setDataAtual(new Date())
    }, 60000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  function calcularDiferencaData(dataCriacao) {
    const diferencaEmMilissegundos = dataAtual - new Date(dataCriacao)

    const umMinuto = 60 * 1000
    const umaHora = 60 * umMinuto
    const umDia = 24 * umaHora
    const umaSemana = 7 * umDia
    const umMes = 30 * umDia

    if (diferencaEmMilissegundos < umMinuto) {
      return 'Há menos de 1 minuto'
    } else if (diferencaEmMilissegundos < umaHora) {
      const minutos = Math.floor(diferencaEmMilissegundos / umMinuto)
      return `Há ${minutos} minuto(s) atrás`
    } else if (diferencaEmMilissegundos < umDia) {
      const horas = Math.floor(diferencaEmMilissegundos / umaHora)
      return `Há ${horas} hora(s) atrás`
    } else if (diferencaEmMilissegundos < umaSemana) {
      const dias = Math.floor(diferencaEmMilissegundos / umDia)
      return `Há ${dias} dia(s) atrás`
    } else if (diferencaEmMilissegundos < umMes) {
      const semanas = Math.floor(diferencaEmMilissegundos / umaSemana)
      return `Há ${semanas} semana(s) atrás`
    } else {
      const meses = Math.floor(diferencaEmMilissegundos / umMes)
      return `Há ${meses} mês(meses) atrás`
    }
  }

  const handleClick = status => {
    if (status === 'open') {
      setOpen(!open)
    }
    setSelectedStatus(status)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleClose = () => {
    setRegister(false)
    setEditing(false)
    setDeleting(false)
    setoOpenDialog(false)
  }

  const handleDelete = async () => {
    setLoading(true)
    try {
      await deleteProductService(token, deleteTicket.ticketId)
      setLoading(false)
      setDeleteOpen(false)
      const gambi = { target: { value: companyId } }
      //getAllProducts(gambi)
    } catch (err) {
      setLoading(false)
    }
  }

  const handleGetListTickets = async () => {
    setLoading(true)
    try {
      const response = await getTicketsAll(token, 500, 0, 'desc', '')
      setTiket(response.data.result)
      const fila = response.data.result.filter(item => item.status === 0)
      const canceled = response.data.result.filter(item => item.status === -999)
      const finished = response.data.result.filter(item => item.status === 3)
      const delay = response.data.result.filter(item => item.status === 4)
      const progress = response.data.result.filter(item => item.status === 1)
      const active = response.data.result.filter(
        item => item.status === 1 || item.status === 0 || item.status === 4
      )

      const calculateTimeElapsedLists = (creationDate, priority) => {
        const currentDate = new Date()
        const differenceInMilliseconds = currentDate - new Date(creationDate)
        const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60)

        const overdue =
          (priority === 1 && differenceInHours > 6) ||
          (priority === 2 && differenceInHours > 12) ||
          (priority === 3 && differenceInHours > 24)

        return { timeElapsed: differenceInHours, overdue }
      }

      const overdueTickets = response.data.result.filter(item => {
        const { overdue } = calculateTimeElapsedLists(item.createDate, item.priority)
        return overdue && item.status !== -999 && item.status !== 3
      })

      const inProgressFila = response.data.result.filter(item => {
        const isItemInProgress = item.status === 0
        const { overdue } = calculateTimeElapsedLists(item.createDate, item.priority)
        return isItemInProgress && overdue
      })

      const inProgressOverdue = response.data.result.filter(item => {
        const isItemInProgress = item.status === 1
        const { overdue } = calculateTimeElapsedLists(item.createDate, item.priority)
        return isItemInProgress && overdue
      })

      setTotalFila(fila.length)
      setTotalCanceled(canceled.length)
      setTotalFinished(finished.length)
      setTotalDelay(overdueTickets.length)
      setTotalProgress(progress.length)
      setTotalActive(active.length)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const totalStatus = () => {
    const fila = ticket.filter(item => item.status === 0)
    const canceled = ticket.filter(item => item.status === -999)
    const finished = ticket.filter(item => item.status === 3)
    const delay = ticket.filter(item => item.status === 4)
    const progress = ticket.filter(item => item.status === 1)
    setTotalFila(fila.length)
    setTotalCanceled(canceled.length)
    setTotalFinished(finished.length)
    setTotalDelay(delay.length)
    setTotalProgress(progress.length)
  }

  useEffect(() => {
    //totalStatus()
  }, [totalFila, totalCanceled, totalFinished, totalDelay, totalProgress])

  useEffect(() => {
    handleGetListTickets()
  }, [])

  const handleDialogAssign = (usertId, movimentationOrderTicketId) => {
    console.log(userId, 'userId')
    setoOpenDialog(true)
    setUserIdAssign(usertId)
    setTicketId(movimentationOrderTicketId)
  }

  const handleAssignTicketToAttendant = async () => {
    setLoading(true)
    handleClose()
    const data = {
      userId: userIdAssign,
      movimentationOrderTicketId: ticketId,
    }
    try {
      const response = await assignTicketToAttendant(token, data)
      await handleGetListTickets()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const filter = () => {
    const filtered = ticket.filter(row => {
      const { timeElapsed, overdue } = calculateTimeElapsedList(row.createDate, row.priority)
      return (
        selectedStatus === '' ||
        (selectedStatus === 5 && (row.status === 0 || row.status === 1)) ||
        (selectedStatus === 0 && row.status === 0) ||
        (selectedStatus === 1 && row.status === 1) ||
        (selectedStatus === 3 && row.status === 3) ||
        (selectedStatus === -999 && row.status === -999) ||
        (selectedStatus === 4 && overdue && row.status !== -999 && row.status !== 3)
      )
    })

    setFilterRow(filtered)
  }

  useEffect(() => {
    filter()
  }, [ticket, selectedStatus])

  const calculateTimeElapsedList = (creationDate, priority) => {
    const currentDate = new Date()
    const differenceInMilliseconds = currentDate - new Date(creationDate)
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60)

    const overdue =
      (priority === 1 && differenceInHours > 6) ||
      (priority === 2 && differenceInHours > 12) ||
      (priority === 3 && differenceInHours > 24)

    return { timeElapsed: differenceInHours, overdue }
  }

  const calculateTimeElapsed = creationDate => {
    const currentDate = new Date()
    const differenceInMilliseconds = currentDate - new Date(creationDate)
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60)
    return differenceInHours
  }

  const getStatusChip = (status, timeElapsed, priority) => {
    if (!isTicketOverdue(priority, timeElapsed)) {
      if (status === 0) {
        return (
          <Chip
            label="Aguardando atendimento"
            style={{
              background: '#E0E7FF',
              color: '#7A76DD',
              borderRadius: '5px',
            }}
          />
        )
      } else if (status === 1) {
        return (
          <Chip
            label="Em progresso"
            style={{
              background: '#FEF08A',
              color: '#A26308',
              borderRadius: '5px',
            }}
          />
        )
      } else if (status === -999) {
        return (
          <Chip
            label="Cancelado"
            style={{
              background: '#FEE2E2',
              color: '#B91C1C',
              borderRadius: '5px',
            }}
          />
        )
      } else if (status === 3) {
        return (
          <Chip
            label="Finalizado"
            style={{
              background: '#F0FDF4',
              color: '#3EB469',
              borderRadius: '5px',
            }}
          />
        )
      } else if (status === 2) {
        return (
          <Chip
            label="Transferência de atendentes"
            style={{
              background: '#F0FDF4',
              color: '#3EB469',
              borderRadius: '5px',
            }}
          />
        )
      } else if (status === 5) {
        return (
          <Chip
            label="Aguardando retorno do cliente"
            style={{
              background: '#FEF08A',
              color: '#A26308',
              borderRadius: '5px',
            }}
          />
        )
      } else if (status === 4) {
        return (
          <Chip
            label="Aguardando retorno do suporte"
            style={{
              background: '#FEF08A',
              color: '#A26308',
              borderRadius: '5px',
            }}
          />
        )
      }
    } else {
      if (status === -999 && isTicketOverdue(priority, timeElapsed)) {
        return (
          <Chip
            label="Cancelado"
            style={{
              background: '#FEE2E2',
              color: '#B91C1C',
              borderRadius: '5px',
            }}
          />
        )
      } else if (status === 3 && isTicketOverdue(priority, timeElapsed)) {
        return (
          <Chip
            label="Finalizado"
            style={{
              background: '#F0FDF4',
              color: '#3EB469',
              borderRadius: '5px',
            }}
          />
        )
      } else {
        return (
          <Chip
            label="Em atraso"
            style={{
              background: '#FEE2E2',
              color: '#B91C1C',
              borderRadius: '5px',
            }}
          />
        )
      }
    }

    return null
  }

  const isTicketOverdue = (priority, timeElapsed) => {
    return (
      (priority === 1 && timeElapsed > 6) ||
      (priority === 2 && timeElapsed > 12) ||
      (priority === 3 && timeElapsed > 24)
    )
  }

  return (
    <Box sx={{ mt: 7.5 }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={errorEmpresa}
        onClose={handleClose}
        //message="Erro no login, verifique seu e-mail e senha"
        //key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {title}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={register}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {title}
        </Alert>
      </Snackbar>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{ textAlign: 'center' }}
      >
        <DialogTitle>Tem certeza que deseja iniciar este atendimento?</DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="contained" onClick={() => handleAssignTicketToAttendant()}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          width: 1,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h5">Ticket</Typography>
        <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
          <HelpIcon />
        </IconButton>
      </Box>
      <Dialog
        open={deleting}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Tem certeza que deseja excluir esse ticket?
        </DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Não
          </Button>
          <Button onClick={deleteTicket}>Sim</Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2} my={2} sx={{ overflow: 'hidden' }}>
        <Grid item xs={12} sm={12} md={12}>
          <Box display="flex" width="100%" sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
            <Drawer
              variant="permanent"
              open={openMenu}
              sx={{
                width: '100%',
                maxWidth: { xs: '100%', md: '300px' },
                '& .MuiDrawer-paper': {
                  position: `inherit`,
                  maxWidth: '100%',
                  width: '100%',
                },
              }}
            >
              <DrawerHeader>
                <ListSubheader
                  component="div"
                  sx={{ fontSize: '16px', textAlign: 'start' }}
                  id="nested-list-subheader"
                >
                  Todos os Tickets ({ticket.length})
                </ListSubheader>
                <IconButton onClick={() => setOpenMenu(!openMenu)}>
                  {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
                </IconButton>
              </DrawerHeader>
              <Divider />
              <Card sx={{ height: '100%', width: '100%' }}>
                <List
                  sx={{ width: '100%', bgcolor: 'background.paper' }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <ListItemButton onClick={() => handleClick('')}>
                    <ListItemIcon>
                      <CurrencyRuble />
                    </ListItemIcon>
                    <ListItemText primary={`Todos (${ticket.length})`} />
                    {open ? (
                      <IconButton onClick={() => handleClick('open')}>
                        <ExpandLess />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => handleClick('open')}>
                        <ExpandMore />
                      </IconButton>
                    )}
                  </ListItemButton>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton onClick={() => handleClick(5)}>
                        <ListItemIcon>
                          <Done />
                        </ListItemIcon>
                        <ListItemText primary={`Ativos (${totalActive})`} />
                      </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                      <ListItemButton onClick={() => handleClick(0)}>
                        <ListItemIcon>
                          <AccessAlarms />
                        </ListItemIcon>
                        <ListItemText primary={`Aguardando atendimento (${totalFila})`} />
                      </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                      <ListItemButton onClick={() => handleClick(1)}>
                        <ListItemIcon>
                          <QuestionAnswer />
                        </ListItemIcon>
                        <ListItemText primary={`Em progresso (${totalProgress})`} />
                      </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                      <ListItemButton onClick={() => handleClick(4)}>
                        <ListItemIcon>
                          <ReportProblem />
                        </ListItemIcon>
                        <ListItemText primary={`Em atraso (${totalDelay})`} />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  <ListItemButton onClick={() => handleClick(3)}>
                    <ListItemIcon>
                      <DoneAll />
                    </ListItemIcon>
                    <ListItemText primary={`Finalizado (${totalFinished})`} />
                  </ListItemButton>
                  <ListItemButton onClick={() => handleClick(-999)}>
                    <ListItemIcon>
                      <Cancel />
                    </ListItemIcon>
                    <ListItemText primary={`Cancelados (${totalCanceled})`} />
                  </ListItemButton>
                  <Divider sx={{ py: 1 }} />
                  <ListItemButton sx={{ py: 2 }} onClick={() => navigate('/TicketCreate')}>
                    <ListItemIcon>
                      <Email />
                    </ListItemIcon>
                    <ListItemText primary="CRIAR NOVO TICKET" />
                  </ListItemButton>
                </List>
              </Card>
            </Drawer>
            <Box sx={{ flexGrow: 2, maxWidth: '100%', overflowX: 'auto' }} ml={2}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      {/* <StyledTableCell align="left">
                    <Checkbox color="primary" />
                  </StyledTableCell> */}
                      <StyledTableCell align="left">Atribuir</StyledTableCell>
                      <StyledTableCell align="left">Visualizar</StyledTableCell>
                      <StyledTableCell align="left" sx={{ minWidth: '150px' }}>
                        Codigo do Ticket
                      </StyledTableCell>
                      <StyledTableCell align="left" sx={{ minWidth: '300px' }}>
                        Filial
                      </StyledTableCell>
                      <StyledTableCell align="left" sx={{ minWidth: '150px' }}>
                        Usuário
                      </StyledTableCell>
                      <StyledTableCell align="left" sx={{ minWidth: '150px' }}>
                        Abertura
                      </StyledTableCell>
                      <StyledTableCell align="left">Tipo de ticket</StyledTableCell>
                      <StyledTableCell align="left">Prioridade</StyledTableCell>
                      <StyledTableCell align="left">Tempo de espera</StyledTableCell>
                      <StyledTableCell align="left">Status</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filterRow
                      .sort((a, b) => new Date(b.createDate) - new Date(a.createDate))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => (
                        <StyledTableRow key={row.movimentationOrderTicketId}>
                          {/* <StyledTableCell align="left">
                        <Checkbox color="secondary" />
                      </StyledTableCell> */}
                          <StyledTableCell component="th" scope="row">
                            <PermissionComponent role="">
                              <Button
                                variant="text"
                                onClick={() =>
                                  handleDialogAssign(userId, row.movimentationOrderTicketId)
                                }
                                disabled={row.status !== 0}
                              >
                                {row.status === 0 ? (
                                  <Typography variant="body2" fontWeight={700}>
                                    Atribuir ticket
                                  </Typography>
                                ) : (
                                  <Typography variant="body2" fontWeight={700}>
                                    {row.assistentUserName}
                                  </Typography>
                                )}
                              </Button>{' '}
                            </PermissionComponent>
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <PermissionComponent role="">
                              <Button
                                variant="text"
                                onClick={() =>
                                  navigate(`/TicketViewResponse/${row.movimentationOrderTicketId}`)
                                }
                              >
                                <Typography variant="body2" fontWeight={700}>
                                  Visualizar
                                </Typography>
                              </Button>
                            </PermissionComponent>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Typography variant="body2" fontWeight={500}>
                              {row.ticket && '#' + row.ticket}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Box display="flex" alignItems="center">
                              {row.subCompanyName ? row.subCompanyName : ''}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Box display="flex" alignItems="center">
                              {row.createUserName}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <td>{calcularDiferencaData(row.createDate)}</td>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Typography variant="body2">
                              {row.typeTicket === 1
                                ? 'Suporte Equipamento'
                                : row.typeTicket === 2
                                ? 'Suporte Sistemas'
                                : row.typeTicket === 3
                                ? 'Manutenção em Equipamentos'
                                : row.typeTicket === 4 && 'Dúvidas'}
                            </Typography>
                          </StyledTableCell>{' '}
                          <StyledTableCell align="left">
                            <Typography variant="body2">
                              {row.priority === 1
                                ? 'Urgente'
                                : row.priority === 2
                                ? 'Emergencial'
                                : row.priority === 3 && 'Normal'}
                            </Typography>
                          </StyledTableCell>{' '}
                          <StyledTableCell align="left">
                            <Typography variant="body2" fontWeight={700} color="#666">
                              {row.priority === 3
                                ? '24 horas'
                                : row.priority === 2
                                ? '12 horas'
                                : row.priority === 1 && '6 horas'}
                            </Typography>
                          </StyledTableCell>{' '}
                          <StyledTableCell align="left">
                            {getStatusChip(
                              row.status,
                              calculateTimeElapsed(row.createDate),
                              row.priority
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  labelRowsPerPage={'Itens por página'}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  component="div"
                  count={filterRow.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog
        open={deleteOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Apagar Produto?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirme que você quer apagar o ticket "{deleteTicket.productName}"
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleDelete} autoFocus>
            Apagar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
