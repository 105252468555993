/* eslint-disable react-hooks/exhaustive-deps */
import MenuIcon from '@mui/icons-material/Menu'
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import React, { useContext, useEffect, useState } from 'react'

import { Badge, Button, Chip, ListItemAvatar, Menu } from '@mui/material'
import { Context } from '../../context/AuthContext'

import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import LogoutIcon from '@mui/icons-material/Logout'
import { useNavigate } from 'react-router-dom'

import {
  AutoGraph,
  Email,
  FastForwardSharp,
  Help,
  Home,
  ImportContacts,
  LaptopWindows,
  LineWeight,
  ListAlt,
  LocalPrintshop,
  Notifications,
  Padding,
  Person,
  ReadMoreOutlined,
  Settings,
  SettingsSuggestSharp,
  Support,
  VideogameAsset,
} from '@mui/icons-material'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import { useTheme } from '@mui/material/styles'
import PermissionComponent from '../../permissions'
import {
  editVisibiltyMessage,
  getAllSupportUserVisibility,
  getAllVisibility,
  getTickeUser,
} from '../../services/helpdeskService'
import { WidgetTicket } from '../ComponentList/WidgetTicket'
import { WidgetTicketSuport } from '../ComponentList/WidgetTicketSuport'
const drawerWidth = window.screen.width > 786 ? 414 : window.screen.width > 500 ? 350 : 250

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create([
    'margin',
    'width',
    {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    },
  ]),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create([
      'margin',
      'width',
      {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      },
    ]),
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    '&:before': {
      display: 'none',
    },
  })
)

const AccordionSummary = styled(props => <MuiAccordionSummary {...props} />)(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}))

function adjustColor(color, alpha, darkenFactor) {
  const rgb = color.replace('#', '')
  const r = parseInt(rgb.slice(0, 2), 16)
  const g = parseInt(rgb.slice(2, 4), 16)
  const b = parseInt(rgb.slice(4, 6), 16)
  const newR = Math.max(0, r - darkenFactor)
  const newG = Math.max(0, g - darkenFactor)
  const newB = Math.max(0, b - darkenFactor)
  const newColor = `rgba(${newR},${newG},${newB},${alpha})`
  return newColor
}

const menuItems = [
  {
    label: 'Home',
    icon: <Home sx={{ mr: 4 }} />,
    path: '/',
    roles: 'Home',
    subItems: [],
  },

  // {
  //   label: 'Mockup',
  //   icon: <DashboardCustomizeOutlinedIcon sx={{ mr: 4 }} />,
  //   roles: '',
  //   subItems: [
  //     {
  //       label: 'Controle amostra nova',
  //       path: '/NewSampleControl',
  //       roles: 'View_NewSampleControl',
  //     },
  //     {
  //       label: 'Relatório de Controle amostra nova',
  //       path: '/ReportNewSampleControl',
  //       roles: 'ReportNewSampleControl',
  //     },
  //   ],
  // },
  {
    label: 'Dashboard',
    icon: <DashboardCustomizeOutlinedIcon sx={{ mr: 4 }} />,
    roles: '',
    subItems: [
      {
        label: 'Inventário',
        path: '/DasboardIventory',
        roles: 'View_Dahsboard_Inventário',
      },
      {
        label: 'Bobinas',
        path: '/DasboardBobinas',
        roles: 'View_Dahsboard_Bobinas',
      },
    ],
  },
  {
    label: 'Dashboard de Vendas',
    icon: <DashboardCustomizeOutlinedIcon sx={{ mr: 4 }} />,
    roles: '',
    subItems: [
      {
        label: 'Vendas',
        path: '/DashboardSales',
        roles: 'View_DashboardSales',
      },
      {
        label: 'Carga de vendas',
        path: '/ChargerDashboardVendor',
        roles: 'Charge_ChargerDashboardVendor',
      },
      {
        label: 'Carga de clientes',
        path: '/ChargerDashboardClient',
        roles: 'Charge_ChargerDashboardClient',
      },
      {
        label: 'Carga de Roteirização',
        path: '/ChargerDashboardRouting',
        roles: 'Charger_DashboardRouting',
      },
      {
        label: 'Carga de Vendedores',
        path: '/ChargerDashboardSeller',
        roles: 'Charger_DashboardSeller',
      },
      {
        label: 'Carga de Contas a pagar',
        path: '/ChargerDashboardUploadBillstopay',
        roles: 'Charger_UploadBillstopay',
      },
    ],
  },
  {
    label: 'Controle de ativos',
    icon: <VideogameAsset sx={{ mr: 4 }} />,
    roles: '',
    subItems: [
      {
        label: 'Produtos',
        path: '/ProductClient',
        roles: 'View_ProductClient',
      },
      {
        label: 'Contagem de Estoque',
        path: '/CountStockOrderClient',
        roles: 'View_CountStockOrder_ControlActive',
      },

      {
        label: 'Contagem de ativos',
        path: '/ReportCountActive',
        roles: 'View_ReportCountActive',
      },
      {
        label: 'Carga para apagar Produtos',
        path: '/UploadDeleteProducts',
        roles: 'View_UploadDeleteProducts',
      },
      {
        label: 'Carga para cadastrar Produtos',
        path: '/UploadCreateProducts',
        roles: 'View_UploadCreateProducts',
      },
    ],
  },
  {
    label: 'Produtos',
    icon: <Inventory2OutlinedIcon sx={{ mr: 4 }} />,
    roles: '',
    subItems: [
      {
        label: 'Produtos',
        path: '/products',
        roles: 'View_Product',
      },

      {
        label: 'Associação de produtos',
        path: '/ProductAssociation',
        roles: 'View_Association',
      },
      {
        label: 'Auditoria de estoque',
        path: '/StockAudit',
        roles: 'View_StockAudit',
      },
      {
        label: 'Carga de associação de produtos',
        path: '/ChargerAssociationProduct',
        roles: 'View_AssociationBarCodeClient',
      },
      {
        label: 'Carga de RFID',
        path: '/ChargeProducts',
        roles: 'Charge_Products',
      },

      {
        label: 'Carga de associação de produtos',
        path: '/ChargerAssociationProduct',
        roles: 'Charge_Products',
      },
      {
        label: 'Carga de quantidade',
        path: '/UploadQuantity',
        roles: 'Charge_UploadQuantity',
      },
      {
        label: 'Carga de Produtos - New',
        path: '/ChargeProductsNew',
        roles: 'Charge_Products',
      },
      {
        label: 'Redirecionamento por Produto',
        path: '/LinkProducts',
        roles: 'View_LinkProduct',
      },
      {
        label: 'Fator de Conversão',
        path: '/QuantityBox',
        roles: 'View_QuantityBox',
      },
      {
        label: 'Data Validade',
        path: '/TimeExpiration',
        roles: 'View_TimeExpiration',
      },
      {
        label: 'Upload de fotos',
        path: '/uploadImage',
        roles: 'View_Product',
      },
    ],
  },
  {
    label: 'Produção',
    icon: <FastForwardSharp sx={{ mr: 4 }} />,
    roles: '',
    subItems: [
      {
        label: 'Ordem de Produção',
        path: '/MovBreak',
        roles: 'View_MovimentationBreak',
      },
      {
        label: 'Carga de Produção',
        path: '/ChargeProduction',
        roles: 'Charge_Production', //Mudar
      },
      {
        label: 'Kanban',
        path: '/MovBreakKanban',
        roles: 'View_Kanban',
      },
      {
        label: 'Processos',
        path: '/Process',
        roles: 'View_Process',
      },
      {
        label: 'Carga de receita',
        path: '/chargeRecipeMachine',
        roles: 'View_MovimentationBreak',
      },
    ],
  },
  {
    label: 'RTLS',
    icon: <ImportContacts sx={{ mr: 4 }} />,
    roles: '',
    subItems: [
      {
        label: 'Dashboard',
        path: '/DashboardRTLS',
        roles: 'RTLS',
      },
      {
        label: 'Cadastro de pessoa e sensor',
        path: '/SensorAndPerson',
        roles: 'RTLS',
      },
      {
        label: 'Localização do gateway',
        path: '/GatewayLocation',
        roles: 'RTLS',
      },
      {
        label: 'Relatório de localidade',
        path: '/ReportPeople',
        roles: 'RTLS',
      },
    ],
  },
  {
    label: 'Ações de leitura',
    icon: <ReadMoreOutlined sx={{ mr: 4 }} />,
    roles: '',
    subItems: [
      {
        label: 'Associação por codigo de barras',
        path: '/AssociationBarCode',
        roles: 'View_AssociationBarCode',
      },
      {
        label: 'Inventario',
        path: '/InventoryClient',
        roles: 'View_InventoryClient',
      },
      // {
      //   label: 'Upload de fotos',
      //   path: '/uploadFotos',
      //   roles: 'View_Pdv',
      // },
      // {
      //   label: 'Ponto de Venda (PDV)',
      //   path: '/PdvClient',
      //   roles: 'View_Pdv_Client',
      // },
      { label: 'Carga de contagem', path: '/UploadSerial', roles: 'View_UploadsSerial' },
      {
        label: 'Associação por codigo de barras - New',
        path: '/AssociationBarCodeClient',
        roles: 'View_AssociationBarCodeClient',
      },
      {
        label: 'Desassociação',
        path: '/Disassociate',
        roles: 'View_Disassociate',
      },
      {
        label: 'Contagem de Estoque',
        path: '/CountStockDesk',
        roles: 'View_CountStockDesk',
      },
      {
        label: 'Pedido de Venda',
        path: '/NewSaleOrder',
        roles: 'View_CountStockDesk',
      },
      {
        label: 'Leitura online',
        path: '/CountOnline',
        roles: 'View_CountOnline',
      },
      {
        label: 'Mudar quantidade',
        path: '/UpdateQuantity',
        roles: 'View_Update_Quantity',
      },
    ],
  },
  {
    label: 'POC',
    icon: <Padding sx={{ mr: 4 }} />,
    roles: '',
    subItems: [
      {
        label: 'Associação de produtos',
        path: '/Association',
        roles: 'View_AlocationStockPOC',
      },
    ],
  },
  {
    label: 'Movimentações',
    icon: <ListAlt sx={{ mr: 4 }} />,
    roles: '',
    subItems: [
      {
        label: 'Carga de Movimentação',
        path: '/ChargeMovimentationAlocation',
        roles: 'Charge_MovimentationAlocation',
      },
      {
        label: 'Carga de Movimentação',
        path: '/ChargerMovimentation',
        roles: 'View_AssociationBarCodeClient',
      },
      {
        label: 'Transferência entre Filiais',
        path: '/TransferOrder',
        roles: 'View_TransferOrder',
      },
      {
        label: 'Transferência entre Locações',
        path: '/TransferBetweenLocations',
        roles: 'View_TransferBetweenLocations',
      },
      {
        label: 'Expedição',
        path: '/ExpeditionOrder',
        roles: 'View_ExpeditionOrder',
      },
      {
        label: 'Contagem de Estoque',
        path: '/CountStockOrder',
        roles: 'View_CountStockOrder',
      },
      {
        label: 'Contagem de categorias',
        path: '/CountStockCategory',
        roles: 'View_CountStockCategory',
      },
      {
        label: 'Entrada de Estoque',
        path: '/StockEntryOrder',
        roles: 'View_StockEntryOrder',
      },
      {
        label: 'Saída de Estoque',
        path: '/OutStockOrder',
        roles: 'View_OutStockOrder',
      },
      {
        label: 'Vendas',
        path: '/SalesOrder',
        roles: 'Purchase_Reports',
      },
      {
        label: 'Retorno',
        path: '/MovimentationReturn',
        roles: 'View_MovimentationRoute',
      },
      {
        label: 'Impressão de Etiqueta',
        path: '/LabelOrder',
        roles: 'View_LabelOrder',
      },
      {
        label: 'Impressão de Etiqueta',
        path: '/LabelOrderClient',
        roles: 'View_LabelOrder_Client',
      },
      {
        label: 'Conferência de peças',
        path: '/PickToLight',
        roles: 'View_Parts_conference',
      },
      {
        label: 'Lista de Separação',
        path: '/PickingList',
        roles: 'View_PickingList',
      },
      {
        label: 'Rejeito',
        path: '/Rejeito',
        roles: 'View_Rejeito',
      },
    ],
  },
  {
    label: 'Conferência de ativos',
    icon: <AutoGraph sx={{ mr: 4 }} />,
    roles: '',
    subItems: [
      {
        label: 'Relatório de Conferência ',
        path: '/ConferenceClient',
        roles: 'View_CountStockClient',
      },
    ],
  },
  {
    label: 'Relatórios',
    icon: <AutoGraph sx={{ mr: 4 }} />,
    roles: '',
    subItems: [
      {
        label: 'Relatório de contagem WMS',
        path: '/ReportCountStockWms',
        roles: 'View_ReportCountStockWms',
      },
      {
        label: 'Log',
        path: '/LogClient',
        roles: 'View_LogClient',
      },
      {
        label: 'Informações de movimentações de estoque',
        path: '/ReportMovimentationStockInfo',
        roles: 'ReportMovimentationStockInfo',
      },

      {
        label: 'Contagem de Estoque',
        path: '/ReportCountStock',
        roles: 'View_ReportCountStock',
      },

      {
        label: 'Relatório de consolidação de contagem',
        path: '/ReportCountStockConsoled',
        roles: 'View_ReportCountStockConsoled',
      },
      {
        label: 'Relatório de Roteiro',
        path: '/RoadMap',
        roles: 'Roadmap_Report',
      },
      {
        label: 'Relatório de Locação',
        path: '/ReportLocation',
        roles: 'Alocation_Reports',
      },
      {
        label: 'Relatório de Produção',
        path: '/ProductionReport',
        roles: 'Production_Reports',
      },
      {
        label: 'Relatório de locação de peças',
        path: '/ReportAlocationParts',
        roles: 'PartsAlocation_Reports',
      },
      {
        label: 'Histórico de produtos',
        path: '/ReportMovimentationHistoryByProduct',
        roles: 'MovimentationHistoryByProduct_Report',
      },
      {
        label: 'Movimentação de Estoque',
        path: '/ReportInventoryMovement',
        roles: 'InventoryMovement_Reports',
      },
      {
        label: 'Estoque Atual',
        path: '/ReportCurrentInventory',
        roles: 'CurrentInventory_Reports',
      },
      {
        label: 'Carga Estoque Atual',
        path: '/UploadStockActual',
        roles: 'CurrentInventory_Reports',
      },
      {
        label: 'Conferência',
        path: '/ReportConference',
        roles: 'Conference_Reports',
      },
      {
        label: 'Etiqueta Impressa',
        path: '/ReportEtiqueta',
        roles: 'TagsPrint_Reports',
      },
      {
        label: 'Movimentação de Saida (Picking)',
        path: '/ReportPicking',
        roles: 'Picking_Reports',
      },
      {
        label: 'Posições ocupadas',
        path: '/ReportPosition',
        roles: 'Position_Reports',
      },
      {
        label: 'Posições livres',
        path: '/ReportPositionFree',
        roles: 'PositionFree_Reports',
      },
      {
        label: 'Produtos Ativos',
        path: '/ReportProductsActive',
        roles: 'ProductsActive_Reports',
      },
      {
        label: 'Lote por Produto',
        path: '/ReportLoteProduct',
        roles: 'LoteProduct_Reports',
      },
      {
        label: 'Produtos sem Picking',
        path: '/ReportProductPickingNotFound',
        roles: 'ProductPickingNotFound_Reports',
      },
      {
        label: 'Produtos Bloqueados',
        path: '/ReportBlockedProducts',
        roles: 'BlockedProducts_Reports',
      },
      {
        label: 'Movimentação de Produtos em Estoque',
        path: '/ReportMovimentationStockByProduct',
        roles: 'MovimentationStockByProduct_Reports',
      },
      {
        label: 'Vendas',
        path: '/ReportPurchase',
        roles: 'Purchase_Reports',
      },
      {
        label: 'Relatório de Balanço do Estoque',
        path: '/ReportBalanceStock',
        roles: 'InventoryBalance_Reports',
      },
      {
        label: 'Relatório de Contagem',
        path: '/ReportCountStock',
        roles: 'View_ReportCountStock',
      },
      {
        label: 'Anti-Furto',
        path: '/AntiTheftReports',
        roles: 'AnthiTheft_Reports',
      },
      {
        label: 'Anti-Furto de Produtos',
        path: '/AntiTheftReportsProduct',
        roles: 'Reports_AntiTheftReportsProduct',
      },
      {
        label: 'Acesso ao produto',
        path: '/ReportAccess',
        roles: 'ReportAccess_Reports',
      },
      {
        label: 'Dissociação',
        path: '/ReportDissassociation',
        roles: 'Dissassociation_Reports',
      },
      {
        label: 'Locação de Produtos',
        path: '/ReportAlocationParts',
        roles: 'AlocationParts',
      },

      {
        label: 'Lotes',
        path: '/LoteReport',
        roles: 'Lote_Reports',
      },
      {
        label: 'Extrair Estoque em TXT',
        path: '/ReportExtractCountStock',
        roles: 'ExtractCountStock_Reports',
      },
      {
        label: 'Equipamentos Vendidos	',
        path: '/EquipmentSold',
        roles: 'View_EquipmentSold',
      },
    ],
  },
  {
    label: 'Impressora',
    icon: <LocalPrintshop sx={{ mr: 4 }} />,
    roles: '',
    subItems: [
      {
        label: 'Designer ZPL',
        path: '/PrinterZpl',
        roles: 'View_PrinterZpl',
      },
      {
        label: 'ZPL',
        path: '/TemplateLabel',
        roles: 'View_TemplateLabel',
      },
      {
        label: 'Minha Impressora',
        path: '/Printer',
        roles: 'View_Printer',
      },
      {
        label: 'Imprimir etiqueta de Produto',
        path: '/PrintTagSerial',
        roles: 'View_PrintTagSerial',
      },
      {
        label: 'ZPL para Lavanderia',
        path: '/PrinterZPLDefault',
        roles: 'View_PrinterZPLDefault',
      },
      {
        label: 'Etiqueta de Lavagem',
        path: '/PrinterLaundry',
        roles: 'PrinterLaundryView',
      },
      {
        label: 'Etiqueta Logística',
        path: '/TagLogistics',
        roles: 'View_TagLogistics',
      },
      {
        label: 'ZPL Designer',
        path: '/ZPLRef',
        roles: 'View_ZPLRef',
      },
    ],
  },
  {
    label: 'Clientes',
    icon: <Person sx={{ mr: 4 }} />,
    roles: '',
    subItems: [
      {
        label: 'Clientes',
        path: '/Client',
        roles: 'View_Client',
      },
      {
        label: 'Carga de Clientes',
        path: '/ChargeClient',
        roles: 'Charge_Client',
      },
    ],
  },
  {
    label: 'Roteiro de Entrega',
    icon: <LocalShippingIcon sx={{ mr: 4 }} />,
    roles: '',
    subItems: [
      {
        label: 'Remessa',
        path: '/MovimentationRoute',
        roles: 'View_MovimentationRoute',
      },
      {
        label: 'Entrega Motorista',
        path: '/Driver',
        roles: 'View_Driver',
      },
      {
        label: 'Upload Nota Fiscal',
        path: '/NotaFiscal',
        roles: 'Charge_NotaFiscal',
      },
    ],
  },
  {
    label: 'Configurações',
    icon: <Settings sx={{ mr: 4 }} />,
    roles: '',
    subItems: [
      {
        label: 'Fábricas Externas',
        path: '/ReportFaccao',
        roles: 'View_ReportFaccao',
      },
      {
        label: 'Receita de maquina',
        path: '/MachineReceipt',
        roles: 'View_MachineReceipt',
      },
      {
        label: 'Grupos de E-mail',
        path: '/EmailGroup',
        roles: 'View_EmailGroup',
      },
      {
        label: 'Equipamentos',
        path: '/Equipment',
        roles: 'View_Equipment',
      },
      {
        label: 'Filiais',
        path: '/SubCompany',
        roles: 'View_SubCompany',
      },
      {
        label: 'Carga de Filial',
        path: '/UploadSubCompany',
        roles: '',
      },
      {
        label: 'Credenciais de Integrações',
        path: '/IntegrationCredencial',
        roles: 'View_IntegrationCredencial',
      },
      {
        label: 'Associação de Integração',
        path: '/IntegrationAssociation',
        roles: 'View_IntegrationAssociation',
      },
      {
        label: 'Colaboradores',
        path: '/Employee',
        roles: 'View_Employee',
      },
      {
        label: 'Minha Empresa',
        path: '/MyCompany',
        roles: 'View_Company',
      },
    ],
  },
  {
    label: 'Monitoramento',
    icon: <LaptopWindows sx={{ mr: 4 }} />,
    roles: '',
    subItems: [
      {
        label: 'Leitores Automáticos',
        path: '/MonitoringEquipmentAutomatic',
        roles: 'View_MonitoringEquipment',
      },
    ],
  },
  {
    label: 'WMS',
    icon: <LineWeight sx={{ mr: 4 }} />,
    roles: '',
    subItems: [
      {
        label: 'Limite de Peso',
        path: '/CarWeight',
        roles: 'View_CarWeight',
      },

      {
        label: 'Locação de Produtos',
        path: '/AlocationStock',
        roles: 'View_AlocationStock',
      },
      {
        label: 'Carga de Locação de Produtos',
        path: '/UploadAlocationStock',
        roles: 'Charge_AlocationParts',
      },
      {
        label: 'Depósitos',
        path: '/Deposit',
        roles: 'View_Deposit',
      },
      {
        label: 'Carga de Deposito',
        path: '/ChargerDeposit',
        roles: 'View_AssociationBarCodeClient',
      },
      {
        label: 'Bloqueio de Palete/Lote',
        path: '/PalletBlock',
        roles: 'PrinterLaundryView',
      },
      {
        label: 'Baixa Manual de Produtos',
        path: '/ReceiveOrderPicking',
        roles: 'ReceiveOrderPicking_WMS',
      },
      {
        label: 'Entrada Manual de Produtos',
        path: '/ReceiveManualEntry',
        roles: 'ReceiveManualEntry_WMS',
      },
    ],
  },
  {
    label: 'Suporte',
    path: '/Support',
    icon: <Support sx={{ mr: 4 }} />,
    roles: '',
    subItems: [
      {
        label: 'Remover duplicados',
        path: '/DuplicateRemover',
        roles: 'View_DuplicateRemover',
      },
      {
        label: 'Log',
        path: '/Log',
        roles: 'View_Log',
      },
      {
        label: 'Reprocessamento de Tags',
        path: '/TagReprocessing',
        roles: 'View_TagReprocessing',
      },
    ],
  },
  {
    label: 'Helpdesk',
    icon: <Help sx={{ mr: 4 }} />,
    roles: '',
    subItems: [
      {
        label: 'Bihands chat',
        path: '/Gpt',
        roles: 'BihandsChat',
      },
      {
        label: 'Dashboard',
        path: '/Helpdesk',
        roles: 'View_Dahsboard_HelpDesk',
      },
      {
        label: 'Atendentes',
        path: '/Attendants',
        roles: 'View_Attendants',
      },
      {
        label: 'Ticket Suporte',
        path: '/TicketSuport',
        roles: 'View_TicketSuport',
      },
      {
        label: 'Ticket',
        path: '/Ticket',
        roles: 'View_Ticket',
      },
    ],
  },
  {
    label: 'Onboarding',
    icon: <Help sx={{ mr: 4 }} />,
    roles: '',
    subItems: [
      {
        label: 'Pedidos',
        path: '/Order',
        roles: 'View_Order',
      },
      {
        label: 'Integração',
        path: '/Onboarding',
        roles: 'View_Onboarding',
      },
      {
        label: 'Equipamentos',
        path: '/TemplateEquipament',
        roles: 'View_TemplateEquipament',
      },
      {
        label: 'Etiquetas',
        path: '/TemplateTags',
        roles: 'View_TemplateTags',
      },
    ],
  },
  {
    label: 'Configurações do Sistema',
    icon: <SettingsSuggestSharp sx={{ mr: 4 }} />,
    roles: '',
    subItems: [
      {
        label: 'Atualizar Versão',
        path: '/VersionUpdate',
        roles: 'View_Update',
      },
      {
        label: 'Grupos de Parâmetros',
        path: '/ParametersGroup',
        roles: 'View_ParametersGroup',
      },
      {
        label: 'Parâmetros',
        path: '/Parameters',
        roles: 'View_Parameters',
      },
      {
        label: 'Parâmetros de E-mail',
        path: '/EmailParameters',
        roles: 'View_EmailParameters',
      },
      {
        label: 'Integrações Externas',
        path: '/IntegrationDefault',
        roles: 'View_IntegrationDefault',
      },
      {
        label: 'Regras',
        path: '/Role',
        roles: 'View_Role',
      },
      {
        label: 'Segmentos',
        path: '/Segment',
        roles: 'View_Segment',
      },
      {
        label: 'Grupo de Segmentos',
        path: '/SegmentGroup',
        roles: 'View_SegmentGroup',
      },
      {
        label: 'Configurar permissões por Grupo',
        path: '/PermissionsGroup',
        roles: 'View_Permissions_Group',
      },
      {
        label: 'Permissões',
        path: '/Rules',
        roles: 'View_Rules',
      },
      {
        label: 'Categorias de Permissões',
        path: '/RulesCategory',
        roles: 'View_RulesCategory',
      },

      {
        label: 'Configurar permissões do Usuário',
        path: '/PermissionsUser',
        roles: 'View_Permissions_User',
      },

      {
        label: 'Usuários',
        path: '/ListUser',
        roles: 'View_ListUser',
      },
      {
        label: 'Planos',
        path: '/Plan',
        roles: 'View_Plan',
      },
      {
        label: 'Empresas',
        path: '/Company',
        roles: 'View_CompanySystem',
      },
      {
        label: 'Razões para Pedidos',
        path: '/ReasonForOrder',
        roles: 'View_ReasonForOrder',
      },
      {
        label: 'Elearning',
        path: '/Elearning',
        roles: 'View_Elearning',
      },
    ],
  },
  {
    label: 'Sair',
    icon: <LogoutIcon sx={{ mr: 4 }} />,
    roles: 'Logout',
    path: 'sair',
    subItems: [],
  },
]

export default function Header() {
  const theme = useTheme()
  const token = localStorage.getItem('token')
  const [ticketsNotification, setTicketsNotification] = useState([])
  const {
    handleLogout,
    userName,
    colorPrimary,
    colorSecondary,
    urlLogo,
    colorMenu,
    sizeLogoMenu,
    sizeLogoSideMenu,
    fontColorMenuPrimary,
    nameUrl,
    userRolesNew,
    userId,
    subCompany,
  } = useContext(Context)
  const [open, setOpen] = React.useState(false)
  const [expanded, setExpanded] = useState(null)
  const navigate = useNavigate()

  const [selectedItem, setSelectedItem] = useState(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const [anchorEl, setAnchorEl] = React.useState(null)
  const openMenu = Boolean(anchorEl)

  const handleClickMenu = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    const img = new Image()

    img.src = urlLogo

    img.onload = function () {
      const widthOfImage = img.width
      const heightOfImage = img.height

      setDimensions({ width: widthOfImage, height: heightOfImage })
    }
  }, []) // O array vazio [] garante que o useEffect só será executado após a montagem do componente

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    if (open === false) {
      setExpanded()
    }

    setOpen(open)
  }

  const handleClick = item => {
    setOpen(false)
    setExpanded(null)
    navigate(item)
    setSelectedItem(item)
  }

  const handleChange = item => {
    if (expanded === item) {
      setExpanded(null)
    } else {
      setExpanded(item)
    }
  }

  const handleListTicketsAssigned = async userId => {
    try {
      const response = await getTickeUser(token, nameUrl, userId)
    } catch (error) {}
  }

  const menuList = () => {
    return (
      <Box
        sx={{
          backgroundColor: colorMenu,
        }}
      >
        <List sx={{ pt: 0 }}>
          <Divider />
          <>
            {menuItems.map((item, index) => {
              const hasSubMenuRoles = item.subItems.some(subItem => {
                return (
                  subItem.roles &&
                  subItem.roles.split(',').some(role => {
                    return userRolesNew.some(userRole => userRole.rolesName === role.trim())
                  })
                )
              })

              const shouldRenderMainItem = item.path === '/' || item.path === 'sair'

              return (
                <div key={index}>
                  {(hasSubMenuRoles || shouldRenderMainItem) && (
                    <>
                      <Accordion
                        key={item.label}
                        expanded={expanded === item.label}
                        onClick={() => {
                          if (item.path === 'sair') {
                            handleLogout()
                          } else if (item.subItems.length === 0) {
                            handleClick(item.path)
                          }
                        }}
                        onChange={() => {
                          if (item.subItems.length > 0) {
                            handleChange(item.label)
                          }
                        }}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          sx={{
                            backgroundColor:
                              item.subItems.length === 0
                                ? selectedItem === item.path
                                  ? `${adjustColor(colorMenu, 0.4, 30)} !important`
                                  : colorMenu
                                : item.subItems.some(subItem => selectedItem === subItem.path)
                                ? `${adjustColor(colorMenu, 0.4, 30)} !important`
                                : colorMenu,
                            color: fontColorMenuPrimary,
                            transition: 'background-color 0.3s ease, opacity 0.3s ease',
                            '&:hover': {
                              backgroundColor: `${adjustColor(colorMenu, 0.7, 30)} !important`,
                              opacity: 0.7,
                            },
                          }}
                        >
                          {item.icon}
                          <Typography>{item.label}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            p: 0,
                          }}
                        >
                          {item.subItems.map((subItems, indexItem) => (
                            <>
                              <List
                                key={indexItem}
                                sx={{
                                  p: 0,
                                  ...(selectedItem === subItems.path && {
                                    backgroundColor: `${adjustColor(
                                      colorMenu,
                                      0.4,
                                      30
                                    )} !important`,
                                    opacity: 0.7,
                                  }),
                                }}
                              >
                                <>
                                  <PermissionComponent role={subItems.roles}>
                                    <ListItem
                                      disablePadding
                                      sx={{ p: 0, border: '0.1px solid rgba(0, 0, 0, 0.12)' }}
                                    >
                                      <ListItemButton
                                        onClick={() => handleClick(`${subItems.path}`)}
                                        sx={{ py: 1, px: 4 }}
                                      >
                                        <ListItemText primary={subItems.label} />
                                      </ListItemButton>
                                    </ListItem>
                                  </PermissionComponent>
                                </>
                              </List>
                            </>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                      <Divider key={`divider-${index}`} />
                    </>
                  )}
                </div>
              )
            })}
          </>
        </List>
      </Box>
    )
  }

  useEffect(() => {
    if (token) {
      handleGetAllSupportUserVisibility(userId)
    }
  }, [token, navigate, userId])

  const handleGetAllSupportUserVisibility = async userId => {
    try {
      if (userId === '576116ee-2fa9-4350-db6d-08d8e422bc5c') {
        const response = await getAllVisibility(token)

        const filteredData = response.data.result.filter(item =>
          item.movimentationOrderTicketDetailsCollection.some(detail => detail.visibility === false)
        )

        const filtered = response.data.result.filter(item => item.status === 0 || item.status === 4)
        setTicketsNotification(filtered)
      } else {
        const response = await getAllSupportUserVisibility(token, nameUrl, userId)
        const filteredData = response.data.result.filter(item =>
          item.movimentationOrderTicketDetailsCollection.some(detail => detail.visibility === false)
        )
        const filtered = response.data.result.filter(item => item.status === 5)
        setTicketsNotification(filtered)
      }
    } catch (error) {}
  }

  const handleUpdateStatusTicket = async ticket => {
    const data = {
      movimentationOrderTicketDetailsId:
        ticket.movimentationOrderTicketDetailsCollection[0].movimentationOrderTicketDetailsId,
      visibility: true,
    }
    try {
      await editVisibiltyMessage(token, nameUrl, data)
      if (userId === ticket.createUserId) {
        navigate(`/TicketViewReponseClient/${ticket.movimentationOrderTicketId}`)
      } else {
        navigate(`/TicketViewResponse/${ticket.movimentationOrderTicketId}`)
      }
    } catch (error) {}
  }

  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>
      <CssBaseline />
      {subCompany[0].companyId === '23262935-641b-49a5-ae6e-201a3d6a019d' ? (
        <WidgetTicketSuport />
      ) : (
        <WidgetTicket />
      )}
      <Box>
        <Box>
          <AppBar position="fixed" open={open}>
            <Toolbar
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: colorPrimary,
                height: '60px',
                overflow: 'hidden',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <IconButton
                  color={colorSecondary}
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{ mr: 2, ...(open && { display: 'none' }) }}
                >
                  <MenuIcon sx={{ color: colorSecondary }} />
                </IconButton>
                <img
                  src={urlLogo}
                  alt="logo"
                  style={{ width: sizeLogoMenu, display: open ? 'none' : 'block' }}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Chip
                  label={userName}
                  variant="outlined"
                  sx={{
                    color: colorSecondary,
                    borderColor: colorSecondary,
                    display: { xs: 'none', sm: 'flex' },
                  }}
                />
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={() => navigate('/Profile')}
                >
                  <AccountCircle sx={{ color: colorSecondary }} />
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleClickMenu}
                  color="inherit"
                >
                  <Badge badgeContent={ticketsNotification.length} color="error">
                    <Notifications sx={{ fontSize: 32 }} />
                  </Badge>
                </IconButton>
                {ticketsNotification.length > 0 && (
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={openMenu}
                    onClose={handleCloseMenu}
                    onClick={handleCloseMenu}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&::before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                      {ticketsNotification.slice(0, 10).map(ticket => (
                        <>
                          <ListItem
                            onClick={() => handleUpdateStatusTicket(ticket)}
                            alignItems="flex-start"
                            sx={{
                              cursor: 'pointer',
                              '&:hover': {
                                backgroundColor: '#f5f5f5',
                              },
                              backgroundColor: ticket.visibility ? 'inherit' : '#f5f5f5',
                            }}
                          >
                            <ListItemAvatar>
                              <Email />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <React.Fragment>
                                  <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body1"
                                    fontWeight={700}
                                  >
                                    {`Ticket: ${ticket.ticket}`}{' '}
                                  </Typography>
                                </React.Fragment>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    sx={{
                                      display: 'inline',
                                      maxWidth: '100%',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    {`Assunto: ${ticket.subject}`}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <Divider />
                        </>
                      ))}
                    </List>
                    <Button onClick={() => navigate('/Ticket')} sx={{ width: '100%' }}>
                      Ver mais...
                    </Button>
                  </Menu>
                )}

                <IconButton onClick={() => handleLogout()}>
                  <LogoutIcon sx={{ color: colorSecondary }} />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>

          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                backgroundColor: colorMenu,
              },
              backgroundColor: colorMenu,
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
            variant="temporary"
            anchor="left"
            open={open}
            onClose={toggleDrawer(false)}
          >
            <DrawerHeader
              sx={{
                alignItems: 'center',
                justifyContent: 'center',

                p: 3,
                backgroundColor: colorMenu,
              }}
            >
              <img style={{ width: sizeLogoSideMenu }} src={urlLogo} alt="Bihands" />
            </DrawerHeader>
            <Divider />
            {menuList()}
          </Drawer>
        </Box>
      </Box>
    </Box>
  )
}
