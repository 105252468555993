import axios from 'axios'

export const getTicketsAll = async (token, limit, offset, order, filterDate) => {
  const response = await axios.get(
    `/api/MovimentationOrderTicket/GetAllSuporte?limit=${limit}&offset=${offset}&Order=${order}&filterDate=${filterDate}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getAttendantsAll = async token => {
  const response = await axios.get(`/api/Employee/GetRoleUserHelpDesk`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getListTicketsAssigned = async (token, userId) => {
  const response = await axios.get(
    `/api/MovimentationOrderTicket/GetAllSupportUser?UserId=${userId}&limit=500&offset=0&Order=desc`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getListTicketsAssignedFilterDate = async (token, userId, filterDate) => {
  const response = await axios.get(
    `/api/MovimentationOrderTicket/GetAllSupportUser?UserId=${userId}&limit=500&offset=0&Order=desc&filterDate=${filterDate}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getTickeUser = async (token, data) => {
  const response = await axios.get(
    `/api/MovimentationOrderTicket/GetAllUser?SubCompanyId=${data.subCompanyId}&UserId=${data.userId}&limit=500&offset=0&Order=desc`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getAllSupportUserVisibility = async (token, data) => {
  const response = await axios.get(
    `/api/MovimentationOrderTicket/GetAllSupportUserVisibility?UserId=${data}&limit=1000&offset=0&Order=desc`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getAllVisibility = async token => {
  const response = await axios.get(
    `/api/MovimentationOrderTicket/GetAllVisibility?limit=1000&offset=0&Order=desc`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const editVisibiltyMessage = async (token, data) => {
  const response = await axios.post(`/api/MovimentationOrderTicket/EditVisibiltyMessage`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getTickeId = async (token, nameUrl, ticketId) => {
  const response = await axios.get(`http://localhost:3000/tickets/${ticketId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createTicket = async (token, data) => {
  const response = await axios.post(`/api/MovimentationOrderTicket/CreateTicket`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createTemplateEquipament = async (token, nameUrl, form) => {
  const response = await axios.post(`/${nameUrl}/api/TemplateEquipment/Create`, form, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const deleteTemplateEquipament = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/TemplateEquipment/Delete`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const deleteTemplateLabel = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/TemplateLabel/Delete`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getTemplateEquipament = async (token, nameUrl, form) => {
  const response = await axios.get(`/${nameUrl}/api/TemplateEquipment/GetAll`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getTemplateLabel = async (token, nameUrl) => {
  const response = await axios.get(`/${nameUrl}/api/TemplateLabel/GetAll`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createAttendants = async (token, form) => {
  const response = await axios.post(`http://localhost:3000/attendants`, form, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createOrder = async (token, form) => {
  const response = await axios.post(`http://localhost:3000/orders`, form, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getOrders = async (token, form) => {
  const response = await axios.get(`http://localhost:3000/orders`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const assignTicketToAttendant = async (token, data) => {
  const response = await axios.get(
    `/api/MovimentationOrderTicket/Assignment?UserId=${data.userId}&MovimentationOrderTicketId=${data.movimentationOrderTicketId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}
