import { useState, useContext, useEffect, useRef } from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Context } from '../../../context/AuthContext'
import Alert from '@mui/material/Alert'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
  InputBase,
  Chip,
  Autocomplete,
  Collapse,
  Checkbox,
  Divider,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import styled from '@emotion/styled'
import { colorTableDefault } from '../../TemplateDefault'
import {
  Add,
  DeleteForever,
  Edit,
  ExpandMore,
  Replay,
  KeyboardArrowUp,
  KeyboardArrowDown,
  Save,
  Remove,
  TramRounded,
  ModeEditOutline,
} from '@mui/icons-material'
import {
  getClientList,
  getProductList,
  getSingleProductService,
  searchProductService,
} from '../../../services/productService'
import { createTypeOrder } from '../../../services/requestServices'
import * as XLSX from 'xlsx'
import { createAlocationStockService } from '../../../services/alocationServices'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const typeOrderOptions = [
  { id: 0, typeOrderName: 'Entrada' },
  { id: 1, typeOrderName: 'Saída' },
  { id: 2, typeOrderName: 'Fornecedor' },
  { id: 3, typeOrderName: 'Divergência' },
  { id: 4, typeOrderName: 'Expedição' },
  { id: 5, typeOrderName: 'Transferência para Depósito' },
  { id: 6, typeOrderName: 'Contagem de Estoque' },
  { id: 7, typeOrderName: 'Entrada em Estoque' },
  { id: 8, typeOrderName: 'Saída do Estoque' },
  { id: 9, typeOrderName: 'Ambos' },
  { id: 10, typeOrderName: 'Pedido de Etiqueta' },
  { id: 11, typeOrderName: 'Conferência' },
  { id: 12, typeOrderName: 'Transferência de Estoque' },
  { id: 13, typeOrderName: 'Conferência Automática com Leitor' },
  { id: 14, typeOrderName: 'Cancelar Pedido de Devolução' },
  { id: 15, typeOrderName: 'Pedido de Retorno' },
  { id: 16, typeOrderName: 'Vendas' },
  { id: 17, typeOrderName: 'Ordem de Produção' },
  { id: 18, typeOrderName: 'Transferência entre Filial e Depósito' },
  { id: 19, typeOrderName: 'Transferência de Local' },
  { id: 20, typeOrderName: 'Lista de Separação' },
  { id: 21, typeOrderName: 'Rotas' },
  { id: 22, typeOrderName: 'Nota Fiscal' },
  { id: 23, typeOrderName: 'Entrada de Nota Fiscal' },
  { id: 24, typeOrderName: 'Saída de Nota Fiscal' },
]

const UploadAlocationStock = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [subCompanyName, setSubCompanyName] = useState('')
  const [selectedTypeOrder, setSelectedTypeOrder] = useState(0)
  const [companyName, setCompanyName] = useState('')
  const [file, setFile] = useState(null)
  const token = localStorage.getItem('token')
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [titleDialog, setTitleDialog] = useState('')
  const [severity, setSeverity] = useState('error')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [statusMessage, setStatusMessage] = useState({})
  const [loadingMap, setLoadingMap] = useState({})
  const [statusCreate, setStatusCreate] = useState(false)
  const [statusLoadingCreate, setStatusLoadingCreate] = useState(false)

  const { v4: uuidv4 } = require('uuid')

  const [dialogAction, setDialogAction] = useState(null)

  const [singleProd, setSingleProd] = useState({
    id: '',
    subCompanyId: '',
    column: '',
    address: '',
    level: '',
    serial: '',
    street: '',
  })

  const [status, setStatus] = useState('Pronto')
  const [deleteId, setDeleteId] = useState('')
  const [search, setSearch] = useState('')
  const [errors, setErrors] = useState({})
  const [selectedStatus, setSelectedStatus] = useState('')
  const [productUpdateId, setProductUpdateId] = useState('')

  const [edit, setEdit] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)
  const [itensMove, setItensMove] = useState([])
  const [selectedItems, setSelectedItems] = useState([])

  const [filter, setFilter] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [editingItems, setEditingItems] = useState({})
  const [editingIndexProduct, setEditingIndexProduct] = useState(-1)
  const [editingIndexClient, setEditingIndexClient] = useState(-1)
  const [editedValue, setEditedValue] = useState('')
  const [searchProd, setSearchProd] = useState('')

  const [searchingProd, setSearchingProd] = useState(false)
  const [produtos, setProdutos] = useState([])
  const [valueFile, setValueFile] = useState('')
  const ref = useRef()
  const [isEditing, setIsEditing] = useState(false)

  const handleEditItem = id => {
    setEditingItems(prevEditingItems => ({
      ...prevEditingItems,
      [id]: !prevEditingItems[id],
    }))
  }

  const handleDoubleClick = () => {
    setIsEditing(true)
  }

  useEffect(() => {}, [editedValue, editedValue])

  useEffect(() => {
    if (searchProd === '') {
      const checagem = setTimeout(() => {
        setProdutos([{ label: '', value: '' }])
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearchingProd(true)
      const checagem = setTimeout(() => {
        const dados = {
          companyId: subCompany[0].companyId,
          SearchWord: searchProd,
          limit: 10,
          offset: 0,
        }
        searchProductService(token, nameUrl, dados).then(response => {
          const prodsFinal = []
          response.data.result.map(produto => {
            const obj = produto
            obj.value = produto.productId
            obj.label = `${produto.name}`
            prodsFinal.push(obj)
          })
          setProdutos(prodsFinal)
          setSearchingProd(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [searchProd])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setSelectAll(event.target.value === 'All')
  }

  const handleItemSelect = itemId => {
    setSelectedItems(prevSelectedItems => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter(id => id !== itemId)
      } else {
        return [...prevSelectedItems, itemId]
      }
    })
  }

  const handleSelectAll = event => {
    const isChecked = event.target.checked

    setSelectAll(isChecked)
    setSelectedItems(prevSelectedItems => {
      if (!selectAll) {
        return filter.map((item, index) => item.id)
      } else {
        return []
      }
    })

    setRowsPerPage(isChecked ? filter.length + 1 : 10)
  }

  useEffect(() => {
    if (selectedItems.length === filter.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }, [selectedItems, filter])

  useEffect(() => {}, [singleProd])

  const handleWindowClick = () => {
    if (editingIndexProduct !== -1) {
      setEditingIndexProduct(-1)
    }

    if (editingIndexClient !== -1) {
      setEditingIndexClient(-1)
    }
  }

  useEffect(() => {}, [statusMessage])

  const fetchData = async values => {
    setFilter([])
    setItensMove([])
    if (!values.target.value) {
      return
    }
    const getValue = values.target.value
    setSubCompanyId(getValue)
    const subCompanyName = subCompany.find(item => item.subCompanyId === getValue)

    setSubCompanyName(subCompanyName.name)
    const selectedSubCompany = subCompany.find(item => item.subCompanyId === getValue)
    if (selectedSubCompany) {
      setCompanyName(selectedSubCompany.nameCompany)
    }
  }

  const handleSelectFile = e => {
    setLoading(true)
    setItensMove([])
    setValueFile(e.target.name[0])
    const selectedFile = e.target.files[0]

    if (!selectedFile) {
      setFile(null)
      return
    }
    setFile(selectedFile)
    const reader = new FileReader()

    reader.onload = e => {
      const data = e.target.result
      const workbook = XLSX.read(data, { type: 'binary' })
      const firstSheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[firstSheetName]

      const excelData = []
      const range = XLSX.utils.decode_range(worksheet['!ref'])

      for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
        const row = []
        let hasData = false

        for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
          const cellAddress = { c: colNum, r: rowNum }
          const cellRef = XLSX.utils.encode_cell(cellAddress)
          const cell = worksheet[cellRef]

          if (cell && cell.v !== undefined && cell.v !== null) {
            hasData = true
          }

          row.push(cell ? cell.v : undefined)
        }

        if (hasData) {
          excelData.push(row)
        }
      }

      const dataList = excelData.map(item => ({
        id: uuidv4(),
        subCompanyId: subCompanyId,
        nameCompany: subCompanyName,
        address: String(item[0]),
        street: String(item[1]),

        serial: String(item[2]),
        column: String(item[3]),
        level: String(item[4]),
      }))

      getCompareListExcel(dataList)
    }
    reader.readAsArrayBuffer(selectedFile)
  }

  const getCompareListExcel = async dataList => {
    const newDataList = dataList.map(item => {
      const matchingTypeOrder = typeOrderOptions.find(name => name.id === selectedTypeOrder)
      const companyName = matchingTypeOrder ? matchingTypeOrder.subCompanyName : 'Product Not Found'

      return {
        ...item,
        id: uuidv4(),
        subCompanyName: companyName,
      }
    })

    const updatedStatusMessages = {}

    dataList.forEach(item => {
      updatedStatusMessages[item.id] = 'Pronto para enviar'
      setStatusMessage(prevStatusMessages => ({
        ...prevStatusMessages,
        ...updatedStatusMessages,
      }))
    })
    setLoading(false)
    setItensMove(newDataList)
    setValueFile('')
    resetFile()
  }

  const filterRow = () => {
    const filtered = itensMove.filter(row => {
      const lowerSearch = search.toLowerCase()
      const status = statusMessage[row.id]
      return (
        (selectedStatus === '' || status === selectedStatus) &&
        (String(row.address).toLowerCase().includes(lowerSearch) ||
          String(row.street).toLowerCase().includes(lowerSearch) ||
          String(row.level).toLowerCase().includes(lowerSearch) ||
          String(row.column).toLowerCase().includes(lowerSearch) ||
          String(row.serial).toLowerCase().includes(lowerSearch))
      )
    })
    setFilter(filtered)
  }

  useEffect(() => {}, [selectedTypeOrder])
  const resetFile = () => {
    ref.current.value = ''
  }

  useEffect(() => {}, [singleProd])

  const addToList = single => {
    const { id, address, street, level, column, serial } = single

    const updatedItensMove = itensMove.map(item => {
      return {
        ...item,
        subCompanyId: subCompanyId,
        address: address,
        id: id,
        street: street,
        level: level,
        column: column,
        serial: serial,
      }
    })
    console.log(updatedItensMove, 'updatedItensMove')

    setItensMove(updatedItensMove)

    setItensMove(updatedItensMove)
    const repetido = itensMove.find(item => item.id === id)

    if (!repetido) {
      setStatus('Item adicionado')
    } else {
      setStatus('Item atualizado')
    }

    resetFields()
    setExpanded(false)
  }

  const resetFields = () => {
    setEdit(false)
    setSingleProd({
      id: '',
      subCompanyId: '',
      street: '',
      address: '',
      level: '',
      column: '',
      serial: '',
    })
  }

  useEffect(() => {}, [selectedTypeOrder])

  useEffect(() => {}, [itensMove])
  const handleActionList = action => {
    if (selectedItems === 0) {
      setSeverity('error')
      setTitle('Selecione um item')
      setAlerta(true)
    } else {
      setOpen(true)
      setTitleDialog('Tem certeza que enviar esses itens?')
      setDialogAction(action)
    }
  }

  const handleDeleteItem = () => {
    const updatedItems = filter.filter(item => item.productId !== deleteId)
    setSeverity('success')
    setTitle('Item apagado com sucesso!')
    setAlerta(true)
    setItensMove(updatedItems)
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
    setAlerta(false)
  }

  const handleCreateAlocationStockSelected = async () => {
    setStatusCreate(true)
    setStatusLoadingCreate(true)
    const arrayFilter = itensMove.filter(objeto => selectedItems.includes(objeto.id))

    for (const data of arrayFilter) {
      setLoadingMap(prevLoadingMap => ({
        ...prevLoadingMap,
        [data.id]: true,
      }))

      const newData = {
        subCompanyId: data.subCompanyId,
        address: data.address,
        column: data.column,
        level: data.level,
        serial: data.serial,
        street: data.street,
      }

      try {
        const response = await createAlocationStockService(token, nameUrl, newData)
        setLoadingMap(prevLoadingMap => ({
          ...prevLoadingMap,
          [data.id]: false,
        }))
        const updatedStatusMessages = {}
        updatedStatusMessages[data.id] = 'Registro feito com sucesso'
        setStatusMessage(prevStatusMessages => ({
          ...prevStatusMessages,
          ...updatedStatusMessages,
        }))

        const newlySuccessfullySentItems = updatedStatusMessages[data.id]
        console.log(newlySuccessfullySentItems, 'sadsa')

        await new Promise(resolve => setTimeout(resolve, 1000))

        setItensMove(prevItems => prevItems.filter(item => item.id !== data.id))
      } catch (error) {
        console.error(error)
        const updatedStatusMessages = {}
        updatedStatusMessages[data.id] = 'Falha ao enviar'
        setStatusMessage(prevStatusMessages => ({
          ...prevStatusMessages,
          ...updatedStatusMessages,
        }))

        setLoadingMap(prevLoadingMap => ({
          ...prevLoadingMap,
          [data.id]: false,
        }))
        setStatusCreate(false)
        setStatusLoadingCreate(false)
      }
    }
    setStatusCreate(false)
    setStatusLoadingCreate(false)
    setSelectedItems([])
  }

  const handleCreateAlocationStock = async () => {
    setStatusCreate(true)
    setStatusLoadingCreate(true)

    const chunkArray = (array, chunkSize) => {
      const results = []
      for (let i = 0; i < array.length; i += chunkSize) {
        results.push(array.slice(i, i + chunkSize))
      }
      return results
    }

    const chunks = chunkArray(itensMove, 10)

    for (const chunk of chunks) {
      const requests = chunk.map(async currentItem => {
        setLoadingMap(prevLoadingMap => ({
          ...prevLoadingMap,
          [currentItem.id]: true,
        }))

        const data = {
          subCompanyId: currentItem.subCompanyId,
          address: currentItem.address,
          column: currentItem.column,
          level: currentItem.level,
          serial: currentItem.serial,
          street: currentItem.street,
        }

        try {
          const response = await createAlocationStockService(token, nameUrl, data)

          const updatedStatusMessages = {}
          updatedStatusMessages[currentItem.id] = 'Registro feito com sucesso'
          setStatusMessage(prevStatusMessages => ({
            ...prevStatusMessages,
            ...updatedStatusMessages,
          }))

          const newlySuccessfullySentItems = updatedStatusMessages[currentItem.id]
          console.log(newlySuccessfullySentItems, 'sadsa')

          await new Promise(resolve => setTimeout(resolve, 1000))

          setItensMove(prevItems => prevItems.filter(item => item.id !== currentItem.id))
          setStatusCreate(false)
        } catch (error) {
          setLoadingMap(prevLoadingMap => ({
            ...prevLoadingMap,
            [error.id]: false,
          }))
          setStatusCreate(false)
          setStatusLoadingCreate(false)
        } finally {
          setLoadingMap(prevLoadingMap => ({
            ...prevLoadingMap,
            [currentItem.id]: false,
          }))
          setSelectedItems([])
        }
      })

      // Esperar todas as requisições do chunk serem concluídas antes de continuar
      await Promise.all(requests)
    }

    setStatusLoadingCreate(false)
  }

  const handleSaveUnity = async data => {
    setStatusCreate(true)
    setStatusLoadingCreate(true)
    setLoadingMap(prevLoadingMap => ({
      ...prevLoadingMap,
      [data.id]: true,
    }))

    const newData = {
      subCompanyId: data.subCompanyId,
      address: data.address,
      column: data.column,
      level: data.level,
      serial: data.serial,
      street: data.street,
    }

    try {
      const response = await createAlocationStockService(token, nameUrl, newData)
      setLoadingMap(prevLoadingMap => ({
        ...prevLoadingMap,
        [data.id]: false,
      }))
      const updatedStatusMessages = {}
      updatedStatusMessages[data.id] = 'Registro feito com sucesso'
      setStatusMessage(prevStatusMessages => ({
        ...prevStatusMessages,
        ...updatedStatusMessages,
      }))

      const newlySuccessfullySentItems = updatedStatusMessages[data.id]
      console.log(newlySuccessfullySentItems, 'sadsa')

      await new Promise(resolve => setTimeout(resolve, 1000))

      setItensMove(prevItems => prevItems.filter(item => item.id !== data.id))

      setStatusCreate(false)
      setStatusLoadingCreate(false)
      setSelectedItems([])
    } catch (error) {
      console.error(error)
      const updatedStatusMessages = {}
      updatedStatusMessages[data.id] = 'Falha ao enviar'
      setStatusMessage(prevStatusMessages => ({
        ...prevStatusMessages,
        ...updatedStatusMessages,
      }))
      setLoadingMap(prevLoadingMap => ({
        ...prevLoadingMap,
        [data.id]: false,
      }))
      setStatusCreate(false)
      setStatusLoadingCreate(false)
    }
  }

  useEffect(() => {
    filterRow()
  }, [search, selectedStatus, itensMove])

  useEffect(() => {}, [selectedStatus])

  return (
    <>
      <Box sx={{ mt: 7.5 }} onClick={handleWindowClick}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>{titleDialog}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              onClick={() => {
                handleClose()
                if (dialogAction === 'delete') {
                  handleDeleteItem()
                } else if (dialogAction === 'save') {
                  handleCreateAlocationStockSelected()
                }
                setDialogAction(null)
              }}
            >
              Sim
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>

        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Carga de Locação de Produtos</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Accordion expanded={expanded} sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography variant="body1">
              {edit ? 'Editar Locações de produtos' : 'Cadastrar Locações de produtos'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                width: 1,
                mt: 2,
                display: 'flex',
                gap: 2,
                border: '1px solid black',
                borderRadius: 2,
                p: 2,
                flexDirection: 'column',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-label">Filial</InputLabel>
                    <Select
                      value={subCompanyId}
                      sx={{ width: '100%' }}
                      label="Escolher a filial"
                      onChange={fetchData}
                    >
                      {subCompany.map((item, index) => {
                        return (
                          <MenuItem value={item.subCompanyId} key={index}>
                            {item.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      disabled={edit}
                      value={singleProd.address}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Endereço"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          address: e.target.value,
                        }))
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl sx={{ width: 1 }}>
                    <TextField
                      sx={{ width: '100%' }}
                      disabled={edit}
                      value={singleProd.street}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Rua"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          street: e.target.value,
                        }))
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      disabled={edit}
                      value={singleProd.level}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Andar"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          level: e.target.value,
                        }))
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      disabled={edit}
                      value={singleProd.column}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Coluna"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          column: e.target.value,
                        }))
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      disabled={edit}
                      value={singleProd.serial}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Serial"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          serial: e.target.value,
                        }))
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                >
                  <Button variant="outlined" onClick={() => addToList(singleProd)}>
                    {edit ? 'Atualizar Item' : 'Adicionar item'}
                  </Button>
                  {edit && (
                    <Button
                      variant="contained"
                      sx={{ ml: 1, display: 'flex', alignItems: 'center' }}
                      onClick={() => {
                        resetFields()
                      }}
                    >
                      Adicionar novo Item <Add sx={{ ml: 1 }} />
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl sx={{ width: '100%', mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                value={subCompanyId}
                sx={{ width: '100%' }}
                label="Escolher a filial"
                onChange={fetchData}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Box
          sx={{ justifyContent: 'space-between', width: 1, minWidth: 1 }}
          display={{ xs: 'column', sm: 'column', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box
            sx={{
              alignItems: 'center',
              textAlign: 'center',
              mt: 2,
              border: '1px solid black',
              width: { xs: 1, md: 0.34 },
              minWidth: { xs: 1, md: 0.3 },
              p: 2,
            }}
          >
            <a
              className="dropzone"
              target="_blank"
              rel="noreferrer"
              href="https://file.bihands.com/template/UploadAlocationStock.xlsx"
              download
            >
              <FileDownloadIcon sx={{ fontSize: 100 }} />
            </a>
            <InputLabel>Faça o download do exemplo de carga</InputLabel>
          </Box>
          <Box
            sx={{
              mt: 2,
              border: { md: '1px solid black' },
              p: 2,
            }}
            width={{ xs: '100%', sm: '100%', md: '65%', lg: '65%', xl: '65%' }}
          >
            <InputLabel>Insira aqui o documento para enviar</InputLabel>
            <TextField
              disabled={!subCompanyId}
              sx={{ width: '100%', mt: 1 }}
              id="standard-basic"
              variant="standard"
              value={valueFile}
              onChange={handleSelectFile}
              ref={ref}
              type="file"
              accept=".xls, .xlsx, .ods"
            />
            <Box
              sx={{
                mt: 4,
                alignItems: 'center',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>Apenas arquivos .XLS ou .XLSX</Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    sx={{ width: '100%', mb: 1 }}
                    id="standard-basic"
                    variant="outlined"
                    label="Procurar"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    type="text"
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-label">Filtrar Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedStatus}
                      onChange={e => setSelectedStatus(e.target.value)}
                    >
                      <MenuItem selected value="">
                        Todos
                      </MenuItem>
                      <MenuItem value="Registro feito com sucesso">Enviados</MenuItem>
                      <MenuItem value="Pronto para enviar">Pronto para enviar</MenuItem>
                      <MenuItem value="Falha ao enviar">Falha ao enviar</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="space-between" alignItems="end">
                <Box>
                  <Typography variant="body" sx={{ marginBottom: 2, mr: 0 }}>
                    {filter.length <= 1
                      ? `${filter.length} Resultado`
                      : `${filter.length} Resultados`}
                  </Typography>
                  {selectedItems.length > 0 && (
                    <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                      {selectedItems.length <= 1
                        ? `${selectedItems.length} Item Selecionado`
                        : `${selectedItems.length} Itens Selecionados`}
                    </Typography>
                  )}
                </Box>
                <Box display="flex" my={2}>
                  {selectedItems.length > 0 ? (
                    <Button
                      variant="contained"
                      disabled={statusLoadingCreate}
                      startIcon={
                        statusLoadingCreate && <CircularProgress color="inherit" size={20} />
                      }
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: ' space-between',
                        fontSize: '.9em',
                      }}
                      onClick={() => handleActionList('save')}
                    >
                      {statusLoadingCreate ? 'Enviando...' : 'Enviar Selecionados'}
                    </Button>
                  ) : (
                    <Button
                      disabled={itensMove.length === 0 || statusLoadingCreate}
                      variant="contained"
                      startIcon={
                        statusLoadingCreate && <CircularProgress color="inherit" size={20} />
                      }
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: ' space-between',
                        fontSize: '.9em',
                      }}
                      onClick={() => handleCreateAlocationStock()}
                    >
                      {statusLoadingCreate ? 'Enviando...' : 'Enviar Lista'}
                    </Button>
                  )}
                  <Button
                    sx={{ ml: 2 }}
                    disabled={statusLoadingCreate}
                    variant="contained"
                    startIcon={expanded ? <Remove size={20} /> : <Add size={20} />}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: ' space-between',
                      fontSize: '.9em',
                    }}
                    onClick={() => {
                      if (expanded) {
                        setExpanded(false)
                        setSingleProd({
                          address: '',
                          street: '',
                          level: '',
                          id: '',
                          column: '',
                          serial: '',
                        })
                        setEdit(false)
                      } else {
                        setExpanded(true)
                      }
                    }}
                  >
                    {expanded ? 'Cancelar' : 'Cadastrar'}
                  </Button>
                </Box>
              </Box>
            </Box>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <Tooltip title="Selecionar Todos" arrow>
                        <span>
                          <Checkbox
                            disabled={statusCreate}
                            checked={selectAll}
                            onChange={handleSelectAll}
                            color="primary"
                          />
                        </span>
                      </Tooltip>
                    </Box>
                  </StyledTableCell>{' '}
                  <StyledTableCell sx={{ pl: 1 }} align="left">
                    Filial
                  </StyledTableCell>
                  <StyledTableCell sx={{ pl: 1 }} align="left">
                    Endereço
                  </StyledTableCell>
                  <StyledTableCell sx={{ pl: 1 }} align="left">
                    Rua
                  </StyledTableCell>
                  <StyledTableCell sx={{ pl: 1 }} align="left">
                    Andar
                  </StyledTableCell>
                  <StyledTableCell sx={{ pl: 1 }} align="left">
                    Coluna
                  </StyledTableCell>
                  <StyledTableCell sx={{ pl: 1 }} align="left">
                    Serial
                  </StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell> </StyledTableCell>
                  <StyledTableCell> </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filter
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        <Checkbox
                          disabled={statusCreate}
                          checked={selectedItems.includes(row.id)}
                          onChange={() => handleItemSelect(row.id)}
                        />
                      </StyledTableCell>

                      <StyledTableCell align="left">{row.nameCompany}</StyledTableCell>
                      <StyledTableCell align="left">{row.address}</StyledTableCell>
                      <StyledTableCell align="left">{row.street}</StyledTableCell>
                      <StyledTableCell align="left">{row.level}</StyledTableCell>
                      <StyledTableCell align="left">{row.column}</StyledTableCell>
                      <StyledTableCell align="left">{row.serial}</StyledTableCell>
                      <StyledTableCell align="center">
                        {loadingMap[row.id] ? (
                          <>
                            <Box display="flex" alignItems="center" justifyContent="center">
                              <CircularProgress
                                value={0}
                                sx={{ color: colorTableDefault }}
                                size={30}
                              />
                              <Typography ml={2} variant="caption">
                                Enviando...
                              </Typography>
                            </Box>
                          </>
                        ) : (
                          <>
                            {(() => {
                              const status = statusMessage[row.id]

                              if (status === 'Falha ao enviar') {
                                return <Chip color="error" variant="filled" label={status || ''} />
                              } else if (status === 'Registro feito com sucesso') {
                                return (
                                  <Chip
                                    color="success"
                                    variant="filled"
                                    label={`${status}` || ''}
                                  />
                                )
                              } else {
                                return (
                                  <Chip
                                    color="info"
                                    variant="filled"
                                    label={`Pronto para enviar`}
                                  />
                                )
                              }
                            })()}
                          </>
                        )}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                        <Tooltip title="Reenviar" arrow>
                          <IconButton onClick={() => handleSaveUnity(row)}>
                            <Replay sx={{ cursor: 'pointer' }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Editar" arrow>
                          <IconButton
                            disabled={statusCreate}
                            onClick={() => {
                              //setEditedValue(row.idOtherSystem)
                              handleDoubleClick()
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Apagar" arrow>
                          <IconButton
                          // onClick={() =>
                          //   handleRemoveProductItem(
                          //     item.id,
                          //     item.productId
                          //   )
                          // }
                          >
                            <DeleteForever sx={{ cursor: 'pointer' }} />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filter.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default UploadAlocationStock
