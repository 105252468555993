import { useCallback, useContext, useEffect, useState } from 'react'
import { deleteSegmentService, getSegmentService } from '../../../services/admServices'
import { Context } from '../../../context/AuthContext'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  Paper,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogActions,
  Tooltip,
  IconButton,
} from '@mui/material'
import SegmentCreate from '../../Create/SegmentCreate'
import SegmentEdit from '../../Edit/SegmentEdit'
import { AiFillPlusCircle } from 'react-icons/ai'

//icones react-icons
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import PermissionComponent from '../../../permissions'

const Segment = () => {
  const token = localStorage.getItem('token')
  const [segment, setSegment] = useState([])
  const [pagination, setPagination] = useState({})
  const [segmentLimit, setSegmentLimit] = useState(500)
  const [segmentOffset, setSegmentOffset] = useState(0)
  const [segmentOrder, setSegmentOrder] = useState(false)

  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [idEditing, setIdEditing] = useState('')

  const { userRoles, nameUrl } = useContext(Context)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getSegment = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getSegmentService(
        token,
        nameUrl,
        segmentLimit,
        segmentOffset,
        segmentOrder
      )
      setSegment(response.data.result)
      setPagination(response.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [segmentOrder])

  useEffect(() => {
    getSegment()
  }, [segmentOrder])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleEdit = id => {
    setIdEditing(id)
    setExpanded(true)
    setEditing(true)
    window.scrollTo(0, 0)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    try {
      await deleteSegmentService(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Segmento deletado com sucesso!')

      setAlerta(true)
      setLoading(false)
      getSegment()
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Typography variant="h5">Segmento</Typography>

        {/* Criar  */}

        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>{editing ? 'Editando Segmento' : 'Cadastrar Segmento'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="SegmentEDIT">
                <SegmentEdit
                  setEditing={setEditing}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  segmentId={idEditing}
                  handleFetchData={getSegment}
                  setExpanded={setExpanded}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="SegmentCREATE">
                <SegmentCreate
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={getSegment}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>
        <Box
          sx={{
            mt: 3,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              ml: 1,
            }}
          >
            <PermissionComponent role="SegmentCREATE">
              <Button
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
                variant="contained"
                endIcon={<AiFillPlusCircle />}
              >
                Criar
              </Button>
            </PermissionComponent>
          </Box>
        </Box>

        {/* Deletar */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>

        {segment.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="left">Nome</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {segment
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left" sx={{ width: 50 }}>
                        <PermissionComponent role="SegmentDELETE">
                          <Tooltip title="Apagar" arrow>
                            <IconButton
                              onClick={() => deleteAlert(row.segmentId)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                        <PermissionComponent role="SegmentEDIT">
                          <Tooltip title="Editar" arrow>
                            <IconButton
                              onClick={() => handleEdit(row.segmentId)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <ModeEditOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                      </StyledTableCell>

                      <StyledTableCell align="left" sx={{ width: 200 }}>
                        {row.name}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={segment.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default Segment
