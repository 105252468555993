import { forgotPassword, resetPassword, userLogin } from '../../services/api'
import { useCallback, useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Context } from '../../context/AuthContext'
import bgImage from '../../assets/login.png'
import bihands from '../../assets/Ativo-3@4x-bihands-300x64.png'

import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'

const ForgotPassword = () => {
  const { handleLogin, nameUrl } = useContext(Context)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [invalidUser, setInvalideUser] = useState(false)
  const [errorMsg, setErrorMsg] = useState()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [error, setError] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [confirmPassword, setConfirmPassword] = useState('')
  const [heaveError, setHeaveErrorMsg] = useState('')

  const resetPass = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)

      const user = {
        email: email,
      }
      setInvalideUser(false)
      setErrorMsg()
      try {
        const response = await forgotPassword(nameUrl, user)
        setHeaveErrorMsg('success')
        setErrorMsg('Um link foi enviado ao seu e-mail')
        setError(true)
        setLoading(false)
      } catch (err) {
        setHeaveErrorMsg('error')
        setLoading(false)
        setError(true)
        setErrorMsg(err.response.data.message)
      }
    },
    [email, password, confirmPassword]
  )

  const handleClose = () => {
    setError(false)
  }

  return (
    <Grid
      container
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={error}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity={heaveError} sx={{ width: '100%' }}>
          {errorMsg}
        </Alert>
      </Snackbar>

      {/* Desktop */}
      <Box
        display={{ xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }}
        sx={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '65%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: `url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
          }}
        />
        <Box
          sx={{
            width: '35%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img style={{ position: 'absolute', top: 50 }} src={bihands} alt="Bihands" />
          <Typography variant="h5" sx={{ mb: 5 }}>
            Redefinição de senha
          </Typography>
          <Box component="form" sx={{ mt: '2rem', width: '90%' }} onSubmit={resetPass}>
            <Box>
              <TextField
                sx={{ width: '100%' }}
                value={email}
                onChange={values => setEmail(values.target.value)}
                id="outlined-basic"
                label="Email"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { sm: 'column', md: 'row' },
                width: '100%',
                mt: 3,
                justifyContent: 'space-evenly',
              }}
            >
              <Button type="submit" variant="contained">
                Redefinir a senha
              </Button>
              <Button variant="contained" onClick={() => navigate('/Login')}>
                Voltar
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Mobile */}
      <Box
        display={{ xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none' }}
        sx={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          position: 'relative',
        }}
      >
        <img style={{ position: 'absolute', top: 20 }} src={bihands} alt="Bihands" />
        <Paper
          elevation={3}
          sx={{
            textAlign: 'center',
            padding: 2,
          }}
        >
          <Typography variant="h5" sx={{ mb: 5 }}>
            Redefinição de senha
          </Typography>
          <Box component="form" sx={{ mt: '2rem' }} onSubmit={resetPass}>
            <Box>
              <TextField
                sx={{ width: '100%' }}
                value={email}
                onChange={values => setEmail(values.target.value)}
                id="outlined-basic"
                label="Email"
                variant="outlined"
                type="text"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                mt: 3,
                justifyContent: 'space-evenly',
              }}
            >
              <Button type="submit" variant="contained">
                Redefinir a senha
              </Button>
              <Button
                type="button"
                variant="contained"
                onClick={() => navigate('/Login')}
                sx={{ mt: 2 }}
              >
                Voltar
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>

      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  )
}

export default ForgotPassword
