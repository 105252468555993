import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { ClientDelete, ClientEdit, ClientCreate } from '../../../const/RoleConstants'
import HelpIcon from '@mui/icons-material/Help'
import { setPermission } from '../../../permissions/userPermissions'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Tooltip,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Skeleton,
  InputAdornment,
} from '@mui/material'
import { AiFillPlusCircle } from 'react-icons/ai'

//icones react-icons
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import { deleteClient, getClient } from '../../../services/clientService'
import ClientPCreate from '../../Create/ClientCreate'
import ClientPEdit from '../../Edit/ClientPEdit'
import PermissionComponent from '../../../permissions'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'
import CreateSensorAndPerson from '../../Create/CreateSensorAndPerson'
import {
  deleteSensorAndPerson,
  searchProductSubCompanyService,
} from '../../../services/productService'
import { useDebounce } from '../Product/Components/useDebounce'
import { DeleteForever, ModeEditOutline, Search } from '@mui/icons-material'
import SensorAndPersonEdit from '../../Edit/SensorAndPersonEdit'
import { useSearchParams } from 'react-router-dom'

const SensorAndPerson = () => {
  const token = localStorage.getItem('token')
  const { debounce } = useDebounce(1000, true)

  const { subCompany, userRoles, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [idEditing, setIdEditing] = useState({})

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)
  const [filterTable, setFilterTable] = useState('')
  const [filterCNPJ, setFilterCNPJ] = useState('')
  const [clientList, setClientList] = useState([])
  const [filterRow, setFilterRow] = useState([])
  const [notSearch, setNotSearch] = useState(false)
  const [search, setSearch] = useState('')
  const [products, setProducts] = useState([])
  const [totalProducts, setTotalProducts] = useState(0)
  const [searchR, setSearchR] = useState('')
  const [selectedId, setSelectedId] = useState(undefined)
  const [currentPage, setCurrentPage] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams()

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1')
  }, [searchParams])

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1)
    setSearchParams({ ...searchParams, page: newPage + 1 })
  }

  const handleChangeRowsPerPage = event => {
    const newRowsPerPage = +event.target.value
    setRowsPerPage(newRowsPerPage)
    setCurrentPage(0)
  }

  const handleGetAllProductsSearch = async subCompanyId => {
    setSubCompanyId(subCompanyId)
    setLoading(true)
    const dataProduct = {
      subCompanyId: subCompanyId,
      limit: rowsPerPage,
      offset: page,
      order: 'desc',
      SearchWord: search,
    }

    try {
      const response = await searchProductSubCompanyService(token, nameUrl, dataProduct)
      const data = response.data.result
      setProducts(data)

      if (selectedId) {
        setTotalProducts(response.data.result.length)
        setSearchR(response.data.result.length)
      } else {
        setTotalProducts(response.data.total)
        setSearchR(response.data.total)
      }
    } catch (error) {
      console.error('Error fetching products:', error)
      setProducts([])
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setCurrentPage(0)
    if (subCompanyId) {
      setLoading(true)
      debounce(() => {
        handleGetAllProductsSearch(subCompanyId)
      })
    }
  }, [search, page, selectedId, subCompanyId, rowsPerPage])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleEdit = item => {
    setIdEditing(item)
    setExpanded(true)
    setEditing(true)
    window.scrollTo(0, 0)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)

    try {
      const response = await deleteSensorAndPerson(token, nameUrl, idDelete)

      handleGetAllProductsSearch()
      setTitle(response.data.message)
      setSeverity('success')
      setAlerta(true)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  const filter = () => {
    const filtered = clientList.filter(
      row =>
        row.codClient.toLowerCase().includes(filterTable.toLowerCase()) ||
        row.name.toLowerCase().includes(filterTable.toLowerCase()) ||
        row.email.toLowerCase().includes(filterTable.toLowerCase()) ||
        row.cpF_CNPJ.toLowerCase().includes(filterTable.toLowerCase())
    )

    setFilterRow(filtered)
  }

  useEffect(() => {}, [idEditing])

  useEffect(() => {
    filter()
  }, [filterTable, clientList, notSearch])

  return (
    <>
      <Box sx={{ mt: 7.5, height: 'fit-content' }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Cadastro de pessoa e sensor</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon />
          </IconButton>
        </Box>
        {/* Criar  */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>
              {editing ? 'Editando de pessoa e sensor' : 'Cadastrar de pessoa e sensor'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="RTLS">
                <SensorAndPersonEdit
                  setEditing={setEditing}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  setExpanded={setExpanded}
                  subCompanyIdItem={subCompanyId}
                  id={idEditing}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="RTLS">
                <CreateSensorAndPerson
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>

        <SelectSubCompany
          value={subCompanyId}
          onChange={e => {
            handleGetAllProductsSearch(e.target.value)
          }}
        />
        <Box
          sx={{
            mt: 3,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              ml: 1,
            }}
          >
            <PermissionComponent role="RTLS">
              <Button
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
                variant="contained"
                endIcon={<AiFillPlusCircle />}
              >
                Criar
              </Button>
            </PermissionComponent>
          </Box>
        </Box>

        {/* Deletar */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>
        <Box width="100%">
          <TextField
            sx={{ width: '100%', maxWidth: '250px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: loading && (
                <InputAdornment position="end">
                  <CircularProgress color="info" size={20} />
                </InputAdornment>
              ),
            }}
            label="Pesquisar"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </Box>
        <TableContainer component={Paper}>
          <Typography sx={{ width: 1, my: 2 }}>
            {products?.length > 1
              ? `${searchR.toLocaleString('pt-BR')} Resultados`
              : `${searchR.toLocaleString('pt-BR')} Resultado`}
          </Typography>

          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Descrição do ativo</StyledTableCell>
                <StyledTableCell align="left">Cód. Barras</StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              Array.from({ length: 10 }).map((_, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Array.from({ length: 3 }).map((_, colIndex) => (
                    <TableCell key={colIndex}>
                      <Skeleton animation="wave" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableBody>
                {products?.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell align="left">{row.barCode}</StyledTableCell>
                    <StyledTableCell align="left">
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Tooltip title="Apagar" arrow>
                          <IconButton>
                            <DeleteForever
                              onClick={() => deleteAlert(row.productItensId)}
                              sx={{ cursor: 'pointer' }}
                            />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Editar" arrow>
                          <IconButton>
                            <ModeEditOutline
                              onClick={() => handleEdit(row)}
                              sx={{ cursor: 'pointer' }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            )}
          </Table>
          <TablePagination
            labelRowsPerPage={'Itens por página'}
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={totalProducts}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default SensorAndPerson
