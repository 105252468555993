import React, { useContext, useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { styled, tableCellClasses } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ListItemText from '@mui/material/ListItemText'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import TablePagination from '@mui/material/TablePagination'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import { formatNumbers } from '../../../../../utils/formatNumbers'
import { Context } from '../../../../../context/AuthContext'
import { Add, Delete, Edit } from '@mui/icons-material'
import { colorTableDefault } from '../../../../TemplateDefault'
import {
  getGenerateTagRfid,
  getMovimentationIdServices,
} from '../../../../../services/productService'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export default function ComponentQuantityCreate({
  open,
  onClose,
  product,
  productRows,
  message,
  subCompanyId,
  onSaveQuantity,
  id,
}) {
  const token = localStorage.getItem('token')
  const { subCompany, userId, nameUrl } = useContext(Context)
  const [loading, setLoading] = useState('')
  const [valueText, setValueText] = useState('')
  const [title, setTitle] = useState('')
  const [messageError, setMessageError] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [register, setRegister] = useState(false)
  const [codeList, setCodeList] = useState([])
  const vertical = 'top'
  const horizontal = 'center'
  const inputRef = useRef(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function Row(props) {
    const { row, onDelete } = props

    const handleDeleteClick = () => {
      onDelete(row)
    }

    return (
      <React.Fragment>
        <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <StyledTableCell align="center" width="200px">
            <Tooltip title="Apagar" arrow>
              <IconButton onClick={handleDeleteClick}>
                <Delete />
              </IconButton>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell align="left">{row.productName}</StyledTableCell>
          <StyledTableCell align="left">{row.idProductPartner}</StyledTableCell>
          <StyledTableCell align="center">{row.quantity}</StyledTableCell>
        </StyledTableRow>
      </React.Fragment>
    )
  }

  const handleGetMovimentationId = async () => {
    try {
      const response = await getMovimentationIdServices(token, nameUrl, id)
      console.log(response.data.movimentationERPitemsCollection, 'code')

      setCodeList(response.data.movimentationERPitemsCollection)
      // setSeverity('success')
      // setAlerta(true)
      // setTitle('Atendimento iniciado com sucesso!')
      setLoading(false)
    } catch (error) {
      // setSeverity('error')
      // setAlerta(true)
      // setTitle('Erro tente novamente mais tarde!')
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id && open) {
      handleGetMovimentationId()
    }
  }, [id, open])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current.focus()
      }
    }, 100)
    return () => clearTimeout(timer)
  }, [open])

  const handleAddList = () => {
    if (valueText === '') {
      setMessageError('Digite um codigo Rfid.')
      setLoading(false)
      return
    }
    const codeExists = codeList.some(item => item.serial === valueText)

    if (codeExists) {
      setTitle('Este código RFID ja foi lido.')
      setSeverity('error')
      setValueText('')
      setRegister(true)
      setLoading(false)
      return
    }

    const newProduct = {
      ...product,
      serial: valueText,
      subCompanyId: subCompanyId,
      userId: userId,
    }
    setCodeList(prevList => [...prevList, newProduct])
    setValueText('')
  }

  const handleSave = async () => {
    setLoading(true)
    const data = codeList.map(item => ({
      productId: item.productId,
      companyId: subCompany[0].companyId,
      quantity: item.quantity,
    }))

    try {
      const response = await getGenerateTagRfid(token, nameUrl, data)
      const newList = codeList.flatMap(product => {
        const serials = response.data.filter(item => item.productId === product.productId)

        return serials.map(serial => ({
          ...product,
          serial: serial.serial,
        }))
      })
      setLoading(false)
      onSaveQuantity(newList)
      setCodeList([])
      onClose()
    } catch (error) {}
  }

  const handleDelete = itemToDelete => {
    const updatedRows = codeList.filter(item => item !== itemToDelete)
    setCodeList(updatedRows)
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={register}
        onClose={() => setRegister(false)}
        key={vertical + horizontal}
      >
        <Alert onClose={() => setRegister(false)} severity={severity} sx={{ width: '100%' }}>
          {title}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={() => {
          onClose()
          setCodeList([])
          setValueText('')
        }}
        onEnter={() => inputRef.current.focus()}
        sx={{ m: 2 }}
        fullScreen
      >
        {loading ? (
          <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />¨<DialogTitle>Gerando códigos...</DialogTitle>
          </Box>
        ) : (
          <>
            <DialogTitle>Produtos no pedido</DialogTitle>
            <DialogContent>
              <Divider sx={{ my: 2 }} />
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left"></StyledTableCell>
                      <StyledTableCell align="left">Nome</StyledTableCell>
                      <StyledTableCell align="left">Identificação ERP</StyledTableCell>
                      <StyledTableCell align="center">Quantidade</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {codeList
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map(row => (
                        <Row key={row.serial} row={row} onDelete={handleDelete} />
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={codeList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  onClose()
                  setCodeList([])
                  setValueText('')
                }}
                variant="outlined"
              >
                Cancelar
              </Button>
              <Button type="submit" variant="contained" onClick={handleSave}>
                Gerar codigo
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  )
}
