import React, { useContext } from 'react'
import { useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import HelpIcon from '@mui/icons-material/Help'
import {
  deleteMovimentationOrderService,
  downloadPrintLabelZPL,
  getOrderPaginationService,
  updateConfirOrder,
} from '../../../services/productService'
import { RESULTS_PER_PAGE } from '../../../utils/excel'
import * as XLSX from 'xlsx'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material'
import LabelOrderEdit from '../../Edit/LabelOrderEdit'

//icones react-icons
import { AiFillFileExcel } from 'react-icons/ai'
import { AiFillPlusCircle } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'
import { Navigate, useNavigate } from 'react-router-dom'
import { returnStatus } from '../../../assets/statusTypes'
import PermissionComponent from '../../../permissions'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'
import { Delete, Download, Update } from '@mui/icons-material'
import LabelOrderClientEdit from '../../Edit/LabelOrderClientEdit'
import { labelaryService } from '../../../services/printerZPLDefaultServices'
import JSZip from 'jszip'
import { updatesPrintTag } from '../../../services/admServices'

const LabelOrderClient = () => {
  const { userRoles, subCompany } = useContext(Context)
  const navigate = useNavigate()
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const selectCompany = localStorage.getItem('companyId')
  const token = localStorage.getItem('token')
  const url = localStorage.getItem('url')
  const [loading, setLoading] = useState(false)
  const [labelOrder, setLabelOrder] = useState([])
  const [originalList, setOriginalList] = useState([])
  const [pageCount, setPageCount] = useState(0)

  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [expandLabelOrder, setExpandLabelOrder] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const [idDownload, setIdDownload] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [idEditing, setIdEditing] = useState('')

  const [currentPage, setCurrentPage] = useState(0)
  const [totalPagesDownload, setTotalPagesDownload] = useState('')
  const [progress, setProgress] = useState(0)
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)
  const [openDownalod, setOpenDownalod] = useState(false)
  const [productItensId, setProductItensId] = useState([])
  const [movimentationOrderNumberBihands, setMovimentationOrderNumberBihands] = useState('')
  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')
  const [nameUrl, setNameUrl] = useState('')
  const [viewElearning, setViewElearning] = useState(false)

  const [labelOrderOrder, setLabelOrderOrder] = useState(false)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalData, setTotalData] = useState(0)
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)
  const [openConfirmOrder, setOpenConfirmOrder] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
          px: 2,
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  }

  const fetchData = async (values, nameUrl) => {
    if (values) {
      setSubCompanyId(values)
    }
    setLoading(true)
    setLoading(true)
    try {
      const response = await getOrderPaginationService(
        token,
        nameUrl,
        { subCompanyId: values, typeOrder: 10 },
        rowsPerPage,
        page,
        labelOrderOrder
      )
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
        setLabelOrder([])
        setOriginalList([])
      } else {
        setLabelOrder(response.data.result)
        setOriginalList(response.data.result)
        setPageCount(response.data.totalpage + 1)
        setTotalData(response.data.total)
        setExpandLabelOrder(true)
      }
      setLoading(false)
    } catch (e) {
      setLabelOrder([])
      setOriginalList([])
      console.log(e)
      setTitle('Erro no servidor, tente novamente')
      setAlerta(true)
      setSeverity('error')
      setLoading(false)
    }
  }

  useEffect(() => {}, [nameUrl])

  const downloadData = async () => {
    setDownloadMessage('Inicializando')
    setDownloading(true)
    await getOrderPaginationService(
      token,
      nameUrl,
      { subCompanyId, typeOrder: 10 },
      RESULTS_PER_PAGE,
      0,
      labelOrderOrder
    ).then(r => {
      setTotalPagesDownload(r.data.totalpage + 1)
      finishDownload(r.data.totalpage + 1)
    })
  }

  const finishDownload = async pages => {
    let result = []

    for (let i = 0; i <= pages; i++) {
      setDownloadMessage(`Baixando ${i} de ${pages}...`)
      setCurrentPage(i)
      setProgress((i / pages) * 100)
      const response = await getOrderPaginationService(
        token,
        nameUrl,
        { subCompanyId: subCompanyId, typeOrder: 10 },
        RESULTS_PER_PAGE,
        i,
        labelOrderOrder
      )
      result = result.concat(response.data.result)
    }
    setDownloadMessage(`Seu arquivo está pronto.`)
    setDownloading(false)
    const relatorioFinal = []
    result.map(item => {
      relatorioFinal.push({
        'Ordem Bihands': item.movimentationOrderNumberBihands,
        'Ordem (Outro Sistema)': item.movimentationOrderNumberOtherSystem,
        Filial: item.subCompanyNameFrom,
        Criado: new Date(item.createRequestMovimentationOrderDate).toLocaleString(),
        Status: item.active ? 'Aberto' : 'Inativo',
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Impressão de Etiquetas')
    XLSX.writeFile(workbook, `Impressão de Etiquetas.xlsx`)
    setProgress(0)
  }

  useEffect(() => {}, [subCompanyId, nameUrl])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
    setOpenDownalod(false)
  }

  const handleEdit = id => {
    setIdEditing(id)
    setExpanded(true)
    setEditing(true)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const downloadAlert = data => {
    setOpen(true)
    setIdDownload(data)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    console.log(nameUrl, 'name')
    try {
      const responde = await deleteMovimentationOrderService(
        token,
        selectCompany ? nameUrl : url,
        idDelete
      )
      setSeverity('success')
      setTitle(responde.data.message)

      setAlerta(true)
      setLoading(false)
      fetchData(subCompanyId, selectCompany ? nameUrl : url)
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (selectCompany) {
      const selectedSubCompany = subCompany.find(item => item.companyId === selectCompany)
      if (selectedSubCompany) {
        console.log('sadsadad')
        setNameUrl(selectedSubCompany.nameUrl ? selectedSubCompany.nameUrl : '1')
        setSubCompanyId(selectedSubCompany.subCompanyId)
        fetchData(
          selectedSubCompany.subCompanyId,
          selectedSubCompany.nameUrl ? selectedSubCompany.nameUrl : '1'
        )
      }
    } else if (!selectCompany && subCompanyId !== '') {
      console.log(url, 'idCsOssm')
      fetchData(subCompanyId, url)
    }
  }, [page, rowsPerPage, selectCompany, subCompany])

  const handleDownlaod = async () => {
    setDownloadMessage(`Inciando download...`)
    setLoading(true)
    setOpen(false)
    setDownloading(true)
    setOpenDownalod(false)

    setTimeout(() => {
      setDownloadMessage(`Baixando Modelo de etiqueta...`)
      for (let i = 0; i <= 50; i++) {
        setProgress(i)
      }
    }, 1000)
    const data = {
      movimentationOrderId: productItensId.movimentationOrderId,
      productItensId: [],
    }

    try {
      const response = await downloadPrintLabelZPL(token, nameUrl, data)

      let zplText = ''

      for (let i = 0; i < response.length; i++) {
        const zplCode = response[i].zpl

        zplText += `${zplCode}\n\n` // Adiciona uma quebra de linha entre os códigos ZPL
      }

      const increment = 50 / productItensId.movimentationOrderItemCollection.length

      for (let i = 0; i < productItensId.movimentationOrderItemCollection.length; i++) {
        const zplCode = productItensId.movimentationOrderItemCollection[i]

        const data = {
          movimentationOrderItensId: zplCode.movimentationOrderItemId,
          productItensId: zplCode.productItensId,
        }

        await new Promise(resolve => {
          setTimeout(() => {
            resolve()
          }, 5000) // 5000 milissegundos = 5 segundos
        })
        setProgress(progress => progress + increment)
        await updatesPrintTag(token, nameUrl, data)
      }

      const blob = new Blob([zplText], { type: 'text/plain' })

      const url = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.download = 'labels.txt'

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      URL.revokeObjectURL(url)

      setSeverity('success')
      setAlerta(true)
      setLoading(false)
      setDownloadMessage('Seu arquivo está pronto.')
      setDownloading(false)
      setProgress(0)
    } catch (error) {
      setDownloading(false)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  const handleConfirmOrder = async () => {
    setLoading(true)
    try {
      const response = await updateConfirOrder(
        token,
        movimentationOrderNumberOtherSystem,
        movimentationOrderNumberBihands
      )
      setSeverity('success')
      setTitle(response.data.message)

      setAlerta(true)
      fetchData(subCompanyId, selectCompany ? nameUrl : url)
      setOpenConfirmOrder(false)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Dialog
          open={openConfirmOrder}
          keepMounted
          //onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-title">Deseja confirmar o pedido?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Não</Button>
            <Button variant="contained" onClick={handleConfirmOrder} autoFocus>
              Sim
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={downloading} keepMounted aria-describedby="alert-dialog-slide-description">
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '30rem', md: '40rem', lg: '40rem', xl: '40rem' }}
          >
            <DialogTitle>{downloadMessage}</DialogTitle>
            <LinearProgressWithLabel value={progress} sx={{ width: '100%' }} />
          </Box>
        </Dialog>
        <Dialog
          open={openDownalod}
          keepMounted
          //onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-title">
            Deseja realizar o downloads do codigo zpl?
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Não</Button>
            <Button variant="contained" onClick={handleDownlaod} autoFocus>
              Sim
            </Button>
          </DialogActions>
        </Dialog>

        <ViewElearning pageName={'LabelOrder'} open={viewElearning} setOpen={setViewElearning} />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Impressão de Etiqueta</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon onClick={() => setViewElearning(true)} />
          </IconButton>
        </Box>

        {/* Criar  */}
        {editing && (
          <Accordion expanded={expanded}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => setExpanded(!expanded)}
            >
              <Typography>
                {editing ? 'Editar Impressão de Etiqueta' : 'Cadastrar Impressão de Etiqueta'}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {editing && (
                <PermissionComponent role="Edit_LabelOrder_Client">
                  <LabelOrderClientEdit
                    setEditing={setExpanded}
                    setAlerta={setAlerta}
                    setSeverity={setSeverity}
                    setTitle={setTitle}
                    labelOrderId={idEditing}
                  />
                </PermissionComponent>
              )}
            </AccordionDetails>
          </Accordion>
        )}

        {/* Deletar */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>

        <SelectSubCompany
          value={subCompanyId}
          onChange={e => {
            const name = subCompany.find(item => item.subCompanyId === e.target.value)
            console.log(name, 'name')
            if (name.nameUrl) {
              setNameUrl(name.nameUrl)
            } else {
              setNameUrl('1')
            }

            fetchData(e.target.value, name.nameUrl ? name.nameUrl : !selectCompany ? url : '1')
          }}
        />
        <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
              {labelOrder.length > 0 && (
                <Button onClick={downloadData} variant="contained" endIcon={<AiFillFileExcel />}>
                  Excel
                </Button>
              )}
            </Box>
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', ml: 1 }}
            >
              <PermissionComponent role="Create_LabelOrder_Client">
                <Button
                  variant="contained"
                  endIcon={<AiFillPlusCircle />}
                  onClick={() => {
                    // setEditing(false)
                    // setExpanded(true)
                    navigate('/LabelOrderClient/LabelOrderCreate')
                  }}
                >
                  Criar
                </Button>
              </PermissionComponent>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TextField
            variant="outlined"
            placeholder="Procurar por Ordem"
            type="search"
            onInput={e => setSearch(e.target.value)}
          />
          {searching && <Typography>Buscando...</Typography>}
        </Box>
        <Box display="flex" flexDirection="column" gap={2} my={2}>
          <Accordion expanded={expandLabelOrder}>
            <AccordionSummary
              onClick={() => setExpandLabelOrder(!expandLabelOrder)}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <Typography>Impressão em aberto</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <TableContainer component={Paper} sx={{ mt: '2rem' }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell> </StyledTableCell>
                      <StyledTableCell align="left">Ordem Bihands</StyledTableCell>
                      <StyledTableCell align="left">Ordem (outro sistema)</StyledTableCell>
                      <StyledTableCell align="left">Filial</StyledTableCell>
                      <StyledTableCell align="left">Criado</StyledTableCell>
                      <StyledTableCell align="left">Status</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {labelOrder.length > 0 && (
                    <TableBody>
                      {labelOrder
                        .filter(item => item.movimentationOrderStatus === 1)
                        .map((row, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell
                              align="left"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '180px',
                              }}
                            >
                              <PermissionComponent role="Confirm_LabelOrder_Client">
                                <Tooltip title="Confirmar Pedido" arrow>
                                  <IconButton
                                    disabled={row.movimentationOrderStatus === 2}
                                    onClick={() => {
                                      setOpenConfirmOrder(true)
                                      setMovimentationOrderNumberBihands(
                                        row.movimentationOrderNumberBihands
                                      )
                                      setMovimentationOrderNumberOtherSystem(
                                        row.movimentationOrderNumberOtherSystem
                                      )
                                    }}
                                    sx={{ cursor: 'pointer' }}
                                  >
                                    <Update />
                                  </IconButton>
                                </Tooltip>
                              </PermissionComponent>
                              <PermissionComponent role="">
                                <Tooltip title="Baixar" arrow>
                                  <IconButton
                                    onClick={() => {
                                      setOpenDownalod(true)
                                      setProductItensId(row)
                                    }}
                                    sx={{ cursor: 'pointer' }}
                                  >
                                    <Download />
                                  </IconButton>
                                </Tooltip>
                              </PermissionComponent>
                              {row.movimentationStatus !== 2 && (
                                <PermissionComponent role="Delete_LabelOrder_Client">
                                  <Tooltip title="Apagar" arrow>
                                    <IconButton
                                      onClick={() => deleteAlert(row.movimentationOrderId)}
                                      sx={{ cursor: 'pointer' }}
                                    >
                                      <DeleteForeverIcon />
                                    </IconButton>
                                  </Tooltip>
                                </PermissionComponent>
                              )}

                              <PermissionComponent role="Edit_LabelOrder_Client">
                                <Tooltip title="Editar" arrow>
                                  <IconButton
                                    onClick={() => handleEdit(row.movimentationOrderId)}
                                    sx={{ cursor: 'pointer' }}
                                  >
                                    <ModeEditOutlineIcon />
                                  </IconButton>
                                </Tooltip>
                              </PermissionComponent>
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {row.movimentationOrderNumberBihands}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.movimentationOrderNumberOtherSystem}
                            </StyledTableCell>
                            <StyledTableCell align="left">{row.subCompanyNameFrom}</StyledTableCell>
                            <StyledTableCell align="left">
                              {new Date(row.createRequestMovimentationOrderDate).toLocaleString()}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {returnStatus(row.movimentationOrderStatus)}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  )}
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  component="div"
                  count={totalData}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Impressão finalizada</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <AccordionDetails>
                <TableContainer component={Paper} sx={{ mt: '2rem' }}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell> </StyledTableCell>
                        <StyledTableCell align="left">Ordem Bihands</StyledTableCell>
                        <StyledTableCell align="left">Ordem (outro sistema)</StyledTableCell>
                        <StyledTableCell align="left">Filial</StyledTableCell>
                        <StyledTableCell align="left">Criado</StyledTableCell>
                        <StyledTableCell align="left">Status</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {labelOrder.length > 0 && (
                      <TableBody>
                        {labelOrder
                          .filter(item => item.movimentationOrderStatus !== 1)
                          .map((row, index) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell
                                align="left"
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <PermissionComponent role="Edit_LabelOrder_Client">
                                  <Tooltip title="Confirmar Pedido" arrow>
                                    <IconButton
                                      disabled={row.movimentationOrderStatus === 2}
                                      onClick={() => {
                                        setOpenConfirmOrder(true)
                                        setMovimentationOrderNumberBihands(
                                          row.movimentationOrderNumberBihands
                                        )
                                        setMovimentationOrderNumberOtherSystem(
                                          row.movimentationOrderNumberOtherSystem
                                        )
                                      }}
                                      sx={{ cursor: 'pointer' }}
                                    >
                                      <Update />
                                    </IconButton>
                                  </Tooltip>
                                </PermissionComponent>
                                {row.movimentationOrderStatus === 2 && (
                                  <PermissionComponent role="Delete_LabelOrder_Client">
                                    <Tooltip title="Apagar" arrow>
                                      <IconButton
                                        onClick={() => deleteAlert(row.movimentationOrderId)}
                                        sx={{ cursor: 'pointer' }}
                                      >
                                        <Delete />
                                      </IconButton>
                                    </Tooltip>
                                  </PermissionComponent>
                                )}
                                <PermissionComponent role="">
                                  <Tooltip title="Baixar" arrow>
                                    <IconButton
                                      onClick={() => {
                                        setOpenDownalod(true)
                                        setProductItensId(row)
                                      }}
                                      sx={{ cursor: 'pointer' }}
                                    >
                                      <Download />
                                    </IconButton>
                                  </Tooltip>
                                </PermissionComponent>
                                <PermissionComponent role="Edit_LabelOrder_Client">
                                  <Tooltip title="Editar" arrow>
                                    <IconButton
                                      onClick={() => handleEdit(row.movimentationOrderId)}
                                      sx={{ cursor: 'pointer' }}
                                    >
                                      <ModeEditOutlineIcon />
                                    </IconButton>
                                  </Tooltip>
                                </PermissionComponent>
                              </StyledTableCell>

                              <StyledTableCell align="left">
                                {row.movimentationOrderNumberBihands}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.movimentationOrderNumberOtherSystem}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.subCompanyNameFrom}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {new Date(row.createRequestMovimentationOrderDate).toLocaleString()}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {returnStatus(row.movimentationOrderStatus)}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    )}
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={totalData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </AccordionDetails>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default LabelOrderClient
