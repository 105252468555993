export const PrintTagSerialVIEW = 'PrintTagSerialVIEW'

export const TransferVIEW = 'TransferVIEW'
export const TransferCREATE = 'TransferCREATE'
export const TransferEDIT = 'TransferEDIT'
export const TransferITEM = 'TransferITEM'

export const ExpeditionVIEW = 'ExpeditionVIEW'
export const ExpeditionCREATE = 'ExpeditionCREATE'
export const ExpeditionDELETE = 'ExpeditionDELETE'
export const ExpeditionITEM = 'ExpeditionITEM'

export const AlocationParts = 'AlocationParts'
export const AlocationPartsVIEW = 'AlocationPartsVIEW'
export const AlocationPartsCREATE = 'AlocationPartsCREATE'
export const AlocationPartsEDIT = 'AlocationPartsEDIT'
export const AlocationPartsDELETE = 'AlocationPartsDELETE'

export const AlocationStockVIEW = 'AlocationStockVIEW'
export const AlocationStockCREATE = 'AlocationStockCREATE'
export const AlocationStockEDIT = 'AlocationStockEDIT'
export const AlocationStockDELETE = 'AlocationStockDELETE'

export const Dissassociation = 'Dissassociation'
export const RoadMap = 'RoadMap'
// export const DissassociationVIEW = "DissassociationVIEW"
// export const DissassociationCREATE = "DissassociationCREATE"
// export const DissassociationEDIT = "DissassociationEDIT"
// export const DissassociationDELETE = "DissassociationDELETE"

export const AnthiTheft = 'AnthiTheft'

export const CountStock = 'CountStock'
export const CountStockVIEW = 'CountStockVIEW'
export const CountStockCREATE = 'CountStockCREATE'
export const CountStockEDIT = 'CountStockEDIT'
//export const CountStockDELETE = "CountStockDELETE"

export const InventoryBalanceVIEW = 'InventoryBalanceVIEW'
export const ReportHistoryproductVIEW = 'ReportHistoryproductVIEW'

export const StockEntryVIEW = 'StockEntryVIEW'
export const StockEntryCREATE = 'StockEntryCREATE'
export const StockEntryEDIT = 'StockEntryEDIT'
//export const StockEntryDELETE = "StockEntryDELETE"

export const OutStockVIEW = 'OutStockVIEW'
export const OutStockCREATE = 'OutStockCREATE'
export const OutStockEDIT = 'OutStockEDIT'
//export const OutStockDELETE = "OutStockDELETE"

export const PrinterZplVIEW = 'PrinterZplVIEW'
export const PrinterZplCREATE = 'PrinterZplCREATE'
export const PrinterZplEDIT = 'PrinterZplEDIT'
export const PrinterZplDELETE = 'PrinterZplDELETE'

//export const Vendor = "Vendor"
export const VendorVIEW = 'VendorVIEW'
// export const VendorCREATE = "VendorCREATE"
// export const VendorEDIT = "VendorEDIT"
// export const VendorDELETE = "VendorDELETE"

export const LabelVIEW = 'LabelVIEW'
export const LabelCREATE = 'LabelCREATE'
export const LabelEDIT = 'LabelEDIT'
export const LabelDELETE = 'LabelDELETE'

export const ConferenceVIEW = 'ConferenceVIEW'
// export const ConferenceCREATE = "ConferenceCREATE"
// export const ConferenceEDIT = "ConferenceEDIT"
// export const ConferenceDELETE = "ConferenceDELETE"

export const PrinterZPLDefaultVIEW = 'PrinterZPLDefaultVIEW'
export const PrinterZPLDefaultCREATE = 'PrinterZPLDefaultCREATE'
export const PrinterZPLDefaultEDIT = 'PrinterZPLDefaultEDIT'
export const PrinterZPLDefaultDELETE = 'PrinterZPLDefaultDELETE'

export const ParametersGroupVIEW = 'ParametersGroupVIEW'
export const ParametersGroupCREATE = 'ParametersGroupCREATE'
export const ParametersGroupEDIT = 'ParametersGroupEDIT'
export const ParametersGroupDELETE = 'ParametersGroupDELETE'

export const ParametersVIEW = 'ParametersVIEW'
export const ParametersCREATE = 'ParametersCREATE'
export const ParametersEDIT = 'ParametersEDIT'
export const ParametersDELETE = 'ParametersDELETE'

export const EmailParametersVIEW = 'EmailParametersVIEW'
export const EmailParametersCREATE = 'EmailParametersCREATE'
export const EmailParametersEDIT = 'EmailParametersEDIT'
export const EmailParametersDELETE = 'EmailParametersDELETE'

export const ElearningVIEW = 'ElearningVIEW'
export const ElearningCREATE = 'ElearningCREATE'
export const ElearningEDIT = 'ElearningEDIT'
export const ElearningDELETE = 'ElearningDELETE'

export const IntegrationDefaultVIEW = 'IntegrationDefaultVIEW'
export const IntegrationDefaultCREATE = 'IntegrationDefaultCREATE'
export const IntegrationDefaultEDIT = 'IntegrationDefaultEDIT'
export const IntegrationDefaultDELETE = 'IntegrationDefaultDELETE'

export const TagLogistic = 'TagLogistics'
export const TagLogisticCreate = 'TagLogisticsCreate'

export const RoleVIEW = 'RoleVIEW'
export const RoleCREATE = 'RoleCREATE'
export const RoleEDIT = 'RoleEDIT'
export const RoleDELETE = 'RoleDELETE'

export const SegmentVIEW = 'SegmentVIEW'
export const SegmentCREATE = 'SegmentCREATE'
export const SegmentEDIT = 'SegmentEDIT'
export const SegmentDELETE = 'SegmentDELETE'

export const SegmentGroupView = 'SegmentGroupView'
export const SegmentGroupCreate = 'SegmentGroupCreate'
export const SegmentGroupEdit = 'SegmentGroupEdit'
export const SegmentGroupDelete = 'SegmentGroupDelete'
export const SegmentGroupRole = 'SegmentGroupRole'
export const SegmentGroupRoleDelete = 'SegmentGroupRoleDelete'

export const UpdateView = 'UpdateView'
export const UpdateCreate = 'UpdateCreate'
export const UpdateDelete = 'UpdateDelete'

export const PlanView = 'PlanView'
export const PlanCreate = 'PlanCreate'
export const PlanEDIT = 'PlanEDIT'
export const PlanDELETE = 'PlanDELETE'

export const CompanyView = 'CompanyView'
export const COMPANYCreate = 'CompanyCreate'
export const CompanyEDIT = 'CompanyEDIT'
export const CompanyDELETE = 'CompanyDELETE'

export const EquipmentAutomaticVIEW = 'EquipmentAutomaticVIEW'
export const EquipmentAutomaticCREATE = 'EquipmentAutomaticCREATE'
export const EquipmentAutomaticEDIT = 'EquipmentAutomaticEDIT'
export const EquipmentAutomaticDELETE = 'EquipmentAutomaticDELETE'

export const ReasonForOrderr = 'ReasonForOrder'
export const ReasonForOrderCREATE = 'ReasonForOrderCREATE'
export const ReasonForOrderEDIT = 'ReasonForOrderEDIT'
export const ReasonForOrderDELETE = 'ReasonForOrderDELETE'

export const LaundryConfigurationVIEW = 'LaundryConfigurationVIEW'
export const LaundryConfigurationCREATE = 'LaundryConfigurationCREATE'
export const LaundryConfigurationEDIT = 'LaundryConfigurationEDIT'
export const LaundryConfigurationDELETE = 'LaundryConfigurationDELETE'

export const PrinterConfigurationVIEW = 'PrinterConfigurationVIEW'
export const PrinterConfigurationCREATE = 'PrinterConfigurationCREATE'
export const PrinterConfigurationEDIT = 'PrinterConfigurationEDIT'
export const PrinterConfigurationDELETE = 'PrinterConfigurationDELETE'

export const PrinterLaundryView = 'PrinterLaundryView'
export const PrinterLaundryCreate = 'PrinterLaundryCreate'
export const PrinterLaundryEdit = 'PrinterLaundryEdit'
export const PrinterLaundryDelete = 'PrinterLaundryDelete'

export const LaundryZplVIEW = 'LaundryZplVIEW'
export const LaundryZplCREATE = 'LaundryZplCREATE'
export const LaundryZplEDIT = 'LaundryZplEDIT'
export const LaundryZplDELETE = 'LaundryZplDELETE'

export const ProcessVIEW = 'ProcessVIEW'
export const ProcessCREATE = 'ProcessCREATE'
export const ProcessEDIT = 'ProcessEDIT'
export const ProcessDELETE = 'ProcessDELETE'

export const CarWeightt = 'CarWeight'
export const CarWeightCREATE = 'CarWeightCREATE'
export const CarWeightEDIT = 'CarWeightEDIT'
export const CarWeightDELETE = 'CarWeightDELETE'

export const MovBreakVIEW = 'MovBreakView'
export const MovBreakCREATE = 'MovBreakCreate'
export const MovBreakEDIT = 'MovBreakEdit'
export const MovBreakDELETE = 'MovBreakDelete'

export const Production = 'Production'
export const ProductionCreate = 'ProductionCreate'
export const ProductionEdit = 'ProductionEdit'
export const ProductionDelete = 'ProductionDelete'

export const UploadProducts = 'UploadProducts'

export const MOVIMENTATION = 'MOVIMENTATION'

export const REPORTS = 'REPORTS'

export const Printer = 'Printer'

export const Configuration = 'Configuration'

export const MonitoringEquipment = 'MonitoringEquipment'
export const MonitoringEquipmentView = 'MonitoringEquipmentView'

export const ConfigurationSystem = 'ConfigurationSystem'

export const MovBreakKanban = 'MovBreakKanban'

export const LOCATIONPRODUCT = 'LOCATIONPRODUCT'

export const ACCESSPRODUCT = 'ACCESSPRODUCT'

export const SalesOrder = 'SalesOrder'

export const ReportLocationVIEW = 'ReportLocationVIEW'

export const LocationProductVIEW = 'LocationProductVIEW'

export const Product = 'Product'
export const FIELDS_BARCODE = 'FIELDS_BARCODE'
export const FIELD_DATE_VALID = 'FIELD_DATE_VALID'
export const FIELDS_LAB = 'FIELDS_LAB'
export const ProductVIEW = 'ProductVIEW'
export const ProductCREATE = 'ProductCREATE'
export const ProductEDIT = 'ProductEDIT'
export const ProductDELETE = 'ProductDELETE'

export const TimeExpirationVIEW = 'TimeExpirationVIEW'
export const TimeExpirationCREATE = 'TimeExpirationCREATE'
export const TimeExpirationEDIT = 'TimeExpirationEDIT'
export const TimeExpirationDELETE = 'TimeExpirationDELETE'

export const LinkProductsVIEW = 'LinkProductsVIEW'
export const LinkProductsCREATE = 'LinkProductsCREATE'
export const LinkProductsEDIT = 'LinkProductsEDIT'
export const LinkProductsDELETE = 'LinkProductsDELETE'

export const ProductAssociationVIEW = 'ProductAssociationVIEW'
export const ProductAssociationCREATE = 'ProductAssociationCREATE'
export const ProductAssociationEDIT = 'ProductAssociationEDIT'
export const ProductAssociationDELETE = 'ProductAssociationDELETE'

export const DepositVIEW = 'DepositVIEW'
export const DepositCREATE = 'DepositCREATE'
export const DepositEDIT = 'DepositEDIT'
export const DepositDELETE = 'DepositDELETE'

export const QuantityBoxVIEW = 'QuantityBoxVIEW'
export const QuantityBoxCREATE = 'QuantityBoxCREATE'
export const QuantityBoxEDIT = 'QuantityBoxEDIT'
export const QuantityBoxDELETE = 'QuantityBoxDELETE'

export const LOTE = 'LOTE'

export const FaccaoVIEW = 'FaccaoVIEW'
export const FaccaoCREATE = 'FaccaoCREATE'
export const FaccaoEDIT = 'FaccaoEDIT'
export const FaccaoDELETE = 'FaccaoDELETE'

export const EmployeeVIEW = 'EmployeeVIEW'
export const EmployeeCREATE = 'EmployeeCREATE'
export const EmployeeEDIT = 'EmployeeEDIT'
export const EmployeeDELETE = 'EmployeeDELETE'

export const SubCompanyVIEW = 'SubCompanyVIEW'
export const SubCompanyCREATE = 'SubCompanyCREATE'
export const SubCompanyEDIT = 'SubCompanyEDIT'
export const SubCompanyDELETE = 'SubCompanyDELETE'

export const IntegrationCredencialVIEW = 'IntegrationCredencialVIEW'
export const IntegrationCredencialCREATE = 'IntegrationCredencialCREATE'
export const IntegrationCredencialEDIT = 'IntegrationCredencialEDIT'
export const IntegrationCredencialDELETE = 'IntegrationCredencialDELETE'

export const IntegrationAssociationVIEW = 'IntegrationAssociationVIEW'
export const IntegrationAssociationCREATE = 'IntegrationAssociationCREATE'
export const IntegrationAssociationEDIT = 'IntegrationAssociationEDIT'
export const IntegrationAssociationDELETE = 'IntegrationAssociationDELETE'

export const EquipmentVIEW = 'EquipmentVIEW'
export const EquipmentCREATE = 'EquipmentCREATE'
export const EquipmentEDIT = 'EquipmentEDIT'
export const EquipmentDELETE = 'EquipmentDELETE'

export const PICKTOLIGHT = 'PICKTOLIGHT'

export const PRODUCAO = 'PRODUCAO'

export const WMS = 'WMS'

export const ZPLLogistica = 'ZPLLogistica'

export const ClientView = 'ClientView'
export const ClientCreate = 'ClientCreate'
export const ClientDelete = 'ClientDelete'
export const ClientEdit = 'ClientEdit'

export const RoutesVIEW = 'RoutesVIEW'
export const RoutesCREATE = 'RoutesCREATE'
export const RoutesDELETE = 'RoutesDELETE'
export const RoutesEDIT = 'RoutesEDIT'

export const DRIVER = 'DRIVER'
export const EquipmentSoldVIEW = 'EquipmentVIEW'
export const EquipmentSoldCREATE = 'EquipmentCREATE'
export const EquipmentSoldEDIT = 'EquipmentEDIT'
export const EquipmentSoldDELETE = 'EquipmentDELETE'

export const ErpIntegracao = 'ERP_INTEGRACAO'

export const EmailGroupVIEW = 'EquipmentVIEW'
export const EmailGroupCREATE = 'EquipmentCREATE'
export const EmailGroupEDIT = 'EquipmentEDIT'
export const EmailGroupDELETE = 'EquipmentDELETE'
