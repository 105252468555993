import { useCallback, useContext, useEffect, useState } from 'react'
import {
  deleteEmployeeRoleService,
  getAllSegmentRoleService,
  getEmployeeRoleService,
  getUpdateRoleService,
} from '../../../services/configServices'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  Paper,
  Backdrop,
  CircularProgress,
  Select,
  MenuItem,
  TextField,
  InputLabel,
} from '@mui/material'
import { Context } from '../../../context/AuthContext'

const EmployeeRole = ({ setEditing, setAlerta, setSeverity, setTitle, employeeId }) => {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const [nameEmployee, setNameEmployee] = useState('')
  const [employeeRole, setEmployeeRole] = useState([])
  const [pagination, setPagination] = useState({})
  const [employeeOrder, setEmployeeOrder] = useState(false)
  const [allRoles, setAllRoles] = useState([])
  const [roleId, setRoleId] = useState('')
  const [roleIdDelete, setRoleIdDelete] = useState('')
  const [modalAdd, setModalAdd] = useState(false)
  const [modalDel, setModalDel] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getEmployeeRole = async () => {
    setLoading(true)
    try {
      const response = await getEmployeeRoleService(token, nameUrl, employeeId)
      setEmployeeRole(response.data.rolesUserList)
      setNameEmployee(response.data.employeeModel.nameEmployee)
      setPagination(response.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const addEmployeeRole = useCallback(async () => {
    setLoading(true)
    setModalAdd(false)
    const data = {
      UserId: employeeId,
      SegmentGroupId: roleId,
    }

    try {
      await getUpdateRoleService(token, nameUrl, data)
      setLoading(false)
      setAlerta(true)
      setSeverity('success')
      setTitle('Regra adicionada com sucesso!')
      getEmployeeRole()
    } catch (err) {
      console.log(err)
      setLoading(false)
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao adicionar, verifique os dados e tente novamente.')
    }
  }, [employeeId, roleId])

  const deleteEmployeeRole = useCallback(async () => {
    setLoading(true)
    setModalDel(false)
    const employeeRole = {
      userId: employeeId,
      rolesId: roleIdDelete,
    }

    try {
      await deleteEmployeeRoleService(token, nameUrl, employeeRole)
      setLoading(false)
      setAlerta(true)
      setSeverity('success')
      setTitle('Regra excluída com sucesso!')
      getEmployeeRole()
    } catch (err) {
      console.log(err)
      setLoading(false)
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao excluir, tente novamente.')
    }
  }, [employeeId, roleIdDelete])

  const getAllRoles = useCallback(async () => {
    try {
      const response = await getAllSegmentRoleService(token, nameUrl, subCompany[0].companyId)
      setAllRoles(response.data)
      console.log(response)
    } catch (err) {
      console.log(err)
    }
  }, [employeeOrder])

  useEffect(() => {
    getEmployeeRole()
    getAllRoles()
  }, [employeeId])

  const handleDelete = id => {
    setRoleIdDelete(id)
    setModalDel(true)
  }

  return (
    <>
      <Box>
        <Dialog
          open={modalAdd}
          onClose={() => setModalAdd(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Adicionar permissão'}</DialogTitle>
          <DialogContent>
            <Box width={{ xs: '200px', sm: '250px', md: '500px', lg: '500px', xl: '500px' }}>
              <InputLabel id="demo-simple-select-label">Regra</InputLabel>
              <Select
                label="Regra"
                value={roleId}
                onChange={values => setRoleId(values.target.value)}
                sx={{ width: '100%' }}
              >
                {allRoles.map((item, index) => {
                  return (
                    <MenuItem value={item.segmentGroupId} key={index}>
                      {item.segmentGroupName}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setModalAdd(false)}>Fechar</Button>
            <Button onClick={addEmployeeRole} autoFocus>
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={modalDel}
          onClose={() => setModalDel(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
            {'Deseja realmente excluir essa regra?'}
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => setModalDel(false)}>Não</Button>
            <Button onClick={deleteEmployeeRole} autoFocus>
              Sim
            </Button>
          </DialogActions>
        </Dialog>
        <TextField
          sx={{ width: '100%', mt: 2 }}
          value={nameEmployee}
          onChange={values => setNameEmployee(values.target.value)}
          id="outlined-basic"
          label="Nome do Colaborador"
          variant="outlined"
          type="text"
          disabled
        />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="contained" onClick={() => setModalAdd(true)}>
            + Adicionar Regra
          </Button>
          <Button sx={{ ml: 1 }} variant="contained" onClick={() => setEditing(false)}>
            Cancelar
          </Button>
        </Box>
        {employeeRole.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="left">Nome</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {employeeRole
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left">
                        <DeleteForeverIcon
                          onClick={() => handleDelete(row.rolesId)}
                          sx={{ cursor: 'pointer' }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.rolesName}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={employeeRole.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default EmployeeRole
