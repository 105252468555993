import React, { useContext } from 'react'
import { useEffect, useState } from 'react'
import { getMovimentationRoadMapService, handleRoadMap } from '../../../services/reportServices'
import { Context } from '../../../context/AuthContext'
import * as XLSX from 'xlsx'
import HelpIcon from '@mui/icons-material/Help'

import {
  Alert,
  Backdrop,
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'

//icones react-icons
import { AiOutlineFileExcel } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const RoadMap = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const [orders, setOrders] = useState()
  const [loading, setLoading] = useState(false)
  const [orderSelect, setOrderSelect] = useState('')
  const token = localStorage.getItem('token')
  const [paramRoadMap, setParamRoadMap] = useState([])

  const [pagination, setPagination] = useState({})
  const [roadMap, setRoadMap] = useState(10)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')

  // apagar daqui pra baixo se der erro e apagar o import xlsx

  const [progressCount, setProgressCount] = useState(0)
  const [downloadMessageCount, setDownloadMessageCount] = useState('Pronto')
  const [downloadingRoute, setDownloadingRoute] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [imageError, setImageError] = useState(false)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const downloadDataRoute = async () => {
    setDownloadMessageCount('Inicializando')
    setDownloadingRoute(true)

    finishDownloadCount(roadMap.length)
  }

  const finishDownloadCount = async pages => {
    let result = []

    for (let i = 0; i <= pages; i++) {
      setDownloadMessageCount(`Baixando ${i} de ${pages}...`)
      setProgressCount((i / pages) * 100)
    }
    const movimentationOrderRoute = {
      subCompanyId: subCompanyId,
      movimentationOrderRouteId: orderSelect,
    }
    const resp = await getMovimentationRoadMapService(token, nameUrl, movimentationOrderRoute)
    result = result.concat(resp.data)
    setDownloadMessageCount(`Seu arquivo está pronto.`)
    setDownloadingRoute(false)
    const relatorioFinal = []
    console.log('result ', result)
    result.map(item => {
      relatorioFinal.push({
        'CPF/CNPJ': item.documentCPFCNPJ,
        'Nome da Empresa': item.companyName,
        Endereço: item.address,
        CEP: item.cep,
        Cidade: item.city,
        'Nome do Destinatário': item.recipientName,
        Diretório: item.directory,
        Descrição: item.description,
        'Pedido em Rota de Expedição': item.movimentationOrderRouteShipping,
        Quantidade: item.quantityTotal,
        Entrega: item.delivery,
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório de contagem')
    XLSX.writeFile(workbook, `Relatório de contagem.xlsx`)
    setProgressCount(0)
  }

  const getRoadMap = async () => {
    setLoading(true)

    const movimentationOrderRoute = {
      subCompanyId: subCompanyId,
      movimentationOrderRouteId: orderSelect,
    }
    try {
      const response = await getMovimentationRoadMapService(token, nameUrl, movimentationOrderRoute)
      if (response.data.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setRoadMap(response.data)
        setSeverity('error')
      } else {
        setRoadMap(response.data)
        setPagination(response.data)
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const fetchData = async values => {
    setSubCompanyId(values)
    const subCompanyId = values
    try {
      const response = await handleRoadMap(token, nameUrl, subCompanyId)
      setOrders(response.data)
    } catch (err) {
      console.log(err)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  useEffect(() => {
    if (subCompanyId) {
      fetchData(subCompanyId)
    }
  }, [subCompanyId])

  const handleClose = () => {
    setAlerta(false)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={downloadingRoute}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessageCount}</DialogTitle>
          </Box>
        </Dialog>
        <ViewElearning
          pageName={'ReportCountStock'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Relatório de Roteiro</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon onClick={() => setViewElearning(true)} />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 2,
            mt: 2,
          }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <SelectSubCompany
                value={subCompanyId}
                onChange={e => {
                  fetchData(e.target.value)
                }}
              />
            </Grid>
            {orders && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <InputLabel id="demo-simple-select-label">Relatório de Roteiro</InputLabel>
                <Select
                  label="Selecione o Roteiro"
                  value={orderSelect}
                  onChange={value => setOrderSelect(value.target.value)}
                  sx={{ width: '100%' }}
                >
                  {orders.map((item, index) => {
                    return (
                      <MenuItem value={item.movimentationOrderRouteId} key={index}>
                        {item.movimentationOrderRouteShipping}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Grid>
            )}
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Button variant="contained" onClick={getRoadMap} disabled={!orderSelect}>
                Pesquisar
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            mb: 2,
          }}
        >
          <Typography variant="h6">Resultados</Typography>
          {roadMap.length > 0 && (
            <Button
              variant="contained"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: ' space-between',
                fontSize: '.9em',
                width: '6.5rem',
              }}
              onClick={downloadDataRoute}
            >
              Excel <AiOutlineFileExcel size="2em" />
            </Button>
          )}
        </Box>
        {roadMap.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Pedido em Rota de Expedição</StyledTableCell>
                  <StyledTableCell align="center">CPF/CNPJ</StyledTableCell>
                  <StyledTableCell align="center">Nome do Destinatário</StyledTableCell>
                  <StyledTableCell align="center">Nome da Empresa</StyledTableCell>
                  <StyledTableCell align="center">Endereço</StyledTableCell>
                  <StyledTableCell align="center">CEP</StyledTableCell>
                  <StyledTableCell align="center">Cidade</StyledTableCell>
                  <StyledTableCell align="center">Diretório</StyledTableCell>
                  <StyledTableCell align="center">Descrição</StyledTableCell>
                  <StyledTableCell align="center">Quantidade</StyledTableCell>
                  <StyledTableCell align="center">Entrega</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {roadMap
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        {row.movimentationOrderRouteShipping}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.documentCPFCNPJ}</StyledTableCell>
                      <StyledTableCell align="center">{row.recipientName}</StyledTableCell>
                      <StyledTableCell align="center">{row.companyName}</StyledTableCell>
                      <StyledTableCell align="center">{row.address}</StyledTableCell>
                      <StyledTableCell align="center">{row.cep}</StyledTableCell>
                      <StyledTableCell align="center">{row.city}</StyledTableCell>
                      <StyledTableCell align="center">
                        <Box
                          height="150px"
                          width="150px"
                          overflow="hidden"
                          position="relative"
                          borderRadius="10px"
                        >
                          <a href={row.directory} target="_blank" rel="noreferrer">
                            <CardMedia
                              component="img"
                              alt="Sem  Imagem"
                              image={row.directory}
                              sx={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                objectFit: 'cover',
                              }}
                            />
                          </a>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.description}</StyledTableCell>

                      <StyledTableCell align="center">{row.quantityTotal}</StyledTableCell>
                      <StyledTableCell align="center">{row.delivery}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={paramRoadMap.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default RoadMap
