import { Delete, Edit, ExpandMore, Search } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { styled } from '@mui/system'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { v4 as uuidv4 } from 'uuid'
import { Context } from '../../../context/AuthContext'
import PermissionComponent from '../../../permissions'
import { getClientGetAll } from '../../../services/clientService'
import { searchProductService, sendNewCountStock } from '../../../services/productService'
import { formatBRL } from '../../../utils/formatBRL'
import ClientPCreate from '../../Create/ClientCreate'
import CreateRFID from '../../Create/CreateRFID'
import { useDebounce } from '../Product/Components/useDebounce'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${TableCell.head}`]: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
  },
  [`&.${TableCell.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const PdvClient = () => {
  const token = localStorage.getItem('token')
  const contentRef = useRef()
  const [showCoupon, setShowCoupon] = useState(false)
  const { subCompany, nameUrl, company } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [subCompanyCNPJ, setSubCompanyCNPJ] = useState('')
  const [productsWithRfid, setProductsWithRfid] = useState([])
  const [codigoVenda, setCodigoVenda] = useState('')
  const { debounce } = useDebounce(1000, true)
  const [products, setProducts] = useState([])
  const [productIdToEdit, setProductIdToEdit] = useState('')
  const [showChangePriceInput, setShowChangePriceInput] = useState(false)
  const [priceInputValue, setPriceInputValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')
  const [priceDiscountValue, setPriceDiscountValue] = useState('')
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)
  const [clientSelected, setClientSelected] = useState({})
  const [client, setClient] = useState({})
  const [clients, setClients] = useState([])
  const [alerta, setAlerta] = useState(false)
  const [searchProduct, setSearchProduct] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [accordionCadastrarExpanded, setAccordionCadastrarExpanded] = useState(false)
  const [accordionRfidExpanded, setAccordionRfidExpanded] = useState(false)

  const somaPrecosProdutosListados = useMemo(() => {
    const precoTotal = productsWithRfid.reduce((totalPrice, currentProduct) => {
      return (totalPrice += currentProduct.price * currentProduct.quantity)
    }, 0)

    return precoTotal
  }, [productsWithRfid])

  const handleOpenCoupon = () => {
    if (productsWithRfid.length === 0) {
      setResponseMessage('É necessário cadastrar ao menos um RFID')
      return
    }
    const newPurchaseCode = uuidv4().split('-')
    setCodigoVenda(newPurchaseCode[0])
    setShowCoupon(true)
  }

  const receiveNewSerials = async () => {
    const serialsArray = productsWithRfid.map(item => item.serial)
    const receiveNewDto = {
      serial: serialsArray,
      subCompanyId: subCompanyId,
    }

    try {
      setLoading(true)
      await sendNewCountStock(token, nameUrl, receiveNewDto)
      setResponseMessage('Informações enviadas com sucesso.')
      setSeverity('success')
      handlePrint()
    } catch (err) {
      console.log(err)
      setResponseMessage(err.response.data.message ?? 'Erro ao enviar informações.')
      setSeverity('error')
    } finally {
      setLoading(false)
    }
  }

  const handleGetAllProductsSearch = async () => {
    setIsLoading(true)
    const productData = {
      companyId: company.companyId,
      limit: 10,
      offset: 0,
      order: 'desc',
      SearchWord: searchProduct,
    }
    try {
      searchProductService(token, nameUrl, productData).then(response => {
        const data = response.data.result
        setProducts(data)
      })
    } catch (error) {
      setResponseMessage('Erro ao buscar produtos')
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handlePrint = useReactToPrint({
    contentRef,
    documentTitle: 'Cupom não fiscal',
  })

  const handleEdit = itemToEdit => {
    const id = itemToEdit.productId
    setProductIdToEdit(id)
    setShowChangePriceInput(prev => !prev)
    setPriceInputValue(itemToEdit.price)
  }

  const handleDelete = itemToDelete => {
    const updatedProducts = productsWithRfid.filter(product => product !== itemToDelete)
    setProductsWithRfid(updatedProducts)
    setResponseMessage('Produto removido com sucesso.')
    setSeverity('success')
  }

  const handleChangeProductPrice = e => {
    const value = Number(e.target.value.replace(/[^0-9]/g, ''))
    setPriceInputValue(value)
  }

  const handleSetNewPriceToProduct = product => {
    const mappedProduct = productsWithRfid.find(item => item.productId === product.productId)

    const newProductsArray = productsWithRfid.map(item => {
      if (item.productId === mappedProduct.productId) {
        return { ...item, price: priceInputValue }
      }

      return item
    })

    setProductsWithRfid(newProductsArray)
    setShowChangePriceInput(false)
  }

  useEffect(() => {
    if (company.companyId) {
      debounce(() => {
        handleGetAllProductsSearch()
      })
    }
  }, [])

  useEffect(() => {
    const getClient = async () => {
      const result = await getClientGetAll(token, nameUrl, subCompanyId)
      setClients(result.data)
    }

    getClient()
  }, [subCompanyId])

  const fetchClientData = async subCompanyId => {
    if (!subCompanyId) {
      return
    }

    setSubCompanyId(subCompanyId)
    setLoading(true)
    try {
      // const response = await getClientSearch(token, nameUrl, subCompanyId, search)
      const response = await getClientGetAll(token, nameUrl, subCompanyId)

      if (response.data.length === 0) {
        setResponseMessage('Não existem dados de clientes para essa consulta.')
        setSeverity('error')
        setAccordionCadastrarExpanded(true)
      } else {
        setClients(response.data)
        setAccordionCadastrarExpanded(false)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  return (
    <Box sx={{ mt: 5.5 }}>
      <Typography variant="h4" gutterBottom>
        Ponto de Venda (PDV)
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <InputLabel id="demo-simple-select-label">Filial</InputLabel>
          <Select
            label="Filial Saída"
            value={subCompanyId}
            onChange={values => {
              const cnpj = subCompany.find(item => item.subCompanyId === values.target.value)
              setSubCompanyCNPJ(cnpj.cnpjCompany, 'cnsda')
              setSubCompanyId(values.target.value)
            }}
            sx={{ width: '100%' }}
          >
            {subCompany.map((item, index) => (
              <MenuItem value={item.subCompanyId} key={index}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Cliente
          </Typography>
          <Autocomplete
            disablePortal
            id="lista-clientes"
            options={clients}
            autoHighlight
            disabled={isLoading || !subCompanyId}
            autoSelect
            value={client}
            loading={searching}
            loadingText="Procurando..."
            noOptionsText="Nenhum cliente"
            getOptionLabel={option => option.name || ''}
            renderOption={(props, option) => (
              <li {...props} key={option.value}>
                {option.name}
              </li>
            )}
            renderInput={params => <TextField {...params} label="Procurar cliente" />}
            onInputChange={(e, value) => {
              setSearch(value)
            }}
            onChange={(e, value) => {
              if (value === null) {
                setClient(null)
                setClientSelected(null)
              } else {
                setClient(value)
                setClientSelected(value.id)
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Descontos
          </Typography>
          <Box>
            <TextField
              autoFocus
              fullWidth
              variant="outlined"
              value={priceDiscountValue}
              disabled={isLoading || !subCompanyId}
              onChange={e => setPriceDiscountValue(e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={12} gap={12}>
          <Accordion
            expanded={accordionCadastrarExpanded}
            onChange={() => setAccordionCadastrarExpanded(!accordionCadastrarExpanded)}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => setAccordionCadastrarExpanded(!accordionCadastrarExpanded)}
            >
              <Typography>{'Cadastrar Cliente'}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PermissionComponent role="Create_Client">
                <ClientPCreate
                  setExpanded={setAccordionCadastrarExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setResponseMessage}
                  handleFetchData={fetchClientData}
                />
              </PermissionComponent>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} md={12} gap={12}>
          <Accordion
            expanded={accordionRfidExpanded}
            onChange={() => setAccordionRfidExpanded(!accordionRfidExpanded)}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              onClick={() => setAccordionRfidExpanded(!accordionRfidExpanded)}
            >
              <Typography>Cadastrar RFID</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CreateRFID
                setExpanded={setAccordionRfidExpanded}
                subCompanyId={subCompanyId}
                setResponseMessage={setResponseMessage}
                productsWithRfid={productsWithRfid}
                setProductsWithRfid={setProductsWithRfid}
                setLoading={setLoading}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card variant="outlined">
            <CardContent sx={{ p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Typography variant="h6" gutterBottom>
                    Produtos
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      paddingY: '12px',
                      gap: '12px',
                    }}
                  >
                    <TextField
                      sx={{ width: '100%' }}
                      disabled={isLoading || !subCompanyId}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                        endAdornment: isLoading && (
                          <InputAdornment position="end">
                            <CircularProgress color="info" size={20} />
                          </InputAdornment>
                        ),
                      }}
                      label="Pesquisar"
                      value={searchProduct}
                      onChange={e => setSearchProduct(e.target.value)}
                    />
                    <Button variant="contained" onClick={handleOpenCoupon}>
                      Fechar carrinho
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">Nome</StyledTableCell>
                          <StyledTableCell align="center">Preço</StyledTableCell>
                          <StyledTableCell align="center">Quantidade</StyledTableCell>
                          <StyledTableCell align="center">Total</StyledTableCell>
                          <StyledTableCell align="center">Ações</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {isLoading ? (
                        Array.from({ length: 10 }).map((_, rowIndex) => (
                          <TableRow key={rowIndex}>
                            {Array.from({ length: 5 }).map((_, colIndex) => (
                              <TableCell key={colIndex}>
                                <Skeleton animation="wave" />
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      ) : (
                        <TableBody>
                          {productsWithRfid.map(row => (
                            <StyledTableRow key={row.productId}>
                              <StyledTableCell component="th" scope="row" align="left">
                                {row.name || row.productName}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Box>
                                  {showChangePriceInput && row.productId === productIdToEdit ? (
                                    <TextField
                                      autoFocus
                                      value={priceInputValue}
                                      onChange={handleChangeProductPrice}
                                      onBlur={() => handleSetNewPriceToProduct(row)}
                                      sx={{
                                        fontSize: '14px',
                                        width: '120px',
                                        padding: 0,
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">R$</InputAdornment>
                                        ),
                                      }}
                                    />
                                  ) : (
                                    formatBRL(Number(row.price))
                                  )}
                                </Box>
                              </StyledTableCell>

                              <StyledTableCell align="center" width="200px">
                                {row.quantity}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {formatBRL(Number(row.price) * Number(row.quantity))}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  gap: '12px',
                                }}
                              >
                                <IconButton onClick={() => handleEdit(row)}>
                                  <Edit />
                                </IconButton>
                                <IconButton onClick={() => handleDelete(row)}>
                                  <Delete />
                                </IconButton>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                          <Box sx={{ padding: '12px', width: '100%', fontWeight: 'bold' }}>
                            Total: {formatBRL(somaPrecosProdutosListados)}
                          </Box>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {showCoupon && (
          <>
            <Grid item xs={12} md={12} ref={contentRef}>
              <Card variant="outlined">
                <CardContent sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                        Cupom não fiscal
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Box>
                        <strong>Nome da empresa:</strong> {company.name}
                      </Box>
                      <Box>
                        <strong>Vendedor:</strong> {company.email}
                      </Box>
                      <Box>
                        <strong>Código da venda:</strong> {codigoVenda}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="left">Nome</StyledTableCell>
                              <StyledTableCell align="center">Preço</StyledTableCell>
                              <StyledTableCell align="center">Quantidade</StyledTableCell>
                              <StyledTableCell align="center">Total</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {productsWithRfid.map(row => (
                              <StyledTableRow key={row.productId}>
                                <StyledTableCell component="th" scope="row">
                                  {row.name || row.productName}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <Box>{formatBRL(Number(row.price))}</Box>
                                </StyledTableCell>
                                <StyledTableCell align="center" width="200px">
                                  {row.quantity || 0}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {formatBRL(Number(row.price) * Number(row.quantity))}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                          <Box
                            sx={{
                              padding: '12px',
                              fontWeight: 'bold',
                            }}
                          >
                            Total: {formatBRL(somaPrecosProdutosListados)}
                          </Box>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Box sx={{ margin: 'auto', padding: '8px' }}>
              <Button variant="contained" onClick={receiveNewSerials}>
                Finalizar compra
              </Button>
            </Box>
          </>
        )}
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(responseMessage)}
        autoHideDuration={3000}
        onClose={() => setResponseMessage('')}
        message={responseMessage}
      />
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}

export default PdvClient
