// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonDocs{
    background-color: #011528;
    color: white;
    display:flex;
    align-items: center;
    justify-content: space-between;
    font-size: .9em;
    width:6.5rem;
    border:4px;
    border-radius: 5px;
    padding:4px;
    cursor:pointer;
}

.buttonDocs:hover{
    background-color: #011528;
}

.iconMore{
    color: rgb(89, 164, 214);
}`, "",{"version":3,"sources":["webpack://./src/assets/global.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;IACf,YAAY;IACZ,UAAU;IACV,kBAAkB;IAClB,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".buttonDocs{\n    background-color: #011528;\n    color: white;\n    display:flex;\n    align-items: center;\n    justify-content: space-between;\n    font-size: .9em;\n    width:6.5rem;\n    border:4px;\n    border-radius: 5px;\n    padding:4px;\n    cursor:pointer;\n}\n\n.buttonDocs:hover{\n    background-color: #011528;\n}\n\n.iconMore{\n    color: rgb(89, 164, 214);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
