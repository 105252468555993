import HelpIcon from '@mui/icons-material/Help'
import {
  Alert,
  Grid,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import '../../../assets/global.css'

//icones react-icons
import { AiFillFilePdf } from 'react-icons/ai'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'
import { getConferenceFinish } from '../../../services/requestServices'
import { pickToLightPDF } from '../../../services/pickToLightServices'
import { getCompanyService, removeDuplicated } from '../../../services/admServices'

const DuplicateRemover = () => {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const [loading, setLoading] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [viewElearning, setViewElearning] = useState(false)

  const [company, setCompany] = useState([])
  const [companyId, setCompanyId] = useState('')

  const handleGetCompany = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getCompanyService(token, nameUrl, 500, 0, 'desc')
      setCompany(response.data.result)

      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    handleGetCompany()
  }, [])

  const handleRemoveDuplicated = async () => {
    setLoading(true)
    try {
      const response = await removeDuplicated(token, nameUrl, companyId)
      console.log(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        {/* <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar> */}

        <ViewElearning
          pageName={'ReportDissassociation'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Remover duplicados</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={10}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
                <Select
                  value={companyId}
                  sx={{ width: '100%' }}
                  onChange={values => {
                    setCompanyId(values.target.value)
                  }}
                >
                  {company.map((item, index) => {
                    return (
                      <MenuItem value={item.companyId} key={item.companyId}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                sx={{
                  width: '100%',
                  py: 2,
                }}
                variant="contained"
                onClick={handleRemoveDuplicated}
              >
                Remover
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default DuplicateRemover
