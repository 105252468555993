import { useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
  Tooltip,
} from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ViewElearning from '../../ViewElearning'
import HelpIcon from '@mui/icons-material/Help'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import EquipmentSoldEdit from '../../Edit/EquipmentSoldEdit'
import EquipmentSoldCreate from '../../Create/EquipmentSoldCreate'
import { colorTableDefault } from '../../TemplateDefault'
import { getAllTemplateEquipment, deleteTemplateEquipment } from '../../../services/configServices'
import { Edit } from '@mui/icons-material'
import PermissionComponent from '../../../permissions'

const EquipmentSold = () => {
  const token = localStorage.getItem('token')
  const [equipmentList, setEquipmentList] = useState([])
  const { subCompany, userRoles, nameUrl } = useContext(Context)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('error')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const [viewElearning, setViewElearning] = useState(false)
  const [editing, setEditing] = useState(false)

  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [idEditing, setIdEditing] = useState('')
  const [originalList, setOriginalList] = useState([])
  const [loading, setLoading] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [deletedEquipmentSold, setdeletedEquipmentSold] = useState(null)

  const requestSearch = searched => {
    setEquipmentList(
      originalList.filter(item => item.name.toLowerCase().includes(searched.toLowerCase()))
    )
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const fetchData = async values => {
    setLoading(true)
    try {
      const data = {
        limit: 20,
        offset: 0,
        order: 'desc',
      }

      const response = await getAllTemplateEquipment(token, nameUrl, data)
      setEquipmentList(response.data.result)
      setOriginalList(response.data.result)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleEdit = id => {
    setIdEditing(id)
    setExpanded(true)
    setEditing(true)
    window.scrollTo(0, 0)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    try {
      const data = {
        templateEquipmentId: idDelete,
      }
      await deleteTemplateEquipment(token, nameUrl, data)
      setSeverity('success')
      setTitle('Equipamento deletado com sucesso!')

      setAlerta(true)
      setLoading(false)
      setdeletedEquipmentSold(idDelete)
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (deletedEquipmentSold) {
      setEquipmentList(prevList =>
        prevList.filter(group => group.templateEquipmentId !== deletedEquipmentSold)
      )
    }
  }, [deletedEquipmentSold])

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <ViewElearning pageName={'EmailGroup'} open={viewElearning} setOpen={setViewElearning} />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Equipamentos Vendidos</Typography>
          <IconButton onClick={() => setViewElearning(true)} sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon />
          </IconButton>
        </Box>

        {/* Criar  */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>{editing ? 'Editando Equipamento' : 'Cadastrar Equipamento'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="EquipmentSoldEdit">
                <EquipmentSoldEdit
                  setEditing={setEditing}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  equipmentId={idEditing}
                  handleFetchData={fetchData}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="EquipmentSoldCreate">
                <EquipmentSoldCreate
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={fetchData}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>
        <Box
          sx={{
            width: '100%',
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        ></Box>
        <TextField
          variant="outlined"
          placeholder="Procurar por Nome"
          type="search"
          onInput={e => requestSearch(e.target.value)}
        />
        <TableContainer component={Paper} sx={{ mt: '2rem' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" width="2%"></StyledTableCell>
                <StyledTableCell align="center" width="2%"></StyledTableCell>
                <StyledTableCell align="center">Imagem</StyledTableCell>
                <StyledTableCell align="center">Nome</StyledTableCell>
                <StyledTableCell align="center" width="20%">
                  Tipo do equipamento
                </StyledTableCell>
                <StyledTableCell align="center">Data de Criação</StyledTableCell>
                <StyledTableCell align="center" width="15%">
                  Ativo
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {equipmentList.length > 0 && (
              <TableBody>
                {equipmentList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        <PermissionComponent role="EquipmentSoldDELETE">
                          <Tooltip title="Apagar" arrow>
                            <IconButton
                              onClick={() => deleteAlert(row.templateEquipmentId)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <PermissionComponent role="EquipmentSoldEDIT">
                          <Tooltip title="Editar" arrow>
                            <IconButton
                              onClick={() => handleEdit(row.templateEquipmentId)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <img
                          src={row.imageUrl}
                          alt="Imagem"
                          style={{
                            width: '100px',
                            height: '100px',
                            borderRadius: '4px',
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.name}</StyledTableCell>
                      <StyledTableCell align="center">{row.typeEquipment}</StyledTableCell>
                      <StyledTableCell align="center">
                        {new Date(row.created).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.active ? 'Sim' : 'Não'}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            )}
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={equipmentList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default EquipmentSold
