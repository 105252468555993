import React, { useState, useEffect } from 'react'
import ReactApexChart from 'react-apexcharts'
import { startOfMonth, endOfMonth, eachDayOfInterval, format, isSameMonth } from 'date-fns'
import { Box, Button, CircularProgress } from '@mui/material'

const ColumnChartBasic = ({ tickets, filterDate, loadingError, loading, reloadHandler }) => {
  const [chartData, setChartData] = useState({
    series: [
      {
        name: 'Tickets',
        data: [],
      },
    ],
    options: {
      tooltip: {
        y: {
          formatter: function (val) {
            return Math.round(val).toString()
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      annotations: {
        points: [],
      },
    },
  })

  useEffect(() => {
    const groupTicketsByDay = () => {
      const groupedData = {}

      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const currentMonth = currentDate.getMonth() + 1
      const currentDay = currentDate.getDate()

      const firstDayOfMonth = startOfMonth(currentDate)

      const [providedYear, providedMonth] = filterDate.split('-').map(Number)

      let endDate

      // Se o filtro for para o mês e ano atuais
      if (providedYear === currentYear && providedMonth === currentMonth) {
        endDate = currentDay // Definir o dia final como o dia atual
      } else {
        endDate = endOfMonth(new Date(filterDate)).getDate()
      }

      const startDate = startOfMonth(new Date(filterDate))
      const datesOfMonth = eachDayOfInterval({
        start: startDate,
        end: new Date(providedYear, providedMonth - 1, endDate),
      })

      datesOfMonth.forEach(date => {
        const dayKey = format(date, 'dd')
        const formattedDate = `Dia - ${dayKey}`
        groupedData[formattedDate] = 0
      })

      tickets.forEach(ticket => {
        const createDate = new Date(ticket.createDate)
        const ticketYear = createDate.getFullYear()
        const ticketMonth = createDate.getMonth() + 1

        if (providedYear === currentYear && providedMonth === currentMonth) {
          // Verificar se a data do ticket está entre o primeiro dia do mês e o dia atual
          if (createDate >= firstDayOfMonth && createDate.getDate() <= endDate) {
            const dayKey = format(createDate, 'dd')
            const formattedDate = `Dia - ${dayKey}`
            if (groupedData[formattedDate]) {
              groupedData[formattedDate]++
            } else {
              groupedData[formattedDate] = 1
            }
          }
        } else if (isSameMonth(createDate, new Date(filterDate))) {
          const dayKey = format(createDate, 'dd')
          const formattedDate = `Dia - ${dayKey}`
          if (groupedData[formattedDate]) {
            groupedData[formattedDate]++
          } else {
            groupedData[formattedDate] = 1
          }
        }
      })

      const chartSeries = Object.entries(groupedData).map(([date, count]) => ({
        x: date,
        y: count,
      }))

      chartSeries.sort((a, b) => Number(a.x.split(' ')[2]) - Number(b.x.split(' ')[2]))

      const chartCategories = Object.keys(groupedData)

      const annotations = chartSeries.map(dataPoint => ({
        x: dataPoint.x,
        y: dataPoint.y,
        label: {
          borderColor: '#FF4560',
          style: {
            color: '#fff',
            background: '#FF4560',
          },
          text: dataPoint.y.toString(),
        },
      }))

      console.log(chartSeries, 'chartSersies')
      let nonZeroData = chartSeries.filter(dataPoint => {
        const dayOfMonth = parseInt(dataPoint.x.split(' ')[2])
        if (providedYear === currentYear && providedMonth === currentMonth) {
          return dayOfMonth <= currentDay
        } else {
          return dataPoint.y > 0
        }
      })

      // Se o mês e o ano forem diferentes do mês e ano atuais, mostrar todos os dias do mês
      if (providedYear !== currentYear || providedMonth !== currentMonth) {
        nonZeroData = chartSeries
      }

      // Atualizar as categorias e anotações baseadas nos dados filtrados
      const nonZeroCategories = nonZeroData.map(dataPoint => dataPoint.x)
      const nonZeroAnnotations = annotations.filter(dataPoint =>
        nonZeroCategories.includes(dataPoint.x)
      )

      setChartData(prevState => ({
        ...prevState,
        series: [
          {
            data: nonZeroData,
          },
        ],
        options: {
          ...prevState.options,
          xaxis: {
            ...prevState.options.xaxis,
            categories: nonZeroCategories,
          },
          annotations: {
            points: nonZeroAnnotations,
          },
        },
      }))
    }

    groupTicketsByDay()
  }, [tickets, filterDate])

  return (
    <>
      {loadingError ? (
        <Box
          sx={{
            alignItems: 'center',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            minHeight: '400px',
            flexDirection: 'column',
          }}
        >
          <p>Erro ao carregar os dados.</p>
          <Button variant="contained" onClick={reloadHandler}>
            Recarregar
          </Button>
        </Box>
      ) : loading ? (
        <Box
          sx={{
            alignItems: 'center',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            minHeight: '400px',
          }}
        >
          <CircularProgress size={30} />
        </Box>
      ) : (
        <div id="chart">
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={350}
          />
        </div>
      )}
    </>
  )
}

export default ColumnChartBasic
