import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  TablePagination,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import ViewElearning from '../../ViewElearning'
import { Clear, ExpandMore, Help, Lock, LockOpen, Search, VpnKey } from '@mui/icons-material'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  addRoleService,
  getAllRoles,
  getCompanyService,
  getListUserRoles,
  getRoleUser,
  getRolesCategoryService,
  removeRoleService,
} from '../../../services/admServices'
import {
  getEmployeeService,
  getSubCompanyCompanyIdService,
  getUserSubCompanyIdService,
} from '../../../services/configServices'
import { useNavigate, useParams } from 'react-router-dom'

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}))

export const PermissionsUser = () => {
  const token = localStorage.getItem('token')
  const params = useParams()
  const navigate = useNavigate()

  const [viewElearning, setViewElearning] = useState(false)
  const [companyLimit, setCompanyLimit] = useState(500)
  const [companyOffset, setCompanyOffset] = useState(0)
  const [companyOrder, setCompanyOrder] = useState('acs')
  const { userId, nameUrl } = useContext(Context)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [companyId, setCompanyId] = useState('')
  const [subCompanyId, setSubCompanyId] = useState('')
  const [usersId, setUserSId] = useState('')
  const [company, setCompany] = useState([])
  const [subCompany, setSubCompany] = useState([])
  const [user, setUser] = useState([])
  const [categoryRoles, setCategoryRoles] = useState([])
  const [roleLimit, setRoleLimit] = useState(1000)
  const [roleOffset, setRoleOffset] = useState(0)
  const [roleOrder, setRoleOrder] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [dataPermissions, setDataPermissions] = useState([])
  const [selectSegment, setSelectSegment] = useState([])
  const [selectedCheckIds, setSelectedCheckIds] = useState([])
  const [switchStates, setSwitchStates] = useState({})
  const [filteredCategoryRoles, setFilteredCategoryRoles] = useState([])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleCheckBoxChange = async role => {
    const updatedSwitchStates = {
      ...switchStates,
      [role.roleId]: !switchStates[role.roleId],
    }
    setSwitchStates(updatedSwitchStates)

    if (selectedCheckIds.includes(role.roleId)) {
      setSelectedCheckIds(selectedCheckIds.filter(selectedId => selectedId !== role.roleId))
    } else {
      setSelectedCheckIds([...selectedCheckIds, role.roleId])
    }

    try {
      if (!params) {
        const data = {
          userId: params.userIdParams,
          rolesId: role.roleId,
        }
        if (switchStates[role.roleId]) {
          const response = await removeRoleService(token, nameUrl, data)
        } else {
          const response = await addRoleService(token, nameUrl, data)
        }
      } else {
        const data = {
          userId: usersId,
          rolesId: role.roleId,
        }

        if (switchStates[role.roleId]) {
          const response = await removeRoleService(token, nameUrl, data)
        } else {
          const response = await addRoleService(token, nameUrl, data)
        }
      }

      //const response = await editRoleService(token, nameUrl, data)
      // console.log(response, 'chec')
      setAlerta(true)
      setSeverity('error')
      //setTitle(response.data.message)
    } catch (error) {
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao atualizar permissões, verifique os dados e tente novamente.')
    }
  }

  const handleGetCompany = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getCompanyService(
        token,
        nameUrl,
        companyLimit,
        companyOffset,
        companyOrder
      )
      setCompany(response.data.result)

      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [])

  const handleGetSubCompany = useCallback(async companyId => {
    setLoading(true)
    setSubCompanyId('')
    setUserSId('')
    try {
      const response = await getSubCompanyCompanyIdService(token, nameUrl, companyId)
      setSubCompany(response.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [])

  const handleGetUser = useCallback(async subCompany => {
    try {
      const response = await getEmployeeService(token, nameUrl, 500, 0, 'desc', subCompany)
      setUser(response.data.result)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [])

  const listRolesUser = async user => {
    setLoading(true)

    try {
      //const response = await getRoleUser(token, nameUrl, setCompanyId, user)
      const responseVerifyRoleId = await getListUserRoles(token, nameUrl, user)
      // const getAllROles = await getAllRoles(token)

      const responseCategory = await getRolesCategoryService(
        token,
        nameUrl,
        roleLimit,
        roleOffset,
        roleOrder
      )

      const list1 = responseCategory.data.result

      const list2 = responseVerifyRoleId.data.rolesUserList

      const matchingRoleIds = findMatchingRoleIds(list1, list2)
      const initialSwitchStates = {}

      list1.forEach(item1 => {
        item1.permissions.forEach(permission1 => {
          initialSwitchStates[permission1.roleId] = matchingRoleIds.includes(permission1.roleId)
        })
      })

      setSwitchStates(initialSwitchStates)
      setSelectedCheckIds(matchingRoleIds)

      setCategoryRoles(list1)
      // console.log(getAllROles, 'getAllROles')

      // const newArray = getAllROles.data.result.map(item => ({
      //   userId: 'c9c1b1fa-83c9-4675-a532-4f3c223efb75',
      //   rolesId: item.id,
      // }))

      // console.log(newArray)
    } catch (error) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  function findMatchingRoleIds(list1, list2) {
    const matchingRoleIds = []

    list1.forEach(item1 => {
      item1.permissions.forEach(permission1 => {
        const roleIdToFind = permission1.roleId
        const isRoleIdInList2 = list2.some(item2 => item2.rolesId === roleIdToFind)

        if (isRoleIdInList2) {
          matchingRoleIds.push(roleIdToFind)
        }
      })
    })

    return matchingRoleIds
  }

  useEffect(() => {
    if (params.userIdParams) {
      handleGetParams()
    } else {
      handleGetCompany()
    }
  }, [params])

  useEffect(() => {}, [usersId, subCompanyId, companyId])

  const handleGetParams = async () => {
    setLoading(true)
    try {
      if (params.companyIdParams) {
        await handleGetCompany()
        setCompanyId(params.companyIdParams)
      }
      if (params.subCompanyIdParams) {
        await handleGetSubCompany(params.companyIdParams)
        setSubCompanyId(params.subCompanyIdParams)
        await handleGetUser(params.subCompanyIdParams)
        setUserSId(params.userIdParams)
      }

      if (params.userIdParams) {
        await listRolesUser(params.userIdParams)
      }

      setLoading(false)
    } catch (error) {
      // Lidar com erros aqui, se necessário
      setLoading(false)
    }
  }

  const handleGetGroup = async () => {
    try {
      const storedData = localStorage.getItem('group')
      if (storedData) {
        const dataArray = JSON.parse(storedData)
        const foundSegment = dataArray.filter(item => item.companyId === subCompany[0].companyId)
        setSelectSegment(foundSegment)

        return foundSegment
      }
      return null
    } catch (error) {
      setError(true)
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao criar, verifique os dados e tente novamente.')
    }
  }

  useEffect(() => {}, [categoryRoles, switchStates])

  useEffect(() => {
    handleGetGroup()
  }, [dataPermissions])

  useEffect(() => {}, [categoryRoles])

  useEffect(() => {
    filterRow()
  }, [searchValue, categoryRoles])

  const filterRow = () => {
    const filtered = categoryRoles.filter(item =>
      item.name.toLowerCase().includes(searchValue.toLowerCase())
    )
    setFilteredCategoryRoles(filtered)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <ViewElearning
          pageName={'ParametersGroup'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Configurar permissões do Usuários</Typography>
          <IconButton onClick={() => setViewElearning(true)} sx={{ justifySelf: 'flex-end' }}>
            <Help />
          </IconButton>
        </Box>
        <Grid container spacing={2}>
          {!params.userIdParams && (
            <Grid item xs={12} md={4}>
              <Box flex={1} display="flex" justifyContent="flex-end">
                <FormControl sx={{ width: '100%', mt: 2 }}>
                  <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
                  <Select
                    disabled={params.userIdParams}
                    value={companyId}
                    sx={{ width: '100%' }}
                    onChange={values => {
                      setCompanyId(values.target.value)
                      handleGetSubCompany(values.target.value)
                    }}
                  >
                    {company.map((item, index) => {
                      return (
                        <MenuItem value={item.companyId} key={item.companyId}>
                          {item.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          )}

          <Grid item xs={12} md={params.userIdParams ? 6 : 4}>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Filial</InputLabel>
                <Select
                  disabled={!companyId || params.userIdParams}
                  value={subCompanyId}
                  onChange={event => {
                    setSubCompanyId(event.target.value)
                    handleGetUser(event.target.value)
                    setCategoryRoles([])
                    setUserSId('')
                    //listRolesUser(userId, event.target.value)
                  }}
                  sx={{ width: '100%' }}
                  label="Filial"
                >
                  {subCompany.map((item, index) => (
                    <MenuItem value={item.subCompanyId} key={item.subCompanyId}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={params.userIdParams ? 6 : 4}>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Usuário</InputLabel>
                <Select
                  disabled={!subCompanyId || params.userIdParams}
                  value={usersId}
                  onChange={event => {
                    setUserSId(event.target.value)
                    listRolesUser(event.target.value)
                  }}
                  sx={{ width: '100%' }}
                  label="Usuário"
                >
                  {user.map((item, index) => (
                    <MenuItem value={item.userId} key={item.userId}>
                      {item.emailEmployee}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" my={4}>
              <TextField
                placeholder="Pesquisar"
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    </>
                  ),
                  endAdornment: (
                    <>
                      {searchValue && (
                        <IconButton aria-label="Limpar pesquisa" onClick={() => setSearchValue('')}>
                          <Clear />
                        </IconButton>
                      )}
                    </>
                  ),
                }}
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography my={2}>
              {searchValue !== '' ? (
                <>
                  {filteredCategoryRoles.length > 0 && filteredCategoryRoles[0].permissions
                    ? `${filteredCategoryRoles[0].permissions.length} Resultados`
                    : 'Nenhum Resultado'}
                </>
              ) : (
                <>
                  {filteredCategoryRoles.length > 0
                    ? `${filteredCategoryRoles.length} Resultados`
                    : 'Nenhum Resultado'}
                </>
              )}
            </Typography>
            {params.userIdParams && (
              <Button variant="contained" onClick={() => navigate('/Employee')}>
                Voltar
              </Button>
            )}
          </Box>
        </Box>
        <Grid container spacing={2}>
          {filteredCategoryRoles
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, index) => (
              <Grid item xs={12} key={index}>
                <Accordion>
                  <StyledAccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Box height="100%" sx={{ display: 'flex', alignItems: 'center' }}>
                      <VpnKey />
                      <Box ml={2}>
                        <Typography>{item.name}</Typography>
                        <Typography variant="caption" color="#ccc">
                          {item.description}
                        </Typography>
                      </Box>
                    </Box>
                  </StyledAccordionSummary>
                  {item.permissions.map((role, index) => (
                    <AccordionDetails key={role.roleId}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          {selectedCheckIds.includes(role.roleId) ? (
                            <LockOpen color="primary" />
                          ) : (
                            <Lock />
                          )}

                          <Typography variant="caption" ml={2}>
                            {role.name}
                          </Typography>
                          <Typography variant="caption" ml={2}>
                            {role.description}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="caption" mr={2}>
                            {selectedCheckIds.includes(role.roleId) ? 'Ativo' : 'Desativado'}
                          </Typography>
                          <Switch
                            color="primary"
                            value={role.rolesStatusUser}
                            checked={switchStates[role.roleId] || false}
                            onChange={() => handleCheckBoxChange(role)}
                          />
                        </Box>
                      </Box>
                    </AccordionDetails>
                  ))}
                </Accordion>
              </Grid>
            ))}
          <Grid item xs={12}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredCategoryRoles.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
          {/* {searchValue === '' ? (
            <>
              {categoryRoles
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Accordion>
                      <StyledAccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Box height="100%" sx={{ display: 'flex', alignItems: 'center' }}>
                          <VpnKey />
                          <Box ml={2}>
                            <Typography>{item.name}</Typography>
                            <Typography variant="caption" color="#ccc">
                              {item.description}
                            </Typography>
                          </Box>
                        </Box>
                      </StyledAccordionSummary>
                      {item.permissions.map((role, index) => (
                        <AccordionDetails key={role.roleId}>
                          <Box
                            //key={permission.id}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              {selectedCheckIds.includes(role.roleId) ? (
                                <LockOpen color="primary" />
                              ) : (
                                <Lock />
                              )}

                              <Typography variant="caption" ml={2}>
                                {role.name}
                              </Typography>
                              <Typography variant="caption" ml={2}>
                                {role.description}
                              </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography variant="caption" mr={2}>
                                {selectedCheckIds.includes(role.roleId) ? 'Ativo' : 'Desativado'}
                              </Typography>
                              <Switch
                                color="primary"
                                value={role.rolesStatusUser}
                                checked={switchStates[role.roleId] || false}
                                onChange={() => handleCheckBoxChange(role)}
                              />
                            </Box>
                          </Box>
                        </AccordionDetails>
                      ))}
                    </Accordion>
                  </Grid>
                ))}
              <Grid item xs={12}>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={categoryRoles.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                {filteredCategoryRoles.map(item =>
                  item.permissions.map((role, index) => (
                    <Box
                      key={index}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        {selectedCheckIds.includes(role.roleId) ? (
                          <LockOpen color="primary" />
                        ) : (
                          <Lock />
                        )}

                        <Typography variant="caption" ml={2}>
                          {role.name}
                        </Typography>
                        <Typography variant="caption" ml={2}>
                          {role.description}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="caption" mr={2}>
                          {selectedCheckIds.includes(role.roleId) ? 'Ativo' : 'Desativado'}
                        </Typography>
                        <Switch
                          color="primary"
                          value={role.rolesStatusUser}
                          checked={switchStates[role.roleId] || false}
                          onChange={() => handleCheckBoxChange(role)}
                        />
                      </Box>
                    </Box>
                  ))
                )}
              </Grid>
            </>
          )} */}
        </Grid>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}
