import { Context } from '../../../context/AuthContext'
import * as XLSX from 'xlsx'
import { useContext, useState } from 'react'
import { handleDissassociation } from '../../../services/reportServices'
import HelpIcon from '@mui/icons-material/Help'
import PropTypes from 'prop-types'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'

//icones react-icons
import { AiOutlineFileExcel } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const ReportDissassociation = () => {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const [startDate, setStartDate] = useState('2020-01-01')
  const [finishDate, setFinishDate] = useState(new Date().toISOString().substring(0, 10))
  const [loading, setLoading] = useState(false)
  const [dissassociationData, setDissassociationData] = useState([])
  const [table, setTable] = useState(false)
  const [orderSelect, setOrderSelect] = useState()

  const [progressCount, setProgressCount] = useState(0)
  const [downloadMessageCount, setDownloadMessageCount] = useState('Pronto')
  const [downloadingCount, setDownloadingCount] = useState(false)
  const [totalPagesDownload, setTotalPagesDownload] = useState()
  const [totalData, setTotalData] = useState()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [viewElearning, setViewElearning] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const handleGetDissassociation = async e => {
    e.preventDefault()
    setLoading(true)
    setTable(false)
    const data = {
      subCompanyId: subCompanyId,
      startDate: startDate,
      finishDate: finishDate,
      limit: 500,
      offset: 1,
    }
    try {
      const response = await handleDissassociation(token, nameUrl, data)
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para esse período.')
        setAlerta(true)
        setSeverity('error')
        setDissassociationData([])
      } else {
        setDissassociationData(response.data.result)
        setTotalPagesDownload(response.data.totalpage + 1)
        setTotalData(response.data.total + 1)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  const downloadDataCount = async () => {
    setDownloadMessageCount('Inicializando')
    setDownloadingCount(true)
    let result = []
    const data = {
      subCompanyId: subCompanyId,
      startDate: startDate,
      finishDate: finishDate,
      limit: 500,
      offset: 1,
      order: orderSelect,
    }

    const resp = await handleDissassociation(token, nameUrl, data)
    finishDownloadCount(resp.data.totalpage + 1)
  }

  const finishDownloadCount = async page => {
    let result = []
    console.log('page ', page)
    for (let i = 0; i <= page; i++) {
      setDownloadMessageCount(`Baixando ${i} de ${page + 1}...`)
      setProgressCount((i / dissassociationData.length) * 100)
      const data = {
        subCompanyId: subCompanyId,
        startDate: startDate,
        finishDate: finishDate,
        limit: 500,
        offset: i,
        order: orderSelect,
      }
      const resp = await handleDissassociation(token, nameUrl, data)
      result = result.concat(resp.data.result)
    }
    setDownloadMessageCount(`Seu arquivo está pronto.`)
    setDownloadingCount(false)
    const relatorioFinalDissassociacao = []

    result.map(item => {
      relatorioFinalDissassociacao.push({
        'Data Criação': new Date(item.created).toLocaleString(),
        'Data Expiração': new Date(item.dataExpiration).toLocaleString(),
        Laboratório: item.laboratory,
        Lote: item.lote,
        Preço: item.price,
        'Nome Produto': item.productName,
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinalDissassociacao)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório de desassociação')
    XLSX.writeFile(workbook, `Relatório de desassociação.xlsx`)
    setProgressCount(0)
  }

  const handleClose = () => {
    setAlerta(false)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={downloadingCount}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessageCount}</DialogTitle>
            <LinearProgressWithLabel value={progressCount} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <ViewElearning
          pageName={'ReportDissassociation'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Relatório de desassociação</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box component="form" onSubmit={handleGetDissassociation}>
          <Box
            sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ mt: 2, width: '100%' }}>
              <SelectSubCompany
                value={subCompanyId}
                onChange={e => {
                  setSubCompanyId(e.target.value)
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Data de criação</InputLabel>
              <TextField
                variant="outlined"
                value={startDate}
                onChange={values => {
                  setStartDate(values.target.value)
                }}
                sx={{ width: '100%' }}
                name="createDate"
                type="date"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Data final</InputLabel>
              <TextField
                variant="outlined"
                value={finishDate}
                onChange={values => setFinishDate(values.target.value)}
                sx={{ width: '100%' }}
                name="endDate"
                type="date"
              />
            </Box>
          </Box>
          <Button variant="contained" type="submit">
            Pesquisar
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            mb: 2,
          }}
        >
          <Typography variant="h6">Resultados</Typography>
          {dissassociationData.length > 0 && (
            <Button
              variant="contained"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: ' space-between',
                fontSize: '.9em',
                width: '6.5rem',
              }}
              onClick={downloadDataCount}
            >
              Excel <AiOutlineFileExcel size="2em" />
            </Button>
          )}
        </Box>
        {dissassociationData.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Data criação</StyledTableCell>
                  <StyledTableCell align="left">Data Expiração</StyledTableCell>
                  <StyledTableCell align="left">Laboratório</StyledTableCell>
                  <StyledTableCell align="left">Lote</StyledTableCell>
                  <StyledTableCell align="left">Preço</StyledTableCell>
                  <StyledTableCell align="left">Nome Produto</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dissassociationData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell align="left">
                        {new Date(row.created).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {new Date(row.dataExpiration).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.laboratory}</StyledTableCell>
                      <StyledTableCell align="left">{row.lote}</StyledTableCell>
                      <StyledTableCell align="left">{row.price}</StyledTableCell>
                      <StyledTableCell align="left">{row.productName}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={dissassociationData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ReportDissassociation
