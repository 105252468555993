import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  deleteMovimentationOrderService,
  reloadSeparatorService,
} from '../../../services/productService'
import HelpIcon from '@mui/icons-material/Help'
import * as XLSX from 'xlsx'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Tooltip,
  Grid,
  TextField,
  Modal,
} from '@mui/material'
import PropTypes from 'prop-types'

//icones react-icons
import { AiFillFileExcel, AiFillFilePdf } from 'react-icons/ai'
import { AiFillPlusCircle, AiFillEye } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'
import PickingListCreate from '../../Create/PickingListCreate'
import { getAllPickingListService } from '../../../services/movOrderItemERP'
import { useNavigate } from 'react-router-dom'
import { returnStatus } from '../../../assets/statusTypes'
import { pickToLightPDF } from '../../../services/pickToLightServices'
import PermissionComponent from '../../../permissions'
import { Api, DeleteForever, PlayArrow } from '@mui/icons-material'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}

const PickingList = () => {
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const [expeditionOrder, setExpeditionOrder] = useState([])
  const [expeditionBackup, setExpeditionBackup] = useState([])
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)

  const [totalPagesDownload, setTotalPagesDownload] = useState('')
  const [progress, setProgress] = useState(0)
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [expeditionOrderId, setExpeditionOrderId] = useState('')
  const [viewElearning, setViewElearning] = useState(false)
  const [filterTable, setFilterTable] = useState(null)
  const [filtered, setFiltered] = useState([])
  const [listImportSeparator, setListImportSeparator] = useState(false)
  const [codReference, setCodReference] = useState('')
  const [saving, setSaving] = useState(false)
  const [register, setRegister] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  }

  const fetchData = async values => {
    if (values) {
      setSubCompanyId(values)
    }
    const data = {
      SubCompanyId: values,
      limit: 500,
      offset: 0,
      Order: 'desc',
    }

    const firstTypeOrder = 20
    const secondTypeOrder = 11

    setLoading(true)
    try {
      let response = await getAllPickingListService(token, nameUrl, data, firstTypeOrder)
      if (response.data.total === 0) {
        response = await getAllPickingListService(token, nameUrl, data, secondTypeOrder)
      }
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
        setExpeditionOrder([])
        setExpeditionBackup([])
      } else {
        setExpeditionOrder(response.data.result)
        setFiltered(response.data.result)
        setExpeditionBackup(response.data.result)
        setPageCount(response.data.totalpage + 1)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setTitle('Erro no servidor, tente novamente')
      setAlerta(true)
      setSeverity('error')
      setLoading(false)
    }
  }

  useEffect(() => {
    if (subCompanyId !== '') {
      fetchData(subCompanyId)
    }
  }, [page, rowsPerPage, subCompanyId])

  const handleFetchData = async () => {
    if (subCompanyId) {
      setLoading(true)
      const data = {
        SubCompanyId: subCompanyId,
        limit: 500,
        offset: 0,
        Order: 'desc',
      }
      const response = await getAllPickingListService(token, nameUrl, data)
      setExpeditionOrder(response.data.result)
      setExpeditionBackup(response.data.result)
      setFiltered(response.data.result)
      setPageCount(response.data.totalpage + 1)
      setLoading(false)
    }
  }

  const downloadData = async () => {
    setDownloadMessage('Inicializando')
    setDownloading(true)
    const data = {
      SubCompanyId: subCompanyId,
      limit: 500,
      offset: 0,
      Order: 'desc',
    }
    await getAllPickingListService(token, nameUrl, data).then(r => {
      setTotalPagesDownload(r.data.totalpage + 1)
      finishDownload(r.data.totalpage + 1)
    })
  }

  const finishDownload = async pages => {
    let result = []

    for (let i = 0; i <= pages; i++) {
      const data = {
        SubCompanyId: subCompanyId,
        limit: 500,
        offset: i,
        Order: 'desc',
      }
      setDownloadMessage(`Baixando ${i} de ${pages}...`)
      setProgress((i / pages) * 100)
      const response = await getAllPickingListService(token, nameUrl, data)
      result = result.concat(response.data.result)
    }
    setDownloadMessage(`Seu arquivo está pronto.`)
    setDownloading(false)
    const relatorioFinal = []
    result.map(item => {
      relatorioFinal.push({
        'Ordem Bihands': item.movimentationOrderNumberBihands,
        'Ordem (Outro Sistema)': item.movimentationOrderNumberOtherSystem,
        Recebimento: new Date(item.receiveMovimentationOrderDate).toLocaleString(),
        Solicitação: new Date(item.createRequestMovimentationOrderDate).toLocaleString(),
        'Filial Saída': item.subCompanyNameFrom,
        Recebido: item.isReceived ? 'Sim' : 'Não',
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Lista de Escolha')
    XLSX.writeFile(workbook, `Lista de Escolha.xlsx`)
  }

  useEffect(() => {}, [subCompanyId])

  const handleClose = () => {
    setAlerta(false)
    setListImportSeparator(false)
    setOpen(false)
  }

  const handleEdit = id => {
    setExpeditionOrderId(id)
    setExpanded(true)
    setEditing(true)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const handleDeleteExpedition = async () => {
    setLoading(true)
    setOpen(false)
    try {
      await deleteMovimentationOrderService(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Expedição deletada com sucesso!')

      setAlerta(true)
      setLoading(false)
      handleFetchData()
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  const filterExpedition = e => {
    setFilterTable(e.target.value)
    if (e.target.value !== 0) {
      const filter = expeditionBackup.filter(exp => exp.movimentationOrderStatus === e.target.value)
      setFiltered(filter)
      setExpeditionOrder(filter)
    } else setExpeditionOrder(expeditionBackup)
  }

  const downloadPDF = async () => {
    try {
      await pickToLightPDF(token, nameUrl, expeditionOrder[0].movimentationOrderId)
    } catch (e) {
      console.log(e)
    }
  }

  const requestSearch = searched => {
    const bihandsArray = filtered.filter(item =>
      item.movimentationOrderNumberBihands.includes(searched)
    )
    const otherArray = filtered.filter(item =>
      item.movimentationOrderNumberOtherSystem.includes(searched)
    )
    const combinedArray = [...bihandsArray, ...otherArray]
    setExpeditionOrder(combinedArray)
  }

  const handleImporSeparator = () => {
    setListImportSeparator(true)
  }

  const handleImportSeparator = async () => {
    handleClose()
    setSaving(true)
    try {
      const response = await reloadSeparatorService(token, nameUrl, codReference, subCompanyId)
      setTitle(response.data.message)
      setAlerta(true)
      setSeverity('success')
      setRegister(true)
    } catch (e) {
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setRegister(true)
    }
    setSaving(false)
  }

  const deleteMovimentationOrder = async () => {
    setLoading(true)
    try {
      await deleteMovimentationOrderService(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Transferencia deletada com sucesso!')
      setLoading(false)
      setAlerta(true)
      setOpen(false)
      fetchData()
    } catch (err) {
      console.log(err)
      setSeverity('error')
      setTitle('Erro ao deletar, tente novamente.')
      setLoading(false)
      setOpen(false)
      setAlerta(true)
    }
  }

  const handleDelete = id => {
    setIdDelete(id)
    setOpen(true)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Dialog open={downloading} keepMounted aria-describedby="alert-dialog-slide-description">
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessage}</DialogTitle>
            <LinearProgressWithLabel value={progress} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => deleteMovimentationOrder()}>Sim</Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <ViewElearning pageName={'PickingList'} open={viewElearning} setOpen={setViewElearning} />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Lista de Separação</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Modal
          open={listImportSeparator}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, maxWidth: 600, width: '100%' }}>
            <Typography my={2} variant="h6" textAlign="center">
              Insira o codigo de separação
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectSubCompany
                  value={subCompanyId}
                  onChange={e => {
                    fetchData(e.target.value)
                    subCompanyId(e.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignContent="center" justifyContent="center">
                  <TextField
                    disabled={!subCompanyId}
                    sx={{ width: '100%' }}
                    required
                    id="outlined-required"
                    label="Codigo"
                    value={codReference}
                    onChange={e => setCodReference(e.target.value)}
                  />
                </Box>
              </Grid>
            </Grid>

            <Box mt={4} display="flex" alignContent="center" justifyContent="center">
              <Button
                sx={{ maxWidth: '200px', width: '50%' }}
                variant="contained"
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <Button
                sx={{ ml: 2, maxWidth: '200px', width: '50%' }}
                variant="contained"
                onClick={() => handleImportSeparator()}
              >
                Salvar
              </Button>
            </Box>
          </Box>
        </Modal>
        {/* Criar Expedição */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>{'Cadastrar PickingList'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PickingListCreate
              setExpanded={setExpanded}
              setAlerta={setAlerta}
              setSeverity={setSeverity}
              setTitle={setTitle}
              handleFetchData={handleFetchData}
              StyledTableCell={StyledTableCell}
              StyledTableRow={StyledTableRow}
            />
          </AccordionDetails>
        </Accordion>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDeleteExpedition()}>Sim</Button>
          </DialogActions>
        </Dialog>

        <SelectSubCompany
          value={subCompanyId}
          onChange={e => {
            fetchData(e.target.value)
          }}
        />
        <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
          alignItems={{ xs: 'flex-start', sm: 'flex-start', md: 'center' }}
          flexDirection={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} sm={6}>
              <TextField
                sx={{ width: '100%' }}
                variant="outlined"
                placeholder="Procurar Ordem"
                type="search"
                value={searchTerm}
                onChange={e => {
                  requestSearch(e.target.value)
                  setSearchTerm(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-label">Filtrar por status</InputLabel>
                <Select
                  value={filterTable}
                  onChange={e => {
                    filterExpedition(e)
                    setSearchTerm('')
                  }}
                  sx={{ width: '100%' }}
                >
                  <MenuItem value={0}>Todos</MenuItem>
                  <MenuItem value={2}>Completo</MenuItem>
                  <MenuItem value={1}>Aberto</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
              <Box width="100%" height="55px">
                <Button
                  sx={{ width: '100%', height: '100%' }}
                  onClick={() => handleImporSeparator()}
                  variant="contained"
                  endIcon={<Api />}
                  disabled={saving}
                >
                  Importar produtos por Api
                </Button>
              </Box>
            </Grid>
            <PermissionComponent role="ExpeditionCREATE">
              <Grid item xs={12} md={3} sm={6}>
                <Button
                  sx={{ width: '100%', height: '100%' }}
                  onClick={() => {
                    setEditing(false)
                    setExpanded(true)
                  }}
                  variant="contained"
                  endIcon={<AiFillPlusCircle />}
                >
                  Criar
                </Button>
              </Grid>
            </PermissionComponent>

            <Grid item xs={12}>
              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    gap: 2,
                  }}
                >
                  {expeditionOrder.length > 0 && (
                    <>
                      <Button
                        variant="contained"
                        endIcon={<AiFillFilePdf />}
                        onClick={() => downloadPDF()}
                      >
                        PDF
                      </Button>
                      <Button
                        variant="contained"
                        endIcon={<AiFillFileExcel />}
                        onClick={downloadData}
                      >
                        Excel
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
          {/* <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-evenly'}} >
            {expeditionOrder.length > 0 &&              
              <Button onClick={downloadData} >
                <DocumentScannerOutlinedIcon sx={{fontSize:'3em'}} />
              </Button>
            }                    
          </Box> */}
        </Box>
        {expeditionOrder.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell> </StyledTableCell>
                  <StyledTableCell align="left">Ordem Bihands</StyledTableCell>
                  <StyledTableCell align="left">Ordem (outro sistema)</StyledTableCell>
                  <StyledTableCell align="left">Data Recebimento</StyledTableCell>
                  <StyledTableCell align="left">Data Solicitação</StyledTableCell>
                  <StyledTableCell align="left">Filial Saída</StyledTableCell>
                  <StyledTableCell align="left">Status</StyledTableCell>
                  <StyledTableCell align="left">Recebido</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {expeditionOrder
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '140px',
                          }}
                        >
                          <PermissionComponent role="">
                            <Tooltip title="Contagem" arrow>
                              <IconButton
                                onClick={() =>
                                  navigate(`/CountStockDesk/${20}/${row.movimentationOrderId}`)
                                }
                                sx={{ cursor: 'pointer' }}
                              >
                                <PlayArrow />
                              </IconButton>
                            </Tooltip>
                          </PermissionComponent>
                          <PermissionComponent role="">
                            <Tooltip title="Ver" arrow>
                              <IconButton
                                onClick={() =>
                                  navigate(`/PickingListEdit/${row.movimentationOrderId}`)
                                }
                                sx={{ cursor: 'pointer' }}
                              >
                                <AiFillEye size="1.0em" />
                              </IconButton>
                            </Tooltip>
                          </PermissionComponent>
                          <PermissionComponent role="">
                            <Tooltip title="Excluir" arrow>
                              <IconButton
                                onClick={() => handleDelete(row.movimentationOrderId)}
                                sx={{ cursor: 'pointer' }}
                              >
                                <DeleteForever />
                              </IconButton>
                            </Tooltip>
                          </PermissionComponent>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.movimentationOrderNumberBihands}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.movimentationOrderNumberOtherSystem}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {new Date(row.receiveMovimentationOrderDate).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {new Date(row.createRequestMovimentationOrderDate).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.subCompanyNameFrom}</StyledTableCell>
                      <StyledTableCell align="left">
                        {returnStatus(row.movimentationOrderStatus)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.isReceived ? 'Sim' : 'Não'}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={expeditionOrder.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default PickingList
