import { Context } from '../../../context/AuthContext'
import * as XLSX from 'xlsx'
import { useContext, useEffect, useState } from 'react'
import { handleStockActual } from '../../../services/reportServices'
import HelpIcon from '@mui/icons-material/Help'
import PropTypes from 'prop-types'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

//icones react-icons
import { AiOutlineFileExcel } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'
import { Edit } from '@mui/icons-material'
import PermissionComponent from '../../../permissions'
import { updateQuantityStock } from '../../../services/movBreakServices'

const ReportCurrentInventory = () => {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const [startDate, setStartDate] = useState('2020-01-01')
  const [finishDate, setFinishDate] = useState(new Date().toISOString().substring(0, 10))
  const [loading, setLoading] = useState(false)
  const [dissassociationData, setDissassociationData] = useState([])
  const [table, setTable] = useState(false)

  const [progressCount, setProgressCount] = useState(0)
  const [downloadMessageCount, setDownloadMessageCount] = useState('Pronto')
  const [downloadingCount, setDownloadingCount] = useState(false)
  const [totalPagesDownload, setTotalPagesDownload] = useState()
  const [totalData, setTotalData] = useState()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const vertical = 'top'
  const horizontal = 'center'
  const [openQuantity, setOpenQuantity] = useState(false)
  const [updateQuantity, setUpdateQuantity] = useState({
    productId: '',
    depositId: '',
    subCompanyId: '',
    quantity: 0,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [viewElearning, setViewElearning] = useState(false)
  const [filterDeposit, setFilterDeposit] = useState('')
  const [filterProduct, setFilterProduct] = useState('')
  const [backupData, setBackupData] = useState([])
  const [filteringDeposit, setFilteringDeposit] = useState(false)
  const [filteringProduct, setFilteringProduct] = useState(false)
  const [notSearch, setNotSearch] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const handleGetDissassociation = async e => {
    e.preventDefault()
    setLoading(true)
    setTable(false)
    const data = {
      subCompanyId: subCompanyId,
      startDate: startDate,
      finishDate: finishDate,
      limit: 50,
      offset: 1,
    }
    try {
      const response = await handleStockActual(token, nameUrl, data)
      if (!response) {
        setTitle('Não existem dados para esse período.')
        setAlerta(true)
        setSeverity('error')
        setDissassociationData([])
        setBackupData([])
      } else {
        const sortedData = response.data.sort((a, b) => {
          return new Date(b.lastUpdate) - new Date(a.lastUpdate)
        })

        setDissassociationData(sortedData)
        setBackupData(sortedData)
        setTotalPagesDownload(response.data.totalpage + 1)
        setTotalData(response.data.total + 1)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const handleFetchData = async () => {
    setLoading(true)
    setTable(false)
    const data = {
      subCompanyId: subCompanyId,
      startDate: startDate,
      finishDate: finishDate,
      limit: 50,
      offset: 1,
    }
    try {
      const response = await handleStockActual(token, nameUrl, data)
      if (!response) {
        setTitle('Não existem dados para esse período.')
        setAlerta(true)
        setSeverity('error')
        setDissassociationData([])
        setBackupData([])
      } else {
        const sortedData = response.data.sort((a, b) => {
          return new Date(b.lastUpdate) - new Date(a.lastUpdate)
        })

        setDissassociationData(sortedData)
        setBackupData(sortedData)
        setTotalPagesDownload(response.data.totalpage + 1)
        setTotalData(response.data.total + 1)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const downloadDataCount = async () => {
    setDownloadMessageCount('Inicializando')
    setDownloadingCount(true)
    finishDownloadCount()
  }

  const finishDownloadCount = async () => {
    let result = []

    // Primeira parte do progresso
    for (let i = 0; i <= 50; i++) {
      setTimeout(() => {
        setDownloadMessageCount(`Baixando ${i} de ${page + 1}...`)
        setProgressCount((i / 100) * 100)
      }, 1000)
    }

    const data = {
      subCompanyId: subCompanyId,
      startDate: startDate,
      finishDate: finishDate,
    }

    try {
      const resp = await handleStockActual(token, nameUrl, data)
      result = resp.data

      // Segunda parte do progresso
      for (let i = 50; i <= 100; i++) {
        setTimeout(() => {
          setDownloadMessageCount(`Baixando ${i} de ${page + 1}...`)
          setProgressCount((i / 100) * 100)
        }, 1000)
      }

      setDownloadMessageCount(`Seu arquivo está pronto.`)
      setDownloadingCount(false)

      const relatorioFinal = result.map(item => ({
        ERP: item.idProductPartner,
        Produto: item.productName,
        Depósito: item.depositName,
        'Quantidade em estoque': item.quantity,
        Filial: item.subCompanyName,
        'Data atualização': new Date(item.lastUpdate).toLocaleString(),
      }))

      const workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Estoque atual')
      XLSX.writeFile(workbook, `Estoque atual.xlsx`)

      setProgressCount(0)
    } catch (error) {
      console.error('Error downloading stock data:', error)
      setDownloadMessageCount(`Erro ao baixar o arquivo. Tente novamente.`)
      setDownloadingCount(false)
      setProgressCount(0)
    }
  }

  const handleClose = () => {
    setAlerta(false)
    setOpenQuantity(false)
  }

  useEffect(() => {
    const handleFilter = () => {
      if (filterDeposit !== '') {
        setNotSearch(false)
        setFilteringDeposit(true)
        const newData = backupData
        const filter = newData.filter(data =>
          data.depositName.toLowerCase().includes(filterDeposit.toLowerCase())
        )
        setFilteringDeposit(false)
        if (filter.length > 0) {
          setDissassociationData(filter)
          setPage(0)
        } else {
          setNotSearch(true)
        }
      } else setDissassociationData(backupData)
    }
    handleFilter()
  }, [filterDeposit])

  useEffect(() => {
    const handleFilter = () => {
      if (filterProduct !== '') {
        setNotSearch(false)
        setFilteringProduct(true)
        const newData = backupData
        const filter = newData.filter(data =>
          data.productName.toLowerCase().includes(filterProduct.toLowerCase())
        )
        setFilteringProduct(false)
        if (filter.length > 0) {
          setDissassociationData(filter)
          setPage(0)
        } else {
          setNotSearch(true)
        }
      } else setDissassociationData(backupData)
    }
    handleFilter()
  }, [filterProduct])

  const handleClearFilter = () => {
    setFilterDeposit('')
    setFilterProduct('')
    setDissassociationData(backupData)
  }

  const handleUpdateQuantity = async () => {
    setIsLoading(true)
    try {
      const response = await updateQuantityStock(token, nameUrl, updateQuantity)
      setTitle(response.data.message)
      setAlerta(true)
      setSeverity('success')
      setIsLoading(false)
      handleFetchData()
      setOpenQuantity(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={downloadingCount}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessageCount}</DialogTitle>
            <LinearProgressWithLabel value={progressCount} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <Dialog maxWidth="xs" open={openQuantity} onClose={handleClose}>
          {isLoading && (
            <Box
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              position="absolute"
              top="0"
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.8)', // White color with 80% opacity
                zIndex: 1,
              }}
            >
              <CircularProgress size={20} />
              <Typography>Salvando...</Typography>
            </Box>
          )}

          <DialogTitle>Editar Quantidade</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              name="quantity"
              label="Quantidade em estoque"
              type="text"
              fullWidth
              variant="standard"
              value={updateQuantity.quantity}
              onChange={e => {
                setUpdateQuantity(prevState => ({
                  ...prevState,
                  quantity: Number(e.target.value),
                }))
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button variant="contained" onClick={handleUpdateQuantity}>
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
        <ViewElearning
          pageName={'ReportDissassociation'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Relatório de estoque atual</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box component="form" onSubmit={handleGetDissassociation}>
          <Box
            sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ mt: 2, width: '100%' }}>
              <SelectSubCompany
                value={subCompanyId}
                onChange={e => {
                  setSubCompanyId(e.target.value)
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Data de criação</InputLabel>
              <TextField
                variant="outlined"
                value={startDate}
                onChange={values => {
                  setStartDate(values.target.value)
                }}
                sx={{ width: '100%' }}
                name="createDate"
                type="date"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Data final</InputLabel>
              <TextField
                variant="outlined"
                value={finishDate}
                onChange={values => setFinishDate(values.target.value)}
                sx={{ width: '100%' }}
                name="endDate"
                type="date"
              />
            </Box>
          </Box>
          <Button disabled={!subCompanyId} variant="contained" type="submit">
            Pesquisar
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            mb: 2,
          }}
        >
          <Typography variant="h6">Resultados</Typography>
          {dissassociationData.length > 0 && (
            <Button
              variant="contained"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: ' space-between',
                fontSize: '.9em',
                width: '6.5rem',
              }}
              onClick={downloadDataCount}
            >
              Excel <AiOutlineFileExcel size="2em" />
            </Button>
          )}
        </Box>
        <Box
          sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box width={{ xs: '100%', sm: '100%', md: '35%', lg: '35%', xl: '35%' }} sx={{ mt: 2 }}>
            <TextField
              variant="outlined"
              label={!notSearch ? 'Filtrar Produto' : 'Produto Não encontrado'}
              value={filterProduct}
              onChange={values => setFilterProduct(values.target.value)}
              sx={{ width: '100%' }}
              error={notSearch}
              type="text"
            />
          </Box>
          <Box width={{ xs: '100%', sm: '100%', md: '35%', lg: '35%', xl: '35%' }} sx={{ mt: 2 }}>
            <TextField
              variant="outlined"
              label={!notSearch ? 'Filtrar Depósito' : 'Depósito não encontrado'}
              value={filterDeposit}
              onChange={values => setFilterDeposit(values.target.value)}
              sx={{ width: '100%' }}
              error={notSearch}
              type="text"
            />
          </Box>
          <Button variant="contained" onClick={() => handleClearFilter()} sx={{ mt: 2 }}>
            Limpar filtros
          </Button>
        </Box>
        <TableContainer component={Paper} sx={{ mt: '2rem' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left"></StyledTableCell>
                <StyledTableCell align="left">Id</StyledTableCell>
                <StyledTableCell align="left">Produto</StyledTableCell>
                <StyledTableCell align="left">Depósito</StyledTableCell>
                <StyledTableCell align="left">Quantidade em estoque</StyledTableCell>
                {/* <StyledTableCell align="left">Em Aguardo</StyledTableCell> */}
                {/* <StyledTableCell align="left">Total</StyledTableCell> */}
                <StyledTableCell align="left">Filial</StyledTableCell>
                <StyledTableCell align="left">Data atualização</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dissassociationData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell align="left">
                      <PermissionComponent role="">
                        <Tooltip title="Editar quantidade">
                          <IconButton
                            onClick={() => {
                              setOpenQuantity(true)
                              setUpdateQuantity({
                                productId: row.productId,
                                depositId: row.depositId,
                                subCompanyId: row.subCompanyId,
                                quantity: row.quantity || 0,
                              })
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </PermissionComponent>
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.idProductPartner}</StyledTableCell>
                    <StyledTableCell align="left">{row.productName}</StyledTableCell>
                    <StyledTableCell align="left">{row.depositName}</StyledTableCell>
                    <StyledTableCell align="left">{row.quantity || 0}</StyledTableCell>

                    {/* <StyledTableCell align="left">
                      {row.wait ? row.quantity - row.wait : row.quantity - 0}
                    </StyledTableCell> */}
                    <StyledTableCell align="left">{row.subCompanyName}</StyledTableCell>
                    <StyledTableCell align="left">
                      {new Date(row.lastUpdate).toLocaleString()}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={dissassociationData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ReportCurrentInventory
