import axios from 'axios'

//criação
export const createElearningService = async (token, nameUrl, data) => {
  console.log('data ', data)
  const response = await axios.post(`/${nameUrl}/api/Elearning/Create`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//get paginação
export const getElearningService = async (token, nameUrl, data) => {
  let orderToString
  if (data.order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }
  const response = await axios.get(
    `/${nameUrl}/api/Elearning/GetAll?limit=${data.limit}&offset=${data.offset}&order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//get específico
export const getElearningIdService = async (token, nameUrl, data) => {
  const response = await axios.get(
    //`/${nameUrl}/api/Elearning/Get?PageName=${data.pageName}&id=${data.id}`,
    `/${nameUrl}/api/Elearning/GetInfoPage/${data.pageName}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//delete
export const deleteElearningIdService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Elearning/Delete`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//edit
export const editElearningService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Elearning/Edit`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}
