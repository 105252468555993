import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  deleteMovimentationOrderService,
  getOrderPaginationService,
  returnOrderStatus,
} from '../../../services/productService'
import * as XLSX from 'xlsx'
import { RESULTS_PER_PAGE } from '../../../utils/excel'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TransferOrderCreate from '../../Create/TransferOrderCreate'
import TransferOrderItem from '../../Edit/TransferOrderItem'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Grid,
  DialogContent,
  Chip,
} from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import HelpIcon from '@mui/icons-material/Help'
import { useNavigate } from 'react-router-dom'
//icones react-icons
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import PermissionComponent from '../../../permissions'
import { Add, Close, Save } from '@mui/icons-material'
import NewSampleControlCreate from '../../Create/NewSampleControlCreate'

const mockupList = [
  {
    des: 'G298',
    var: '000B2',
    caixa: '006',
    base: '108082',
    cadastro: '03/03/2024',
    cliente: 'Grupo internancional - Kyan TOP',
    observation: '',
    dataProd: '03/03/2024',
    quem: '',
    saida: null,
    devolution: null,
  },
  {
    des: 'G298',
    var: '000B2',
    caixa: '006',
    base: '108082',
    cadastro: '03/03/2024',
    cliente: 'Grupo internacional - Kyan TOP',
    observation: '',
    dataProd: '03/03/2024',
    quem: '',
    saida: null,
    devolution: null,
  },
  {
    des: 'G299',
    var: '000B3',
    caixa: '007',
    base: '108083',
    cadastro: '10/03/2023',
    cliente: 'Grupo internacional - Zara TOP',
    observation: '',
    dataProd: '24/03/2023',
    quem: '',
    saida: null,
    devolution: null,
  },
  {
    des: 'G300',
    var: '000B4',
    caixa: '008',
    base: '108084',
    cadastro: '11/03/2023',
    cliente: 'Grupo internacional - H&M TOP',
    observation: '',
    dataProd: '25/03/2023',
    quem: '',
    saida: null,
    devolution: null,
  },
  {
    des: 'G301',
    var: '000B5',
    caixa: '009',
    base: '108085',
    cadastro: '12/03/2023',
    cliente: 'Grupo internacional - Gap TOP',
    observation: '',
    dataProd: '26/03/2023',
    quem: '',
    saida: null,
    devolution: null,
  },
  {
    des: 'G302',
    var: '000B6',
    caixa: '010',
    base: '108086',
    cadastro: '13/03/2023',
    cliente: 'Grupo internacional - Nike TOP',
    observation: '',
    dataProd: '27/03/2023',
    quem: '',
    saida: null,
    devolution: null,
  },
  {
    des: 'G303',
    var: '000B7',
    caixa: '011',
    base: '108087',
    cadastro: '14/03/2023',
    cliente: 'Grupo internacional - Adidas TOP',
    observation: '',
    dataProd: '28/03/2023',
    quem: '',
    saida: null,
    devolution: null,
  },
  {
    des: 'G304',
    var: '000B8',
    caixa: '012',
    base: '108088',
    cadastro: '15/03/2023',
    cliente: 'Grupo internacional - Puma TOP',
    observation: '',
    dataProd: '29/03/2023',
    quem: '',
    saida: null,
    devolution: null,
  },
  {
    des: 'G305',
    var: '000B9',
    caixa: '013',
    base: '108089',
    cadastro: '16/03/2023',
    cliente: 'Grupo internacional - Reebok TOP',
    observation: '',
    dataProd: '30/03/2023',
    quem: '',
    saida: null,
    devolution: null,
  },
  {
    des: 'G306',
    var: '000C0',
    caixa: '014',
    base: '108090',
    cadastro: '17/03/2023',
    cliente: 'Grupo internacional - Asics TOP',
    observation: '',
    dataProd: '31/03/2023',
    quem: '',
    saida: null,
    devolution: null,
  },
  {
    des: 'G307',
    var: '000C1',
    caixa: '015',
    base: '108091',
    cadastro: '18/03/2023',
    cliente: 'Grupo internacional - Under Armour TOP',
    observation: '',
    dataProd: '01/04/2023',
    quem: '',
    saida: null,
    devolution: null,
  },
  {
    des: 'G308',
    var: '000C2',
    caixa: '016',
    base: '108092',
    cadastro: '19/03/2023',
    cliente: 'Grupo internacional - New Balance TOP',
    observation: '',
    dataProd: '02/04/2023',
    quem: '',
    saida: null,
    devolution: null,
  },
  {
    des: 'G309',
    var: '000C3',
    caixa: '017',
    base: '108093',
    cadastro: '20/03/2023',
    cliente: 'Grupo internacional - Vans TOP',
    observation: '',
    dataProd: '03/04/2023',
    quem: '',
    saida: null,
    devolution: null,
  },
  {
    des: 'G310',
    var: '000C4',
    caixa: '018',
    base: '108094',
    cadastro: '21/03/2023',
    cliente: 'Grupo internacional - Converse TOP',
    observation: '',
    dataProd: '04/04/2023',
    quem: '',
    saida: null,
    devolution: null,
  },
  {
    des: 'G311',
    var: '000C5',
    caixa: '019',
    base: '108095',
    cadastro: '22/03/2023',
    cliente: 'Grupo internacional - Skechers TOP',
    observation: '',
    dataProd: '05/04/2023',
    quem: '',
    saida: null,
    devolution: null,
  },
  {
    des: 'G312',
    var: '000C6',
    caixa: '020',
    base: '108096',
    cadastro: '23/03/2023',
    cliente: 'Grupo internacional - Timberland TOP',
    observation: '',
    dataProd: '06/04/2023',
    quem: '',
    saida: null,
    devolution: null,
  },
  {
    des: 'G313',
    var: '000C7',
    caixa: '021',
    base: '108097',
    cadastro: '24/03/2023',
    cliente: 'Grupo internacional - Birkenstock TOP',
    observation: '',
    dataProd: '07/04/2023',
    quem: '',
    saida: null,
    devolution: null,
  },
  {
    des: 'G314',
    var: '000C8',
    caixa: '022',
    base: '108098',
    cadastro: '25/03/2023',
    cliente: 'Grupo internacional - Crocs TOP',
    observation: '',
    dataProd: '08/04/2023',
    quem: '',
    saida: null,
    devolution: null,
  },
  {
    des: 'G315',
    var: '000C9',
    caixa: '023',
    base: '108099',
    cadastro: '26/03/2023',
    cliente: 'Grupo internacional - Merrell TOP',
    observation: '',
    dataProd: '09/04/2023',
    quem: '',
    saida: null,
    devolution: null,
  },
  {
    des: 'G316',
    var: '000D0',
    caixa: '024',
    base: '108100',
    cadastro: '27/03/2023',
    cliente: 'Grupo internacional - Salomon TOP',
    observation: '',
    dataProd: '10/04/2023',
    quem: '',
    saida: null,
    devolution: null,
  },
  {
    des: 'G299',
    var: '000B3',
    caixa: '007',
    base: '108083',
    cadastro: '10/03/2023',
    cliente: 'Grupo internancional - Zara TOP',
    observation: '',
    dataProd: '24/03/2023',
    quem: '',
    saida: '',
    devolution: '',
  },
  {
    des: 'G300',
    var: '000B4',
    caixa: '008',
    base: '108084',
    cadastro: '11/03/2023',
    cliente: 'Grupo internancional - H&M TOP',
    observation: '',
    dataProd: '25/03/2023',
    quem: '',
    saida: '',
    devolution: '',
  },
  {
    des: 'G301',
    var: '000B5',
    caixa: '009',
    base: '108085',
    cadastro: '12/03/2023',
    cliente: 'Grupo internancional - Gap TOP',
    observation: '',
    dataProd: '26/03/2023',
    quem: '',
    saida: '',
    devolution: '',
  },
  {
    des: 'G302',
    var: '000B6',
    caixa: '010',
    base: '108086',
    cadastro: '13/03/2023',
    cliente: 'Grupo internancional - Nike TOP',
    observation: '',
    dataProd: '27/03/2023',
    quem: '',
    saida: '31/08/1990',
    devolution: '',
  },
  {
    des: 'G303',
    var: '000B7',
    caixa: '011',
    base: '108087',
    cadastro: '14/03/2023',
    cliente: 'Grupo internancional - Adidas TOP',
    observation: '',
    dataProd: '28/03/2023',
    quem: '',
    saida: '01/09/1990',
    devolution: '01/09/1990',
  },
  {
    des: 'G304',
    var: '000B8',
    caixa: '012',
    base: '108088',
    cadastro: '15/03/2023',
    cliente: 'Grupo internancional - Puma TOP',
    observation: '',
    dataProd: '29/03/2023',
    quem: '',
    saida: '02/09/1990',
    devolution: '02/09/1990',
  },
  {
    des: 'G305',
    var: '000B9',
    caixa: '013',
    base: '108089',
    cadastro: '16/03/2023',
    cliente: 'Grupo internancional - Reebok TOP',
    observation: '',
    dataProd: '30/03/2023',
    quem: '',
    saida: '03/09/1990',
    devolution: '03/09/1990',
  },
  {
    des: 'G306',
    var: '000C0',
    caixa: '014',
    base: '108090',
    cadastro: '17/03/2023',
    cliente: 'Grupo internancional - Asics TOP',
    observation: '',
    dataProd: '31/03/2023',
    quem: '',
    saida: '04/09/1990',
    devolution: '04/09/1990',
  },
  {
    des: 'G307',
    var: '000C1',
    caixa: '015',
    base: '108091',
    cadastro: '18/03/2023',
    cliente: 'Grupo internancional - Under Armour TOP',
    observation: '',
    dataProd: '01/04/2023',
    quem: '',
    saida: '05/09/1990',
    devolution: '05/09/1990',
  },
  {
    des: 'G308',
    var: '000C2',
    caixa: '016',
    base: '108092',
    cadastro: '19/03/2023',
    cliente: 'Grupo internancional - New Balance TOP',
    observation: '',
    dataProd: '02/04/2023',
    quem: '',
    saida: '06/09/1990',
    devolution: '06/09/1990',
  },
  {
    des: 'G309',
    var: '000C3',
    caixa: '017',
    base: '108093',
    cadastro: '20/03/2023',
    cliente: 'Grupo internancional - Vans TOP',
    observation: '',
    dataProd: '03/04/2023',
    quem: '',
    saida: '07/09/1990',
    devolution: '07/09/1990',
  },
  {
    des: 'G310',
    var: '000C4',
    caixa: '018',
    base: '108094',
    cadastro: '21/03/2023',
    cliente: 'Grupo internancional - Converse TOP',
    observation: '',
    dataProd: '04/04/2023',
    quem: '',
    saida: '08/09/1990',
    devolution: '08/09/1990',
  },
  {
    des: 'G311',
    var: '000C5',
    caixa: '019',
    base: '108095',
    cadastro: '22/03/2023',
    cliente: 'Grupo internancional - Skechers TOP',
    observation: '',
    dataProd: '05/04/2023',
    quem: '',
    saida: '09/09/1990',
    devolution: '09/09/1990',
  },
  {
    des: 'G312',
    var: '000C6',
    caixa: '020',
    base: '108096',
    cadastro: '23/03/2023',
    cliente: 'Grupo internancional - Timberland TOP',
    observation: '',
    dataProd: '06/04/2023',
    quem: '',
    saida: '10/09/1990',
    devolution: '10/09/1990',
  },
  {
    des: 'G313',
    var: '000C7',
    caixa: '021',
    base: '108097',
    cadastro: '24/03/2023',
    cliente: 'Grupo internancional - Birkenstock TOP',
    observation: '',
    dataProd: '07/04/2023',
    quem: '',
    saida: '11/09/1990',
    devolution: '11/09/1990',
  },
  {
    des: 'G314',
    var: '000C8',
    caixa: '022',
    base: '108098',
    cadastro: '25/03/2023',
    cliente: 'Grupo internancional - Crocs TOP',
    observation: '',
    dataProd: '08/04/2023',
    quem: '',
    saida: '12/09/1990',
    devolution: '12/09/1990',
  },
  {
    des: 'G315',
    var: '000C9',
    caixa: '023',
    base: '108099',
    cadastro: '26/03/2023',
    cliente: 'Grupo internancional - Merrell TOP',
    observation: '',
    dataProd: '09/04/2023',
    quem: '',
    saida: '13/09/1990',
    devolution: '13/09/1990',
  },
  {
    des: 'G316',
    var: '000D0',
    caixa: '024',
    base: '108100',
    cadastro: '27/03/2023',
    cliente: 'Grupo internancional - Salomon TOP',
    observation: '',
    dataProd: '10/04/2023',
    quem: '',
    saida: '14/09/1990',
    devolution: '14/09/1990',
  },
]

const mockupListWithId = mockupList.map(item => ({
  id: uuidv4(),
  ...item,
}))

const NewSampleControl = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const [openDialog, setOpenDialog] = useState(false)
  const [editing, setEditing] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const [loading, setLoading] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [viewElearning, setViewElearning] = useState(false)
  const [progress, setProgress] = useState(0)
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalData, setTotalData] = useState(0)
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [userName, setUserName] = useState('')
  const [observation, setObservation] = useState('')
  const [mockId, setMockId] = useState('')
  const [message, setMessage] = useState('')
  const [operation, setOperation] = useState(0)

  const handleGetAll = async () => {
    try {
      const sortedData = [...mockupListWithId].sort((a, b) => {
        const dateA = new Date(a.dataProd.split('/').reverse().join('/'))
        const dateB = new Date(b.dataProd.split('/').reverse().join('/'))
        return dateB - dateA
      })

      localStorage.setItem('data', JSON.stringify(sortedData))

      const storedData = JSON.parse(localStorage.getItem('data'))
      setData(storedData)
    } catch (error) {}
  }

  const handleSaidaClick = id => {
    console.log(id, 'disada')
    const index = mockupListWithId.findIndex(item => item.id === id) // Encontra o índice do item com base no ID
    setOpenDialog(true)
    setMockId(id)
    setOperation(1)
  }

  const handleDevolucaoClick = id => {
    const index = mockupListWithId.findIndex(item => item.id === id) // Encontra o índice do item com base no ID
    setOpenDialog(true)
    setMockId(id)
    setOperation(2)
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const handleSaveDevolution = () => {
    setIsLoading(true)

    setTimeout(() => {
      if (!userName) {
        setIsLoading(false)
        setMessage('Por favor, informe o nome do usuário.')
        return
      }

      try {
        const storedData = JSON.parse(localStorage.getItem('data'))

        const index = storedData.findIndex(item => item.id === mockId)
        if (index !== -1) {
          storedData[index].quem = userName
          storedData[index].observation = observation
          storedData[index].devolution = new Date().toLocaleDateString()

          localStorage.setItem('data', JSON.stringify(storedData))
          setData(storedData)
        }

        setOpenDialog(false)
        setIsLoading(false)
      } catch (error) {
        console.error('Erro ao salvar devolução:', error)
        setIsLoading(false)
      }
    }, 1000)
  }

  const handleSaveExit = () => {
    setIsLoading(true)

    setTimeout(() => {
      if (!userName) {
        setIsLoading(false)
        setMessage('Por favor, informe o nome do usuário.')
        return
      }

      try {
        const storedData = JSON.parse(localStorage.getItem('data'))

        const index = storedData.findIndex(item => item.id === mockId)
        if (index !== -1) {
          storedData[index].quem = userName
          storedData[index].observation = observation
          storedData[index].saida = new Date().toLocaleDateString()

          localStorage.setItem('data', JSON.stringify(storedData))
          setData(storedData)
        }

        setOpenDialog(false)
        setIsLoading(false)
      } catch (error) {
        console.error('Erro ao salvar saída:', error)
        setIsLoading(false)
      }
    }, 1000)
  }

  useEffect(() => {
    handleGetAll()
  }, [])

  useEffect(() => {}, [data, userName])

  const handleAddNewItem = form => {
    const newData = [...data, form]

    newData.sort((a, b) => {
      const dateA = new Date(a.dataProd.split('/').reverse().join('/'))
      const dateB = new Date(b.dataProd.split('/').reverse().join('/'))
      return dateB - dateA
    })

    localStorage.setItem('data', JSON.stringify(newData))

    setData(newData)
  }

  return (
    <>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Informe o nome do usuário da operação</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="user"
                name="user"
                label="Usuário"
                type="text"
                fullWidth
                error={!!message}
                helperText={message}
                variant="outlined"
                disabled={isLoading}
                value={userName}
                onChange={e => setUserName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="user"
                name="user"
                label="Observação"
                type="text"
                fullWidth
                variant="outlined"
                rows={4}
                multiline
                disabled={isLoading}
                value={observation}
                onChange={e => setObservation(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            startIcon={<Close />}
            disabled={isLoading}
            onClick={() => setOpenDialog(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disabled={isLoading}
            startIcon={isLoading ? <CircularProgress size={20} /> : <Save />}
            onClick={() => {
              if (operation === 2) {
                handleSaveDevolution()
              } else if (operation === 1) {
                handleSaveExit()
              }
            }}
          >
            {isLoading ? 'Salvando...' : 'Salvar'}
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ mt: 7.5 }}>
        <ViewElearning pageName={'TransferOrder'} open={viewElearning} setOpen={setViewElearning} />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Controle amostra nova</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        {/* Criar Transferencia */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>
              {editing ? 'Editar Controle amostra nova' : 'Cadastrar Controle amostra nova'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="">
                <TransferOrderItem
                  setEditing={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  //movimentationOrderId={}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="">
                <NewSampleControlCreate
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  onClick={form => handleAddNewItem(form)} //handleFetchData={}
                  //handleFetchData={fetchData}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>

        {/* Deletar */}

        <FormControl sx={{ width: '100%', mt: 2 }}>
          <InputLabel id="demo-simple-select-label">Filial</InputLabel>
          <Select label="Selecione a empresa" value={subCompanyId}>
            {subCompany
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => {
                return (
                  <MenuItem value={item.subCompanyId} key={index}>
                    {item.name}
                  </MenuItem>
                )
              })}
          </Select>
        </FormControl>

        {/* <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
            {transferOrder.length > 0 && (
              <Button onClick={downloadData} variant="contained" endIcon={<AiFillFileExcel />}>
                Excel
              </Button>
            )}
          </Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', ml: 1 }}
          >
            <PermissionComponent role="TransferCREATE">
              <Button
                variant="contained"
                endIcon={<AiFillPlusCircle />}
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
              >
                Criar
              </Button>
            </PermissionComponent>
          </Box>
        </Box> */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 1,
            mt: 2,
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Pesquisar"
            type="search"
            onInput={e => setSearch(e.target.value)}
          />
          {searching && <Typography>Buscando...</Typography>}
          <Button variant="contained" startIcon={<Add />} onClick={() => setExpanded(true)}>
            Adicionar
          </Button>
        </Box>

        <TableContainer component={Paper} sx={{ mt: '2rem' }}>
          <Typography sx={{ width: 1, my: 2 }} variant="h6">
            {data.length > 1
              ? `${data.length} Resultados`
              : data.length < 1 && `${data.length} Resultado`}
          </Typography>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">DES:</StyledTableCell>
                <StyledTableCell align="left">VAR:</StyledTableCell>
                <StyledTableCell align="left">CAIXA</StyledTableCell>
                <StyledTableCell align="left">BASE</StyledTableCell>
                <StyledTableCell align="left">CADASTRO</StyledTableCell>
                <StyledTableCell align="left">CLIENTE</StyledTableCell>
                <StyledTableCell align="left">OBSERVAÇÃO</StyledTableCell>
                <StyledTableCell align="left">DATA D. PROD</StyledTableCell>
                <StyledTableCell align="left">QUEM</StyledTableCell>
                <StyledTableCell align="left">RETIRADA</StyledTableCell>
                <StyledTableCell align="left">DEVOLUÇÃO</StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell align="left">{row.des}</StyledTableCell>
                    <StyledTableCell align="left">{row.var}</StyledTableCell>
                    <StyledTableCell align="left">{row.caixa}</StyledTableCell>
                    <StyledTableCell align="left">{row.base}</StyledTableCell>
                    <StyledTableCell align="left">{row.cadastro}</StyledTableCell>
                    <StyledTableCell align="left">{row.cliente}</StyledTableCell>
                    <StyledTableCell align="left">{row.observation}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.dataProd && new Date(row.dataProd).getTime()
                        ? new Date(row.dataProd).toLocaleDateString('pt-BR')
                        : ''}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.quem}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.saida && new Date(row.saida).getTime()
                        ? new Date(row.saida).toLocaleDateString('pt-BR')
                        : ''}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.devolution && new Date(row.devolution).getTime()
                        ? new Date(row.devolution).toLocaleDateString('pt-BR')
                        : ''}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {(row.devolution === '' || row.devolution === null) && row.saida ? (
                        <Button variant="contained" onClick={() => handleDevolucaoClick(row.id)}>
                          Devolução
                        </Button>
                      ) : (row.saida === '' || row.saida === null) && row.devolution ? (
                        <Button variant="contained" onClick={() => handleDevolucaoClick(row.id)}>
                          Retirada
                        </Button>
                      ) : (row.devolution === '' || row.devolution === null) &&
                        (row.saida === '' || row.saida === null) ? (
                        <Button variant="contained" onClick={() => handleSaidaClick(row.id)}>
                          Retirada
                        </Button>
                      ) : (
                        row.devolution &&
                        row.saida && (
                          <Button variant="contained" onClick={() => handleSaidaClick(row.id)}>
                            Retirada
                          </Button>
                        )
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default NewSampleControl
