import {
  Box,
  Button,
  CardContent,
  Checkbox,
  Divider,
  LinearProgress,
  Typography,
} from '@mui/material'
import React from 'react'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

const BreakCard = props => {
  const item = props.info

  const ProgressBar = props => {
    const { value, total } = props
    const percent = Math.floor((value / total) * 100)

    return <LinearProgress variant="determinate" value={percent} />
  }

  const ReturnStatus = props => {
    const { numero } = props
    let colorFont = '#000000'
    let texto = 'Aberto'

    if (numero === 1) {
      texto = 'Aberto'
      colorFont = '#000000'
    } else if (numero === 2) {
      colorFont = '#008000'
      texto = 'Completo'
    } else if (numero === 3) {
      colorFont = '#FFA500'
      texto = 'Em andamento'
    } else if (numero === -2) {
      colorFont = '#FF0000'
      texto = 'Cancelado'
    } else if (numero === -1) {
      texto = 'Rascunho'
    }
    const styleStatus = { color: `${colorFont}` }
    return <span style={styleStatus}>{texto}</span>
  }
  return (
    <Box sx={{ minWidth: '250px', width: '250px', maxWidth: '250px', mt: 0, flexGrow: '1' }}>
      {/* <LinearProgress
    variant="determinate"
    value={Math.floor((item.quantityFinishOrder / item.quantityInicial) * 100)}
  /> */}
      <ProgressBar
        value={item.quantityFinishOrderBreak}
        total={item.quantityInicialBreak}
        colorType={item.orderStatus}
      />
      <Box sx={{ display: 'flex' }}>
        {/* <Box sx={{ display: 'flex', alignContent: 'center' }}>
        <Checkbox />
      </Box> */}
        <CardContent sx={{ width: '100%' }}>
          <Typography variant="h6" component="div">
            #{item.movimentationOrderNumberOtherSystemBreak}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Iniciada em {new Date(item.startOrderBreak).toLocaleString()}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Status: <ReturnStatus numero={item.orderStatus} /> ({item.quantityFinishOrderBreak} /{' '}
            {item.quantityInicialBreak})
          </Typography>
          <Typography variant="overline" gutterBottom sx={{width: 1, textAlign: "center", display: "block"}}>ETAPAS</Typography>

          {item.movimentationBreakKambanOrderItemProcessCollection.length > 0 && (
            <>
              {item.movimentationBreakKambanOrderItemProcessCollection.map(i => (
                <>
                  <Divider variant="middle" />
                  <Box
                    sx={{
                      border: '1px solid #e0e0e0',
                      borderRadius: 1,
                      width: 1,
                      padding: 1,
                      marginY: 1,
                    }}
                  >
                    <Typography>{i.processSequenceName && i.processSequenceName}</Typography>
                    <Typography>Início: {new Date(i.startDateProcess).toLocaleString()}</Typography>
                    <Typography>Usuário: {i.userNameStart}</Typography>
                    <br />
                    {/* <Typography>
                            {i.qualityStatus && `Qualidade: ${i.qualityStatus}`}
                          </Typography>
                          <br />
                          <Typography>
                            {i.serialActual && `Serial Atual: ${i.serialActual}`}
                          </Typography>
                          <Typography>
                            {i.serialLast && `Último Serial: ${i.serialLast}`}
                          </Typography> */}
                    <Typography>Término: {new Date(i.finishDateProcess).toLocaleString()}</Typography>
                    <Typography>Usuário: {i.userNameFinish}</Typography>
                  </Box>
                </>
              ))}
            </>
          )}
        </CardContent>
        {/* <Box
        sx={{ display: 'flex', alignContent: 'flex-end', justifyContent: 'center' }}
      >
        <Button
          size="small"
          onClick={e => props.fetchSingleBreak(item.movimentationOrderNumberOtherSystemBreak)}
        >
          <ArrowForwardIosIcon fontSize="large" sx={{ margin: 'auto 0' }} />
        </Button>
      </Box> */}
      </Box>
      {/* <CardActions>
      <Button
        size="small"
        onClick={e => fetchSingleBreak(item.movimentationOrderBreakId)}
      >
        Exibir Informações
      </Button>
    </CardActions> */}
    </Box>
  )
}

export default BreakCard
