export function convertToMeters(value, unit) {
  const conversionRates = {
    km: 1000,
    m: 1,
    cm: 0.01,
    mm: 0.001,
    mi: 1609.34,
    yd: 0.9144,
    ft: 0.3048,
    in: 0.0254,
  }

  return value * conversionRates[unit]
}
