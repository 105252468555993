import { useState, useContext, useEffect, useRef } from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Context } from '../../../context/AuthContext'
import Alert from '@mui/material/Alert'
import * as XLSX from 'xlsx'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
  Chip,
  Select,
  MenuItem,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import styled from '@emotion/styled'
import { colorTableDefault } from '../../TemplateDefault'
import { Add, DeleteForever, Edit, ExpandMore, Replay } from '@mui/icons-material'

import { createDepositService, getDepositService } from '../../../services/depositService'
import { getProductList } from '../../../services/productService'
import { updateQuantityStock } from '../../../services/movBreakServices'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const UploadStcokActual = () => {
  const { subCompany, company, nameUrl } = useContext(Context)
  const [file, setFile] = useState(null)
  const [valueFile, setValueFile] = useState('')
  const token = localStorage.getItem('token')
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [titleDialog, setTitleDialog] = useState('')
  const [severity, setSeverity] = useState('error')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [statusMessage, setStatusMessage] = useState({})
  const [statusTable, setStatusTable] = useState(false)
  const [statusInfo, setStatusInfo] = useState(false)
  const [currentBatchIndex, setCurrentBatchIndex] = useState(0)
  const [progress, setProgress] = useState(0)

  const [dialogAction, setDialogAction] = useState(null)

  const [singleProd, setSingleProd] = useState({
    id: '',
    idProductPartner: '',
    name: '',
    description: '',
    price: '',
  })

  const [status, setStatus] = useState('Pronto')
  const [deleteId, setDeleteId] = useState('')
  const [search, setSearch] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('')

  const [edit, setEdit] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)
  const [itemExcel, setItemExceç] = useState([])
  const [itemProd, setItensProd] = useState([])
  const [selectedItems, setSelectedItems] = useState([])

  const [filter, setFilter] = useState([])
  const [selectAll, setSelectAll] = useState(false)

  const [sentProdsCount, setSentProdsCount] = useState(0)
  const [failedProds, setFailedProds] = useState([])
  const [numberItem, setNumberItem] = useState([])
  const [listOrderExit, setListOrderExist] = useState([])
  const [totaProdsSelectedCount, setTotaProdsSelectedCount] = useState(0)
  const ref = useRef()
  const [loadingMap, setLoadingMap] = useState({})
  const [subCompanyId, setSubCompanyId] = useState('')
  const [depositId, setDepositId] = useState('')
  const [subCompanyName, setSubCompanyName] = useState('')
  const [depositName, setDepositName] = useState('')
  const [deposit, setDeposit] = useState([])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setSelectAll(event.target.value === 'All')
  }

  const handleItemSelect = itemId => {
    setSelectedItems(prevSelectedItems => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter(id => id !== itemId)
      } else {
        return [...prevSelectedItems, itemId]
      }
    })
  }

  const handleSelectAll = event => {
    const isChecked = event.target.checked

    setSelectAll(isChecked)
    setSelectedItems(prevSelectedItems => {
      if (!selectAll) {
        return filter.map((item, index) => item.idProductPartner)
      } else {
        return []
      }
    })
    setRowsPerPage(isChecked ? filter.length + 1 : 10)
  }

  useEffect(() => {
    if (selectedItems.length === filter.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }, [selectedItems, filter])

  useEffect(() => {}, [singleProd])

  useEffect(() => {}, [itemExcel, statusMessage])

  const handleSelectFile = e => {
    setLoading(true)
    setItensProd([])
    setValueFile(e.target.name[0])
    const selectedFile = e.target.files[0]

    if (!selectedFile) {
      setFile(null)
      return
    }
    setFile(selectedFile)
    //sendFile(selectedFile)
    readExcel(selectedFile)
  }
  const readExcel = async file => {
    const reader = new FileReader()

    reader.onload = async e => {
      const data = e.target.result
      const workbook = XLSX.read(data, { type: 'binary' })
      const firstSheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[firstSheetName]

      const excelData = []
      const range = XLSX.utils.decode_range(worksheet['!ref'])

      for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
        const row = []
        let hasData = false

        for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
          const cellAddress = { c: colNum, r: rowNum }
          const cellRef = XLSX.utils.encode_cell(cellAddress)
          const cell = worksheet[cellRef]

          if (cell && cell.v !== undefined && cell.v !== null) {
            hasData = true
          }

          row.push(cell ? cell.v : undefined)
        }

        if (hasData) {
          excelData.push(row)
        }
      }
      const dataList = excelData.map(item => ({
        idProductPartner: item[0] !== undefined && item[0] !== null ? String(item[0]) : '0',
        quantity: item[1] !== undefined && item[1] !== null ? String(item[1]) : '0',
        subCompanyId: subCompanyId,
        depositId: depositId,
      }))

      await handleGetProductsCode(dataList)

      setLoading(false)
      setValueFile('')
      resetFile()
    }

    reader.readAsBinaryString(file)
  }
  const handleGetProductsCode = async dataList => {
    const codProductList = dataList.map(item => item.idProductPartner)
    console.log(dataList, 'dataList')

    try {
      const response = await getProductList(token, nameUrl, company.companyId, codProductList)

      const productMap = response.data.reduce((acc, product) => {
        acc[product.idProductPartner] = product
        return acc
      }, {})

      const foundProducts = []
      const notFoundProducts = []

      dataList.forEach(item => {
        const product = productMap[item.idProductPartner]

        if (product) {
          foundProducts.push({
            idProductPartner: item.idProductPartner,
            productName: product.name,
            productId: product.productId,
            quantity: item.quantity,
            depositName: depositName,
            depositId: item.depositId,
            subCompanyId: item.subCompanyId,
            subCompanyName: subCompanyName,
          })
        } else {
          notFoundProducts.push({
            idProductPartner: item.idProductPartner,
            productName: 'Produto não encontrado',
            productId: null,
            quantity: item.quantity,
            depositId: item.depositId,
            subCompanyId: item.subCompanyId,
            depositName: depositName,
            subCompanyName: subCompanyName,
          })
        }
      })

      const finalProductList = [...foundProducts, ...notFoundProducts]

      setItensProd(finalProductList)
    } catch (error) {
      console.error('Erro ao buscar os produtos:', error)
    }
  }

  const resetFile = () => {
    ref.current.value = ''
  }

  useEffect(() => {}, [itemProd])

  useEffect(() => {}, [numberItem])

  const handleActionList = action => {
    if (selectedItems.length === 0) {
      setSeverity('error')
      setTitle('Selecione um item')
      setAlerta(true)
    } else {
      setOpen(true)
      setTitleDialog('Tem certeza que enviar esses itens?')
      setDialogAction(action)
    }
  }

  const handleSaveUnity = async data => {
    setStatusTable(true)
    setSentProdsCount(0)
    setFailedProds([])

    try {
      const updatedFilter = filter.map(row => {
        if (row.idProductPartner === data.idProductPartner) {
          return {
            ...row,
            loading: true,
          }
        }
        return row
      })

      const dataList = {
        productId: data.productId,
        depositId: data.depositId,
        subCompanyId: data.subCompanyId,
        quantity: Number(data.quantity),
      }

      setFilter(updatedFilter)
      await new Promise(resolve => setTimeout(resolve, 1000))

      const response = await updateQuantityStock(token, nameUrl, dataList)

      const updatedFilterWithStatus = updatedFilter.map(row => {
        if (row.idProductPartner === data.idProductPartner) {
          return {
            ...row,
            loading: false,
            status: response.data.message,
          }
        }
        return row
      })
      setFilter(updatedFilterWithStatus)
      setSeverity('success')
      await new Promise(resolve => setTimeout(resolve, 1000))

      const updatedFilterWithoutSuccess = updatedFilterWithStatus.filter(
        row => row.idProductPartner !== data.idProductPartner
      )

      setSentProdsCount(prevSentProdsCount => prevSentProdsCount + 1)
      setFilter(updatedFilterWithoutSuccess)
    } catch (error) {
      const updatedFilter = filter.map(row => {
        if (row.idProductPartner === data.idProductPartner) {
          return {
            ...row,
            loading: false,
            status: 'Falha ao enviar, tente novamente!',
          }
        }
        return row
      })
      setSeverity('error')
      setFilter(updatedFilter)
      setFailedProds(prevSentProdsCount => prevSentProdsCount + 1)
    }
  }

  const handleSaveProductsSelected = async () => {
    setStatusInfo(true)
    setStatusTable(true)
    setSentProdsCount(0)
    setFailedProds([])
    setProgress(0)

    const selectedProducts = itemProd.filter(prod => selectedItems.includes(prod.idProductPartner))
    setTotaProdsSelectedCount(selectedProducts.length)

    const updatedFilter = filter.map(row => {
      if (row.idProductPartner === data.idProductPartner) {
        return {
          ...row,
          loading: true,
        }
      }
      return row
    })

    setFilter(updatedFilter)
    const data = selectedProducts.map(item => ({
      productId: item.productId,
      depositId: item.depositId,
      subCompanyId: item.subCompanyId,
      quantity: Number(item.quantity),
    }))

    for (const selectedProduct of data) {
      try {
        console.log(filter, 'filter')
        // Set status to 'Enviando...' and loading to true for the current product
        setFilter(prevFilter =>
          prevFilter.map(row => {
            if (row.idProductPartner === selectedProduct.idProductPartner) {
              return {
                ...row,
                loading: true,
                status: 'Enviando...',
              }
            }
            return row
          })
        )

        await new Promise(resolve => setTimeout(resolve, 1000))
        const response = await updateQuantityStock(token, nameUrl, selectedProduct)

        // Set status to 'Registro feito com sucesso' and loading to false for the current product
        setFilter(prevFilter =>
          prevFilter.map(row => {
            if (row.idProductPartner === selectedProduct.idProductPartner) {
              return {
                ...row,
                loading: false,
                status: 'Registro feito com sucesso',
              }
            }
            return row
          })
        )

        setSeverity('success')
        await new Promise(resolve => setTimeout(resolve, 1000))

        // Remove the successfully sent product from the filter
        setFilter(prevFilter =>
          prevFilter.filter(row => row.idProductPartner !== selectedProduct.idProductPartner)
        )

        // Update sent products count
        setSentProdsCount(prevSentProdsCount => prevSentProdsCount + 1)

        // Remove the successfully sent product from itemProd
        setItensProd(prevItemProd =>
          prevItemProd.filter(item => item.idProductPartner !== selectedProduct.idProductPartner)
        )
      } catch (error) {
        // Set status to 'Falha ao enviar, tente novamente!' and loading to false for the current product
        setFilter(prevFilter =>
          prevFilter.map(row => {
            if (row.idProductPartner === selectedProduct.idProductPartner) {
              return {
                ...row,
                loading: false,
                status: 'Falha ao enviar, tente novamente!',
              }
            }
            return row
          })
        )

        setSeverity('error')
        setFailedProds(prevFailedProdsCount => prevFailedProdsCount + 1)
      }
    }

    setStatusTable(false)
    setSelectedItems([])
    setStatusInfo(false)
  }

  const handleSaveProd = async () => {
    setStatusInfo(true)
    setStatusTable(true)
    setSentProdsCount(0)
    setFailedProds([])
    setProgress(0)

    const totalItems = itemProd.length
    const batchSize = 20
    setNumberItem(totalItems)
    const numBatches = Math.ceil(totalItems / batchSize)
    let updatedFilter = [...itemProd]

    for (let batchIndex = 0; batchIndex < numBatches; batchIndex++) {
      try {
        await new Promise(resolve => setTimeout(resolve, 1000))
        setCurrentBatchIndex(batchIndex + 1)
        const batchStart = batchIndex * batchSize
        const batchEnd = Math.min((batchIndex + 1) * batchSize, totalItems)
        const batchProducts = itemProd.slice(batchStart, batchEnd)

        const newProgress = ((batchIndex + 1) / numBatches) * 100
        setProgress(newProgress)

        setFilter(prevFilter => {
          return prevFilter.map(row => {
            if (batchProducts.some(product => product.idProductPartner === row.idProductPartner)) {
              return {
                ...row,
                loading: true,
                status: 'Enviando...',
              }
            }
            return row
          })
        })

        await new Promise(resolve => setTimeout(resolve, 1000))
        const requests = batchProducts.map(item => {
          const data = {
            productId: item.productId,
            depositId: item.depositId,
            subCompanyId: item.subCompanyId,
            quantity: Number(item.quantity),
          }
          return updateQuantityStock(token, nameUrl, data)
        })

        const responses = await Promise.allSettled(requests)

        responses.forEach((response, index) => {
          const product = batchProducts[index]
          if (response.status === 'fulfilled') {
            setFilter(prevFilter => {
              return prevFilter.map(row => {
                if (row.idProductPartner === product.idProductPartner) {
                  return {
                    ...row,
                    loading: false,
                    status: 'Registro feito com sucesso',
                  }
                }
                return row
              })
            })
            updatedFilter = updatedFilter.filter(
              row => row.idProductPartner !== product.idProductPartner
            )
            setSentProdsCount(prevSentProdsCount => prevSentProdsCount + 1)
          } else {
            setFilter(prevFilter => {
              return prevFilter.map(row => {
                if (row.idProductPartner === product.idProductPartner) {
                  return {
                    ...row,
                    loading: false,
                    status: 'Falha ao enviar, tente novamente!',
                  }
                }
                return row
              })
            })
            setSeverity('error')
            setFailedProds(prevFailedProds => [...prevFailedProds, product])
          }
        })

        setFilter(updatedFilter)
      } catch (error) {
        setFilter(prevFilter => {
          return prevFilter.map(row => {
            if (itemProd.some(product => product.idProductPartner === row.idProductPartner)) {
              return {
                ...row,
                loading: false,
                status: 'Falha ao enviar, tente novamente!',
              }
            }
            return row
          })
        })
        setSeverity('error')
        setFailedProds(prevFailedProds => prevFailedProds + itemProd.length)
      }
    }

    setStatusTable(false)
    setStatusInfo(false)
  }

  const deleteDialog = (clientId, del) => {
    setDeleteId(clientId)
    setOpen(true)
    setTitleDialog('Tem certeza que deseja excluir este item?')
    setDialogAction(del)
  }

  const handleDeleteItem = () => {
    const updatedItems = filter.filter(item => item.idProductPartner !== deleteId)
    setSeverity('success')
    setTitle('Item apagado com sucesso!')
    setAlerta(true)
    setItensProd(updatedItems)
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
    setAlerta(false)
  }

  const filterRow = () => {
    const filtered = itemProd.filter(
      row =>
        row.idProductPartner.toLowerCase().includes(search.toLowerCase()) ||
        row.productName.toLowerCase().includes(search.toLowerCase())
    )
    setFilter(filtered)
  }

  useEffect(() => {
    filterRow()
  }, [search, itemProd])

  const fetchData = async values => {
    setSubCompanyId(values)
    setLoading(true)
    const dados = {
      limit: 500,
      order: 0,
      offset: 0,
      subCompanyId: values,
    }
    try {
      const response = await getDepositService(token, nameUrl, dados)
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
        setDeposit([])
      } else {
        setDeposit(response.data.result)
        //setPageCount(response.data.totalpage + 1)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        {statusInfo && (
          <Backdrop
            sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
            open={statusInfo}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              position="relative"
            >
              <CircularProgress value={progress} sx={{ color: colorTableDefault }} size={60} />
              <Box
                sx={{
                  position: 'absolute',
                  top: '26%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <Typography>{Math.round(progress)}%</Typography>
              </Box>
              <Box textAlign="center" p={2}>
                {selectedItems.length > 0 ? (
                  <Typography>
                    {totaProdsSelectedCount.toLocaleString()} de {Math.floor(sentProdsCount)}{' '}
                    Produtos Enviados...
                  </Typography>
                ) : (
                  <Typography>
                    {numberItem.toLocaleString()} de {sentProdsCount.toLocaleString()} Produtos
                    Enviados...
                  </Typography>
                )}
              </Box>
            </Box>
          </Backdrop>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>{titleDialog}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              onClick={() => {
                handleClose()
                if (dialogAction === 'delete') {
                  handleDeleteItem()
                } else if (dialogAction === 'save') {
                  handleSaveProductsSelected()
                }
                setDialogAction(null)
              }}
            >
              Sim
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>

        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Carga de estoque atual</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl sx={{ width: '100%', mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                value={subCompanyId}
                sx={{ width: '100%' }}
                label="Escolher o Usuário"
                onChange={e => {
                  setSubCompanyId(e.target.value)
                  fetchData(e.target.value)

                  const subCompanyName = subCompany.find(sub => sub.subCompanyId === e.target.value)
                  setSubCompanyName(subCompanyName.name)
                }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl sx={{ width: '100%', mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Deposito</InputLabel>
              <Select
                value={depositId}
                sx={{ width: '100%' }}
                label="Escolher o Usuário"
                onChange={e => {
                  setDepositId(e.target.value)
                  const depositName = deposit.find(sub => sub.depositId === e.target.value)
                  setDepositName(depositName.name)
                }}
              >
                {deposit.map((item, index) => {
                  return (
                    <MenuItem value={item.depositId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box
          sx={{ justifyContent: 'space-between', width: 1, minWidth: 1 }}
          display={{ xs: 'column', sm: 'column', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box
            sx={{
              alignItems: 'center',
              textAlign: 'center',
              mt: 2,
              border: '1px solid black',
              width: { xs: 1, md: 0.34 },
              minWidth: { xs: 1, md: 0.3 },
              p: 2,
            }}
          >
            <a
              className="dropzone"
              target="_blank"
              rel="noreferrer"
              href="https://file.bihands.com/template/UploadStockActual.xlsx"
              download
            >
              <FileDownloadIcon sx={{ fontSize: 100 }} />
            </a>
            <InputLabel>Faça o download do exemplo de carga</InputLabel>
          </Box>
          <Box
            sx={{
              mt: 2,
              border: { md: '1px solid black' },
              p: 2,
            }}
            width={{ xs: '100%', sm: '100%', md: '65%', lg: '65%', xl: '65%' }}
          >
            <InputLabel>Insira aqui o documento para enviar</InputLabel>
            <TextField
              sx={{ width: '100%', mt: 1 }}
              id="standard-basic"
              variant="standard"
              value={valueFile}
              disabled={!depositId}
              onChange={handleSelectFile}
              ref={ref}
              type="file"
              accept=".xls, .xlsx, .ods"
            />
            <Box
              sx={{
                mt: 4,
                alignItems: 'center',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>Apenas arquivos .XLS ou .XLSX</Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    sx={{ width: '100%', mb: 1 }}
                    id="standard-basic"
                    variant="outlined"
                    label="Procurar"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    type="text"
                  />
                </Grid>
              </Grid>

              <Box display="flex" justifyContent="space-between" alignItems="end">
                <Box>
                  <Typography variant="body" sx={{ marginBottom: 2, mr: 0 }}>
                    {filter.length <= 1
                      ? `${filter.length} Resultado`
                      : `${filter.length} Resultados`}
                  </Typography>
                  {selectedItems.length > 0 && (
                    <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                      {selectedItems.length <= 1
                        ? `${selectedItems.length} Item Selecionado`
                        : `${selectedItems.length} Itens Selecionados`}
                    </Typography>
                  )}
                  {statusTable && (
                    <>
                      <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                        {listOrderExit.length > 0 && `${listOrderExit.length} ordens existentes`}
                      </Typography>
                    </>
                  )}
                  {statusTable && (
                    <>
                      <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                        {sentProdsCount <= 1
                          ? `${sentProdsCount} Itens Enviado`
                          : `${sentProdsCount} Itens Enviados`}
                      </Typography>
                      <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                        {failedProds.length > 1
                          ? `${failedProds.length} Itens não enviados`
                          : `${failedProds.length} Iten não enviados`}
                      </Typography>
                    </>
                  )}
                </Box>
                {selectedItems.length > 0 ? (
                  <Button
                    variant="contained"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: ' space-between',
                      fontSize: '.9em',
                    }}
                    onClick={() => handleActionList('save')}
                  >
                    Enviar Selecionados
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: ' space-between',
                      fontSize: '.9em',
                    }}
                    onClick={() => handleSaveProd()}
                  >
                    Enviar Lista
                  </Button>
                )}
              </Box>
            </Box>

            <Table sx={{ minWidth: 700, mt: 1 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {/* <StyledTableCell align="center">
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <Tooltip title="Selecionar Todos" arrow>
                        <span>
                          <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAll}
                            color="primary"
                          />
                        </span>
                      </Tooltip>
                    </Box>
                  </StyledTableCell> */}
                  <StyledTableCell align="center">Codigo do produto</StyledTableCell>
                  <StyledTableCell align="left">Nome</StyledTableCell>
                  <StyledTableCell align="center">Quantidade</StyledTableCell>
                  <StyledTableCell align="center">Deposito</StyledTableCell>
                  <StyledTableCell align="center">Filial</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">Ação</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filter
                  .filter(
                    row =>
                      selectedStatus === '' ||
                      statusMessage[row.idProductPartner] === selectedStatus
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      {/* <StyledTableCell align="center">
                        <Checkbox
                          checked={selectedItems.includes(row.idProductPartner)}
                          onChange={() => handleItemSelect(row.idProductPartner)}
                        />
                      </StyledTableCell> */}
                      <StyledTableCell align="center">{row.idProductPartner}</StyledTableCell>
                      <StyledTableCell align="left">{row.productName}</StyledTableCell>
                      <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                      <StyledTableCell align="left">{row.depositName}</StyledTableCell>
                      <StyledTableCell align="left">{row.subCompanyName}</StyledTableCell>
                      <StyledTableCell align="center">
                        {row.loading ? (
                          <Box display="flex" alignItems="center" justifyContent="center">
                            <CircularProgress size={30} />
                            <Typography ml={2} variant="caption">
                              Enviando...
                            </Typography>
                          </Box>
                        ) : (
                          <Chip
                            color={
                              row.status === 'Falha ao enviar, tente novamente!'
                                ? 'error'
                                : row.status === 'Registro feito com sucesso'
                                ? 'success'
                                : 'info'
                            }
                            label={
                              row.status === 'Falha ao enviar, tente novamente!'
                                ? 'Falha ao enviar'
                                : row.status === 'Registro feito com sucesso'
                                ? 'Registro feito com sucesso'
                                : 'Pronto para enviar'
                            }
                          />
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <Tooltip title="Reenviar" arrow>
                            <IconButton onClick={() => handleSaveUnity(row)}>
                              <Replay sx={{ cursor: 'pointer' }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Apagar" arrow>
                            <IconButton
                              onClick={() => deleteDialog(row.idProductPartner, 'delete')}
                            >
                              <DeleteForever sx={{ cursor: 'pointer' }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filter.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default UploadStcokActual
