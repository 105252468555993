import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { ClientDelete, ClientEdit, ClientCreate } from '../../../const/RoleConstants'
import HelpIcon from '@mui/icons-material/Help'
import { setPermission } from '../../../permissions/userPermissions'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Tooltip,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { AiFillPlusCircle } from 'react-icons/ai'

//icones react-icons
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import { deleteClient, getClient } from '../../../services/clientService'
import ClientPCreate from '../../Create/ClientCreate'
import ClientPEdit from '../../Edit/ClientPEdit'
import PermissionComponent from '../../../permissions'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const Client = () => {
  const token = localStorage.getItem('token')
  const { subCompany, userRoles, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [idEditing, setIdEditing] = useState({})

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)
  const [filterTable, setFilterTable] = useState('')
  const [filterCNPJ, setFilterCNPJ] = useState('')
  const [clientList, setClientList] = useState([])
  const [filterRow, setFilterRow] = useState([])
  const [notSearch, setNotSearch] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const fetchData = async subCompanyId => {
    if (!subCompanyId) {
      return
    }

    setSubCompanyId(subCompanyId)
    setLoading(true)
    try {
      const response = await getClient(token, nameUrl, subCompanyId)

      if (response.data.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setClientList(response.data)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (subCompanyId) {
      fetchData(subCompanyId)
    }
  }, [subCompanyId])
  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleEdit = item => {
    setIdEditing(item)
    setExpanded(true)
    setEditing(true)
    window.scrollTo(0, 0)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    const currentIdDelete = idDelete

    const data = {
      clientId: currentIdDelete,
    }
    try {
      const response = await deleteClient(token, nameUrl, data)

      fetchData(subCompanyId)
      setTitle(response.data)
      setSeverity('success')
      setAlerta(true)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  const filter = () => {
    const filtered = clientList.filter(
      row =>
        row.codClient.toLowerCase().includes(filterTable.toLowerCase()) ||
        row.name.toLowerCase().includes(filterTable.toLowerCase()) ||
        row.email.toLowerCase().includes(filterTable.toLowerCase()) ||
        row.cpF_CNPJ.toLowerCase().includes(filterTable.toLowerCase())
    )

    setFilterRow(filtered)
  }

  useEffect(() => {
    console.log(idEditing, 'idEditing')
  }, [idEditing])

  useEffect(() => {
    filter()
  }, [filterTable, clientList, notSearch])

  const handleClearFilter = () => {
    setFilterTable('')
    setFilterCNPJ('')
  }

  return (
    <>
      <Box sx={{ mt: 7.5, height: 'fit-content' }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Clientes</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon />
          </IconButton>
        </Box>
        {/* Criar  */}
        <Accordion expanded={expanded} variant="outlined">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>{editing ? 'Editando Cliente' : 'Cadastrar Cliente'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="Edit_Client">
                <ClientPEdit
                  setEditing={setEditing}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={fetchData}
                  setExpanded={setExpanded}
                  id={idEditing}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="Create_Client">
                <ClientPCreate
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={fetchData}
                />
              </PermissionComponent>
            )}
            {/* <ClientEdit
              setEditing={setEditing}
              setAlerta={setAlerta}
              setSeverity={setSeverity}
              setTitle={setTitle}
              companyId={idEditing}
              handleFetchData={fetchData}
              setExpanded={setExpanded}
            />
            <ClientCreate
              setExpanded={setExpanded}
              setAlerta={setAlerta}
              setSeverity={setSeverity}
              setTitle={setTitle}
              handleFetchData={fetchData}
              />*/}
          </AccordionDetails>
        </Accordion>

        <SelectSubCompany
          value={subCompanyId}
          onChange={e => {
            fetchData(e.target.value)
          }}
        />
        <Box
          sx={{
            mt: 3,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              ml: 1,
            }}
          >
            <PermissionComponent role="Create_Client">
              <Button
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
                variant="contained"
                endIcon={<AiFillPlusCircle />}
              >
                Criar
              </Button>
            </PermissionComponent>
          </Box>
        </Box>

        {/* Deletar */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>

        {clientList.length > 0 && (
          <>
            <Box
              sx={{ width: '100%', alignItems: 'end', justifyContent: 'space-between', mb: 2 }}
              display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
            >
              <Box
                width={{ xs: '100%', sm: '100%', md: '35%', lg: '35%', xl: '35%' }}
                sx={{ mt: 2 }}
              >
                <TextField
                  variant="outlined"
                  label={'Filtrar Cliente'}
                  value={filterTable}
                  onChange={values => setFilterTable(values.target.value)}
                  sx={{ width: '100%' }}
                  // name="endDate"
                  type="text"
                  error={notSearch}
                />
              </Box>
              <Button variant="contained" onClick={() => handleClearFilter()} sx={{ mt: 2 }}>
                Limpar filtros
              </Button>
            </Box>

            <TableContainer component={Paper} sx={{ mt: '2rem' }}>
              <Box>
                <Typography>
                  {filterRow.length <= 1
                    ? `${filterRow.length} Resultado`
                    : `${filterRow.length} Resultados`}
                </Typography>
              </Box>
              <Table sx={{ minWidth: 700, mt: 1 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Codigo do Cliente</StyledTableCell>
                    <StyledTableCell align="center">Nome do Cliente</StyledTableCell>
                    <StyledTableCell align="center">Email</StyledTableCell>
                    <StyledTableCell align="center">CPF/CNPJ</StyledTableCell>
                    <StyledTableCell align="center">CEP</StyledTableCell>
                    <StyledTableCell align="center">Mobile</StyledTableCell>
                    <StyledTableCell align="center">Vendedor</StyledTableCell>
                    <StyledTableCell> </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterRow
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <StyledTableRow key={row.clientId}>
                        <StyledTableCell align="center">{row.codClient}</StyledTableCell>
                        <StyledTableCell align="center">{row.name}</StyledTableCell>
                        <StyledTableCell align="center">{row.email}</StyledTableCell>
                        <StyledTableCell align="center">{row.cpF_CNPJ}</StyledTableCell>
                        <StyledTableCell align="center">{row.cep}</StyledTableCell>
                        <StyledTableCell align="center">{row.mobile}</StyledTableCell>
                        <StyledTableCell align="center">{row.vendor}</StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                            <PermissionComponent role="Delete_Client">
                              <Tooltip title="Apagar" arrow>
                                <IconButton>
                                  <DeleteForeverIcon
                                    onClick={() => deleteAlert(row.clientId)}
                                    sx={{ cursor: 'pointer' }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </PermissionComponent>

                            <PermissionComponent role="Edit_Client">
                              <Tooltip title="Editar" arrow>
                                <IconButton>
                                  <ModeEditOutlineIcon
                                    onClick={() => handleEdit(row.clientId)}
                                    sx={{ cursor: 'pointer' }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </PermissionComponent>
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={clientList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </>

          // <Box
          //   sx={{
          //     mt:'2rem',
          //     minHeight: 600,
          //     width: '100%',
          //     '& .super-app-theme--header': {
          //       backgroundColor: colorTableDefault,
          //       width:'100%'
          //     },
          //   }}
          // >
          //   <DataGrid
          //     sx={{width:'100%'}}
          //     autoHeight
          //     // autoPageSize
          //     rows={company}
          //     columns={columns}
          //     getRowId={(row) => row.companyId}
          //     initialState={{
          //       pagination: {
          //         paginationModel: {
          //           pageSize: 5,
          //         },
          //       },
          //     }}
          //     pageSizeOptions={[5, 10, 25]}
          //     disableRowSelectionOnClick
          //     localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          //   />
          // </Box>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default Client
