import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Select, InputLabel, MenuItem } from '@mui/material'

const GraficTotalSale = ({ totalSale }) => {
  const [selectValue, setSelectValue] = useState('0')
  const [data, setData] = useState([])
  const [isShowTop10, setIsShowTop10] = useState(false)

  useEffect(() => {
    if (!totalSale || totalSale.length === 0) {
      return
    }
    if (totalSale.length > 0 && selectValue === '') {
      setSelectValue('0')
    }
  }, [totalSale, selectValue])

  useEffect(() => {
    handleFilter()
  }, [selectValue])

  const handleFilter = () => {
    let filter = []

    switch (selectValue) {
      case '0':
        filter = totalSale.slice(0, 10).map(item => ({ name: item.name, price: item.price }))
        setIsShowTop10(true)
        break
      case '1':
        // Filtra por Estado
        const uniqueStates = Array.from(new Set(totalSale.map(item => item.route))).map(state =>
          totalSale.find(item => item.route === state)
        )
        filter = uniqueStates
          .sort((a, b) => b.price - a.price)
          .slice(0, 10)
          .map(item => ({ name: item.route, price: item.price }))
        setIsShowTop10(false)
        break
      case '2':
        // Filtra por Cidade
        const uniqueCities = Array.from(new Set(totalSale.map(item => item.name2))).map(city =>
          totalSale.find(item => item.name2 === city)
        )
        filter = uniqueCities
          .sort((a, b) => b.price - a.price)
          .slice(0, 10)
          .map(item => ({ name: item.name2, price: item.price }))
        setIsShowTop10(false)
        break
      case '3':
        // Filtra por Família de Produtos
        const uniqueFamilies = Array.from(new Set(totalSale.map(item => item.name5))).map(family =>
          totalSale.find(item => item.name5 === family)
        )
        filter = uniqueFamilies
          .sort((a, b) => b.price - a.price)
          .slice(0, 10)
          .map(item => ({ name: item.name5, price: item.price }))
        setIsShowTop10(false)
        break
      case '4':
        // Filtra por Rota
        const uniqueRoutes = Array.from(new Set(totalSale.map(item => item.name4))).map(route =>
          totalSale.find(item => item.name4 === route)
        )
        filter = uniqueRoutes
          .sort((a, b) => b.price - a.price)
          .slice(0, 10)
          .map(item => ({ name: item.name4, price: item.price }))
        setIsShowTop10(false)
        break
      default:
        filter = totalSale.map(item => ({ name: item.name, price: item.price }))
        setIsShowTop10(false)
    }

    setData(filter)
  }

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: isShowTop10 ? '80%' : '50%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(val)
      },
      offsetX: 0,
      offsetY: 0,

      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },
    stroke: {
      show: true,
      width: 4,
      colors: ['transparent'],
    },
    xaxis: {
      categories: isShowTop10
        ? totalSale.slice(0, 10).map(item => item.name4 || '')
        : data.map(item => item.name || ''),
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(val)
        },
        style: {
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      shared: true,
      intersect: false,

      y: {
        formatter: function (val) {
          return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(val)
        },
      },
    },
  }

  const series = [
    {
      name: 'Valor',
      data: isShowTop10
        ? totalSale.slice(0, 10).map(item => item.price)
        : data.map(item => item.price),
    },
  ]

  return (
    <div>
      <div>
        <InputLabel id="demo-simple-select-label">Selecione um tipo</InputLabel>
        <Select
          size="small"
          sx={{ width: '50%' }}
          label="Escolher um tipo"
          value={selectValue}
          onChange={e => setSelectValue(e.target.value)}
        >
          <MenuItem value="0">Total</MenuItem>
          <MenuItem value="1">Estado</MenuItem>
          <MenuItem value="2">Cidade</MenuItem>
          <MenuItem value="3">Familia de produtos</MenuItem>
          <MenuItem value="4">Produtos</MenuItem>
        </Select>
      </div>
      <div id="grafico">
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  )
}

export default GraficTotalSale
