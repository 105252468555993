import React, { useContext } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import HelpIcon from '@mui/icons-material/Help'
import { deletePrinterZplService, getPrinterZplService } from '../../../services/configServices'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  Tooltip,
  Divider,
  InputBase,
} from '@mui/material'
import PrinterZplCreate from '../../Create/PrinterZplCreate'
import PrinterZplEdit from '../../Edit/PrinterZplEdit'
import { AiFillPlusCircle } from 'react-icons/ai'
import ViewElearning from '../../ViewElearning'
import PermissionComponent from '../../../permissions'
import { formatarDataHoraParaBRL } from '../../../utils/formatDate'
import { Clear, Search } from '@mui/icons-material'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const PrinterZpl = () => {
  const { nameUrl, subCompany } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [idEditing, setIdEditing] = useState('')
  const [searchValue, setSearchValue] = useState('')

  const token = localStorage.getItem('token')
  const [printerZpl, setPrinterZpl] = useState([])
  const [pagination, setPagination] = useState({})
  const [printerZplLimit, setPrinterZplLimit] = useState(10)
  const [printerZplOffset, setPrinterZplOffset] = useState(0)
  const [printerZplOrder, setPrinterZplOrder] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getPrinterZpl = async () => {
    setLoading(true)
    try {
      const response = await getPrinterZplService(
        token,
        nameUrl,
        subCompanyId,
        printerZplLimit,
        printerZplOffset,
        printerZplOrder,
        ''
      )
      setPrinterZpl(response.data)
      setPagination(response.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (subCompanyId) {
      getPrinterZpl()
    }
  }, [printerZplOrder, subCompanyId])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleEdit = id => {
    setIdEditing(id)
    setExpanded(true)
    setEditing(true)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    try {
      await deletePrinterZplService(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('ZPL deletada com sucesso!')

      setAlerta(true)
      setLoading(false)
      getPrinterZpl()
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  const handleClearSearch = () => {
    setSearchValue('')
  }

  const filteredRows =
    printerZpl.filter(row => row.name.toLowerCase().includes(searchValue.toLowerCase())) || []

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <ViewElearning pageName={'PrinterZpl'} open={viewElearning} setOpen={setViewElearning} />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">ZPL</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>

        {/* Criar  */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>{editing ? 'Editar ZPL' : 'Cadastrar ZPL'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="Edit_PrinterZpl">
                <PrinterZplEdit
                  setEditing={setEditing}
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  printerId={idEditing}
                  handleFetchData={getPrinterZpl}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="Create_PrinterZpl">
                <PrinterZplCreate
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={getPrinterZpl}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>
        <Box>
          <SelectSubCompany
            value={subCompanyId}
            onChange={e => {
              setSubCompanyId(e.target.value)
            }}
          />
        </Box>
        <Box
          sx={{
            mt: 3,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', ml: 1 }}
          >
            <PermissionComponent role="Create_PrinterZpl">
              <Button
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
                variant="contained"
                endIcon={<AiFillPlusCircle />}
              >
                Criar
              </Button>
            </PermissionComponent>
          </Box>
        </Box>

        {/* Editar  */}

        {/* Deletar */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>
        <Paper
          component="form"
          sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            maxWidth: '300px',
            width: '100%',
          }}
        >
          <IconButton sx={{ p: '10px' }} aria-label="menu">
            <Search />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Pessoa"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
          />
          {searchValue && (
            <IconButton aria-label="Limpar pesquisa" onClick={handleClearSearch}>
              <Clear />
            </IconButton>
          )}
        </Paper>

        {filteredRows.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="left">Nome</StyledTableCell>
                  <StyledTableCell align="left">ZPL</StyledTableCell>
                  <StyledTableCell align="left">Criação</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {printerZpl.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell align="left">
                      <PermissionComponent role="Delete_PrinterZpl">
                        <Tooltip title="Apagar" arrow>
                          <IconButton
                            onClick={() => deleteAlert(row.printerZplId)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </Tooltip>
                      </PermissionComponent>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <PermissionComponent role="Edit_PrinterZpl">
                        <Tooltip title="Editar" arrow>
                          <IconButton
                            onClick={() => handleEdit(row.printerZplId)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <ModeEditOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </PermissionComponent>
                    </StyledTableCell>

                    <StyledTableCell align="left" sx={{ width: 200 }}>
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        display: 'flex',
                        justifyContent: 'left',
                      }}
                    >
                      <Box sx={{ width: '100%' }}>{row.zpl}</Box>
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ width: 200 }}>
                      {formatarDataHoraParaBRL(row.create)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={printerZpl.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default PrinterZpl
