import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  deleteMovimentationOrderService,
  getOrderPaginationService,
  returnOrderStatus,
} from '../../../services/productService'
import * as XLSX from 'xlsx'
import { RESULTS_PER_PAGE } from '../../../utils/excel'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TransferOrderCreate from '../../Create/TransferOrderCreate'
import TransferOrderItem from '../../Edit/TransferOrderItem'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Grid,
  DialogContent,
  Chip,
} from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import HelpIcon from '@mui/icons-material/Help'
import { useNavigate } from 'react-router-dom'
//icones react-icons
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import PermissionComponent from '../../../permissions'
import { Add, Close, Save } from '@mui/icons-material'
import NewSampleControlCreate from '../../Create/NewSampleControlCreate'

const ReportNewSampleControl = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const [openDialog, setOpenDialog] = useState(false)
  const [editing, setEditing] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const [loading, setLoading] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [viewElearning, setViewElearning] = useState(false)
  const [progress, setProgress] = useState(0)
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalData, setTotalData] = useState(0)
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [userName, setUserName] = useState('')
  const [observation, setObservation] = useState('')
  const [mockId, setMockId] = useState('')
  const [message, setMessage] = useState('')
  const [operation, setOperation] = useState(0)

  const handleGetAll = async () => {
    try {
      const storedData = JSON.parse(localStorage.getItem('data'))
      const filteredData = storedData.filter(item => item.quem)
      setData(filteredData)
    } catch (error) {}
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const handleSaveDevolution = () => {
    setIsLoading(true)

    setTimeout(() => {
      if (!userName) {
        setIsLoading(false)
        setMessage('Por favor, informe o nome do usuário.')
        return
      }

      try {
        const storedData = JSON.parse(localStorage.getItem('data'))

        const index = storedData.findIndex(item => item.id === mockId)
        if (index !== -1) {
          storedData[index].quem = userName
          storedData[index].observation = observation
          storedData[index].devolution = new Date().toLocaleDateString()

          localStorage.setItem('data', JSON.stringify(storedData))
          setData(storedData)
        }

        setOpenDialog(false)
        setIsLoading(false)
      } catch (error) {
        console.error('Erro ao salvar devolução:', error)
        setIsLoading(false)
      }
    }, 1000)
  }

  const handleSaveExit = () => {
    setIsLoading(true)

    setTimeout(() => {
      if (!userName) {
        setIsLoading(false)
        setMessage('Por favor, informe o nome do usuário.')
        return
      }

      try {
        const storedData = JSON.parse(localStorage.getItem('data'))

        const index = storedData.findIndex(item => item.id === mockId)
        if (index !== -1) {
          storedData[index].quem = userName
          storedData[index].observation = observation
          storedData[index].saida = new Date().toLocaleDateString()

          localStorage.setItem('data', JSON.stringify(storedData))
          setData(storedData)
        }

        setOpenDialog(false)
        setIsLoading(false)
      } catch (error) {
        console.error('Erro ao salvar saída:', error)
        setIsLoading(false)
      }
    }, 1000)
  }

  useEffect(() => {
    handleGetAll()
  }, [])

  useEffect(() => {}, [data, userName])

  const handleAddNewItem = form => {
    const newData = [...data, form]

    newData.sort((a, b) => {
      const dateA = new Date(a.dataProd.split('/').reverse().join('/'))
      const dateB = new Date(b.dataProd.split('/').reverse().join('/'))
      return dateB - dateA
    })

    localStorage.setItem('data', JSON.stringify(newData))

    setData(newData)
  }

  return (
    <>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Informe o nome do usuário da operação</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="user"
                name="user"
                label="Usuário"
                type="text"
                fullWidth
                error={!!message}
                helperText={message}
                variant="outlined"
                disabled={isLoading}
                value={userName}
                onChange={e => setUserName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="user"
                name="user"
                label="Observação"
                type="text"
                fullWidth
                variant="outlined"
                rows={4}
                multiline
                disabled={isLoading}
                value={observation}
                onChange={e => setObservation(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            startIcon={<Close />}
            disabled={isLoading}
            onClick={() => setOpenDialog(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            disabled={isLoading}
            startIcon={isLoading ? <CircularProgress size={20} /> : <Save />}
            onClick={() => {
              if (operation === 2) {
                handleSaveDevolution()
              } else if (operation === 1) {
                handleSaveExit()
              }
            }}
          >
            {isLoading ? 'Salvando...' : 'Salvar'}
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ mt: 7.5 }}>
        <ViewElearning pageName={'TransferOrder'} open={viewElearning} setOpen={setViewElearning} />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Relatório de Controle amostra nova</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: '100%', mt: 2 }}>
          <InputLabel id="demo-simple-select-label">Filial</InputLabel>
          <Select label="Selecione a empresa" value={subCompanyId}>
            {subCompany
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => {
                return (
                  <MenuItem value={item.subCompanyId} key={index}>
                    {item.name}
                  </MenuItem>
                )
              })}
          </Select>
        </FormControl>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 1,
            mt: 2,
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Pesquisar"
            type="search"
            onInput={e => setSearch(e.target.value)}
          />
          {searching && <Typography>Buscando...</Typography>}
        </Box>

        <TableContainer component={Paper} sx={{ mt: '2rem' }}>
          <Typography sx={{ width: 1, my: 2 }} variant="h6">
            {data.length > 1
              ? `${data.length} Resultados`
              : data.length < 1 && `${data.length} Resultado`}
          </Typography>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">DES:</StyledTableCell>
                <StyledTableCell align="left">VAR:</StyledTableCell>
                <StyledTableCell align="left">CAIXA</StyledTableCell>
                <StyledTableCell align="left">BASE</StyledTableCell>
                <StyledTableCell align="left">CADASTRO</StyledTableCell>
                <StyledTableCell align="left">CLIENTE</StyledTableCell>
                <StyledTableCell align="left">OBSERVAÇÃO</StyledTableCell>
                <StyledTableCell align="left">DATA D. PROD</StyledTableCell>
                <StyledTableCell align="left">QUEM</StyledTableCell>
                <StyledTableCell align="left">RETIRADA</StyledTableCell>
                <StyledTableCell align="left">DEVOLUÇÃO</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell align="left">{row.des}</StyledTableCell>
                    <StyledTableCell align="left">{row.var}</StyledTableCell>
                    <StyledTableCell align="left">{row.caixa}</StyledTableCell>
                    <StyledTableCell align="left">{row.base}</StyledTableCell>
                    <StyledTableCell align="left">{row.cadastro}</StyledTableCell>
                    <StyledTableCell align="left">{row.cliente}</StyledTableCell>
                    <StyledTableCell align="left">{row.observation}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.dataProd && new Date(row.dataProd).getTime()
                        ? new Date(row.dataProd).toLocaleDateString('pt-BR')
                        : ''}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.quem}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.saida && new Date(row.saida).getTime()
                        ? new Date(row.saida).toLocaleDateString('pt-BR')
                        : ''}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.devolution && new Date(row.devolution).getTime()
                        ? new Date(row.devolution).toLocaleDateString('pt-BR')
                        : ''}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ReportNewSampleControl
