import { ArrowDownward, Message, Person, RocketLaunch, Stop } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material'
import React, { useRef, useState } from 'react'
import { postOpenAiGpt } from '../../../services/openAiChat'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

// Função para formatar o texto
const formatTextToTopics = text => {
  const lines = text.split('\n')
  return lines.map((line, index) => line.trim()).filter(line => line.length > 0)
}

// CSS para animação de loading
const loadingStyles = `
@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}
.balls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.ball {
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  animation: blink 1.4s infinite both;
}
.ball:nth-child(1) { animation-delay: 0s; }
.ball:nth-child(2) { animation-delay: 0.2s; }
.ball:nth-child(3) { animation-delay: 0.4s; }
`

function Gpt() {
  const token = localStorage.getItem('token')
  const [subCompanyId, setSubCompanyId] = useState('')
  const [text, setText] = useState('')
  const [messageError, setMenssageError] = useState('')
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(false)
  const [openChat, setOpenChat] = useState(false)
  const [stop, setStop] = useState(false)
  const abortControllerRef = useRef(new AbortController())

  const handlePostMessage = async textValue => {
    if (!textValue) {
      return
    }

    if (!subCompanyId) {
      setMenssageError('Campo obrigatório')
      return
    }

    setMenssageError('')
    setOpenChat(true)
    setText('')
    setLoading(true)
    setStop(false)

    abortControllerRef.current.abort()
    abortControllerRef.current = new AbortController()

    const data = {
      subCompanyId: subCompanyId,
      text: textValue,
    }

    setMessages(prev => [
      ...prev,
      {
        text: textValue,
        isBot: false,
      },
    ])

    try {
      const response = await postOpenAiGpt(token, data, abortControllerRef.current.signal)
      const fullMessage = response.data.resposta

      if (stop) return

      const topics = formatTextToTopics(fullMessage)

      setMessages(prev => [
        ...prev,
        {
          text: '',
          isBot: true,
        },
      ])

      let index = 0
      const typingInterval = setInterval(() => {
        setMessages(prev => {
          const newMessages = [...prev]
          if (index < fullMessage.length) {
            newMessages[newMessages.length - 1].text += fullMessage[index]
            index++
          } else {
            clearInterval(typingInterval)
            newMessages[newMessages.length - 1].text = topics.join('\n')
          }
          return newMessages
        })
        if (stop) {
          clearInterval(typingInterval)
        }
      }, 20)

      setLoading(false)
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Request was aborted')
      } else {
        console.error('Error sending message:', error)
      }
      setLoading(false)
    }
  }

  const handleStopMessage = () => {
    setStop(true)
    abortControllerRef.current.abort()
    setLoading(false)
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handlePostMessage(text)
    }
  }

  return (
    <>
      <style>{loadingStyles}</style>
      <Box
        mt={7}
        display="flex"
        flexDirection="column"
        gap={2}
        p={2}
        height="100vh"
        overflow="hidden"
      >
        <Box
          width="100%"
          height="100%"
          py={4}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          borderRadius={2}
          gap={2}
          sx={{ px: { xs: 4, md: 20 } }}
        >
          <Grid container spacing={2} overflow="auto" px={2}>
            <Grid item xs={12}>
              <SelectSubCompany
                error={messageError}
                value={subCompanyId}
                onChange={e => setSubCompanyId(e.target.value)}
              />
              {!!messageError && (
                <Typography variant="caption" color="red">
                  Campo obrigatório
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box flexGrow={1}>
                {openChat ? (
                  <>
                    {messages.map((message, index) => (
                      <Box
                        key={index}
                        bgcolor={message.isBot ? '#ccc' : 'transparent'}
                        px={4}
                        py={2}
                        borderRadius={2}
                        display="flex"
                        alignItems="flex-start"
                        mb={1}
                      >
                        <ListItemAvatar>
                          <Avatar>{message.isBot ? <Message /> : <Person />}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="body1" fontWeight={700}>
                              {message.isBot ? 'Chat' : 'Você'}
                            </Typography>
                          }
                          secondary={
                            message.isBot ? (
                              <Box color="#000">
                                {message.text.split('\n').map((line, idx) => (
                                  <Typography key={idx} component="div" sx={{ mb: 2 }}>
                                    {line}
                                  </Typography>
                                ))}
                              </Box>
                            ) : (
                              <Typography variant="body2">{message.text}</Typography>
                            )
                          }
                        />
                      </Box>
                    ))}
                    {loading && (
                      <Box display="flex" justifyContent="flex-start" mt={2} pl={4}>
                        <Box className="balls">
                          <Box className="ball" bgcolor="#ccc" />
                          <Box className="ball" bgcolor="#ccc" />
                          <Box className="ball" bgcolor="#ccc" />
                        </Box>
                      </Box>
                    )}
                  </>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    color="white"
                    height="100%"
                  >
                    <Typography variant="h4" my={4}>
                      Bihands Chat
                    </Typography>
                    <List component="nav" aria-label="main mailbox folders">
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Card
                            variant="outlined"
                            sx={{
                              bgcolor: 'transparent',
                              border: '1px solid #ccc',

                              minHeight: '100px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <ListItemButton onClick={() => handlePostMessage('Bihands chat')}>
                              <ListItemText primary="Bihands chat" />
                            </ListItemButton>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Card
                            variant="outlined"
                            sx={{
                              border: '1px solid #ccc',

                              minHeight: '100px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <ListItemButton
                              onClick={() => handlePostMessage('Sugestao de análise')}
                            >
                              <ListItemText primary="Sugestao de análise" />
                            </ListItemButton>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Card
                            variant="outlined"
                            sx={{
                              bgcolor: 'transparent',
                              border: '1px solid #ccc',

                              minHeight: '100px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <ListItemButton
                              onClick={() =>
                                handlePostMessage(
                                  'Liste todos os produtos disponíveis no estoque com suas respectivas quantidades.'
                                )
                              }
                            >
                              <ListItemText primary="Liste todos os produtos disponíveis no estoque com suas respectivas quantidades." />
                            </ListItemButton>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Card
                            variant="outlined"
                            sx={{
                              bgcolor: 'transparent',
                              border: '1px solid #ccc',

                              minHeight: '100px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <ListItemButton
                              onClick={() =>
                                handlePostMessage('Quais são os pedidos pendentes de envio?')
                              }
                            >
                              <ListItemText primary="Quais são os pedidos pendentes de envio?" />
                            </ListItemButton>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Card
                            variant="outlined"
                            sx={{
                              bgcolor: 'transparent',
                              border: '1px solid #ccc',

                              minHeight: '100px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <ListItemButton
                              onClick={() =>
                                handlePostMessage(
                                  'Analise os dados a seguir e me diga todos os produtos que estão nos endereço A e a quantidade e também.'
                                )
                              }
                            >
                              <ListItemText primary="Analise os dados a seguir e me diga todos os produtos que estão nos endereço A e a quantidade e também." />
                            </ListItemButton>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Card
                            variant="outlined"
                            sx={{
                              bgcolor: 'transparent',
                              border: '1px solid #ccc',

                              minHeight: '100px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <ListItemButton
                              onClick={() =>
                                handlePostMessage(
                                  'Analise os dados a seguir e me diga qual produto mais antigo?'
                                )
                              }
                            >
                              <ListItemText primary="Analise os dados a seguir e me diga qual produto mais antigo?" />
                            </ListItemButton>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Card
                            variant="outlined"
                            sx={{
                              bgcolor: 'transparent',
                              border: '1px solid #ccc',

                              minHeight: '100px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <ListItemButton
                              onClick={() =>
                                handlePostMessage(
                                  'Compare as vendas dos produtos da semana passada com a semana atual.'
                                )
                              }
                            >
                              <ListItemText primary="Compare as vendas dos produtos da semana passada com a semana atual." />
                            </ListItemButton>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Card
                            variant="outlined"
                            sx={{
                              border: '1px solid #ccc',

                              minHeight: '100px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <ListItemButton
                              onClick={() =>
                                handlePostMessage(
                                  'Analise os dados a seguir e me diga a quantidade de todos total dos produtos.'
                                )
                              }
                            >
                              <ListItemText primary="Analise os dados a seguir e me diga a quantidade de todos total dos produtos." />
                            </ListItemButton>
                          </Card>
                        </Grid>
                      </Grid>
                    </List>
                  </Box>
                )}
              </Box>
            </Grid>
            {openChat && (
              <Grid item xs={12} display="flex" alignItems="center">
                <TextField
                  placeholder="Mensagem"
                  value={text}
                  onChange={e => setText(e.target.value)}
                  onKeyDown={handleKeyDown}
                  sx={{
                    width: '100%',
                    borderRadius: 10,
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderRadius: 10,
                      },
                      '&:hover fieldset': {
                        borderRadius: 10,
                      },
                      '&.Mui-focused fieldset': {
                        borderRadius: 10,
                      },
                    },
                    '& .MuiInputBase-input': {},
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => handlePostMessage(text)}>
                          <RocketLaunch />
                        </IconButton>
                        {loading && (
                          <IconButton onClick={handleStopMessage} color="error">
                            <Stop />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default Gpt
