import React, { useContext, useState } from 'react'

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { Context } from '../../../context/AuthContext'
import { getProductItensRfid } from '../../../services/productService'

const CreateRFID = ({
  setExpanded,
  subCompanyId,
  setResponseMessage,
  productsWithRfid,
  setProductsWithRfid,
  setLoading,
}) => {
  const {
    handleSubmit,
    register,
    reset,
    resetField,
    formState: { errors },
  } = useForm()
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const [counting, setCounting] = useState(false)
  const [showInput, setShowInput] = useState(false)

  const handleEquipmentChange = e => {
    const selectedEquipment = e.target.value
    if (selectedEquipment === 'MID40') {
      setShowInput(true)
    } else if (selectedEquipment === 1) {
      setShowInput(true)
    } else {
      setShowInput(false)
    }
    setCounting(false)
  }

  const handleRegisterRFID = async data => {
    setLoading(true)
    const rfid = data.rfid
    let response

    try {
      response = await getProductItensRfid(token, nameUrl, subCompanyId, rfid)
    } catch (err) {
      console.error(err)
      setResponseMessage('Erro ao adicionar produto. Verifique se o código RFID existe')
      return
    } finally {
      setLoading(false)
    }

    if (!response || !response.data) {
      console.log('response é null ou undefined')
      return
    }

    if (response.data.length === 0) {
      console.log('response tem length zero')
      return
    }

    const serialAlreadyExists = productsWithRfid.some(
      product => product.serial === response.data.serial
    )

    if (serialAlreadyExists) {
      const newProductsWithRfidArray = productsWithRfid.map(product => {
        if (product.serial === response.data.serial) {
          return { ...product, quantity: (product.quantity += 1) }
        }

        return product
      })

      setProductsWithRfid(newProductsWithRfidArray)
    } else {
      const newProduct = {
        idProductPartner: response.data.idProductPartner ?? '',
        reference: response.data.referencia ?? '',
        color: response.data.color ?? '',
        barCode: response.data.barCode ?? '',
        subCompanyCNPJ: response.data.subCompanyCNPJ ?? '',
        dataExpiration: response.data.dataExpiration ?? '',
        productName: response.data.productName ?? '',
        price: response.data.price ?? 0,
        size: response.data.size ?? '',
        serial: response.data.serial,
        productId: response.data.productId ?? '',
        quantity: 1,
        sku: response.data.sku,
      }

      setProductsWithRfid(prev => [...prev, newProduct])
    }

    resetField('rfid')
    setResponseMessage('Produto adicionado à lista')
  }

  return (
    <>
      <Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={handleSubmit(handleRegisterRFID)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth variant="outlined" error={!!errors.subCompanyId}>
                <InputLabel id="subCompanyId-label">Filial</InputLabel>
                <Select
                  label="Filial"
                  labelId="subCompanyId-label"
                  {...register('subCompanyId', {
                    required: 'Filial é obrigatória',
                  })}
                >
                  {subCompany.map((item, index) => (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.subCompanyId?.message}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <FormControl sx={{ width: '100%', mt: 0 }}>
                <InputLabel sx={{ color: 'black' }} id="demo-simple-select-label">
                  Escolha o equipamento
                </InputLabel>
                <Select
                  onChange={event => {
                    handleEquipmentChange(event)
                  }}
                  sx={{ width: '100%', color: 'black' }}
                  label="Escolha o equipamento"
                  {...register('equipment', {
                    required: 'Campo obrigatório',
                  })}
                >
                  <MenuItem selected value={1}>
                    HID
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl sx={{ width: '100%', mt: 0 }}>
                <TextField
                  autoFocus
                  fullWidth
                  label="Código RFID"
                  type="text"
                  variant="outlined"
                  {...register('rfid', {
                    required: 'Insira um RFID',
                  })}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: 'black',
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: 'red',
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  mt: 3,
                  justifyContent: 'center',
                }}
              >
                <Button variant="contained" type="submit">
                  Adicionar código
                </Button>

                <Button
                  variant="contained"
                  type="button"
                  onClick={() => {
                    reset()
                    setExpanded(false)
                  }}
                  sx={{ ml: 2 }}
                >
                  Cancelar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default CreateRFID
