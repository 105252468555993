import React, { useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  IconButton,
  Autocomplete,
  FormControl,
  tableCellClasses,
  styled,
  Tooltip,
  InputAdornment,
  Button,
  Backdrop,
  Snackbar,
  Alert,
  TablePagination,
} from '@mui/material'
import Collapse from '@mui/material/Collapse'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Context } from '../../../context/AuthContext'
import { Add, Clear, Delete, Edit, Help, Print, Save, Search } from '@mui/icons-material'
import { getPrinterZplService } from '../../../services/configServices'
import {
  createAssociationItensList,
  createDisassociationItemRemovesList,
  searchProductService,
} from '../../../services/productService'
import { useDebounce } from '../Product/Components/useDebounce'
import { colorTableDefault } from '../../TemplateDefault'
import { Printer, Text, Cut } from 'react-thermal-printer'
import ComponentQuantityCreate from './ComponentRfid/Create'
import ComponentQuantityEdit from './ComponentRfid/Edit'
import { getFaction } from '../../../services/factionService'
import { getAlocationStockGetAll } from '../../../services/alocationServices'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

function Disassociate() {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const { debounce } = useDebounce(1000, true)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [subCompanyName, setSubCompanyName] = useState('')
  const [alocationStockId, setAlocationStockId] = useState('')
  const [factionId, setFactionId] = useState('')
  const [serial, setSerial] = useState('')
  const [typePrint, setTypePrint] = useState(0)
  const [printZpls, setPrintZpls] = useState([])
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [product, setProduct] = useState([])
  const [totalProducts, setTotalProducts] = useState(0)
  const [selectedId, setSelectedId] = useState(undefined)
  const [productItem, setProductItem] = useState([])
  const [isExternalLoading, setIsExternalLoading] = useState(false)
  const [openModalCreate, setOpenModalCreate] = useState(false)
  const [rowsProducts, setRowsProducts] = useState([])
  const [rowsFilter, setRowsFilter] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [factions, setFactions] = useState([])
  const [alocationStocks, setAlocationStocks] = useState([])
  const [messageError, setMessageError] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [searchFilter, setSearchFilter] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [alert, setAlerta] = useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function Row(props) {
    const { row, onDelete } = props

    const handleOpenEditModal = row => {
      setIsEditModalOpen(true)
      setProductItem(row)
    }
    const handleDeleteClick = () => {
      onDelete(row)
    }

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <StyledTableCell component="th" scope="row">
            {row.productName}
          </StyledTableCell>
          <StyledTableCell align="left">{row.serial}</StyledTableCell>
          <StyledTableCell align="left">{row.sku}</StyledTableCell>
          <StyledTableCell align="center" width="200px">
            <Tooltip title="Editar" arrow>
              <IconButton onClick={() => handleOpenEditModal(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Apagar" arrow>
              <IconButton onClick={handleDeleteClick}>
                <Delete />
              </IconButton>
            </Tooltip>
          </StyledTableCell>
        </TableRow>
      </React.Fragment>
    )
  }

  const handleDelete = itemToDelete => {
    const updatedRows = rowsProducts.filter(item => item !== itemToDelete)
    setRowsProducts(updatedRows)
  }

  const handleGetAllProductsSearch = async () => {
    setPage(0)
    setIsLoading(true)
    const dataProduct = {
      companyId: subCompany[0].companyId,
      limit: 10,
      offset: 0,
      order: 'desc',
      SearchWord: search,
    }
    try {
      searchProductService(token, nameUrl, dataProduct).then(response => {
        const data = response.data.result
        setProduct(data)

        setIsLoading(false)
      })
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (search !== '') {
      setIsLoading(true)
      debounce(() => {
        handleGetAllProductsSearch()
      })
    }
  }, [search])

  useEffect(() => {}, [product, productItem])

  useEffect(() => {
    if (product.length === 1 && !isLoading) {
      setOpenModalCreate(true)
      setSelectedId(product[0]?.productId)
      setProductItem(product[0])
    }
  }, [product, isLoading])

  useEffect(() => {
    if (selectedId && !isEditModalOpen) {
      setSearch('')
      setOpenModalCreate(true)
      setProductItem(product[0])
    }
  }, [selectedId, productItem])

  useEffect(() => {
    if (selectedId && isEditModalOpen) {
      setOpenModalCreate(false)
    }
  }, [selectedId, isEditModalOpen])

  const autoCompleteSelectedOptions = useMemo(() => {
    if (!selectedId) return undefined

    const selectedOptions = product.find(item => item.id === selectedId)

    return selectedOptions
  }, [selectedId, product])

  const handleSaveCodeRfidCreate = async () => {
    setLoading(true)

    rowsProducts.forEach(objeto => {
      delete objeto.sku
    })

    try {
      const response = await createDisassociationItemRemovesList(token, nameUrl, rowsProducts)
      setTitle(response.data.message)
      setAlerta(true)
      setSeverity('success')
      setLoading(false)
      setRowsProducts([])
    } catch (error) {
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setSeverity('error')
      setLoading(false)
    }
  }

  const handleSaveCodeRfidEdit = newProduct => {
    const updatedProducts = rowsProducts.filter(product => product.serial !== productItem.serial)

    updatedProducts.push(newProduct)

    setRowsProducts(updatedProducts)
  }

  const filterRow = () => {
    const filtered = rowsProducts.filter(
      item =>
        item.productName.toLowerCase().includes(searchFilter.toLowerCase()) ||
        item.serial.toLowerCase().includes(searchFilter.toLowerCase())
    )
    setRowsFilter(filtered)
  }

  useEffect(() => {
    filterRow()
  }, [searchFilter, rowsProducts])

  return (
    <Box sx={{ mt: 7 }}>
      <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">Desassociação</Typography>
        <IconButton sx={{ justifySelf: 'flex-end' }}>
          <Help />
        </IconButton>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={alert}
        onClose={() => setAlerta(false)}
        key={vertical + horizontal}
      >
        <Alert onClose={() => setAlerta(false)} severity={severity} sx={{ width: '100%' }}>
          {title}
        </Alert>
      </Snackbar>
      <ComponentQuantityCreate
        open={openModalCreate}
        onClose={() => {
          setOpenModalCreate(false)
        }}
        product={productItem}
        productRows={rowsProducts}
        subCompanyId={subCompanyId}
        subCompanyName={subCompanyName}
        message={messageError}
        onSaveQuantity={e => {
          setRowsProducts(prevRows => prevRows.concat(e))
          setSearch('')
        }}
      />
      <ComponentQuantityEdit
        open={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false)
        }}
        product={productItem}
        subCompanyId={subCompanyId}
        onSaveQuantity={handleSaveCodeRfidEdit}
        isEdit={isEditModalOpen}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <FormControl sx={{ width: '100%', mt: 0 }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                value={subCompanyId}
                onChange={event => {
                  const selectedSubCompany = subCompany.find(
                    item => item.subCompanyId === event.target.value
                  )
                  setSubCompanyId(event.target.value)
                  setSubCompanyName(selectedSubCompany.name) // Aqui você obtém o name da filial selecionada
                }}
                sx={{ width: '100%' }}
                label="Filial"
              >
                {subCompany.map((item, index) => (
                  <MenuItem value={item.subCompanyId} key={item.subCompanyId}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <FormControl sx={{ width: '100%', mt: 0 }}>
              <InputLabel id="demo-simple-select-label">Escolha o equipamento</InputLabel>
              <Select
                value={typePrint}
                disabled={!subCompanyId}
                onChange={event => {
                  setTypePrint(event.target.value)
                }}
                sx={{ width: '100%' }}
                label="Selecione o tipo de impressora"
              >
                <MenuItem selected value={1}>
                  HID
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              sx={{ maxWidth: { xs: 'auto', md: '500px' }, width: '100%' }}
              type="text"
              variant="outlined"
              placeholder="Pesquisar"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={searchFilter}
              onChange={e => setSearchFilter(e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            gap={2}
            mt={2}
            sx={{ flexDirection: { xs: 'column', md: 'row' } }}
          >
            <Button
              onClick={() => setRowsProducts([])}
              startIcon={<Clear />}
              variant="outlined"
              sx={{ width: '100%', maxWidth: { xs: 'auto', md: '250px' } }}
            >
              Limpar
            </Button>
            <Button
              startIcon={<Add />}
              variant="contained"
              sx={{ width: '100%', maxWidth: { xs: 'auto', md: '250px' } }}
              onClick={() => setOpenModalCreate(true)}
            >
              Adicionar codigo
            </Button>
            <Button
              startIcon={<Save />}
              variant="contained"
              disabled={rowsFilter.length === 0}
              sx={{ width: '100%', maxWidth: { xs: 'auto', md: '250px' } }}
              onClick={handleSaveCodeRfidCreate}
            >
              Salvar dados
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
            <Typography variant="h5">{`Total de produtos ${rowsFilter.length}`}</Typography>
            {/* <Typography variant="h5">{`Total de códigos gerados ${rowsFilter.reduce(
              (total, item) => total + (item.tagsRfid ? item.tagsRfid.length : 0),
              0
            )}`}</Typography> */}
          </Box>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Nome</StyledTableCell>
                    <StyledTableCell align="left">Serial</StyledTableCell>
                    <StyledTableCell align="left">SKU</StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsFilter.map(row => (
                    <Row key={row.productId} row={row} onDelete={handleDelete} />
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rowsFilter.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}

export default Disassociate
