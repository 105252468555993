import { useState, useContext, useEffect, useRef } from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Context } from '../../../context/AuthContext'
import Alert from '@mui/material/Alert'
import * as XLSX from 'xlsx'

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  InputLabel,
  Snackbar,
  TableCell,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
  LinearProgress,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import styled from '@emotion/styled'
import { colorTableDefault } from '../../TemplateDefault'
import { getOnlyOrder } from '../../../services/reportServices'
import { createBuckCreateMobileList } from '../../../services/productService'

const UploadSerial = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const [file, setFile] = useState(null)
  const [files, setFiles] = useState([])
  const [valueFile, setValueFile] = useState('')
  const token = localStorage.getItem('token')
  const [subCompanyId, setSubCompanyId] = useState('')
  const [severity, setSeverity] = useState('')
  const [loading, setLoading] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [progress, setProgress] = useState(0)
  const [sending, setSending] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)
  const [orderSelect, setOrderSelect] = useState('')
  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')
  const [orders, setOrders] = useState([])

  const [itemProd, setItensProd] = useState([])
  const ref = useRef()

  const handleSelectFile = e => {
    setLoading(true)
    setValueFile(e.target.name[0])
    const selectedFile = e.target.files[0]

    if (!selectedFile) {
      setFile(null)
      return
    }

    setFiles(prevFiles => [...prevFiles, selectedFile]) // Adiciona o arquivo ao estado files
    //sendFile(selectedFile);
    readExcel(selectedFile)
  }

  const handleClose = () => {
    setSeverity()
  }
  const readExcel = async file => {
    const reader = new FileReader()
    await new Promise(resolve => setTimeout(resolve, 1000))

    reader.onload = e => {
      const data = e.target.result
      const workbook = XLSX.read(data, { type: 'binary' })
      const firstSheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[firstSheetName]

      const excelData = XLSX.utils.sheet_to_json(worksheet)
      const dataList = excelData.map(item => ({
        subCompanyId: subCompanyId,
        sEPCHex: String(item.sEPCHex),
        movimentationOrderNumberOtherSystem: String(movimentationOrderNumberOtherSystem),
        typeOrder: 6,
      }))

      console.log(dataList, ' dataList')
      setItensProd(prev => [...prev, ...dataList])
      setLoading(false)
      setValueFile('')
      resetFile()
    }

    reader.readAsBinaryString(file)
  }
  const resetFile = () => {
    if (ref.current) {
      ref.current.value = ''
    }
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} color="secondary" />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.primary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  const handleOrder = async e => {
    const newOrder = e.target.value
    try {
      const response = await getOnlyOrder(token, nameUrl, newOrder)
      setOrders(response.data)
    } catch (e) {
      console.log(e)
    }
  }

  const sendFile = async () => {
    setSending(true)

    try {
      for (let x = 0; x <= 80; x++) {
        setProgress(x)
      }
      const response = await createBuckCreateMobileList(token, nameUrl, itemProd)
      console.log(response, 'res')
      for (let x = 80; x <= 100; x++) {
        setProgress(x)
      }
      setSeverity('success')
      resetFile()
      setValueFile('')
      setFiles([])

      setSending(false)
    } catch (error) {
      setSeverity('error')
      setSending(false)
    }
  }
  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={severity === 'error'}
          onClose={handleClose}
          //key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Verifique o formato do arquivo, a extensão deve ser xlsx ou xls
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={severity === 'success'}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Arquivo enviado com sucesso
          </Alert>
        </Snackbar>

        <Dialog open={sending} keepMounted aria-describedby="alert-dialog-slide-description">
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>Enviando...</DialogTitle>
            <LinearProgressWithLabel value={progress} sx={{ width: '90%' }} />
          </Box>
        </Dialog>

        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Carga de RFID</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <FormControl sx={{ width: '100%', mt: 0 }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                value={subCompanyId}
                sx={{ width: '100%' }}
                label="Escolher a filial"
                onChange={e => {
                  setSubCompanyId(e.target.value)
                  handleOrder(e)
                }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormControl sx={{ width: '100%', mt: 0 }}>
              <InputLabel id="demo-simple-select-label">Selecione o pedido</InputLabel>
              <Select
                label="Selecione o pedido"
                value={movimentationOrderNumberOtherSystem}
                disabled={!subCompanyId}
                onChange={e => setMovimentationOrderNumberOtherSystem(e.target.value)}
                sx={{ width: '100%' }}
              >
                {orders.map((item, index) => {
                  return (
                    <MenuItem value={item.movimentationOrderNumberOtherSystem} key={index}>
                      {item.movimentationOrderNumberOtherSystem}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              sx={{ width: '100%' }}
              variant="contained"
              disabled={!movimentationOrderNumberOtherSystem}
              onClick={() => setFiles(prevFiles => [...prevFiles, null])}
            >
              Adicionar
            </Button>
          </Grid>
          {files.map((file, index) => (
            <>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    alignItems: 'center',
                    textAlign: 'center',
                    border: '1px solid black',
                    width: { xs: '100%' },
                    minWidth: { xs: '100%' },
                    p: 2,
                    height: '170px',
                  }}
                >
                  <a
                    className="dropzone"
                    target="_blank"
                    rel="noreferrer"
                    href="https://file.bihands.com/template/Uploadproduct.xlsx"
                    download
                  >
                    <FileDownloadIcon sx={{ fontSize: 100 }} />
                  </a>
                  <InputLabel>Faça o download do exemplo de carga</InputLabel>
                </Box>
              </Grid>

              <Grid item xs={12} md={8}>
                <Box
                  sx={{ border: { md: '1px solid black' }, p: 2, height: '170px' }}
                  width={{ xs: '100%' }}
                >
                  <InputLabel>Insira aqui o documento para enviar</InputLabel>
                  <TextField
                    sx={{ width: '100%', mt: 1 }}
                    id="standard-basic"
                    //label="Nome"
                    variant="standard"
                    disabled={!movimentationOrderNumberOtherSystem}
                    //value={name}
                    onChange={handleSelectFile}
                    type="file"
                    accept=".xls, .xlsx, .ods"
                  />
                  <Box
                    sx={{
                      mt: 4,
                      alignItems: 'center',
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography>Apenas arquivos .XLS ou .XLSX</Typography>
                  </Box>
                </Box>
              </Grid>
            </>
          ))}

          {files.length > 0 && (
            <Grid item xs={12}>
              <Box textAlign="end">
                <Button onClick={() => sendFile()} variant="contained">
                  Enviar
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default UploadSerial
