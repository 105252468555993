import HelpIcon from '@mui/icons-material/Help'
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
  Grid,
  tableCellClasses,
} from '@mui/material'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import { Context } from '../../../context/AuthContext'
import {
  handleMovimentationStockByProduct,
  handleMovimentationStockInfo,
  handleMovimentationStockInfoAndProduct,
  handleMovimentationStockInfoAndProductAndSubCompany,
  handleMovimentationStockInfoAndSubCompany,
} from '../../../services/reportServices'

//icones react-icons
import { AiOutlineFileExcel } from 'react-icons/ai'
import '../../../assets/global.css'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import {
  getProductAssociationService,
  searchProductService,
} from '../../../services/productService'
import { useForm } from 'react-hook-form'

const schema = yup.object().shape({
  typeOrderSelected: yup.string().required('Campo obrigatório.'),
  subCompanyId: yup.string(),
  startDate: yup.string().required('Campo obrigatório.'),
  finishDate: yup.string().required('Campo obrigatório.'),
})

const ReportMovimentationStockInfo = () => {
  const {
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  })

  const token = localStorage.getItem('token')
  const [subCompanyId, setSubCompanyId] = useState('')
  const { subCompany, company, nameUrl } = useContext(Context)
  const [startDate, setStartDate] = useState('2020-01-01')
  const [finishDate, setFinishDate] = useState(new Date().toISOString().substring(0, 10))
  const [loading, setLoading] = useState(false)
  const [dissassociationData, setDissassociationData] = useState([])
  const [table, setTable] = useState(false)

  const [progressCount, setProgressCount] = useState(0)
  const [downloadMessageCount, setDownloadMessageCount] = useState('Pronto')
  const [downloadingCount, setDownloadingCount] = useState(false)
  const [totalPagesDownload, setTotalPagesDownload] = useState()
  const [totalData, setTotalData] = useState()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [viewElearning, setViewElearning] = useState(false)
  const [filterDeposit, setFilterDeposit] = useState('')
  const [filterLote, setFilterLote] = useState('')
  const [filterProduct, setFilterProduct] = useState('')
  const [subCompanyName, setSubCompanyName] = useState('')
  const [backupData, setBackupData] = useState([])
  const [notSearch, setNotSearch] = useState(false)
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)
  const [listProducts, setListProducts] = useState()
  const [productSelected, setProductSelected] = useState()
  const [typeOrderSelected, setTypeOrderSelected] = useState()
  const [produtos, setProdutos] = useState([])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  }

  const handleGetDissassociation = async e => {
    setLoading(true)
    setTable(false)
    const data = {
      subCompanyId: watch('subCompanyId') || '',
      startDate: watch('startDate') || '',
      finishDate: watch('finishDate') || '',
      productId: productSelected ? productSelected : '',
      typeOrder: watch('typeOrderSelected') || '-99',
    }

    console.log(productSelected, 'sdadass')

    try {
      let response = []
      if (watch('subCompanyId') && !productSelected) {
        const responseData = await handleMovimentationStockInfoAndSubCompany(token, nameUrl, data)
        response = responseData.data
      }

      if (!watch('subCompanyId') && productSelected) {
        const responseData = await handleMovimentationStockInfoAndProduct(token, nameUrl, data)
        response = responseData.data
      }

      if (!watch('subCompanyId') && !productSelected) {
        const responseData = await handleMovimentationStockInfoAndProductAndSubCompany(
          token,
          nameUrl,
          data
        )
        response = responseData.data
      }

      if (watch('subCompanyId') && productSelected) {
        const responseData = await handleMovimentationStockInfo(token, nameUrl, data)
        response = responseData.data
      }

      if (!response || response.length === 0) {
        setTitle('Não existem dados para esse período.')
        setAlerta(true)
        setSeverity('error')
        setDissassociationData([])
        setBackupData([])
      } else {
        console.log(response)

        setDissassociationData(response)
        setBackupData(response)
        setTotalPagesDownload(response.length)
        setTotalData(response.length)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  const finishDownloadCount = async () => {
    let result = []
    for (let i = 0; i <= 50; i++) {
      setTimeout(() => {
        setDownloadMessageCount(`Baixando ${i} de ${page + 1}...`)
        setProgressCount((i / 100) * 100)
      }, 1000)
    }
    let response = []

    const data = {
      subCompanyId: watch('subCompanyId') || '',
      startDate: watch('startDate') || '',
      finishDate: watch('finishDate') || '',
      productId: productSelected ? productSelected : '',
      typeOrder: watch('typeOrder') || '-99',
    }

    if (watch('subCompanyId') && !productSelected) {
      const responseData = await handleMovimentationStockInfoAndSubCompany(token, nameUrl, data)
      response = responseData.data
    }

    if (!watch('subCompanyId') && productSelected) {
      const responseData = await handleMovimentationStockInfoAndProduct(token, nameUrl, data)
      response = responseData.data
    }

    if (!watch('subCompanyId') && !productSelected) {
      const responseData = await handleMovimentationStockInfoAndProductAndSubCompany(
        token,
        nameUrl,
        data
      )
      response = responseData.data
    }

    if (watch('subCompanyId') && productSelected) {
      const responseData = await handleMovimentationStockInfo(token, nameUrl, data)
      response = responseData.data
    }
    result = response
    for (let i = 50; i <= 100; i++) {
      setTimeout(() => {
        setDownloadMessageCount(`Baixando ${i} de ${page + 1}...`)
        setProgressCount((i / 100) * 100)
      }, 1000)
    }
    setDownloadMessageCount(`Seu arquivo está pronto.`)
    setDownloadingCount(false)
    const relatorioFinal = []

    result.map(item => {
      relatorioFinal.push({
        'Data criação': new Date(item.createDate).toLocaleString(),
        Produto: item.productName,
        Quantidade: item.quantity,
        Filial: item.subCompanyName,
        Serial: item.serial,
        Referencia: item.referencia,
        Movimentação: item.typeMovimentations,
        Tamanho: item.size,
        Cor: item.color,
        'Ordem Bihands': item.movimentationOrderNumberBihands,
        'Ordem Outro Sistema': item.movimentationOrderNumberOtherSystem,
      })
    })

    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    // Nome da planilha encurtado
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Estoque')
    XLSX.writeFile(workbook, `Informacao_estoque.xlsx`)
    setProgressCount(0)
  }

  const downloadDataCount = async () => {
    setDownloadMessageCount('Inicializando')
    setDownloadingCount(true)
    finishDownloadCount()
  }

  const handleClose = () => {
    setAlerta(false)
  }

  const filteredProduct = () => {
    const filtered = backupData.filter(row => {
      const productName = row.productName || ''
      const filterText = filterProduct || ''

      return productName.toLowerCase().includes(filterText.toLowerCase())
    })

    setDissassociationData(filtered)
  }

  useEffect(() => {
    filteredProduct()
  }, [filterProduct])

  const handleClearFilter = () => {
    setFilterDeposit('')
    setFilterProduct('')
    setSubCompanyName('')
    setFilterLote('')
    setDissassociationData(backupData)
  }

  useEffect(() => {
    if (search === '') {
      const checagem = setTimeout(() => {
        setProdutos([{ label: '', value: '' }])
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearching(true)
      const checagem = setTimeout(() => {
        const dados = {
          companyId: company.companyId,
          SearchWord: search,
          limit: 10,
          offset: 0,
        }
        searchProductService(token, nameUrl, dados).then(response => {
          const prodsFinal = []
          response.data.result.map(produto => {
            const obj = produto
            obj.value = produto.productId
            obj.label = `${produto.name}`
            prodsFinal.push(obj)
          })
          setProdutos(prodsFinal)
          setSearching(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [search])

  const handleGetProducts = async id => {
    setLoading(true)
    setSubCompanyId(id)
    try {
      const response = await getProductAssociationService(token, nameUrl, 100, 0, 'desc', id)
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setListProducts(response.data.result)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={downloadingCount}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessageCount}</DialogTitle>
            <LinearProgressWithLabel value={progressCount} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <ViewElearning
          pageName={'ReportDissassociation'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Informações de movimentação de estoque por produto</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box component="form" onSubmit={handleSubmit(handleGetDissassociation)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <InputLabel id="demo-simple-select-label">
                Selecione a o tipo de movimentação
              </InputLabel>
              <Select
                {...register('typeOrderSelected')}
                error={!!errors.typeOrderSelected?.message}
                label="Selecione a o tipo de movimentação"
                sx={{ width: '100%' }}
              >
                <MenuItem value={-99}>Todos</MenuItem>
                <MenuItem value={2}>Vendas</MenuItem>
                <MenuItem value={7}>Entrada de estoque</MenuItem>
                <MenuItem value={8}>Saida de estoque</MenuItem>
                <MenuItem value={10}>Pedido de Etiqueta</MenuItem>
                <MenuItem value={17}>Ordem de Produção</MenuItem>
              </Select>
              {!!errors.typeOrderSelected?.message && (
                <Typography sx={{ color: '#d32f2f', fontSize: '0.75rem', ml: '14px' }}>
                  {errors.typeOrderSelected?.message}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                label="Selecione a filial"
                {...register('subCompanyId')}
                error={!!errors.subCompanyId?.message}
                sx={{ width: '100%' }}
              >
                {subCompany &&
                  subCompany.map((item, index) => {
                    return (
                      <MenuItem value={item.subCompanyId} key={index}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
              </Select>
              {!!errors.subCompanyId?.message && (
                <Typography sx={{ color: '#d32f2f', fontSize: '0.75rem', ml: '14px' }}>
                  {errors.subCompanyId?.message}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel id="demo-simple-select-label">Produto</InputLabel>
              <Autocomplete
                disablePortal
                id="lista-produtos"
                options={produtos}
                autoHighlight
                autoSelect
                disabled={!watch('subCompanyId')}
                //sx={{ width: 300 }}
                loading={searching}
                loadingText="Procurando..."
                noOptionsText="Nenhum produto"
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.productId}>
                      {option.label}
                    </li>
                  )
                }}
                renderInput={params => <TextField {...params} />}
                onInputChange={(e, v) => {
                  setSearch(v)
                }}
                //onChange={(e, v) => setProductId(v)}
                onChange={(e, v) => {
                  if (v === null) {
                    setProductSelected()
                  } else {
                    setProductSelected(v.productId)
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel id="demo-simple-select-label">Data de criação</InputLabel>
              <TextField
                {...register('startDate')}
                variant="outlined"
                error={!!errors.startDate?.message}
                helperText={errors.startDate?.message || ''}
                sx={{ width: '100%' }}
                type="date"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel id="demo-simple-select-label">Data final</InputLabel>
              <TextField
                variant="outlined"
                {...register('finishDate')}
                error={!!errors.finishDate?.message}
                helperText={errors.finishDate?.message || ''}
                sx={{ width: '100%' }}
                type="date"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                type="submit"
                size="large"
                fullWidth
                sx={{ mt: { xs: 0, md: 4 } }}
              >
                Pesquisar
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            mb: 2,
          }}
        >
          <Typography variant="h6">Resultados</Typography>
          {dissassociationData.length > 0 && (
            <Button
              variant="contained"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: ' space-between',
                fontSize: '.9em',
                width: '6.5rem',
              }}
              onClick={downloadDataCount}
            >
              Excel <AiOutlineFileExcel size="2em" />
            </Button>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
              <TextField
                variant="outlined"
                label={!notSearch ? 'Filtrar Produto' : 'Produto Não encontrado'}
                value={filterProduct}
                onChange={values => setFilterProduct(values.target.value)}
                sx={{ width: '100%', maxWidth: '300px' }}
                error={notSearch}
                type="text"
              />
              <Button
                variant="contained"
                onClick={() => handleClearFilter()}
                sx={{ mt: 2, width: '100%', maxWidth: '200px' }}
              >
                Limpar filtros
              </Button>
            </Box>
          </Grid>
          {/* <Grid item xs={12} md={3.33}>
            <TextField
              variant="outlined"
              label={!notSearch ? 'Filtrar Depósito' : 'Depósito não encontrado'}
              value={filterDeposit}
              onChange={values => setFilterDeposit(values.target.value)}
              sx={{ width: '100%' }}
              error={notSearch}
              type="text"
            />
          </Grid>
          <Grid item xs={12} md={3.33}>
            <TextField
              variant="outlined"
              label={!notSearch ? 'Filtrar Lote' : 'Lote não encontrado'}
              value={filterLote}
              onChange={values => setFilterLote(values.target.value)}
              sx={{ width: '100%' }}
              error={notSearch}
              type="text"
            />
          </Grid> */}
        </Grid>

        <TableContainer component={Paper} sx={{ mt: '2rem' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Data criação</StyledTableCell>
                <StyledTableCell align="left">Produto</StyledTableCell>
                <StyledTableCell align="left">Quantidade</StyledTableCell>
                <StyledTableCell align="left">Tamanho</StyledTableCell>
                <StyledTableCell align="left">Cor</StyledTableCell>
                <StyledTableCell align="left">Referencia</StyledTableCell>
                <StyledTableCell align="left">Filial</StyledTableCell>
                <StyledTableCell align="left">Movimentação</StyledTableCell>
                <StyledTableCell align="left">Serial</StyledTableCell>
                <StyledTableCell align="left">Ordem Bihands</StyledTableCell>
                <StyledTableCell align="left">Ordem Outro Sistema</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dissassociationData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left">
                      {new Date(row.createDate).toLocaleString()}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.productName}</StyledTableCell>
                    <StyledTableCell align="left">{row.quantitys}</StyledTableCell>
                    <StyledTableCell align="left">{row.size}</StyledTableCell>
                    <StyledTableCell align="left">{row.color}</StyledTableCell>
                    <StyledTableCell align="left">{row.referencia}</StyledTableCell>
                    <StyledTableCell align="left">{row.subCompanyName}</StyledTableCell>
                    <StyledTableCell align="left">{row.typeMovimentations}</StyledTableCell>
                    <StyledTableCell align="left">{row.serial}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.movimentationOrderNumberBihands}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.movimentationOrderNumberOtherSystem}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={dissassociationData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ReportMovimentationStockInfo
