import { Backdrop, CircularProgress } from '@mui/material'
import React, { useContext } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { Context } from '../context/AuthContext'
import { PageAuthorization } from '../components/PageAuthorization'
import CompanyList from '../components/View/CompanyList'

export const PrivateRoute = ({ role, children }) => {
  const navigate = useNavigate()
  const selectedList = localStorage.getItem('selectedList')
  const { authenticated, loading, userRolesNew } = useContext(Context)

  if (loading) {
    return (
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  if (!authenticated) {
    return <Navigate to="/Login" />
  }

  if (selectedList === 'true') {
    return (
      <Routes>
        <Route path="/" element={<CompanyList />} />
      </Routes>
    )
  }

  if (!userRolesNew || userRolesNew.length === 0) {
    return (
      <Routes>
        <Route path="/" element={children} />
      </Routes>
    )
  }

  if (!role && authenticated) {
    return (
      <Routes>
        <Route path="/" element={children} />
      </Routes>
    )
  }

  const { hasPermission } = checkRolePermissionFromBackend(role, userRolesNew)
  if (hasPermission) {
    return (
      <Routes>
        <Route path="/" element={children} />
      </Routes>
    )
  } else {
    return <Navigate to="/Aviso" replace />
  }
}

function checkRolePermissionFromBackend(userRoles, rolesApi) {
  const hasPermission = rolesApi.some(item => userRoles === item.rolesName)

  return {
    hasPermission,
  }
}
