import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import ViewElearning from '../../ViewElearning'
import { Clear, ExpandMore, Help, Lock, LockOpen, Search, VpnKey } from '@mui/icons-material'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  editRoleService,
  getCategoryRolesID,
  getListRolesService,
  getSegmentIdService,
  getSegmentService,
} from '../../../services/admServices'

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}))

export const PermissionsGroup = () => {
  const token = localStorage.getItem('token')
  const [viewElearning, setViewElearning] = useState(false)
  const { subCompany, userRoles, nameUrl } = useContext(Context)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [segmentId, setSegmentId] = useState('')
  const [segmentGroupId, setSegmentGroupId] = useState('')
  const [segment, setSegment] = useState([])
  const [segmentGroup, setSegmentGroup] = useState([])
  const [categoryRoles, setCategoryRoles] = useState([])
  const [roles, setRoles] = useState([])

  const [selectedPositionId, setSelectedPositionId] = useState(null)
  const [permissions, setPermissions] = useState({})
  const [dataPermissions, setDataPermissions] = useState([])
  const [dataPosition, setDataPosition] = useState([])

  const [selectSegment, setSelectSegment] = useState([])
  const [selectedCheckIds, setSelectedCheckIds] = useState([])
  const [switchStates, setSwitchStates] = useState({})
  const [filteredCategoryRoles, setFilteredCategoryRoles] = useState([])

  const handleCheckBoxChange = async role => {
    const updatedSwitchStates = {
      ...switchStates,
      [role.roleId]: !switchStates[role.roleId],
    }

    setSwitchStates(updatedSwitchStates)

    if (selectedCheckIds.includes(role.roleId)) {
      setSelectedCheckIds(selectedCheckIds.filter(selectedId => selectedId !== role.roleId))
    } else {
      setSelectedCheckIds([...selectedCheckIds, role.roleId])
    }

    const data = {
      id: role.roleId,
      name: role.name,
      description: role.description,
      active: !switchStates[role.roleId],
    }

    try {
      const response = await editRoleService(token, nameUrl, data)
      console.log(response, 'chec')
      setAlerta(true)
      setSeverity('error')
      setTitle(response.data.message)
    } catch (error) {
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao atualizar permissões, verifique os dados e tente novamente.')
    }
  }

  const getSegment = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getSegmentService(token, nameUrl, 10000, 0, false)

      setSegment(response.data.result)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [])

  const getSegmentIdGroup = useCallback(async segmentId => {
    setLoading(true)
    try {
      const response = await getSegmentIdService(token, nameUrl, 500, 0, false, segmentId)
      const data = response.data.result
      setSegmentGroup(data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getSegment()
  }, [])

  const handleGetGroup = async () => {
    try {
      const storedData = localStorage.getItem('group')
      if (storedData) {
        const dataArray = JSON.parse(storedData)
        const foundSegment = dataArray.filter(item => item.companyId === subCompany[0].companyId)
        setSelectSegment(foundSegment)

        return foundSegment
      }
      return null
    } catch (error) {
      setError(true)
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao criar, verifique os dados e tente novamente.')
    }
  }

  const getListPermission = async categoryId => {
    setLoading(true)

    try {
      const response = await getCategoryRolesID(token, nameUrl, categoryId)
      handleListRoles(response.data.detailsRoleCategory)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {}, [categoryRoles, switchStates])

  const handleListRoles = async listOne => {
    try {
      const response = await getListRolesService(token)
      setRoles(response.data.result)

      findIdenticalItems(listOne, response.data.result)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetGroup()
  }, [dataPermissions])

  useEffect(() => {
    const initialSelectedCheckIds = categoryRoles.reduce((acc, item) => {
      const roleIds = item.permissions.map(role => role.roleId)
      roleIds.forEach(roleId => {
        const role = item.permissions.find(role => role.roleId === roleId)
        if (role && role.active) {
          acc.push(roleId)
        }
      })
      return acc
    }, [])

    setSelectedCheckIds(initialSelectedCheckIds)
  }, [categoryRoles])

  const loadPermissionsForPosition = () => {
    const selectedPosition = dataPosition.find(item => item.positionId === selectedPositionId)

    if (selectedPosition) {
      const associatedPermissions = selectedPosition.permissions || []
      const initialPermissionsState = {}
      associatedPermissions.forEach(permission => {
        initialPermissionsState[permission.category] = {
          ...initialPermissionsState[permission.category],
          [permission.name]: true,
        }
      })
      setPermissions(initialPermissionsState)
    } else {
      setPermissions({})
    }
  }

  const findIdenticalItems = (listOne, listTwo) => {
    const list1 = [...listOne]
    const roleIdMap = new Map()

    listTwo.forEach(item => {
      if (item) {
        roleIdMap.set(item.id, item)
      }
    })

    const initialSwitchStates = {}
    listTwo.forEach(item => {
      if (item) {
        initialSwitchStates[item.id] = item.active
      }
    })
    setSwitchStates(initialSwitchStates)

    const updatedList = list1.map(item1 => {
      if (item1.permissions) {
        item1.permissions.forEach(permission1 => {
          const objetoCorrespondente = roleIdMap.get(permission1.roleId)

          if (objetoCorrespondente) {
            permission1.active = objetoCorrespondente.active
          }
        })
      }

      return item1 // Retorna o item atualizado ou o item original se não houver correspondência
    })

    setCategoryRoles(updatedList)
  }

  useEffect(() => {
    if (selectedPositionId !== null) {
      loadPermissionsForPosition()
    }
  }, [selectedPositionId])

  useEffect(() => {
    if (searchValue === '') {
      setFilteredCategoryRoles(categoryRoles)
    } else {
      const filtered = categoryRoles
        .map(item => ({
          ...item,
          permissions: item.permissions
            ? item.permissions.filter(permission => permission.name.includes(searchValue))
            : [],
        }))
        .filter(
          item =>
            (item.permissions && item.permissions.length > 0) || item.name.includes(searchValue)
        )
      setFilteredCategoryRoles(filtered)
      console.log(filtered[0]?.permissions)
    }
  }, [searchValue, categoryRoles])

  useEffect(() => {}, [filteredCategoryRoles])

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <ViewElearning
          pageName={'ParametersGroup'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Grupos de Permissões</Typography>
          <IconButton onClick={() => setViewElearning(true)} sx={{ justifySelf: 'flex-end' }}>
            <Help />
          </IconButton>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Segmento</InputLabel>
                <Select
                  value={segmentId}
                  sx={{ width: '100%' }}
                  onChange={values => {
                    getSegmentIdGroup(values.target.value)
                    setSegmentId(values.target.value)
                  }}
                >
                  {segment.map((item, index) => {
                    return (
                      <MenuItem value={item.segmentId} key={item.segmentId}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Grupos</InputLabel>
                <Select
                  disabled={!segmentId}
                  value={segmentGroupId}
                  onChange={event => {
                    setSegmentGroupId(event.target.value)
                    getListPermission(event.target.value)
                  }}
                  sx={{ width: '100%' }}
                  label="Grupos"
                >
                  {segmentGroup.map((item, index) => (
                    <MenuItem value={item.segmentGroupId} key={item.segmentGroupId}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" my={4}>
              <TextField
                placeholder="Pesquisar"
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <>
                      {' '}
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    </>
                  ),
                  endAdornment: (
                    <>
                      {searchValue && (
                        <IconButton aria-label="Limpar pesquisa" onClick={() => setSearchValue('')}>
                          <Clear />
                        </IconButton>
                      )}
                    </>
                  ),
                }}
              />
            </Box>
          </Box>
          <Typography my={2}>
            {searchValue !== '' ? (
              <>
                {filteredCategoryRoles.length > 0 && filteredCategoryRoles[0].permissions
                  ? `${filteredCategoryRoles[0].permissions.length} Resultados`
                  : 'Nenhum Resultado'}
              </>
            ) : (
              <>
                {filteredCategoryRoles.length > 0
                  ? `${filteredCategoryRoles.length} Resultados`
                  : 'Nenhum Resultado'}
              </>
            )}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {searchValue === '' ? (
            <>
              {categoryRoles.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <Accordion>
                    <StyledAccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Box height="100%" sx={{ display: 'flex', alignItems: 'center' }}>
                        <VpnKey />
                        <Box ml={2}>
                          <Typography>{item.name}</Typography>
                          <Typography variant="caption" color="#ccc">
                            {item.description}
                          </Typography>
                        </Box>
                      </Box>
                    </StyledAccordionSummary>
                    {item.permissions.map((role, index) => (
                      <AccordionDetails key={role.roleId}>
                        <Box
                          //key={permission.id}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              width: '100%',
                            }}
                          >
                            {selectedCheckIds.includes(role.roleId) ? (
                              <LockOpen color="primary" />
                            ) : (
                              <Lock />
                            )}

                            <Typography variant="caption" ml={2}>
                              {role.name}
                            </Typography>
                            <Typography variant="caption" ml={2}>
                              {role.description}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="caption" mr={2}>
                              {selectedCheckIds.includes(role.roleId) ? 'Ativo' : 'Desativado'}
                            </Typography>
                            <Switch
                              color="primary"
                              value={role.active}
                              checked={switchStates[role.roleId] || false}
                              onChange={() => handleCheckBoxChange(role)}
                            />
                          </Box>
                        </Box>
                      </AccordionDetails>
                    ))}
                  </Accordion>
                </Grid>
              ))}
            </>
          ) : (
            <>
              <Grid item xs={12}>
                {filteredCategoryRoles.map(item =>
                  item.permissions.map((role, index) => (
                    <Box
                      key={index}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        {selectedCheckIds.includes(role.roleId) ? (
                          <LockOpen color="primary" />
                        ) : (
                          <Lock />
                        )}

                        <Typography variant="caption" ml={2}>
                          {role.name}
                        </Typography>
                        <Typography variant="caption" ml={2}>
                          {role.description}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="caption" mr={2}>
                          {selectedCheckIds.includes(role.roleId) ? 'Ativo' : 'Desativado'}
                        </Typography>
                        <Switch
                          color="primary"
                          value={role.active}
                          checked={switchStates[role.roleId] || false}
                          onChange={() => handleCheckBoxChange(role)}
                        />
                      </Box>
                    </Box>
                  ))
                )}
              </Grid>
            </>
          )}
        </Grid>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}
