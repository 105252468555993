import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '../../../context/AuthContext'
import HelpIcon from '@mui/icons-material/Help'
import { AiOutlineFileExcel } from 'react-icons/ai'
import * as XLSX from 'xlsx'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  Paper,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  Tooltip,
} from '@mui/material'

//icones react-icons
import { AiFillPlusCircle } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'
import { getAllDriver } from '../../../services/driverServices'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import PermissionComponent from '../../../permissions'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const Driver = () => {
  const { subCompany, userId, nameUrl } = useContext(Context)
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const [intCredencialOrder, setIntCredencialOrder] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const [listAllDriver, setListAllDriver] = useState([])

  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [viewElearning, setViewElearning] = useState(false)

  const [totalPagesDownloadCount, setTotalPagesDownloadCount] = useState('')
  const [progressCount, setProgressCount] = useState(0)
  const [downloadMessageCount, setDownloadMessageCount] = useState('Pronto')
  const [downloadingCount, setDownloadingCount] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      wordBreak: 'break-word',
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getPermissions = useCallback(async () => {}, [])

  const getListAllDriver = async id => {
    setLoading(true)
    setSubCompanyId(id)
    const data = {
      SubCompanyId: id,
      UserDriverId: userId,
      limit: 500,
      offset: 0,
    }

    try {
      const response = await getAllDriver(token, nameUrl, data)
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setListAllDriver(response.data.result)
      }
      setLoading(false)
    } catch (err) {
      setError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (subCompanyId) {
      getListAllDriver(subCompanyId)
    }
  }, [subCompanyId])

  useEffect(() => {
    getPermissions()
  }, [intCredencialOrder])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const downloadDataCount = async () => {
    setDownloadMessageCount('Inicializando')
    setDownloadingCount(true)
    const data = {
      SubCompanyId: subCompanyId,
      UserDriverId: userId,
      limit: 500,
      offset: 0,
    }
    await getAllDriver(token, nameUrl, data).then(r => {
      setTotalPagesDownloadCount(r.data.totalpage + 1)
      finishDownloadCount(r.data.totalpage + 1)
      console.log(r)
    })
  }

  const finishDownloadCount = async pages => {
    let result = []

    for (let i = 0; i <= pages; i++) {
      setDownloadMessageCount(`Baixando ${i} de ${pages}...`)
      setProgressCount((i / pages) * 100)
      const data = {
        SubCompanyId: subCompanyId,
        UserDriverId: userId,
        limit: 500,
        offset: i,
      }
      const resp = await getAllDriver(token, nameUrl, data)
      result = result.concat(resp.data.result)
    }
    setDownloadMessageCount(`Seu arquivo está pronto.`)
    setDownloadingCount(false)
    const relatorioFinalContagem = []

    result.map(item => {
      relatorioFinalContagem.push({
        Motorista: item.userNameDriver,
        'KG Máximo': item.carWeightKGMax,
        'Nome carro': item.carWeightName,
        Status: item.orderStatusDelivery,
        Criação: new Date(item.createDate).toLocaleString(),
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinalContagem)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório de entrega')
    XLSX.writeFile(workbook, `Relatório de entrega.xlsx`)
    setProgressCount(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  }

  const returnStatus = st => {
    if (st === 0) return 'Rascunho'
    else if (st === 1) return 'Em Entrega'
    else if (st === 2) return 'Entregue'
    else if (st === 3) return 'Não Recebido'
    else if (st === 4) return 'Aguardando Entrega'
    else if (st === -2) return 'Cancelado'
    else if (st === 5) return 'Aberto'
    else if (st === 6) return 'Fechado'
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={downloadingCount}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessageCount}</DialogTitle>
            <LinearProgressWithLabel value={progressCount} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Entrega Motorista</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>

        <ViewElearning pageName={'Driver'} open={viewElearning} setOpen={setViewElearning} />

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
          <SelectSubCompany
            value={subCompanyId}
            onChange={e => {
              getListAllDriver(e.target.value)
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            mt: 2,
            mb: 2,
          }}
        >
          {listAllDriver.length > 0 && (
            <button className="buttonDocs" onClick={downloadDataCount}>
              Excel <AiOutlineFileExcel size="2em" />
            </button>
          )}
        </Box>

        <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <PermissionComponent role="CarWeightCREATE">
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', ml: 1 }}
            >
              <Button
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
                variant="contained"
                endIcon={<AiFillPlusCircle />}
              >
                Criar
              </Button>
            </Box>
          </PermissionComponent>
        </Box>

        {listAllDriver.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {/* <StyledTableCell align="center"></StyledTableCell> */}
                  <StyledTableCell align="center"></StyledTableCell>
                  <StyledTableCell align="center">Motorista</StyledTableCell>
                  <StyledTableCell align="center">Kg Máximo</StyledTableCell>
                  <StyledTableCell align="center">Nome Carro</StyledTableCell>
                  <StyledTableCell align="center">Ordem</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">Criação</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listAllDriver
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center" sx={{ width: 100 }}>
                        <Tooltip title="Visualizar" arrow>
                          <IconButton
                            onClick={() => navigate(`/ListDriver/${row.movimentationOrderRouteId}`)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <RemoveRedEyeIcon />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.userNameDriver}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.carWeightKGMax}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.carWeightName}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.movimentationOrderRouteShipping}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {returnStatus(row.orderStatusDelivery)}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {new Date(row.createDate).toLocaleString()}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={listAllDriver.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default Driver
