import { Context } from '../../../context/AuthContext'
import * as XLSX from 'xlsx'
import { useContext, useEffect, useState } from 'react'
import { getReportProductPickingNotFound } from '../../../services/reportServices'
import HelpIcon from '@mui/icons-material/Help'
import PropTypes from 'prop-types'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'

//icones react-icons
import { AiOutlineFileExcel } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'

const ReportProductPickingNotFound = () => {
  const token = localStorage.getItem('token')
  const [companyId, setCompanyId] = useState('')
  const { subCompany, nameUrl } = useContext(Context)
  const [loading, setLoading] = useState(false)
  const [dissassociationData, setDissassociationData] = useState([])

  const [progressCount, setProgressCount] = useState(0)
  const [downloadMessageCount, setDownloadMessageCount] = useState('Pronto')
  const [downloadingCount, setDownloadingCount] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [viewElearning, setViewElearning] = useState(false)
  const [companies, setCompanies] = useState()

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const handleGetReport = async () => {
    setLoading(true)
    try {
      const response = await getReportProductPickingNotFound(token, nameUrl, companyId)
      if (response.data.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
        setDissassociationData([])
      } else {
        setDissassociationData(response.data)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
      setTitle('Erro no sistema')
      setAlerta(true)
      setSeverity('error')
    }
  }

  const downloadDataCount = async () => {
    setDownloadMessageCount('Inicializando')
    setDownloadingCount(true)
    // let result = []
    // const data = {
    //   subCompanyId: subCompanyId,
    //   startDate: startDate,
    //   finishDate: finishDate,
    //   limit: 10,
    //   offset: 1,
    //   order: orderSelect,
    // }

    // const resp = await handleDissassociation(token, nameUrl, data)
    finishDownloadCount()
  }

  const finishDownloadCount = async () => {
    let result = []

    for (let i = 0; i <= 100; i++) {
      setDownloadMessageCount(`Baixando ${i} de ${100}...`)
      setProgressCount((i / dissassociationData.length) * 100)
      // const data = {
      //   subCompanyId: subCompanyId,
      //   startDate: startDate,
      //   finishDate: finishDate,
      //   limit: 10,
      //   offset: i,
      //   order: orderSelect,
      // }
    }
    const resp = await getReportProductPickingNotFound(token, nameUrl, companyId)
    result = result.concat(resp.data)
    setDownloadMessageCount(`Seu arquivo está pronto.`)
    setDownloadingCount(false)
    const relatorioFinalDissassociacao = []

    result.map(row => {
      relatorioFinalDissassociacao.push({
        IdPartener: row.idProductPartner,
        Descrição: row.description,
        Referencia: row.referencia,
        'Código da Cor': row.codColor,
        Cor: row.color,
        Tamanho: row.size,
        BarCode: row.barCode,
        SKU: row.sku,
        Marca: row.brand,
        Tipo: row.typeProduct,
        Stock: row.stock,
        'Qtde/Cx Embarque': row.piece,
        Picking: row.picking,
      })
    })

    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinalDissassociacao)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Produtos sem Picking')
    XLSX.writeFile(workbook, `Produtos sem Picking.xlsx`)
    setProgressCount(0)
  }

  const handleClose = () => {
    setAlerta(false)
  }

  useEffect(() => {
    fetchCompanies()
  }, [])

  const fetchCompanies = () => {
    let arrayCompanies = []
    subCompany.forEach(element => {
      const filter = arrayCompanies.some(company => company.companyId === element.companyId)
      if (!filter) {
        arrayCompanies.push(element)
      }
    })
    setCompanies(arrayCompanies)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={downloadingCount}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessageCount}</DialogTitle>
            <LinearProgressWithLabel value={progressCount} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <ViewElearning
          pageName={'ReportDissassociation'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Relatório de Produtos sem Picking</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box>
          <Box
            sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ mt: 2, width: '100%' }}>
              <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
              <Select
                label="Selecione a filial"
                onChange={e => {
                  setCompanyId(e.target.value)
                }}
                name="companySelect"
                sx={{ width: '100%' }}
                value={companyId}
              >
                {companies &&
                  companies.map((item, index) => {
                    return (
                      <MenuItem value={item.companyId} key={index}>
                        {item.nameCompany}
                      </MenuItem>
                    )
                  })}
              </Select>
            </Box>
          </Box>
          <Button disabled={companyId === ''} variant="contained" onClick={() => handleGetReport()}>
            Pesquisar
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            mb: 2,
          }}
        >
          <Typography variant="h6">Resultados</Typography>
          {dissassociationData.length > 0 && (
            <Button
              variant="contained"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: ' space-between',
                fontSize: '.9em',
                width: '6.5rem',
              }}
              onClick={downloadDataCount}
            >
              Excel <AiOutlineFileExcel size="2em" />
            </Button>
          )}
        </Box>
        {dissassociationData.length > 0 && (
          <>
            <TableContainer component={Paper} sx={{ mt: '2rem' }}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">IdPartener</StyledTableCell>
                    <StyledTableCell align="left">Descrição</StyledTableCell>
                    <StyledTableCell align="left">Referencia</StyledTableCell>
                    <StyledTableCell align="left">Código da Cor</StyledTableCell>
                    <StyledTableCell align="left">Cor</StyledTableCell>
                    <StyledTableCell align="left">Tamanho</StyledTableCell>
                    <StyledTableCell align="left">BarCode</StyledTableCell>
                    <StyledTableCell align="left">SKU</StyledTableCell>
                    <StyledTableCell align="left">Marca</StyledTableCell>
                    <StyledTableCell align="left">Tipo</StyledTableCell>
                    <StyledTableCell align="left">Stock</StyledTableCell>
                    <StyledTableCell align="left">Qtde/Cx Embarque</StyledTableCell>
                    <StyledTableCell align="left">Picking</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dissassociationData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => (
                      <StyledTableRow key={row.idProductPartner}>
                        <StyledTableCell align="left">{row.idProductPartner}</StyledTableCell>
                        <StyledTableCell align="left">{row.description}</StyledTableCell>
                        <StyledTableCell align="left">{row.referencia}</StyledTableCell>
                        <StyledTableCell align="left">{row.codColor}</StyledTableCell>
                        <StyledTableCell align="left">{row.color}</StyledTableCell>
                        <StyledTableCell align="left">{row.size}</StyledTableCell>
                        <StyledTableCell align="left">{row.barCode}</StyledTableCell>
                        <StyledTableCell align="left">{row.sku}</StyledTableCell>
                        <StyledTableCell align="left">{row.brand}</StyledTableCell>
                        <StyledTableCell align="left">{row.typeProduct}</StyledTableCell>
                        <StyledTableCell align="left">{row.stock}</StyledTableCell>
                        <StyledTableCell align="left">{row.piece}</StyledTableCell>
                        <StyledTableCell align="left">{row.picking}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={dissassociationData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ReportProductPickingNotFound
