import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { useContext, useEffect, useState } from 'react'

import { Book, Close, Message, Person } from '@mui/icons-material'
import { getListTicketsAssignedFilterDate, getTicketsAll } from '../../../services/helpdeskService'
import {
  getWeek,
  getMonth,
  getYear,
  getDate,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  addWeeks,
  addDays,
  endOfDay,
  startOfYear,
  endOfYear,
  setMonth,
  subDays,
} from 'date-fns'
import RadialBarChart from './Components/ReactApexChart'

import ColumnChartBasic from './Components/ColumnChartBasic'
import { getCountTicketCompany } from '../../../services/admServices'
import TextField from '@mui/material/TextField'
import ColumnChartBasicClient from './Components/ColumnChartBasicClient'
import ColumnChartBasicUser from './Components/ColumnChartBasicUser'
import { Context } from '../../../context/AuthContext'
import { getEmployeeAll } from '../../../services/configServices'

export const DahsboardHelpe = () => {
  const { userId } = useContext(Context)
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)

  const [viewElearning, setViewElearning] = useState(false)
  const [ticketId, setTicketId] = useState('')
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0') // Adiciona zero à esquerda se for necessário
  const initialFilterDate = `${currentYear}-${currentMonth}`

  // State para armazenar a data filtrada
  const [filterDate, setFilterDate] = useState(initialFilterDate)
  const [totalActive, setTotalActive] = useState(0)
  const [totalFila, setTotalFila] = useState(0)
  const [totalCanceled, setTotalCanceled] = useState(0)
  const [totalFinished, setTotalFinished] = useState(0)
  const [totalDelay, setTotalDelay] = useState(0)
  const [totalProgress, setTotalProgress] = useState(0)
  const [totalFilaUser, setTotalFilaUser] = useState(0)
  const [totalCanceledUser, setTotalCanceledUser] = useState(0)
  const [totalFinishedUser, setTotalFinishedUser] = useState(0)
  const [totalDelayUser, setTotalDelayUser] = useState(0)
  const [totalProgressUser, setTotalProgressUser] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loadingError, setLoadingError] = useState(false)
  const [ticket, setTiket] = useState([])
  const [ticketsUser, setTiketsUser] = useState([])
  const [ticketCompany, setTiketCompany] = useState([])
  const [filterRow, setFilterRow] = useState([])
  const [totalDay, setTotalDay] = useState(0)
  const [totalWeek, setTotalWeek] = useState([])
  const [totalMonth, setTotalMonth] = useState([])
  const [totalYear, setTotalYear] = useState([])
  const [totalDayYesterday, setTotalDayYesterday] = useState(0)
  const [filter, setFilter] = useState(1)
  const [users, setUsers] = useState([])
  const [userListId, setUserListId] = useState('')
  const [loadingUser, setLoadingUser] = useState(false)
  const [loadingUserError, setLoadingUserError] = useState(false)
  const [loadingClient, setLoadingClient] = useState(false)
  const [loadingClientError, setLoadingClientError] = useState(false)

  const handleGetListTickets = async () => {
    setLoading(true)
    setLoadingError(false)
    try {

      const response = await getTicketsAll(token,  500, 0, 'desc', filterDate)
      setTiket(response.data.result)
      calculateTicketTotals(response.data.result)
      const fila = response.data.result.filter(item => item.status === 0)
      const canceled = response.data.result.filter(item => item.status === -999)
      const finished = response.data.result.filter(item => item.status === 3)
      const delay = response.data.result.filter(item => item.status === 4)
      const progress = response.data.result.filter(
        item => item.status === 1 || item.status === 5 || item.status === 4
      )
      const active = response.data.result.filter(
        item => item.status === 1 || item.status === 0 || item.status === 4
      )

      const calculateTimeElapsedLists = (creationDate, priority) => {
        const currentDate = new Date()
        const differenceInMilliseconds = currentDate - new Date(creationDate)
        const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60)

        const overdue =
          (priority === 1 && differenceInHours > 6) ||
          (priority === 2 && differenceInHours > 12) ||
          (priority === 3 && differenceInHours > 24)

        return { timeElapsed: differenceInHours, overdue }
      }

      const overdueTickets = response.data.result.filter(item => {
        const { overdue } = calculateTimeElapsedLists(item.createDate, item.priority)
        return overdue && item.status !== -999 && item.status !== 3
      })

      const inProgressFila = response.data.result.filter(item => {
        const isItemInProgress = item.status === 0
        const { overdue } = calculateTimeElapsedLists(item.createDate, item.priority)
        return isItemInProgress && overdue
      })

      const inProgressOverdue = response.data.result.filter(item => {
        const isItemInProgress = item.status === 1
        const { overdue } = calculateTimeElapsedLists(item.createDate, item.priority)
        return isItemInProgress && overdue
      })

      const currentWeekStart = startOfWeek(new Date(), { weekStartsOn: 1 }) // Inicia a semana na segunda-feira
      const currentWeekEnd = endOfWeek(new Date(), { weekStartsOn: 1 })

      const weekDays = Array.from({ length: 7 }, (_, index) => {
        const currentDate = new Date(currentWeekStart)
        currentDate.setDate(currentDate.getDate() + index)
        return currentDate
      })

      const dailyTicketCounts = weekDays.map(day => {
        const ticketsForDay = response.data.result.filter(item => {
          const itemDate = new Date(item.createDate)
          return itemDate >= day && itemDate < new Date(day).setHours(23, 59, 59, 999)
        })

        return ticketsForDay.length
      })
      setTotalWeek(dailyTicketCounts)

      const currentMonthStart = startOfMonth(new Date()) // Início do mês atual
      const currentMonthEnd = endOfMonth(new Date())

      const monthWeeks = Array.from({ length: 4 }, (_, weekIndex) => {
        const weekStart = addWeeks(currentMonthStart, weekIndex)
        const weekEnd = addDays(weekStart, 6)

        const ticketsForWeek = response.data.result.filter(item => {
          const itemDate = new Date(item.createDate)
          return itemDate >= weekStart && itemDate <= endOfDay(weekEnd)
        })

        return ticketsForWeek.length
      })

      setTotalMonth(monthWeeks)

      const currentYearStart = startOfYear(new Date()) // Início do ano atual
      const currentYearEnd = endOfYear(new Date())

      const yearMonths = Array.from({ length: 12 }, (_, monthIndex) => {
        const monthStart = setMonth(currentYearStart, monthIndex)
        const monthEnd = endOfMonth(monthStart)

        const ticketsForMonth = response.data.result.filter(item => {
          const itemDate = new Date(item.createDate)
          return itemDate >= monthStart && itemDate <= endOfDay(monthEnd)
        })

        return ticketsForMonth.length
      })

      setTotalYear(yearMonths)

      setTotalFila(fila.length)
      setTotalCanceled(canceled.length)
      setTotalFinished(finished.length)
      setTotalDelay(overdueTickets.length)
      setTotalProgress(progress.length)
      setTotalActive(active.length)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setLoadingError(true)
    }
  }

  const calculateTicketTotals = tickets => {
    const currentDay = getDate(new Date())
    const currentWeek = getWeek(new Date())
    const currentMonth = getMonth(new Date())
    const currentYear = getYear(new Date())
    const yesterday = subDays(new Date(), 1)

    const dayTickets = tickets.filter(item => {
      const itemDay = getDate(new Date(item.createDate))
      return itemDay === currentDay
    })

    const weekTickets = tickets.filter(item => {
      const itemWeek = getWeek(new Date(item.createDate))
      return itemWeek === currentWeek
    })

    const monthTickets = tickets.filter(item => {
      const itemMonth = getMonth(new Date(item.createDate))
      return itemMonth === currentMonth
    })

    const yearTickets = tickets.filter(item => {
      const itemYear = getYear(new Date(item.createDate))
      return itemYear === currentYear
    })

    const dayTicketsYesterday = tickets.filter(item => {
      const itemDay = getDate(new Date(item.createDate))
      return itemDay === getDate(yesterday)
    })

    setTotalDay(dayTickets.length)
    setTotalDayYesterday(dayTicketsYesterday.length)
    //setTotalMonth(monthTickets.length)
    //setTotalYear(yearTickets.length)
  }

  useEffect(() => {}, [totalFila, totalCanceled, totalFinished, totalDelay, totalProgress])

  useEffect(() => {}, [totalDay, totalWeek, totalMonth, totalYear])

  const handleEmployee = async () => {
    try {
      const response = await getEmployeeAll(token)
      setUsers(response.data)
    } catch (error) {}
  }
  useEffect(() => {
    handleGetListTickets()
    handlegetCountTicketCompany()
    handleEmployee()
    handlegetGetListTicketsAssignedFilterDate()
  }, [filterDate])

  useEffect(() => {
    handlegetGetListTicketsAssignedFilterDate()
  }, [userListId])

  const handlegetCountTicketCompany = async () => {
    setLoadingClient(true)
    setLoadingClientError(false)
    try {
      const response = await getCountTicketCompany(token, 500, 0, 'desc', filterDate)
      const sortedResults = response.data.result.sort((a, b) => b.count - a.count)
      setTiketCompany(sortedResults)
      setLoadingClient(false)
    } catch (error) {
      setLoadingClient(false)
      setLoadingClientError(true)
    }
  }

  const handlegetGetListTicketsAssignedFilterDate = async () => {
    setLoadingUser(true)
    setLoadingUserError(false)
    try {
      const response = await getListTicketsAssignedFilterDate(
        token,
        userListId ? userListId : userId,
        filterDate
      )
      if (userListId === '') {
        setUserListId(userId)
      }
      setTiketsUser(response.data.result)
      const fila = response.data.result.filter(item => item.status === 0)
      const canceled = response.data.result.filter(item => item.status === -999)
      const finished = response.data.result.filter(item => item.status === 3)
      const delay = response.data.result.filter(item => item.status === 4)
      const progress = response.data.result.filter(
        item => item.status === 1 || item.status === 5 || item.status === 4
      )
      const active = response.data.result.filter(
        item => item.status === 1 || item.status === 0 || item.status === 4
      )

      const calculateTimeElapsedLists = (creationDate, priority) => {
        const currentDate = new Date()
        const differenceInMilliseconds = currentDate - new Date(creationDate)
        const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60)

        const overdue =
          (priority === 1 && differenceInHours > 6) ||
          (priority === 2 && differenceInHours > 12) ||
          (priority === 3 && differenceInHours > 24)

        return { timeElapsed: differenceInHours, overdue }
      }

      const overdueTickets = response.data.result.filter(item => {
        const { overdue } = calculateTimeElapsedLists(item.createDate, item.priority)
        return overdue && item.status !== -999 && item.status !== 3
      })

      const inProgressFila = response.data.result.filter(item => {
        const isItemInProgress = item.status === 0
        const { overdue } = calculateTimeElapsedLists(item.createDate, item.priority)
        return isItemInProgress && overdue
      })

      const inProgressOverdue = response.data.result.filter(item => {
        const isItemInProgress = item.status === 1
        const { overdue } = calculateTimeElapsedLists(item.createDate, item.priority)
        return isItemInProgress && overdue
      })

      const currentWeekStart = startOfWeek(new Date(), { weekStartsOn: 1 }) // Inicia a semana na segunda-feira
      const currentWeekEnd = endOfWeek(new Date(), { weekStartsOn: 1 })

      const weekDays = Array.from({ length: 7 }, (_, index) => {
        const currentDate = new Date(currentWeekStart)
        currentDate.setDate(currentDate.getDate() + index)
        return currentDate
      })

      const dailyTicketCounts = weekDays.map(day => {
        const ticketsForDay = response.data.result.filter(item => {
          const itemDate = new Date(item.createDate)
          return itemDate >= day && itemDate < new Date(day).setHours(23, 59, 59, 999)
        })

        return ticketsForDay.length
      })
      setTotalWeek(dailyTicketCounts)

      const currentMonthStart = startOfMonth(new Date()) // Início do mês atual
      const currentMonthEnd = endOfMonth(new Date())

      const monthWeeks = Array.from({ length: 4 }, (_, weekIndex) => {
        const weekStart = addWeeks(currentMonthStart, weekIndex)
        const weekEnd = addDays(weekStart, 6)

        const ticketsForWeek = response.data.result.filter(item => {
          const itemDate = new Date(item.createDate)
          return itemDate >= weekStart && itemDate <= endOfDay(weekEnd)
        })

        return ticketsForWeek.length
      })

      setTotalMonth(monthWeeks)

      const currentYearStart = startOfYear(new Date()) // Início do ano atual
      const currentYearEnd = endOfYear(new Date())

      const yearMonths = Array.from({ length: 12 }, (_, monthIndex) => {
        const monthStart = setMonth(currentYearStart, monthIndex)
        const monthEnd = endOfMonth(monthStart)

        const ticketsForMonth = response.data.result.filter(item => {
          const itemDate = new Date(item.createDate)
          return itemDate >= monthStart && itemDate <= endOfDay(monthEnd)
        })

        return ticketsForMonth.length
      })

      setTotalYear(yearMonths)

      setTotalFilaUser(fila.length)
      setTotalCanceledUser(canceled.length)
      setTotalFinishedUser(finished.length)
      setTotalDelayUser(overdueTickets.length)
      setTotalProgressUser(progress.length)
      setLoadingUser(false)
    } catch (error) {
      setLoadingUser(false)
      setLoadingUserError(true)
    }
  }

  return (
    <Box sx={{ mt: 7.5 }}>
      <Box
        sx={{
          width: 1,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="body1">Dashboard</Typography>
        <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
          <HelpIcon />
        </IconButton>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ textAlight: { xs: 'start', md: 'end' } }}>
            <TextField
              type="month"
              value={filterDate}
              onChange={e => setFilterDate(e.target.value)}
              label="Selecione o mês e o ano"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={2}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
            }}
          >
            <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
              {loading ? (
                <Box
                  sx={{
                    alignItems: 'center',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    minHeight: '80px',
                    flexDirection: 'column',
                  }}
                >
                  <CircularProgress size={30} />
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <Box
                    bgcolor="#F9E608"
                    width="60px"
                    height="60px"
                    borderRadius="100%"
                    display="flex"
                    alignItems="center"
                    p={2}
                    color="#fff"
                    mr={2}
                  >
                    <Person />
                  </Box>
                  <Box>
                    <Typography variant="body1" color="#B3BDC1">
                      Tickets
                    </Typography>
                    <Typography variant="body1" color="#B3BDC1">
                      Abertos
                    </Typography>
                    <Typography variant="h6" fontWeight={700}>
                      {totalFila}
                    </Typography>
                  </Box>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
            }}
          >
            <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
              {loading ? (
                <Box
                  sx={{
                    alignItems: 'center',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    minHeight: '80px',
                    flexDirection: 'column',
                  }}
                >
                  <CircularProgress size={30} />
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <Box
                    bgcolor="#0999FF"
                    width="60px"
                    height="60px"
                    borderRadius="100%"
                    display="flex"
                    alignItems="center"
                    p={2}
                    color="#fff"
                    mr={2}
                  >
                    <Person />
                  </Box>
                  <Box>
                    <Typography variant="body1" color="#B3BDC1">
                      Tickets em
                    </Typography>
                    <Typography variant="body1" color="#B3BDC1">
                      progresso
                    </Typography>
                    <Typography variant="h6" fontWeight={700}>
                      {totalProgress}
                    </Typography>
                  </Box>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
            }}
          >
            <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
              {loading ? (
                <Box
                  sx={{
                    alignItems: 'center',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    minHeight: '80px',
                    flexDirection: 'column',
                  }}
                >
                  <CircularProgress size={30} />
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <Box
                    bgcolor="#FE4F58"
                    width="60px"
                    height="60px"
                    borderRadius="100%"
                    display="flex"
                    alignItems="center"
                    p={2}
                    color="#fff"
                    mr={2}
                  >
                    <Message />
                  </Box>
                  <Box width="100%">
                    <Typography variant="body1" color="#B3BDC1">
                      Tickets em
                    </Typography>
                    <Typography variant="body1" color="#B3BDC1">
                      atraso
                    </Typography>
                    <Typography variant="h6" fontWeight={700}>
                      {totalDelay}
                    </Typography>
                  </Box>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
            }}
          >
            <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
              {loading ? (
                <Box
                  sx={{
                    alignItems: 'center',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    minHeight: '80px',
                    flexDirection: 'column',
                  }}
                >
                  <CircularProgress size={30} />
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <Box
                    bgcolor="green"
                    width="60px"
                    height="60px"
                    borderRadius="100%"
                    display="flex"
                    alignItems="center"
                    p={2}
                    color="#fff"
                    mr={2}
                  >
                    <Person />
                  </Box>
                  <Box>
                    <Typography variant="body1" color="#B3BDC1">
                      Tickets
                    </Typography>
                    <Typography variant="body1" color="#B3BDC1">
                      Finalizados
                    </Typography>
                    <Typography variant="h6" fontWeight={700}>
                      {totalFinished}
                    </Typography>
                  </Box>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
            }}
          >
            <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
              {loading ? (
                <Box
                  sx={{
                    alignItems: 'center',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    minHeight: '80px',
                    flexDirection: 'column',
                  }}
                >
                  <CircularProgress size={30} />
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <Box
                    bgcolor="#FE4F58"
                    width="60px"
                    height="60px"
                    borderRadius="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    p={2}
                    color="#fff"
                    mr={2}
                  >
                    <Close />
                  </Box>
                  <Box>
                    <Typography variant="body1" color="#B3BDC1">
                      Tickets
                    </Typography>
                    <Typography variant="body1" color="#B3BDC1">
                      Cancelados
                    </Typography>
                    <Typography variant="h6" fontWeight={700}>
                      {totalCanceled}
                    </Typography>
                  </Box>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
            }}
          >
            <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
              {loading ? (
                <Box
                  sx={{
                    alignItems: 'center',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    minHeight: '80px',
                    flexDirection: 'column',
                  }}
                >
                  <CircularProgress size={30} />
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <Box
                    bgcolor="#0999FF"
                    width="60px"
                    height="60px"
                    borderRadius="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    p={2}
                    color="#fff"
                    mr={2}
                  >
                    <Person />
                  </Box>
                  <Box>
                    <Typography variant="body1" color="#B3BDC1">
                      Total de
                    </Typography>
                    <Typography variant="body1" color="#B3BDC1">
                      tickets
                    </Typography>
                    <Typography variant="h6" fontWeight={700}>
                      {ticket.length}
                    </Typography>
                  </Box>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
            }}
          >
            <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
              <Box display="flex" flexDirection="column">
                <Box display="flex" justifyContent="space-between" flexDirection="row" py={2}>
                  <Typography variant="body1" color="#B3BDC1">
                    Gráfico de Tickets
                  </Typography>
                </Box>
                <Divider />
                <Box>
                  <ColumnChartBasic
                    tickets={ticket}
                    filterDate={filterDate}
                    loadingError={loadingError}
                    loading={loading}
                    reloadHandler={handleGetListTickets}
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Card
                sx={{
                  boxShadow:
                    'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
                  borderRadius: '10px',
                }}
              >
                <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" justifyContent="space-between" flexDirection="row" py={2}>
                      <Typography variant="body1" color="#B3BDC1">
                        Chats fechados em relação ao dia anterior
                      </Typography>
                    </Box>
                    <Divider />
                    <Box></Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Card
                sx={{
                  boxShadow:
                    'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
                  borderRadius: '10px',
                }}
              >
                <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" justifyContent="space-between" flexDirection="row" py={2}>
                      <Typography variant="body1" color="#B3BDC1">
                        Tickets fechados em relação ao dia anterior
                      </Typography>
                    </Box>
                    <Divider />
                    <Box></Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item xs={12} sm={12} md={12}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
            }}
          >
            <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
              <Box display="flex" flexDirection="column">
                <Box display="flex" justifyContent="space-between" flexDirection="row" py={2}>
                  <Typography variant="body1" color="#B3BDC1">
                    Gráfico por cliente
                  </Typography>
                </Box>
                <Divider />
                <Box>
                  <ColumnChartBasicClient
                    tickets={ticketCompany}
                    filterDate={filterDate}
                    loadingError={loadingClientError}
                    loading={loadingClient}
                    reloadHandler={handlegetCountTicketCompany}
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
            }}
          >
            <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" justifyContent="space-between" flexDirection="row" py={2}>
                  <Typography variant="body1" color="#B3BDC1">
                    Gráfico por usuários
                  </Typography>
                </Box>

                <Divider />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <Card
                      sx={{
                        boxShadow:
                          'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
                        borderRadius: '10px',
                        width: '100%',
                        p: 2,
                      }}
                    >
                      {!loadingUser && (
                        <Box py={2}>
                          <Select
                            label="Selecione uusuárioio"
                            variant="outlined"
                            sx={{ width: '100%' }}
                            value={userListId}
                            onChange={e => setUserListId(e.target.value)}
                          >
                            {users.map(user => (
                              <MenuItem value={user.userId} key={user.userId}>
                                {user.nameEmployee}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                      )}
                      <Box>
                        <ColumnChartBasicUser
                          tickets={ticketsUser}
                          filterDate={filterDate}
                          loadingError={loadingUserError}
                          loading={loadingUser}
                          reloadHandler={handlegetGetListTicketsAssignedFilterDate}
                        />
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        gap: 2,
                      }}
                    >
                      <Card
                        sx={{
                          boxShadow:
                            'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
                          borderRadius: '10px',
                          width: '100%',
                        }}
                      >
                        <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
                          {loadingUser ? (
                            <Box
                              sx={{
                                alignItems: 'center',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                minHeight: '80px',
                                flexDirection: 'column',
                              }}
                            >
                              <CircularProgress size={30} />
                            </Box>
                          ) : (
                            <Box display="flex" alignItems="center">
                              <Box
                                bgcolor="#0999FF"
                                width="60px"
                                height="60px"
                                borderRadius="100%"
                                display="flex"
                                alignItems="center"
                                p={2}
                                color="#fff"
                                mr={2}
                              >
                                <Book />
                              </Box>
                              <Box display="flex" alignItems="center" gap={2}>
                                <Typography variant="body1" color="#B3BDC1">
                                  Tickets em progresso
                                </Typography>
                                <Typography variant="h6" fontWeight={700}>
                                  {totalProgressUser}
                                </Typography>
                              </Box>
                            </Box>
                          )}
                        </CardContent>
                      </Card>
                      <Card
                        sx={{
                          boxShadow:
                            'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
                          borderRadius: '10px',
                          width: '100%',
                        }}
                      >
                        <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
                          {loadingUser ? (
                            <Box
                              sx={{
                                alignItems: 'center',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                minHeight: '80px',
                                flexDirection: 'column',
                              }}
                            >
                              <CircularProgress size={30} />
                            </Box>
                          ) : (
                            <Box display="flex" alignItems="center">
                              <Box
                                bgcolor="#FE4F58"
                                width="60px"
                                height="60px"
                                borderRadius="100%"
                                display="flex"
                                alignItems="center"
                                p={2}
                                color="#fff"
                                mr={2}
                              >
                                <Message />
                              </Box>
                              <Box display="flex" alignItems="center" gap={2}>
                                <Typography variant="body1" color="#B3BDC1">
                                  Tickets em atraso
                                </Typography>

                                <Typography variant="h6" fontWeight={700}>
                                  {totalDelayUser}
                                </Typography>
                              </Box>
                            </Box>
                          )}
                        </CardContent>
                      </Card>
                      <Card
                        sx={{
                          boxShadow:
                            'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
                          borderRadius: '10px',
                          width: '100%',
                        }}
                      >
                        <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
                          {loadingUser ? (
                            <Box
                              sx={{
                                alignItems: 'center',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                minHeight: '80px',
                                flexDirection: 'column',
                              }}
                            >
                              <CircularProgress size={30} />
                            </Box>
                          ) : (
                            <Box display="flex" alignItems="center">
                              <Box
                                bgcolor="green"
                                width="60px"
                                height="60px"
                                borderRadius="100%"
                                display="flex"
                                alignItems="center"
                                p={2}
                                color="#fff"
                                mr={2}
                              >
                                <Book />
                              </Box>
                              <Box display="flex" alignItems="center" gap={2}>
                                <Typography variant="body1" color="#B3BDC1">
                                  Tickets Finalizados
                                </Typography>

                                <Typography variant="h6" fontWeight={700}>
                                  {totalFinishedUser}
                                </Typography>
                              </Box>
                            </Box>
                          )}
                        </CardContent>
                      </Card>
                      <Card
                        sx={{
                          boxShadow:
                            'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
                          borderRadius: '10px',
                          width: '100%',
                        }}
                      >
                        <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
                          {loadingUser ? (
                            <Box
                              sx={{
                                alignItems: 'center',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                minHeight: '80px',
                                flexDirection: 'column',
                              }}
                            >
                              <CircularProgress size={30} />
                            </Box>
                          ) : (
                            <Box display="flex" alignItems="center">
                              <Box
                                bgcolor="#FE4F58"
                                width="60px"
                                height="60px"
                                borderRadius="100%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                p={2}
                                color="#fff"
                                mr={2}
                              >
                                <Close />
                              </Box>
                              <Box display="flex" alignItems="center" gap={2}>
                                <Typography variant="body1" color="#B3BDC1">
                                  Tickets Cancelados
                                </Typography>
                                <Typography variant="h6" fontWeight={700}>
                                  {totalCanceledUser}
                                </Typography>
                              </Box>
                            </Box>
                          )}
                        </CardContent>
                      </Card>
                      <Card
                        sx={{
                          boxShadow:
                            'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
                          borderRadius: '10px',
                          width: '100%',
                        }}
                      >
                        <CardContent sx={{ py: '32px !Important', px: '24px !Important' }}>
                          {loadingUser ? (
                            <Box
                              sx={{
                                alignItems: 'center',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                minHeight: '80px',
                                flexDirection: 'column',
                              }}
                            >
                              <CircularProgress size={30} />
                            </Box>
                          ) : (
                            <Box display="flex" alignItems="center">
                              <Box
                                bgcolor="#0999FF"
                                width="60px"
                                height="60px"
                                borderRadius="100%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                p={2}
                                color="#fff"
                                mr={2}
                              >
                                <Book />
                              </Box>
                              <Box display="flex" alignItems="center" gap={2}>
                                <Typography variant="body1" color="#B3BDC1">
                                  Total de tickets
                                </Typography>

                                <Typography variant="h6" fontWeight={700}>
                                  {ticketsUser.length}
                                </Typography>
                              </Box>
                            </Box>
                          )}
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}
