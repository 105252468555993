import { useState, useContext, useEffect, useRef } from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Context } from '../../../context/AuthContext'
import Alert from '@mui/material/Alert'
import { read, utils } from 'xlsx'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
  Chip,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import styled from '@emotion/styled'
import { colorTableDefault } from '../../TemplateDefault'
import { Add, DeleteForever, Edit, ExpandMore, Replay } from '@mui/icons-material'
import { creatClientListUpdate } from '../../../services/clientService'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const UploadClient = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const [progress, setProgress] = useState(0)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const [subCompanyname, setSubCompanyname] = useState('')
  const [file, setFile] = useState(null)
  const token = localStorage.getItem('token')
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [titleDialog, setTitleDialog] = useState('')
  const [severity, setSeverity] = useState('error')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [statusMessage, setStatusMessage] = useState({})
  const [statusTable, setStatusTable] = useState(false)
  const [statusInfo, setStatusInfo] = useState(false)
  const [sentClientsCount, setSentClientsCount] = useState(0)
  const [failedClients, setFailedClients] = useState([])
  const [totalClientsCount, setTotalClientsCount] = useState(0)
  const [totalClientsSelectedCount, setTotalClientsSelectedCount] = useState(0)
  const [currentBatchIndex, setCurrentBatchIndex] = useState(0)
  const [numBatches, setNumBatches] = useState(0)
  const [sentClients, setSentClients] = useState([])
  const [dialogAction, setDialogAction] = useState(null)

  const [singleProd, setSingleProd] = useState({
    codClient: '',
    name: '',
    email: '',
    cpF_CNPJ: '',
    cep: '',
    mobile: '',
    cpF_CNPJ: '',
  })

  const [status, setStatus] = useState('Pronto')
  const [deleteId, setDeleteId] = useState('')
  const [search, setSearch] = useState('')
  const [errors, setErrors] = useState({})

  const [edit, setEdit] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)
  const [itemExcel, setItemExceç] = useState([])
  const [itemClient, setItensClient] = useState([])
  const [selectedItems, setSelectedItems] = useState([])

  const [filter, setFilter] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [successfullySentItems, setSuccessfullySentItems] = useState([])
  const [valueFile, setValueFile] = useState('')
  const ref = useRef()

  const formatCep = cep => {
    cep = cep.replace(/\D/g, '')
    if (cep.length > 5) {
      cep = cep.replace(/(\d{5})(\d)/, '$1-$2')
    }
    return cep
  }

  const formatCpfCnpj = value => {
    const onlyNumbers = value.replace(/\D/g, '')

    if (onlyNumbers.length <= 11) {
      return onlyNumbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    } else {
      return onlyNumbers.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    }
  }

  const isEmailValid = email => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    return emailRegex.test(email)
  }

  const formatMobile = mobile => {
    mobile = mobile.replace(/\D/g, '')
    if (mobile.length > 0) {
      let formattedMobile = `(${mobile.substring(0, 2)}`

      if (mobile.length > 2) {
        formattedMobile += `) ${mobile.substring(2, 7)}`
      }

      if (mobile.length > 7) {
        formattedMobile += `-${mobile.substring(7, 11)}`
      }

      return formattedMobile
    }

    return mobile
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setSelectAll(event.target.value === 'All')
  }

  const fetchData = async values => {
    setStatusTable(false)
    setStatusInfo(false)
    if (!values || !values.target || !values.target.value) {
      return
    }

    setSubCompanyId(values.target.value)
    const selectedSubCompany = subCompany.find(item => item.subCompanyId === values.target.value)

    if (selectedSubCompany) {
      setSubCompanyname(selectedSubCompany.name)
    }
  }

  useEffect(() => {
    if (subCompanyId) {
      fetchData(subCompanyId)
    }
  }, [subCompanyId])

  useEffect(() => {
    const totalClients = itemClient.length
    const batchSize = 1000
    const calculatedNumBatches = Math.ceil(totalClients / batchSize)
    setTotalClientsCount(totalClients)
    setNumBatches(calculatedNumBatches)
  }, [itemClient])

  useEffect(() => {
    const totalClients = selectedItems.length
    const batchSize = selectedItems.length / 3
    const calculatedNumBatches = Math.ceil(totalClients / batchSize)
    setTotalClientsSelectedCount(totalClients)
    setNumBatches(calculatedNumBatches)
  }, [selectedItems])

  useEffect(() => {
    if (selectedItems.length === filter.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }, [selectedItems, filter])

  const handleSelectFile = e => {
    setItensClient([])
    setValueFile(e.target.name[0])
    const selectedFile = e.target.files[0]

    if (!selectedFile) {
      setFile(null)
      return
    }
    setFile(selectedFile)
    const reader = new FileReader()

    reader.onload = event => {
      console.log(event.target.result, 'asssda')

      const data = new Uint8Array(event.target.result)
      const workbook = read(data, { type: 'array' })

      const firstSheet = workbook.Sheets[workbook.SheetNames[0]]
      const excelData = utils.sheet_to_json(firstSheet, { header: 1 })

      // Assuming the first row contains column headers
      const headers = excelData[0]
      const dataArray = []

      for (let i = 1; i < excelData.length; i++) {
        const row = excelData[i]
        const rowObject = {}

        for (let j = 0; j < headers.length; j++) {
          const header = headers[j]
          rowObject[header] = row[j]
        }

        dataArray.push(rowObject)
      }
      getClientExel(dataArray)
    }

    reader.readAsArrayBuffer(selectedFile)
  }

  const getClientExel = async data => {
    setLoading(true)
    setStatusTable(false)
    setStatusInfo(false)
    const listsExcel = data.map(item => {
      return {
        codClient: String(item.codClient),
        name: String(item.name),
        email: String(item.email),
        cpF_CNPJ: String(item.cpF_CNPJ),
        cep: String(item.cep),
        mobile: String(item.mobile),
        subCompanyname: subCompanyname,
        subCompanyId: subCompanyId,
      }
    })
    setItensClient(listsExcel)
    setValueFile('')
    resetFile()
    await new Promise(resolve => setTimeout(resolve, 1000))

    setLoading(false)
  }

  const resetFile = () => {
    ref.current.value = ''
  }

  useEffect(() => {}, [itemExcel, statusMessage])

  const addToList = single => {
    const { codClient, name, email, cpF_CNPJ, cep, mobile } = single

    if (!codClient) {
      setStatus('Código do cliente é obrigatório')
      setErrors({ codClient: 'Código do cliente é obrigatório' })
      return
    }

    if (!name || !email || !cpF_CNPJ || !cep || !mobile) {
      setStatus('Todos os campo são obrigatórios')
      return
    }

    if (!isEmailValid(email)) {
      setErrors({ email: 'Digite um email válido' })
      setStatus('Digite um email válido')
      return
    }

    const repetido = itemClient.find(x => x.codClient === codClient)

    if (repetido) {
      if (edit === true) {
        const updatedClient = {
          ...repetido,
          name,
          email,
          cpF_CNPJ,
          cep,
          mobile,
        }

        setItensClient(curr =>
          curr.map(item => (item.codClient === codClient ? updatedClient : item))
        )
        setStatus('Cliente atualizado')
        resetFields()
      } else {
        setStatus('Cliente já cadastrado')
        setErrors({ codClient: 'Código do cliente já existe' })
      }
    } else {
      if (!codClient) {
        setStatus('Código inválido')
        return
      }

      const newClient = {
        codClient,
        name,
        email,
        cpF_CNPJ,
        cep,
        mobile,
        subCompanyId,
        subCompanyname,
      }

      setItensClient(curr => [...curr, newClient])
      setStatus('Cliente adicionado')
      resetFields()
    }
  }

  const resetFields = () => {
    setEdit(false)
    setSingleProd({
      codClient: '',
      name: '',
      email: '',
      cpF_CNPJ: '',
      cep: '',
      mobile: '',
    })
  }

  useEffect(() => {}, [itemClient])

  const handleGetEdit = single => {
    setEdit(true)
    setExpanded(true)
    setSingleProd({
      codClient: single.codClient,
      name: single.name,
      email: single.email,
      cpF_CNPJ: single.cpF_CNPJ,
      cep: single.cep,
      mobile: single.mobile,
      address: single.address,
      uf: single.uf,
      city: single.city,
      neighborhood: single.neighborhood,
      number: single.number,
    })
  }

  const handleActionList = action => {
    if (selectedItems.length === 0) {
      setSeverity('error')
      setTitle('Selecione um item')
      setAlerta(true)
    } else {
      setOpen(true)
      setTitleDialog('Tem certeza que enviar esses itens?')
      setDialogAction(action)
    }
  }

  const handleItemSelect = itemId => {
    setSelectedItems(prevSelectedItems => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter(id => id !== itemId)
      } else {
        return [...prevSelectedItems, itemId]
      }
    })
  }

  const handleSelectAll = event => {
    const isChecked = event.target.checked
    setSelectAll(isChecked)
    setSelectedItems(prevSelectedItems => {
      if (!selectAll) {
        return filter.map((item, index) => item.codClient)
      } else {
        return []
      }
    })
    setRowsPerPage(isChecked ? filter.length + 1 : 10)
  }

  const handleCreatClientUnit = async client => {
    setExpanded(false)
    const updatedClients = []
    setStatusTable(true)
    setStatusMessage(prevStatusMap => ({
      ...prevStatusMap,
      [client.codClient]: (
        <div>
          <CircularProgress sx={{ marginRight: '5px', color: 'red' }} size={20} />
        </div>
      ),
    }))

    await new Promise(resolve => setTimeout(resolve, 1000))

    try {
      const response = await creatClientListUpdate(token, nameUrl, [client])
      setStatusMessage(prevStatus => ({
        ...prevStatus,
        [client.codClient]: <Chip label={response.data.message} color="success" variant="filled" />,
      }))
      await new Promise(resolve => setTimeout(resolve, 1000))
    } catch (error) {
      setStatusMessage(prevStatus => ({
        ...prevStatus,
        [client.codClient]: <Chip label="Falha ao enviar" color="error" variant="filled" />,
      }))
      updatedClients.push(client)
    }
    setFilter(prevItems => prevItems.filter(item => item.codClient !== client.codClient))
    setStatusInfo(false)
  }

  const calculateBatchSize = numClients => {
    if (numClients <= 50) {
      return 5
    } else if (numClients <= 100) {
      return 30
    } else if (numClients <= 400) {
      return 80
    } else if (numClients <= 700) {
      return 100
    } else if (numClients > 1000) {
      return 1000
    } else {
      return 2
    }
  }

  const handleSaveClient = async () => {
    setStatusMessage({})
    setExpanded(false)
    setStatusTable(true)
    setStatusInfo(true)
    setSuccessfullySentItems([])
    setSentClientsCount(0)
    setSentClients([])
    setFailedClients([])

    const numClients = itemClient.length
    const batchSize = calculateBatchSize(numClients)
    const numBatches = Math.ceil(itemClient.length / batchSize)

    for (let batchIndex = 0; batchIndex < numBatches; batchIndex++) {
      setCurrentBatchIndex(batchIndex + 1)
      const batchStart = batchIndex * batchSize
      const batchEnd = Math.min((batchIndex + 1) * batchSize, itemClient.length)
      const batchClients = itemClient.slice(batchStart, batchEnd)

      const newProgress = ((batchIndex + 1) / numBatches) * 100
      setProgress(newProgress)

      await new Promise(resolve => setTimeout(resolve, 1000))

      try {
        console.log(batchClients, 'batchClients')
        const response = await creatClientListUpdate(token, nameUrl, batchClients)
        setStatusTable(true)
        const updatedStatusMessages = {}
        batchClients.forEach(clientObj => {
          updatedStatusMessages[clientObj.codClient] = (
            <Chip label={response.data.message} color="success" variant="outlined" />
          )
        })
        setStatusMessage(prevStatusMessages => ({
          ...prevStatusMessages,
          ...updatedStatusMessages,
        }))
        await new Promise(resolve => setTimeout(resolve, 1000))

        setItensClient(prevItemClient =>
          prevItemClient.filter(clientObj => !batchClients.includes(clientObj))
        )

        setSentClients(prevSentClients => [
          ...prevSentClients,
          ...batchClients.map(client => client.codClient),
        ])

        setSentClientsCount(prevSentClientsCount => prevSentClientsCount + batchClients.length)
        setProgress((batchEnd / itemClient.length) * 100)
      } catch (error) {
        setStatusTable(true)
        batchClients.forEach(clientObj => {
          setStatusMessage(prevStatusMessages => ({
            ...prevStatusMessages,
            [clientObj.codClient]: <Chip label="Falha ao enviar" color="error" variant="filled" />,
          }))
          setFailedClients(prevFailedClients => [...prevFailedClients, clientObj.codClient])
        })
      }

      if (batchIndex < numBatches - 1) {
        await new Promise(resolve => setTimeout(resolve, 1000))
      }
    }

    setProgress(0)
    setStatusInfo(false)
  }

  const handleSaveClientSelected = async () => {
    setStatusMessage({})
    setExpanded(false)
    setStatusTable(true)
    setStatusInfo(true)
    setSuccessfullySentItems([])
    setSentClientsCount(0)
    setSentClients([])
    setFailedClients([])

    const numClients = itemClient.length
    const batchSize = calculateBatchSize(numClients)
    const selectedClients = itemClient.filter(client => selectedItems.includes(client.codClient))
    const numBatches = Math.ceil(selectedClients.length / batchSize)

    for (let batchIndex = 0; batchIndex < numBatches; batchIndex++) {
      setCurrentBatchIndex(batchIndex + 1)
      const batchStart = batchIndex * batchSize
      const batchEnd = Math.min((batchIndex + 1) * batchSize, selectedClients.length)
      const batchClients = selectedClients.slice(batchStart, batchEnd)

      setProgress((batchEnd / selectedClients.length) * 100)

      await new Promise(resolve => setTimeout(resolve, 1000))

      try {
        const batchData = batchClients.map(clientObj => ({
          codClient: clientObj.codClient,
          name: clientObj.name,
          email: clientObj.email,
          cpF_CNPJ: clientObj.cpF_CNPJ,
          cep: clientObj.cep,
          mobile: clientObj.mobile,
          subCompanyId: clientObj.subCompanyId,
          subCompanyname: clientObj.subCompanyname,
        }))

        const response = await creatClientListUpdate(token, nameUrl, batchData)
        setSentClientsCount(prevSentClientsCount => prevSentClientsCount + batchClients.length)

        const updatedStatusMessages = {}
        batchClients.forEach(clientObj => {
          updatedStatusMessages[clientObj.codClient] = (
            <Chip label={response.data.message} color="success" variant="filled" />
          )
        })
        setStatusMessage(prevStatusMessages => ({
          ...prevStatusMessages,
          ...updatedStatusMessages,
        }))
        await new Promise(resolve => setTimeout(resolve, 1000))

        const newlySuccessfullySentItems = batchClients.map(client => client.codClient)
        setSuccessfullySentItems(prevSentItems => [...prevSentItems, ...newlySuccessfullySentItems])
        setFilter(prevItems =>
          prevItems.filter(item => !newlySuccessfullySentItems.includes(item.codClient))
        )
      } catch (error) {
        batchClients.forEach(clientObj => {
          setStatusMessage(prevStatusMessages => ({
            ...prevStatusMessages,
            [clientObj.codClient]: <Chip label="Falha ao enviar" color="error" variant="filled" />,
          }))
          setFailedClients(prevFailedClients => [...prevFailedClients, clientObj.codClient])
        })
      }

      if (batchIndex < numBatches - 1) {
        await new Promise(resolve => setTimeout(resolve, 1000))
      }
    }

    setProgress(0)
    setSelectedItems([])
    setStatusInfo(false)
  }

  const deleteDialog = (clientId, del) => {
    setDeleteId(clientId)
    setOpen(true)
    setTitleDialog('Tem certeza que deseja excluir este item?')
    setDialogAction(del)
  }

  const handleDeleteItem = () => {
    const updatedItems = filter.filter(item => item.codClient !== deleteId)
    setSeverity('success')
    setTitle('Item apagado com sucesso!')
    setAlerta(true)
    setItensClient(updatedItems)
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
    setAlerta(false)
  }

  const filterRow = () => {
    const filtered = itemClient.filter(
      row =>
        row.codClient.toLowerCase().includes(search.toLowerCase()) ||
        row.name.toLowerCase().includes(search.toLowerCase()) ||
        row.email.toLowerCase().includes(search.toLowerCase()) ||
        row.cpF_CNPJ.toLowerCase().includes(search.toLowerCase())
    )
    setFilter(filtered)
  }

  useEffect(() => {
    filterRow()
  }, [search, itemClient])

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        {statusInfo && (
          <Backdrop
            sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
            open={statusInfo}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              position="relative"
            >
              <CircularProgress value={progress} sx={{ color: colorTableDefault }} size={60} />
              <Box
                sx={{
                  position: 'absolute',
                  top: '26%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <Typography>{Math.round(progress)}%</Typography>
              </Box>
              <Box textAlign="center" p={2}>
                {selectedItems.length > 0 ? (
                  <Typography>
                    {totalClientsSelectedCount.toLocaleString()} de {Math.floor(sentClientsCount)}{' '}
                    Clientes Enviados...
                  </Typography>
                ) : (
                  <Typography>
                    {totalClientsCount.toLocaleString()} de {sentClientsCount.toLocaleString()}{' '}
                    Clientes Enviados...
                  </Typography>
                )}
              </Box>
            </Box>
          </Backdrop>
        )}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>{titleDialog}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              onClick={() => {
                handleClose()
                if (dialogAction === 'delete') {
                  handleDeleteItem()
                } else if (dialogAction === 'save') {
                  handleSaveClientSelected()
                }
                setDialogAction(null)
              }}
            >
              Sim
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>

        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Carga de Cliente</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>

        <Accordion expanded={expanded} sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography variant="body1">{edit ? 'Editar Cliente' : 'Cadastrar Cliente'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                width: 1,
                mt: 2,
                display: 'flex',
                gap: 2,
                border: '1px solid black',
                borderRadius: 2,
                p: 2,
                flexDirection: 'column',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      disabled={edit}
                      sx={{ width: '100%' }}
                      value={singleProd.codClient}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Codigo do Cliente"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          codClient: e.target.value,
                        }))
                      }
                      error={!!errors.codClient}
                      helperText={errors.codClient}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      value={singleProd.name}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Nome"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          name: e.target.value,
                        }))
                      }
                      error={!!errors.name}
                      helperText={errors.name}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      value={singleProd.email}
                      id="outlined-basic"
                      variant="outlined"
                      label="Email"
                      type="text"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          email: e.target.value,
                        }))
                      }
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      value={singleProd.cpF_CNPJ}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="CPF/CNPJ"
                      inputProps={{ maxLength: 18 }}
                      onChange={e => {
                        const formattedValue = formatCpfCnpj(e.target.value)
                        setSingleProd(curr => ({
                          ...curr,
                          cpF_CNPJ: formattedValue,
                        }))
                      }}
                      error={!!errors.cpF_CNPJ}
                      helperText={errors.cpF_CNPJ}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      value={singleProd.cep}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="CEP"
                      inputProps={{ maxLength: 9 }}
                      onChange={e => {
                        const formattedCep = formatCep(e.target.value)
                        setSingleProd(curr => ({
                          ...curr,
                          cep: formattedCep,
                        }))
                      }}
                      error={!!errors.cep}
                      helperText={errors.cep}
                    />
                  </FormControl>
                </Grid>
                {/* <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      disabled={singleProd.address !== '' ? true : false}
                      sx={{ width: '100%' }}
                      value={singleProd.address}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Endereço"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          address: e.target.value,
                        }))
                      }
                      error={!!errors.address}
                      helperText={errors.address}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      disabled={singleProd.uf !== '' ? true : false}
                      sx={{ width: '100%' }}
                      value={singleProd.uf}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="UF"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          uf: e.target.value,
                        }))
                      }
                      error={!!errors.uf}
                      helperText={errors.uf}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      disabled={singleProd.city !== '' ? true : false}
                      sx={{ width: '100%' }}
                      value={singleProd.city}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Cidade"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          city: e.target.value,
                        }))
                      }
                      error={!!errors.city}
                      helperText={errors.city}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      value={singleProd.neighborhood}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Bairro"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          neighborhood: e.target.value,
                        }))
                      }
                      error={!!errors.neighborhood}
                      helperText={errors.neighborhood}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      value={singleProd.number}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Numero"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          number: e.target.value,
                        }))
                      }
                    />
                  </FormControl>
                </Grid>*/}
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      value={singleProd.mobile}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      inputProps={{ maxLength: 15 }}
                      label="Mobile"
                      onChange={e => {
                        const formattedMobile = formatMobile(e.target.value)
                        setSingleProd(curr => ({
                          ...curr,
                          mobile: formattedMobile,
                        }))
                      }}
                      error={!!errors.mobile}
                      helperText={errors.mobile}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      value={status}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Status"
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                >
                  <Button variant="outlined" onClick={() => addToList(singleProd)}>
                    {edit ? 'Atualizar Cliente' : 'Adicionar à lista'}
                  </Button>
                  {edit && (
                    <Button
                      variant="contained"
                      sx={{ ml: 1, display: 'flex', alignItems: 'center' }}
                      onClick={() => {
                        resetFields()
                      }}
                    >
                      Adicionar novo cliente <Add sx={{ ml: 1 }} />
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>

        <SelectSubCompany
          value={subCompanyId}
          onChange={e => {
            fetchData(e.target.value)
          }}
        />

        <Box
          sx={{ justifyContent: 'space-between', width: 1, minWidth: 1 }}
          display={{ xs: 'column', sm: 'column', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box
            sx={{
              alignItems: 'center',
              textAlign: 'center',
              mt: 2,
              border: '1px solid black',
              width: { xs: 1, md: 0.34 },
              minWidth: { xs: 1, md: 0.3 },
              p: 2,
            }}
          >
            <a
              className="dropzone"
              target="_blank"
              rel="noreferrer"
              href="https://file.bihands.com/template/UploadClient.xlsx"
              download
            >
              <FileDownloadIcon sx={{ fontSize: 100 }} />
            </a>
            <InputLabel>Faça o download do exemplo de carga</InputLabel>
          </Box>
          <Box
            sx={{
              mt: 2,
              border: { md: '1px solid black' },
              p: 2,
            }}
            width={{ xs: '100%', sm: '100%', md: '65%', lg: '65%', xl: '65%' }}
          >
            <InputLabel>Insira aqui o documento para enviar</InputLabel>
            <TextField
              disabled={!subCompanyId}
              sx={{ width: '100%', mt: 1 }}
              id="standard-basic"
              variant="standard"
              value={valueFile}
              onChange={handleSelectFile}
              ref={ref}
              type="file"
              accept=".xls, .xlsx, .ods"
            />
            <Box
              sx={{
                mt: 4,
                alignItems: 'center',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>Apenas arquivos .XLS ou .XLSX</Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Box>
              <TextField
                sx={{ mb: 1 }}
                id="standard-basic"
                variant="outlined"
                label="Procurar"
                value={search}
                onChange={e => setSearch(e.target.value)}
                type="text"
              />
              <Box display="flex" justifyContent="space-between" alignItems="end">
                <Box>
                  <Typography variant="body" sx={{ marginBottom: 2, mr: 2 }}>
                    {filter.length <= 1
                      ? `${filter.length} Resultado`
                      : `${filter.length} Resultados`}
                  </Typography>
                  {selectedItems.length > 0 && (
                    <Typography variant="body" sx={{ marginBottom: 2, mr: 2 }}>
                      {selectedItems.length <= 1
                        ? `${selectedItems.length} Item Selecionado`
                        : `${selectedItems.length} Itens Selecionados`}
                    </Typography>
                  )}
                  {statusTable && (
                    <>
                      <Typography variant="body" sx={{ marginBottom: 2 }}>
                        {sentClientsCount <= 1
                          ? `${sentClientsCount} Cliente Enviado`
                          : `${sentClientsCount} Clientes Enviados`}
                      </Typography>
                      <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                        {failedClients.length > 0 &&
                          `${failedClients.length} clientes não enviados`}
                      </Typography>
                    </>
                  )}
                </Box>
                {selectedItems.length > 0 ? (
                  <Button
                    variant="contained"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: ' space-between',
                      fontSize: '.9em',
                    }}
                    onClick={() => handleActionList('save')}
                  >
                    Enviar Selecionados
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: ' space-between',
                      fontSize: '.9em',
                    }}
                    onClick={() => handleSaveClient('save')}
                  >
                    Enviar Lista
                  </Button>
                )}
              </Box>
            </Box>

            <Table sx={{ minWidth: 700, mt: 1 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <Tooltip title="Selecionar Todos" arrow>
                        <span>
                          <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAll}
                            color="primary"
                          />
                        </span>
                      </Tooltip>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center">Codigo do Cliente</StyledTableCell>
                  <StyledTableCell align="center">Nome do Cliente</StyledTableCell>
                  <StyledTableCell align="center">Email</StyledTableCell>
                  <StyledTableCell align="center">CPF/CNPJ</StyledTableCell>
                  <StyledTableCell align="center">CEP</StyledTableCell>
                  <StyledTableCell align="center">Mobile</StyledTableCell>
                  {statusTable && <StyledTableCell align="center">Status</StyledTableCell>}
                  <StyledTableCell align="center" width="10%">
                    Ação
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filter

                  .filter(item => !successfullySentItems.includes(item.codClient))
                  .filter(item => !sentClients.includes(item.codClient))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        <Checkbox
                          checked={selectedItems.includes(row.codClient)}
                          onChange={() => handleItemSelect(row.codClient)}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.codClient}</StyledTableCell>
                      <StyledTableCell align="center">{row.name}</StyledTableCell>
                      <StyledTableCell align="center">{row.email}</StyledTableCell>
                      <StyledTableCell align="center">{row.cpF_CNPJ}</StyledTableCell>
                      <StyledTableCell align="center">{row.cep}</StyledTableCell>
                      <StyledTableCell align="center">{row.mobile}</StyledTableCell>
                      {statusTable && (
                        <StyledTableCell align="center">
                          {statusMessage[row.codClient] || ''}
                        </StyledTableCell>
                      )}
                      <StyledTableCell align="center">
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          {statusTable && (
                            <Tooltip title="Reenviar" arrow>
                              <IconButton onClick={() => handleCreatClientUnit(row)}>
                                <Replay sx={{ cursor: 'pointer' }} />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title="Apagar" arrow>
                            <IconButton onClick={() => deleteDialog(row.codClient, 'delete')}>
                              <DeleteForever sx={{ cursor: 'pointer' }} />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Editar" arrow>
                            <IconButton onClick={() => handleGetEdit(row)}>
                              <Edit sx={{ cursor: 'pointer' }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filter.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default UploadClient
