import { Context } from '../../../context/AuthContext'
import * as XLSX from 'xlsx'
import { useContext, useState } from 'react'
import { getAlocationStockGetAll } from '../../../services/alocationServices'
import HelpIcon from '@mui/icons-material/Help'
import PropTypes from 'prop-types'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'

//icones react-icons
import { AiOutlineFileExcel } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'

const ReportAlocationParts = () => {
  const token = localStorage.getItem('token')
  const [subCompanyId, setSubCompanyId] = useState('')
  const { subCompany, nameUrl } = useContext(Context)
  const [loading, setLoading] = useState(false)
  const [relatory, setRelatory] = useState()

  const [progressCount, setProgressCount] = useState(0)
  const [downloadMessageCount, setDownloadMessageCount] = useState('Pronto')
  const [downloadingCount, setDownloadingCount] = useState(false)
  const [totalPage, setTotalPage] = useState()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [viewElearning, setViewElearning] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const getRelatory = async e => {
    setSubCompanyId(e.target.value)
    setLoading(true)
    const data = {
      subCompanyId: e.target.value,
      limit: 500,
      offset: 1,
    }

    try {
      const response = await getAlocationStockGetAll(token, nameUrl, data)
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
        setRelatory([])
      } else {
        setRelatory(response.data.result)
        setTotalPage(response.data.totalpage)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  const downloadDataCount = async () => {
    setDownloadMessageCount('Inicializando')
    setDownloadingCount(true)
    let result = []

    for (let i = 0; i < totalPage; i++) {
      // Iniciando em 0
      try {
        setDownloadMessageCount(`Baixando ${i} de ${totalPage}...`)
        setProgressCount((i / totalPage) * 100)
        const data = {
          subCompanyId: subCompanyId,
          limit: 500,
          offset: i,
        }
        const resp = await getAlocationStockGetAll(token, nameUrl, data)
        result = result.concat(resp.data.result)
      } catch (error) {}
    }
    setDownloadMessageCount(`Seu arquivo está pronto.`)
    setDownloadingCount(false)
    const relatorioFinalContagem = []

    result.map(item => {
      relatorioFinalContagem.push({
        Rua: item.street,
        Coluna: item.column,
        Level: item.level,
        Endereço: item.address,
        Criação: new Date(item.created).toLocaleString(),
        Ativo: item.active ? 'Sim' : 'Não',
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinalContagem)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório de locação de peças')
    XLSX.writeFile(workbook, `Relatório de locação de peças.xlsx`)
    setProgressCount(0)
  }

  const handleClose = () => {
    setAlerta(false)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={downloadingCount}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessageCount}</DialogTitle>
            <LinearProgressWithLabel value={progressCount} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <ViewElearning
          pageName={'ReportAlocationParts'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Relatório de locação de peças</Typography>{' '}
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box
          sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box sx={{ mt: 2, width: '100%' }}>
            <InputLabel id="demo-simple-select-label">Filial</InputLabel>
            <Select
              label="Selecione a filial"
              value={subCompanyId}
              onChange={getRelatory}
              sx={{ width: '100%' }}
            >
              {subCompany &&
                subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
            </Select>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            mb: 2,
          }}
        >
          <Typography variant="h6"></Typography>
          {relatory && relatory.length > 0 && (
            <Button
              variant="contained"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: ' space-between',
                fontSize: '.9em',
                width: '6.5rem',
              }}
              onClick={downloadDataCount}
            >
              Excel <AiOutlineFileExcel size="2em" />
            </Button>
          )}
        </Box>
        {relatory && relatory.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Rua</StyledTableCell>
                  <StyledTableCell align="left">Coluna</StyledTableCell>
                  <StyledTableCell align="left">Level</StyledTableCell>
                  <StyledTableCell align="left">Endereço</StyledTableCell>
                  <StyledTableCell align="left">Criação</StyledTableCell>
                  <StyledTableCell align="left">Ativo</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {relatory
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left">{row.street}</StyledTableCell>
                      <StyledTableCell align="left">{row.column}</StyledTableCell>
                      <StyledTableCell align="left">{row.level}</StyledTableCell>
                      <StyledTableCell align="left">{row.address}</StyledTableCell>
                      <StyledTableCell align="left">
                        {new Date(row.created).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.active ? 'Sim' : 'Não'}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={relatory.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ReportAlocationParts
