import React, { useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  IconButton,
  Autocomplete,
  FormControl,
  tableCellClasses,
  styled,
  Tooltip,
  InputAdornment,
  Button,
  TablePagination,
} from '@mui/material'
import Collapse from '@mui/material/Collapse'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Context } from '../../../context/AuthContext'
import { Clear, Delete, Edit, Help, Print, Save, Search } from '@mui/icons-material'
import { getPrinterZplService } from '../../../services/configServices'
import {
  getMovimentationIdServices,
  getOrderPaginationService,
  getOrderPaginationServiceSearch,
  searchProductService,
} from '../../../services/productService'
import { useDebounce } from '../Product/Components/useDebounce'
import { colorTableDefault } from '../../TemplateDefault'
import { Printer, Text, Cut } from 'react-thermal-printer'
import ComponentQuantityCreate from './ComponentQuantity/Create'
import ComponentQuantityEdit from './ComponentQuantity/Edit'
import { getFaction } from '../../../services/factionService'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

function PrintOrder() {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const { debounce } = useDebounce(1000, true)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [printerZplId, setPrinterZplId] = useState('')
  const [factionId, setFactionId] = useState('')
  const [typePrint, setTypePrint] = useState(0)
  const [printZpls, setPrintZpls] = useState([])
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [product, setProduct] = useState([])
  const [totalProducts, setTotalProducts] = useState(0)
  const [selectedId, setSelectedId] = useState(undefined)
  const [productItem, setProductItem] = useState([])
  const [isExternalLoading, setIsExternalLoading] = useState(false)
  const [openModalCreate, setOpenModalCreate] = useState(false)
  const [rowsProducts, setRowsProducts] = useState([])
  const [rowsFilter, setRowsFilter] = useState([])
  const [factions, setFactions] = useState([])
  const [messageError, setMessageError] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [searchFilter, setSearchFilter] = useState('')
  const [countProducts, setCountProducts] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleDelete = itemToDelete => {
    const updatedRows = rowsProducts.filter(item => item !== itemToDelete)
    setRowsProducts(updatedRows)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handlePrintZpl = async subCompanyId => {
    try {
      const response = await getPrinterZplService(token, nameUrl, subCompanyId)
      setPrintZpls(response.data)
    } catch (error) {}
  }

  const handleGetAllProductsSearch = async () => {
    setPage(0)
    setIsLoading(true)
    const dataProduct = {
      subCompanyId: subCompanyId,
      limit: 10,
      TypeOrder: 10,
      offset: 0,
      order: 'desc',
      SearchWord: search,
    }
    try {
      getOrderPaginationServiceSearch(token, nameUrl, dataProduct).then(response => {
        const data = response.data.result
        setProduct(data)

        setIsLoading(false)
      })
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (search !== '') {
      setIsLoading(true)
      debounce(() => {
        handleGetAllProductsSearch()
      })
    }
  }, [search])

  const handleFaction = async () => {
    const data = {
      companyId: subCompany[0].companyId,
      limit: 500,
      offset: 0,
      order: 'desc',
    }
    try {
      const response = await getFaction(token, nameUrl, data)
      setFactions(response.data.result)
    } catch (error) {}
  }

  useEffect(() => {
    if (subCompany[0].companyId) {
      handleFaction()
    }
  }, [subCompany[0].companyId])

  useEffect(() => {}, [product])

  useEffect(() => {
    if (product.length === 1 && !isLoading && rowsProducts.length === 0 && selectedId && search) {
      setOpenModalCreate(true)
      setSelectedId(product[0]?.productId)
      setProductItem(product[0])
    }
  }, [product, isLoading])

  useEffect(() => {
    if (selectedId && rowsProducts.length === 0 && search) {
      setOpenModalCreate(true)
    }
  }, [selectedId, productItem])

  const autoCompleteSelectedOptions = useMemo(() => {
    if (!selectedId) return undefined

    const selectedOptions = product.find(item => item.id === selectedId)

    return selectedOptions
  }, [selectedId, product])

  const handleSaveQuantityCreate = newProduct => {
    const productIdExists = rowsProducts.some(product => product.productId === newProduct.productId)

    if (productIdExists) {
      setMessageError(true)
      return
    }
    setRowsProducts(newProduct)

    const uniqueProductIds = new Set()

    newProduct.forEach(item => {
      uniqueProductIds.add(item.productId)
    })

    const uniqueProductCount = uniqueProductIds.size
    console.log('Número de produtos únicos:', uniqueProductCount)

    setCountProducts(uniqueProductIds.size)
  }

  const handleSaveQuantityEdit = newProduct => {
    // const existingProductIndex = rowsProducts.findIndex(
    //   product => product.productId === newProduct.productId
    // )
    // if (existingProductIndex !== -1) {
    //   const updatedProducts = [...rowsProducts]
    //   updatedProducts.splice(existingProductIndex, 1, newProduct)
    //   setRowsProducts(updatedProducts)
    // } else {
    //   setRowsProducts(prevent => [...prevent, newProduct])
    // }
  }

  const filterRow = () => {
    const filtered = rowsProducts.filter(
      item =>
        item.productName.toLowerCase().includes(searchFilter.toLowerCase()) ||
        item.idProductPartner.toLowerCase().includes(searchFilter.toLowerCase()) ||
        item.serial.toLowerCase().includes(searchFilter.toLowerCase()) ||
        item.barCode.toLowerCase().includes(searchFilter.toLowerCase())
    )
    setRowsFilter(filtered)
  }

  useEffect(() => {
    filterRow()
  }, [searchFilter, rowsProducts])

  return (
    <Box sx={{ mt: 7 }}>
      <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">Impressão por pedido</Typography>
        <IconButton sx={{ justifySelf: 'flex-end' }}>
          <Help />
        </IconButton>
      </Box>

      <ComponentQuantityCreate
        open={openModalCreate}
        onClose={() => {
          setOpenModalCreate(false)
        }}
        product={productItem}
        message={messageError}
        onSaveQuantity={handleSaveQuantityCreate}
        id={selectedId}
      />
      <ComponentQuantityEdit
        open={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false)
        }}
        product={productItem}
        onSaveQuantity={handleSaveQuantityEdit}
        isEdit={isEditModalOpen}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <FormControl sx={{ width: '100%', mt: 0 }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                value={subCompanyId}
                disabled={rowsProducts.length > 0}
                onChange={event => {
                  setSubCompanyId(event.target.value)
                  handlePrintZpl(event.target.value)
                }}
                sx={{ width: '100%' }}
                label="Filial"
              >
                {subCompany.map((item, index) => (
                  <MenuItem value={item.subCompanyId} key={item.subCompanyId}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <FormControl sx={{ width: '100%', mt: 0 }}>
              <InputLabel id="demo-simple-select-label">Layout da etiqueta</InputLabel>
              <Select
                value={printerZplId}
                disabled={!subCompanyId || rowsProducts.length > 0}
                onChange={event => {
                  setPrinterZplId(event.target.value)
                }}
                sx={{ width: '100%' }}
                label="Layout da etiqueta"
              >
                {printZpls.map((item, index) => (
                  <MenuItem value={item.subCompanyId} key={item.subCompanyId}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box width="100%">
            <Autocomplete
              openText="Abrir"
              closeText="Fechar"
              clearText="Apagar"
              noOptionsText={isLoading ? 'Carregando...' : 'Nenhum resultado encontrado'}
              loadingText="Carregando..."
              disablePortal
              value={autoCompleteSelectedOptions}
              loading={isLoading}
              onInputChange={(_, newValue) => setSearch(newValue)}
              disabled={!subCompanyId || isExternalLoading || rowsProducts.length > 0}
              popupIcon={
                isExternalLoading || isLoading ? (
                  <CircularProgress color="warning" size={20} />
                ) : undefined
              }
              onChange={(_, newValue) => {
                setSelectedId(newValue?.movimentationOrderId)
                setSearch(newValue ? newValue.movimentationOrderNumberOtherSystem : '')
                if (newValue === null) {
                  handleGetAllProductsSearch()
                }
              }}
              options={product}
              getOptionLabel={option =>
                option
                  ? `${option.movimentationOrderNumberOtherSystem} - ${
                      option.movimentationOrderNumberBihands || ''
                    }`
                  : ''
              }
              isOptionEqualToValue={(option, value) =>
                option.movimentationOrderId === value.movimentationOrderId
              }
              renderInput={params => <TextField {...params} label="Pesquisar pedidos" />}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <FormControl sx={{ width: '100%', mt: 0 }}>
              <InputLabel id="demo-simple-select-label">Facção</InputLabel>
              <Select
                value={factionId}
                disabled={!subCompanyId || !printerZplId || rowsProducts.length > 0}
                onChange={event => {
                  setFactionId(event.target.value)
                }}
                sx={{ width: '100%' }}
                label="Facção"
              >
                {factions.map((item, index) => (
                  <MenuItem value={item.factionId} key={item.factionId}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} md={typePrint === 3 ? 2 : 4}>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <FormControl sx={{ width: '100%', mt: 0 }}>
              <InputLabel id="demo-simple-select-label">Selecione o tipo de impressora</InputLabel>
              <Select
                value={typePrint}
                disabled={!subCompanyId || rowsProducts.length > 0}
                onChange={event => {
                  setTypePrint(event.target.value)
                }}
                sx={{ width: '100%' }}
                label="Selecione o tipo de impressora"
              >
                {/* <MenuItem value={1}>Ethernet</MenuItem>
                <MenuItem value={2}>Postek</MenuItem>
                <MenuItem value={3}>USB</MenuItem> */}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {typePrint === 3 && (
          <Grid item xs={12} md={2}>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <FormControl sx={{ width: '100%', mt: 0 }}>
                <InputLabel id="demo-simple-select-label">
                  Selecione o tipo de impressora
                </InputLabel>
                <Select
                  value={typePrint}
                  disabled={!subCompanyId}
                  onChange={event => {
                    setTypePrint(event.target.value)
                  }}
                  sx={{ width: '100%' }}
                  label="Selecione o tipo de impressora"
                >
                  {/* <MenuItem value={1}>Ethernet</MenuItem>
                  <MenuItem value={2}>Postek</MenuItem>
                  <MenuItem value={3}>USB</MenuItem> */}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              sx={{ maxWidth: '500px', width: '100%' }}
              type="text"
              variant="outlined"
              placeholder="Pesquisar"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={searchFilter}
              onChange={e => setSearchFilter(e.target.value)}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          <Box display="flex" alignItems="center" justifyContent="end" gap={2} mt={2}>
            <Button
              onClick={() => {
                setRowsProducts([])
                setSearch('')
                setCountProducts(0)
              }}
              startIcon={<Clear />}
              variant="outlined"
              sx={{ width: '100%', maxWidth: '180px' }}
            >
              Limpar
            </Button>
            <Button
              startIcon={<Save />}
              variant="contained"
              sx={{ width: '100%', maxWidth: '180px' }}
            >
              Salvar dados
            </Button>
            <Button
              startIcon={<Print />}
              variant="contained"
              sx={{ width: '100%', maxWidth: '180px' }}
            >
              Imprimir todos
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="start" gap={2}>
            <Typography variant="h5">{`Total de produtos ${countProducts}`}</Typography>
            <Typography variant="h5">{`Total de códigos gerados ${rowsFilter.length}`}</Typography>
          </Box>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <TableContainer component={Paper}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Codigo</StyledTableCell>
                      <StyledTableCell align="left">Nome do produto</StyledTableCell>
                      <StyledTableCell align="left">Codigo de barras</StyledTableCell>
                      <StyledTableCell align="center">Serial</StyledTableCell>
                      <StyledTableCell align="left"></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsFilter
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((item, index) => (
                        <TableRow key={item.serial}>
                          <StyledTableCell align="left">{item.idProductPartner}</StyledTableCell>
                          <StyledTableCell align="left">{item.productName}</StyledTableCell>
                          <StyledTableCell align="left">{item.barCode}</StyledTableCell>
                          <StyledTableCell align="center">{item.serial}</StyledTableCell>
                          <StyledTableCell align="center" width="200px">
                            {/* <Tooltip title="Editar" arrow>
                            <IconButton>
                              <Edit />
                            </IconButton>
                          </Tooltip> */}
                            <Tooltip title="Apagar" arrow>
                              <IconButton>
                                <Delete />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Imprimir" arrow>
                              <IconButton>
                                <Print />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsFilter.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PrintOrder
