import axios from 'axios'

export const getAccess = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/AccessRedirect/GetAll?SubCompanyId=${data.subCompanyId}&CreateDate=${data.createDate}&FinishDate=${data.finishData}&limit=${data.limit}&offset=${data.offset}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}
