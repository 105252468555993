import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { useContext, useEffect, useState } from 'react'
import {
  Api,
  Clear,
  DeleteForever,
  ExpandMore,
  Message,
  ModeEditOutline,
  Person,
  Visibility,
} from '@mui/icons-material'
import ApexChart from './Components/ColumnChartBasic '
import ColumnChartBasic from './Components/ColumnChartBasic '
import PermissionComponent from '../../../permissions'
import ProductEdit from '../../Edit/ProductEdit'
import ProductCreate from '../../Create/ProductCreate'
import { Context } from '../../../context/AuthContext'
import { AiFillPlusCircle } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import { deleteProductService } from '../../../services/productService'
import { TicketCreate } from '../../Create/TicketCreate'
import { TicketEdit } from '../../Edit/TicketEdit'
import { getTickeUser } from '../../../services/helpdeskService'
import { useNavigate } from 'react-router-dom'
import { formatDistance, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '8px',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export const Ticket = () => {
  const token = localStorage.getItem('token')
  const navigate = useNavigate()
  const { subCompany, userId, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const [viewElearning, setViewElearning] = useState(false)
  const [ticket, setTiket] = useState([])
  const [filterRow, setFilterRow] = useState([])
  const [companyId, setCompanyId] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const vertical = 'top'
  const horizontal = 'center'
  const [register, setRegister] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [name, setName] = useState('')
  const [ticketId, setTicketId] = useState('')
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)
  const [searchR, setSearchR] = useState('')

  const [errorEmpresa, setErrorEmpresa] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteTicket, setDeleteTicket] = useState({ ticketName: '', ticketId: '' })
  const [loading, setLoading] = useState(false)
  const [selectedType, setSelectedType] = useState('')
  const [selectedPriority, setSelectedPriority] = useState('')
  const [selectedDepartment, setSelectedDepartment] = useState('')

  const calculateTimeDifference = lastUpdateDate => {
    const currentDate = new Date()
    const updateDate = parseISO(lastUpdateDate)

    const timeDifference = formatDistance(updateDate, currentDate, {
      addSuffix: true,
      locale: ptBR,
    })

    return timeDifference
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
    //getAllProducts(companyId)
  }

  const onEditClick = e => {
    e.stopPropagation()
  }

  const handleGetTicket = async subCompanyId => {
    setLoading(true)
    setSubCompanyId(subCompanyId)
    const data = {
      subCompanyId: subCompanyId,
      userId: userId,
    }

    try {
      const response = await getTickeUser(token, data)
      const sortedTickets = response.data.result.sort((a, b) => {
        const dateA = new Date(a.createDate)
        const dateB = new Date(b.createDate)
        return dateB - dateA
      })

      setTiket(sortedTickets)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (subCompanyId) {
      handleGetTicket(subCompanyId)
    }
  }, [subCompanyId])

  const handleEditing = id => {
    setTicketId(id)
    setEditing(true)
    setExpanded(true)
  }

  const handleClose = () => {
    setRegister(false)
    setEditing(false)
    setDeleting(false)
  }

  const handleDelete = async () => {
    setLoading(true)
    try {
      await deleteProductService(token, deleteTicket.ticketId)
      setLoading(false)
      setDeleteOpen(false)
      const gambi = { target: { value: companyId } }
      //getAllProducts(gambi)
    } catch (err) {
      setLoading(false)
    }
  }

  const filter = () => {
    const filtered = ticket.filter(
      row =>
        (row.ticket.toLowerCase().includes(search.toLowerCase()) ||
          row.subject.toLowerCase().includes(search.toLowerCase())) &&
        (selectedType === '' || row.typeTicket === selectedType) &&
        (selectedPriority === '' || row.priority === selectedPriority) &&
        (selectedDepartment === '' || row.derpatament === selectedDepartment)
    )
    setFilterRow(filtered)
  }

  useEffect(() => {
    filter()
  }, [search, selectedType, selectedPriority, selectedDepartment, ticket])

  return (
    <Box sx={{ mt: 7.5 }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={register}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {title}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          width: 1,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h5">Ticket</Typography>
        <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
          <HelpIcon />
        </IconButton>
      </Box>
      <Dialog
        open={deleting}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Tem certeza que deseja excluir esse ticket?
        </DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Não
          </Button>
          <Button onClick={deleteTicket}>Sim</Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Accordion expanded={expanded} onClick={() => setExpanded(!expanded)} disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {editing ? (
                <Typography>Editando Ticket - {name}</Typography>
              ) : (
                <Typography>Cadastrar Ticket</Typography>
              )}
            </AccordionSummary>
            <AccordionDetails onClick={e => onEditClick(e)}>
              {editing ? (
                <PermissionComponent role="Edit_Ticket">
                  <TicketEdit
                    setName={setName}
                    setExpanded={setExpanded}
                    ticketId={ticketId}
                    setTitle={setTitle}
                    setSeverity={setSeverity}
                    setAlerta={setRegister}
                    setEditing={setEditing}
                    //getAllProducts={getAllProducts}
                  />
                </PermissionComponent>
              ) : (
                <PermissionComponent role="Create_Ticket">
                  <TicketCreate
                    setExpanded={setExpanded}
                    setTitle={setTitle}
                    setSeverity={setSeverity}
                    setAlerta={setRegister}
                    handleGetTicket={handleGetTicket}
                    //getAllProducts={getAllProducts}
                  />
                </PermissionComponent>
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <SelectSubCompany
            value={subCompanyId}
            onChange={e => {
              setSubCompanyId(e.target.value)
              handleGetTicket(e.target.value)
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => {
                setSelectedType('')
                setSelectedPriority('')
                setSelectedDepartment('')
                setSearch('')
              }}
              variant="outlined"
              endIcon={<Clear />}
            >
              Limpar
            </Button>
            <PermissionComponent role="Create_Ticket">
              <Button
                sx={{ ml: 2 }}
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
                variant="contained"
                endIcon={<AiFillPlusCircle />}
              >
                Criar
              </Button>
            </PermissionComponent>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box width="100%" height="55px">
            <TextField
              sx={{ width: '100%', height: '100%' }}
              value={search}
              id="outlined-basic"
              variant="outlined"
              type="text"
              label="Procurar ticket"
              disabled={searching}
              onChange={e => setSearch(e.target.value)}
            />
            <Typography sx={{ width: 1, margin: '10px 0' }}>{searchR}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Tipo de ticket </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              label="Prioridade"
              sx={{ width: '100%' }}
              onChange={event => setSelectedType(event.target.value)}
              value={selectedType}
            >
              <MenuItem value={''}>Nenhum</MenuItem>
              <MenuItem value={1}>Suporte Equipamento</MenuItem>
              <MenuItem value={2}>Suporte Sistemas</MenuItem>
              <MenuItem value={3}>Manutenção em Equipamentos</MenuItem>
              <MenuItem value={4}>Dúvidas</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Prioridade</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              label="Prioridade"
              sx={{ width: '100%' }}
              onChange={event => setSelectedPriority(event.target.value)}
              value={selectedPriority}
            >
              <MenuItem value={''}>Nenhum</MenuItem>
              <MenuItem value={1}>Urgente</MenuItem>
              <MenuItem value={2}>Emergencial</MenuItem>
              <MenuItem value={3}>Normal</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">Departamento</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              label="Departamento"
              sx={{ width: '100%' }}
              onChange={event => setSelectedDepartment(event.target.value)}
              value={selectedDepartment}
            >
              <MenuItem value={''}>Nenhum</MenuItem>
              <MenuItem value={1}>Financeiro</MenuItem>
              <MenuItem value={0}>Suporte</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {ticket.length > 0 && (
        <TableContainer sx={{ my: 2 }} component={Paper}>
          <Typography>
            {filterRow.length > 1
              ? filterRow.length + ' Resultados'
              : filterRow.length + ' Resultado'}
          </Typography>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Sessão</StyledTableCell>
                <StyledTableCell align="left">Nome</StyledTableCell>
                <StyledTableCell align="left">Assunto</StyledTableCell>
                <StyledTableCell align="left">Tipo de ticket</StyledTableCell>
                <StyledTableCell align="left">Prioridade</StyledTableCell>
                <StyledTableCell align="left">Departamento</StyledTableCell>
                <StyledTableCell align="left">Data de Criaçao</StyledTableCell>
                <StyledTableCell align="left">Atualização</StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell> </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterRow
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow key={row.movimentationOrderTicketId}>
                    <StyledTableCell component="th" scope="row">
                      {(row.ticket && '#' + row.ticket) || ''}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ fontWeight: 700 }}>
                      {row.createUserName || ''}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.subject}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.typeTicket === 1
                        ? 'Suporte Equipamento'
                        : row.typeTicket === 2
                        ? 'Suporte Sistemas'
                        : row.typeTicket === 3
                        ? 'Manutenção em Equipamentos'
                        : row.typeTicket === 4 && 'Dúvidas'}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.priority === 1
                        ? 'Urgente'
                        : row.priority === 2
                        ? 'Emergencial'
                        : row.priority === 3 && 'Normal'}
                    </StyledTableCell>{' '}
                    <StyledTableCell align="left">
                      {row.derpatament === 1 ? 'Financeiro' : row.derpatament === 0 && 'Suporte'}
                    </StyledTableCell>{' '}
                    <StyledTableCell align="left">
                      {new Date(row.createDate).toLocaleString()}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {calculateTimeDifference(row.lastUpdateDate)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.status === 0 ? (
                        <Chip
                          label="Aguardando atendimento"
                          style={{
                            background: '#E0E7FF',
                            color: '#7A76DD',
                            borderRadius: '5px',
                          }}
                        />
                      ) : row.status === 1 ? (
                        <Chip
                          label="Em progresso"
                          style={{ background: '#FEF08A', color: '#A26308', borderRadius: '5px' }}
                        />
                      ) : row.status === 6 ? (
                        <Chip
                          label="Em atraso"
                          styl
                          style={{ background: '#FEE2E2', color: '#B91C1C', borderRadius: '5px' }}
                        />
                      ) : row.status === -999 ? (
                        <Chip
                          label="Cancelado"
                          style={{ background: '#FEE2E2', color: '#B91C1C', borderRadius: '5px' }}
                        />
                      ) : row.status === 3 ? (
                        <Chip
                          label="Finalizado"
                          style={{
                            background: '#F0FDF4',
                            color: '#3EB469',
                            borderRadius: '5px',
                          }}
                        />
                      ) : row.status === 5 ? (
                        <Chip
                          label="Aguardando retorno do cliente"
                          style={{
                            background: '#FEF08A',
                            color: '#A26308',
                            borderRadius: '5px',
                          }}
                        />
                      ) : (
                        row.status === 4 && (
                          <Chip
                            label="Aguardando retorno do suporte"
                            style={{
                              background: '#FEF08A',
                              color: '#A26308',
                              borderRadius: '5px',
                            }}
                          />
                        )
                      )}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <PermissionComponent role="View_Ticket">
                        <Tooltip title="Visualizar" arrow>
                          <IconButton
                            onClick={() =>
                              navigate(`/TicketViewReponseClient/${row.movimentationOrderTicketId}`)
                            }
                            sx={{ cursor: 'pointer' }}
                          >
                            <Visibility />
                          </IconButton>
                        </Tooltip>
                      </PermissionComponent>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            labelRowsPerPage={'Itens por página'}
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={filterRow.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog
        open={deleteOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Apagar Produto?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirme que você quer apagar o ticket "{deleteTicket.productName}"
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleDelete} autoFocus>
            Apagar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
