import React, { useContext } from 'react'
import { Button } from '@mui/material'
import { Context } from '../context/AuthContext'

const PermissionComponent = ({ role, children }) => {
  const { authenticated, userRolesNew, nameUrl } = useContext(Context)

  // Defina as permissões necessárias para cada ação

  if (!userRolesNew || userRolesNew.length === 0) {
    return null
  }

  if (!role && authenticated) {
    return children
  }

  const hasPermission = userRolesNew.some(permission => permission.rolesName === role)

  if (!authenticated || !hasPermission) {
    // Não autenticado ou não tem permissão ativa
    return null
  }

  return children
}

export default PermissionComponent
