import React, { useCallback, useContext, useEffect } from 'react'
import { useState } from 'react'
import { Context } from '../../../context/AuthContext'
import * as XLSX from 'xlsx'
import { RESULTS_PER_PAGE } from '../../../utils/excel'
import { deleteDepositService, getDepositService } from '../../../services/depositService'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import HelpIcon from '@mui/icons-material/Help'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Tooltip,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import DepositCreate from '../../Create/DepositCreate'
import DepositEdit from '../../Edit/DepositEdit'

//icones react-icons
import { AiFillFileExcel } from 'react-icons/ai'
import { AiFillPlusCircle } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'
import PermissionComponent from '../../../permissions'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const Deposit = () => {
  const { subCompany, userRoles, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const token = localStorage.getItem('token')
  const [deposit, setDeposit] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(false)

  const [totalPagesDownload, setTotalPagesDownload] = useState('')
  const [progress, setProgress] = useState(0)
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)
  const [table, setTable] = useState()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [depositId, setDepositId] = useState('')
  const [viewElearning, setViewElearning] = useState(false)
  const [depositDelete, setDepositDelete] = useState('')

  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const vertical = 'top'
  const horizontal = 'center'

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleDelete = id => {
    setOpen(true)
    setDepositDelete(id)
  }

  const fetchData = async values => {
    setSubCompanyId(values)
    setLoading(true)
    setTable(false)
    const dados = {
      limit: 500,
      order: 0,
      offset: 0,
      subCompanyId: values,
    }
    try {
      const response = await getDepositService(token, nameUrl, dados)
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
        setDeposit([])
      } else {
        setDeposit(response.data.result)
        setPageCount(response.data.totalpage + 1)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (subCompanyId) {
      fetchData(subCompanyId)
    }
  }, [subCompanyId])

  const handleFetchData = async () => {
    setLoading(true)
    const dados = {
      limit: 500,
      order: 0,
      offset: 0,
      SubCompanyId: subCompanyId,
    }
    try {
      const response = await getDepositService(token, nameUrl, dados)
      setDeposit(response.data.result)
      setPageCount(response.data.totalpage + 1)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const downloadData = async () => {
    setDownloadMessage('Inicializando')
    setDownloading(true)
    const dados = {
      limit: RESULTS_PER_PAGE,
      order: 0,
      offset: 0,
      SubCompanyId: subCompanyId,
    }
    await getDepositService(token, nameUrl, dados).then(r => {
      setTotalPagesDownload(r.data.totalpage + 1)
      finishDownload(r.data.totalpage + 1)
    })
  }

  const finishDownload = async pages => {
    let result = []

    for (let i = 0; i <= pages; i++) {
      setDownloadMessage(`Baixando ${i} de ${pages}...`)
      setProgress((i / pages) * 100)
      const dados = {
        limit: RESULTS_PER_PAGE,
        order: 0,
        offset: i,
        SubCompanyId: subCompanyId,
      }
      const response = await getDepositService(token, nameUrl, dados)
      result = result.concat(response.data.result)
    }
    setDownloadMessage(`Seu arquivo está pronto.`)
    setDownloading(false)
    setProgress(0)
    const relatorioFinal = []
    //VER A PARTIR DAQUI
    result.map(item => {
      relatorioFinal.push({
        'Nome depósito': item.name,
        Filial: item.subCompanyId,
        Ativo: item.active ? 'Sim' : 'Não',
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Depósito')
    XLSX.writeFile(workbook, `Depósito.xlsx`)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const handleClose = () => {
    setDownloading(false)
    setAlerta(false)
  }

  const handleDeleteDeposit = async () => {
    try {
      await deleteDepositService(token, nameUrl, depositDelete)
      setOpen(false)
      fetchData({ target: { value: subCompanyId } })
    } catch (e) {
      console.log(e)
    }
  }

  const handleDeleteClose = () => {
    setError(false)
    setOpen(false)
    setAlerta(false)
    setEditing(false)
  }

  const handleEdit = id => {
    setDepositId(id)
    setExpanded(true)
    setEditing(true)
  }

  //importar css
  //importar icones
  //alterar header tabela
  //alterar mt do box
  //alterar mb do typography
  //alterar tamanho do select
  //alterar botoes
  //trocar icone edit

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Dialog
          open={downloading}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessage}</DialogTitle>
            <LinearProgressWithLabel value={progress} />
          </Box>
        </Dialog>
        <Dialog
          open={open}
          onClose={handleDeleteClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose}>Cancelar</Button>
            <Button onClick={() => handleDeleteDeposit()}>Sim</Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <ViewElearning pageName={'Deposit'} open={viewElearning} setOpen={setViewElearning} />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Depósitos</Typography>{' '}
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>

        {/* Criar Depósito */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>{editing ? 'Editar Depósito' : 'Cadastrar Depósito'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="Edit_Deposit">
                <DepositEdit
                  setEditing={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  depositId={depositId}
                  fetchData={handleFetchData}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="Create_Deposit">
                <DepositCreate
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  fetchData={handleFetchData}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>

        <SelectSubCompany
          value={subCompanyId}
          onChange={e => {
            fetchData(e.target.value)
          }}
        />
        <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
            {deposit.length > 0 && (
              <Button onClick={downloadData} variant="contained" endIcon={<AiFillFileExcel />}>
                Excel
              </Button>
            )}
          </Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', ml: 1 }}
          >
            <PermissionComponent role="Create_Deposit">
              <Button
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
                variant="contained"
                endIcon={<AiFillPlusCircle />}
              >
                Criar
              </Button>
            </PermissionComponent>
          </Box>
        </Box>
        {deposit.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell> </StyledTableCell>
                  <StyledTableCell align="left">Nome Depósito</StyledTableCell>
                  <StyledTableCell align="left">Filial</StyledTableCell>
                  <StyledTableCell align="left">ID outro sistema</StyledTableCell>
                  <StyledTableCell align="left">Ativo</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deposit.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                      <PermissionComponent role="Edit_Deposit">
                        <Tooltip title="Apagar" arrow>
                          <IconButton
                            onClick={() => handleDelete(row.depositId)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </Tooltip>
                      </PermissionComponent>
                      <PermissionComponent role="Edit_Deposit">
                        <Tooltip title="Editar" arrow>
                          <IconButton
                            onClick={() => handleEdit(row.depositId)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <ModeEditOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </PermissionComponent>
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.name}</StyledTableCell>
                    <StyledTableCell align="left">{row.subCompanyName}</StyledTableCell>
                    <StyledTableCell align="left">{row.idDepositPartner}</StyledTableCell>
                    <StyledTableCell align="left">{row.active ? 'Sim' : 'Não'}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={deposit.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default Deposit
