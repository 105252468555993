import { useState, useContext, useEffect, useRef } from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Context } from '../../../context/AuthContext'
import Alert from '@mui/material/Alert'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
  InputBase,
  Chip,
  Autocomplete,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import styled from '@emotion/styled'
import { colorTableDefault } from '../../TemplateDefault'
import {
  Add,
  DeleteForever,
  Edit,
  ExpandMore,
  Replay,
  CheckCircleRounded,
} from '@mui/icons-material'
import {
  getClientId,
  searchClientService,
  uploadProductions,
} from '../../../services/clientService'
import {
  createOrderProcessFullService,
  getAllMovOrderService,
} from '../../../services/movBreakServices'
import { getSingleProductService, searchProductService } from '../../../services/productService'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const UpdateProduction = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [subCompanyCpnj, setSubCompanyCnpj] = useState('')
  const [file, setFile] = useState(null)
  const token = localStorage.getItem('token')
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [titleDialog, setTitleDialog] = useState('')
  const [severity, setSeverity] = useState('error')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  let formData = new FormData()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [statusMessage, setStatusMessage] = useState({})
  const [statusTable, setStatusTable] = useState(false)
  const [statusInfo, setStatusInfo] = useState(false)
  const [sentProds, setSentProds] = useState([])
  const [successfullySentItems, setSuccessfullySentItems] = useState([])
  const [currentBatchIndex, setCurrentBatchIndex] = useState(0)
  const [progress, setProgress] = useState(0)
  const [valueFile, setValueFile] = useState('')
  const ref = useRef()

  const [dialogAction, setDialogAction] = useState(null)

  const [singleProd, setSingleProd] = useState({
    id: '',
    movimentationOrderNumberOtherSystem: '',
    codClient: '',
    clientName: '',
    idProductPartner: '',
    productName: '',
    quantity: '',
  })

  const [status, setStatus] = useState('Pronto')
  const [deleteId, setDeleteId] = useState('')
  const [search, setSearch] = useState('')
  const [errors, setErrors] = useState({})
  const [selectedStatus, setSelectedStatus] = useState('')

  const [sending, setSending] = useState(false)
  const [edit, setEdit] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)
  const [itemExcel, setItemExceç] = useState([])
  const [downListOrder, setDownListOrder] = useState([])
  const [itemProd, setItensProd] = useState([])
  const [selectedItems, setSelectedItems] = useState([])

  const [filter, setFilter] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [editingIndexOuterSystem, setEditingIndexOuterSystem] = useState(-1)
  const [editingIndexProduct, setEditingIndexProduct] = useState(-1)
  const [editingIndexClient, setEditingIndexClient] = useState(-1)
  const [editedValue, setEditedValue] = useState('')
  const [showEditButtonOuterSystem, setShowEditButtonOuterSystem] = useState({})
  const [searchProd, setSearchProd] = useState('')
  const [searchClient, setSearchClient] = useState('')

  const [searchingProd, setSearchingProd] = useState(false)
  const [searchingClient, setSearchingClient] = useState(false)
  const [clients, setClients] = useState([])
  const [produtos, setProdutos] = useState([])
  const [sentProdsCount, setSentProdsCount] = useState(0)
  const [failedProds, setFailedProds] = useState([])
  const [numberItem, setNumberItem] = useState([])
  const [listOrderExit, setListOrderExist] = useState([])
  const [compareOrder, setCompareOrder] = useState([])
  const [totaProdsSelectedCount, setTotaProdsSelectedCount] = useState(0)
  const [totalProdsCount, setTotalProdsCount] = useState(0)
  const [numBatches, setNumBatches] = useState(0)

  const handleEditOuterSystem = (index, value) => {
    setEditedValue(value)
    setEditingIndexOuterSystem(index)
  }

  const handleInputChange = event => {
    setEditedValue(event.target.value)
  }

  useEffect(() => {
    if (searchProd === '') {
      const checagem = setTimeout(() => {
        setProdutos([{ label: '', value: '' }])
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearchingProd(true)
      const checagem = setTimeout(() => {
        const dados = {
          companyId: subCompany[0].companyId,
          SearchWord: searchProd,
          limit: 10,
          offset: 0,
        }
        searchProductService(token, nameUrl, dados).then(response => {
          const prodsFinal = []
          response.data.result.map(produto => {
            const obj = produto
            obj.value = produto.productId
            obj.label = `${produto.name}`
            prodsFinal.push(obj)
          })
          setProdutos(prodsFinal)
          setSearchingProd(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [searchProd])

  useEffect(() => {
    if (searchClient === '') {
      const checagem = setTimeout(() => {
        setClients([{ label: '', value: '' }])
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearchingClient(true)
      const checagem = setTimeout(() => {
        const dados = {
          subCompanyId: subCompanyId,
          search: searchClient,
        }
        searchClientService(token, nameUrl, dados).then(response => {
          const clientsFinal = []
          response.data.map(client => {
            const obj = client
            obj.value = client.clientId
            obj.label = `${client.codClient} - ${client.name}`
            clientsFinal.push(obj)
          })
          setClients(clientsFinal)
          searchingClient(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [searchClient])

  const changeProd = async id => {
    try {
      const response = await getSingleProductService(token, nameUrl, { productId: id })
      const prod = response.data

      setSingleProd(prevSingleProd => ({
        ...prevSingleProd,
        idProductPartner: prod.idProductPartner,
        productName: prod.name,
      }))
    } catch (error) {}
  }

  const changeClient = async id => {
    try {
      const response = await getClientId(token, nameUrl, id)
      const prod = response.data

      setSingleProd(prevSingleProd => ({
        ...prevSingleProd,
        codClient: prod.codClient,
        clientName: prod.name,
      }))
    } catch (error) {}
  }

  const handleSaveCodeOuterSystem = index => {
    if (editingIndexOuterSystem === index) {
      setSingleProd(prevSingleProd => ({
        ...prevSingleProd,
        movimentationOrderNumberOtherSystem: editedValue,
      }))
      const updatedItemClient = itemProd.map((item, itemIndex) =>
        itemIndex === index ? { ...item, movimentationOrderNumberOtherSystem: editedValue } : item
      )
      setItensProd(updatedItemClient)

      const updatedFilter = filter.map((item, itemIndex) =>
        itemIndex === index ? { ...item, movimentationOrderNumberOtherSystem: editedValue } : item
      )
      setFilter(updatedFilter)
      setEditingIndexOuterSystem(-1)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setSelectAll(event.target.value === 'All')
  }

  const handleItemSelect = itemId => {
    setSelectedItems(prevSelectedItems => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter(id => id !== itemId)
      } else {
        return [...prevSelectedItems, itemId]
      }
    })
  }

  const handleSelectAll = event => {
    const isChecked = event.target.checked
    setSelectAll(isChecked)
    setSelectedItems(prevSelectedItems => {
      if (!selectAll) {
        return filter.map((item, index) => item.productId)
      } else {
        return []
      }
    })
    setRowsPerPage(isChecked ? filter.length + 1 : 10)
  }

  useEffect(() => {
    if (selectedItems.length === filter.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }, [selectedItems, filter])

  useEffect(() => {}, [singleProd])

  const handleWindowClick = () => {
    if (editingIndexOuterSystem !== -1) {
      setEditingIndexOuterSystem(-1)
    }

    if (editingIndexProduct !== -1) {
      setEditingIndexProduct(-1)
    }

    if (editingIndexClient !== -1) {
      setEditingIndexClient(-1)
    }
  }

  useEffect(() => {}, [itemExcel, statusMessage])

  const fetchData = async values => {
    if (!values.target.value) {
      return
    }
    const getValue = values.target.value
    setSubCompanyId(getValue)

    const selectedSubCompany = subCompany.find(item => item.subCompanyId === getValue)
    if (selectedSubCompany) {
      setSubCompanyCnpj(selectedSubCompany.cnpjCompany)
    }
  }

  const handleSelectFile = e => {
    setLoading(true)
    setDownListOrder([])
    setNumberItem([])
    setItensProd([])
    setListOrderExist([])
    setCompareOrder([])
    setValueFile(e.target.name[0])

    const selectedFile = e.target.files[0]

    if (!selectedFile) {
      setFile(null)
      return
    }
    setFile(selectedFile)
    sendFile(selectedFile)
  }

  const sendFile = async file => {
    setSending(true)
    formData.append('file', file)
    formData.append('SubCompanyId', subCompanyId)

    try {
      const response = await uploadProductions(token, nameUrl, formData, setProgress)
      const uniqueProducts = getUniqueProducts(response.data.infomationList)
      setDownListOrder(uniqueProducts)
      setNumberItem(uniqueProducts)
      setSeverity('success')
      setSending(false)
      setStatusMessage({})
      getCompareOrderOuterSystem(uniqueProducts)
      setStatusMessage({})
      setSuccessfullySentItems([])
      setListOrderExist([])
      setSentProdsCount([])
      setFailedProds([])
    } catch (error) {
      setSeverity('error')
      setSending(false)
    }
  }

  const resetFile = () => {
    ref.current.value = ''
  }

  const getUniqueProducts = dataList => {
    const uniqueProductsMap = new Map()

    for (const item of dataList) {
      if (!uniqueProductsMap.has(item.idProductPartner)) {
        uniqueProductsMap.set(item.idProductPartner, item)
      }
    }

    return Array.from(uniqueProductsMap.values())
  }

  const getCompareOrderOuterSystem = async dataListOne => {
    setLoading(true)

    const data = {
      SubCompanyId: subCompanyId,
      limit: 10000,
      offset: 1,
    }

    try {
      const response = await getAllMovOrderService(token, nameUrl, data)
      const listOne = dataListOne
      const listTwo = response.data.result
      const valueListTwo = listTwo.map(item => item.movimentationOrderNumberOtherSystem)
      const updatedStatusMessages = {}
      const listOrderRepeat = []

      const listOneOrderExist = listOne.map(item => {
        if (valueListTwo.includes(item.movimentationOrderNumberOtherSystem)) {
          listOrderRepeat.push(item.movimentationOrderNumberOtherSystem)
          updatedStatusMessages[item.productId] = 'Ordem já existe'
          return {
            ...item,
            status: 'Ordem já existe',
          }
        } else {
          return {
            ...item,
            status: '',
          }
        }
      })

      setStatusMessage(prevStatusMessages => ({
        ...prevStatusMessages,
        ...updatedStatusMessages,
      }))

      if (listOneOrderExist.length > 0) {
        setStatusTable(true)
      }

      setItensProd(listOneOrderExist)
      setListOrderExist(listOrderRepeat)
      setCompareOrder(valueListTwo)
      setValueFile('')
      setSelectedItems([])

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const getCompareCodeOrder = async () => {
    const data = {
      SubCompanyId: subCompanyId,
      limit: 10000,
      offset: 1,
    }

    try {
      const response = await getAllMovOrderService(token, nameUrl, data)
      const listOne = itemProd
      const listTwo = response.data.result

      const valueListTwo = listTwo.map(item => item.movimentationOrderNumberOtherSystem)
      const updatedStatusMessages = {}
      const listOrderRepeat = []

      const listOneOrderExist = listOne.map(item => {
        if (valueListTwo.includes(item.movimentationOrderNumberOtherSystem)) {
          listOrderRepeat.push(item.movimentationOrderNumberOtherSystem)
          updatedStatusMessages[item.productId] = 'Ordem já existe'
          return {
            ...item,
            status: 'Ordem já existe',
          }
        } else {
          return {
            ...item,
            status: '',
          }
        }
      })

      setStatusMessage(prevStatusMessages => ({
        ...prevStatusMessages,
        ...updatedStatusMessages,
      }))

      if (listOneOrderExist.length > 0) {
        setStatusTable(true)
      }
      setCompareOrder(valueListTwo)

      setSelectedItems([])

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {}, [listOrderExit, selectedItems, singleProd])

  const addToList = single => {
    const {
      id,
      codClient,
      clientName,
      idProductPartner,
      movimentationOrderNumberOtherSystem,
      productName,
      quantity,
    } = single

    if (
      !clientName ||
      !idProductPartner ||
      !movimentationOrderNumberOtherSystem ||
      !productName ||
      !quantity ||
      !codClient
    ) {
      setStatus('Todos os campos são obrigatórios')
      return
    }

    const orderExists = itemProd.some(
      item => item.movimentationOrderNumberOtherSystem === movimentationOrderNumberOtherSystem
    )

    if (!edit && orderExists) {
      setStatus('Número de ordem já existe na lista')
      return
    }

    const repetido = itemProd.find(item => item.id === id)

    if (repetido) {
      const updatedClient = {
        ...repetido,
        clientName,
        idProductPartner,
        movimentationOrderNumberOtherSystem,
        productName,
        quantity,
        codClient,
      }

      const updatedIndex = itemProd.findIndex(item => item.id === id)

      if (updatedIndex !== -1) {
        const updatedItemClient = [...itemProd]
        updatedItemClient[updatedIndex] = updatedClient
        setItensProd(updatedItemClient)
      }

      setStatus('Cliente atualizado')
      resetFields()
      setExpanded(false)
    } else {
      if (!movimentationOrderNumberOtherSystem) {
        setStatus('Código inválido')
        return
      }

      const newClient = {
        id,
        clientName,
        idProductPartner: singleProd.idProductPartner,
        movimentationOrderNumberOtherSystem,
        productName: singleProd.productName,
        quantity,
        codClient,
      }

      setItensProd(curr => [...curr, newClient])
      setStatus('Cliente adicionado')
      resetFields()
      setExpanded(false)
    }
  }

  const resetFields = () => {
    setEdit(false)
    setSingleProd({
      movimentationOrderNumberOtherSystem: '',
      codClient: '',
      clientName: '',
      idProductPartner: '',
      productName: '',
      quantity: '',
    })
  }

  useEffect(() => {}, [itemProd])

  useEffect(() => {}, [numberItem])

  const handleGetEdit = single => {
    setEdit(true)
    setExpanded(true)
    setSingleProd({
      id: single.id,
      movimentationOrderNumberOtherSystem: single.movimentationOrderNumberOtherSystem,
      codClient: single.codClient,
      clientName: single.clientName,
      idProductPartner: single.idProductPartner,
      productName: single.productName,
      quantity: single.quantity,
    })
  }

  const handleActionList = action => {
    if (selectedItems.length === 0) {
      setSeverity('error')
      setTitle('Selecione um item')
      setAlerta(true)
    } else {
      setOpen(true)
      setTitleDialog('Tem certeza que enviar esses itens?')
      setDialogAction(action)
    }
  }

  const handleSaveUnity = async data => {
    setExpanded(false)
    setStatusTable(true)
    setStatusMessage({})
    setSentProdsCount(0)
    setSentProds([])
    setFailedProds([])
    setSuccessfullySentItems([])
    setListOrderExist([])
    setNumberItem(itemProd)

    const batchProds = {
      movimentationOrderNumberOtherSystem: data.movimentationOrderNumberOtherSystem,
      subCompanyCNPJ: subCompanyCpnj,
      typeOrder: 17,
      itensOrder: [
        {
          idProductPartner: data.idProductPartner,
          quantity: data.quantity,
          productName: data.productName,
          movimentationOrderNumberOtherSystem: data.movimentationOrderNumberOtherSystem,
          subCompanyCNPJ: subCompanyCpnj,
          productId: data.productId,
          finishGood: true,
        },
      ],
    }
    const updatedStatusMessages = {}
    console.log(batchProds, 'batchProds')
    batchProds.itensOrder.forEach(prodObj => {
      updatedStatusMessages[prodObj.productId] = 'Carregando'
      setFailedProds(prevFailedProds => [...prevFailedProds, prodObj.productId])
    })
    setStatusMessage(prevStatusMessages => ({
      ...prevStatusMessages,
      ...updatedStatusMessages,
    }))

    await new Promise(resolve => setTimeout(resolve, 1000))

    const batchContainsExistingOrder = batchProds.itensOrder.some(prodObj =>
      compareOrder.includes(prodObj.movimentationOrderNumberOtherSystem)
    )
    if (batchContainsExistingOrder) {
      console.log(batchContainsExistingOrder, ' existe')

      const updatedStatusMessages = {}
      batchProds.itensOrder.forEach(prodObj => {
        updatedStatusMessages[prodObj.productId] = 'Ordem já existe'
        setFailedProds(prevFailedProds => [...prevFailedProds, prodObj.productId])
      })
      setStatusMessage(prevStatusMessages => ({
        ...prevStatusMessages,
        ...updatedStatusMessages,
      }))
    } else {
      try {
        await createOrderProcessFullService(token, nameUrl, batchProds)
        setStatusTable(true)

        const updatedStatusMessages = {}
        batchProds.itensOrder.forEach(prodObj => {
          updatedStatusMessages[prodObj.productId] = 'Registro feito com sucesso'
        })
        setStatusMessage(prevStatusMessages => ({
          ...prevStatusMessages,
          ...updatedStatusMessages,
        }))

        setSentProdsCount(prevSentProdsCount => prevSentProdsCount + batchProds.itensOrder.length)

        await new Promise(resolve => setTimeout(resolve, 1000))
        const newlySuccessfullySentItems = batchProds.itensOrder.map(prod => prod.productId)
        setSuccessfullySentItems(prevSentItems => [...prevSentItems, ...newlySuccessfullySentItems])
        setFilter(prevItems =>
          prevItems.filter(item => !newlySuccessfullySentItems.includes(item.productId))
        )

        setItensProd(prevItemProd =>
          prevItemProd.filter(item => !newlySuccessfullySentItems.includes(item.productId))
        )
      } catch (error) {
        setStatusTable(true)
        batchProds.itensOrder.forEach(prodObj => {
          setStatusMessage(prevStatusMessages => ({
            ...prevStatusMessages,
            [prodObj.productId]: 'Falha ao enviar',
          }))
          setFailedProds(prevFailedProds => [...prevFailedProds, prodObj.productId])
        })
      }
    }
    setProgress(0)
    setStatusInfo(false)
    getCompareCodeOrder()
  }

  const handleSaveClientSelected = async () => {
    setStatusMessage({})
    setExpanded(false)
    setStatusTable(true)
    setStatusInfo(true)
    setSuccessfullySentItems([])
    setSentProdsCount(0)
    setSentProds([])
    setFailedProds([])
    setListOrderExist([])
    setNumberItem(selectedItems)
    const batchSize = 1
    const selectedClients = itemProd.filter(prod => selectedItems.includes(prod.productId))
    const numBatches = Math.ceil(selectedClients.length / batchSize)
    console.log(numBatches)

    for (let batchIndex = 0; batchIndex < numBatches; batchIndex++) {
      setCurrentBatchIndex(batchIndex + 1)
      const batchStart = batchIndex * batchSize
      const batchEnd = Math.min((batchIndex + 1) * batchSize, selectedClients.length)
      const batchProds = selectedClients.slice(batchStart, batchEnd).map(item => ({
        movimentationOrderNumberOtherSystem: item.movimentationOrderNumberOtherSystem,
        subCompanyCNPJ: subCompanyCpnj,
        typeOrder: 17,
        itensOrder: [
          {
            idProductPartner: item.idProductPartner,
            quantity: item.quantity,
            productName: item.productName,
            movimentationOrderNumberOtherSystem: item.movimentationOrderNumberOtherSystem,
            subCompanyCNPJ: subCompanyCpnj,
            productId: item.productId,
            finishGood: true,
          },
        ],
      }))

      await new Promise(resolve => setTimeout(resolve, 1000))
      try {
        const batchContainsExistingOrder = batchProds.some(prodObj =>
          compareOrder.includes(prodObj.movimentationOrderNumberOtherSystem)
        )

        if (batchContainsExistingOrder) {
          const updatedStatusMessages = {}
          batchProds[0].itensOrder.forEach(prodObj => {
            updatedStatusMessages[prodObj.productId] = 'Ordem já existe'
            setFailedProds(prevFailedProds => [...prevFailedProds, prodObj.productId])
          })
          setStatusMessage(prevStatusMessages => ({
            ...prevStatusMessages,
            ...updatedStatusMessages,
          }))
        } else {
          const response = await createOrderProcessFullService(token, nameUrl, batchProds[0])

          setSentProdsCount(prevSentProdsCount => prevSentProdsCount + batchProds.length)

          const updatedStatusMessages = {}
          batchProds[0].itensOrder.forEach(prodObj => {
            updatedStatusMessages[prodObj.productId] = 'Registro feito com sucesso'
          })
          setStatusMessage(prevStatusMessages => ({
            ...prevStatusMessages,
            ...updatedStatusMessages,
          }))
          await new Promise(resolve => setTimeout(resolve, 1000))

          const newlySuccessfullySentItems = batchProds[0].itensOrder.map(prod => prod.productId)
          setSuccessfullySentItems(prevSentItems => [
            ...prevSentItems,
            ...newlySuccessfullySentItems,
          ])
          setFilter(prevItems =>
            prevItems.filter(item => !newlySuccessfullySentItems.includes(item.productId))
          )
        }
        setProgress((batchEnd / selectedItems.length) * 100)
      } catch (error) {
        setStatusTable(true)
        batchProds[0].itensOrder.forEach(prodObj => {
          setStatusMessage(prevStatusMessages => ({
            ...prevStatusMessages,
            [prodObj.productId]: 'Falha ao enviar',
          }))
          setFailedProds(prevFailedProds => [...prevFailedProds, prodObj.productId])
        })
        const failedItemIds = batchProds[0].itensOrder.map(prod => prod.productId)
        setItensProd(prevItemProd =>
          prevItemProd.filter(prodObj => !failedItemIds.includes(prodObj.productId))
        )
        setFilter(prevItemProd =>
          prevItemProd.filter(prodObj => !failedItemIds.includes(prodObj.productId))
        )
      }

      if (batchIndex < numBatches - 1) {
        await new Promise(resolve => setTimeout(resolve, 1000))
      }
    }
    setProgress(0)
    setSelectedItems([])
    setStatusInfo(false)
    getCompareCodeOrder()
  }

  const handleSaveProd = async () => {
    setExpanded(false)
    setStatusTable(true)
    setStatusInfo(true)
    setStatusMessage({})
    setSentProdsCount(0)
    setSentProds([])
    setFailedProds([])
    setSuccessfullySentItems([])
    setListOrderExist([])
    setNumberItem(itemProd)

    const totalProds = itemProd.length
    let successfullySentCount = 0
    let existingOrderCount = 0
    let failedCount = 0

    for (const item of itemProd) {
      const batchProds = {
        movimentationOrderNumberOtherSystem: item.movimentationOrderNumberOtherSystem,
        subCompanyCNPJ: subCompanyCpnj,
        typeOrder: 17,
        itensOrder: [
          {
            idProductPartner: item.idProductPartner,
            quantity: item.quantity,
            productName: item.productName,
            movimentationOrderNumberOtherSystem: item.movimentationOrderNumberOtherSystem,
            subCompanyCNPJ: subCompanyCpnj,
            productId: item.productId,
            finishGood: true,
          },
        ],
      }

      const batchContainsExistingOrder = batchProds.itensOrder.some(prodObj =>
        compareOrder.includes(prodObj.movimentationOrderNumberOtherSystem)
      )

      if (batchContainsExistingOrder) {
        const updatedStatusMessages = {}
        existingOrderCount++

        batchProds.itensOrder.forEach(prodObj => {
          updatedStatusMessages[prodObj.productId] = 'Ordem já existe'
          setFailedProds(prevFailedProds => [...prevFailedProds, prodObj.productId])
        })
        setStatusMessage(prevStatusMessages => ({
          ...prevStatusMessages,
          ...updatedStatusMessages,
        }))
      } else {
        try {
          const response = await createOrderProcessFullService(token, nameUrl, batchProds)
          setStatusTable(true)

          const updatedStatusMessages = {}
          batchProds.itensOrder.forEach(prodObj => {
            updatedStatusMessages[prodObj.productId] = 'Registro feito com sucesso'
          })
          setStatusMessage(prevStatusMessages => ({
            ...prevStatusMessages,
            ...updatedStatusMessages,
          }))

          setSentProdsCount(prevSentProdsCount => prevSentProdsCount + batchProds.itensOrder.length)

          await new Promise(resolve => setTimeout(resolve, 200))
          const newlySuccessfullySentItems = batchProds.itensOrder.map(prod => prod.productId)
          setSuccessfullySentItems(prevSentItems => [
            ...prevSentItems,
            ...newlySuccessfullySentItems,
          ])
          setFilter(prevItems =>
            prevItems.filter(item => !newlySuccessfullySentItems.includes(item.productId))
          )

          setItensProd(prevItemProd =>
            prevItemProd.filter(item => !newlySuccessfullySentItems.includes(item.productId))
          )
          successfullySentCount += batchProds.itensOrder.length
        } catch (error) {
          setStatusTable(true)
          batchProds.itensOrder.forEach(prodObj => {
            setStatusMessage(prevStatusMessages => ({
              ...prevStatusMessages,
              [prodObj.productId]: 'Falha ao enviar',
            }))
            setFailedProds(prevFailedProds => [...prevFailedProds, prodObj.productId])
          })
          failedCount++
        }
      }
      await new Promise(resolve => setTimeout(resolve, 200))

      const completedCount = successfullySentCount + existingOrderCount + failedCount
      const progress2 = (completedCount / totalProds) * 100
      setProgress(progress2)
    }

    setProgress(0)
    setStatusInfo(false)
    getCompareCodeOrder()
  }

  const deleteDialog = (clientId, del) => {
    setDeleteId(clientId)
    setOpen(true)
    setTitleDialog('Tem certeza que deseja excluir este item?')
    setDialogAction(del)
  }

  const handleDeleteItem = () => {
    const updatedItems = filter.filter(item => item.productId !== deleteId)
    setSeverity('success')
    setTitle('Item apagado com sucesso!')
    setAlerta(true)
    setItensProd(updatedItems)
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
    setAlerta(false)
  }

  const filterRow = () => {
    const filtered = itemProd.filter(
      row =>
        row.movimentationOrderNumberOtherSystem.toLowerCase().includes(search.toLowerCase()) ||
        row.idProductPartner.toLowerCase().includes(search.toLowerCase()) ||
        row.codClient.toLowerCase().includes(search.toLowerCase()) ||
        row.clientName.toLowerCase().includes(search.toLowerCase()) ||
        row.productName.toLowerCase().includes(search.toLowerCase())
    )
    setFilter(filtered)
  }

  useEffect(() => {
    filterRow()
  }, [search, itemProd])

  useEffect(() => {
    const totalProds = itemProd.length
    const batchSize = 1
    const calculatedNumBatches = Math.ceil(totalProds / batchSize)
    setTotalProdsCount(totalProds)
    setNumBatches(calculatedNumBatches)
  }, [itemProd])

  useEffect(() => {
    const totalProds = selectedItems.length
    const batchSize = 1
    const calculatedNumBatches = Math.ceil(totalProds / batchSize)
    setTotaProdsSelectedCount(totalProds)
    setNumBatches(calculatedNumBatches)
  }, [selectedItems])

  return (
    <>
      <Box sx={{ mt: 7.5 }} onClick={handleWindowClick}>
        {statusInfo && (
          <Backdrop
            sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
            open={statusInfo}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              position="relative"
            >
              <CircularProgress value={progress} sx={{ color: colorTableDefault }} size={60} />
              <Box
                sx={{
                  position: 'absolute',
                  top: '26%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <Typography>{Math.round(progress)}%</Typography>
              </Box>
              <Box textAlign="center" p={2}>
                {selectedItems.length > 0 ? (
                  <Typography>
                    {totaProdsSelectedCount.toLocaleString()} de {Math.floor(sentProdsCount)} Ordens
                    Enviadas...
                  </Typography>
                ) : (
                  <Typography>
                    {numberItem.length.toLocaleString()} de {sentProdsCount.toLocaleString()} Ordens
                    Enviadas...
                  </Typography>
                )}
              </Box>
            </Box>
          </Backdrop>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>{titleDialog}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              onClick={() => {
                handleClose()
                if (dialogAction === 'delete') {
                  handleDeleteItem()
                } else if (dialogAction === 'save') {
                  handleSaveClientSelected()
                }
                setDialogAction(null)
              }}
            >
              Sim
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>

        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Carga de Produção</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Accordion expanded={expanded} sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography variant="body1">
              {edit ? 'Editar Produção' : 'Cadastrar Produção'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                width: 1,
                mt: 2,
                display: 'flex',
                gap: 2,
                border: '1px solid black',
                borderRadius: 2,
                p: 2,
                flexDirection: 'column',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      value={singleProd.movimentationOrderNumberOtherSystem}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Ordem (Outro sistema)"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          movimentationOrderNumberOtherSystem: e.target.value,
                        }))
                      }
                      error={itemProd.some(
                        item =>
                          item.movimentationOrderNumberOtherSystem ===
                          singleProd.movimentationOrderNumberOtherSystem
                      )}
                      helperText={
                        itemProd.some(
                          item =>
                            item.movimentationOrderNumberOtherSystem ===
                            singleProd.movimentationOrderNumberOtherSystem
                        )
                          ? 'Número de ordem já existe na lista'
                          : ''
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: 1 }}>
                    <Autocomplete
                      disablePortal
                      id="lista-produtos"
                      options={clients}
                      value={edit ? singleProd.codClient : ''}
                      loading={searchingClient}
                      loadingText="Procurando..."
                      noOptionsText="Nenhum cliente"
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={`product-${option.clientId}`}>
                            {option.label}
                          </li>
                        )
                      }}
                      renderInput={params => <TextField {...params} label="Procurar cliente" />}
                      onInputChange={(e, v) => {
                        setSearchClient(v)
                        setEditedValue(v)
                      }}
                      onChange={(e, v) => {
                        if (v && v.value) {
                          changeClient(v.value)
                          setEditedValue(v.label)
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      disabled
                      sx={{ width: '100%' }}
                      value={singleProd.clientName}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Nome do cliente"
                      onChange={e =>
                        setSingleProd(curr => ({
                          ...curr,
                          clientName: e.target.value,
                        }))
                      }
                      error={!!errors.clientName}
                      helperText={errors.clientName}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: 0.5 }}>
                    <Autocomplete
                      disablePortal
                      id="lista-produtos"
                      options={produtos}
                      value={edit ? singleProd.idProductPartner : ''}
                      loading={searchingProd}
                      loadingText="Procurando..."
                      noOptionsText="Nenhum produto"
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={`product-${option.productId}`}>
                            {option.label}
                          </li>
                        )
                      }}
                      renderInput={params => <TextField {...params} label="Procurar produto" />}
                      onInputChange={(e, v) => {
                        setSearchProd(v)
                        setEditedValue(v)
                      }}
                      onChange={(e, v) => {
                        if (v && v.value) {
                          changeProd(v.value)
                          setEditedValue(v.label)
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      disabled
                      sx={{ width: '100%' }}
                      value={singleProd.productName}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Nome do produto"
                      inputProps={{ maxLength: 18 }}
                      onChange={e => {
                        setSingleProd(curr => ({
                          ...curr,
                          productName: e.target.value,
                        }))
                      }}
                      error={!!errors.productName}
                      helperText={errors.productName}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      disabled={edit}
                      sx={{ width: '100%' }}
                      value={singleProd.quantity}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Quantidade Total"
                      inputProps={{ maxLength: 9 }}
                      onChange={e => {
                        setSingleProd(curr => ({
                          ...curr,
                          quantity: e.target.value,
                        }))
                      }}
                      error={!!errors.quantity}
                      helperText={errors.quantity}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      value={status}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      label="Status"
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                >
                  <Button variant="outlined" onClick={() => addToList(singleProd)}>
                    {edit ? 'Atualizar Cliente' : 'Adicionar à lista'}
                  </Button>
                  {edit && (
                    <Button
                      variant="contained"
                      sx={{ ml: 1, display: 'flex', alignItems: 'center' }}
                      onClick={() => {
                        resetFields()
                      }}
                    >
                      Adicionar novo cliente <Add sx={{ ml: 1 }} />
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
        <FormControl sx={{ width: '100%', mt: 2 }}>
          <InputLabel id="demo-simple-select-label">Filial</InputLabel>
          <Select
            value={subCompanyId}
            sx={{ width: '100%' }}
            label="Escolher o Usuário"
            onChange={fetchData}
          >
            {subCompany.map((item, index) => {
              return (
                <MenuItem value={item.subCompanyId} key={index}>
                  {item.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <Box
          sx={{ justifyContent: 'space-between', width: 1, minWidth: 1 }}
          display={{ xs: 'column', sm: 'column', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box
            sx={{
              alignItems: 'center',
              textAlign: 'center',
              mt: 2,
              border: '1px solid black',
              width: { xs: 1, md: 0.34 },
              minWidth: { xs: 1, md: 0.3 },
              p: 2,
            }}
          >
            <a
              className="dropzone"
              target="_blank"
              rel="noreferrer"
              href="https://file.bihands.com/template/UploadProduction.xlsx"
              download
            >
              <FileDownloadIcon sx={{ fontSize: 100 }} />
            </a>
            <InputLabel>Faça o download do exemplo de carga</InputLabel>
          </Box>
          <Box
            sx={{
              mt: 2,
              border: { md: '1px solid black' },
              p: 2,
            }}
            width={{ xs: '100%', sm: '100%', md: '65%', lg: '65%', xl: '65%' }}
          >
            <InputLabel>Insira aqui o documento para enviar</InputLabel>
            <TextField
              disabled={!subCompanyId}
              sx={{ width: '100%', mt: 1 }}
              id="standard-basic"
              variant="standard"
              value={valueFile}
              onChange={handleSelectFile}
              ref={ref}
              type="file"
              accept=".xls, .xlsx, .ods"
            />
            <Box
              sx={{
                mt: 4,
                alignItems: 'center',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>Apenas arquivos .XLS ou .XLSX</Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    sx={{ width: '100%', mb: 1 }}
                    id="standard-basic"
                    variant="outlined"
                    label="Procurar"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    type="text"
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-label">Filtrar Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedStatus}
                      onChange={e => setSelectedStatus(e.target.value)}
                    >
                      <MenuItem selected value="">
                        Todos
                      </MenuItem>
                      <MenuItem value="Ordem já existe">Ordem já existe</MenuItem>
                      <MenuItem value="Falha ao enviar">Falha ao enviar</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Box display="flex" justifyContent="space-between" alignItems="end">
                <Box>
                  <Typography variant="body" sx={{ marginBottom: 2, mr: 0 }}>
                    {filter.length <= 1
                      ? `${filter.length} Resultado`
                      : `${filter.length} Resultados`}
                  </Typography>
                  {selectedItems.length > 0 && (
                    <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                      {selectedItems.length <= 1
                        ? `${selectedItems.length} Item Selecionado`
                        : `${selectedItems.length} Itens Selecionados`}
                    </Typography>
                  )}
                  {statusTable && (
                    <>
                      <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                        {listOrderExit.length > 0 && `${listOrderExit.length} ordens existentes`}
                      </Typography>
                    </>
                  )}
                  {statusTable && (
                    <>
                      <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                        {sentProdsCount <= 1
                          ? `${sentProdsCount} Itens Enviado`
                          : `${sentProdsCount} Itens Enviados`}
                      </Typography>
                      <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                        {failedProds.length > 1
                          ? `${failedProds.length} Itens não enviados`
                          : `${failedProds.length} Iten não enviados`}
                      </Typography>
                    </>
                  )}
                </Box>
                {selectedItems.length > 0 ? (
                  <Button
                    variant="contained"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: ' space-between',
                      fontSize: '.9em',
                    }}
                    onClick={() => handleActionList('save')}
                  >
                    Enviar Selecionados
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: ' space-between',
                      fontSize: '.9em',
                    }}
                    onClick={() => handleSaveProd()}
                  >
                    Enviar Lista
                  </Button>
                )}
              </Box>
            </Box>

            <Table sx={{ minWidth: 700, mt: 1 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <Tooltip title="Selecionar Todos" arrow>
                        <span>
                          <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAll}
                            color="primary"
                          />
                        </span>
                      </Tooltip>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center">Ordem (Outro sistema)</StyledTableCell>
                  <StyledTableCell align="center">Codigo do produto</StyledTableCell>
                  <StyledTableCell align="center">Quantidade total</StyledTableCell>
                  <StyledTableCell align="center">Nome do produto</StyledTableCell>
                  <StyledTableCell align="center">Codigo do Cliente</StyledTableCell>
                  <StyledTableCell align="center">Nome do Cliente</StyledTableCell>
                  {statusTable && <StyledTableCell align="center">Status</StyledTableCell>}
                  <StyledTableCell align="center" width="10%">
                    Ação
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filter
                  .filter(
                    row => selectedStatus === '' || statusMessage[row.productId] === selectedStatus
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell align="center">
                        <Checkbox
                          checked={selectedItems.includes(row.productId)}
                          onChange={() => handleItemSelect(row.productId)}
                        />
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        onClick={event => {
                          event.stopPropagation()
                        }}
                      >
                        <Box
                          sx={{ cursor: 'pointer' }}
                          onMouseOver={() =>
                            setShowEditButtonOuterSystem(prevState => ({
                              ...prevState,
                              [index]: true,
                            }))
                          }
                          onMouseLeave={() =>
                            setShowEditButtonOuterSystem(prevState => ({
                              ...prevState,
                              [index]: false,
                            }))
                          }
                        >
                          {editingIndexOuterSystem === index ? (
                            <>
                              <Paper sx={{ display: 'flex', alignItems: ' center' }}>
                                <InputBase
                                  value={editedValue}
                                  onChange={handleInputChange}
                                  sx={{ p: 1 }}
                                />
                                <Tooltip title="Salvar" arrow>
                                  <IconButton
                                    onClick={() => handleSaveCodeOuterSystem(index, row)}
                                    sx={{ ml: 1 }}
                                  >
                                    <CheckCircleRounded />
                                  </IconButton>
                                </Tooltip>
                              </Paper>
                            </>
                          ) : (
                            <>
                              {row.movimentationOrderNumberOtherSystem}
                              {showEditButtonOuterSystem[index] && (
                                <Tooltip title="Editar" arrow>
                                  <IconButton
                                    onClick={() =>
                                      handleEditOuterSystem(
                                        index,
                                        row.movimentationOrderNumberOtherSystem
                                      )
                                    }
                                  >
                                    <Edit />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </Box>
                      </StyledTableCell>

                      <StyledTableCell align="center">{row.idProductPartner}</StyledTableCell>

                      <StyledTableCell align="center">{row.quantity}</StyledTableCell>

                      <StyledTableCell align="center">{row.productName}</StyledTableCell>
                      <StyledTableCell align="center">{row.codClient}</StyledTableCell>

                      <StyledTableCell align="center">{row.clientName}</StyledTableCell>

                      {selectedStatus === '' || statusMessage[row.productId] === selectedStatus ? (
                        <StyledTableCell align="center">
                          {(() => {
                            const status = statusMessage[row.productId]

                            if (status === 'Ordem já existe') {
                              return <Chip color="error" variant="filled" label={status || ''} />
                            } else if (status === 'Falha ao enviar') {
                              return <Chip color="error" variant="filled" label={status || ''} />
                            } else if (status === 'Registro feito com sucesso') {
                              return <Chip color="success" variant="filled" label={status || ''} />
                            } else if (status === 'Carregando') {
                              return (
                                <CircularProgress
                                  sx={{ marginRight: '5px', color: 'red' }}
                                  size={20}
                                />
                              )
                            } else {
                              return null
                            }
                          })()}
                        </StyledTableCell>
                      ) : null}
                      <StyledTableCell align="center">
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <Tooltip title="Reenviar" arrow>
                            <IconButton onClick={() => handleSaveUnity(row)}>
                              <Replay sx={{ cursor: 'pointer' }} />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Apagar" arrow>
                            <IconButton onClick={() => deleteDialog(row.codClient, 'delete')}>
                              <DeleteForever sx={{ cursor: 'pointer' }} />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Editar" arrow>
                            <IconButton onClick={() => handleGetEdit(row)}>
                              <Edit sx={{ cursor: 'pointer' }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filter.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default UpdateProduction
