import axios from 'axios'

//criação
export const createProcess = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Process/Create`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//consulte
export const getProcess = async (token, nameUrl, data) => {
  let orderToString
  if (data.order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/Process/GetAll?SubCompanyId=${data.subCompanyId}&limit=${data.limit}&offset=${data.offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

//consulta id
export const getProcessId = async (token, nameUrl, data) => {
  const response = await axios.get(`/${nameUrl}/api/Process/Get/${data}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//editar
export const editProcess = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Process/Edit`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//deletar
export const deleteProcess = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/Process/Delete`,
    { processId: data },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//criação sequence
export const createProcessSequence = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/ProcessSequence/Create`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
  return response
}

//consulta sequence
export const getProcessSequence = async (token, nameUrl, data) => {
  let orderToString
  if (data.order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/ProcessSequence/GetAll?SubCompanyId=${data.subCompanyId}&limit=${data.limit}&offset=${data.offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getProcessSequenceAllList = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/ProcessSequence/GetAlls/${data.subCompanyId}/${data.processId}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

//consulta sequence id
export const getProcessSequenceId = async (token, nameUrl, data) => {
  const response = await axios.get(`/${nameUrl}/api/ProcessSequence/Get/${data}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//editar
export const editProcessSequence = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/ProcessSequence/Edit`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//deletar sequence
export const deleteProcessSequence = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/ProcessSequence/Delete`,
    { processSequenceId: data },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}
