import { useCallback, useContext, useEffect, useState } from 'react'
import {
  deleteRoleCategoryService,
  deleteRoleService,
  getRolesCategoryService,
} from '../../../services/admServices'
import { Context } from '../../../context/AuthContext'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  Paper,
  Backdrop,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  Collapse,
  Tooltip,
  TextField,
  InputAdornment,
  Divider,
  ListItemText,
  Grid,
} from '@mui/material'
import { AiFillPlusCircle } from 'react-icons/ai'

//icones react-icons
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import RulesCategoryCreate from '../../Create/RulesCategoryCreate'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import RulesCategoryEdit from '../../Edit/RulesCategoryEdit'
import PermissionComponent from '../../../permissions'
import { Add, Clear, Search } from '@mui/icons-material'
import { ComponentTable } from '../../ComponentList/ComponentTable'
import { ComponentTableAccordion } from '../../ComponentList/ComponentTableAccordion'

const RulesCategory = () => {
  const token = localStorage.getItem('token')
  const [role, setRole] = useState([])
  const [pagination, setPagination] = useState({})
  const [roleLimit, setRoleLimit] = useState(1000)
  const [roleOffset, setRoleOffset] = useState(0)
  const [roleOrder, setRoleOrder] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [filteredCategoryRoles, setFilteredCategoryRoles] = useState([])

  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const [openRule, setOpenRule] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [idEditing, setIdEditing] = useState('')
  const [roleArray, setRolesArray] = useState('')

  const { userRoles, nameUrl } = useContext(Context)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getRoles = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getRolesCategoryService(
        token,
        nameUrl,
        roleLimit,
        roleOffset,
        roleOrder
      )
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setRole(response.data.result)
        setPagination(response.data.page)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [roleOrder])

  useEffect(() => {
    getRoles()
  }, [roleOrder])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
    setOpenRule(false)
  }

  const handleEdit = (id, name, description, permission) => {
    setIdEditing(id)
    setRolesArray(permission)
    setName(name)
    setExpanded(true)
    setEditing(true)
    window.scrollTo(0, 0)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const deleteAlertRole = data => {
    setOpenRule(true)
    setIdDelete(data)
  }

  const handleRoleCategoryDelete = async () => {
    setLoading(true)
    setOpen(false)
    try {
      await deleteRoleCategoryService(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Categoria deletada com sucesso!')

      setAlerta(true)
      setLoading(false)
      getRoles()
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  const handleRoleDelete = async () => {
    setLoading(true)
    setOpenRule(false)
    try {
      await deleteRoleService(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Regra deletada com sucesso!')

      setAlerta(true)
      setLoading(false)
      getRoles()
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  function Row(props) {
    const { row } = props
    const [open, setOpen] = useState(false)

    return (
      <>
        <StyledTableRow key={row.roleCategoryId} sx={{ '& > *': { borderBottom: 'unset' } }}>
          <StyledTableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>

          <StyledTableCell>
            <ListItemText primary={row.name} secondary={row.description} />
          </StyledTableCell>
          <StyledTableCell>
            <Tooltip title="Excluir" arrow>
              <IconButton
                onClick={() => deleteAlert(row.roleCategoryId)}
                sx={{ cursor: 'pointer' }}
              >
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell>
            <Tooltip title="Editar" arrow>
              <IconButton
                onClick={() =>
                  handleEdit(row.roleCategoryId, row.name, row.description, row.permissions)
                }
                sx={{ cursor: 'pointer' }}
              >
                <ModeEditOutlineIcon />
              </IconButton>
            </Tooltip>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Lista de regras
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell sx={{ width: '20px' }}></StyledTableCell>
                      <StyledTableCell sx={{ width: 300 }}>Nome da regra</StyledTableCell>
                      <StyledTableCell>Descrição</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {row.permissions.map((permission, index) => (
                      <StyledTableRow key={permission.roleId}>
                        <PermissionComponent role="Delete_RulesCategory">
                          <StyledTableCell>
                            <Tooltip title="Excluir" arrow>
                              <IconButton
                                onClick={() => deleteAlertRole(permission.roleId)}
                                sx={{ cursor: 'pointer' }}
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        </PermissionComponent>

                        <StyledTableCell>{permission.name}</StyledTableCell>
                        <StyledTableCell>{permission.description}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      </>
    )
  }

  useEffect(() => {
    filterRow()
  }, [searchValue, role])

  const filterRow = () => {
    const filtered = role.filter(item =>
      item.name.toLowerCase().includes(searchValue.toLowerCase())
    )
    setFilteredCategoryRoles(filtered)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Typography variant="h5">Grupo de Regras</Typography>

        {/* Criar  */}

        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>
              {editing ? 'Editando Grupo de Regras' : 'Cadastrar Grupo de Regras'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="">
                <RulesCategoryEdit
                  setEditing={setEditing}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  roleId={roleArray}
                  roleCategoryId={idEditing}
                  roleName={name}
                  handleFetchData={getRoles}
                  setExpanded={setExpanded}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="">
                <RulesCategoryCreate
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={getRoles}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>

        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" my={4}>
              <TextField
                placeholder="Pesquisar"
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    </>
                  ),
                  endAdornment: (
                    <>
                      {searchValue && (
                        <IconButton aria-label="Limpar pesquisa" onClick={() => setSearchValue('')}>
                          <Clear />
                        </IconButton>
                      )}
                    </>
                  ),
                }}
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography my={2}>
              {searchValue !== '' ? (
                <>
                  {filteredCategoryRoles.length > 0 && filteredCategoryRoles[0].permissions
                    ? `${filteredCategoryRoles[0].permissions.length} Resultados`
                    : 'Nenhum Resultado'}
                </>
              ) : (
                <>
                  {filteredCategoryRoles.length > 0
                    ? `${filteredCategoryRoles.length} Resultados`
                    : 'Nenhum Resultado'}
                </>
              )}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                ml: 1,
              }}
            >
              <PermissionComponent role="Create_RulesCategory">
                <Button
                  className="buttonDocs"
                  onClick={() => {
                    setEditing(false)
                    setExpanded(true)
                  }}
                  variant="contained"
                  endIcon={<AiFillPlusCircle />}
                >
                  Criar
                </Button>
              </PermissionComponent>
            </Box>
          </Box>
        </Box>

        {/* Deletar */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleRoleCategoryDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openRule}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleRoleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>

        {/* <Box>
          <ComponentTableAccordion
            placeholderSearch="Pesquisar"
            titleBtn="Adicionar"
            grid1={12}
            numberPage={10}
            typeUser={1}
            pagination={true}
            heightTbodyMessage="200px"
            header={false}
            additionalStyles={{ borderRadius: '0px', border: 'none' }}
            grid2={2}
            iconBtn={<Add />}
            //loading={isLoading}
            columns={[
              {
                heading: 'Nome',
                element: row => <span>{row.name}</span>,
                columnsFilds: {
                  heading: 'Nome da Permissão',
                  element: row => (
                    <span>
                      {row.permissions.map(permission => (
                        <div key={permission.name}>{permission.name}</div>
                      ))}
                    </span>
                  ),
                },
              },
            ]}
            data={filteredCategoryRoles}
          />
        </Box> */}
        {filteredCategoryRoles.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="left" sx={{ width: '20px' }}></StyledTableCell>
                  <StyledTableCell align="left" sx={{ width: 300 }}>
                    Nome
                  </StyledTableCell>
                  <StyledTableCell align="left" sx={{ width: '20px' }}></StyledTableCell>
                  <StyledTableCell align="left" sx={{ width: '20px' }}></StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {filteredCategoryRoles
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <Row row={row} key={row.roleCategoryId} />
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredCategoryRoles.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default RulesCategory
