import axios from 'axios'

export const getAlocationStockService = async (token, nameUrl, subcompany, company) => {
  const response = await axios.get(`/${nameUrl}/api/AlocationStock/Get/${subcompany}/${company}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
  return response
}

export const getAlocationStockGetAll = async (token, nameUrl, item) => {
  let orderToString
  if (item.order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }
  const response = await axios.get(
    `/${nameUrl}/api/AlocationStock/GetAll?SubCompanyId=${item.subCompanyId}&limit=${item.limit}&offset=${item.offset}&Order=${orderToString}`,
    { headers: { Authorization: `Bearer ${token}` } }
  )
  return response
}

export const getAlocationStocGetList = async (token, nameUrl, subCompanyId, companyId) => {
  const response = await axios.get(
    `/${nameUrl}/api/AlocationStock/GetlistFree/${subCompanyId}/${companyId}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
  return response
}

export const createAlocationStockService = async (token, nameUrl, alocation) => {
  const response = await axios.post(`/${nameUrl}/api/AlocationStock/Create`, alocation, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response
}

export const deleteAlocationStockService = async (token, nameUrl, alocationId) => {
  const response = await axios.post(
    `/${nameUrl}/api/AlocationStock/Delete?Id=${alocationId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return response
}

export const editAlocationStockService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/AlocationStock/Edit`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response
}

export const getLocationReport = async (token, nameUrl, item) => {
  let orderToString
  if (item.order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }
  const response = await axios.get(
    `/${nameUrl}/api/Report/GetLocatioReport?CompanyId=${item.companyId}&AlocationStockId=${item.alocationStockId}&limit=${item.limit}&offset=${item.offset}&Order=${orderToString}`,
    { headers: { Authorization: `Bearer ${token}` } }
  )
  return response
}
