import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Context } from '../../../context/AuthContext'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import HelpIcon from '@mui/icons-material/Help'
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  Paper,
  Backdrop,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  TextField,
  InputLabel,
} from '@mui/material'

//icones react-icons
import { AiFillPlusCircle } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'
import {
  DeliveryAddImage,
  DeliveryRoute,
  getOrdersDriver,
  getStartRoute,
  NotDeliveryRoute,
} from '../../../services/driverServices'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import EmailIcon from '@mui/icons-material/Email'
import Canva from './Canva'

const ListDriver = () => {
  const { id } = useParams()
  const [permissionCarWeightCREATE, setPermissionCarWeightCREATE] = useState(false)
  const navigate = useNavigate()
  const { nameUrl } = useContext(Context)

  const token = localStorage.getItem('token')
  const [intCredencial, setIntCredencial] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [listMovimentation, setListMovimentation] = useState([])
  const [recipientName, setRecipientName] = useState('')
  const [cpf, setCpf] = useState()
  const [idMovimentation, setIdMovimentation] = useState('')

  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [viewElearning, setViewElearning] = useState(false)
  const [photoPrincipal, setPhotoPrincipal] = useState()
  const [packPhotos, setPackPhotos] = useState([])
  const [assing, setAssing] = useState()
  const [rejected, setRejected] = useState(false)
  const [description, setDescription] = useState()
  const [extra, setExtra] = useState(false)
  const [extraDescription, setExtraDescription] = useState()
  const [photoExtra, setPhotoExtra] = useState()
  const formData = new FormData()

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      wordBreak: 'break-word',
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getPermissions = useCallback(async () => {}, [])

  const handleGetList = async () => {
    setLoading(true)
    try {
      const response = await getOrdersDriver(token, nameUrl, id)
      console.log('listDri')
      if (response.data.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
        setIntCredencial([])
      } else {
        setListMovimentation(response.data.listMovimentation)
      }
      setLoading(false)
    } catch (err) {
      setError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getPermissions()
    handleGetList()
  }, [])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleStartRoute = async id => {
    setLoading(true)
    try {
      await getStartRoute(token, nameUrl, id)
      setTitle('Rota iniciada com sucesso!')
      setOpen(true)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  const handleDelivery = async e => {
    e.preventDefault()
    setLoading(true)
    if (recipientName && cpf) {
      if (extra) {
        const fileExtra = await sendExtra()
        if (!fileExtra) {
          setTitle('Erro na finalização, tente novamente.')
          setOpen(true)
          setLoading(false)
          return
        }
      }
      if (!rejected) {
        const formData = new FormData()
        formData.append('PhotoPrincipal', photoPrincipal)
        formData.append('Assing', assing)
        formData.append('RecipientName', recipientName)
        formData.append('DocumentCPFCNPJ', parseInt(cpf.replace(/[.-]/g, '')))
        formData.append('MovimentationOrderId', idMovimentation)

        try {
          await DeliveryRoute(token, nameUrl, formData)
          setTitle('Entrega finalizada com sucesso!')
          setOpen(true)
          setRecipientName('')
          setCpf()
          setPhotoPrincipal()
          setAssing()
          setExpanded(false)
          handleGetList()
          setLoading(false)
        } catch (e) {
          console.log(e)
          setTitle('Erro na finalização, tente novamente.')
          setOpen(true)
          setLoading(false)
        }
      } else {
        const formData = new FormData()
        formData.append('RecipientName', recipientName)
        formData.append('DocumentCPFCNPJ', parseInt(cpf.replace(/[.-]/g, '')))
        formData.append('MovimentationOrderId', idMovimentation)
        formData.append('Description', description)
        packPhotos.forEach((file, index) => {
          formData.append(`PhotoPrincipal`, file)
        })

        try {
          await NotDeliveryRoute(token, nameUrl, formData)
          setTitle('Entrega finalizada com sucesso!')
          setOpen(true)
          setRecipientName('')
          setCpf()
          setPhotoPrincipal()
          setPackPhotos([])
          setDescription()
          setAssing()
          setExpanded(false)
          handleGetList()
          setLoading(false)
        } catch (e) {
          console.log(e)
          setLoading(false)
        }
      }
    } else {
      setTitle('Preencha o CPF e o nome do recebedor!')
      setOpen(true)
      setLoading(false)
    }
  }

  const sendExtra = async () => {
    const formData = new FormData()
    try {
      formData.append('MovimentationOrderId', idMovimentation)
      formData.append('Description', extraDescription)
      formData.append('Photo', photoExtra)
      await DeliveryAddImage(token, nameUrl, formData)
      setExtra(false)
      setPhotoExtra()
      setExtraDescription()
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  }

  const handleInitDelivery = id => {
    setIdMovimentation(id)
    setExpanded(true)
  }

  const handleImageChange = e => {
    const newImages = Array.from(e.target.files)
    setPackPhotos([...packPhotos, ...newImages])
  }

  const handleInputCpf = event => {
    const regex = /^[0-9\b]+$/ // permite apenas números
    const input = event.target.value

    if (regex.test(input)) {
      setCpf(input)
    }
  }

  const returnStatus = st => {
    if (st === 0) return 'Rascunho'
    else if (st === 1) return 'Em Entrega'
    else if (st === 2) return 'Entregue'
    else if (st === 3) return 'Não Recebido'
    else if (st === 4) return 'Aguardando Entrega'
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Button
          onClick={() => navigate('/Driver')}
          color="error"
          variant="outlined"
          sx={{ width: 70, fontSize: '.8em' }}
        >
          Voltar
        </Button>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Typography variant="h5">Lista de Entrega</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>

        <ViewElearning pageName={'ListDriver'} open={viewElearning} setOpen={setViewElearning} />

        {expanded && (
          <Accordion expanded={expanded}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => setExpanded(!expanded)}
            >
              <Typography>Finalizar Entrega</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box component="form" onSubmit={handleDelivery} sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                  <Typography
                    onClick={() => setExtra(!extra)}
                    sx={{
                      borderBottom: extra ? '1px solid blue' : 'none',
                      backgroundColor: '#edf7fe',
                      padding: 1,
                      cursor: 'pointer',
                      borderRadius: 2,
                    }}
                  >
                    Informações extras
                  </Typography>
                  <Typography
                    onClick={() => setRejected(!rejected)}
                    sx={{
                      borderBottom: rejected ? '1px solid blue' : 'none',
                      backgroundColor: '#edf7fe',
                      padding: 1,
                      cursor: 'pointer',
                      borderRadius: 2,
                    }}
                  >
                    {rejected ? 'Entrega Completa' : 'Entrega Rejeitada'}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                  flexDirection={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
                >
                  <Box sx={{ mt: 2 }} width={{ xs: '100%', sm: '100%', md: '100%', lg: '45%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      id="outlined-basic"
                      label="Recebedor"
                      variant="outlined"
                      type="text"
                      value={recipientName}
                      onChange={e => setRecipientName(e.target.value)}
                    />
                  </Box>
                  <Box sx={{ mt: 2 }} width={{ xs: '100%', sm: '100%', md: '100%', lg: '45%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      id="outlined-basic"
                      label="CPF Recebedor"
                      variant="outlined"
                      type="text"
                      value={cpf}
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      onChange={handleInputCpf}
                      // helperText="Apenas números"
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                  flexDirection={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
                >
                  <Box sx={{ mt: 2 }} width={{ xs: '100%', sm: '100%', md: '100%', lg: '45%' }}>
                    {!rejected ? (
                      <>
                        <InputLabel>Imagem do recebimento</InputLabel>
                        <TextField
                          sx={{ width: '100%' }}
                          id="outlined-basic"
                          // label="Imagem do recebimento"
                          variant="outlined"
                          type="file"
                          accept="image/*"
                          // value={photoPrincipal}
                          onChange={e => setPhotoPrincipal(e.target.files[0])}
                        />
                      </>
                    ) : (
                      <>
                        <InputLabel>Imagens do recebimento</InputLabel>
                        <TextField
                          sx={{ width: '100%' }}
                          id="outlined-basic"
                          // label="Imagem do recebimento"
                          variant="outlined"
                          type="file"
                          accept="image/*"
                          // value={photoPrincipal}
                          onChange={handleImageChange}
                        />
                      </>
                    )}
                  </Box>
                  <Box sx={{ mt: 2 }} width={{ xs: '100%', sm: '100%', md: '100%', lg: '45%' }}>
                    {!rejected ? (
                      // <>
                      //   <InputLabel>Assinatura do Recebedor</InputLabel>
                      //   <TextField
                      //     sx={{ width: '100%' }}
                      //     id="outlined-basic"
                      //     // label="Assinatura Recebedor"
                      //     variant="outlined"
                      //     type="file"
                      //     accept="image/*"
                      //     // value={assing}
                      //     onChange={e => setAssing(e.target.files[0])}
                      //   />
                      // </>
                      <>
                        <InputLabel>Assinatura do Recebedor</InputLabel>
                        <Canva assing={assing} setAssing={setAssing} />
                      </>
                    ) : (
                      <>
                        <TextField
                          sx={{ width: '100%', mt: 3 }}
                          id="outlined-basic"
                          label="Descrição"
                          variant="outlined"
                          type="text"
                          value={description}
                          onChange={e => setDescription(e.target.value)}
                        />
                      </>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                  flexDirection={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
                >
                  {extra && (
                    <>
                      <Box sx={{ mt: 2 }} width={{ xs: '100%', sm: '100%', md: '100%', lg: '45%' }}>
                        <TextField
                          sx={{ width: '100%' }}
                          id="outlined-basic"
                          label="Descrição"
                          variant="outlined"
                          type="text"
                          value={extraDescription}
                          onChange={e => setExtraDescription(e.target.value)}
                        />
                      </Box>
                      <Box sx={{ mt: 2 }} width={{ xs: '100%', sm: '100%', md: '100%', lg: '45%' }}>
                        <InputLabel>Imagem Extra</InputLabel>
                        <TextField
                          sx={{ width: '100%' }}
                          id="outlined-basic"
                          // label="Imagem do recebimento"
                          variant="outlined"
                          type="file"
                          accept="image/*"
                          //value={photoExtra}
                          onChange={e => setPhotoExtra(e.target.files[0])}
                        />
                      </Box>
                    </>
                  )}
                </Box>
                <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', mt: 3 }}>
                  <Button type="submit" sx={{ mt: 2 }} variant="contained">
                    Finalizar
                  </Button>
                  {/* <Button
                    type="button"
                    sx={{ mt: 2 }}
                    variant="text"
                    onClick={() => setExtra(!extra)}
                  >
                    Informações extras
                  </Button>
                  <Button
                    onClick={() => setRejected(!rejected)}
                    type="button"
                    sx={{ mt: 2 }}
                    variant="text"
                  >
                    {rejected ? 'Entrega Completa' : 'Entrega Rejeitada'}
                  </Button> */}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Ok</Button>
          </DialogActions>
        </Dialog>

        <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          {permissionCarWeightCREATE && (
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', ml: 1 }}
            >
              <Button
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
                variant="contained"
                endIcon={<AiFillPlusCircle />}
              >
                Criar
              </Button>
            </Box>
          )}
        </Box>

        {listMovimentation.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center"></StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                  <StyledTableCell align="center">Empresa</StyledTableCell>
                  <StyledTableCell align="center">Cidade</StyledTableCell>
                  <StyledTableCell align="center">Bairro</StyledTableCell>
                  <StyledTableCell align="center">Endereço</StyledTableCell>
                  <StyledTableCell align="center">Cep</StyledTableCell>
                  <StyledTableCell align="center">Google Maps</StyledTableCell>
                  <StyledTableCell align="center">Telefone</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listMovimentation
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center" sx={{ width: 100 }}>
                        {!row.delivery && (
                          <PlayArrowIcon
                            onClick={() => handleStartRoute(row.movimentationOrderId)}
                            sx={{ cursor: 'pointer' }}
                          />
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 100 }}>
                        {!row.delivery && (
                          <EmailIcon
                            onClick={() => handleInitDelivery(row.movimentationOrderId)}
                            sx={{ cursor: 'pointer' }}
                          />
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.companyName}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.city}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.neighborhood}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.address}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.cep}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        <a
                          className="dropzone"
                          target="_blank"
                          rel="noreferrer"
                          href={row.linkMaps}
                          style={{ textDecoration: 'none' }}
                        >
                          {row.linkMaps}
                        </a>
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.phone}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {returnStatus(row.orderDelivery)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={listMovimentation.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ListDriver
