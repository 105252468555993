import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material'
import ViewElearning from '../../ViewElearning'
import { Clear, Delete, Edit, ExpandMore, Help, Lock, MoreVert, Search } from '@mui/icons-material'
import { AiFillPlusCircle } from 'react-icons/ai'
import { useCallback, useContext, useEffect, useState } from 'react'
import { getCompanyService, getParamGroupService } from '../../../services/admServices'
import { colorTableDefault } from '../../TemplateDefault'
import { Context } from '../../../context/AuthContext'
import CollaboratorCreate from '../../Create/ListUserCreate'
import CollaboratorEdit from '../../Edit/ListUserEdit'
import {
  getSubCompanyCompanyIdService,
  getUserSubCompanyIdService,
} from '../../../services/configServices'
import { useNavigate } from 'react-router-dom'
import PermissionComponent from '../../../permissions'
import ListUserEdit from '../../Edit/ListUserEdit'
import ListUserCreate from '../../Create/ListUserCreate'

export const ListUser = () => {
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)
  const [companyLimit, setCompanyLimit] = useState(500)
  const [companyOffset, setCompanyOffset] = useState(0)
  const [companyOrder, setCompanyOrder] = useState('acs')
  const [company, setCompany] = useState([])
  const [companyId, setCompanyId] = useState('')
  const [subCompanyId, setSubCompanyId] = useState('')
  const [subCompany, setSubCompany] = useState([])
  const [paramGroup, setParamGroup] = useState([])
  const [dataFunctions, setDataFunctions] = useState([])
  const [pagination, setPagination] = useState({})
  const [paramGroupLimit, setParamGroupLimit] = useState(10)
  const [paramGroupOffset, setParamGroupOffset] = useState(0)
  const [paramGroupOrder, setParamGroupOrder] = useState(false)

  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [idEditing, setIdEditing] = useState('')
  const [viewElearning, setViewElearning] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [user, setUser] = useState([])

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const [anchorElMap, setAnchorElMap] = useState(new Map())

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleLockClick = (companyIdParams, subCompanyIdParams, userIdParams) => {
    const url = `/PermissionsUser/${companyIdParams}/${subCompanyIdParams}/${userIdParams}`

    navigate(url)
  }

  useEffect(() => {
    handleGetCompany()
  }, [subCompanyId, companyId])

  const handleGetCompany = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getCompanyService(
        token,
        nameUrl,
        companyLimit,
        companyOffset,
        companyOrder
      )
      setCompany(response.data.result)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [])

  const handleGetSubCompany = useCallback(async companyId => {
    setLoading(true)
    try {
      const response = await getSubCompanyCompanyIdService(token, nameUrl, companyId)
      setSubCompany(response.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [])

  const handleGetUser = useCallback(async subCompany => {
    setLoading(true)
    try {
      const response = await getUserSubCompanyIdService(token, nameUrl, subCompany)
      setUser(response.data)
      setDataFunctions(response.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [])

  const fetchData = async values => {
    setSubCompanyId(values)
    setLoading(true)
    try {
      handleGetUser(values)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  const getParamGroup = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getParamGroupService(
        token,
        paramGroupLimit,
        paramGroupOffset,
        paramGroupOrder
      )
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setParamGroup(response.data.result)
        setPagination(response.data)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [paramGroupOrder])

  useEffect(() => {
    getParamGroup()
  }, [paramGroupOrder])

  const handleEdit = item => {
    handleCloseMenu(item.id)
    setIdEditing(item)
    setExpanded(true)
    setEditing(true)
  }

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const deleteAlert = id => {
    setOpen(true)
    setIdDelete(id)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)

    try {
      setDataFunctions(prevData => prevData.filter(item => item.id !== idDelete))
      setSeverity('success')
      setTitle('Grupo de Parametros deletado com sucesso!')

      setAlerta(true)
      setLoading(false)
      getParamGroup()
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  const handleOpenMenu = (event, itemId) => {
    setAnchorElMap(prevMap => new Map(prevMap.set(itemId, event.currentTarget)))
  }

  const handleCloseMenu = id => {
    setAnchorElMap(prevMap => {
      const newMap = new Map(prevMap)
      newMap.delete(id)
      return newMap
    })
  }

  const handleClearSearch = () => {
    setSearchValue('')
  }

  const filteredRows =
    dataFunctions.filter(row =>
      row.nameEmployee.toLowerCase().includes(searchValue.toLowerCase())
    ) || []
  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <ViewElearning
          pageName={'ParametersGroup'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Lista de Usuários</Typography>
          <IconButton onClick={() => setViewElearning(true)} sx={{ justifySelf: 'flex-end' }}>
            <Help />
          </IconButton>
        </Box>

        {/* Criar  */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>{editing ? 'Editando Usuario' : 'Cadastrar Usuario'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="ParametersGroupEDIT">
                <ListUserEdit
                  setEditing={setEditing}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  functions={idEditing}
                  setExpanded={setExpanded}
                  handleFetchData={fetchData}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="ParametersGroupCREATE">
                <ListUserCreate
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={fetchData}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
                <Select
                  value={companyId}
                  sx={{ width: '100%' }}
                  onChange={values => {
                    setCompanyId(values.target.value)
                    handleGetSubCompany(values.target.value)
                  }}
                >
                  {company.map((item, index) => {
                    return (
                      <MenuItem value={item.companyId} key={item.companyId}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Selecione a Filial</InputLabel>
                <Select
                  disabled={!companyId}
                  value={subCompanyId}
                  onChange={event => {
                    setSubCompanyId(event.target.value)
                    handleGetUser(event.target.value)
                  }}
                  sx={{ width: '100%' }}
                  label="Filial"
                >
                  {subCompany.map((item, index) => (
                    <MenuItem value={item.subCompanyId} key={item.subCompanyId}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: 3,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', ml: 1 }}
          >
            <PermissionComponent role="ParametersGroupCREATE">
              <Button
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
                variant="contained"
                endIcon={<AiFillPlusCircle />}
              >
                Criar
              </Button>
            </PermissionComponent>
          </Box>
        </Box>
        {/* Deletar */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>

        <Grid
          container
          spacing={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {filteredRows.length > 0 && (
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: '300px',
                  width: '100%',
                }}
              >
                <IconButton sx={{ p: '10px' }} aria-label="menu">
                  <Search />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Pessoa"
                  value={searchValue}
                  onChange={e => setSearchValue(e.target.value)}
                />
                {searchValue && (
                  <IconButton aria-label="Limpar pesquisa" onClick={handleClearSearch}>
                    <Clear />
                  </IconButton>
                )}
              </Paper>
            </Grid>
          )}
          {filteredRows.length > 0 && (
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <Typography variant="body1">
                {filteredRows.length}
                {filteredRows.length === 1 ? ' Resultado' : ' Resultados'}
              </Typography>
            </Grid>
          )}
        </Grid>

        {filteredRows.length > 0 && (
          <Paper sx={{ mt: 2 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Nome</StyledTableCell>
                    <StyledTableCell align="center"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(item => (
                      <TableRow key={item.userId}>
                        <TableCell>{item.nameEmployee}</TableCell>

                        <TableCell align="right">
                          <Tooltip title="Permissões" arrow>
                            <IconButton
                              aria-label="Editar"
                              size="small"
                              onClick={() =>
                                handleLockClick(subCompany[0].companyId, subCompanyId, item.userId)
                              }
                            >
                              <Lock />
                            </IconButton>
                          </Tooltip>

                          <IconButton color="primary" onClick={e => handleOpenMenu(e, item.id)}>
                            <MoreVert />
                          </IconButton>
                          <Menu
                            anchorEl={anchorElMap.get(item.userId)}
                            open={Boolean(anchorElMap.get(item.userId))}
                            onClose={() => handleCloseMenu(item.userId)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            sx={{ pt: '0 !important ', pb: '0 !important' }}
                          >
                            <MenuItem onClick={() => handleEdit(item)}>
                              <ListItemIcon>
                                <Edit />
                              </ListItemIcon>
                              Editar
                            </MenuItem>
                            <MenuItem onClick={() => deleteAlert(item.userId)}>
                              <ListItemIcon>
                                <Delete />
                              </ListItemIcon>
                              Remover
                              <Typography></Typography>
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}
