import { useCallback, useContext, useEffect, useState } from 'react'
import {
  createReprocessingTags,
  getLogs,
  getSubCompanyCompanyIdService,
} from '../../../services/configServices'
import { Context } from '../../../context/AuthContext'

import {
  Backdrop,
  Box,
  Button,
  Alert,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  IconButton,
  Snackbar,
  FormControl,
  TablePagination,
  Paper,
  TableContainer,
  Table,
  TableHead,
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
  TableBody,
  Tooltip,
  Collapse,
} from '@mui/material'
import {
  Check,
  ContentPaste,
  DeleteForever,
  Help,
  KeyboardArrowDown,
  KeyboardArrowUp,
  ModeEditOutline,
} from '@mui/icons-material'
import { getCompanyService } from '../../../services/admServices'
import { colorTableDefault } from '../../TemplateDefault'
import {
  differenceInDays,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  format,
} from 'date-fns'
import CopyToClipboard from 'react-copy-to-clipboard'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const typeLog = [{ value: 32, label: 'Logs' }]
const LogClient = () => {
  const token = localStorage.getItem('token')
  const { company, nameUrl } = useContext(Context)
  const [companyId, setCompanyId] = useState('')
  const [subCompanyId, setSubCompanyId] = useState('')
  const [subCompanyCnpj, setSubCompanyCnpj] = useState(0)
  const [subCompany, setSubCompany] = useState([])
  const [typeLogId, setTypeLogId] = useState('')
  const [process, setProcess] = useState([])
  const [filtered, setFiltered] = useState([])
  const [listCodRfid, setListCodRfid] = useState('')
  const [loading, setLoading] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [search, setSearch] = useState('')

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const formatDistanceToNowString = dateString => {
    const date = new Date(dateString)
    const now = new Date()

    const millisecondsDiff = differenceInMilliseconds(now, date)
    const minutesDiff = differenceInMinutes(now, date)
    const hoursDiff = differenceInHours(now, date)
    const daysDiff = differenceInDays(now, date)

    if (millisecondsDiff < 5000) {
      return 'há menos de 5 segundos'
    } else if (minutesDiff === 1) {
      return 'há 1 minuto'
    } else if (minutesDiff < 60) {
      return `há ${minutesDiff} minutos`
    } else if (hoursDiff === 1) {
      return 'há 1 hora'
    } else if (hoursDiff < 24) {
      return `há ${hoursDiff} horas`
    } else if (daysDiff === 1) {
      return 'há 1 dia atrás'
    } else if (daysDiff < 7) {
      return `há ${daysDiff} dias atrás`
    } else {
      return 'há mais de uma semana'
    }
  }

  const handleSearchLog = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)

      try {
        const response = await getLogs(token, nameUrl, typeLogId)
        if (response.data.length < 1) {
          setProcess(response.data)
          setLoading(false)
          setAlerta(true)
          setSeverity('error')
          setTitle('Nenhum log encontrado!')
          console.log(subCompany)
        } else {
          setProcess(response.data)
          setLoading(false)
          setAlerta(true)
          setTitle('Consulta realizada com sucesso!')
          setSeverity('success')
          console.log(subCompany)
        }

        //handleSubCompanyAll(subCompany[0].companyId)
        //setTitle(response.data.message)
      } catch (err) {
        console.log(err)
        setLoading(false)
        setAlerta(true)
        setSeverity('error')
        setTitle('Erro ao cadastrar, verifique os dados e tente novamente.')
      }
    },
    [listCodRfid, typeLogId]
  )

  useEffect(() => {
    if (company.companyId) {
      console.log(company, 'comp')
      handleSubCompanyAll()
    }
  }, [company.companyId])

  const handleSubCompanyAll = async () => {
    setLoading(true)
    setSubCompanyId('')
    setSearch('')
    try {
      const response = await getSubCompanyCompanyIdService(token, nameUrl, company.companyId)
      setSubCompany(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const filteredLogs = () => {
    const filtered = process.filter(row => {
      const jsonIncludesSearch = row.json.toLowerCase().includes(search.toLowerCase())
      const matchesSubCompany = row.json
        .toLowerCase()
        .includes(subCompanyId.toString().toLowerCase())
      return jsonIncludesSearch && matchesSubCompany
    })
    console.log(subCompanyId, 'dsad')
    setFiltered(filtered)
  }

  useEffect(() => {
    filteredLogs()
  }, [search, subCompanyId, process])

  const truncateString = (str, maxLength) => {
    if (str.length <= maxLength) {
      return str
    } else {
      return str.substring(0, maxLength) + '...'
    }
  }

  function Row(props) {
    const { row } = props
    const [open, setOpen] = useState(false)
    const [copied, setCopied] = useState(false)

    const handleCopy = text => {
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    }
    return (
      <>
        <StyledTableRow key={row.roleCategoryId} sx={{ '& > *': { borderBottom: 'unset' } }}>
          <StyledTableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Box
              position="relative"
              display="flex"
              flexDirection="column"
              justifyContent="start"
              alignItems="start"
            >
              <CopyToClipboard text={row.json} onCopy={() => handleCopy(row.json)}>
                {copied ? (
                  <Button sx={{ fontSize: '1rem', textTransform: 'none' }}>
                    <Check sx={{ color: 'green', fontSize: '1rem' }} />
                    <Typography sx={{ fontSize: '1rem', textTransform: 'none' }}>
                      Copiado!
                    </Typography>
                  </Button>
                ) : (
                  <Button sx={{ fontSize: '1rem', textTransform: 'none' }}>
                    <ContentPaste sx={{ fontSize: '1rem' }} />
                    <Typography sx={{ fontSize: '1rem', textTransform: 'none' }}>
                      Copiar!
                    </Typography>
                  </Button>
                )}
              </CopyToClipboard>
              {truncateString(row.json, 50)}{' '}
            </Box>
          </StyledTableCell>
          <StyledTableCell align="left">{truncateString(row.jobId, 50)}</StyledTableCell>
          <StyledTableCell align="center">
            {row.createDate && typeof new Date(row.createDate) === 'object' ? (
              <Tooltip title={format(new Date(row.createDate), 'dd/MM/yyyy HH:mm:ss')} arrow>
                {formatDistanceToNowString(row.createDate)}
              </Tooltip>
            ) : (
              'Data Inválida'
            )}
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit onClick={e => e.stopPropagation()}>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell sx={{ width: 100 }}>JSON</StyledTableCell>
                      <StyledTableCell sx={{ width: 100 }}>JobId</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell>
                        <Box
                          position="relative"
                          display="flex"
                          flexDirection="column"
                          justifyContent="start"
                          alignItems="start"
                        >
                          <CopyToClipboard text={row.json} onCopy={handleCopy}>
                            {copied ? (
                              <Button
                                sx={{ fontSize: '1rem', textTransform: 'none' }}
                                onClick={e => e.stopPropagation()}
                              >
                                <Check sx={{ color: 'green', fontSize: '1rem' }} />
                                <Typography sx={{ fontSize: '1rem', textTransform: 'none' }}>
                                  Copiado!
                                </Typography>
                              </Button>
                            ) : (
                              <Button
                                sx={{ fontSize: '1rem', textTransform: 'none' }}
                                onClick={e => e.stopPropagation()}
                              >
                                <ContentPaste sx={{ fontSize: '1rem' }} />
                                <Typography sx={{ fontSize: '1rem', textTransform: 'none' }}>
                                  Copiar!
                                </Typography>
                              </Button>
                            )}
                          </CopyToClipboard>
                          {row.json}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>{row.jobId}</StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      </>
    )
  }

  return (
    <>
      <Box sx={{ mt: 9 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={() => setAlerta(false)}
          key={vertical + horizontal}
        >
          <Alert onClose={() => setAlerta(false)} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Log</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <Help />
          </IconButton>
        </Box>
        <Box component="form" sx={{ mt: '2rem' }} onSubmit={handleSearchLog}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Tipo de Log</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Tipo de Log"
                  value={typeLogId}
                  onChange={values => {
                    setTypeLogId(values.target.value)
                    setCompanyId('')
                    setSubCompanyId('')
                    setSearch('')
                  }}
                  sx={{ width: '100%' }}
                >
                  {typeLog.map((item, index) => {
                    return (
                      <MenuItem value={item.value} key={index}>
                        {item.label}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}></Grid>

            <Grid item xs={12} md={2}>
              <Button
                disabled={!typeLogId}
                type="submit"
                variant="contained"
                sx={{ width: '100%', py: 2 }}
                //onClick={handleSearchLog}
              >
                Pesquisar
              </Button>
            </Grid>
          </Grid>
          <Box my={4}>
            <Typography variant="h6" my={2}>
              Filtrar resultados
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Filial</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Filial"
                    disabled={!typeLogId}
                    value={subCompanyId}
                    onChange={values => {
                      setSubCompanyId(values.target.value)
                    }}
                    sx={{ width: '100%' }}
                  >
                    <MenuItem value="">Todos</MenuItem>
                    {Array.isArray(subCompany) &&
                      subCompany.map((item, index) => (
                        <MenuItem value={item.subCompanyId} key={index}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  value={search}
                  id="outlined-basic"
                  label="Pesquisa"
                  variant="outlined"
                  sx={{ width: '100%' }}
                  onChange={e => setSearch(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Paper sx={{ mt: 2 }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell>JSON</StyledTableCell>
                          <StyledTableCell>JobId</StyledTableCell>
                          <StyledTableCell align="center">Data de criação</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filtered
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map(row => (
                            <Row row={row} key={row.logReceiveJsonId} />
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={filtered.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default LogClient
