import axios from 'axios'

export const uploadPhotos = async ({ token, formData, nameUrl }) => {
  try {
    const response = await axios.post(`/${nameUrl}/api/ProductItens/UploadPhoto`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    console.log('Deu certo', response.data)
    return response.data
  } catch (error) {
    console.error('Erro ao enviar dados:', error)
    throw error
  }
}

export const getPhotos = async ({ token, nameUrl, productItemsId }) => {
  console.log(`/${nameUrl}/api/ProductItens/GetPhoto/ProductItensId=${productItemsId}`)
  try {
    const response = await axios.get(
      `/${nameUrl}/api/ProductItens/GetPhoto?ProductItensId=${productItemsId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    console.log('Deu certo', response.data)
    return response.data
  } catch (error) {
    console.error('Erro ao enviar dados:', error)
    throw error
  }
}

export const deletePhoto = async ({ token, nameUrl, ProductItensPhotoId }) => {
  console.log(token, nameUrl, ProductItensPhotoId)
  try {
    const response = await axios.get(
      `/${nameUrl}/api/ProductItens/DeletePhoto?ProductItensPhotoId=${ProductItensPhotoId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    console.log('Deu certo', response.data)
    return response.data
  } catch (error) {
    console.error('Erro ao enviar dados:', error)
    throw error
  }
}
