export const formatCEP = inputValue => {
  const value = inputValue.replace(/\D/g, '') // Remove caracteres não numéricos

  // Adiciona a máscara
  if (value.length > 5) {
    return `${value.slice(0, 5)}-${value.slice(5)}`
  } else {
    return value
  }
}
