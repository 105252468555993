import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useContext, useEffect, useState } from 'react'
import { getUser, userUpdate } from '../../../services/api'
import ResetPassword from '../../ResetPassword'
import { Context } from '../../../context/AuthContext'

const Profile = () => {
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)

  const vertical = 'top'
  const horizontal = 'center'
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('')
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const handleGetUser = async () => {
      setLoading(true)
      try {
        const response = await getUser(token, nameUrl)
        setName(response.data.fullName)
        setEmail(response.data.email)
        setPhoneNumber(response.data.phoneNumber)
        setLoading(false)
      } catch (e) {
        console.log(e)
        setLoading(false)
      }
    }

    handleGetUser()
  }, [])

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const user = {
      name,
      phoneNumber,
      email,
    }
    try {
      await userUpdate(token, nameUrl, user)
      setTitle('Dados atualizados com sucesso!')
      setSeverity('success')
      setOpen(true)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setTitle('Erro ao atualizar, verifique os dados e tente novamente')
      setSeverity('error')
      setOpen(true)
      setLoading(false)
    }
  }

  return (
    <Box sx={{ mt: 7 }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {title}
        </Alert>
      </Snackbar>
      <Typography variant="h5">Perfil</Typography>
      <Box
        sx={{ alignItems: 'center', justifyContent: 'space-evenly', width: '100%', mt: 2 }}
        display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
      >
        <Box
          width={{ xs: '90%', sm: '90%', md: '40%', lg: '40%', xl: '40%' }}
          sx={{
            mt: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
          }}
        >
          <Paper
            elevation={3}
            sx={{
              textAlign: 'center',
              padding: 2,
              width: '100%',
            }}
          >
            <Typography variant="h5" sx={{ mb: 5 }}>
              Alterar dados
            </Typography>
            <Box component="form" sx={{ mt: '2rem' }} onSubmit={handleSubmit}>
              <Box>
                <TextField
                  sx={{ width: '100%' }}
                  value={name}
                  onChange={values => setName(values.target.value)}
                  id="outlined-basic"
                  label="Nome"
                  variant="outlined"
                  type="text"
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <TextField
                  sx={{ width: '100%' }}
                  value={email}
                  onChange={values => setEmail(values.target.value)}
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  type="text"
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <TextField
                  sx={{ width: '100%' }}
                  value={phoneNumber}
                  onChange={values => setPhoneNumber(values.target.value)}
                  id="outlined-basic"
                  label="Telefone"
                  variant="outlined"
                  type="text"
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  mt: 3,
                  justifyContent: 'space-evenly',
                }}
              >
                <Button type="submit" variant="contained">
                  Enviar
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box
          width={{ xs: '90%', sm: '90%', md: '40%', lg: '40%', xl: '40%' }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
            mt: 2,
          }}
        >
          <ResetPassword
            setLoading={setLoading}
            setTitle={setTitle}
            setSeverity={setSeverity}
            setOpen={setOpen}
          />
        </Box>
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}

export default Profile
