import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Select, InputLabel, MenuItem } from '@mui/material'

const GraficSalesAveragePerSeller = ({ salesAveragePerSeller }) => {
  const [selectValue, setSelectValue] = useState('Todos')
  const [data, setData] = useState([])
  const [isShowTop10, setIsShowTop10] = useState(false)

  useEffect(() => {
    if (!salesAveragePerSeller || salesAveragePerSeller.length === 0) {
      return
    }
    if (salesAveragePerSeller.length > 0 && selectValue === 'Todos') {
      salesAveragePerSeller.sort((a, b) => b.price - a.price).slice(0, 10)
      setSelectValue('Todos')
    }
  }, [salesAveragePerSeller])

  useEffect(() => {
    handleFilter()
  }, [selectValue])

  const handleFilter = () => {
    let filter

    if (selectValue === 'Todos') {
      filter = salesAveragePerSeller.sort((a, b) => b.price - a.price).slice(0, 10)
      setIsShowTop10(true)
    } else {
      filter = salesAveragePerSeller.filter(item => item.name === selectValue)
      setIsShowTop10(false)
    }

    setData(filter)
  }

  const options = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: isShowTop10 ? '80%' : '50%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(val)
      },
      offsetX: 0,
      offsetY: 0,

      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },
    stroke: {
      show: true,
      width: 4,
      colors: ['transparent'],
    },
    xaxis: {
      categories: isShowTop10
        ? salesAveragePerSeller.slice(0, 10).map(item => item.name)
        : data.map(item => item.name),
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(val)
        },
        style: {
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      shared: true,
      intersect: false,

      y: {
        formatter: function (val) {
          return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(val)
        },
      },
    },
  }

  const series = [
    {
      name: 'Ticket Médio',
      data: isShowTop10
        ? salesAveragePerSeller.slice(0, 10).map(item => item.price)
        : data.map(item => item.price),
    },
  ]

  return (
    <div>
      <div>
        <InputLabel id="demo-simple-select-label">Selecione o vendedor</InputLabel>
        <Select
          size="small"
          sx={{ width: '50%' }}
          label="Escolher o vendedor"
          value={selectValue}
          onChange={e => setSelectValue(e.target.value)}
        >
          <MenuItem value="Todos">Todos</MenuItem>
          {salesAveragePerSeller.map((item, index) => {
            return (
              <MenuItem value={item.name} key={item.name}>
                {item.name}
              </MenuItem>
            )
          })}
        </Select>
      </div>
      <div id="grafico">
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  )
}

export default GraficSalesAveragePerSeller
