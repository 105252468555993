import axios from 'axios'

export const createCarWeightService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/CarWeight/Create`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getCarWeightService = async (token, nameUrl, data) => {
  let orderToString
  if (data.order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }
  const response = await axios.get(
    `/${nameUrl}/api/CarWeight/GetAll?SubCompanyId=${data.SubCompanyId}&limit=${data.limit}&offset=${data.offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getCarWeightIdService = async (token, nameUrl, id) => {
  const response = await axios.get(`/api/CarWeight/Get/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const editCarWeightService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/CarWeight/Edit`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const deleteCarWeightService = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/CarWeight/Delete`,
    {
      carWeightId: data,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}
