import React, { useContext } from 'react'
import { useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  deleteMovimentationOrderService,
  getOrderPaginationService,
} from '../../../services/productService'
import * as XLSX from 'xlsx'
import HelpIcon from '@mui/icons-material/Help'
import { RESULTS_PER_PAGE } from '../../../utils/excel'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

//icones react-icons
import { AiFillFileExcel } from 'react-icons/ai'
import { AiFillPlusCircle } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import SalesOrderCreate from '../../Create/SalesOrderCreate'
import SalesOrderEdit from '../../Edit/SalesOrderEdit'
import ViewElearning from '../../ViewElearning'
import { returnStatus } from '../../../assets/statusTypes'
import MovimentationReturnCreate from '../../Create/MovimentationReturnCreate'
import MovimentationReturnEdit from '../../Edit/MovimentationReturnEdit'
import RejeitoCreate from '../../Create/RejeitoCreate'
import RejeitoEdit from '../../Edit/RejeitoEdit'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'
import { useNavigate } from 'react-router-dom'
import PermissionComponent from '../../../permissions'
import { PlayArrow } from '@mui/icons-material'

const Rejeito = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const [salesOrder, setSalesOrder] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [salesOrderOrder, setSalesOrderOrder] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)

  //constantes crud
  const [error, setError] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [idEditing, setIdEditing] = useState('')

  const [totalPagesDownload, setTotalPagesDownload] = useState('')
  const [progress, setProgress] = useState(0)
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalData, setTotalData] = useState(0)
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const fetchData = async values => {
    if (values) {
      setSubCompanyId(values)
    }
    setLoading(true)
    try {
      const response = await getOrderPaginationService(
        token,
        nameUrl,
        { subCompanyId: values ? values : subCompanyId, typeOrder: 27 },
        rowsPerPage,
        page,
        salesOrderOrder
      )
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
        setSalesOrder([])
      } else {
        setSalesOrder(response.data.result)
        setPageCount(response.data.totalpage + 1)
        setTotalData(response.data.total)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setTitle('Erro no servidor, tente novamente')
      setAlerta(true)
      setSeverity('error')
      setLoading(false)
    }
  }

  useEffect(() => {
    if (subCompanyId !== '') {
      fetchData(subCompanyId)
    }
  }, [page, rowsPerPage])

  useEffect(() => {
    handleFetchData()
  }, [search])

  const handleFetchData = async () => {
    if (subCompanyId) {
      setSearching(true)
      const response = await getOrderPaginationService(
        token,
        nameUrl,
        { subCompanyId: subCompanyId, typeOrder: 27 },
        rowsPerPage,
        0,
        salesOrderOrder,
        search
      )
      setSalesOrder(response.data.result)
      setPageCount(response.data.totalpage + 1)
      setTotalData(response.data.total)
      setPage(0)
      setSearching(false)
    }
  }

  const downloadData = async () => {
    setDownloadMessage('Inicializando')
    setDownloading(true)
    await getOrderPaginationService(
      token,
      nameUrl,
      { subCompanyId, typeOrder: 27 },
      RESULTS_PER_PAGE,
      0,
      salesOrderOrder
    ).then(r => {
      setTotalPagesDownload(r.data.totalpage + 1)
      finishDownload(r.data.totalpage + 1)
    })
  }

  const finishDownload = async pages => {
    let result = []

    for (let i = 0; i <= pages; i++) {
      setDownloadMessage(`Baixando ${i} de ${pages}...`)
      setProgress((i / pages) * 100)
      const response = await getOrderPaginationService(
        token,
        nameUrl,
        { subCompanyId: subCompanyId, typeOrder: 27 },
        RESULTS_PER_PAGE,
        i,
        salesOrderOrder
      )
      result = result.concat(response.data.result)
    }
    setDownloadMessage(`Seu arquivo está pronto.`)
    setDownloading(false)
    const relatorioFinal = []
    result.map(item => {
      relatorioFinal.push({
        'Ordem Bihands': item.movimentationOrderNumberBihands,
        'Ordem (Outro Sistema)': item.movimentationOrderNumberOtherSystem,
        Filial: item.subCompanyNameTo,
        Criado: new Date(item.createRequestMovimentationOrderDate).toLocaleString(),
        Status: item.active ? 'Aberto' : 'Inativo',
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Rejeito')
    XLSX.writeFile(workbook, `Rejeito.xlsx`)
  }

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleEdit = id => {
    setIdEditing(id)
    setExpanded(true)
    setEditing(true)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    try {
      await deleteMovimentationOrderService(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Venda deletada com sucesso!')

      setAlerta(true)
      setLoading(false)
      fetchData()
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Dialog open={downloading} keepMounted aria-describedby="alert-dialog-slide-description">
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessage}</DialogTitle>
            <LinearProgressWithLabel value={progress} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <ViewElearning pageName={'SalesOrder'} open={viewElearning} setOpen={setViewElearning} />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Rejeito</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon onClick={() => setViewElearning(true)} />
          </IconButton>
        </Box>
        {/* Criar */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>{editing ? 'Editar Rejeito' : 'Cadastrar Rejeito'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <RejeitoEdit
                setEditing={setExpanded}
                setAlerta={setAlerta}
                setSeverity={setSeverity}
                setTitle={setTitle}
                labelOrderId={idEditing}
                handleFetchData={fetchData}
              />
            ) : (
              <RejeitoCreate
                setExpanded={setExpanded}
                setAlerta={setAlerta}
                setSeverity={setSeverity}
                setTitle={setTitle}
                handleFetchData={fetchData}
              />
            )}
          </AccordionDetails>
        </Accordion>

        {/* Deletar */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>
        <SelectSubCompany
          value={subCompanyId}
          onChange={e => {
            fetchData(e.target.value)
          }}
        />
        <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
            {salesOrder.length > 0 && (
              <Button onClick={downloadData} variant="contained" endIcon={<AiFillFileExcel />}>
                Excel
              </Button>
            )}
          </Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', ml: 1 }}
          >
            <Button
              variant="contained"
              endIcon={<AiFillPlusCircle />}
              onClick={() => {
                setEditing(false)
                setExpanded(true)
              }}
            >
              Criar
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TextField
            variant="outlined"
            placeholder="Procurar por Ordem"
            type="search"
            onInput={e => setSearch(e.target.value)}
          />
          {searching && <Typography>Buscando...</Typography>}
        </Box>

        <TableContainer component={Paper} sx={{ mt: '2rem' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell> </StyledTableCell>
                <StyledTableCell align="left">Ordem Bihands</StyledTableCell>
                <StyledTableCell align="left">Ordem (outro sistema)</StyledTableCell>
                <StyledTableCell align="left">Filial</StyledTableCell>
                <StyledTableCell align="left">Criado</StyledTableCell>
                <StyledTableCell align="left">Motivo</StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {salesOrder.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                        width: '80px',
                      }}
                    >
                      <PermissionComponent role="">
                        <Tooltip title="Contagem" arrow>
                          <IconButton
                            onClick={() =>
                              navigate(`/CountStockDesk/${27}/${row.movimentationOrderId}`)
                            }
                            sx={{ cursor: 'pointer' }}
                          >
                            <PlayArrow />
                          </IconButton>
                        </Tooltip>
                      </PermissionComponent>{' '}
                      <PermissionComponent role="">
                        <Tooltip title="Apagar" arrow>
                          <IconButton
                            onClick={() => deleteAlert(row.movimentationOrderId)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </Tooltip>
                      </PermissionComponent>{' '}
                      <PermissionComponent role="">
                        <Tooltip title="Editar" arrow>
                          <IconButton
                            onClick={() => handleEdit(row.movimentationOrderId)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <ModeEditOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </PermissionComponent>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.movimentationOrderNumberBihands}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.movimentationOrderNumberOtherSystem}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.subCompanyNameTo}</StyledTableCell>
                  <StyledTableCell align="left">
                    {new Date(row.createRequestMovimentationOrderDate).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.reasonsName}</StyledTableCell>
                  <StyledTableCell align="left">
                    {returnStatus(row.movimentationOrderStatus)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={totalData}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default Rejeito
