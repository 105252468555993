import { useCallback, useContext, useEffect, useState } from 'react'
import {
  addSubCompanyUser,
  addSubCompnayUser,
  deleteEmployeeService,
  getEmployeeService,
  getSubCompanyCompanyIdService,
  getUniqueEmployeeService,
  removeSubCompanyUser,
} from '../../../services/configServices'
import { Context } from '../../../context/AuthContext'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import HelpIcon from '@mui/icons-material/Help'
import { useNavigate } from 'react-router-dom'

import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  Paper,
  Backdrop,
  CircularProgress,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  Tooltip,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  DialogContent,
  DialogContentText,
  List,
  Chip,
} from '@mui/material'
import EmployeeCreate from '../../Create/EmployeeCreate'
import EmployeeEdit from '../../Edit/EmployeeEdit'
import EmployeeRole from '../EmployeeRole'

//icones react-icons
import { AiFillPlusCircle } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'
import PermissionComponent from '../../../permissions'
import { Add, EdgesensorLowSharp, Lock, Remove, Visibility } from '@mui/icons-material'
import { getCompanyService } from '../../../services/admServices'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const Employee = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const navigate = useNavigate()
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [companyLimit, setCompanyLimit] = useState(500)
  const [companyOffset, setCompanyOffset] = useState(0)
  const [companyOrder, setCompanyOrder] = useState('acs')
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [idEditing, setIdEditing] = useState('')
  const [editingRole, setEditingRole] = useState(false)
  const token = localStorage.getItem('token')
  const [employee, setEmployee] = useState([])
  const [employeeFilter, setEmployeeFilter] = useState([])
  const [subCompanyList, setSubCompanyList] = useState([])
  const [employeeSubCompanyList, setEmployeeSubCompanyList] = useState({})
  const [company, setCompany] = useState([])
  const [search, setSearch] = useState('')
  const [usersId, setUsersId] = useState('')
  const [companyId, setCompanyId] = useState('')
  const [pagination, setPagination] = useState({})
  const [employeeLimit, setEmployeeLimit] = useState(10)
  const [employeeOffset, setEmployeeOffset] = useState(0)
  const [employeeOrder, setEmployeeOrder] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)
  const [loadingPopup, setLoadingPopup] = useState([])
  const [viewElearning, setViewElearning] = useState(false)

  const [openPopup, setOpenPopup] = useState(false)
  const [fullWidth, setFullWidth] = useState(true)
  const [maxWidth, setMaxWidth] = useState('md')

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleGetCompany = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getCompanyService(
        token,
        nameUrl,
        companyLimit,
        companyOffset,
        companyOrder
      )
      setCompany(response.data.result)

      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [])

  const handleGetSubCompany = useCallback(async subCompanyId => {
    setLoading(true)
    setSubCompanyId('')
    setUsersId('')
    try {
      const response = await getSubCompanyCompanyIdService(token, nameUrl, subCompanyId)
      console.log(subCompanyId, 'sub')
      console.log(response.data, 'response.data')
      setSubCompanyList(response.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    handleGetCompany()
    handleGetSubCompany(subCompany[0].companyId)
  }, [])

  const getEmployee = async subCompanyId => {
    setSubCompanyId(subCompanyId)
    setLoading(true)
    try {
      const response = await getEmployeeService(
        token,
        nameUrl,
        500,
        employeeOffset,
        'desc',
        subCompanyId
      )
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        console.log(response.data.result, 'response.data.result')
        setEmployee(response.data.result)
        setPagination(response.data)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (subCompanyId) {
      getEmployee(subCompanyId)
    }
  }, [subCompanyId])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleEdit = id => {
    setIdEditing(id)
    setExpanded(true)
    setEditing(true)
    setEditingRole(false)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    try {
      await deleteEmployeeService(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Colaborador deletado com sucesso!')

      setAlerta(true)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  const handleSelectSubCompany = userId => {
    setOpenPopup(true)
    setUsersId(userId)
    handleGetListSubCompanyUser(userId)
  }

  const handleGetListSubCompanyUser = async userId => {
    try {
      const response = await getUniqueEmployeeService(token, nameUrl, userId)
      const userSubCompanyList = response.data.listSubCompany

      const modifiedList = subCompanyList.map(subCompany => {
        const isInUse = userSubCompanyList.some(
          userSubCompany => userSubCompany.subCompanyId === subCompany.subCompanyId
        )

        return {
          ...subCompany,
          status: isInUse ? 2 : 1,
        }
      })

      setEmployeeSubCompanyList({
        ...response.data,
        listSubCompany: modifiedList,
      })
    } catch (error) {}
  }

  const handleAddSubCompany = async (userId, subCompanyId, index) => {
    setLoadingPopup(prevLoading => [
      ...prevLoading.slice(0, index),
      true,
      ...prevLoading.slice(index + 1),
    ])
    try {
      const response = await addSubCompanyUser(token, nameUrl, userId, subCompanyId)
      setSeverity('success')
      setTitle('Filial Adicionada com sucesso.')
      setAlerta(true)
      setLoadingPopup(prevLoading => [
        ...prevLoading.slice(0, index),
        false,
        ...prevLoading.slice(index + 1),
      ])
      handleGetListSubCompanyUser(userId)
    } catch (error) {
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoadingPopup(prevLoading => [
        ...prevLoading.slice(0, index),
        false,
        ...prevLoading.slice(index + 1),
      ])
    }
  }

  const handleRemoveSubCompany = async (userId, subCompanyId, index) => {
    setLoadingPopup(prevLoading => [
      ...prevLoading.slice(0, index),
      true,
      ...prevLoading.slice(index + 1),
    ])
    try {
      const response = await removeSubCompanyUser(token, nameUrl, userId, subCompanyId)
      setSeverity('success')
      setTitle('Filial Removida com sucesso.')
      setAlerta(true)
      setLoadingPopup(prevLoading => [
        ...prevLoading.slice(0, index),
        false,
        ...prevLoading.slice(index + 1),
      ])

      handleGetListSubCompanyUser(userId)
    } catch (error) {
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoadingPopup(prevLoading => [
        ...prevLoading.slice(0, index),
        false,
        ...prevLoading.slice(index + 1),
      ])
    }
  }

  useEffect(() => {
    filterRow()
  }, [employeeOrder, search, employee])

  const filterRow = () => {
    const filtered = employee.filter(
      item =>
        item.nameEmployee.toLowerCase().includes(search.toLowerCase()) ||
        item.emailEmployee.toLowerCase().includes(search.toLowerCase())
    )
    setEmployeeFilter(filtered)
  }

  return (
    <>
      <Box sx={{ mt: 7 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <ViewElearning pageName={'Employee'} open={viewElearning} setOpen={setViewElearning} />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5" sx={{ mb: 5 }}>
            Colaboradores
          </Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        {openPopup && (
          <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={openPopup}
            onClose={() => setOpenPopup(false)}
          >
            <DialogTitle>Filiais atribuídas ao colaborador</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ my: 2 }}>
                <Box>
                  <Typography component="span" variant="h6" fontWeight={700}>
                    Nome:
                  </Typography>
                  <Typography component="span" variant="body2" color="text.primary" sx={{ ml: 1 }}>
                    {employeeSubCompanyList.nameEmployee}
                  </Typography>
                </Box>
                <Box>
                  <Typography component="span" variant="h6" fontWeight={700}>
                    Email:
                  </Typography>

                  <Typography component="span" variant="body2" color="text.primary" sx={{ ml: 1 }}>
                    {employeeSubCompanyList.emailEmployee}
                  </Typography>
                </Box>
              </DialogContentText>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">Nome da Filial</StyledTableCell>
                      <StyledTableCell align="left">Status</StyledTableCell>
                      <StyledTableCell align="left"></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {employeeSubCompanyList.listSubCompany &&
                      employeeSubCompanyList.listSubCompany.map((row, index) => (
                        <StyledTableRow key={row.subCompanyId}>
                          <StyledTableCell align="left">{row.name}</StyledTableCell>
                          <StyledTableCell align="left" sx={{ width: 200 }}>
                            {row.status === 1 ? (
                              <Chip
                                label="Disponível"
                                style={{
                                  background: '#F0FDF4',
                                  color: '#3EB469',
                                  borderRadius: '5px',
                                }}
                              />
                            ) : (
                              <Chip
                                label="Em uso"
                                style={{
                                  background: '#FEE2E2',
                                  color: '#B91C1C',
                                  borderRadius: '5px',
                                }}
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="left" sx={{ width: 100 }}>
                            <Box display="flex" justifyContent="space-between" width="100%">
                              {row.status === 1 ? (
                                <Button
                                  variant="contained"
                                  disabled={loadingPopup[index]}
                                  onClick={() =>
                                    handleAddSubCompany(
                                      employeeSubCompanyList.userId,
                                      row.subCompanyId,
                                      index
                                    )
                                  }
                                  sx={{ cursor: 'pointer', width: '100%' }}
                                  startIcon={
                                    loadingPopup[index] ? (
                                      <CircularProgress size={10} color="inherit" />
                                    ) : (
                                      <Add />
                                    )
                                  }
                                >
                                  {loadingPopup[index] ? 'Adicionando...' : 'Adicionar'}
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  disabled={
                                    loadingPopup[index] ||
                                    employeeSubCompanyList.listSubCompany.length === 1
                                  }
                                  onClick={() =>
                                    handleRemoveSubCompany(
                                      employeeSubCompanyList.userId,
                                      row.subCompanyId,
                                      index
                                    )
                                  }
                                  sx={{ cursor: 'pointer', width: '100%' }}
                                  startIcon={
                                    loadingPopup[index] ? (
                                      <CircularProgress size={10} color="inherit" />
                                    ) : (
                                      <Remove />
                                    )
                                  }
                                >
                                  {loadingPopup[index] ? 'Removendo...' : 'Remover'}
                                </Button>
                              )}
                            </Box>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={() => setOpenPopup(false)}>
                Sair
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {/* Criar  */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>
              {editing
                ? 'Editar Colaborador'
                : editingRole
                ? 'Editar Regras'
                : 'Cadastrar Colaborador'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="Edit_Employee">
                <EmployeeEdit
                  setEditing={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  employeeId={idEditing}
                  //handleFetchData={getEmployeeTotal}
                />
              </PermissionComponent>
            ) : editingRole ? (
              <PermissionComponent role="Create_Employee">
                <EmployeeRole
                  setEditing={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  employeeId={idEditing}
                  //handleFetchData={getEmployeeTotal}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="Create_Employee">
                <EmployeeCreate
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  //handleFetchData={getEmployeeTotal}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>

        {/* Deletar */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse colaborador?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>

        <Grid container spacing={2}>
          {subCompany[0].companyId === '23262935-641b-49a5-ae6e-201a3d6a019d' && (
            <Grid item xs={12} md={6}>
              <Box flex={1} display="flex" justifyContent="flex-end">
                <FormControl sx={{ width: '100%', mt: 2 }}>
                  <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
                  <Select
                    value={companyId}
                    label="Empresa"
                    sx={{ width: '100%' }}
                    onChange={values => {
                      setCompanyId(values.target.value)
                      handleGetSubCompany(values.target.value)
                      setEmployee([])
                    }}
                  >
                    {company.map((item, index) => {
                      return (
                        <MenuItem value={item.companyId} key={item.companyId}>
                          {item.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <FormControl sx={{ width: '100%', mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Filial</InputLabel>
                <Select
                  value={subCompanyId}
                  onChange={event => {
                    setSubCompanyId(event.target.value)
                    getEmployee(event.target.value)
                  }}
                  sx={{ width: '100%' }}
                  label="Filial"
                >
                  {subCompanyList.map((item, index) => (
                    <MenuItem value={item.subCompanyId} key={item.subCompanyId}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Pesquisar"
              type="search"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            <PermissionComponent role="Create_Employee">
              <Button
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                  setEditingRole(false)
                }}
                variant="contained"
                endIcon={<AiFillPlusCircle />}
              >
                Criar
              </Button>
            </PermissionComponent>
          </Box>
        </Box>

        <TableContainer component={Paper} sx={{ mt: '2rem' }}>
          <Typography mb={2}>
            {employeeFilter.length > 1
              ? employeeFilter.length + ' Resultados'
              : employeeFilter.length + ' Resultado'}
          </Typography>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Nome do Colaborador</StyledTableCell>
                <StyledTableCell align="left">Email do Colaborador</StyledTableCell>
                {/* <StyledTableCell align="left">Nome da Compania</StyledTableCell>
                <StyledTableCell align="left">Nome da Filial</StyledTableCell> */}
                <StyledTableCell align="center">Ativo</StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employeeFilter
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left" sx={{ width: 200 }}>
                      {row.nameEmployee}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ width: 200 }}>
                      {row.emailEmployee}
                    </StyledTableCell>
                    {/* <StyledTableCell align="left" sx={{ width: 200 }}>
                      {row.companyName}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ width: 200 }}>
                      {row.companyName}
                    </StyledTableCell> */}
                    <StyledTableCell align="center" sx={{ width: 100 }}>
                      {row.active ? 'Sim' : 'Não'}
                    </StyledTableCell>

                    <StyledTableCell align="left" sx={{ maxWidth: 100, width: 100 }}>
                      <Box display="flex" justifyContent="space-between" width="100%">
                        <PermissionComponent role="View_Employee">
                          <Tooltip title="Visualizar Filial" arrow>
                            <IconButton
                              onClick={() => handleSelectSubCompany(row.userId)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <Visibility />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                        <PermissionComponent role="Edit_Employee">
                          <Tooltip title="Permissões" arrow>
                            <IconButton
                              onClick={() => {
                                if (
                                  subCompany[0].companyId === '23262935-641b-49a5-ae6e-201a3d6a019d'
                                ) {
                                  navigate(
                                    `/permissionsUser/${subCompany[0].companyId}/${subCompanyId}/${row.userId}`
                                  )
                                } else {
                                  navigate(`/PermissionsUserGroup/${row.userId}`)
                                }
                              }}
                              sx={{ cursor: 'pointer' }}
                            >
                              <Lock />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                        <PermissionComponent role="Delete_Employee">
                          <Tooltip title="Apagar" arrow>
                            <IconButton
                              onClick={() => deleteAlert(row.userId)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                        <PermissionComponent role="Edit_Employee">
                          <Tooltip title="Editar" arrow>
                            <IconButton
                              onClick={() => handleEdit(row.userId)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <ModeEditOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={employeeFilter.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default Employee
