import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '../../../context/AuthContext'
import HelpIcon from '@mui/icons-material/Help'

import {
  Box,
  Button,
  Typography,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material'

import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'
import { XMLUpload } from '../../../services/driverServices'

const NotaFiscal = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const [subCompanyId, setSubCompanyId] = useState()

  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)

  const [xmlFile, setXmlFile] = useState(null)
  const [xmlData, setXmlData] = useState('')

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleUpload = async () => {
    setLoading(true)
    if (subCompanyId && xmlFile) {
      const data = {
        SubCompanyId: subCompanyId,
        ContentType: xmlFile.type,
        ContentDisposition: 'file',
        Headers: 'file',
        Length: xmlFile.size,
        Name: xmlFile.name,
        FileName: xmlFile.name,
      }
      try {
        await XMLUpload(token, nameUrl, data)
        setTitle('Arquivo enviado com sucesso!')
        setOpen(true)
        setXmlData()
        setXmlFile()
        setLoading(false)
      } catch (e) {
        console.log(e)
        setTitle('Erro ao enviar arquivo, tente novamente.')
        setOpen(true)
        setLoading(false)
      }
    } else {
      setTitle('Selecione uma filial e um arquivo para enviar.')
      setOpen(true)
      setLoading(false)
    }
  }

  const handleXmlFileChange = event => {
    if (event) {
      const file = event.target.files[0]
      setXmlFile(file)

      const reader = new FileReader()
      reader.onload = event => {
        setXmlData(event.target.result)
      }
      reader.readAsText(file)
    }
  }

  const handleXmlDataChange = event => {
    setXmlData(event.target.value)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Typography variant="h5">Upload de nota fiscal</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <ViewElearning pageName={'ListDriver'} open={viewElearning} setOpen={setViewElearning} />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Ok</Button>
          </DialogActions>
        </Dialog>
        <FormControl sx={{ width: '100%', mt: 2 }}>
          <InputLabel id="demo-simple-select-label">Filial</InputLabel>
          <Select
            label="Selecione a filial"
            onChange={e => setSubCompanyId(e.target.value)}
            value={subCompanyId}
          >
            {subCompany.map((item, index) => {
              return (
                <MenuItem value={item.subCompanyId} key={index}>
                  {item.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <Box
          sx={{
            mt: 3,
            flexDirection: 'column',
            border: { md: '1px solid black' },
            p: 2,
            display: 'flex',
          }}
        >
          <Box sx={{ display: 'flex', width: '100%' }}>
            <TextField
              type="file"
              inputProps={{ accept: 'application/xml, text/xml' }}
              onChange={handleXmlFileChange}
            />
            <Button
              disabled={!xmlData}
              sx={{ ml: 3 }}
              variant="contained"
              onClick={() => handleUpload()}
            >
              Enviar
            </Button>
          </Box>
          <Typography>Apenas arquivos .XLS ou .XLSX</Typography>
          {xmlData && (
            <TextField
              sx={{ mt: 2 }}
              multiline
              rows={7}
              value={xmlData}
              onChange={handleXmlDataChange}
            />
          )}
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default NotaFiscal
