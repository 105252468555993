import React, { useContext } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { getCountStockService, getOnlyOrder } from '../../../services/reportServices'
import { Context } from '../../../context/AuthContext'
import * as XLSX from 'xlsx'
import PropTypes from 'prop-types'
import HelpIcon from '@mui/icons-material/Help'

import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Autocomplete,
  TextField,
  Chip,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  ListItemText,
  Checkbox,
  OutlinedInput,
} from '@mui/material'
//icones react-icons
import { AiOutlineFileExcel } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'
import PermissionComponent from '../../../permissions'
import { ArrowBack, Cancel, ResetTvRounded } from '@mui/icons-material'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const ReportCountStockConsolid = () => {
  const { subCompany, userRolesNew, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const [selectedOrders, setSelectedOrders] = useState([])
  const [selectedOrdersIds, setSelectedOrdersIds] = useState([])
  const [orders, setOrders] = useState()
  const [loading, setLoading] = useState(false)
  const [orderSelect, setOrderSelect] = useState([])
  const token = localStorage.getItem('token')
  const [countStock, setCountStock] = useState([])
  const [pagination, setPagination] = useState({})
  const [listReportCount, setListReportCount] = useState([])
  const [countStockLimit, setCountStockLimit] = useState(10)
  const [countStockOrder, setCountStockOrder] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')

  // apagar daqui pra baixo se der erro e apagar o import xlsx

  const [progressCount, setProgressCount] = useState(0)
  const [downloadMessageCount, setDownloadMessageCount] = useState('Pronto')
  const [downloadingCount, setDownloadingCount] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [personName, setPersonName] = React.useState([])

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChange = (event, newValue) => {
    console.log(newValue, 'vekdad')
    setOrderSelect(newValue)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const downloadDataCount = async () => {
    setDownloadMessageCount('Inicializando')
    setDownloadingCount(true)
    finishDownloadCount(0)
  }

  const downloadDataCountList = async () => {
    setDownloadMessageCount('Inicializando')
    setDownloadingCount(true)
    finishDownloadCountList(listReportCount.length)
  }

  const finishDownloadCountList = async pages => {
    let result = []

    for (let i = 0; i <= pages; i++) {
      setDownloadMessageCount(`Baixando ${i} de ${pages}...`)
      setProgressCount((i / pages) * 100)
    }

    setDownloadMessageCount(`Seu arquivo está pronto.`)
    setDownloadingCount(false)
    const relatorioFinalContagem = []
    listReportCount.map(item => {
      relatorioFinalContagem.push({
        'Código ERP': item.idProductPartner,
        'Nome do Produto': item.productName,
        Quantidade: item.quantityTotal,
        // Cor: item.color,
        // Tamanho: item.size,
        // Referencia: item.reference,
        // 'Número do palete': item.numberPallet,
        // 'Quantidade de palete contato': item.quantityPallet,
        // Picking: item.picking,
        // Lote: item.lote,
        // Posição: item.locationName,
        CNPJ: item.subCompanyCNPJ,
        // 'Data da Leitura': new Date(item.createDate).toLocaleString(),
        // 'Data de Expiração': new Date(item.createDate).toLocaleString(),
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinalContagem)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório de contagem')
    XLSX.writeFile(workbook, `Relatório de contagem.xlsx`)
    setProgressCount(0)
  }

  const finishDownloadCount = async pages => {
    try {
      let result = []

      for (const order of orderSelect) {
        const countStockDTO = {
          subCompanyId: subCompanyId,
          movimentationOrderId: order.movimentationOrderId,
        }
        const resp = await getCountStockService(token, nameUrl, countStockDTO)
        const dataWithCodOrder = resp.data.map(item => ({
          ...item,
          codOrder: order.movimentationOrderNumberOtherSystem,
        }))
        result.push(...dataWithCodOrder)
      }

      const relatorioFinalContagem = result.map(item => {
        const hasPermission = userRolesNew.some(
          permission => permission.rolesName === 'Fields_Report_CountStock'
        )
        const nomeProduto = hasPermission ? item.productName.split(' - ')[0] : item.productName

        return {
          Pedido: item.codOrder,
          'Código ERP': item.idProductPartner,
          'Nome do Produto': nomeProduto,
          Quantidade: item.stockcount || '0',
          CNPJ: item.subCompanyCNPJ,
          Cor: item.color || '-',
          Tamanho: item.size || '-',
          'Código de barras': item.barCode || '0',
          Referencia: item.reference || '-',
          'Número do palete': item.numberPallet,
          'Quantidade de palete contato': item.quantityPallet,
          Lote: item.lote || '-',
          Posição: item.picking || '-',
          'Data da Leitura': new Date(item.createDate).toLocaleString(),
          'Data de Expiração': new Date(item.createDate).toLocaleString(),
        }
      })

      const workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.json_to_sheet(relatorioFinalContagem)
      XLSX.utils.book_append_sheet(workbook, worksheet, 'RelatorioContagem') // Nome encurtado
      XLSX.writeFile(workbook, `RelatorioConsolidacaoContagem.xlsx`)
    } catch (error) {
      console.log(error)
    } finally {
      setDownloadMessageCount(`Seu arquivo está pronto.`)
      setDownloadingCount(false)
      setProgressCount(0)
    }
  }

  const getCountStock = async () => {
    setListReportCount([])
    setPagination([])
    setCountStock([])
    setLoading(true)
    try {
      let consolidatedData = []
      let resume = []
      for (const order of orderSelect) {
        const countStockDTO = {
          subCompanyId: subCompanyId,
          movimentationOrderId: order.movimentationOrderId,
        }
        const response = await getCountStockService(token, nameUrl, countStockDTO)
        if (response.data.length === 0) {
          setTitle(
            `Não existem dados para a consulta da ordem: ${order.movimentationOrderNumberOtherSystem}.`
          )
          setAlerta(true)
          setSeverity('error')
        } else {
          const dataWithCodOrder = response.data.map(item => ({
            ...item,
            codOrder: order.movimentationOrderNumberOtherSystem,
          }))

          consolidatedData = [...consolidatedData, ...dataWithCodOrder]

          const productMapper = response.data.reduce((acc, curr) => {
            if (acc[curr.idProductPartner]) {
              acc[curr.idProductPartner].quantityTotal += curr.stockcount
            } else {
              acc[curr.idProductPartner] = { ...curr, quantityTotal: curr.stockcount }
            }
            return acc
          }, {})
          const listaFinal = Object.values(productMapper)
          resume = [...resume, ...listaFinal]
        }
      }
      console.log(resume, 'listaFinalta')

      setListReportCount(resume)
      setCountStock(consolidatedData)
      setPagination(consolidatedData)

      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  useEffect(() => {
    if (subCompanyId) {
      getOrders(subCompanyId)
    }
  }, [subCompanyId])

  const getOrders = async value => {
    setSubCompanyId(value)
    try {
      const response = await getOnlyOrder(token, nameUrl, value)
      setOrders(response.data)
    } catch (e) {
      console.log(e)
    }
  }

  const handleOrder = (event, newValue) => {
    setOrderSelect(newValue)

    if (newValue !== undefined && newValue !== null) {
      getOnlyOrder(token, nameUrl, newValue)
        .then(response => {
          setOrders(response.data)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  useEffect(() => {}, [countStockOrder])

  const handleClose = () => {
    setAlerta(false)
  }

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullScreen
        sx={{ m: 2 }}
      >
        <DialogTitle id="scroll-dialog-title">Relatório de contagem</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <TableContainer>
              <Box display="flex" alignItems="center" justifyContent="space-between" gap={2} my={2}>
                <Box display="flex" alignItems="center" justifyContent="start" gap={2} width="100%">
                  <Typography variant="h6" fontWeight={700}>
                    {`Total de produtos ${listReportCount.length}`}
                  </Typography>
                  <Typography variant="h6" fontWeight={700}>
                    {`Contagem total ${listReportCount.reduce(
                      (total, item) => total + item.quantityTotal,
                      0
                    )}`}
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: ' space-between',
                    fontSize: '.9em',
                    width: '150px',
                  }}
                  onClick={downloadDataCountList}
                >
                  Excel <AiOutlineFileExcel size="2em" />
                </Button>
              </Box>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Código ERP</StyledTableCell>
                    <StyledTableCell align="left">Nome do produto</StyledTableCell>
                    <StyledTableCell align="center">Quantidade total</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listReportCount
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="left">{row.idProductPartner}</StyledTableCell>
                        <StyledTableCell align="left">{row.productName}</StyledTableCell>
                        <StyledTableCell align="center">{row.quantityTotal}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={listReportCount.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ m: 2 }}>
          <Button variant="outlined" startIcon={<ArrowBack />} onClick={() => setOpenDialog(false)}>
            Voltar
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={downloadingCount}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessageCount}</DialogTitle>
            <LinearProgressWithLabel value={progressCount} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <ViewElearning
          pageName={'ReportCountStock'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Relatório de consolidação de Contagem</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon onClick={() => setViewElearning(true)} />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 2,
            mt: 2,
            gap: 2,
          }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box width={{ xs: '100%', sm: '100%', md: '50%', lg: '50%', xl: '50%' }}>
            <SelectSubCompany
              value={subCompanyId}
              onChange={e => {
                getOrders(e.target.value)
              }}
            />
          </Box>
          <Box width={{ xs: '100%', sm: '100%', md: '50%', lg: '50%', xl: '50%' }}>
            {orders && (
              <>
                <InputLabel id="demo-simple-select-label">Pedido</InputLabel>
                <Autocomplete
                  multiple
                  value={orderSelect}
                  onChange={handleChange}
                  options={orders}
                  noOptionsText={'Nenhum resultado encontrado'}
                  getOptionLabel={option => option.movimentationOrderNumberOtherSystem || ''}
                  renderInput={params => <TextField {...params} />}
                />
              </>
            )}
          </Box>
        </Box>
        <Button variant="contained" onClick={getCountStock} disabled={!selectedOrders}>
          Pesquisar
        </Button>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            mb: 2,
            width: '100%',
          }}
        >
          {countStock.length > 0 && (
            <Box width="100%" display="flex" alignItems="center" justifyContent="end" gap={2}>
              <Button
                disabled={countStock.length < 1}
                variant="contained"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: ' space-between',
                  fontSize: '.9em',
                  width: '150px',
                }}
                onClick={() => setOpenDialog(true)}
              >
                Resumo <ResetTvRounded size="2em" />
              </Button>
              <Button
                variant="contained"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: ' space-between',
                  fontSize: '.9em',
                  width: '150px',
                }}
                onClick={downloadDataCount}
              >
                Excel <AiOutlineFileExcel size="2em" />
              </Button>
            </Box>
          )}
        </Box>
        {countStock.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Typography variant="h6">
              {countStock.length > 1
                ? `${countStock.length} Resultados`
                : `${countStock.length} Resultado`}
            </Typography>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left"> Pedido</StyledTableCell>
                  <StyledTableCell align="left"> Código ERP</StyledTableCell>
                  <StyledTableCell align="left">Nome do Produto</StyledTableCell>
                  <PermissionComponent role="Fields_Report_CountStock">
                    <StyledTableCell align="left">Codigo de barras</StyledTableCell>
                  </PermissionComponent>
                  <StyledTableCell align="left">Quantidade</StyledTableCell>
                  <PermissionComponent role="FIELD_COLOR">
                    <StyledTableCell align="left">Cor</StyledTableCell>
                  </PermissionComponent>
                  <PermissionComponent role="FIELD_SIZE">
                    <StyledTableCell align="left">Tamanho</StyledTableCell>
                  </PermissionComponent>
                  <PermissionComponent role="FIELD_REFERENCE">
                    <StyledTableCell align="left">Referência</StyledTableCell>
                  </PermissionComponent>
                  <PermissionComponent role="FIELD_NUMBERPALLET">
                    <StyledTableCell align="left">Número do palete</StyledTableCell>
                  </PermissionComponent>
                  <PermissionComponent role="FIELD_QUANTITYPALLET">
                    <StyledTableCell align="left">Quantidade de palete contados</StyledTableCell>
                  </PermissionComponent>
                  <PermissionComponent role="FIELD_PICKING">
                    <StyledTableCell align="left">Picking</StyledTableCell>
                  </PermissionComponent>
                  <PermissionComponent role="FIELD_LOTE">
                    <StyledTableCell align="left">Lote</StyledTableCell>
                  </PermissionComponent>
                  <PermissionComponent role="FIELD_LOCALNAME">
                    <StyledTableCell align="left">Posição</StyledTableCell>
                  </PermissionComponent>
                  <StyledTableCell align="left">CNPJ</StyledTableCell>
                  <StyledTableCell align="left">Data da Leitura</StyledTableCell>
                  <PermissionComponent role="FIELD_DATAEXPIRATOR">
                    <StyledTableCell align="left">Data de Expiração</StyledTableCell>
                  </PermissionComponent>
                  <PermissionComponent role="FIELD_DIVERGENCE">
                    <StyledTableCell align="left">Divergência</StyledTableCell>
                  </PermissionComponent>
                </TableRow>
              </TableHead>
              <TableBody>
                {countStock.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell align="left">{row.codOrder}</StyledTableCell>
                    <StyledTableCell align="left">{row.idProductPartner}</StyledTableCell>
                    <PermissionComponent role="Fields_Report_CountStock">
                      <StyledTableCell align="left">
                        {row.productName.split(' - ')[0]}
                      </StyledTableCell>
                    </PermissionComponent>
                    <PermissionComponent role="Fields_Report_CountStock">
                      <StyledTableCell align="left">{row.barCode}</StyledTableCell>
                    </PermissionComponent>
                    <PermissionComponent
                      role={userRolesNew.some(
                        permission => permission.rolesName === 'Fields_Report_CountStock'
                      )}
                    >
                      <StyledTableCell align="left">{row.productName}</StyledTableCell>
                    </PermissionComponent>

                    <StyledTableCell align="left">{row.stockcount || 0}</StyledTableCell>
                    <PermissionComponent role="FIELD_COLOR">
                      <StyledTableCell align="left">{row.color || 0}</StyledTableCell>
                    </PermissionComponent>
                    <PermissionComponent role="FIELD_SIZE">
                      <StyledTableCell align="left">{row.size}</StyledTableCell>
                    </PermissionComponent>
                    <PermissionComponent role="FIELD_REFERENCE">
                      <StyledTableCell align="left">{row.reference || 0}</StyledTableCell>
                    </PermissionComponent>
                    <PermissionComponent role="FIELD_NUMBERPALLET">
                      <StyledTableCell align="left">{row.numberPallet}</StyledTableCell>
                    </PermissionComponent>
                    <PermissionComponent role="FIELD_QUANTITYPALLET">
                      <StyledTableCell align="left">{row.quantityPallet || 0}</StyledTableCell>
                    </PermissionComponent>
                    <PermissionComponent role="FIELD_PICKING">
                      <StyledTableCell align="left">{row.picking || 0}</StyledTableCell>
                    </PermissionComponent>
                    <PermissionComponent role="FIELD_LOTE">
                      <StyledTableCell align="left">{row.lote}</StyledTableCell>
                    </PermissionComponent>
                    <PermissionComponent role="FIELD_LOCALNAME">
                      <StyledTableCell align="left">{row.locationName}</StyledTableCell>
                    </PermissionComponent>
                    <StyledTableCell align="left">{row.subCompanyCNPJ}</StyledTableCell>
                    <StyledTableCell align="left">
                      {new Date(row.createDate).toLocaleString() ||
                        new Date(row.createDate).toLocaleString()}{' '}
                    </StyledTableCell>
                    <PermissionComponent role="FIELD_DATAEXPIRATOR">
                      <StyledTableCell align="left">
                        {new Date(row.dataExpiration).toLocaleString() ||
                          new Date(row.dataExpiration).toLocaleString()}
                      </StyledTableCell>
                    </PermissionComponent>
                    <PermissionComponent role="FIELD_DIVERGENCE">
                      <StyledTableCell align="left">
                        {row.quantityERP - row.stockcount > 0 ? (
                          <Chip
                            label="Divergente"
                            style={{
                              background: '#FEF08A',
                              color: '#A26308',
                              borderRadius: '5px',
                            }}
                          />
                        ) : row.quantityERP - row.stockcount < 0 ? (
                          <Chip
                            label="Divergente"
                            style={{
                              background: '#FEE2E2',
                              color: '#B91C1C',
                              borderRadius: '5px',
                            }}
                          />
                        ) : (
                          row.quantityERP - row.stockcount === 0 && (
                            <Chip
                              label="OK"
                              style={{
                                background: '#F0FDF4',
                                color: '#3EB469',
                                borderRadius: '5px',
                              }}
                            />
                          )
                        )}
                      </StyledTableCell>
                    </PermissionComponent>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={countStock.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ReportCountStockConsolid
