import React, { useContext } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { setPermission } from '../../../permissions/userPermissions'
import { TagLogisticCreate, TagLogistic } from '../../../const/RoleConstants'
import { Context } from '../../../context/AuthContext'
import HelpIcon from '@mui/icons-material/Help'
import { getPrinterZplService } from '../../../services/configServices'
import {
  Box,
  Chip,
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  Paper,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
  Tooltip,
  MenuItem,
  Select,
  InputLabel,
  Grid,
  Checkbox,
  TextField,
} from '@mui/material'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'

import ViewElearning from '../../ViewElearning'
import { useParams } from 'react-router-dom'
import { Visibility } from '@mui/icons-material'
import { getOrderItemPaginationService } from '../../../services/productService'
import PrintTagSerialCreate from '../../Create/PrintTagSerialCreate'
import { colorTableDefault } from '../../TemplateDefault'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const PrintTagSerialId = () => {
  const { userRoles, nameUrl, subCompany } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const params = useParams()
  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [labelOrderPrint, setLabelOrderPrint] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [searching, setSearching] = useState(false)

  const token = localStorage.getItem('token')
  const [printerZpl, setPrinterZpl] = useState([])
  const [serialProduct, setSerialProduct] = useState('')
  const [productName, setProductName] = useState('')
  const [movimentationOrderItemId, setMovimentationOrderItensId] = useState('')
  const [movimentationOrderId, setMovimentationOrderId] = useState('')
  const [productItensId, setProductItensId] = useState('')
  const [filtered, setFiltered] = useState([])

  const [pagination, setPagination] = useState({})
  const [printerZplLimit, setPrinterZplLimit] = useState(10)
  const [printerZplOffset, setPrinterZplOffset] = useState(0)
  const [printerZplOrder, setPrinterZplOrder] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(true)
  const [viewElearning, setViewElearning] = useState(false)
  const [permissionTagLogisticView, setPermissionTagLogisticView] = useState(false)
  const [permissionTagLogisticCreate, setPermissionTagLogisticCreate] = useState(false)
  const [reloadItems, setReloadItems] = useState(false)
  const [zpl, setZpl] = useState('')
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])
  const [isParentCheckboxChecked, setIsParentCheckboxChecked] = useState(false)
  const [textResult, setTextResult] = useState('')
  const [allSelectedIds, setAllSelectedIds] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0) // Add this state

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const customFields = [
    { positionTag: '54,119', positionProd: '3,14', positionSeria: '4,27' },
    { positionTag: '246,119', positionProd: '195,14', positionSeria: '195,27' },
    { positionTag: '446,119', positionProd: '395,14', positionSeria: '495,27' },
  ]

  const handleToggleParentCheckbox = () => {
    const newSelected = isParentCheckboxChecked ? [] : labelOrderPrint.map(row => row.serial)
    console.log(newSelected, 'sads')
    setSelectedCheckboxes(newSelected)
    setIsParentCheckboxChecked(!isParentCheckboxChecked)
    updateTextResult(newSelected.length)

    if (!isParentCheckboxChecked) {
      setSelectedItems([
        ...labelOrderPrint.map((row, index) => {
          const updatedRow = { ...row }
          const fieldIndex = index % customFields.length
          if (customFields[fieldIndex]) {
            updatedRow.positionTag = customFields[fieldIndex].positionTag
            updatedRow.positionProd = customFields[fieldIndex].positionProd
            updatedRow.positionSeria = customFields[fieldIndex].positionSeria
          }
          return updatedRow
        }),
      ])
    } else {
      setSelectedItems([])
    }
  }

  const handleUncheckCheckbox = id => {
    const newSelectedCheckboxes = selectedCheckboxes.filter(checkboxId => checkboxId !== id)
    setSelectedCheckboxes(newSelectedCheckboxes)
    setIsParentCheckboxChecked(false) // Update the parent checkbox status if needed
    updateTextResult(newSelectedCheckboxes.length)

    // Remove the item from the selectedItems state as well
    const updatedSelectedItems = selectedItems.filter(item => item.serial !== id)
    setSelectedItems(updatedSelectedItems)
  }

  const handleToggleChildCheckbox = id => {
    const currentIndex = selectedCheckboxes.indexOf(id)
    const newSelected = [...selectedCheckboxes]

    if (currentIndex === -1) {
      newSelected.push(id)
    } else {
      newSelected.splice(currentIndex, 1)
    }

    setSelectedCheckboxes(newSelected)

    // Create a new array to hold the selected items
    const newSelectedItems = []

    // Iterate over the selected checkboxes and add the corresponding item from labelOrderPrint
    newSelected.forEach(selectedId => {
      const selectedRow = labelOrderPrint.find(row => row.serial === selectedId)
      if (selectedRow) {
        // Check if the fields exist before accessing them
        const newIndex = newSelected.indexOf(selectedId) % customFields.length
        const updatedRow = { ...selectedRow }
        if (customFields[newIndex]) {
          updatedRow.positionTag = customFields[newIndex].positionTag
          updatedRow.positionProd = customFields[newIndex].positionProd
          updatedRow.positionSeria = customFields[newIndex].positionSeria
        }
        newSelectedItems.push(updatedRow)
      }
    })

    setSelectedItems(newSelectedItems)
    setIsParentCheckboxChecked(newSelected.length === labelOrderPrint.length)
    updateTextResult(newSelected.length)
  }

  const updateTextResult = count => {
    if (count === 0) {
      setTextResult('')
    } else if (count === 1) {
      setTextResult('1 Resultado selecionado')
    } else {
      setTextResult(`${count} Resultados selecionados`)
    }
  }

  const collectSelectedIds = () => {
    let selectedIds = []

    selectedIds = [...selectedCheckboxes]

    if (isParentCheckboxChecked) {
      const parentIds = labelOrderPrint.map(row => row.serial)

      const uniqueParentIds = parentIds.filter(id => !selectedIds.includes(id))

      selectedIds = [...selectedIds, ...uniqueParentIds]
    }

    setAllSelectedIds(selectedIds)
  }

  useEffect(() => {
    collectSelectedIds()
  }, [selectedCheckboxes, isParentCheckboxChecked])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getPermissions = useCallback(async () => {
    setPermissionTagLogisticView(setPermission(userRoles, TagLogistic))
    setPermissionTagLogisticCreate(setPermission(userRoles, TagLogisticCreate))
  }, [])

  const getPrinterZpl = async () => {
    setLoading(true)
    try {
      const response = await getPrinterZplService(
        token,
        nameUrl,
        subCompanyId,
        printerZplLimit,
        printerZplOffset,
        printerZplOrder,
        ''
      )
      setPrinterZpl(response.data)
      setPagination(response.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (subCompanyId) {
      getPrinterZpl()
    }
  }, [printerZplOrder, subCompanyId])

  const handleFetchData = async value => {
    setSearching(true)

    let valueFetch = params.movimentationOrderId

    if (reloadItems) {
      valueFetch = value
    }

    try {
      const response = await getOrderItemPaginationService(token, nameUrl, valueFetch)
      setLabelOrderPrint(response.data.result)
      setFiltered(response.data.result)

      setPage(0)
      setSearching(false)
    } catch (error) {}
  }

  useEffect(() => {
    getPermissions()
    handleFetchData()
  }, [zpl])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handlePrintTag = async row => {
    console.log(row, ' rowrow')
    setProductName(row.productName)
    setSerialProduct(row.serial)
    setMovimentationOrderItensId(row.movimentationOrderItemId)
    setMovimentationOrderId(row.movimentationOrderId)
    setProductItensId(row.productItensId)
    setLoading(true)

    await new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, 500)
    })

    setLoading(false)
  }

  const requestSearch = searched => {
    const bihandsArray = filtered.filter(item =>
      item.movimentationOrderNumberBihands.includes(searched)
    )
    const otherArray = filtered.filter(item =>
      item.movimentationOrderNumberOtherSystem.includes(searched)
    )
    const serial = filtered.filter(item => item.serial.includes(searched))

    const combinedArray = [...bihandsArray, ...otherArray, ...serial]
    setLabelOrderPrint(combinedArray)
  }

  return (
    <>
      {permissionTagLogisticView && (
        <Box sx={{ mt: 7.5 }}>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={alerta}
            onClose={handleClose}
            key={vertical + horizontal}
          >
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
              {title}
            </Alert>
          </Snackbar>
          <ViewElearning pageName={'PrinterZpl'} open={viewElearning} setOpen={setViewElearning} />
          <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h5">Imprimir Etiqueta de Produto</Typography>
            <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
              <HelpIcon />
            </IconButton>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectSubCompany
                value={subCompanyId}
                onChange={e => {
                  setSubCompanyId(e.target.value)
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Box width="100%">
                <InputLabel id="demo-simple-select-label">Etiqueta</InputLabel>
                <Select
                  value={zpl}
                  sx={{ width: '100%' }}
                  onChange={values => {
                    setZpl(values.target.value)
                  }}
                >
                  {printerZpl.map((item, index) => {
                    return (
                      <MenuItem value={item.zpl} key={index}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Box>
            </Grid>
          </Grid>

          <Box mt={2}>
            <TextField
              variant="outlined"
              placeholder="Pesquisar"
              type="search"
              value={searchTerm}
              onChange={e => {
                requestSearch(e.target.value)
                setSearchTerm(e.target.value)
              }}
            />
          </Box>
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Box display="flex" mb={2}>
              {labelOrderPrint.length > 0 && (
                <Typography mr={2}>
                  {textResult === 0 && labelOrderPrint.length > 0
                    ? `${labelOrderPrint.length} Resultados`
                    : `${labelOrderPrint.length} Resultado`}
                </Typography>
              )}
              {labelOrderPrint.length > 0 && <Typography>{textResult}</Typography>}
            </Box>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    <Checkbox
                      disabled={!zpl}
                      checked={isParentCheckboxChecked}
                      onChange={handleToggleParentCheckbox}
                    />
                  </StyledTableCell>
                  <StyledTableCell> </StyledTableCell>
                  <StyledTableCell align="left">Ordem Bihands</StyledTableCell>
                  <StyledTableCell align="left">Ordem (outro sistema)</StyledTableCell>
                  <StyledTableCell align="left">Codigo do Produto</StyledTableCell>
                  <StyledTableCell align="left">Nome do Produto</StyledTableCell>
                  <StyledTableCell align="left">Preço</StyledTableCell>
                  <StyledTableCell align="left">Serial</StyledTableCell>
                  <StyledTableCell align="left">Quantidade</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="left">Criado</StyledTableCell>
                </TableRow>
              </TableHead>
              {labelOrderPrint.length > 0 && (
                <TableBody>
                  {labelOrderPrint.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        <Checkbox
                          disabled={!zpl}
                          checked={selectedCheckboxes.includes(row.serial)}
                          onChange={() => {
                            if (selectedCheckboxes.includes(row.serial)) {
                              handleUncheckCheckbox(row.serial)
                            } else {
                              handleToggleChildCheckbox(row.serial)
                            }
                          }}
                          sx={{
                            color: selectedCheckboxes.includes(row.serial)
                              ? colorTableDefault + '!Important'
                              : 'inherit',
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'left',
                            justifyContent: 'space-between',
                            width: '80px',
                          }}
                        >
                          <Tooltip title="Gerar Etiqueta" arrow>
                            <IconButton
                              disabled={!selectedCheckboxes.includes(row.serial)}
                              size="1.5em"
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                handlePrintTag(row)
                              }}
                            >
                              <Visibility />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.movimentationOrderNumberBihands}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.movimentationOrderNumberOtherSystem}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.idProductPartner}</StyledTableCell>
                      <StyledTableCell align="left">{row.productName}</StyledTableCell>
                      <StyledTableCell align="left">{row.price}</StyledTableCell>
                      <StyledTableCell align="left">{row.serial}</StyledTableCell>
                      <StyledTableCell align="left">{row.quantity}</StyledTableCell>
                      <StyledTableCell align="center">
                        {row.reprint > 0 ? (
                          <Chip label="Etiqueta já impressa" color="success" />
                        ) : (
                          <Chip label="Falta impressão" color="warning" />
                        )}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {new Date(row.createDate).toLocaleString()}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={labelOrderPrint.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
          {permissionTagLogisticCreate && (
            <PrintTagSerialCreate
              setExpanded={setExpanded}
              setAlerta2={setAlerta}
              setSeverity2={setSeverity}
              setTitle2={setTitle}
              zplId={zpl}
              fetch={handleFetchData}
              setReloadItems={setReloadItems}
              setMvimentationOrderId={movimentationOrderId}
              productName={productName}
              serialProduct={serialProduct}
              productItensId={productItensId}
              movimentationOrderItemId={movimentationOrderItemId}
              allSelectedIds={selectedItems}
            />
          )}
          <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}
    </>
  )
}

export default PrintTagSerialId
