import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Select, InputLabel, MenuItem } from '@mui/material'

const GraficStoreRouterIntinerary = ({ totalStoreRouterIntinerary }) => {
  const [selectValue, setSelectValue] = useState('Todos')
  const [data, setData] = useState([])
  const [isShowTop10, setIsShowTop10] = useState(false)

  useEffect(() => {
    if (!totalStoreRouterIntinerary || totalStoreRouterIntinerary.length === 0) {
      return
    }
    if (totalStoreRouterIntinerary.length > 0 && selectValue === '') {
      setSelectValue('Todos')
    }
  }, [totalStoreRouterIntinerary, selectValue])

  useEffect(() => {
    handleFilter()
  }, [selectValue])

  const handleFilter = () => {
    let filter

    if (selectValue === 'Todos') {
      filter = totalStoreRouterIntinerary.slice(0, 10)
      setIsShowTop10(true)
    } else {
      const uniqueItems = Array.from(
        new Set(totalStoreRouterIntinerary.map(item => item.name))
      ).map(name => totalStoreRouterIntinerary.find(item => item.name === name))

      filter = uniqueItems.filter(item => item.name === selectValue)
      setIsShowTop10(false)
    }

    setData(filter)
  }

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: isShowTop10 ? '80%' : '50%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        const value = val + '%'
        return value
      },
      offsetX: 0,
      offsetY: 0,

      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },
    stroke: {
      show: true,
      width: 4,
      colors: ['transparent'],
    },
    xaxis: {
      categories: isShowTop10
        ? totalStoreRouterIntinerary.slice(0, 10).map(item => item.name)
        : data.map(item => item.name),
    },
    yaxis: {},
    fill: {
      opacity: 1,
    },
    tooltip: {
      shared: true,
      intersect: false,

      y: {
        formatter: function (val) {
          return val + '%'
        },
      },
    },
  }

  const series = [
    {
      name: 'Lojas que faz visita',
      data: isShowTop10
        ? totalStoreRouterIntinerary.slice(0, 10).map(item => item.visity)
        : data.map(item => item.visity),
    },
    {
      name: 'Lojas que não faz visita',
      data: isShowTop10
        ? totalStoreRouterIntinerary.slice(0, 10).map(item => item.visityNot)
        : data.map(item => item.visityNot),
    },
  ]

  return (
    <div>
      <div>
        <InputLabel id="demo-simple-select-label">Selecione a cidade</InputLabel>
        <Select
          size="small"
          sx={{ width: '50%' }}
          label="Escolher a loja"
          value={selectValue}
          onChange={e => setSelectValue(e.target.value)}
        >
          <MenuItem value="Todos">Todos</MenuItem>
          {[...new Set(totalStoreRouterIntinerary.map(item => item.name))].map((name, index) => (
            <MenuItem value={name} key={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div id="grafico">
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  )
}

export default GraficStoreRouterIntinerary
