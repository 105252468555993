import axios from 'axios'

export const createFaction = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Faction/Create`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//get
export const getFaction = async (token, nameUrl, item) => {
  let orderToString
  if (item.order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/Faction/GetAll?CompanyId=${item.companyId}&limit=${item.limit}&offset=${item.offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  console.log(response, 'reporte')

  return response
}

//get faccao especifica
export const getFactionId = async (token, nameUrl, id) => {
  const response = await axios.get(`/${nameUrl}/api/Faction/Get/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//delete
export const deleteFaction = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/Faction/Delete`,
    {
      factionId: data,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//edit
export const editFaction = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Faction/Edit`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}
