import HelpIcon from '@mui/icons-material/Help'
import React, { useContext, useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import { Context } from '../../../context/AuthContext'

import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'

//icones react-icons
import { AiOutlineFileExcel } from 'react-icons/ai'
import '../../../assets/global.css'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import { getValidationandLote } from '../../../services/reportServices'
import {
  getProductAssociationService,
  searchProductService,
} from '../../../services/productService'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const ReportLoteProduct = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const token = localStorage.getItem('token')
  const [loteReport, setLoteReport] = useState(false)
  const [limit, setLimit] = useState('500')
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)

  const [totalPagesDownload, setTotalPagesDownload] = useState('')
  const [progress, setProgress] = useState(0)
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [viewElearning, setViewElearning] = useState(false)
  const [filterProduct, setFilterProduct] = useState('')
  const [backupData, setBackupData] = useState([])

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [listProducts, setListProducts] = useState([])
  const [productSelected, setProductSelected] = useState()
  const [viewAlocation, setViewAlocation] = useState(false)
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)
  const [produtos, setProdutos] = useState([])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const fetchData = async e => {
    setLoteReport([])
    setBackupData([])
    e.preventDefault()
    setLoading(true)
    const dados = {
      limit: 500,
      offset: 0,
      Order: 'desc',
      SubCompanyId: subCompanyId,
      ProductId: productSelected,
    }
    try {
      const response = await getValidationandLote(token, nameUrl, dados)
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setLoteReport(response.data.result)
        setBackupData(response.data.result)
        setPageCount(response.data.totalpage + 1)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const handleGetProducts = async id => {
    setLoading(true)
    setSubCompanyId(id)
    try {
      const response = await getProductAssociationService(token, nameUrl, 100, 0, 'desc', id)
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setListProducts(response.data.result)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (subCompanyId) {
      handleGetProducts(subCompanyId)
    }
  }, [subCompanyId])

  const downloadData = async () => {
    setDownloadMessage('Inicializando')
    setDownloading(true)
    const dados = {
      limit: parseInt(limit),
      offset: 0,
      Order: 'desc',
      SubCompanyId: subCompanyId,
      ProductId: productSelected,
    }
    await getValidationandLote(token, nameUrl, dados).then(r => {
      setTotalPagesDownload(r.data.totalpage + 1)
      finishDownload(r.data.totalpage + 1)
    })
  }

  const finishDownload = async pages => {
    let result = []

    for (let i = 0; i <= pages; i++) {
      setDownloadMessage(`Baixando ${i} de ${pages + 1}...`)
      setProgress((i / pages) * 100)
      const dados = {
        limit: parseInt(limit),
        offset: i,
        Order: 'desc',
        SubCompanyId: subCompanyId,
        ProductId: productSelected,
      }
      const response = await getValidationandLote(token, nameUrl, dados)
      result = result.concat(response.data.result)
    }
    setDownloadMessage(`Seu arquivo está pronto.`)
    setDownloading(false)
    const relatorioFinal = []
    result.map(item => {
      relatorioFinal.push({
        'Data Requisição': item.requestDate ? new Date(item.requestDate).toLocaleString() : '',
        Nome: item.productName,
        Lote: item.lote,
        Palete: item.numberPallet,
        'Posição de baixa': item.picking,
        Quantidade: item.quantitys,
        'Data Validade': new Date(item.dataExpiration).toLocaleString(),
        'Dias Restantes': new Date(item.dataExpiration).toLocaleDateString(),
        Alocado: item.nameAlocation,
        Depósito: item.depositName,
        Picking: item.picking,
        Serial: item.serial,
        'Código de Barras': item.barCode,
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Lote Por produto')
    XLSX.writeFile(workbook, `Lote Por produto.xlsx`)
    setProgress(0)
  }

  const handleClose = () => {
    setAlerta(false)
  }

  const handleCalcVal = (created, expiration) => {
    const dif = new Date(expiration).getTime() - new Date(created).getTime()
    const difDays = Math.round(dif / (1000 * 60 * 60 * 24))
    return difDays
  }

  // useEffect(() => {
  //   const handleFilter = () => {
  //     if (filterProduct !== '') {
  //       setNotSearch(false)
  //       setFilteringProduct(true)
  //       const newData = backupData
  //       const filter = newData.filter(data =>
  //         data.productName.toLowerCase().includes(filterProduct.toLowerCase())
  //       )
  //       setFilteringProduct(false)
  //       if (filter.length > 0) {
  //         setLoteReport(filter)
  //       } else {
  //         setNotSearch(true)
  //       }
  //     } else setLoteReport(backupData)
  //   }
  //   handleFilter()
  // }, [filterProduct])

  const handleClearFilter = () => {
    setFilterProduct('')
    setLoteReport(backupData)
  }

  const handleChangeView = () => {
    setViewAlocation(!viewAlocation)
    const confirm = !viewAlocation
    let backup = []
    if (confirm) {
      backup = loteReport.filter(item => item.nameAlocation !== '')
      setLoteReport([])
      setPage(0)
      setTimeout(() => {
        setLoteReport(backup)
      }, 1)
    } else {
      setLoteReport(backupData)
      setPage(0)
    }
  }

  useEffect(() => {
    if (search === '') {
      const checagem = setTimeout(() => {
        setProdutos([{ label: '', value: '' }])
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearching(true)
      const checagem = setTimeout(() => {
        const dados = {
          companyId: subCompany[0].companyId,
          SearchWord: search,
          limit: 10,
          offset: 0,
        }
        searchProductService(token, nameUrl, dados).then(response => {
          const prodsFinal = []
          response.data.result.map(produto => {
            const obj = produto
            obj.value = produto.productId
            obj.label = `${produto.name}`
            prodsFinal.push(obj)
          })
          setProdutos(prodsFinal)
          setSearching(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [search])

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog open={downloading} keepMounted aria-describedby="alert-dialog-slide-description">
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessage}</DialogTitle>
            <LinearProgressWithLabel value={progress} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <ViewElearning pageName={'LoteReport'} open={viewElearning} setOpen={setViewElearning} />
          <Typography variant="h5">Relatório de Lote Por produto</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box component="form" onSubmit={fetchData}>
          <Box
            sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <SelectSubCompany
                value={subCompanyId}
                onChange={e => {
                  handleGetProducts(e.target.value)
                }}
              />
            </Box>
            {listProducts && (
              <Box
                width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
                sx={{ mt: 2 }}
              >
                <InputLabel id="demo-simple-select-label">Produto</InputLabel>

                <Autocomplete
                  disablePortal
                  id="lista-produtos"
                  options={produtos}
                  autoHighlight
                  autoSelect
                  //sx={{ width: 300 }}
                  loading={searching}
                  loadingText="Procurando..."
                  noOptionsText="Nenhum produto"
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.productId}>
                        {option.label}
                      </li>
                    )
                  }}
                  renderInput={params => <TextField {...params} label="Procurar produto" />}
                  onInputChange={(e, v) => {
                    setSearch(v)
                  }}
                  //onChange={(e, v) => setProductId(v)}
                  onChange={(e, v) => {
                    if (v === null) {
                      setProductSelected()
                    } else {
                      setProductSelected(v.productId)
                    }
                  }}
                />
              </Box>
            )}
          </Box>
          <Button variant="contained" type="submit" disabled={productSelected ? false : true}>
            Procurar
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            mb: 2,
          }}
        >
          <Typography variant="h6"></Typography>
          {loteReport.length > 0 && (
            <Button
              variant="contained"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: ' space-between',
                fontSize: '.9em',
                width: '6.5rem',
              }}
              onClick={downloadData}
            >
              Excel <AiOutlineFileExcel size="2em" />
            </Button>
          )}
        </Box>
        {loteReport && (
          <>
            <Box>
              <FormControlLabel
                control={<Switch checked={viewAlocation} />}
                label="Mostrar só itens que tem alocação"
                onClick={() => handleChangeView()}
              />
            </Box>
            <TableContainer component={Paper} sx={{ mt: '2rem' }}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Data Requisição</StyledTableCell>
                    <StyledTableCell align="left">Nome</StyledTableCell>
                    <StyledTableCell align="left">Lote</StyledTableCell>
                    <StyledTableCell align="left">Palete</StyledTableCell>
                    <StyledTableCell align="left">Posição de baixa</StyledTableCell>
                    <StyledTableCell align="left">Quantidade</StyledTableCell>
                    <StyledTableCell align="left">Data Validade</StyledTableCell>
                    <StyledTableCell align="left">Dias Restantes</StyledTableCell>
                    <StyledTableCell align="left">Alocado</StyledTableCell>
                    <StyledTableCell align="left">Depósito</StyledTableCell>
                    <StyledTableCell align="left">Picking</StyledTableCell>
                    <StyledTableCell align="left">Serial</StyledTableCell>
                    <StyledTableCell align="left">Código de Barras</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loteReport
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="left">
                          {row.requestDate ? new Date(row.requestDate).toLocaleString() : ''}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.idProductPartner + ' - ' + row.productName}
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.lote}</StyledTableCell>
                        <StyledTableCell align="left">{row.numberPallet}</StyledTableCell>
                        <StyledTableCell align="left">{row.picking}</StyledTableCell>
                        <StyledTableCell align="left">{row.quantitys}</StyledTableCell>
                        <StyledTableCell align="left">
                          {new Date(row.dataExpiration).toLocaleDateString()}
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.daysleft}</StyledTableCell>
                        <StyledTableCell align="left">{row.nameAlocation}</StyledTableCell>
                        <StyledTableCell align="left">{row.depositName}</StyledTableCell>
                        <StyledTableCell align="left">{row.picking}</StyledTableCell>
                        <StyledTableCell align="left">{row.serial}</StyledTableCell>
                        <StyledTableCell align="left">{row.barCode}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={loteReport.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ReportLoteProduct
