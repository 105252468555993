import axios from 'axios'

//criação
export const createEmailGroup = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/EmailGroup/Create`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createAddUSer = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/EmailGroup/AddUserBuck`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getUserGroupEmail = async (token, nameUrl, data) => {
  try {
    const response = await axios.get(
      `/${nameUrl}/api/Employee/GetEmployeeWithSubCompanyAll?SubCompanyId=${data.subCompanyId}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    )

    const uniqueUsersMap = new Map()
    response.data.forEach(user => {
      uniqueUsersMap.set(user.userId, user)
    })

    const uniqueUsersList = Array.from(uniqueUsersMap.values())

    return uniqueUsersList
  } catch (error) {
    throw error
  }
}

export const getUserGroupEmailService = async (token, nameUrl, data) => {
  let orderToString
  if (data.order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }
  const response = await axios.get(
    `/${nameUrl}/api/EmailGroup/GetAll?SubCompanyId=${data.subCompanyId}&limit=${data.limit}&offset=${data.offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  return response
}

export const getEmailGroupService = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Employee/GetEmployeeWithSubCompanyAll?SubCompanyId=${data.subCompanyId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//consulta id
export const getEmailGroupId = async (token, nameUrl, data) => {
  const response = await axios.get(`/${nameUrl}/api/EmailGroup/Get/${data}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}
//criação sequence
export const createEmailGroupSequence = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/EmailGroup/AddUser`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//consulta sequence id
export const getEmailGroupSequenceId = async (token, nameUrl, data) => {
  const response = await axios.get(`/${nameUrl}/api/ProcessSequence/Get/${data}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//editar
export const editEmailGroup = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/EmailGroup/Edit`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//deletar userEmailGroup
export const deleteUserGroupEmail = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/EmailGroup/DeleteEmployeGroup`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//deletar
export const deleteEmailGroup = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/EmailGroup/DeleteGroup`,
    { emailGroupId: data },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}
