import axios from 'axios'

export const getAllMovimentationOrderRouteService = async (token, nameUrl, data) => {
  console.log(
    `/${nameUrl}/api/MovimentationOrderRoute/GetAllSubCompany?SubCompanyId=${data.subCompanyId}&limit=${data.limit}&offset=${data.offset}`
  )
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderRoute/GetAllSubCompany?SubCompanyId=${data.subCompanyId}&limit=${data.limit}&offset=${data.offset}`,

    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getAllOrdersService = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderRoute/Order_GetAll?SubCompanyId=${data}&limit=10000&offset=0&Order=desc`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getAllDriverMovimentationOrderRouteService = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderRoute/GetAllDriver?SubCompanyId=${data.subCompanyId}&UserDriverId=${data.userDriverId}&limit=${data.limit}&offset=${data.offset}`,

    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getMovimentationOrderRouteService = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderRoute/Get/${data.routeId}`,

    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const createMovimentationOrderRouteService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/MovimentationOrderRoute/Create`, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}

export const createOrderRouteService = async (token, nameUrl, data) => {
  console.log(data)
  const response = await axios.post(`/${nameUrl}/api/MovimentationOrderRoute/Order_Create`, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}

export const deleteMovimentationOrderRouteService = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrderRoute/Delete`,
    { movimentationOrderRouteId: data },

    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getDriverListService = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderRoute/GetDriver?SubCompanyId=${data}`,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}

export const getSingleMovRouteService = async (token, nameUrl, id) => {
  console.log(
    `/${nameUrl}/api/MovimentationOrderRoute/Order_Get?MovimentationOrderId=${id}`,
    'responseid'
  )

  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderRoute/Order_Get?MovimentationOrderId=${id}`,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}

export const editMovRouteService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/MovimentationOrderRoute/Edit`, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}

export const releaseMovRouteService = async (token, nameUrl, movimentationOrderRouteId) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderRoute/Release?MovimentationOrderRouteId=${movimentationOrderRouteId}`,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}

export const deleteMovOrderRouteService = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderRoute/Order_Delete?MovimentationOrderId=${data}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getAllNFService = async (token, nameUrl, data) => {
  // let movOrder = ''
  // if (typeof data.movOrder === 'undefined') {
  //   movOrder = ''
  // }

  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderRoute/GetAllNF?SubCompanyId=${data.subCompanyId}&limit=${data.limit}&offset=${data.offset}&Order=desc&MovimentationOrder=${data.movOrder}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const deleteTagService = async (token, nameUrl, id) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderItem/Delete?MovimentationOrderItemId=${id}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const editTagService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/MovimentationOrderItem/Edit`, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}

export const addTagService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/MovimentationOrderItem/AddLabel`, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}
