import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '../../../context/AuthContext'
import { AiFillPlusCircle } from 'react-icons/ai'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { getAllZPLReferencePag, getZplDefault } from '../../../services/printerZPLDefaultServices'

import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  Paper,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  Divider,
  InputBase,
  Backdrop,
  CircularProgress,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import ViewElearning from '../../ViewElearning'
import PermissionComponent from '../../../permissions'
import { Clear, Search } from '@mui/icons-material'
import { getAllReference } from '../../../services/productService'

const ZPLRef = () => {
  const { userRoles, subCompany, nameUrl } = useContext(Context)
  const [viewElearning, setViewElearning] = useState(false)
  const [companyId, setCompanyId] = useState(subCompany[0].companyId)
  const [loadingSearch, setLoadingSearch] = useState(true)
  const token = localStorage.getItem('token')
  const [ZplRef, setZplRef] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const [loading, setLoading] = useState(false)
  const [ZPLDefaults, setZPLDefaults] = useState()

  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const getRefsDefaultsLaundry = async () => {
    setLoading(true)

    const reqZPL = {
      order: true,
      companyId: subCompany[0].companyId,
      limit: 1000,
      offset: 0,
    }
    const r2 = await getZplDefault(token, nameUrl, reqZPL)
    setZPLDefaults(r2.data.result)

    setLoading(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleGetZplRef = async () => {
    setLoading(true)
    //setCompanyId(e.target.value)
    const data = {
      companyId: subCompany[0].companyId,
      limit: 1000,
      offset: 0,
      order: true,
    }
    const response = await getAllZPLReferencePag(token, nameUrl, data)
    const sortedData = response.data.result.sort(
      (a, b) => new Date(b.created) - new Date(a.created)
    )
    setZplRef(sortedData)
    setLoading(false)
    setLoadingSearch(false)
  }

  const handleItems = async () => {
    await handleGetZplRef()
    await getRefsDefaultsLaundry()
  }

  useEffect(() => {
    handleItems()
    //handleGetLaundry()
  }, [subCompany])

  const handleClearSearch = () => {
    setSearchValue('')
  }
  const getZplModelNameById = id => {
    if (!ZPLDefaults || ZPLDefaults.length === 0) {
      return <CircularProgress size={20} />
    }

    const zplModel = ZPLDefaults.find(zpl => zpl.printerZPLDefaultId === id)
    return zplModel ? zplModel.name : 'Modelo não encontrado'
  }

  const filteredRows =
    ZplRef.filter(row => row.name.toLowerCase().includes(searchValue.toLowerCase())) || []

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <ViewElearning pageName={'ZPLRef'} open={viewElearning} setOpen={setViewElearning} />
        <Box sx={{ display: { md: 'flex' }, width: 1, justifyContent: 'space-between' }}>
          <Typography variant="h5">ZPL Designer</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Box sx={{ width: '100%' }}>
            <InputLabel>Empresa</InputLabel>
            <Select value={companyId} disabled onChange={handleGetZplRef} style={{ width: '100%' }}>
              <MenuItem value={companyId}>{subCompany[0].nameCompany}</MenuItem>
            </Select>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 2 }}>
          <PermissionComponent role="LaundryZplCREATE">
            <Tooltip title="Novo" arrow>
              <Button
                variant="contained"
                endIcon={<AiFillPlusCircle />}
                onClick={() => navigate('/ZPLRefCreate')}
              >
                Novo
              </Button>
            </Tooltip>
          </PermissionComponent>
        </Box>
        <Paper
          component="form"
          sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            maxWidth: '300px',
            width: '100%',
          }}
        >
          <IconButton sx={{ p: '10px' }} aria-label="menu">
            <Search />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Pessoa"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
          />
          {searchValue && (
            <IconButton aria-label="Limpar pesquisa" onClick={handleClearSearch}>
              <Clear />
            </IconButton>
          )}
        </Paper>
        {filteredRows.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center"></StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                  <StyledTableCell align="center">Nome</StyledTableCell>
                  <StyledTableCell align="center">Referência</StyledTableCell>
                  <StyledTableCell align="center">Símbolos</StyledTableCell>
                  <StyledTableCell align="center">Descrições</StyledTableCell>
                  <StyledTableCell align="center">Modelo de etiqueta</StyledTableCell>
                  <StyledTableCell align="center">Ativo</StyledTableCell>
                  <StyledTableCell align="center">Criação</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center" sx={{ width: 100 }}>
                        <PermissionComponent role="LaundryZplDELETE">
                          <Tooltip title="Excluir" arrow>
                            <IconButton
                              onClick={() => navigate(`/ZPLRefDelete/${row.printerZplReferenceId}`)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 100 }}>
                        <PermissionComponent role="LaundryZplEDIT">
                          <Tooltip title="Editar" arrow>
                            <IconButton
                              onClick={() => navigate(`/ZPLRefEdit/${row.printerZplReferenceId}`)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <RemoveRedEyeIcon />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.reference}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.printerLaundryCollection.length}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.printerZPLDescriptionCollection.length}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {getZplModelNameById(row.printerZPLDefaultId)}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.active ? 'Sim' : 'Não'}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {new Date(row.created).toLocaleString()}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={ZplRef.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default ZPLRef
