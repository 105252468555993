import { useCallback, useContext, useEffect, useState } from 'react'
import {
  deleteSegmentGroupService,
  getSegmentGroup,
  getSegmentService,
} from '../../../services/admServices'
import { Context } from '../../../context/AuthContext'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import HelpIcon from '@mui/icons-material/Help'
import ViewElearning from '../../ViewElearning'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  Paper,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from '@mui/material'
import SegmentGroupCreated from '../../Create/SegmentGroupCreate'
import SegmentGroupEdit from '../../Edit/SegmentGroupEdit'
import SegmentGroupRoled from '../RulesCategory'

//icones react-icons
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import { AiFillPlusCircle } from 'react-icons/ai'
import PermissionComponent from '../../../permissions'

const SegmentGroup = () => {
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)
  const [segmentGroup, setSegmentGroup] = useState([])
  const [segmentId, setSegmentId] = useState('')
  const [viewElearning, setViewElearning] = useState(false)
  const [segment, setSegment] = useState([])

  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const [editingRoles, setEditingRoles] = useState(false)
  const [idEditingRoles, setIdEditingRoles] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [idEditing, setIdEditing] = useState('')

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getSegment = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getSegmentService(token, nameUrl, 10000, 0, false)
      setSegment(response.data.result)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [])

  const handleSegmentGroup = async value => {
    setLoading(true)
    try {
      const response = await getSegmentGroup(token, nameUrl, 10000, 0, false, value)
      setSegmentGroup(response.data.result)
      setLoading(false)
      setSegmentId(value)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getSegment()
  }, [])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleEdit = id => {
    setIdEditing(id)
    setIdEditingRoles(id)
    setExpanded(true)
    setEditing(true)
    window.scrollTo(0, 0)
  }

  const handleEditRoles = id => {
    setIdEditingRoles(id)
    setEditingRoles(true)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    try {
      await deleteSegmentGroupService(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Grupo de segmentos deletado com sucesso!')
      setAlerta(true)
      setLoading(false)
      handleSegmentGroup(segmentId)
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <ViewElearning pageName={'SegmentGroup'} open={viewElearning} setOpen={setViewElearning} />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Grupo de Segmentos</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon />
          </IconButton>
        </Box>

        {/* Criar  */}

        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>
              {editing ? 'Editando Grupo de Segmentos' : 'Cadastrar Grupo de Segmentos'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="">
                <SegmentGroupEdit
                  setEditing={setEditing}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  segmentGroupId={idEditing}
                  segmentId={segmentId}
                  handleFetchData={handleSegmentGroup}
                  setExpanded={setExpanded}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="SegmentGroupCreate">
                <SegmentGroupCreated
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={handleSegmentGroup}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>
        <Box
          sx={{
            mt: 3,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              ml: 1,
            }}
          >
            <PermissionComponent role="SegmentGroupCreate">
              <Button
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
                variant="contained"
                endIcon={<AiFillPlusCircle />}
              >
                Criar
              </Button>
            </PermissionComponent>
          </Box>
        </Box>

        {/* Editar  regras*/}
        <Dialog
          open={editingRoles}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth={'xl'}
        >
          <DialogTitle>Regras de Grupo de Segmentos</DialogTitle>
          <DialogContent>
            <SegmentGroupRoled
              setEditing={setEditingRoles}
              setAlerta={setAlerta}
              setSeverity={setSeverity}
              setTitle={setTitle}
              segmentGroupId={idEditingRoles}
              handleFetchData={getSegmentGroup}
            />
          </DialogContent>
        </Dialog>

        {/* Deletar */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>
        <Box sx={{ width: 1 }}>
          <InputLabel id="demo-simple-select-label">Segmento</InputLabel>
          <Select
            value={segmentId}
            sx={{ width: '100%' }}
            onChange={values => {
              setSegmentId(values.target.value)
              handleSegmentGroup(values.target.value)
            }}
          >
            {segment.map((item, index) => {
              return (
                <MenuItem value={item.segmentId} key={item.segmentId}>
                  {item.name}
                </MenuItem>
              )
            })}
          </Select>
        </Box>

        {segmentGroup.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="left">Nome do Grupo</StyledTableCell>
                  <StyledTableCell align="left">Descrição</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {segmentGroup
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left" sx={{ width: 20 }}>
                        <PermissionComponent role="SegmentGroupEdit">
                          <Tooltip title="Apagar" arrow>
                            <IconButton
                              onClick={() => deleteAlert(row.segmentGroupId)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                        <PermissionComponent role="">
                          <Tooltip title="Editar" arrow>
                            <IconButton
                              onClick={() => handleEdit(row.segmentGroupId)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <ModeEditOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                      </StyledTableCell>

                      <StyledTableCell align="left" sx={{ width: 200 }}>
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="left" sx={{ width: 200 }}>
                        {row.description}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={segmentGroup.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default SegmentGroup
