import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  InputAdornment,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
} from '@mui/material'
import { Box, styled } from '@mui/system'
import { useContext, useEffect, useState, useRef } from 'react'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import { Add, Clear, Delete, Help, Search } from '@mui/icons-material'
import { Context } from '../../../context/AuthContext'
import { getOnlyOrder } from '../../../services/reportServices'
import {
  searchProductService,
  sendNewCountStock,
  getProductItensRfid,
} from '../../../services/productService'
import { useDebounce } from '../Product/Components/useDebounce'
import { useParams } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'

export const NewSaleOrder = () => {
  const { subCompany, userId, nameUrl } = useContext(Context)
  const token = localStorage.getItem('token')
  const params = useParams()
  const { debounce } = useDebounce(1000, true)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [search, setSearch] = useState('')
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [loadingCount, setLoadingCount] = useState(false)
  const [orderSelect, setOrderSelect] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [pagination, setPagination] = useState({})
  const [rowsProducts, setRowsProducts] = useState([])
  const [rowsFilter, setRowsFilter] = useState([])
  const [viewElearning, setViewElearning] = useState(false)
  const [searchFilter, setSearchFilter] = useState('')
  const [product, setProduct] = useState([])
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [selectedId, setSelectedId] = useState(undefined)
  const [severity, setSeverity] = useState('warning')
  const [count, setCount] = useState(0)
  const [counting, setCounting] = useState(false)
  const [finished, setFinished] = useState(false)
  const [shouldStopCounting, setShouldStopCounting] = useState(false)
  const [showInput, setShowInput] = useState(false)
  const [selectedEquipment, setSelectedEquipment] = useState('')
  const [formattedIP, setFormattedIP] = useState('')
  const [formattedPort, setFormattedPort] = useState('')
  const [products, setProducts] = useState('')
  const [error, setError] = useState('')
  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')
  const [intervalId, setIntervalId] = useState(null)
  const [openModalCreate, setOpenModalCreate] = useState(false)
  const [productItem, setProductItem] = useState([])
  const [messageError, setMessageError] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const inputRef = useRef(null)
  const [isExternalLoading, setIsExternalLoading] = useState(false)
  const [codeList, setCodeList] = useState([])
  const [valueText, setValueText] = useState('')
  const [serials, setSerials] = useState([])
  const [pendingRequests, setPendingRequests] = useState(0)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const formatIP = ip => {
    if (!ip) return ''

    const numericValue = ip.replace(/[^0-9]/g, '')
    const formattedValue = numericValue.replace(/(\d{3})(?=\d)/g, '$1.')

    return formattedValue
  }

  const formatPort = port => {
    if (!port) return ''

    const numericValue = port.replace(/\D/g, '')
    return numericValue
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleClose = () => {
    setAlerta(false)
  }

  const getOrders = async e => {
    setSubCompanyId(e)
    try {
      const response = await getOnlyOrder(token, nameUrl, e, Number(params.typeOrder))
      setOrders(response.data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (params.movimentationOrderId) {
      setOrderSelect(params.movimentationOrderId)
    }
  }, [params])

  const handleOrder = e => {
    const newOrder = e.target.value
    setOrderSelect(newOrder)

    const selectedOrder = orders.find(order => order.movimentationOrderId === newOrder)

    if (selectedOrder) {
      const movimentationOrderNumberOtherSystem = selectedOrder.movimentationOrderNumberOtherSystem
      setMovimentationOrderNumberOtherSystem(movimentationOrderNumberOtherSystem)
      // Faça o que você precisa com movimentationOrderNumberOtherSystem
    }
  }

  const receiveNewSerials = async () => {
    setIsLoading(true)
    setLoading(true)

    if (serials.length === 0) {
      setTitle('Nenhum serial adicionado.')
      setAlerta(true)
      setSeverity('warning')
      setLoading(false)
      setIsLoading(false)
      return
    }

    //cria um array de serials
    const receiveNewDto = {
      serial: serials,
      subCompanyId: subCompanyId,
    }

    try {
      //envia os serials para a api
      await sendNewCountStock(token, nameUrl, receiveNewDto)
      setSerials([])
      setCodeList([])
      setValueText('')
      setTitle('Informações enviadas com sucesso.')
      setAlerta(true)
      setSeverity('success')
    } catch (err) {
      console.log(err)
      setTitle(err.response.data.message ?? 'Erro ao enviar informações.')
      setAlerta(true)
      setSeverity('error')
    }
    setIsLoading(false)
    setLoading(false)
  }

  const handleEquipmentChange = e => {
    const selectedEquipment = e.target.value
    setSelectedEquipment(selectedEquipment)
    if (selectedEquipment === 'MID40') {
      setShowInput(true)
    } else if (selectedEquipment === 1) {
      setShowInput(true)
    } else {
      setShowInput(false)
    }
    setCounting(false)
  }

  const initialize = () => {
    console.log(shouldStopCounting)
    setShouldStopCounting(false)
    setFinished(false)

    if (selectedEquipment === 'MID40' && (!formattedIP || !formattedPort)) {
      setError('Informe o IP e a porta antes de iniciar a contagem.')
      return
    }
    if (selectedEquipment === 'HID' && !products) {
      setError('Informe o código do produto antes de iniciar a contagem.')
      return
    }

    if (!orderSelect) return
    setLoadingCount(true)
    setCounting(true)
    setCount(0)
    setShouldStopCounting(false)

    const newIntervalId = setInterval(() => {
      if (shouldStopCounting) {
        clearInterval(newIntervalId)
        setCounting(false)
        setFinished(true)
        setShouldStopCounting(false)
        return
      }
      setCount(prevCount => prevCount + 1)
    }, 1000)

    setIntervalId(newIntervalId)
    setTimeout(() => {
      clearInterval(newIntervalId)
      setCounting(false)
      setFinished(true)
      setLoadingCount(false)
    }, 10000)
  }

  const stopCounting = () => {
    console.log('parar')
    clearInterval(intervalId)
    setCounting(false)
    setShouldStopCounting(true)
    setShouldStopCounting(false)
  }

  useEffect(() => {
    setCounting(false)
  }, [orderSelect, shouldStopCounting])

  const handleGetAllProductsSearch = async () => {
    setPage(0)
    setIsLoading(true)
    const dataProduct = {
      companyId: subCompany[0].companyId,
      limit: 10,
      offset: 0,
      order: 'desc',
      SearchWord: search,
    }
    try {
      searchProductService(token, nameUrl, dataProduct).then(response => {
        const data = response.data.result
        setProduct(data)

        setIsLoading(false)
      })
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (search !== '') {
      setIsLoading(true)
      debounce(() => {
        handleGetAllProductsSearch()
      })
    }
  }, [search])

  const handleSaveCodeRfidEdit = newProduct => {
    const updatedProducts = rowsProducts.filter(product => product.serial !== productItem.serial)

    updatedProducts.push(newProduct)

    setRowsProducts(updatedProducts)
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      return handleAddList()
    }
  }

  useEffect(() => {
    setLoading(pendingRequests > 0)
  }, [pendingRequests])

  const handleDelete = itemToDelete => {
    const updatedRows = codeList.filter(item => item !== itemToDelete)
    const updatedSerials = serials.filter(serial => !itemToDelete.serials.includes(serial))
    setCodeList(updatedRows)
    setSerials(updatedSerials)
    setTitle('Produto removido com sucesso.')
    setAlerta(true)
    setSeverity('success')
  }

  const handleThrowError = title => {
    setTitle(title)
    setAlerta(true)
    setSeverity('error')
    setValueText('')
    setMessageError('')
  }

  const handleEmptyText = errorMessage => {
    setMessageError(errorMessage)
  }

  const handleAddList = async () => {
    setPendingRequests(prev => prev + 1)

    if (valueText === '') {
      handleEmptyText('Campo obrigatório.')
      setPendingRequests(prev => prev - 1)
      return
    }

    const codeExists = serials.includes(valueText)

    if (codeExists) {
      handleThrowError(`Codigo ${valueText} já adicionado.`)
      setValueText('')
      setMessageError('')
      setPendingRequests(prev => prev - 1)
      return
    }

    let newProduct
    let response

    //limpa o campo de entrada
    const currentValue = valueText
    setValueText('')

    //busca o produto pelo serial
    try {
      response = await getProductItensRfid(token, nameUrl, subCompanyId, currentValue)
    } catch (err) {
      handleThrowError(err.response.data.title ?? 'Erro ao buscar produto.')
      setPendingRequests(prev => prev - 1)
      return
    }

    // Verifica se o response é undefined ou null
    if (!response || !response.data) {
      handleThrowError('Produto não encontrado.')
      setPendingRequests(prev => prev - 1)
      return
    }

    if (response.data.length === 0) {
      handleThrowError('Produto não encontrado.')
      setPendingRequests(prev => prev - 1)
      return
    }

    //verifica se o produto ja foi contado
    const productExists = codeList.some(product => product.productId === response.data.productId)

    //se o produto ja foi contado, adiciona o serial ao array de serials do produto e soma a quantidade
    if (productExists) {
      setCodeList(prevList =>
        prevList.map(item =>
          item.productId === response.data.productId
            ? {
                ...item,
                serials: [...item.serials, valueText],
                quantityPallet: (item.quantityPallet || 0) + 1,
              }
            : item
        )
      )
    } else {
      //se o produto ainda não foi contado, cria um novo produto
      newProduct = {
        idProductPartner: response.data.idProductPartner ?? '',
        reference: response.data.referencia ?? '',
        color: response.data.color ?? '',
        barCode: response.data.barCode ?? '',
        subCompanyCNPJ: response.data.subCompanyCNPJ ?? '',
        dataExpiration: response.data.dataExpiration ?? '',
        productName: response.data.productName ?? '',
        size: response.data.size ?? '',
        productId: response.data.productId ?? '',
        serials: [valueText],
        quantityPallet: 1,
        sku: response.data.sku,
      }

      setCodeList(prevList => [...prevList, newProduct])
    }

    setSerials(prevSerials => [...prevSerials, valueText])
    setValueText('')
    setSelectedId('')
    setPendingRequests(prev => prev - 1)
  }

  const handleCleanList = () => {
    setSerials([])
    setCodeList([])
    setTitle('Lista limpa com sucesso.')
    setAlerta(true)
    setSeverity('success')
  }

  const handleDisableInput = () => {
    return !subCompanyId
  }

  const handleChangeCodigoRfid = e => {
    setValueText(e.target.value)
    setMessageError('')
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }} data-testid="new-sale-order">
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={alerta}
            onClose={handleClose}
            key={vertical + horizontal}
          >
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
              {title}
            </Alert>
          </Snackbar>
          <Typography variant="h5">Pedido de Venda</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <Help onClick={() => setViewElearning(true)} />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 2,
            mt: 2,
          }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box flex={1} display="flex" justifyContent="flex-end">
                <FormControl sx={{ width: '100%', mt: 0 }}>
                  <InputLabel sx={{ color: 'black' }} id="demo-simple-select-label">
                    Filial
                  </InputLabel>
                  <Select
                    value={subCompanyId}
                    onChange={event => {
                      setSubCompanyId(event.target.value)
                    }}
                    sx={{ width: '100%' }}
                    label="Filial"
                  >
                    {subCompany.map((item, index) => (
                      <MenuItem value={item.subCompanyId} key={item.subCompanyId}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box flex={1} display="flex" justifyContent="flex-end">
                <FormControl sx={{ width: '100%', mt: 0 }}>
                  <InputLabel sx={{ color: 'black' }} id="demo-simple-select-label">
                    Escolha o equipamento
                  </InputLabel>
                  <Select
                    value={selectedEquipment}
                    onChange={event => {
                      setSelectedEquipment(event.target.value)
                      handleEquipmentChange(event)
                    }}
                    sx={{ width: '100%', color: 'black' }}
                    label="Escolha o equipamento"
                  >
                    <MenuItem selected value={1}>
                      HID
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            {showInput && (
              <>
                {selectedEquipment === 'MID40' && (
                  <>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        id="outlined-basic"
                        label="IP"
                        variant="outlined"
                        sx={{ width: '100%' }}
                        value={formattedIP}
                        error={!!error}
                        onChange={e => {
                          setFormattedIP(formatIP(e.target.value))
                        }}
                        inputProps={{
                          maxLength: 15,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        id="outlined-basic"
                        label="Porta"
                        variant="outlined"
                        value={formattedPort}
                        error={!!error}
                        onChange={e => {
                          setFormattedPort(formatPort(e.target.value))
                        }}
                        sx={{ width: '100%' }}
                      />
                    </Grid>
                  </>
                )}
                {error && (
                  <Grid item xs={12}>
                    <Typography variant="body2" color="error">
                      {error}
                    </Typography>
                  </Grid>
                )}
              </>
            )}

            <Grid
              item
              xs={12}
              md={6}
              sx={{
                height: '95px',
              }}
            >
              <Box display="flex" justifyContent="flex-end">
                <TextField
                  inputRef={inputRef}
                  autoFocus
                  label="Codigo RFID"
                  type="text"
                  disabled={handleDisableInput()}
                  onKeyDown={handleKeyPress}
                  value={valueText}
                  error={!!messageError}
                  helperText={messageError}
                  onChange={e => handleChangeCodigoRfid(e)}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    style: { color: 'black' }, // Cor da label quando não está ativo
                  }}
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: 'black', // Cor da label quando não está ativo
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: 'red', // Cor da label quando está ativo (focado)
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                justifyContent="flex-end"
                width="100%"
                alignItems="start"
                height="100%"
                sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
              >
                <Button
                  sx={{
                    mr: 2,
                    height: '100%',
                    width: '100%',
                    maxHeight: '56px',
                  }}
                  variant="outlined"
                  startIcon={<Clear />}
                  onClick={() => handleCleanList()}
                  disabled={counting}
                >
                  Limpar
                </Button>
                <Button
                  sx={{
                    mr: 2,
                    height: '100%',
                    width: '100%',
                    maxHeight: '56px',
                    color: 'black',
                  }}
                  startIcon={<Add />}
                  variant="contained"
                  onClick={() => {
                    handleAddList()
                  }}
                  disabled={counting}
                >
                  Adicionar Codigo
                </Button>
                <Button
                  sx={{
                    height: '100%',
                    width: '100%',
                    maxHeight: '56px',
                    color: 'black',
                  }}
                  variant="contained"
                  onClick={receiveNewSerials}
                  disabled={counting}
                >
                  Enviar informação
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box display="flex">
                {finished && (
                  <Typography variant="h6" mr={2}>
                    {codeList.length} Peças contadas
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          position="relative"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
            <Typography variant="h5">{`Total de produtos: ${codeList.length}`}</Typography>
            <Typography variant="h5">{`Total de itens contados: ${codeList.reduce(
              (total, item) => total + item.quantityPallet,
              0
            )}`}</Typography>
          </Box>
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left"> Código ERP</StyledTableCell>
                  <StyledTableCell align="left">Nome do Produto</StyledTableCell>
                  <StyledTableCell align="left">Quantidade</StyledTableCell>
                  <StyledTableCell align="left">Cor</StyledTableCell>
                  <StyledTableCell align="left">Tamanho</StyledTableCell>
                  <StyledTableCell align="left">Referencia</StyledTableCell>
                  <StyledTableCell align="left">SKU</StyledTableCell>

                  <StyledTableCell align="left">Ações</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {codeList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell align="left">{row.idProductPartner}</StyledTableCell>
                    <StyledTableCell align="left">{row.productName}</StyledTableCell>
                    <StyledTableCell align="left">{row.quantityPallet}</StyledTableCell>
                    <StyledTableCell align="left">{row.color}</StyledTableCell>
                    <StyledTableCell align="left">{row.size}</StyledTableCell>
                    <StyledTableCell align="left">{row.reference}</StyledTableCell>
                    <StyledTableCell align="left">{row.sku}</StyledTableCell>

                    <StyledTableCell align="left">
                      <IconButton onClick={() => handleDelete(row)}>
                        <Delete />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={codeList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: theme => theme.zIndex.drawer - 1, // Ajuste o zIndex para ficar acima dos elementos interativos
              pointerEvents: 'none', // Permite que os cliques passem através do Box
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        )}
      </Box>
    </>
  )
}
