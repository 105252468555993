import axios from 'axios'

export const getProductionReportService = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Report/GetProductionkanbamMES?SubCompanyId=${data.SubCompanyId}&StartDate=${data.StartDate}&FinishDate=${data.FinishDate}&OrderStatus=${data.OrderStatus}&QualityStatus=${data.QualityStatus}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}
