import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Select, InputLabel, MenuItem, Grid } from '@mui/material'

const GraficoApex = ({ totalCitiesRoute }) => {
  const [selectValue, setSelectValue] = useState('Todos')
  const [selectValueRouter, setSelectValueRouter] = useState('Todos')
  const [filterCity, setFilterCity] = useState([])
  const [data, setData] = useState([])
  const [dataList, setDataList] = useState([])
  const [isShowTop10, setIsShowTop10] = useState(false)

  useEffect(() => {
    if (!totalCitiesRoute || totalCitiesRoute.length === 0) {
      return
    }
    if (totalCitiesRoute.length > 0 && selectValue === 'Todos') {
      setSelectValue('Todos')
    }
    if (totalCitiesRoute.length > 0 && selectValueRouter === 'Todos') {
      setSelectValueRouter('Todos')
    }
  }, [totalCitiesRoute])

  const handleItemList = () => {
    const groupedData = totalCitiesRoute.reduce((acc, curr) => {
      const key = curr.route
      if (!key) return acc
      if (!acc[key]) {
        acc[key] = { ...curr }
      } else {
        acc[key].visity += curr.visity
        acc[key].visityNot += curr.visityNot
      }
      return acc
    }, {})

    const result = Object.keys(groupedData)
      .filter(key => !!key)
      .map(key => groupedData[key])
    if (result) {
      setIsShowTop10(true)
      setDataList(result)
    }
  }

  useEffect(() => {
    if (dataList) {
      handleItemList()
    }
  }, [totalCitiesRoute])

  useEffect(() => {
    handleFilter()
  }, [selectValueRouter])

  useEffect(() => {
    handleFilterCity()
  }, [selectValue])

  const handleFilter = () => {
    let filter = totalCitiesRoute.slice(0, 10)
    let isTop10 = true

    if (selectValueRouter !== 'Todos') {
      filter = totalCitiesRoute.filter(item => item.route === selectValueRouter)
      if (filter.length > 10) {
        filter = filter.slice(0, 10)
      }
      setFilterCity(filter)
      console.log(filter, 'todossda')

      isTop10 = false
      setSelectValue('Todos')
    }

    setData(filter)
    setIsShowTop10(isTop10)
  }

  const handleFilterCity = () => {
    let filteredData = filterCity

    if (selectValue !== 'Todos') {
      filteredData = filteredData.filter(item => item.name === selectValue)
    }
    setData(filteredData)
    setIsShowTop10(false)
  }

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: isShowTop10 ? '80%' : '50%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: 0,

      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },
    stroke: {
      show: true,
      width: 4,
      colors: ['transparent'],
    },
    xaxis: {
      categories: isShowTop10
        ? dataList.slice(0, 10).map(item => item.route)
        : data.map(item => item.name),
    },
    yaxis: {},
    fill: {
      opacity: 1,
    },
    tooltip: {
      shared: true,
      intersect: false,

      y: {
        formatter: function (val) {
          return val
        },
      },
    },
    toolbar: {
      show: false, // Remover o botão de download
    },
  }

  const series = [
    {
      name: 'Cidades no roteiro',
      data: isShowTop10
        ? dataList.slice(0, 10).map(item => item.visity)
        : data.map(item => item.visity),
    },
    {
      name: 'Cidades com Lojas Ativa',
      data: isShowTop10
        ? dataList.slice(0, 10).map(item => item.visityNot)
        : data.map(item => item.visityNot),
    },
  ]

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputLabel id="demo-simple-select-label">Selecione o roteiro</InputLabel>
          <Select
            size="small"
            sx={{ width: '100%' }}
            label="Selecione o roteiro"
            value={selectValueRouter}
            onChange={e => setSelectValueRouter(e.target.value)}
          >
            <MenuItem value="Todos">Todos</MenuItem>
            {[...new Set(dataList.map(item => item.route))].map((name, index) => (
              <MenuItem value={name} key={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <InputLabel id="demo-simple-select-label">Selecione a cidade</InputLabel>
          <Select
            size="small"
            sx={{ width: '100%' }}
            label="Escolher a cidade"
            disabled={selectValueRouter === 'Todos'}
            value={selectValue}
            onChange={e => setSelectValue(e.target.value)}
          >
            <MenuItem value="Todos">Todos</MenuItem>
            {[...new Set(filterCity.map(item => item.name))].map((name, index) => (
              <MenuItem value={name} key={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <div id="grafico">
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  )
}

export default GraficoApex
