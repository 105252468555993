import React, { useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  IconButton,
  Autocomplete,
  FormControl,
  tableCellClasses,
  styled,
  Tooltip,
  InputAdornment,
  Button,
  Modal,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import Collapse from '@mui/material/Collapse'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Context } from '../../../context/AuthContext'
import { Clear, Close, Delete, Edit, Help, Print, Save, Search } from '@mui/icons-material'
import { getPrinterService, getPrinterZplService } from '../../../services/configServices'
import { searchProductService } from '../../../services/productService'
import { useDebounce } from '../Product/Components/useDebounce'
import { colorTableDefault } from '../../TemplateDefault'
import { Printer, Text, Cut } from 'react-thermal-printer'
import ComponentQuantityCreate from './ComponentQuantity/Create'
import ComponentQuantityEdit from './ComponentQuantity/Edit'
import { getFaction } from '../../../services/factionService'
import { labelaryService, printZpl } from '../../../services/printerZPLDefaultServices'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

function PrintReference() {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const { debounce } = useDebounce(1000, true)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [printerZplId, setPrinterZplId] = useState('')
  const [factionId, setFactionId] = useState('')
  const [equipmentId, setEquipmentId] = useState('')
  const [equipamentIp, setEquipmentIp] = useState('')
  const [zpl, setZpl] = useState('')
  const [equipmentPort, setEquipmentPort] = useState(0)
  const [typePrint, setTypePrint] = useState(0)
  const [printZpls, setPrintZpls] = useState([])
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [product, setProduct] = useState([])
  const [totalProducts, setTotalProducts] = useState(0)
  const [selectedId, setSelectedId] = useState(undefined)
  const [productItem, setProductItem] = useState([])
  const [isExternalLoading, setIsExternalLoading] = useState(false)
  const [openModalCreate, setOpenModalCreate] = useState(false)
  const [rowsProducts, setRowsProducts] = useState([])
  const [rowsFilter, setRowsFilter] = useState([])
  const [factions, setFactions] = useState([])
  const [messageError, setMessageError] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [searchFilter, setSearchFilter] = useState('')
  const [printer, setPrinter] = useState([])
  const [zplLab, setZplLab] = useState('')
  const [formatZpl, setFormatZpl] = useState('')
  const [productId, setProductId] = useState({})
  const [previewOpen, setPreviewOpen] = useState(false)
  const [loadingPrint, setLoadingPrint] = useState(false)
  const [previewOpenDialog, setPreviewOpenDialog] = useState(false)

  const getPrinter = async () => {
    setLoading(true)
    try {
      const response = await getPrinterService(token, nameUrl, subCompanyId, 500, 0, 'desc')
      setPrinter(response.data.result)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const handleDelete = itemToDelete => {
    const updatedRows = rowsProducts.filter(item => item !== itemToDelete)
    setRowsProducts(updatedRows)
  }

  const handlePrintZpl = async subCompanyId => {
    try {
      const response = await getPrinterZplService(token, nameUrl, subCompanyId, 500, 0, 'des')
      setPrintZpls(response.data)
    } catch (error) {}
  }

  const handleGetAllProductsSearch = async () => {
    setPage(0)
    setIsLoading(true)
    const dataProduct = {
      CompanyId: subCompany[0].companyId,
      limit: 10,
      offset: 0,
      order: 'desc',
      SearchWord: search,
    }
    try {
      searchProductService(token, nameUrl, dataProduct).then(response => {
        const data = response.data.result
        setProduct(data)

        setIsLoading(false)
      })
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (search !== '') {
      setIsLoading(true)
      debounce(() => {
        handleGetAllProductsSearch()
      })
    }
  }, [search])

  const handleFaction = async () => {
    const data = {
      companyId: subCompany[0].companyId,
      limit: 500,
      offset: 0,
      order: 'desc',
    }
    try {
      const response = await getFaction(token, nameUrl, data)
      setFactions(response.data.result)
    } catch (error) {}
  }

  useEffect(() => {
    if (subCompany[0].companyId) {
      handleFaction()
    }
  }, [subCompany[0].companyId])

  useEffect(() => {}, [product])

  useEffect(() => {
    if (product.length === 1 && !isLoading) {
      setOpenModalCreate(true)
      setSelectedId(product[0]?.productId)
      setProductItem(product[0])
    }
  }, [product, isLoading])

  useEffect(() => {
    if (selectedId && openModalCreate) {
      setOpenModalCreate(true)
    }
  }, [selectedId, productItem])

  const autoCompleteSelectedOptions = useMemo(() => {
    if (!selectedId) return undefined

    const selectedOptions = product.find(item => item.id === selectedId)

    return selectedOptions
  }, [selectedId, product])

  const handleSaveQuantityCreate = newProduct => {
    const productIdExists = rowsProducts.some(product => product.productId === newProduct.productId)

    if (productIdExists) {
      setMessageError(true)
      return
    }
    console.log(newProduct, ' newProduct')
    setRowsProducts(prevent => [...prevent, newProduct])
  }

  const handleSaveQuantityEdit = newProduct => {
    const existingProductIndex = rowsProducts.findIndex(
      product => product.productId === newProduct.productId
    )

    if (existingProductIndex !== -1) {
      const updatedProducts = [...rowsProducts]
      updatedProducts.splice(existingProductIndex, 1, newProduct)
      setRowsProducts(updatedProducts)
    } else {
      setRowsProducts(prevent => [...prevent, newProduct])
    }
  }

  const filterRow = () => {
    const filtered = rowsProducts.filter(
      item =>
        item.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
        item.idProductPartner.toLowerCase().includes(searchFilter.toLowerCase()) ||
        item.barCode.toLowerCase().includes(searchFilter.toLowerCase())
    )
    setRowsFilter(filtered)
  }

  useEffect(() => {
    filterRow()
  }, [searchFilter, rowsProducts])

  useEffect(() => {
    if (zpl) {
      loadPreviewImage()
    }
  }, [zpl, productId])

  useEffect(() => {
    if (zpl) {
      const formattedZpl = zpl
        .replace('{0}', productId.barCode)
        .replace('{1}', productId.idProductPartner)

      console.log(formattedZpl, 'sadas')

      loadPreviewImage(formattedZpl)
      setFormatZpl(formattedZpl)
    }
  }, [productId, zpl])
  const loadPreviewImage = async formattedZpl => {
    try {
      const zplLabelary = await labelaryService(formattedZpl)

      // Converte os dados binários para Blob
      const blob = new Blob([zplLabelary.data], { type: 'image/png' })

      // Cria um FileReader para ler o Blob como base64
      const reader = new FileReader()

      // Define a função de callback para quando a leitura estiver concluída
      reader.onloadend = () => {
        // O resultado da leitura estará disponível em reader.result
        setZplLab(reader.result)
      }

      // Lê o Blob como base64
      reader.readAsDataURL(blob)
    } catch (error) {
      console.error(error)
      setZplLab('')
    }
  }

  const handlePrintZPLAction = async () => {
    setLoadingPrint(true)
    const formattedZpl = zpl
      .replace('{0}', productId.barCode)
      .replace('{1}', productId.idProductPartner)
    console.log(formattedZpl, 'sadas')
    const url = `http://${equipamentIp}:${equipmentPort}`
    try {
      const response = await printZpl(url, formattedZpl)
      setLoadingPrint(false)
    } catch (error) {
      setLoadingPrint(false)
      console.error(error)
    }
  }

  function getPrinters() {
    if (navigator && navigator.print && navigator.print !== undefined && navigator.print !== null) {
      return new Promise((resolve, reject) => {
        navigator.print.getPrinters(printers => {
          const printerNames = printers.map(printer => printer.name)
          resolve(printerNames)
        })
      })
    } else {
      return Promise.reject('Navigator.print.getPrinters is not supported.')
    }
  }

  // Exemplo de uso:
  getPrinters()
    .then(printerList => {
      console.log('Lista de impressoras:', printerList)
      // Envie a lista de impressoras para o backend aqui
    })
    .catch(error => {
      console.error('Erro ao obter a lista de impressoras:', error)
    })

  return (
    <Box sx={{ mt: 7 }}>
      <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">Impressão por referencia</Typography>
        <IconButton sx={{ justifySelf: 'flex-end' }}>
          <Help />
        </IconButton>
      </Box>

      <Modal
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          {zplLab ? <img src={zplLab} alt="Preview" height="500px" /> : 'Carregando...'}
        </Box>
      </Modal>
      <React.Fragment>
        <Dialog
          open={previewOpenDialog}
          onClose={() => setPreviewOpenDialog(false)}
          fullWidth
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"> Deseja imprimir esta etiqueta?</DialogTitle>
          <DialogContent sx={{ textAlign: 'center' }}>
            {zplLab ? <img src={zplLab} width="400px" alt="Preview" /> : 'Carregando...'}
          </DialogContent>
          <DialogActions>
            <Button
              startIcon={<Close />}
              variant="outlined"
              onClick={() => setPreviewOpenDialog(false)}
              disabled={loadingPrint}
            >
              Cancelar
            </Button>
            <Button
              startIcon={loadingPrint && <Print />}
              endIcon={loadingPrint && <CircularProgress size={20} />}
              disabled={loadingPrint}
              variant="contained"
              onClick={handlePrintZPLAction}
            >
              {loadingPrint ? 'Imprimindo...' : 'Imprimir'}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>

      <ComponentQuantityCreate
        open={openModalCreate}
        onClose={() => {
          setOpenModalCreate(false)
        }}
        product={productItem}
        message={messageError}
        onSaveQuantity={handleSaveQuantityCreate}
      />
      <ComponentQuantityEdit
        open={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false)
        }}
        product={productItem}
        onSaveQuantity={handleSaveQuantityEdit}
        isEdit={isEditModalOpen}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <FormControl sx={{ width: '100%', mt: 0 }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                value={subCompanyId}
                disabled={isLoading}
                onChange={event => {
                  setSubCompanyId(event.target.value)
                  handlePrintZpl(event.target.value)
                }}
                sx={{ width: '100%' }}
                label="Filial"
              >
                {subCompany.map((item, index) => (
                  <MenuItem value={item.subCompanyId} key={item.subCompanyId}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <FormControl sx={{ width: '100%', mt: 0 }}>
              <InputLabel id="demo-simple-select-label">Layout da etiqueta</InputLabel>
              <Select
                value={printerZplId}
                disabled={!subCompanyId || isLoading}
                onChange={event => {
                  setPrinterZplId(event.target.value)
                  const selectedPrinter = printZpls.find(
                    item => item.printerZplId === event.target.value
                  )

                  if (selectedPrinter) {
                    setZpl(selectedPrinter.zpl)
                  }
                }}
                sx={{ width: '100%' }}
                label="Layout da etiqueta"
              >
                {printZpls.map((item, index) => (
                  <MenuItem value={item.printerZplId} key={item.printerZplId}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <FormControl sx={{ width: '100%', mt: 0 }}>
              <InputLabel id="demo-simple-select-label">Facção</InputLabel>
              <Select
                value={factionId}
                disabled={!printerZplId || isLoading}
                onChange={event => {
                  setFactionId(event.target.value)
                }}
                sx={{ width: '100%' }}
                label="Facção"
              >
                {factions.map((item, index) => (
                  <MenuItem value={item.factionId} key={item.factionId}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box width="100%">
            <Autocomplete
              openText="Abrir"
              closeText="Fechar"
              clearText="Apagar"
              noOptionsText={isLoading ? 'Carregando...' : 'Nenhum resultado encontrado'}
              loadingText="Carregando..."
              disablePortal
              value={autoCompleteSelectedOptions}
              loading={isLoading}
              onInputChange={(_, newValue) => setSearch(newValue)}
              disabled={isExternalLoading}
              popupIcon={
                isExternalLoading || isLoading ? (
                  <CircularProgress color="warning" size={20} />
                ) : undefined
              }
              onChange={(_, newValue) => {
                setSelectedId(newValue?.productId)
                setSearch(newValue ? newValue.idProductPartner : '')
                if (newValue === null) {
                  handleGetAllProductsSearch()
                }
              }}
              options={product}
              getOptionLabel={option => (option ? `${option.barCode} - ${option.name || ''}` : '')}
              isOptionEqualToValue={(option, value) => option.productId === value.productId}
              renderInput={params => <TextField {...params} label="Pesquisar produtos" />}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={typePrint === 3 || typePrint === 1 ? 2 : 4}>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <FormControl sx={{ width: '100%', mt: 0 }}>
              <InputLabel id="demo-simple-select-label">Selecione o tipo de impressora</InputLabel>
              <Select
                value={typePrint}
                disabled={!subCompanyId || isLoading}
                onChange={event => {
                  setTypePrint(event.target.value)
                  if (event.target.value === 1) {
                    getPrinter()
                  }
                }}
                sx={{ width: '100%' }}
                label="Selecione o tipo de impressora"
              >
                <MenuItem value={1}>Ethernet</MenuItem>
                <MenuItem value={2}>Postek</MenuItem>
                <MenuItem value={3}>USB</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {typePrint === 3 && (
          <Grid item xs={12} md={2}>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <FormControl sx={{ width: '100%', mt: 0 }}>
                <InputLabel id="demo-simple-select-label">Selecione a impressora</InputLabel>
                <Select
                  value={typePrint}
                  disabled={!subCompanyId || isLoading}
                  onChange={event => {
                    setTypePrint(event.target.value)
                  }}
                  sx={{ width: '100%' }}
                  label="Selecione a impressora"
                >
                  {/* <MenuItem value={1}>Ethernet</MenuItem>
                  <MenuItem value={2}>Postek</MenuItem>
                  <MenuItem value={3}>USB</MenuItem> */}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        )}
        {typePrint === 1 && (
          <Grid item xs={12} md={2}>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <FormControl sx={{ width: '100%', mt: 0 }}>
                <InputLabel id="demo-simple-select-label">Selecione a impressora</InputLabel>
                <Select
                  value={equipmentId}
                  disabled={!subCompanyId || isLoading}
                  onChange={event => {
                    setEquipmentId(event.target.value)
                    const selectedPrinter = printer.find(
                      item => item.equipmentId === event.target.value
                    )
                    if (selectedPrinter) {
                      setEquipmentIp(selectedPrinter.ip)
                      setEquipmentPort(selectedPrinter.port)
                    }
                  }}
                  sx={{ width: '100%' }}
                  label="Selecione a impressora"
                >
                  {printer.map((item, index) => (
                    <MenuItem value={item.equipmentId}>{item.location}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              sx={{ maxWidth: '500px', width: '100%' }}
              type="text"
              variant="outlined"
              placeholder="Pesquisar"
              disabled={!subCompanyId || isLoading}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={searchFilter}
              onChange={e => setSearchFilter(e.target.value)}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          <Box display="flex" alignItems="center" justifyContent="end" gap={2} mt={2}>
            <Button
              onClick={() => setRowsProducts([])}
              startIcon={<Clear />}
              variant="outlined"
              sx={{ width: '100%', maxWidth: '180px' }}
            >
              Limpar
            </Button>
            <Button
              onClick={() => setPreviewOpen(true)}
              startIcon={<Clear />}
              variant="outlined"
              sx={{ width: '100%', maxWidth: '180px' }}
            >
              Visualizar Etiqueta
            </Button>
            <Button
              startIcon={<Save />}
              variant="contained"
              sx={{ width: '100%', maxWidth: '180px' }}
            >
              Salvar dados
            </Button>
            <Button
              startIcon={<Print />}
              variant="contained"
              sx={{ width: '100%', maxWidth: '180px' }}
            >
              Imprimir todos
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="start" gap={2}>
            <Typography variant="h5">{`Total de produtos ${rowsFilter.length}`}</Typography>
            <Typography variant="h5">{`Total de códigos gerados ${rowsFilter.length}`}</Typography>
          </Box>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <TableContainer component={Paper}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Codigo</StyledTableCell>
                    <StyledTableCell align="left">Nome do produto</StyledTableCell>
                    <StyledTableCell align="left">Codigo de barras</StyledTableCell>
                    <StyledTableCell align="center">Serial</StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsFilter.map((item, index) => (
                    <TableRow key={item.serial}>
                      <StyledTableCell align="left">{item.idProductPartner}</StyledTableCell>
                      <StyledTableCell align="left">{item.name}</StyledTableCell>
                      <StyledTableCell align="left">{item.barCode}</StyledTableCell>
                      <StyledTableCell align="center">{item.serial}</StyledTableCell>
                      <StyledTableCell align="center" width="200px">
                        <Tooltip title="Editar" arrow>
                          <IconButton>
                            <Edit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Apagar" arrow>
                          <IconButton>
                            <Delete />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Imprimir" arrow>
                          <IconButton
                            onClick={() => {
                              setPreviewOpenDialog(true)
                              setProductId(item)
                            }}
                          >
                            <Print />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PrintReference
