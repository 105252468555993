import React, { useContext } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
} from '@mui/material'
import { Context } from '../../../../context/AuthContext'
import {
  createClient,
  createMachineReceipt,
  editMachineReceipt,
  getMachineReceiptId,
} from '../../../../services/clientService'
import { useForm } from 'react-hook-form'

const MachineReceiptForm = ({
  setExpanded,
  setAlerta,
  setSeverity,
  setTitle,
  handleFetchData,
  companyId,
  setEditing,
  id,
}) => {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    reset,
    watch,
  } = useForm()
  const token = localStorage.getItem('token')
  const { subCompany, userRoles, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [subCompanyName, setSubCompanyname] = useState('')

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [search, setSearch] = useState('')
  const [singleProd, setSingleProd] = useState({
    sequenceReceipt: '',
    description: '',
    internalParNumber: '',
    isDelete: false,
  })

  useEffect(() => {
    if (id) {
      handleEdit()
    }
  }, [id])

  const handleEdit = async () => {
    setLoading(true)
    try {
      const response = await getMachineReceiptId(token, nameUrl, id)
      setValue('sequenceReceipt', response.data.sequenceReceipt)
      setValue('description', response.data.description)
      setValue('internalParNumber', response.data.internalParNumber)
      setValue('isDelete', response.data.isDelete)
      setValue('subCompanyId', response.data.subCompanyId)
      setSubCompanyId(response.data.subCompanyId) // sincronia adicional
      setLoading(false)
    } catch (error) {
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao cadastrar, verifique os dados e tente novamente')
    }
  }

  const handleCreateMachineReceipt = async object => {
    setLoading(true)

    const data = {
      sequenceReceipt: object.sequenceReceipt,
      description: object.description,
      internalParNumber: object.internalParNumber,
      subCompanyId: object.subCompanyId,
      isDelete: object.isDelete,
    }
    try {
      const response = (await id)
        ? editMachineReceipt(token, nameUrl, data)
        : createMachineReceipt(token, nameUrl, data)
      setLoading(false)
      setExpanded(false)
      setAlerta(true)
      setSeverity('success')
      setTitle('Operação concluída com sucesso!')
      handleFetchData(object.subCompanyId)
      reset()
      setSingleProd({
        sequenceReceipt: '',
        description: '',
        internalParNumber: '',
        isDelete: false,
      })
      setSubCompanyId('')
    } catch (err) {
      console.log(err)
      setLoading(false)
      setAlerta(true)
      setSeverity('error')
      setTitle('Erro ao cadastrar, verifique os dados e tente novamente.2')
    }
  }

  return (
    <>
      <Box>
        <Box
          component="form"
          sx={{ mt: '2rem' }}
          onSubmit={handleSubmit(handleCreateMachineReceipt)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth variant="outlined" error={!!errors.subCompanyId}>
                <InputLabel id="subCompanyId-label">Filial</InputLabel>
                <Select
                  label="Filial"
                  labelId="subCompanyId-label"
                  {...register('subCompanyId', {
                    required: 'Filial é obrigatória',
                  })}
                  value={watch('subCompanyId') || subCompanyId}
                  onChange={e => {
                    setValue('subCompanyId', e.target.value)
                    setSubCompanyId(e.target.value)
                  }}
                >
                  {subCompany.map((item, index) => (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>

                <FormHelperText>{errors.subCompanyId?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  {...register('description', {
                    required: 'Campo obrigatório',
                  })}
                  sx={{ width: '100%' }}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  label="Descrição"
                  error={!!errors.description}
                  helperText={errors.description?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  {...register('sequenceReceipt', {
                    required: 'Campo obrigatório',
                  })}
                  sx={{ width: '100%' }}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  label="Sequência de recebimento"
                  error={!!errors.sequenceReceipt}
                  helperText={errors.sequenceReceipt?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  {...register('internalParNumber', {
                    required: 'Campo obrigatório',
                  })}
                  sx={{ width: '100%' }}
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  label="Número de par interno"
                  error={!!errors.internalParNumber}
                  helperText={errors.internalParNumber?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControlLabel
                control={<Checkbox {...register('isDelete')} defaultChecked={false} />}
                label="Marcar como excluído"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  mt: 3,
                  justifyContent: 'center',
                }}
              >
                <Button variant="contained" type="submit">
                  Salvar
                </Button>

                <Button
                  variant="contained"
                  type="button"
                  onClick={() => setExpanded(false)}
                  sx={{ ml: 2 }}
                >
                  Cancelar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default MachineReceiptForm
