import { useState, useContext, useEffect, useRef, useMemo } from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Context } from '../../../context/AuthContext'
import Alert from '@mui/material/Alert'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
  InputBase,
  Chip,
  Autocomplete,
  Collapse,
  Checkbox,
  Divider,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import styled from '@emotion/styled'
import { colorTableDefault } from '../../TemplateDefault'
import {
  Add,
  DeleteForever,
  Edit,
  ExpandMore,
  Replay,
  KeyboardArrowUp,
  KeyboardArrowDown,
  Save,
  Remove,
  TramRounded,
} from '@mui/icons-material'
import {
  getClientList,
  getProductAll,
  getProductList,
  getSingleProductService,
  searchProductService,
} from '../../../services/productService'
import { createTypeOrder } from '../../../services/requestServices'
import * as XLSX from 'xlsx'
import { useDebounce } from '../Product/Components/useDebounce'
import { searchClientService } from '../../../services/clientService'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const typeOrderOptions = [
  { id: 0, typeOrderName: 'Entrada' },
  { id: 1, typeOrderName: 'Saída' },
  { id: 2, typeOrderName: 'Fornecedor' },
  { id: 3, typeOrderName: 'Divergência' },
  { id: 4, typeOrderName: 'Expedição' },
  { id: 5, typeOrderName: 'Transferência para Depósito' },
  { id: 6, typeOrderName: 'Contagem de Estoque' },
  { id: 7, typeOrderName: 'Entrada em Estoque' },
  { id: 8, typeOrderName: 'Saída do Estoque' },
  { id: 9, typeOrderName: 'Ambos' },
  { id: 10, typeOrderName: 'Pedido de Etiqueta' },
  { id: 11, typeOrderName: 'Conferência' },
  { id: 12, typeOrderName: 'Transferência de Estoque' },
  { id: 13, typeOrderName: 'Conferência Automática com Leitor' },
  { id: 14, typeOrderName: 'Cancelar Pedido de Devolução' },
  { id: 15, typeOrderName: 'Pedido de Retorno' },
  { id: 16, typeOrderName: 'Vendas' },
  { id: 17, typeOrderName: 'Ordem de Produção' },
  { id: 18, typeOrderName: 'Transferência entre Filial e Depósito' },
  { id: 19, typeOrderName: 'Transferência de Local' },
  { id: 20, typeOrderName: 'Lista de Separação' },
  { id: 21, typeOrderName: 'Rotas' },
  { id: 22, typeOrderName: 'Nota Fiscal' },
  { id: 23, typeOrderName: 'Entrada de Nota Fiscal' },
  { id: 24, typeOrderName: 'Saída de Nota Fiscal' },
]

const UpdateMovimentation = () => {
  const { subCompany, company, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [selectedTypeOrder, setSelectedTypeOrder] = useState(0)
  const [companyName, setCompanyName] = useState('')
  const [file, setFile] = useState(null)
  const token = localStorage.getItem('token')
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [titleDialog, setTitleDialog] = useState('')
  const [severity, setSeverity] = useState('error')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [statusMessage, setStatusMessage] = useState({})
  const [loadingMap, setLoadingMap] = useState({})
  const [statusCreate, setStatusCreate] = useState(false)
  const [statusLoadingCreate, setStatusLoadingCreate] = useState(false)
  const [quantity, setQuantity] = useState('')
  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')
  const [failedItems, setFailedItems] = useState([])

  const [observation, setObservation] = useState('')
  const [quantityTotal, setQuantityTotal] = useState()
  const [lote, setLote] = useState('')
  const [datetimeExpiration, setDateTimeExpiration] = useState(new Date())
  const [numberPallet, setNumberPallet] = useState('')
  const [subCompanySelect, setSubCompanySelect] = useState('')
  const [quantityList, setQuantityList] = useState('')

  const { v4: uuidv4 } = require('uuid')

  const [dialogAction, setDialogAction] = useState(null)

  const [singleProd, setSingleProd] = useState({
    id: '',
    movimentationOrderNumberOtherSystem: '',
    idProductPartner: '',
    productId: '',
    name: '',
    quantity: '',
    picking: '',
    sku: '',
    lote: '',
    palette: '',
    referencia: '',
  })

  const [status, setStatus] = useState('Pronto')
  const [deleteId, setDeleteId] = useState('')
  const { debounce } = useDebounce(1000, true)

  const [search, setSearch] = useState('')
  const [searchProduct, setSearchProduct] = useState('')
  const [searchClient, setSearchClient] = useState('')
  const [errors, setErrors] = useState({})
  const [selectedStatus, setSelectedStatus] = useState('')
  const [productUpdateId, setProductUpdateId] = useState('')
  const [subCompanyCNPJ, setSubCompanyCNPJ] = useState('')
  const [searching, setSearching] = useState(false)

  const [edit, setEdit] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)
  const [itensMove, setItensMove] = useState([])
  const [selectedItems, setSelectedItems] = useState([])

  const [filter, setFilter] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [editingItems, setEditingItems] = useState({})
  const [editingIndexProduct, setEditingIndexProduct] = useState(-1)
  const [editingIndexClient, setEditingIndexClient] = useState(-1)
  const [editedValue, setEditedValue] = useState('')
  const [searchProd, setSearchProd] = useState('')
  const [idProd, setIdProd] = useState()

  const [searchingProd, setSearchingProd] = useState(false)
  const [produtos, setProdutos] = useState([])
  const [valueFile, setValueFile] = useState('')
  const ref = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const [product, setProduct] = useState([])
  const [clients, setClients] = useState([])
  const [productItem, setProductItem] = useState([])
  const [clientItem, setClientasItem] = useState([])
  const [selectedId, setSelectedId] = useState('')
  const [selectedClientId, setSelectedClientId] = useState('')
  const [isExternalLoading, setIsExternalLoading] = useState(false)

  const handleEditItem = movimentationOrderNumber => {
    setEditingItems(prevEditingItems => ({
      ...prevEditingItems,
      [movimentationOrderNumber]: !prevEditingItems[movimentationOrderNumber],
    }))
  }

  useEffect(() => {}, [editedValue, editedValue])

  const getTypeOrderName = id => {
    const typeOrderOption = typeOrderOptions.find(option => option.id === id)
    return typeOrderOption ? typeOrderOption.typeOrderName : ''
  }

  const handleSelectFilial = e => {
    setSubCompanySelect(e.target.value)

    const selectCnpj = subCompany.filter(sub => sub.subCompanyId === e.target.value)
    setSubCompanyCNPJ(selectCnpj[0].cnpj)
  }

  const changeProd = async id => {
    try {
      const response = await getSingleProductService(token, nameUrl, { productId: id })
      const prod = response.data

      setSingleProd(prevSingleProd => ({
        ...prevSingleProd,
        idProductPartner: prod.idProductPartner,
        name: prod.name,
        productId: prod.productId,
      }))
    } catch (error) {}
  }

  const handleSaveCodeOuterSystem = movimentationOrderNumberOtherSystem => {
    setSingleProd(prevSingleProd => ({
      ...prevSingleProd,
      movimentationOrderNumberOtherSystem: editedValue,
    }))

    const updatedItensMove = itensMove.map(item => {
      return item.movimentationOrderNumberOtherSystem === movimentationOrderNumberOtherSystem
        ? {
            ...item,
            movimentationOrderNumberOtherSystem: editedValue,
            itensOrder: item.itensOrder
              ? item.itensOrder.map(subItem =>
                  subItem.movimentationOrderNumberOtherSystem ===
                  movimentationOrderNumberOtherSystem
                    ? {
                        ...subItem,
                        movimentationOrderNumberOtherSystem: editedValue,
                      }
                    : subItem
                )
              : undefined,
          }
        : item
    })

    setItensMove(updatedItensMove)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setSelectAll(event.target.value === 'All')
  }

  const handleItemSelect = itemId => {
    setSelectedItems(prevSelectedItems => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter(id => id !== itemId)
      } else {
        return [...prevSelectedItems, itemId]
      }
    })
  }

  const handleSelectAll = event => {
    const isChecked = event.target.checked

    setSelectAll(isChecked)
    setSelectedItems(prevSelectedItems => {
      if (!selectAll) {
        return filter.map((item, index) => item.movimentationOrderNumberOtherSystem)
      } else {
        return []
      }
    })

    setRowsPerPage(isChecked ? filter.length + 1 : 10)
  }

  useEffect(() => {
    if (selectedItems.length === filter.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }, [selectedItems, filter])

  useEffect(() => {}, [singleProd])

  const handleWindowClick = () => {
    if (editingIndexProduct !== -1) {
      setEditingIndexProduct(-1)
    }

    if (editingIndexClient !== -1) {
      setEditingIndexClient(-1)
    }
  }

  useEffect(() => {}, [statusMessage])

  const fetchData = async values => {
    if (!values.target.value) {
      return
    }
    const getValue = values.target.value
    setSubCompanyId(getValue)
    const selectedSubCompany = subCompany.find(item => item.subCompanyId === getValue)
    if (selectedSubCompany) {
      setCompanyName(selectedSubCompany.nameCompany)
    }
  }

  const handleSelectFile = e => {
    setItensMove([])
    setLoading(true)
    setValueFile(e.target.name[0])
    const selectedFile = e.target.files[0]

    if (!selectedFile) {
      setFile(null)
      return
    }
    setFile(selectedFile)
    const reader = new FileReader()

    reader.onload = e => {
      const data = e.target.result
      const workbook = XLSX.read(data, { type: 'binary' })
      const firstSheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[firstSheetName]

      const excelData = []
      const range = XLSX.utils.decode_range(worksheet['!ref'])

      for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
        const row = []
        for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
          const cellAddress = { c: colNum, r: rowNum }
          const cellRef = XLSX.utils.encode_cell(cellAddress)
          const cell = worksheet[cellRef]
          row.push(cell ? cell.v : undefined)
        }

        // Check if the row has any meaningful data
        if (
          row.some(cell => cell !== undefined && cell !== null && cell.toString().trim() !== '')
        ) {
          excelData.push(row)
        }
      }

      const dataList = excelData.map(item => ({
        movimentationOrderNumberOtherSystem: String(item[0]),
        idProductPartner: String(item[1]),
        quantity: String(item[2]),
        lote: String(item[3]),
        codClient: String(item[4]),
        clientName: String(item[5]),
        companyId: company.companyId,
      }))

      console.log(dataList, 'list')
      getCompareListExcel(dataList)
    }
    reader.readAsArrayBuffer(selectedFile)
  }

  const getCompareListExcel = async dataList => {
    const newDataList = dataList.map(item => {
      const matchingTypeOrder = typeOrderOptions.find(name => name.id === selectedTypeOrder)
      const typeOrderName = matchingTypeOrder
        ? matchingTypeOrder.typeOrderName
        : 'Product Not Found'

      return {
        ...item,
        id: uuidv4(),
        typeOrderName: typeOrderName,
      }
    })
    //setItensMove(newDataList)
    handleGetProductList(newDataList)
  }

  useEffect(() => {}, [selectedTypeOrder])
  const resetFile = () => {
    ref.current.value = ''
  }

  const handleGetProductList = async data => {
    const listIdProduct = data.map(item => item.idProductPartner.toString())
    const listCodClient = data.map(item => item.codClient.toString())

    try {
      const response = await getProductList(token, nameUrl, company.companyId, listIdProduct)
      const responseClient = await getClientList(token, nameUrl, subCompanyId, listCodClient)

      const newArrayProduct = data.map(item => {
        const correspondingProduct = response.data.find(
          product => product.idProductPartner === item.idProductPartner
        )

        if (correspondingProduct) {
          const { name, productId, ...rest } = correspondingProduct
          return {
            ...item,
            ...rest,
            productName: name,
            productId: productId,
          }
        } else {
          return item
        }
      })
      const newArrayClientProduct = newArrayProduct.map(item => {
        const correspondingProduct = responseClient.data.find(
          client => client.codClient === item.codClient
        )

        if (correspondingProduct) {
          return {
            ...item,
            ...correspondingProduct,
          }
        } else {
          return item
        }
      })

      const groupedData = newArrayClientProduct.reduce((result, item) => {
        const orderNumber = item.movimentationOrderNumberOtherSystem
        console.log(item.clientId, 'orderNumber')
        if (!result[orderNumber]) {
          result[orderNumber] = {
            movimentationOrderNumberOtherSystem: orderNumber,
            companyName: subCompany[0].nameCompany,
            companyCode: company.companyId,
            clientId: item.clientId,
            name: item.name,
            codClient: item.codClient,
            typeOrder: selectedTypeOrder,
            subCompanyCNPJ: subCompany[0].cnpjCompany,
            itensOrder: [],
          }
        }

        result[orderNumber].itensOrder.push({
          subCompanyCNPJ: subCompany[0].cnpjCompany,
          movimentationOrderNumberOtherSystem: orderNumber,
          productName: item.productName,
          idProductPartner: item.idProductPartner,
          productId: item.productId,
          quantity: parseInt(item.quantity),
          lote: item.lote,
        })

        return result
      }, {})

      const finalResult = Object.values(groupedData)
      const updatedStatusMessages = {}
      finalResult.forEach(item => {
        // Verifica se o clientId é null ou vazio
        if (!item.clientId || item.clientId.trim() === '') {
          updatedStatusMessages[item.movimentationOrderNumberOtherSystem] =
            'Dados do cliente incorretos'

          // Adiciona o item ao estado failedItems
          setFailedItems(prevFailedItems => [...prevFailedItems, item])
        } else {
          updatedStatusMessages[item.movimentationOrderNumberOtherSystem] = 'Pronto para enviar'
        }
      })

      setStatusMessage(prevStatusMessages => ({
        ...prevStatusMessages,
        ...updatedStatusMessages,
      }))
      setItensMove(finalResult)
      setLoading(false)
      setValueFile('')
      resetFile()
    } catch (error) {}
  }

  useEffect(() => {}, [])

  useEffect(() => {}, [singleProd])

  const addToList = single => {
    const prod = singleProd

    const dataList = {
      subCompanyCNPJ: subCompanyCNPJ,
      companyName: companyName,
      typeOrder: selectedTypeOrder,
      movimentationOrderNumberOtherSystem: prod.movimentationOrderNumberOtherSystem,
      codClient: prod.codClient,
      name: prod.clientName,
      clientId: prod.clientId,
      itensOrder: [
        {
          idProductPartner: prod.idProductPartner,
          lote: prod.lote,
          movimentationOrderNumberOtherSystem: prod.movimentationOrderNumberOtherSystem,
          productId: prod.productId,
          productName: prod.name,
          quantity: prod.quantity,
          subCompanyCNPJ: subCompanyCNPJ,
        },
      ],
    }

    setItensMove([...itensMove, dataList])

    resetFields()
    setExpanded(false)
  }

  const resetFields = () => {
    setEdit(false)
    setSingleProd({
      movimentationOrderNumberOtherSystem: '',
      codClient: '',
      clientName: '',
      idProductPartner: '',
      productId: '',
      name: '',
      quantity: '',
    })
  }

  useEffect(() => {}, [selectedTypeOrder])

  useEffect(() => {}, [itensMove])

  const handleGetEdit = single => {
    setEdit(true)
    setExpanded(true)
    setProductUpdateId(single.productId)
    setSingleProd({
      id: single.id,
      movimentationOrderNumberOtherSystem: single.movimentationOrderNumberOtherSystem,
      codClient: single.codClient,
      clientName: single.clientName,
      idProductPartner: single.idProductPartner,
      name: single.productName,
      quantity: single.quantity,
      productId: single.productId,
    })
  }

  const handleActionList = action => {
    if (selectedItems === 0) {
      setSeverity('error')
      setTitle('Selecione um item')
      setAlerta(true)
    } else {
      setOpen(true)
      setTitleDialog('Tem certeza que enviar esses itens?')
      setDialogAction(action)
    }
  }

  const handleRemoveItem = id => {
    const newArray = itensMove.filter(item => item.movimentationOrderNumberOtherSystem !== id)
    setItensMove(newArray)
  }

  const handleRemoveProductItem = (movimentationOrderNumber, productId) => {
    let itemRemoved = false

    const newArray = itensMove.map(item => {
      const updatedItensOrder = item.itensOrder.filter(subItem => {
        if (
          subItem.movimentationOrderNumber === movimentationOrderNumber &&
          subItem.productId === productId &&
          !itemRemoved
        ) {
          itemRemoved = true
          return false
        }

        return TramRounded
      })

      return { ...item, itensOrder: updatedItensOrder }
    })

    console.log(newArray, 'array')
    setItensMove(newArray)
  }

  const handleDeleteItem = () => {
    const updatedItems = filter.filter(item => item.productId !== deleteId)
    setSeverity('success')
    setTitle('Item apagado com sucesso!')
    setAlerta(true)
    setItensMove(updatedItems)
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
    setAlerta(false)
  }

  const filterRow = () => {
    const filtered = itensMove.filter(row => {
      const lowerSearch = search.toLowerCase()
      const status = statusMessage[row.movimentationOrderNumberOtherSystem]

      return (
        (selectedStatus === '' || status === selectedStatus) &&
        (String(row.movimentationOrderNumberOtherSystem).toLowerCase().includes(lowerSearch) ||
          String(row.idProductPartner).toLowerCase().includes(lowerSearch) ||
          String(row.name).toLowerCase().includes(lowerSearch))
      )
    })

    setFilter(filtered)
  }

  const handleGetAllProductsSearch = async () => {
    setPage(0)
    setIsLoading(true)
    const dataProduct = {
      companyId: subCompany[0].companyId,
      limit: 10,
      offset: 0,
      order: 'desc',
      SearchWord: searchProduct,
    }
    try {
      searchProductService(token, nameUrl, dataProduct).then(response => {
        const data = response.data.result
        setProduct(data)
        setIsLoading(false)
      })
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  const handleGetAllClientsSearch = async () => {
    setPage(0)
    setIsLoading(true)
    const dataClient = {
      subCompanyId: subCompany[0].subCompanyId,
      search: searchClient,
    }
    try {
      searchClientService(token, nameUrl, dataClient).then(response => {
        const data = response.data
        setClients(data)
        setIsLoading(false)
      })
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (searchProduct !== '') {
      setIsLoading(true)
      debounce(() => {
        handleGetAllProductsSearch()
      })
    }
  }, [searchProduct])

  useEffect(() => {
    if (searchClient !== '') {
      console.log(searchClient, 'searchClient')
      setIsLoading(true)
      debounce(() => {
        handleGetAllClientsSearch()
      })
    }
  }, [searchClient])

  const autoCompleteSelectedOptions = useMemo(() => {
    if (!selectedId) return undefined

    const selectedOptions = product.find(item => item.id === selectedId)

    return selectedOptions
  }, [selectedId, product])

  const autoCompleteSelectedOptionsClient = useMemo(() => {
    if (!selectedClientId) return undefined

    const selectedOptions = clients.find(item => item.clientId === selectedClientId)
    return selectedOptions
  }, [selectedClientId, clients])

  const handleCreateTypeOrderSelected = async () => {
    setStatusCreate(true)
    setStatusLoadingCreate(true)
    const arrayFilter = itensMove.filter(objeto =>
      selectedItems.includes(objeto.movimentationOrderNumberOtherSystem)
    )
    try {
      for (const data of arrayFilter) {
        setLoadingMap(prevLoadingMap => ({
          ...prevLoadingMap,
          [data.movimentationOrderNumberOtherSystem]: true,
        }))
        const response = await createTypeOrder(token, nameUrl, [data])
        setLoadingMap(prevLoadingMap => ({
          ...prevLoadingMap,
          [data.movimentationOrderNumberOtherSystem]: false,
        }))
        const updatedStatusMessages = {}
        updatedStatusMessages[data.movimentationOrderNumberOtherSystem] =
          'Registro feito com sucesso'
        setStatusMessage(prevStatusMessages => ({
          ...prevStatusMessages,
          ...updatedStatusMessages,
        }))

        const newlySuccessfullySentItems = data.itensOrder.map(
          prod => prod.movimentationOrderNumberOtherSystem
        )

        await new Promise(resolve => setTimeout(resolve, 1000))

        setItensMove(prevItems =>
          prevItems.filter(
            item => !newlySuccessfullySentItems.includes(item.movimentationOrderNumberOtherSystem)
          )
        )
      }
      setStatusCreate(false)
      setStatusLoadingCreate(false)
      setSelectedItems([])
    } catch (error) {
      console.error(error)
      const updatedStatusMessages = {}
      updatedStatusMessages[error.movimentationOrderNumberOtherSystem] = 'Falha ao enviar'
      setStatusMessage(prevStatusMessages => ({
        ...prevStatusMessages,
        ...updatedStatusMessages,
      }))
      setLoadingMap(prevLoadingMap => ({
        ...prevLoadingMap,
        [error.movimentationOrderNumberOtherSystem]: false,
      }))
      setStatusCreate(false)
      setStatusLoadingCreate(false)
    }
  }

  const handleCreateTypeOrder = async () => {
    setStatusCreate(true)
    setStatusLoadingCreate(true)
    setFailedItems([])
    for (const currentItem of itensMove) {
      setLoadingMap(prevLoadingMap => ({
        ...prevLoadingMap,
        [currentItem.movimentationOrderNumberOtherSystem]: true,
      }))
      console.log(currentItem, 'currentItem')

      try {
        // if (!currentItem.clientId || currentItem.clientId.trim() === '') {
        //   const updatedStatusMessages = {}
        //   updatedStatusMessages[currentItem.movimentationOrderNumberOtherSystem] =
        //     'Dados do cliente incorretos'
        //   setStatusMessage(prevStatusMessages => ({
        //     ...prevStatusMessages,
        //     ...updatedStatusMessages,
        //   }))

        //   setFailedItems(prevFailedItems => [...prevFailedItems, currentItem])
        //   continue
        // }

        await createTypeOrder(token, nameUrl, [currentItem])

        const updatedStatusMessages = {}
        updatedStatusMessages[currentItem.movimentationOrderNumberOtherSystem] =
          'Registro feito com sucesso'
        setStatusMessage(prevStatusMessages => ({
          ...prevStatusMessages,
          ...updatedStatusMessages,
        }))

        const newlySuccessfullySentItems = currentItem.itensOrder.map(
          prod => prod.movimentationOrderNumberOtherSystem
        )

        await new Promise(resolve => setTimeout(resolve, 1000))

        setItensMove(prevItems =>
          prevItems.filter(
            item => !newlySuccessfullySentItems.includes(item.movimentationOrderNumberOtherSystem)
          )
        )
        setStatusCreate(false)
        setStatusLoadingCreate(false)
      } catch (error) {
        setLoadingMap(prevLoadingMap => ({
          ...prevLoadingMap,
          [error.movimentationOrderNumberOtherSystem]: false,
        }))
        setStatusCreate(false)
        setStatusLoadingCreate(false)
        const updatedStatusMessages = {}
        updatedStatusMessages[currentItem.movimentationOrderNumberOtherSystem] = 'Falha ao enviar'
        setStatusMessage(prevStatusMessages => ({
          ...prevStatusMessages,
          ...updatedStatusMessages,
        }))
        setFailedItems(prevFailedItems => [...prevFailedItems, currentItem])
      } finally {
        setLoadingMap(prevLoadingMap => ({
          ...prevLoadingMap,
          [currentItem.movimentationOrderNumberOtherSystem]: false,
        }))
        setSelectedItems([])
        setStatusCreate(false)
        setStatusLoadingCreate(false)
      }
    }
    setStatusCreate(false)
    setStatusLoadingCreate(false)
  }

  const handleSaveUnity = async data => {
    setStatusCreate(true)
    setStatusLoadingCreate(true)
    setLoadingMap(prevLoadingMap => ({
      ...prevLoadingMap,
      [data.movimentationOrderNumberOtherSystem]: true,
    }))
    try {
      const response = await createTypeOrder(token, nameUrl, [data])
      setLoadingMap(prevLoadingMap => ({
        ...prevLoadingMap,
        [data.movimentationOrderNumberOtherSystem]: false,
      }))
      const updatedStatusMessages = {}
      updatedStatusMessages[data.movimentationOrderNumberOtherSystem] = 'Registro feito com sucesso'
      setStatusMessage(prevStatusMessages => ({
        ...prevStatusMessages,
        ...updatedStatusMessages,
      }))

      const newlySuccessfullySentItems = data.itensOrder.map(
        prod => prod.movimentationOrderNumberOtherSystem
      )

      await new Promise(resolve => setTimeout(resolve, 1000))

      setItensMove(prevItems =>
        prevItems.filter(
          item => !newlySuccessfullySentItems.includes(item.movimentationOrderNumberOtherSystem)
        )
      )
      setStatusCreate(false)
      setStatusLoadingCreate(false)
      setSelectedItems([])
    } catch (error) {
      console.error(error)
      const updatedStatusMessages = {}
      updatedStatusMessages[error.movimentationOrderNumberOtherSystem] = 'Falha ao enviar'
      setStatusMessage(prevStatusMessages => ({
        ...prevStatusMessages,
        ...updatedStatusMessages,
      }))
      setLoadingMap(prevLoadingMap => ({
        ...prevLoadingMap,
        [error.movimentationOrderNumberOtherSystem]: false,
      }))
      setStatusCreate(false)
      setStatusLoadingCreate(false)
    }
  }

  useEffect(() => {
    filterRow()
  }, [search, itensMove])

  useEffect(() => {}, [selectedStatus])

  function Row({ row, index }) {
    const { movimentationOrderNumberOtherSystem } = row
    const [open, setOpen] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [editValue, setEditValue] = useState(row.movimentationOrderNumberOtherSystem)

    const handleDoubleClick = () => {
      setIsEditing(true)
    }
    const handleSaveCodeOuter = movimentationOrderNumberOtherSystem => {
      setSingleProd(prevSingleProd => ({
        ...prevSingleProd,
        movimentationOrderNumberOtherSystem: editValue,
      }))

      const updatedItensMove = itensMove.map(item => {
        return item.movimentationOrderNumberOtherSystem === movimentationOrderNumberOtherSystem
          ? {
              ...item,
              movimentationOrderNumberOtherSystem: editValue,
              itensOrder: item.itensOrder
                ? item.itensOrder.map(subItem =>
                    subItem.movimentationOrderNumberOtherSystem ===
                    movimentationOrderNumberOtherSystem
                      ? {
                          ...subItem,
                          movimentationOrderNumberOtherSystem: editValue,
                        }
                      : subItem
                  )
                : undefined,
            }
          : item
      })

      setItensMove(updatedItensMove)
    }

    return (
      <>
        <StyledTableRow key={row.movimentationOrderNumberOtherSystem}>
          <StyledTableCell align="center">
            <Checkbox
              disabled={statusCreate}
              checked={selectedItems.includes(row.movimentationOrderNumberOtherSystem)}
              onChange={() => handleItemSelect(row.movimentationOrderNumberOtherSystem)}
            />
          </StyledTableCell>
          <StyledTableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell align="center">
            {isEditing ? (
              <>
                <Paper
                  component="form"
                  sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    value={editValue}
                    onChange={e => {
                      setEditValue(e.target.value)
                    }}
                    inputProps={{ 'aria-label': 'search google maps' }}
                  />

                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    type="button"
                    sx={{ p: '10px' }}
                    aria-label="search"
                    onClick={() => {
                      handleSaveCodeOuter(row.movimentationOrderNumberOtherSystem)
                    }}
                  >
                    <Save />
                  </IconButton>
                </Paper>
              </>
            ) : (
              <Box display="flex" alignItems="center">
                <Typography>{movimentationOrderNumberOtherSystem}</Typography>
                <Tooltip title="Editar" arrow>
                  <IconButton
                    disabled={statusCreate}
                    onClick={() => {
                      //setEditedValue(row.movimentationOrderNumberOtherSystem)
                      handleDoubleClick()
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </StyledTableCell>
          <StyledTableCell align="center">{row.companyName}</StyledTableCell>

          <StyledTableCell align="center">{getTypeOrderName(row.typeOrder)}</StyledTableCell>
          <StyledTableCell align="center">{row.codClient}</StyledTableCell>
          <StyledTableCell align="center">{row.name}</StyledTableCell>
          <StyledTableCell align="center">
            {loadingMap[row.movimentationOrderNumberOtherSystem] ? (
              <>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <CircularProgress value={0} sx={{ color: colorTableDefault }} size={30} />
                  <Typography ml={2} variant="caption">
                    Enviando...
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                {(() => {
                  const status = statusMessage[row.movimentationOrderNumberOtherSystem]

                  if (status === 'Ordem já existe') {
                    return <Chip color="error" variant="filled" label={status || ''} />
                  } else if (status === 'Falha ao enviar') {
                    return <Chip color="error" variant="filled" label={status || ''} />
                  } else if (status === 'Registro feito com sucesso') {
                    return <Chip color="success" variant="filled" label={`${status}` || ''} />
                  } else if (status === 'Dados do cliente incorretos') {
                    return <Chip color="error" variant="filled" label={`${status}` || ''} />
                  } else {
                    return <Chip color="info" variant="filled" label={`Pronto para enviar`} />
                  }
                })()}
              </>
            )}
          </StyledTableCell>
          <StyledTableCell align="center">
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Tooltip title="Reenviar" arrow>
                <IconButton disabled={statusCreate} onClick={() => handleSaveUnity(row)}>
                  <Replay sx={{ cursor: 'pointer' }} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Apagar" arrow>
                <IconButton
                  onClick={() =>
                    handleRemoveItem(row.movimentationOrderNumberOtherSystem, 'delete')
                  }
                >
                  <DeleteForever sx={{ cursor: 'pointer' }} />
                </IconButton>
              </Tooltip>
            </Box>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 0,
              paddingRight: 0,
              width: '100%',
            }}
            colSpan={15}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell align="left">Ordem (Outro sistema)</StyledTableCell>
                      <StyledTableCell align="left">Codigo do produto</StyledTableCell>
                      <StyledTableCell align="left">Nome do produto</StyledTableCell>
                      <StyledTableCell align="left">Quantidade total</StyledTableCell>
                      <StyledTableCell align="left">Lote</StyledTableCell>{' '}
                      <StyledTableCell align="center" width="10%">
                        Ação
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {row.itensOrder &&
                      row.itensOrder.map((item, index) => (
                        <StyledTableRow key={item.idProductPartner}>
                          <StyledTableCell align="left">
                            {item.movimentationOrderNumberOtherSystem}
                          </StyledTableCell>
                          <StyledTableCell align="left">{item.idProductPartner}</StyledTableCell>
                          <StyledTableCell align="left">{item.productName}</StyledTableCell>
                          <StyledTableCell align="left">{item.quantity}</StyledTableCell>
                          <StyledTableCell align="left">{item.lote}</StyledTableCell>
                          <StyledTableCell align="center">
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                              <Tooltip title="Apagar" arrow>
                                <IconButton
                                  onClick={() =>
                                    handleRemoveProductItem(
                                      item.movimentationOrderNumber,
                                      item.productId
                                    )
                                  }
                                >
                                  <DeleteForever sx={{ cursor: 'pointer' }} />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="Editar" arrow>
                                <IconButton
                                  disabled={statusCreate}
                                  onClick={() => handleGetEdit(item)}
                                >
                                  <Edit sx={{ cursor: 'pointer' }} />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      </>
    )
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }} onClick={handleWindowClick}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>{titleDialog}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              onClick={() => {
                handleClose()
                if (dialogAction === 'delete') {
                  handleDeleteItem()
                } else if (dialogAction === 'save') {
                  handleCreateTypeOrderSelected()
                }
                setDialogAction(null)
              }}
            >
              Sim
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>

        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Carga de Movimentação</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Accordion expanded={expanded} sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography variant="body1">
              {edit ? 'Editar Movimentação' : 'Cadastrar Movimentação'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                width: 1,
                mt: 2,
                display: 'flex',
                gap: 2,
                border: '1px solid black',
                borderRadius: 2,
                p: 2,
                flexDirection: 'column',
              }}
            >
              {selectedTypeOrder === 10 ? (
                <>
                  <Box sx={{ width: 1, mt: 2, display: 'flex', gap: 2 }}>
                    <FormControl sx={{ width: 0.5 }}>
                      <InputLabel id="filial">Filial</InputLabel>
                      <Select
                        label="Filial"
                        name="filial"
                        onChange={handleSelectFilial}
                        value={subCompanySelect}
                      >
                        {subCompany.map((item, index) => {
                          return (
                            <MenuItem value={item.subCompanyId} key={index}>
                              {item.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ width: 0.5 }}>
                      <TextField
                        sx={{ width: '100%' }}
                        disabled={edit}
                        value={singleProd.movimentationOrderNumberOtherSystem}
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        label="Ordem (Outro sistema)"
                        onChange={e =>
                          setSingleProd(curr => ({
                            ...curr,
                            movimentationOrderNumberOtherSystem: e.target.value,
                          }))
                        }
                      />
                    </FormControl>
                  </Box>
                  <Box sx={{ width: 1, mt: 2, display: 'flex', gap: 2 }}>
                    <Box sx={{ width: 0.5, gap: 2, display: 'flex' }}>
                      <FormControl sx={{ width: 1 }}>
                        <TextField
                          sx={{ width: '100%' }}
                          value={observation}
                          onChange={values => setObservation(values.target.value)}
                          id="outlined-basic"
                          label="Observação"
                          variant="outlined"
                          type="text"
                        />
                      </FormControl>
                    </Box>
                    <FormControl sx={{ width: 0.5 }}>
                      <Autocomplete
                        openText="Abrir"
                        closeText="Fechar"
                        clearText="Apagar"
                        noOptionsText={isLoading ? 'Carregando...' : 'Nenhum resultado encontrado'}
                        loadingText="Carregando..."
                        disablePortal
                        value={autoCompleteSelectedOptions}
                        loading={isLoading}
                        onInputChange={(_, newValue) => setSearchProduct(newValue)}
                        disabled={isExternalLoading}
                        popupIcon={
                          isExternalLoading || isLoading ? (
                            <CircularProgress color="warning" size={20} />
                          ) : undefined
                        }
                        onChange={(_, newValue) => {
                          setSelectedId(newValue?.productId)
                          console.log(newValue, 'newww')
                          setSingleProd(prevSingleProd => ({
                            ...prevSingleProd,
                            movimentationOrderNumberOtherSystem:
                              singleProd.movimentationOrderNumberOtherSystem,
                            idProductPartner: newValue.idProductPartner,
                            name: newValue.name,
                            quantity: quantity,
                            productId: newValue.productId,
                          }))
                          setProductItem(newValue)
                          setSearchProduct(newValue ? newValue.idProductPartner : '')
                          if (newValue === null) {
                            handleGetAllProductsSearch()
                          }
                        }}
                        options={product}
                        getOptionLabel={option => option.name}
                        renderInput={params => <TextField {...params} label="Pesquisar produtos" />}
                      />
                    </FormControl>
                  </Box>
                  {/* <Divider sx={{ width: 1, mt: 2 }} /> */}
                  <Box
                    sx={{
                      width: 1,
                      mt: 2,
                      display: 'flex',
                      gap: 2,
                      border: '1px solid black',
                      borderRadius: 2,
                      p: 2,
                      flexDirection: 'column',
                    }}
                  >
                    <Box sx={{ width: 1, display: 'flex', gap: 2 }}>
                      <Box sx={{ display: 'flex', gap: 2, width: 0.5 }}>
                        <FormControl sx={{ width: 1 }}>
                          <TextField
                            sx={{ width: '100%' }}
                            value={singleProd.name}
                            id="outlined-basic"
                            variant="outlined"
                            type="text"
                            label="Nome Produto"
                            disabled
                          />
                        </FormControl>
                      </Box>
                      <Box sx={{ width: 0.5, gap: 2, display: 'flex' }}>
                        <FormControl sx={{ width: 0.5 }}>
                          <TextField
                            sx={{ width: '100%' }}
                            value={singleProd.referencia}
                            id="outlined-basic"
                            disabled
                            variant="outlined"
                            type="text"
                            label="Referência"
                          />
                        </FormControl>
                        <FormControl sx={{ width: 0.5 }}>
                          <TextField
                            sx={{ width: '100%' }}
                            value={singleProd.sku}
                            id="outlined-basic"
                            variant="outlined"
                            label="SKU"
                            type="text"
                            disabled
                          />
                        </FormControl>
                      </Box>
                    </Box>
                    <Box sx={{ width: 1, display: 'flex', gap: 2 }}>
                      <Box sx={{ display: 'flex', gap: 2, width: 0.5 }}>
                        <FormControl sx={{ width: 1 }}>
                          <TextField
                            sx={{ width: '100%' }}
                            value={lote}
                            id="outlined-basic"
                            variant="outlined"
                            type="text"
                            label="Lote"
                            onChange={e => setLote(e.target.value)}
                          />
                        </FormControl>
                      </Box>
                      <Box sx={{ display: 'flex', gap: 2, width: 0.5 }}>
                        <FormControl sx={{ width: 1 }}>
                          <TextField
                            sx={{ width: '100%' }}
                            value={quantityList}
                            id="outlined-basic"
                            variant="outlined"
                            type="text"
                            label="Quantidade"
                            onChange={e => setQuantityList(e.target.value)}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                    <Box sx={{ width: 1, gap: 2, display: 'flex' }}>
                      <FormControl sx={{ width: 0.5 }}>
                        <TextField
                          sx={{ width: '100%' }}
                          value={datetimeExpiration}
                          id="outlined-basic"
                          variant="outlined"
                          type="month"
                          label="Data de validade"
                          onChange={e => setDateTimeExpiration(e.target.value)}
                        />
                      </FormControl>
                      <FormControl sx={{ width: 0.5 }}>
                        <TextField
                          sx={{ width: '100%' }}
                          value={numberPallet}
                          id="outlined-basic"
                          variant="outlined"
                          label="Número de palete"
                          type="text"
                          onChange={e => {
                            setNumberPallet(e.target.value)
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box sx={{ width: 1, mt: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
                      <Box sx={{ width: 0.25 }}>
                        <Button variant="outlined" onClick={addToList}>
                          Adicionar à lista
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <FormControl sx={{ width: '100%' }}>
                      <TextField
                        sx={{ width: '100%' }}
                        disabled={edit}
                        value={singleProd.movimentationOrderNumberOtherSystem}
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        label="Ordem (Outro sistema)"
                        onChange={e =>
                          setSingleProd(curr => ({
                            ...curr,
                            movimentationOrderNumberOtherSystem: e.target.value,
                          }))
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl sx={{ width: 1 }}>
                      <Autocomplete
                        openText="Abrir"
                        closeText="Fechar"
                        clearText="Apagar"
                        noOptionsText={isLoading ? 'Carregando...' : 'Nenhum resultado encontrado'}
                        loadingText="Carregando..."
                        disablePortal
                        value={autoCompleteSelectedOptions}
                        loading={isLoading}
                        onInputChange={(_, newValue) => setSearchProduct(newValue)}
                        disabled={isExternalLoading}
                        popupIcon={
                          isExternalLoading || isLoading ? (
                            <CircularProgress color="warning" size={20} />
                          ) : undefined
                        }
                        onChange={(_, newValue) => {
                          setSelectedId(newValue?.productId)
                          console.log(newValue, 'newww')

                          setSingleProd(prevSingleProd => ({
                            ...prevSingleProd,
                            movimentationOrderNumberOtherSystem:
                              singleProd.movimentationOrderNumberOtherSystem,
                            idProductPartner: newValue.idProductPartner,
                            name: newValue.name,
                            quantity: quantity,
                            productId: newValue.productId,
                          }))

                          setProductItem(newValue)
                          setSearchProduct(newValue ? newValue.idProductPartner : '')
                          if (newValue === null) {
                            handleGetAllProductsSearch()
                          }
                        }}
                        options={product}
                        getOptionLabel={option => option.name}
                        renderInput={params => <TextField {...params} label="Pesquisar produtos" />}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl sx={{ width: '100%' }}>
                      <TextField
                        disabled
                        sx={{ width: '100%' }}
                        value={singleProd.name}
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        label={singleProd.name ? '' : 'Nome do produto'}
                        inputProps={{ maxLength: 18 }}
                        onChange={e => {
                          setSingleProd(curr => ({
                            ...curr,
                            name: e.target.value,
                          }))
                        }}
                        error={!!errors.name}
                        helperText={errors.name}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl sx={{ width: 1 }}>
                      <Autocomplete
                        openText="Abrir"
                        closeText="Fechar"
                        clearText="Apagar"
                        noOptionsText={isLoading ? 'Carregando...' : 'Nenhum resultado encontrado'}
                        loadingText="Carregando..."
                        disablePortal
                        value={autoCompleteSelectedOptionsClient}
                        loading={isLoading}
                        onInputChange={(_, newValue) => setSearchClient(newValue)}
                        disabled={isExternalLoading}
                        popupIcon={
                          isExternalLoading || isLoading ? (
                            <CircularProgress color="warning" size={20} />
                          ) : undefined
                        }
                        onChange={(_, newValue) => {
                          setSelectedId(newValue?.clientId)
                          setSingleProd(prevSingleProd => ({
                            ...prevSingleProd,
                            movimentationOrderNumberOtherSystem:
                              singleProd.movimentationOrderNumberOtherSystem,
                            clientId: newValue.clientId,
                            codClient: newValue.codClient,
                            clientName: newValue.name,
                          }))

                          setProductItem(newValue)
                          setSearchClient(newValue ? newValue.codClient : '')
                          if (newValue === null) {
                            handleGetAllClientsSearch()
                          }
                        }}
                        options={clients}
                        getOptionLabel={option => option.name}
                        renderInput={params => <TextField {...params} label="Pesquisar cliente" />}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormControl sx={{ width: '100%' }}>
                      <TextField
                        disabled
                        sx={{ width: '100%' }}
                        value={singleProd.clientName}
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        label={singleProd.clientName ? '' : 'Nome do cliente'}
                        inputProps={{ maxLength: 18 }}
                        onChange={e => {
                          setSingleProd(curr => ({
                            ...curr,
                            clientName: e.target.value,
                          }))
                        }}
                        error={!!errors.clientName}
                        helperText={errors.clientName}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl sx={{ width: '100%' }}>
                      <TextField
                        sx={{ width: '100%' }}
                        value={singleProd.quantity}
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        label="Quantidade Total"
                        inputProps={{ maxLength: 9 }}
                        onChange={e => {
                          setSingleProd(curr => ({
                            ...curr,
                            quantity: e.target.value,
                          }))
                        }}
                        error={!!errors.quantity}
                        helperText={errors.quantity}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl sx={{ width: '100%' }}>
                      <TextField
                        sx={{ width: '100%' }}
                        value={status}
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        label="Status"
                        disabled
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                  >
                    <Button variant="outlined" onClick={() => addToList(singleProd)}>
                      {edit ? 'Atualizar Cliente' : 'Adicionar à lista'}
                    </Button>
                    {edit && (
                      <Button
                        variant="contained"
                        sx={{ ml: 1, display: 'flex', alignItems: 'center' }}
                        onClick={() => {
                          resetFields()
                        }}
                      >
                        Adicionar novo cliente <Add sx={{ ml: 1 }} />
                      </Button>
                    )}
                  </Grid>
                </Grid>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {' '}
            <FormControl sx={{ width: '100%', mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                value={subCompanyId}
                sx={{ width: '100%' }}
                label="Escolher a filial"
                onChange={fetchData}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl sx={{ width: '100%', mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Tipo de pedido</InputLabel>
              <Select
                disabled={!subCompanyId}
                value={selectedTypeOrder}
                sx={{ width: '100%' }}
                label="Escolher o pedido"
                onChange={e => {
                  setSelectedTypeOrder(e.target.value)
                }}
              >
                {typeOrderOptions.map((item, index) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.typeOrderName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Box
          sx={{ justifyContent: 'space-between', width: 1, minWidth: 1 }}
          display={{ xs: 'column', sm: 'column', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box
            sx={{
              alignItems: 'center',
              textAlign: 'center',
              mt: 2,
              border: '1px solid black',
              width: { xs: 1, md: 0.34 },
              minWidth: { xs: 1, md: 0.3 },
              p: 2,
            }}
          >
            <a
              className="dropzone"
              target="_blank"
              rel="noreferrer"
              href="https://file.bihands.com/template/UploadMovimentation.xlsx"
              download
            >
              <FileDownloadIcon sx={{ fontSize: 100 }} />
            </a>
            <InputLabel>Faça o download do exemplo de carga</InputLabel>
          </Box>
          <Box
            sx={{
              mt: 2,
              border: { md: '1px solid black' },
              p: 2,
            }}
            width={{ xs: '100%', sm: '100%', md: '65%', lg: '65%', xl: '65%' }}
          >
            <InputLabel>Insira aqui o documento para enviar</InputLabel>
            <TextField
              disabled={!selectedTypeOrder}
              sx={{ width: '100%', mt: 1 }}
              id="standard-basic"
              variant="standard"
              value={valueFile}
              onChange={handleSelectFile}
              ref={ref}
              type="file"
              accept=".xls, .xlsx, .ods"
            />
            <Box
              sx={{
                mt: 4,
                alignItems: 'center',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>Apenas arquivos .XLS ou .XLSX</Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    sx={{ width: '100%', mb: 1 }}
                    id="standard-basic"
                    variant="outlined"
                    label="Procurar"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    type="text"
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-label">Filtrar Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedStatus}
                      onChange={e => setSelectedStatus(e.target.value)}
                    >
                      <MenuItem selected value="">
                        Todos
                      </MenuItem>
                      <MenuItem value="Registro feito com sucesso">Enviados</MenuItem>
                      <MenuItem value="Pronto para enviar">Pronto para enviar</MenuItem>
                      <MenuItem value="Falha ao enviar">Falha ao enviar</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="space-between" alignItems="end">
                <Box>
                  <Typography variant="body" sx={{ marginBottom: 2, mr: 0 }}>
                    {filter.length <= 1
                      ? `${filter.length} Resultado`
                      : `${filter.length} Resultados`}
                  </Typography>
                  {selectedItems.length > 0 && (
                    <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                      {selectedItems.length <= 1
                        ? `${selectedItems.length} Item Selecionado`
                        : `${selectedItems.length} Itens Selecionados`}
                    </Typography>
                  )}
                  {failedItems.length > 0 && (
                    <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                      {failedItems.length <= 1
                        ? `${failedItems.length} Item com falha`
                        : `${failedItems.length} Itens com falhas`}
                    </Typography>
                  )}
                </Box>
                <Box display="flex" my={2}>
                  {selectedItems.length > 0 ? (
                    <Button
                      variant="contained"
                      disabled={statusLoadingCreate}
                      startIcon={
                        statusLoadingCreate && <CircularProgress color="inherit" size={20} />
                      }
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: ' space-between',
                        fontSize: '.9em',
                      }}
                      onClick={() => handleActionList('save')}
                    >
                      {statusLoadingCreate ? 'Enviando...' : 'Enviar Selecionados'}
                    </Button>
                  ) : (
                    <Button
                      disabled={itensMove.length === 0 || statusLoadingCreate}
                      variant="contained"
                      startIcon={
                        statusLoadingCreate && <CircularProgress color="inherit" size={20} />
                      }
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: ' space-between',
                        fontSize: '.9em',
                      }}
                      onClick={() => handleCreateTypeOrder()}
                    >
                      {statusLoadingCreate ? 'Enviando...' : 'Enviar Lista'}
                    </Button>
                  )}
                  <Button
                    sx={{ ml: 2 }}
                    disabled={statusLoadingCreate}
                    variant="contained"
                    startIcon={expanded ? <Remove size={20} /> : <Add size={20} />}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: ' space-between',
                      fontSize: '.9em',
                    }}
                    onClick={() => {
                      if (expanded) {
                        setExpanded(false)
                        setSingleProd({
                          name: '',
                          quantity: '',
                          productId: '',
                          id: '',
                          idProductPartner: '',
                          movimentationOrderNumberOtherSystem: '',
                        })
                        setEdit(false)
                      } else {
                        setExpanded(true)
                      }
                    }}
                  >
                    {expanded ? 'Cancelar' : 'Cadastrar'}
                  </Button>
                </Box>
              </Box>
            </Box>
            {selectedTypeOrder === 11 || selectedTypeOrder === 4 || selectedTypeOrder === 10 ? (
              <Table sx={{ minWidth: 700, mt: 1 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">
                      <Box display="flex" flexDirection="column" alignItems="center">
                        <Tooltip title="Selecionar Todos" arrow>
                          <span>
                            <Checkbox
                              disabled={statusCreate}
                              checked={selectAll}
                              onChange={handleSelectAll}
                              color="primary"
                            />
                          </span>
                        </Tooltip>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ width: 100 }}></StyledTableCell>
                    <StyledTableCell align="left">Ordem (Outro sistema)</StyledTableCell>
                    <StyledTableCell align="left">Filial</StyledTableCell>
                    <StyledTableCell align="left">Tipo de Pedido</StyledTableCell>
                    <StyledTableCell align="left">Codigo do cliente</StyledTableCell>
                    <StyledTableCell align="left">Nome do cliente</StyledTableCell>
                    <StyledTableCell align="center">Status</StyledTableCell>
                    <StyledTableCell align="center" width="10%">
                      Ação
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filter
                    .filter(
                      row => selectedStatus === '' || statusMessage[row.id] === selectedStatus
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <Row row={row} key={index} />
                    ))}
                </TableBody>
              </Table>
            ) : (
              <Table sx={{ minWidth: 700, mt: 1 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">
                      <Box display="flex" flexDirection="column" alignItems="center">
                        <Tooltip title="Selecionar Todos" arrow>
                          <span>
                            <Checkbox
                              disabled={statusCreate}
                              checked={selectAll}
                              onChange={handleSelectAll}
                              color="primary"
                            />
                          </span>
                        </Tooltip>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="center">Ordem (Outro sistema)</StyledTableCell>
                    <StyledTableCell align="center">Filial</StyledTableCell>
                    <StyledTableCell align="center">Tipo de Pedido</StyledTableCell>
                    <StyledTableCell align="center">Codigo do cliente</StyledTableCell>
                    <StyledTableCell align="center">Nome do cliente</StyledTableCell>
                    <StyledTableCell align="center">Status</StyledTableCell>
                    <StyledTableCell align="center" width="10%">
                      Ação
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filter
                    .filter(
                      row =>
                        selectedStatus === '' ||
                        statusMessage[row.movimentationOrderNumberOtherSystem] === selectedStatus
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <StyledTableRow key={row.movimentationOrderNumberOtherSystem}>
                        <StyledTableCell align="center">
                          <Checkbox
                            disabled={statusCreate}
                            checked={selectedItems.includes(
                              row.movimentationOrderNumberOtherSystem
                            )}
                            onChange={() =>
                              handleItemSelect(row.movimentationOrderNumberOtherSystem)
                            }
                          />
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {editingItems[row.movimentationOrderNumberOtherSystem] ? (
                            <>
                              <Paper
                                component="form"
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                              >
                                <InputBase
                                  sx={{ ml: 1, flex: 1 }}
                                  value={editedValue}
                                  onChange={e => setEditedValue(e.target.value)}
                                  inputProps={{ 'aria-label': 'search google maps' }}
                                />
                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                <IconButton
                                  type="button"
                                  sx={{ p: '10px' }}
                                  aria-label="search"
                                  onClick={() => {
                                    handleSaveCodeOuterSystem(
                                      row.movimentationOrderNumberOtherSystem
                                    )
                                  }}
                                >
                                  <Save />
                                </IconButton>
                              </Paper>
                            </>
                          ) : (
                            <>
                              <Box display="flex" alignItems="center">
                                <Typography>{row.movimentationOrderNumberOtherSystem}</Typography>
                                <Tooltip title="Editar" arrow>
                                  <IconButton
                                    onClick={() => {
                                      handleEditItem(row.movimentationOrderNumberOtherSystem)
                                      setEditedValue(row.movimentationOrderNumberOtherSystem)
                                    }}
                                    disabled={statusCreate}
                                  >
                                    <Edit />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </>
                          )}
                        </StyledTableCell>

                        <StyledTableCell align="center">{companyName}</StyledTableCell>

                        <StyledTableCell align="center">
                          {getTypeOrderName(row.typeOrder)}
                        </StyledTableCell>
                        <StyledTableCell align="center">{row.codClient}</StyledTableCell>
                        <StyledTableCell align="center">{row.name}</StyledTableCell>

                        <StyledTableCell align="center">
                          {loadingMap[row.movimentationOrderNumberOtherSystem] ? (
                            <>
                              <Box display="flex" alignItems="center" justifyContent="center">
                                <CircularProgress
                                  value={0}
                                  sx={{ color: colorTableDefault }}
                                  size={30}
                                />
                                <Typography ml={2} variant="caption">
                                  Enviando...
                                </Typography>
                              </Box>
                            </>
                          ) : (
                            <>
                              {(() => {
                                const status =
                                  statusMessage[row.movimentationOrderNumberOtherSystem]

                                if (status === 'Ordem já existe') {
                                  return (
                                    <Chip color="error" variant="filled" label={status || ''} />
                                  )
                                } else if (status === 'Falha ao enviar') {
                                  return (
                                    <Chip color="error" variant="filled" label={status || ''} />
                                  )
                                } else if (status === 'Registro feito com sucesso') {
                                  return (
                                    <Chip
                                      color="success"
                                      variant="filled"
                                      label={`${status}` || ''}
                                    />
                                  )
                                } else {
                                  return (
                                    <Chip
                                      color="info"
                                      variant="filled"
                                      label={`Pronto para enviar`}
                                    />
                                  )
                                }
                              })()}
                            </>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Tooltip title="Reenviar" arrow>
                              <IconButton onClick={() => handleSaveUnity(row)}>
                                <Replay sx={{ cursor: 'pointer' }} />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Apagar" arrow>
                              <IconButton
                                disabled={statusCreate}
                                onClick={() =>
                                  handleRemoveItem(
                                    row.movimentationOrderNumberOtherSystem,
                                    'delete'
                                  )
                                }
                              >
                                <DeleteForever sx={{ cursor: 'pointer' }} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            )}

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filter.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default UpdateMovimentation
