import { useCallback, useRef } from 'react'

export const useDebounce = (delay = 3000000, notDelayInFirstTime = true) => {
  const isFirstTime = useRef(notDelayInFirstTime)
  const debouncing = useRef()

  const debounce = useCallback(
    func => {
      if (isFirstTime.current) {
        isFirstTime.current = false
        func()
      } else {
        if (debouncing.current) {
          clearTimeout(debouncing.current)
        }
        debouncing.current = setTimeout(() => func(), delay)
      }
    },
    [delay]
  )

  return { debounce }
}
