import React, { useContext } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '../../../context/AuthContext'
import {
  deleteMovBreakItemProcess,
  getAllMovOrderService,
} from '../../../services/movBreakServices'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { colorTableDefault } from '../../TemplateDefault'
import { AiFillEye, AiFillPlusCircle } from 'react-icons/ai'
import ViewElearning from '../../ViewElearning'
import PermissionComponent from '../../../permissions'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const MovimentationBreak = () => {
  const { userRoles, nameUrl, subCompany } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const token = localStorage.getItem('token')
  const [movOrders, setMovOrders] = useState([])
  const [originalList, setOriginalList] = useState([])
  const [loading, setLoading] = useState(false)

  const [open, setOpen] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [severity, setSeverity] = useState('success')
  const [title, setTitle] = useState('')
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'

  const requestSearch = searched => {
    const bihandsArray = originalList.filter(item =>
      item.movimentationOrderNumberBihands.includes(searched)
    )
    const otherArray = originalList.filter(item =>
      item.movimentationOrderNumberOtherSystem.includes(searched)
    )
    const arrayFinal = Array.from(new Set(bihandsArray.concat(otherArray)))

    setMovOrders(arrayFinal)
  }

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const navigate = useNavigate()
  const [viewElearning, setViewElearning] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const fetchData = async () => {
    if (!subCompanyId || subCompanyId === '') {
      return
    }
    setLoading(true)
    const dados = {
      SubCompanyId: subCompanyId,
      limit: 1000,
      offset: 0,
    }
    const response = await getAllMovOrderService(token, nameUrl, dados)
    setMovOrders(response.data.result)
    setOriginalList(response.data.result)
    //setPageCount(response.data.totalpage + 1)
    setLoading(false)
  }

  useEffect(() => {}, [])

  useEffect(() => {
    fetchData()
  }, [subCompanyId])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    try {
      await deleteMovBreakItemProcess(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Ordem deletada com sucesso!')

      setAlerta(true)
      setLoading(false)
      fetchData()
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Ordem de Produção</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <ViewElearning pageName={'MovBreak'} open={viewElearning} setOpen={setViewElearning} />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
          }}
        >
          <SelectSubCompany
            value={subCompanyId}
            onChange={e => {
              setSubCompanyId(e.target.value)
            }}
          />
        </Box>
        <Box
          sx={{
            mt: 3,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', ml: 1 }}
          >
            <PermissionComponent role="Create_MovimentationBreak">
              <Button
                onClick={() => {
                  navigate('/MovBreakCreate')
                }}
                variant="contained"
                endIcon={<AiFillPlusCircle />}
              >
                Criar
              </Button>
            </PermissionComponent>
          </Box>
        </Box>
        <TextField
          variant="outlined"
          placeholder="Procurar por Ordem"
          type="search"
          onInput={e => requestSearch(e.target.value)}
        />

        <TableContainer component={Paper} sx={{ mt: '2rem' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell> </StyledTableCell>
                <StyledTableCell align="center">Ordem Bihands</StyledTableCell>
                <StyledTableCell align="center">Ordem (outro sistema)</StyledTableCell>
                <StyledTableCell align="center">Quantidade Total</StyledTableCell>
                <StyledTableCell align="center">Nome do Usuario</StyledTableCell>
                <PermissionComponent role="FIELD_ClientName">
                  <StyledTableCell align="center">Nome do Cliente</StyledTableCell>
                </PermissionComponent>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Criado</StyledTableCell>
              </TableRow>
            </TableHead>
            {movOrders.length > 0 && (
              <TableBody>
                {movOrders
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">
                        <PermissionComponent role="Create_MovimentationBreak">
                          <IconButton
                            size="1.5em"
                            onClick={() => navigate(`/MovBreakEdit/${row.movimentationOrderId}`)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <AiFillEye />
                          </IconButton>
                        </PermissionComponent>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {row.movimentationOrderNumberBihands}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {row.movimentationOrderNumberOtherSystem}
                      </StyledTableCell>

                      <StyledTableCell align="center">{row.quantityTotal}</StyledTableCell>
                      <PermissionComponent role="FIELD_ClientName">
                        <StyledTableCell align="center">{row.clientName}</StyledTableCell>
                      </PermissionComponent>
                      <StyledTableCell align="center">{row.userName}</StyledTableCell>
                      <StyledTableCell align="center">
                        {row.movimentationOrderStatus === 1
                          ? 'Aberto'
                          : row.movimentationOrderStatus === 2
                          ? 'Concluído'
                          : row.movimentationOrderStatus === 3
                          ? 'Importado'
                          : row.movimentationOrderStatus === 4
                          ? 'Na Fila'
                          : row.movimentationOrderStatus === 5
                          ? 'Em processo'
                          : row.movimentationOrderStatus === 0
                          ? 'Conferência em espera'
                          : row.movimentationOrderStatus === -1
                          ? 'Rascunho'
                          : row.movimentationOrderStatus === -2
                          ? 'Cancelar'
                          : row.movimentationOrderStatus === -3
                          ? 'Todos'
                          : row.movimentationOrderStatus === 99
                          ? 'Divergência'
                          : row.movimentationOrderStatus === -99
                          ? 'Apagado'
                          : ''}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {new Date(row.createRequestMovimentationOrderDate).toLocaleString()}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            )}
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={movOrders.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default MovimentationBreak
