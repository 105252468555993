import axios from 'axios'

//get paginação
export const getLaundry = async (token, nameUrl, data) => {
  let orderToString
  if (data.order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }
  const response = await axios.get(
    `/${nameUrl}/api/PrinterLaundry/GetAllPagination?limit=${data.limit}&offset=${data.offset}&order${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//get laundry especifica
export const getLaundryId = async (token, nameUrl, id) => {
  const response = await axios.get(`/${nameUrl}/api/PrinterLaundry/Get/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//criação
export const createLaundry = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/PrinterLaundry/Create`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//delete
export const deleteLaundry = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/PrinterLaundry/Delete`,
    {
      printerLaundryId: data,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//edit
export const editLaundry = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/PrinterLaundry/Edit`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}
