import {
  Alert,
  Backdrop,
  Box,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import HelpIcon from '@mui/icons-material/Help'
import { Context } from '../../../../context/AuthContext'
import {
  getAllMovOrderBreakOnGoingService,
  getInfoKanbanService,
  getMovBreakListService,
} from '../../../../services/movBreakServices'
import { getUniqueTransferOrderService } from '../../../../services/requestServices'

import BreakCard from './components/BreakCard'
import ViewElearning from '../../../ViewElearning'

const MovBreakKanban = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const token = localStorage.getItem('token')
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('')

  const [subCompanyId, setSubCompanyId] = useState('')
  const [orders, setOrders] = useState([])
  const [currentOrder, setCurrentOrder] = useState({ movimentationOrderId: '' })
  const [currentBreak, setCurrentBreak] = useState()
  const [viewElearning, setViewElearning] = useState(false)

  const [loading, setLoading] = useState(false)

  const ReturnStatus = props => {
    const { numero } = props
    let colorFont = '#000000'
    let texto = 'Aberto'

    if (numero === 1) {
      texto = 'Aberto'
      colorFont = '#000000'
    } else if (numero === 2) {
      colorFont = '#008000'
      texto = 'Completo'
    } else if (numero === 3) {
      colorFont = '#FFA500'
      texto = 'Em andamento'
    } else if (numero === -2) {
      colorFont = '#FF0000'
      texto = 'Cancelado'
    } else if (numero === -1) {
      texto = 'Rascunho'
    }
    const styleStatus = { color: `${colorFont}` }
    return <span style={styleStatus}>{texto}</span>
  }

  const fetchData = async values => {
    setSubCompanyId(values)
    setLoading(true)
    const dados = {
      SubCompanyId: values,
      limit: 1000,
      offset: 0,
    }
    const response = await getAllMovOrderBreakOnGoingService(token, nameUrl, dados)
    setOrders(response.data.result)
    // setPageCount(response.data.totalpage + 1);
    setLoading(false)
  }

  const fetchOrder = async values => {
    setCurrentOrder({ movimentationOrderId: '' })
    try {
      const response = await getInfoKanbanService(token, nameUrl, {
        movimentationOrderId: values,
        subCompanyId,
      })
      if (response.data.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        const breakOrder = numero => {
          return parseInt(numero.split('-')[1])
        }
        const respFinal = response.data[0]
        const arrayFinal = respFinal.listMovimentationOrderCollection.sort(
          (a, b) =>
            breakOrder(a.movimentationOrderNumberOtherSystemBreak) -
            breakOrder(b.movimentationOrderNumberOtherSystemBreak)
        )
        respFinal.listMovimentationOrderCollection = arrayFinal
        setCurrentOrder(() => respFinal)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const fetchSingleBreak = async values => {
    //const response = await getMovBreakService(token, nameUrl, values)

    //setCurrentBreak(() => response.data)
    const singleBreak = currentOrder.listMovimentationOrderCollection.find(
      x => x.movimentationOrderNumberOtherSystemBreak === values
    )
    setCurrentBreak(() => singleBreak)
  }

  const handleClose = () => {
    setAlerta(false)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <ViewElearning
          pageName={'MovBreakKanban'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Kanban - Processos ativos</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon onClick={() => setViewElearning(true)} />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            gap: 4,
          }}
        >
          <FormControl sx={{ width: '50%' }}>
            <InputLabel id="subcompId-label">Filial</InputLabel>
            <Select
              label="Selecione a empresa"
              onChange={e => fetchData(e.target.value)}
              value={subCompanyId}
            >
              {subCompany.map(item => {
                return <MenuItem value={item.subCompanyId}>{item.name}</MenuItem>
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ width: '50%' }}>
            <InputLabel id="subcompId-label">Ordem</InputLabel>
            <Select
              label="Ordem"
              onChange={e => fetchOrder(e.target.value)}
              value={currentOrder.movimentationOrderId}
            >
              {orders.map(item => {
                return (
                  <MenuItem value={item.movimentationOrderId}>
                    {item.movimentationOrderNumberOtherSystem}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
      <Box sx={{ display: 'flex', maxWidth: '100%', gap: 2 }}>
        <Box
          component="div"
          sx={{
            //overflowX: 'auto',
            maxWidth: '100%',
            minWidth: '100%',
            //maxHeight: '80vh',
            display: 'flex',
            flexDirection: 'column',
            my: 2,
            p: 1,
            //border: "1px solid",
            //borderRadius: 2,
            gap: 1,
          }}
        >
          {currentOrder.movimentationOrderId && (
            <Box>
              <Card raised sx={{ minWidth: '250px', mt: 2, marginBottom: 4 }}>
                <CardContent>
                  <Typography variant="h6" component="div">
                    #{currentOrder.movimentationOrderNumberOtherSystem} - {currentOrder.productName}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Criado em {new Date(currentOrder.createDateOrder).toLocaleString()}
                  </Typography>
                  <Typography variant="body2">
                    Status: <ReturnStatus numero={currentOrder.orderStatus} />
                  </Typography>
                  <Typography variant="body2">
                    Quantidade: {currentOrder.quantityTotalOrder}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          )}
          {currentOrder.listMovimentationOrderCollection && (
            <>
              <Box sx={{ display: 'flex', overflowX: 'auto' }}>
                {currentOrder.listMovimentationOrderCollection.map(item => (
                  <>
                    <BreakCard info={item} fetchSingleBreak={fetchSingleBreak} />
                  </>
                ))}
              </Box>
            </>
          )}
        </Box>

        {/* <Box sx={{ maxWidth: '40%', minWidth: '40%' }}>
          {currentBreak && (
            <>
              <Paper sx={{ minWidth: '100%', marginTop: 4, padding: 4 }}>
                <Typography>
                  Ordem #{currentBreak.movimentationOrderNumberOtherSystemBreak}
                </Typography>
                <Typography>Status: {currentBreak.orderStatus}</Typography>
                <br />
                <Typography>
                  Criado: {new Date(currentBreak.createDateOrderBreak).toLocaleString()}
                </Typography>
              </Paper>
              {currentBreak.movimentationBreakKambanOrderItemProcessCollection && (
                <>
                  <Box
                    sx={{
                      overflowY: 'auto',
                      overflowX: 'hidden',
                      maxHeight: '60vh',
                      display: 'flex',
                      flexDirection: 'column',
                      my: 2,
                      ml: 0,
                      p: 1,
                      //border: "1px solid",
                      //borderRadius: 2,
                      gap: 0,
                      width: 1,
                    }}
                  >
                    <Typography sx={{ textAlign: 'center' }}>Processos</Typography>
                    {currentBreak.movimentationBreakKambanOrderItemProcessCollection.length ===
                      0 && (
                      <>
                        <Typography sx={{ textAlign: 'center', color: 'red' }}>
                          Processo não iniciado. Selecione outro pedido na lateral
                        </Typography>
                      </>
                    )}
                    {currentBreak.movimentationBreakKambanOrderItemProcessCollection.map(i => (
                      <>
                        <Box
                          sx={{
                            border: '1px solid black',
                            borderRadius: 1,
                            width: 0.9,
                            padding: 2,
                            margin: 1,
                          }}
                        >
                          <Typography>{i.processSequenceName && i.processSequenceName}</Typography>
                          <Typography>
                            Início: {new Date(i.startDateProcess).toLocaleString()}
                          </Typography>
                          <Typography>Usuário: {i.userNameStart}</Typography>
                          <br />
                          <Typography>
                            {i.qualityStatus && `Qualidade: ${i.qualityStatus}`}
                          </Typography>
                          <br />
                          <Typography>
                            {i.serialActual && `Serial Atual: ${i.serialActual}`}
                          </Typography>
                          <Typography>
                            {i.serialLast && `Último Serial: ${i.serialLast}`}
                          </Typography>
                          <br />
                        </Box>
                      </>
                    ))}
                  </Box>
                </>
              )}
            </>
          )}
        </Box> */}
      </Box>
    </>
  )
}

export default MovBreakKanban
