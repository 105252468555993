import React, { useContext } from 'react'
import { useEffect, useState } from 'react'

import {
  divergenceStatus,
  getOnlyOrder,
  getReportInventoryService,
} from '../../../services/reportServices'
import { Context } from '../../../context/AuthContext'
import * as XLSX from 'xlsx'
import PropTypes from 'prop-types'
import HelpIcon from '@mui/icons-material/Help'

import {
  Alert,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

//icones react-icons
import { AiOutlineFileExcel } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'
import { ArrowDownward, ArrowUpward, ChatBubble } from '@mui/icons-material'
import { format } from 'date-fns'

const ScalableIconButton = styled(IconButton)`
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }
`

const ReportInventory = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [orders, setOrders] = useState()
  const [loading, setLoading] = useState(false)
  const [orderSelect, setOrderSelect] = useState('')
  const [itemstockWmsId, setItemstockWmsId] = useState('')
  const token = localStorage.getItem('token')
  const [inventoryBalance, setInventoryBalance] = useState([])
  const [pagination, setPagination] = useState({})
  const [statusJustify, setStatusJustify] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [justify, setJustify] = useState('')
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = React.useState(false)

  // apagar daqui pra baixo se der erro e apagar o import xlsx

  const [progressCount, setProgressCount] = useState(0)
  const [downloadMessageCount, setDownloadMessageCount] = useState('Pronto')
  const [downloadingCount, setDownloadingCount] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [selectedItems, setSelectedItems] = useState([])
  const [operationStatus, setOperationStatus] = useState({})
  const [filterValue, setFilterValue] = useState('')
  const [totalResults, setTotalResults] = useState(0)
  const [filteredTotalResults, setFilteredTotalResults] = useState(0)
  const [balanceStockFilterData, setBalanceStockFilterData] = useState([])
  const [filteringInventory, setFilteringInventory] = useState(false)
  const [filteredInventoryBalance, setFilteredInventoryBalance] = useState([])
  const [originalInventoryBalance, setOriginalInventoryBalance] = useState([])
  const [currentInventoryBalance, setCurrentInventoryBalance] = useState([])

  const [selectAll, setSelectAll] = useState(false)

  const [notSearch, setNotSearch] = useState(false)

  const [sortDirection, setSortDirection] = useState({
    location: null,
    product: null,
    resultFinal: null,
    shippingStatus: null,
  })
  const [orderBy, setOrderBy] = useState('')

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
    '&.ok': {
      backgroundColor: 'green',
      color: 'white',
    },
    '&.nok': {
      backgroundColor: '#D21919',
      color: 'white',
    },
    '&.selected': {
      backgroundColor: 'yellow',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const formatDate = date => {
    const dateHr = new Date(date)
    return format(dateHr, 'dd/MM/yyyy HH:mm:ss')
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    const rowsPerPageValue = event.target.value
    setRowsPerPage(rowsPerPageValue)

    setSelectAll(rowsPerPageValue === 'All')

    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const downloadDataCount = async () => {
    setDownloadMessageCount('Inicializando')
    setDownloadingCount(true)

    finishDownloadCount(inventoryBalance.length)
  }

  useEffect(() => {
    if (selectedItems.length === balanceStockFilterData.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }, [selectedItems, balanceStockFilterData])

  useEffect(() => {
    const filter = inventoryBalance.filter(item => item.locationOld === 'A6304')

    console.log(filter, 'filtesr')
  }, [])

  useEffect(() => {
    const handleFilter = () => {
      if (filterValue !== '') {
        setNotSearch(false)
        setFilteringInventory(true)
        const newData = inventoryBalance.filter(
          data =>
            data.locationActual.toLowerCase().includes(filterValue.toLowerCase()) ||
            data.locationOld.toLowerCase().includes(filterValue.toLowerCase()) ||
            data.productNameActual.toLowerCase().includes(filterValue.toLowerCase()) ||
            data.productNameOld.toLowerCase().includes(filterValue.toLowerCase())
        )

        setFilteringInventory(false)
        if (newData.length > 0) {
          setBalanceStockFilterData(newData)
          setPage(0)
          setFilteredTotalResults(newData.length)
          setCurrentInventoryBalance(newData)
        } else {
          setNotSearch(true)

          setBalanceStockFilterData([])
          setFilteredTotalResults(0)
          setCurrentInventoryBalance([])
        }
      } else {
        setBalanceStockFilterData(inventoryBalance)
        setFilteredTotalResults(totalResults)
        setCurrentInventoryBalance(inventoryBalance)
      }
    }
    handleFilter()
  }, [filterValue, inventoryBalance, totalResults])

  const clearFilter = () => {
    setFilterValue('')
    setSelectedItems([])
    setOrderBy('')
    setSortDirection({
      location: null,
      products: null,
      resultFinal: null,
      shippingStatus: null,
    })
    setRowsPerPage(10)
    setFilteredInventoryBalance(inventoryBalance)
  }

  const finishDownloadCount = async pages => {
    let result = []

    for (let i = 0; i <= pages; i++) {
      setDownloadMessageCount(`Baixando ${i} de ${pages}...`)
      setProgressCount((i / pages) * 100)
    }
    const inventoryBalanceDTO = {
      subCompanyId: subCompanyId,
      movimentationOrderId: orderSelect,
    }
    const resp = await getReportInventoryService(
      token,
      nameUrl,
      inventoryBalanceDTO.movimentationOrderId
    )
    result = result.concat(resp.data)
    setDownloadMessageCount(`Seu arquivo está pronto.`)
    setDownloadingCount(false)
    const relatorioFinalContagem = []
    result.map(item => {
      relatorioFinalContagem.push({
        'Locação Atual': item.locationActual,
        'Locação Antiga': item.locationOld,
        'Nome do Produto Atual': item.productNameActual,
        'Nome do produto Antiga': item.productNameOld,
        'Associação de resultados': item.resultAssociation,
        'Resultado Final': item.resultFinal,
        'Itens de produto de resultado': item.resultProductItens,
        'Data da Leitura': new Date(item.createDate).toLocaleString(),
        'Justificativa	': item.descriptionDivergence,
        'Status da Ação': item.divergenceStatus,
        'Data do ajuste	': item.updateAdjustCreateDate,
        'Nome de quem ajustou': item.userAdjust,
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinalContagem)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório Balanço de estoque')
    XLSX.writeFile(workbook, `Relatório Balanço de estoque.xlsx`)
    setProgressCount(0)
  }

  const getInventoryBalance = async () => {
    setLoading(true)
    const InventoryBalanceDTO = {
      movimentationOrderId: orderSelect,
    }

    try {
      const response = await getReportInventoryService(
        token,
        InventoryBalanceDTO.movimentationOrderId
      )

      setItemstockWmsId(response.data)

      if (response.data.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setInventoryBalance(response.data)
        console.log(response.data, 'dsata')
        setPagination(response.data)
        setFilteredInventoryBalance(response.data)
        setTotalResults(response.data.length)
        setFilteredTotalResults(response.data.length)
        setOriginalInventoryBalance(response.data)
        setBalanceStockFilterData(response.data)
        setCurrentInventoryBalance(response.data)
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const actionStatus = async () => {
    if (justify === '') {
      setSeverity('error')
      setTitle('Descreva sua Justificativa!')
      setAlerta(true)
    } else {
      setOpen(false)
      setLoading(true)
      setStatusJustify(true)

      for (const item of selectedItems) {
        setOperationStatus(prevStatusMap => ({
          ...prevStatusMap,
          [item]: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CircularProgress sx={{ marginRight: '5px' }} size={20} /> Enviando...
            </div>
          ),
        }))

        try {
          const itemStokWmsId = {
            description: justify,
            movimentationOrderItemStockWmsId: item,
          }
          const response = await divergenceStatus(token, nameUrl, itemStokWmsId)
          setOperationStatus(prevStatusMap => ({
            ...prevStatusMap,
            [item]: response.data.message,
          }))

          setInventoryBalance(prevInventory =>
            prevInventory.filter(invItem => invItem.movimentationOrderItemStockWmsId !== item)
          )

          setSelectedItems(prevSelectedItems => prevSelectedItems.filter(id => id !== item))

          setJustify('')
        } catch (error) {
          console.log(error)
        }
      }
      await new Promise(resolve => setTimeout(resolve, 1000))
    }

    setLoading(false)
  }

  const actionStatusUnit = async () => {
    if (justify === '') {
      setSeverity('error')
      setTitle('Descreva sua Justificativa!')
      setAlerta(true)
    } else {
      setOpen(false)
      setLoading(true)
      setStatusJustify(true)
      setOperationStatus({})
      setOperationStatus(prevStatus => ({ ...prevStatus, [itemstockWmsId]: 'Carregando...' }))
      await new Promise(resolve => setTimeout(resolve, 0))

      const ItemStokWmsId = {
        description: justify,
        movimentationOrderItemStockWmsId: itemstockWmsId,
      }
      try {
        const response = await divergenceStatus(token, nameUrl, ItemStokWmsId)

        setOperationStatus(prevStatus => ({
          ...prevStatus,
          [itemstockWmsId]: response.data.message,
        }))
        setJustify('')
        setInventoryBalance(prevInventory =>
          prevInventory.filter(
            invItem => invItem.movimentationOrderItemStockWmsId !== itemstockWmsId
          )
        )

        setSelectedItems(prevSelectedItems => prevSelectedItems.filter(id => id !== itemstockWmsId))
      } catch (error) {
        console.log(error)
      } finally {
        await new Promise(resolve => setTimeout(resolve, 500))
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    setFilteredTotalResults(balanceStockFilterData.length)
  }, [balanceStockFilterData])

  const handleAction = id => {
    if (selectedItems.length === 0) {
      setSeverity('error')
      setTitle('Selecione um item')
      setAlerta(true)
    } else {
      setItemstockWmsId(id)
      setOpen(true)
    }
  }

  const handleActionUnit = id => {
    setItemstockWmsId(id)
    setOpen(true)
  }

  const getOrders = async e => {
    setSubCompanyId(e.target.value)
    try {
      const response = await getOnlyOrder(token, nameUrl, e.target.value)

      setOrders(response.data)
    } catch (e) {
      console.log(e)
    }
  }

  const handleOrder = e => {
    const newOrder = e.target.value
    setOrderSelect(newOrder)
  }

  const handleClose = () => {
    setJustify('')
    setOpen(false)
    setAlerta(false)
  }

  const handleItemSelect = itemId => {
    setSelectedItems(prevSelectedItems => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter(id => id !== itemId)
      } else {
        return [...prevSelectedItems, itemId]
      }
    })
  }

  const handleSelectAll = event => {
    const isChecked = event.target.checked
    setSelectAll(isChecked)
    setSelectAll(prevSelectAll => !prevSelectAll)
    setSelectedItems(prevSelectedItems => {
      if (!selectAll) {
        return balanceStockFilterData.map(item => item.movimentationOrderItemStockWmsId)
      } else {
        return []
      }
    })
    setRowsPerPage(isChecked ? balanceStockFilterData.length + 1 : '10')
  }

  const handleSort = field => {
    setSortDirection(prevSortDirection => {
      const newSortDirection = {
        ...prevSortDirection,
        [field]: prevSortDirection[field] === 'asc' ? 'desc' : 'asc',
      }
      setOrderBy(field)

      return newSortDirection
    })
  }

  const sortItems = (items, field) => {
    const sortedItems = [...items]
    sortedItems.sort((a, b) => {
      if (field === 'location') {
        if (a.locationActual < b.locationActual) return -1
        if (a.locationActual > b.locationActual) return 1
        return 0
      } else if (field === 'products') {
        if (a.productNameActual < b.productNameActual) return -1
        if (a.productNameActual > b.productNameActual) return 1
        return 0
      } else if (field === 'resultFinal') {
        if (a.resultFinal < b.resultFinal) return -1
        if (a.resultFinal > b.resultFinal) return 1
        return 0
      } else if (field === 'shippingStatus') {
        if (a.shippingStatus < b.shippingStatus) return -1
        if (a.shippingStatus > b.shippingStatus) return 1
      }
    })
    if (sortDirection[field] === 'desc') {
      sortedItems.reverse()
    }

    return sortedItems
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={downloadingCount}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessageCount}</DialogTitle>
            <LinearProgressWithLabel value={progressCount} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            {selectedItems.length === 0
              ? 'Descreva sua justificativa.'
              : `Descreva sua justificativa para ${selectedItems.length} Itens.`}
          </DialogTitle>
          <DialogContent>
            <Box width="100%">
              <TextareaAutosize
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '5px',
                  outline: 'none',
                  resize: 'none',
                  fontSize: '16px',
                  borderColor: '#bfbfbf',
                }}
                minRows={1}
                maxRows={10}
                ja
                estou
                value={justify}
                onChange={e => setJustify(e.target.value)}
                aria-label="minimum height"
                required
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancela</Button>
            <Button onClick={selectedItems.length > 0 ? actionStatus : actionStatusUnit}>
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
        <ViewElearning
          pageName={'ReportInventory'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Relatorio de Balanço de Estoque</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 2,
            mt: 2,
          }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
            <InputLabel id="demo-simple-select-label">Filial</InputLabel>
            <Select
              label="Selecione a filial"
              value={subCompanyId}
              onChange={getOrders}
              sx={{ width: '100%' }}
            >
              {subCompany.map((item, index) => {
                return (
                  <MenuItem value={item.subCompanyId} key={index}>
                    {item.name}
                  </MenuItem>
                )
              })}
            </Select>
          </Box>
          {orders && (
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <InputLabel id="demo-simple-select-label">Pedido</InputLabel>
              <Select
                label="Selecione a filial"
                value={orderSelect}
                onChange={handleOrder}
                sx={{ width: '100%' }}
              >
                {orders.map((item, index) => {
                  return (
                    <MenuItem value={item.movimentationOrderId} key={index}>
                      {item.movimentationOrderNumberOtherSystem}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
          )}
        </Box>
        <Button variant="contained" onClick={getInventoryBalance} disabled={!orderSelect}>
          Pesquisar
        </Button>
        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            mb: 2,
            width: '100%',
          }}
        >
          <Box display="flex" alignItems="center" width="100%" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Typography variant="h6">{filteredTotalResults} Resultados</Typography>
              {selectedItems.length > 0 && (
                <Typography ml={2} variant="h6">
                  {selectedItems.length}{' '}
                  {selectedItems.length === 1 ? 'Item Selecionado' : 'Itens Selecionados'}
                </Typography>
              )}
            </Box>
            {inventoryBalance.length > 0 && (
              <Button
                variant="contained"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: ' space-between',
                  fontSize: '.9em',
                  width: '6.5rem',
                  marginLeft: '5px',
                }}
                onClick={downloadDataCount}
              >
                Excel <AiOutlineFileExcel size="2em" />
              </Button>
            )}
          </Box>
          {inventoryBalance.length > 0 && (
            <Box display="flex" justifyContent="space-between" width="100%" sx={{ mt: 2 }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width={{ xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' }}
              >
                <Box>
                  <TextField
                    variant="outlined"
                    label={!notSearch ? 'Filtrar Tabela' : 'Produto Não encontrado'}
                    type="text"
                    error={notSearch}
                    value={filterValue}
                    onChange={e => setFilterValue(e.target.value)}
                  />
                </Box>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: '.9em',
                  }}
                  onClick={clearFilter}
                >
                  Limpar Filtros
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        <TableContainer component={Paper} sx={{ mt: '2rem' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">
                  <Button
                    variant="contained"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: ' space-between',
                      fontSize: '.9em',
                      backgroundColor: '#011528',
                    }}
                    onClick={handleAction}
                  >
                    Enviar Justificativa
                  </Button>

                  <Tooltip title="Selecionar Todos" arrow>
                    <span>
                      <Checkbox checked={selectAll} onChange={handleSelectAll} color="primary" />
                    </span>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell align="left">Locação Atual</StyledTableCell>
                <StyledTableCell align="left">Locação Antiga</StyledTableCell>
                <StyledTableCell align="left">Nome do Produto Atual</StyledTableCell>
                <StyledTableCell align="left">Nome do produto Antiga</StyledTableCell>
                <StyledTableCell align="left">
                  <TableSortLabel
                    variant="contained"
                    onClick={() => handleSort('location')}
                    hideSortIcon={true}
                  >
                    Locação
                    {sortDirection['location'] === 'asc' && <ArrowUpward />}
                    {sortDirection['location'] === 'desc' && <ArrowDownward />}
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <TableSortLabel
                    variant="contained"
                    onClick={() => handleSort('products')}
                    hideSortIcon={true}
                  >
                    Produtos
                    {sortDirection['products'] === 'asc' && <ArrowUpward />}
                    {sortDirection['products'] === 'desc' && <ArrowDownward />}
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <TableSortLabel
                    variant="contained"
                    onClick={() => handleSort('resultFinal')}
                    hideSortIcon={true}
                  >
                    Resultado Final
                    {sortDirection['resultFinal'] === 'asc' && <ArrowUpward />}
                    {sortDirection['resultFinal'] === 'desc' && <ArrowDownward />}
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="left">Data da Leitura</StyledTableCell>
                <StyledTableCell align="left">Ações</StyledTableCell>
                <StyledTableCell align="left">
                  <TableSortLabel
                    variant="contained"
                    onClick={() => handleSort('shippingStatus')}
                    hideSortIcon={true}
                  >
                    Status de envio {sortDirection['shippingStatus'] === 'asc' && <ArrowUpward />}
                    {sortDirection['shippingStatus'] === 'desc' && <ArrowDownward />}
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="left">Justificativa</StyledTableCell>
                {statusJustify && (
                  <StyledTableCell align="left">Status da Operacao</StyledTableCell>
                )}
                <StyledTableCell align="left">Data do ajuste</StyledTableCell>
                <StyledTableCell align="left">Nome de quem ajustou</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortItems(balanceStockFilterData, orderBy)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(
                  (row, index) =>
                    !row.removed && (
                      <StyledTableRow
                        key={row.movimentationOrderItemStockWmsId}
                        style={
                          selectedItems.includes(row.movimentationOrderItemStockWmsId)
                            ? { backgroundColor: '#ccc', zIndex: '5' }
                            : {}
                        }
                      >
                        <StyledTableCell align="center">
                          <Checkbox
                            checked={selectedItems.includes(row.movimentationOrderItemStockWmsId)}
                            onChange={() => handleItemSelect(row.movimentationOrderItemStockWmsId)}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.locationActual}</StyledTableCell>
                        <StyledTableCell align="left">{row.locationOld}</StyledTableCell>
                        <StyledTableCell align="left">{row.productNameActual}</StyledTableCell>
                        <StyledTableCell align="left">{row.productNameOld}</StyledTableCell>
                        <StyledTableCell
                          align="center"
                          className={row.resultAssociation ? 'ok' : 'nok'}
                        >
                          {row.resultAssociation ? 'OK' : 'NOK'}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          className={row.resultProductItens ? 'ok' : 'nok'}
                        >
                          {row.resultProductItens ? 'OK' : 'NOK'}
                        </StyledTableCell>
                        <StyledTableCell align="center" className={row.resultFinal ? 'ok' : 'nok'}>
                          {row.resultFinal ? 'OK' : 'NOK'}
                        </StyledTableCell>
                        <StyledTableCell align="left">{formatDate(row.createDate)}</StyledTableCell>
                        <StyledTableCell align="center">
                          {row.resultAssociation === true &&
                          row.resultProductItens === true &&
                          row.resultFinal === true &&
                          row.divergenceStatus === true ? null : (
                            <Tooltip title="Descreva sua justificativa" arrow>
                              <span>
                                <ScalableIconButton
                                  disabled={selectedItems.length > 0}
                                  onClick={() =>
                                    handleActionUnit(row.movimentationOrderItemStockWmsId)
                                  }
                                >
                                  <ChatBubble />
                                </ScalableIconButton>
                              </span>
                            </Tooltip>
                          )}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          className={row.divergenceStatus ? 'ok' : 'nok'}
                        >
                          {row.divergenceStatus ? 'OK' : 'NOK'}
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.descriptionDivergence}</StyledTableCell>
                        {statusJustify && (
                          <StyledTableCell align="left">
                            {operationStatus[row.movimentationOrderItemStockWmsId] || ''}
                          </StyledTableCell>
                        )}

                        <StyledTableCell align="left">
                          {formatDate(row.updateAdjustCreateDate)}
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.userAdjust}</StyledTableCell>
                      </StyledTableRow>
                    )
                )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={balanceStockFilterData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ReportInventory
