import axios from 'axios'

//Adição
export const pickToLightAdd = async (token, nameUrl, item) => {
  try {
    const response = await axios.post(
      `/${nameUrl}/api/PickToLight/PickToLightAdd`,
      {
        movimentationOrderId: item.movimentationOrderId,
        serial: item.serial,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    )

    return response
  } catch (e) {
    console.log(e)
  }
}

export const pickToLightControl = async (token, nameUrl, orderSelect) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderItemERP/Get?MovimentationOrderId=${orderSelect}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Refazer
export const pickToLightUndo = async (token, nameUrl, item) => {
  const response = await axios.post(
    `/${nameUrl}/api/PickToLight/PickToLightUndo`,
    {
      movimentationOrderId: item,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Cancelar
export const pickToLightCancel = async (token, nameUrl, item) => {
  const response = await axios.post(
    `/${nameUrl}/api/PickToLight/PickToLightCancel`,
    {
      movimentationOrderId: item,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//finalização conferencia

export const sendConference = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/PickToLight/PickToLightFinish`,
    {
      movimentationOrderId: data,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//upload excel
//upload excel
export const uploadConference = async (token, nameUrl, formData) => {
  var tokenFinal = 'Bearer ' + token

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
      Authorization: tokenFinal,
    },
  }
  const response = await axios.post(
    `/${nameUrl}api/MovimentationOrder/UploadConference`,
    formData,
    config
  )

  return response
}

export const pickToLightPDF = async (token, nameUrl, orderSelect) => {
  const response = await axios.get(
    `/${nameUrl}/api/Report/ConferencePDF?MovimentationOrderId=${orderSelect}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      responseType: 'blob',
    }
  )

  return response
}
