import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import HelpIcon from '@mui/icons-material/Help'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Paper,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { AiFillPlusCircle } from 'react-icons/ai'
import { Context } from '../../../context/AuthContext'

//icones react-icons
import { useSearchParams } from 'react-router-dom'
import '../../../assets/global.css'
import PermissionComponent from '../../../permissions'
import {
  deleteClient,
  deleteMachineReceipt,
  getMachineReceiptAll,
} from '../../../services/clientService'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'
import { colorTableDefault } from '../../TemplateDefault'
import MachineReceiptForm from './form'

const MachineReceipt = () => {
  const token = localStorage.getItem('token')
  const { subCompany, userRoles, nameUrl } = useContext(Context)
  const [searchParams, setSearchParams] = useSearchParams()
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )

  const [page, setPage] = useState(parseInt(searchParams.get('offset')) || 0)
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(searchParams.get('limit')) || 10)
  const [order, setOrder] = useState(searchParams.get('order') || 'desc')
  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [idEditing, setIdEditing] = useState({})

  const [loading, setLoading] = useState(false)
  const [filterTable, setFilterTable] = useState('')
  const [filterCNPJ, setFilterCNPJ] = useState('')
  const [machineReceipts, setMachineReceipts] = useState({})
  const [filterRow, setFilterRow] = useState([])
  const [notSearch, setNotSearch] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setSearchParams({
      limit: rowsPerPage,
      offset: newPage * rowsPerPage,
      order: order,
    })
  }

  const handleChangeRowsPerPage = event => {
    const newLimit = parseInt(event.target.value, 10)
    setRowsPerPage(newLimit)
    setPage(0) // Reseta a página ao mudar o número de linhas
    setSearchParams({
      limit: newLimit,
      offset: 0,
      order: order,
    })
  }

  const fetchData = async () => {
    if (!subCompanyId) return

    setLoading(true)
    const data = {
      subCompanyId,
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      order: order,
    }
    try {
      const response = await getMachineReceiptAll(token, nameUrl, data)

      if (response.data.length === 0) {
        // Ações quando não há dados
      } else {
        setMachineReceipts(response.data)
      }
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [subCompanyId, page, rowsPerPage, order])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleEdit = item => {
    setIdEditing(item)
    setExpanded(true)
    setEditing(true)
    window.scrollTo(0, 0)
  }

  const filter = () => {
    const filtered = machineReceipts.filter(row =>
      row.description.toLowerCase().includes(filterTable.toLowerCase())
    )

    setFilterRow(filtered)
  }

  useEffect(() => {
    console.log(idEditing, 'idEditing')
  }, [idEditing])

  useEffect(() => {
    //filter()
  }, [filterTable, machineReceipts, notSearch])

  const handleClearFilter = () => {
    setFilterTable('')
    setFilterCNPJ('')
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    const currentIdDelete = idDelete

    const data = {
      receiptMachineId: currentIdDelete,
    }
    try {
      const response = await deleteMachineReceipt(token, nameUrl, data)
      fetchData(subCompanyId)
      setTitle(response.data.message)
      setSeverity('success')
      setAlerta(true)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5, height: 'fit-content' }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Receita de maquinas</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon />
          </IconButton>
        </Box>
        {/* Criar  */}
        <Accordion expanded={expanded} variant="outlined">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>
              {editing ? 'Editando receita de maquina' : 'Cadastrar receita de maquina'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="Edit_MachineReceipt">
                <MachineReceiptForm
                  setEditing={setEditing}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={fetchData}
                  setExpanded={setExpanded}
                  id={idEditing}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="Create_MachineReceipt">
                <MachineReceiptForm
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={fetchData}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>

        <SelectSubCompany
          value={subCompanyId}
          onChange={e => {
            fetchData(e.target.value)
          }}
        />
        <Box
          sx={{
            mt: 3,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              ml: 1,
            }}
          >
            <PermissionComponent role="Create_MachineReceipt">
              <Button
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
                variant="contained"
                endIcon={<AiFillPlusCircle />}
              >
                Criar
              </Button>
            </PermissionComponent>
          </Box>
        </Box>

        {/* Deletar */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>

        {machineReceipts.total > 0 && (
          <>
            <TableContainer component={Paper} sx={{ mt: '2rem' }}>
              <Box>
                <Typography>
                  {machineReceipts.total <= 1
                    ? `${machineReceipts.total} Resultado`
                    : `${machineReceipts.total} Resultados`}
                </Typography>
              </Box>
              <Table sx={{ minWidth: 700, mt: 1 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Descrição</StyledTableCell>
                    <StyledTableCell align="center">Número de par interno</StyledTableCell>
                    <StyledTableCell align="center">Sequência de recebimento</StyledTableCell>
                    <StyledTableCell> </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {machineReceipts.result.map((row, index) => (
                    <StyledTableRow key={row.receiptMachineId}>
                      <StyledTableCell align="center">{row.description}</StyledTableCell>
                      <StyledTableCell align="center">{row.internalParNumber}</StyledTableCell>
                      <StyledTableCell align="center">{row.sequenceReceipt}</StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <PermissionComponent role="Delete_MachineReceipt">
                            <Tooltip title="Apagar" arrow>
                              <IconButton>
                                <DeleteForeverIcon
                                  onClick={() => deleteAlert(row.receiptMachineId)}
                                  sx={{ cursor: 'pointer' }}
                                />
                              </IconButton>
                            </Tooltip>
                          </PermissionComponent>

                          <PermissionComponent role="Edit_MachineReceipt">
                            <Tooltip title="Editar" arrow>
                              <IconButton>
                                <ModeEditOutlineIcon
                                  onClick={() => handleEdit(row.receiptMachineId)}
                                  sx={{ cursor: 'pointer' }}
                                />
                              </IconButton>
                            </Tooltip>
                          </PermissionComponent>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={machineReceipts.total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default MachineReceipt
