import axios from 'axios'

export const getDriver = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderRoute/GetDriver?SubCompanyId=${data}`,

    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getAllDriver = async (token, nameUrl, data) => {
  let orderToString
  if (data.order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderRoute/GetAllDriver?SubCompanyId=${data.SubCompanyId}&UserDriverId=${data.UserDriverId}&limit=${data.limit}&offset=${data.offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getOrdersDriver = async (token, nameUrl, id) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderRoute/Get/${id}`,

    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getStartRoute = async (token, nameUrl, id) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderRoute/StartShipping?MovimentationOrderRouteId=${id}`,

    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const DeliveryRoute = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrderRoute/Delivery`,
    data,

    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const NotDeliveryRoute = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrderRoute/NotDelivery`,
    data,

    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const DeliveryAddImage = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrderRoute/AddImageMovimentation`,
    data,

    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const XMLUpload = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrderRoute/XMLUpload`,
    data,

    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}
