import { useState, useContext, useEffect, useRef } from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Context } from '../../../context/AuthContext'
import Alert from '@mui/material/Alert'
import * as XLSX from 'xlsx'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
  Chip,
  MenuItem,
  Select,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import styled from '@emotion/styled'
import { colorTableDefault } from '../../TemplateDefault'
import { Add, DeleteForever, Edit, ExpandMore, Replay } from '@mui/icons-material'
import { saveAs } from 'file-saver'

import {
  createAssociationItensList,
  createListProductService,
  createProductService,
  createUploadDepreciation,
  getProductList,
} from '../../../services/productService'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const UploadDepreciation = () => {
  const { subCompany, company, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')

  const [file, setFile] = useState(null)
  const [valueFile, setValueFile] = useState('')
  const token = localStorage.getItem('token')
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [titleDialog, setTitleDialog] = useState('')
  const [severity, setSeverity] = useState('error')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [statusMessage, setStatusMessage] = useState({})
  const [statusTable, setStatusTable] = useState(false)
  const [statusInfo, setStatusInfo] = useState(false)
  const [currentBatchIndex, setCurrentBatchIndex] = useState(0)
  const [progress, setProgress] = useState(0)

  const [dialogAction, setDialogAction] = useState(null)

  const [singleProd, setSingleProd] = useState({
    id: '',
    idProductPartner: '',
    name: '',
    description: '',
    price: '',
  })

  const [status, setStatus] = useState('Pronto')
  const [deleteId, setDeleteId] = useState('')
  const [search, setSearch] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('')

  const [edit, setEdit] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)
  const [itemExcel, setItemExceç] = useState([])
  const [itemProd, setItensProd] = useState([])
  const [selectedItems, setSelectedItems] = useState([])

  const [filter, setFilter] = useState([])
  const [selectAll, setSelectAll] = useState(false)

  const [sentProdsCount, setSentProdsCount] = useState(0)
  const [failedProds, setFailedProds] = useState([])
  const [numberItem, setNumberItem] = useState([])
  const [listOrderExit, setListOrderExist] = useState([])
  const [totaProdsSelectedCount, setTotaProdsSelectedCount] = useState(0)
  const ref = useRef()
  const [loadingMap, setLoadingMap] = useState({})
  const [headers, setHeaders] = useState(['Codigo de barras', 'Valor de depreciação'])

  const handleDownloadExcel = () => {
    const ws = XLSX.utils.aoa_to_sheet([headers])

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })

    const file = new Blob([wbout], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    saveAs(file, 'Carga de depreciacao.xlsx')
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setSelectAll(event.target.value === 'All')
  }

  const handleItemSelect = itemId => {
    setSelectedItems(prevSelectedItems => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter(id => id !== itemId)
      } else {
        return [...prevSelectedItems, itemId]
      }
    })
  }

  const handleSelectAll = event => {
    const isChecked = event.target.checked

    setSelectAll(isChecked)
    setSelectedItems(prevSelectedItems => {
      if (!selectAll) {
        return filter.map((item, index) => item.idProductPartner)
      } else {
        return []
      }
    })
    setRowsPerPage(isChecked ? filter.length + 1 : 10)
  }

  useEffect(() => {
    if (selectedItems.length === filter.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }, [selectedItems, filter])

  useEffect(() => {}, [singleProd])

  useEffect(() => {}, [itemExcel, statusMessage])

  const handleSelectFile = e => {
    setLoading(true)
    setItensProd([])
    setValueFile(e.target.name[0])
    const selectedFile = e.target.files[0]

    if (!selectedFile) {
      setFile(null)
      return
    }
    setFile(selectedFile)
    //sendFile(selectedFile)
    readExcel(selectedFile)
  }

  const readExcel = async file => {
    const reader = new FileReader()
    await new Promise(resolve => setTimeout(resolve, 1000))

    reader.onload = async e => {
      const data = e.target.result
      const workbook = XLSX.read(data, { type: 'binary' })
      const firstSheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[firstSheetName]

      const excelData = []
      const range = XLSX.utils.decode_range(worksheet['!ref'])

      for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
        const row = []
        let hasData = false

        for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
          const cellAddress = { c: colNum, r: rowNum }
          const cellRef = XLSX.utils.encode_cell(cellAddress)
          const cell = worksheet[cellRef]

          if (cell && cell.v !== undefined && cell.v !== null) {
            hasData = true
          }

          row.push(cell ? cell.v : undefined)
        }

        if (hasData) {
          excelData.push(row)
        }
      }
      const dataList = excelData.map(item => ({
        idProductPartner: item[0] !== undefined && item[0] !== null ? String(item[0]) : '0',
        depreciationValue: item[1] !== undefined && item[1] !== null ? String(item[1]) : '0',
      }))
      handleGetProductsCode(dataList)
      //setItensProd(dataList)
      setLoading(false)
      setValueFile('')
      resetFile()
    }

    reader.readAsBinaryString(file)
  }
  const handleGetProductsCode = async dataList => {
    const codProductList = dataList.map(item => item.idProductPartner)

    try {
      const response = await getProductList(token, nameUrl, company.companyId, codProductList)

      const serialMap = dataList.reduce((acc, item) => {
        acc[item.idProductPartner] = item.depreciationValue
        return acc
      }, {})

      const uniqueProductListWithSerial = response.data.map(product => {
        if (serialMap.hasOwnProperty(product.idProductPartner)) {
          return {
            ...product,
            subCompanyId: subCompanyId,
            depreciationValue: serialMap[product.idProductPartner],
          }
        } else {
          return {
            ...product,
            subCompanyId: subCompanyId,
          }
        }
      })

      setItensProd(uniqueProductListWithSerial)
    } catch (error) {}
  }

  const resetFile = () => {
    ref.current.value = ''
  }

  const addToList = single => {
    const { id, idProductPartner, name, description, price } = single

    if (!idProductPartner || !name || !description || !price) {
      setStatus('Todos os campos são obrigatórios')
      return
    }

    const orderExists = itemProd.some(item => item.idProductPartner === idProductPartner)

    if (!edit && orderExists) {
      setStatus('Número de ordem já existe na lista')
      return
    }

    const repetido = itemProd.find(item => item.id === id)

    if (repetido) {
      const updatedClient = {
        ...repetido,
        idProductPartner,
        name,
        description,
        price,
      }

      const updatedIndex = itemProd.findIndex(item => item.id === id)

      if (updatedIndex !== -1) {
        const updatedItemClient = [...itemProd]
        updatedItemClient[updatedIndex] = updatedClient
        setItensProd(updatedItemClient)
      }

      setStatus('Cliente atualizado')
      resetFields()
      setExpanded(false)
    } else {
      if (!idProductPartner) {
        setStatus('Código inválido')
        return
      }

      const newClient = {
        id,
        idProductPartner,
        name,
        description,
        price,
      }

      setItensProd(curr => [...curr, newClient])
      setStatus('Cliente adicionado')
      resetFields()
      setExpanded(false)
    }
  }

  const resetFields = () => {
    setEdit(false)
    setSingleProd({
      idProductPartner: '',
      name: '',
      description: '',
      price: '',
    })
  }

  useEffect(() => {}, [itemProd])

  useEffect(() => {}, [numberItem])

  const handleActionList = action => {
    if (selectedItems.length === 0) {
      setSeverity('error')
      setTitle('Selecione um item')
      setAlerta(true)
    } else {
      setOpen(true)
      setTitleDialog('Tem certeza que enviar esses itens?')
      setDialogAction(action)
    }
  }

  const handleSaveUnity = async data => {
    setStatusTable(true)
    setSentProdsCount(0)
    setFailedProds([])

    try {
      const updatedFilter = filter.map(row => {
        if (row.idProductPartner === data.idProductPartner) {
          return {
            ...row,
            loading: true,
          }
        }
        return row
      })

      setFilter(updatedFilter)
      await new Promise(resolve => setTimeout(resolve, 1000))

      const response = await createAssociationItensList(token, nameUrl, [data])

      const updatedFilterWithStatus = updatedFilter.map(row => {
        if (row.idProductPartner === data.idProductPartner) {
          return {
            ...row,
            loading: false,
            status: response.data.message,
          }
        }
        return row
      })
      setFilter(updatedFilterWithStatus)
      setSeverity('success')
      await new Promise(resolve => setTimeout(resolve, 1000))

      const updatedFilterWithoutSuccess = updatedFilterWithStatus.filter(
        row => row.idProductPartner !== data.idProductPartner
      )

      setSentProdsCount(prevSentProdsCount => prevSentProdsCount + 1)
      setFilter(updatedFilterWithoutSuccess)
    } catch (error) {
      const updatedFilter = filter.map(row => {
        if (row.idProductPartner === data.idProductPartner) {
          return {
            ...row,
            loading: false,
            status: 'Falha ao enviar, tente novamente!',
          }
        }
        return row
      })
      setSeverity('error')
      setFilter(updatedFilter)
      setFailedProds(prevSentProdsCount => prevSentProdsCount + 1)
    }
  }

  const handleSaveProductsSelected = async () => {
    setStatusInfo(true)
    setStatusTable(true)
    setSentProdsCount(0)
    setFailedProds([])
    setProgress(0)

    const selectedProducts = itemProd.filter(prod => selectedItems.includes(prod.idProductPartner))
    setTotaProdsSelectedCount(selectedProducts.length)
    console.log(selectedProducts.length)
    for (const selectedProduct of selectedProducts) {
      try {
        const updatedFilter = filter.map(row => {
          if (row.idProductPartner === selectedProduct.idProductPartner) {
            return {
              ...row,
              loading: true,
            }
          }
          return row
        })

        setFilter(updatedFilter)
        await Promise.resolve()
        await new Promise(resolve => setTimeout(resolve, 1000))
        const response = await createAssociationItensList(token, nameUrl, [selectedProduct])

        const updatedFilterWithStatus = updatedFilter.map(row => {
          if (row.idProductPartner === selectedProduct.idProductPartner) {
            return {
              ...row,
              loading: false,
              status: response.data.message,
            }
          }
          return row
        })

        setFilter(updatedFilterWithStatus)
        setSeverity('success')
        await new Promise(resolve => setTimeout(resolve, 1000))

        const updatedFilterWithoutSuccess = updatedFilterWithStatus.filter(
          row => row.idProductPartner !== selectedProduct.idProductPartner
        )

        setSentProdsCount(prevSentProdsCount => prevSentProdsCount + 1)

        setItensProd(prevItemProd =>
          prevItemProd.filter(item => item.idProductPartner !== selectedProduct.idProductPartner)
        )

        setFilter(updatedFilterWithoutSuccess)
      } catch (error) {
        const updatedFilter = filter.map(row => {
          if (row.idProductPartner === selectedProduct.idProductPartner) {
            return {
              ...row,
              loading: false,
              status: 'Falha ao enviar, tente novamente!',
            }
          }
          return row
        })

        setSeverity('error')
        setFilter(updatedFilter)
        setFailedProds(prevSentProdsCount => prevSentProdsCount + 1)
      }
    }

    setStatusTable(false)
    setSelectedItems([])
    setStatusInfo(false)
  }

  const handleSaveProd = async () => {
    setStatusInfo(true)
    setStatusTable(true)
    setSentProdsCount(0)
    setFailedProds([])
    setProgress(0)

    const totalItems = itemProd.length
    const batchSize = 1
    setNumberItem(totalItems)
    const numBatches = Math.ceil(totalItems / batchSize)
    const dataItems = itemProd.map(item => ({
      idProductPartner: item.idProductPartner,
      depreciationValue: Number(item.depreciationValue),
      subCompanyId: subCompanyId,
    }))
    let updatedFilter = [...dataItems]

    for (let batchIndex = 0; batchIndex < numBatches; batchIndex++) {
      try {
        await new Promise(resolve => setTimeout(resolve, 1000))
        setCurrentBatchIndex(batchIndex + 1)
        const batchStart = batchIndex * batchSize
        const batchEnd = Math.min((batchIndex + 1) * batchSize, totalItems)
        const batchProducts = dataItems.slice(batchStart, batchEnd)

        const newProgress = ((batchIndex + 1) / numBatches) * 100
        setProgress(newProgress)
        setFilter(prevFilter => {
          return prevFilter.map(row => {
            if (batchProducts.some(product => product.idProductPartner === row.idProductPartner)) {
              return {
                ...row,
                loading: true,
              }
            }
            return row
          })
        })

        await new Promise(resolve => setTimeout(resolve, 1000))
        try {
          const response = await createUploadDepreciation(token, nameUrl, batchProducts[0])
          setFilter(prevFilter => {
            return prevFilter.map(row => {
              if (
                batchProducts.some(product => product.idProductPartner === row.idProductPartner)
              ) {
                return {
                  ...row,
                  loading: false,
                  //status: response.data.message,
                }
              }
              return row
            })
          })

          setSeverity('success')
          await new Promise(resolve => setTimeout(resolve, 1000))

          updatedFilter = updatedFilter.filter(
            row => !batchProducts.some(product => product.idProductPartner === row.idProductPartner)
          )
          setFilter(updatedFilter)
          setSentProdsCount(prevSentProdsCount => prevSentProdsCount + batchProducts.length)
        } catch (error) {
          setFilter(prevFilter => {
            return prevFilter.map(row => {
              if (
                batchProducts.some(product => product.idProductPartner === row.idProductPartner)
              ) {
                return {
                  ...row,
                  loading: false,
                  status: 'Falha ao enviar, tente novamente!',
                }
              }
              return row
            })
          })
          setSeverity('error')
          setFilter(updatedFilter)
          setFailedProds(prevSentProdsCount => prevSentProdsCount + batchProducts.length)
        }
      } catch (error) {
        setFilter(prevFilter => {
          return prevFilter.map(row => {
            if (dataItems.some(product => product.idProductPartner === row.idProductPartner)) {
              return {
                ...row,
                loading: false,
                status: 'Falha ao enviar, tente novamente!',
              }
            }
            return row
          })
        })
        setSeverity('error')
        setFilter(updatedFilter)
        setFailedProds(prevSentProdsCount => prevSentProdsCount + dataItems.length)
      }
    }

    setStatusTable(false)
    setStatusInfo(false)
  }

  const deleteDialog = (clientId, del) => {
    setDeleteId(clientId)
    setOpen(true)
    setTitleDialog('Tem certeza que deseja excluir este item?')
    setDialogAction(del)
  }

  const handleDeleteItem = () => {
    const updatedItems = filter.filter(item => item.idProductPartner !== deleteId)
    setSeverity('success')
    setTitle('Item apagado com sucesso!')
    setAlerta(true)
    setItensProd(updatedItems)
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
    setAlerta(false)
  }

  const filterRow = () => {
    const filtered = itemProd.filter(
      row =>
        row.idProductPartner.toLowerCase().includes(search.toLowerCase()) ||
        row.description.toLowerCase().includes(search.toLowerCase()) ||
        row.barCode.toLowerCase().includes(search.toLowerCase())
    )
    setFilter(filtered)
  }

  useEffect(() => {
    filterRow()
  }, [search, itemProd])

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        {statusInfo && (
          <Backdrop
            sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
            open={statusInfo}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              position="relative"
            >
              <CircularProgress value={progress} sx={{ color: colorTableDefault }} size={60} />
              <Box
                sx={{
                  position: 'absolute',
                  top: '26%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <Typography>{Math.round(progress)}%</Typography>
              </Box>
              <Box textAlign="center" p={2}>
                {selectedItems.length > 0 ? (
                  <Typography>
                    {totaProdsSelectedCount.toLocaleString()} de {Math.floor(sentProdsCount)}{' '}
                    Produtos Enviadas...
                  </Typography>
                ) : (
                  <Typography>
                    {numberItem.toLocaleString()} de {sentProdsCount.toLocaleString()} Produtos
                    Enviadas...
                  </Typography>
                )}
              </Box>
            </Box>
          </Backdrop>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>{titleDialog}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              onClick={() => {
                handleClose()
                if (dialogAction === 'delete') {
                  handleDeleteItem()
                } else if (dialogAction === 'save') {
                  handleSaveProductsSelected()
                }
                setDialogAction(null)
              }}
            >
              Sim
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>

        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Carga de Depreciação</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl sx={{ width: '100%', mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                value={subCompanyId}
                sx={{ width: '100%' }}
                label="Escolher o Usuário"
                onChange={e => {
                  setSubCompanyId(e.target.value)
                }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{ justifyContent: 'space-between', width: 1, minWidth: 1 }}
              display={{ xs: 'column', sm: 'column', md: 'flex', lg: 'flex', xl: 'flex' }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  textAlign: 'center',
                  mt: 2,
                  border: '1px solid black',
                  width: { xs: 1, md: 0.34 },
                  minWidth: { xs: 1, md: 0.3 },
                  p: 2,
                }}
              >
                <Button onClick={handleDownloadExcel}>
                  <FileDownloadIcon sx={{ fontSize: 100 }} />
                </Button>
                <InputLabel>Faça o download do exemplo de carga</InputLabel>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  border: { md: '1px solid black' },
                  p: 2,
                }}
                width={{ xs: '100%', sm: '100%', md: '65%', lg: '65%', xl: '65%' }}
              >
                <InputLabel>Insira aqui o documento para enviar</InputLabel>
                <TextField
                  sx={{ width: '100%', mt: 1 }}
                  id="standard-basic"
                  variant="standard"
                  value={valueFile}
                  disabled={!subCompanyId}
                  onChange={handleSelectFile}
                  ref={ref}
                  type="file"
                  accept=".xls, .xlsx, .ods"
                />
                <Box
                  sx={{
                    mt: 4,
                    alignItems: 'center',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography>Apenas arquivos .XLS ou .XLSX</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    sx={{ width: '100%', mb: 1 }}
                    id="standard-basic"
                    variant="outlined"
                    label="Procurar"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    type="text"
                  />
                </Grid>
              </Grid>

              <Box display="flex" justifyContent="space-between" alignItems="end">
                <Box>
                  <Typography variant="body" sx={{ marginBottom: 2, mr: 0 }}>
                    {filter.length <= 1
                      ? `${filter.length} Resultado`
                      : `${filter.length} Resultados`}
                  </Typography>
                  {selectedItems.length > 0 && (
                    <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                      {selectedItems.length <= 1
                        ? `${selectedItems.length} Item Selecionado`
                        : `${selectedItems.length} Itens Selecionados`}
                    </Typography>
                  )}
                  {statusTable && (
                    <>
                      <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                        {listOrderExit.length > 0 && `${listOrderExit.length} ordens existentes`}
                      </Typography>
                    </>
                  )}
                  {statusTable && (
                    <>
                      <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                        {sentProdsCount <= 1
                          ? `${sentProdsCount} Itens Enviado`
                          : `${sentProdsCount} Itens Enviados`}
                      </Typography>
                      <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                        {failedProds.length > 1
                          ? `${failedProds.length} Itens não enviados`
                          : `${failedProds.length} Iten não enviados`}
                      </Typography>
                    </>
                  )}
                </Box>
                {selectedItems.length > 0 ? (
                  <Button
                    variant="contained"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: ' space-between',
                      fontSize: '.9em',
                    }}
                    onClick={() => handleActionList('save')}
                  >
                    Enviar Selecionados
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: ' space-between',
                      fontSize: '.9em',
                    }}
                    onClick={() => handleSaveProd()}
                  >
                    Enviar Lista
                  </Button>
                )}
              </Box>
            </Box>

            <Table sx={{ minWidth: 700, mt: 1 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <Tooltip title="Selecionar Todos" arrow>
                        <span>
                          <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAll}
                            color="primary"
                          />
                        </span>
                      </Tooltip>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center">Codigo do Produto</StyledTableCell>
                  <StyledTableCell align="center">Nome</StyledTableCell>
                  <StyledTableCell align="center">Valor de depreciação</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>

                  <StyledTableCell align="center" width="10%">
                    Ação
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filter
                  .filter(
                    row => selectedStatus === '' || statusMessage[row.productId] === selectedStatus
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        <Checkbox
                          checked={selectedItems.includes(row.idProductPartner)}
                          onChange={() => handleItemSelect(row.idProductPartner)}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.idProductPartner}</StyledTableCell>
                      <StyledTableCell align="center">{row.name}</StyledTableCell>
                      <StyledTableCell align="center">{row.depreciationValue}</StyledTableCell>
                      <StyledTableCell align="center">
                        {loadingMap[row.idProductPartner] ? (
                          <>
                            <Box display="flex" alignItems="center" justifyContent="center">
                              <CircularProgress
                                value={0}
                                sx={{ color: colorTableDefault }}
                                size={30}
                              />
                              <Typography ml={2} variant="caption">
                                Enviando...
                              </Typography>
                            </Box>
                          </>
                        ) : (
                          <>
                            {(() => {
                              const status = statusMessage[row.idProductPartner]

                              if (status === 'Codigo do produto invalido') {
                                return <Chip color="error" variant="filled" label={status || ''} />
                              } else if (status === 'Falha ao enviar') {
                                return <Chip color="error" variant="filled" label={status || ''} />
                              } else if (status === 'Registro feito com sucesso') {
                                return (
                                  <Chip
                                    color="success"
                                    variant="filled"
                                    label={`${status}` || ''}
                                  />
                                )
                              } else {
                                return (
                                  <Chip
                                    color="info"
                                    variant="filled"
                                    label={`Pronto para enviar`}
                                  />
                                )
                              }
                            })()}
                          </>
                        )}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <Tooltip title="Reenviar" arrow>
                            <IconButton onClick={() => handleSaveUnity(row)}>
                              <Replay sx={{ cursor: 'pointer' }} />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Apagar" arrow>
                            <IconButton
                              onClick={() => deleteDialog(row.idProductPartner, 'delete')}
                            >
                              <DeleteForever sx={{ cursor: 'pointer' }} />
                            </IconButton>
                          </Tooltip>

                          {/* <Tooltip title="Editar" arrow>
                            <IconButton onClick={() => handleGetEdit(row)}>
                              <Edit sx={{ cursor: 'pointer' }} />
                            </IconButton>
                          </Tooltip> */}
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filter.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default UploadDepreciation
