import { useCallback, useContext, useEffect, useState } from 'react'
import {
  deleteIntegrationCredencialService,
  getIntegrationCredencialService,
  getUniqueIntegrationCredencialService,
} from '../../../services/configServices'
import { Context } from '../../../context/AuthContext'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import HelpIcon from '@mui/icons-material/Help'
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  Paper,
  Backdrop,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  Tooltip,
  Card,
  CardMedia,
  CardActionArea,
  Grid,
  TextField,
  Modal,
  InputLabel,
  Divider,
} from '@mui/material'
import IntegrationCredencialCreate from '../../Create/IntegrationCredencialCreate'
import IntegrationCredencialEdit from '../../Edit/IntegrationCredencialEdit'

//icones react-icons
import { AiFillPlusCircle } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'
import PermissionComponent from '../../../permissions'
import { Close } from '@mui/icons-material'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const IntegrationCredencial = () => {
  const { userRoles, logo, nameUrl } = useContext(Context)
  const token = localStorage.getItem('token')
  const [intCredencial, setIntCredencial] = useState([])
  const [pagination, setPagination] = useState({})
  const [intCredencialLimit, setIntCredencialLimit] = useState(10)
  const [intCredencialOffset, setIntCredencialOffset] = useState(0)
  const [intCredencialOrder, setIntCredencialOrder] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [integrationDefault, setIntegration] = useState([])

  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [idEditing, setIdEditing] = useState('')
  const [nameApp, setNameApp] = useState('')
  const [viewElearning, setViewElearning] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      wordBreak: 'break-word',
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getIntCredencial = useCallback(async () => {
    setLoading(true)
    setError(false)

    try {
      const response = await getIntegrationCredencialService(
        token,
        intCredencialLimit,
        intCredencialOffset,
        intCredencialOrder
      )
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setIntCredencial(response.data.result)
        setPagination(response.data)
      }
      setLoading(false)
    } catch (err) {
      setError(true)
      setLoading(false)
    }
  }, [intCredencialOrder])

  const getIntCredencialPage = useCallback(async (offset, order) => {
    setError(false)

    try {
      const response = await getIntegrationCredencialService(
        token,
        intCredencialLimit,
        offset,
        order
      )
      setIntCredencial(response.data.result)
      setPagination(response.data)
    } catch (err) {
      setError(true)
    }
  }, [])

  const getIntegrationCredencial = async integrationCredencialId => {
    setLoading(true)
    try {
      const response = await getUniqueIntegrationCredencialService(
        token,
        nameUrl,
        integrationCredencialId
      )
      setIntegration(response.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    getIntCredencial()
  }, [intCredencialOrder])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleEdit = id => {
    setIdEditing(id)
    setExpanded(true)
    setEditing(true)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    try {
      await deleteIntegrationCredencialService(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Integração deletada com sucesso!')

      setAlerta(true)
      setLoading(false)
      getIntCredencial()
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
              Integração
            </Typography>
            <Box>
              <PermissionComponent role="IntegrationCredencialDELETE">
                <Tooltip title="Apagar" arrow>
                  <IconButton
                    onClick={() => deleteAlert(integrationDefault.integrationCredencialId)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              </PermissionComponent>
              <PermissionComponent role="IntegrationCredencialEDIT">
                <Tooltip title="Editar" arrow>
                  <IconButton
                    onClick={() => {
                      setOpenModal(false)
                      handleEdit(integrationDefault.integrationCredencialId)
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    <ModeEditOutlineIcon />
                  </IconButton>
                </Tooltip>
              </PermissionComponent>
              <Tooltip title="Fechar" arrow>
                <IconButton
                  onClick={() => {
                    setOpenModal(false)
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Divider sx={{ mb: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel shrink sx={{ fontSize: 22 }}>
                Nome App
              </InputLabel>
              <TextField disabled size="small" value={integrationDefault.name} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel shrink sx={{ fontSize: 22 }}>
                Status
              </InputLabel>
              <TextField
                disabled
                size="small"
                value={integrationDefault.appId ? 'Ativo' : 'Inativo'}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <ViewElearning
          pageName={'IntegrationCredencial'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Integrações</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>

        {/* Criar  */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>{editing ? 'Editar Integração' : 'Cadastrar Integração'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="IntegrationCredencialEDIT">
                <IntegrationCredencialEdit
                  setEditing={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  integrationCredencialId={idEditing}
                  handleFetchData={getIntCredencial}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="IntegrationCredencialCREATE">
                <IntegrationCredencialCreate
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={getIntCredencial}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>

        {/* Deletar */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>

        <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', ml: 1 }}
          >
            <PermissionComponent role="IntegrationCredencialCREATE">
              <Button
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
                variant="contained"
                endIcon={<AiFillPlusCircle />}
              >
                Criar
              </Button>
            </PermissionComponent>
          </Box>
        </Box>

        <Grid container spacing={2}>
          {intCredencial
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <Grid item xs={12} md={3}>
                <Card
                  sx={{
                    minWidth: 275,
                    height: '140px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CardActionArea
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={() => {
                      setOpenModal(true)
                      getIntegrationCredencial(row.integrationCredencialId)
                    }}
                  >
                    <CardMedia
                      component="img"
                      width="100%"
                      image={row.urlImage ? row.urlImage : logo}
                      alt="green iguana"
                    />
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
        </Grid>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default IntegrationCredencial
