import { format } from 'date-fns'

export const formatarDataHoraParaBRL = dataHora => {
  if (!dataHora) {
    return 'Data não disponível'
  }

  const data = new Date(dataHora)

  if (isNaN(data.getTime())) {
    return 'Data inválida'
  }

  return format(data, 'dd/MM/yyyy HH:mm:ss')
}
