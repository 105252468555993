import axios from 'axios'
export const getAllSegmentGroup = async (token, nameUrl) => {
  const response = await axios.get(
    `/${nameUrl}/api/SegmentGroup/GetAll?offset=0&limit=500&Order=desc`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const createSegmentGroup = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/SegmentGroup/Create`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const editSegmentGroup = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/SegmentGroup/Edit`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const deleteIdSegmentGroup = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/SegmentGroup/Delete`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getSegmentList = async (token, nameUrl) => {
  const response = await axios.get(`/${nameUrl}/api/Segment/GetAll`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getSegmentId = async (token, nameUrl, id) => {
  const response = await axios.get(`/${nameUrl}/api/Segment/Get/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getPermissionSegmentGroup = async (token, nameUrl, IdSegmentGroup) => {
  const response = await axios.get(`/${nameUrl}/api/SegmentGroupRole/Get/${IdSegmentGroup}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}
