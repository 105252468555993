import { useCallback, useContext, useEffect, useState } from 'react'
import HelpIcon from '@mui/icons-material/Help'
import { Context } from '../../../context/AuthContext'
import * as XLSX from 'xlsx'
import { getFaction } from '../../../services/factionService'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import { deleteLaundry, getLaundry } from '../../../services/laundryServices'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  Paper,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tooltip,
} from '@mui/material'
import LaundryCreate from '../../Create/LaundryCreate'
import LaundryEdit from '../../Edit/LaundryEdit'
import { AiFillPlusCircle } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import PermissionComponent from '../../../permissions'

const PrinterLaundry = () => {
  const { userRoles, nameUrl } = useContext(Context)
  const [companyId, setCompanyId] = useState()
  const [loadingSearch, setLoadingSearch] = useState(true)
  const token = localStorage.getItem('token')
  const [laundry, setLaundry] = useState([])
  const [productOrder, setProductOrder] = useState(false)

  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [idEditing, setIdEditing] = useState('')

  const [loading, setLoading] = useState(false)

  const [totalPagesDownload, setTotalPagesDownload] = useState('')
  const [totalLaundry, setTotalLaundry] = useState(0)
  const [progress, setProgress] = useState(0)
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)
  const [alertDelete, setAlertDelete] = useState(false)
  const [idDelete, setIdDelete] = useState()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [viewElearning, setViewElearning] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
      wordBreak: 'break-word',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleGetLaundry = async () => {
    setLoading(true)

    const data = {
      limit: 10,
      offset: 1,
    }
    const response = await getLaundry(token, nameUrl, data)
    setTotalPagesDownload(response.data.totalpage + 1)
    setTotalLaundry(response.data.total)
    setLaundry(response.data)
    setLoading(false)
    setLoadingSearch(false)
    setTimeout(() => {}, 30)
  }

  useEffect(() => {
    handleGetLaundry()
  }, [productOrder])

  const handleVerificDelete = laundryId => {
    setIdDelete(laundryId)
    setAlertDelete(true)
  }

  const handleDelete = async () => {
    try {
      await deleteLaundry(token, nameUrl, idDelete)
      setAlertDelete(false)
      handleGetLaundry()
    } catch (e) {
      console.log(e)
    }
  }

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
    setAlertDelete(false)
  }

  const handleEdit = id => {
    setIdEditing(id)
    setExpanded(true)
    setEditing(true)
    window.scrollTo(0, 0)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Dialog open={alertDelete} aria-describedby="alert-dialog-slide-description" fullWidth>
          <DialogTitle style={{ fontSize: '1.2em' }} id="alert-dialog-title">
            Tem certeza que deseja excluir essa impressão?
          </DialogTitle>
          <DialogActions>
            <Button style={{ fontSize: '1em' }} onClick={() => handleClose()}>
              Cancelar
            </Button>
            <Button style={{ fontSize: '1em' }} onClick={() => handleDelete()}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <ViewElearning
          pageName={'PrinterLaundry'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Etiqueta de lavagem</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>

        {/* Criar  */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>{editing ? 'Editar Lavanderia' : 'Cadastrar Lavanderia'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="PrinterLaundryEdit">
                <LaundryEdit
                  setEditing={setEditing}
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  laundryId={idEditing}
                  handleFetchData={handleGetLaundry}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="PrinterLaundryCreate">
                <LaundryCreate
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={handleGetLaundry}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>
        <Box
          sx={{
            mt: 3,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', ml: 1 }}
          >
            <PermissionComponent role="PrinterLaundryCreate">
              <Button
                variant="contained"
                endIcon={<AiFillPlusCircle />}
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
              >
                Criar
              </Button>
            </PermissionComponent>
          </Box>
        </Box>

        {laundry.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="left">Nome</StyledTableCell>
                  <StyledTableCell align="left">ZPL</StyledTableCell>
                  <StyledTableCell align="left">Imagem</StyledTableCell>
                  <StyledTableCell align="left">Ativo</StyledTableCell>
                  <StyledTableCell align="left">Criação</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {laundry
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left" sx={{ width: 100 }}>
                        <PermissionComponent role="PrinterLaundryDelete">
                          <Tooltip title="Apagar" arrow>
                            <IconButton
                              onClick={() => handleVerificDelete(row.printerLaundryId)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                      </StyledTableCell>
                      <StyledTableCell align="left" sx={{ width: 100 }}>
                        <PermissionComponent role="PrinterLaundryEdit">
                          <Tooltip title="Editar" arrow>
                            <IconButton
                              onClick={() => handleEdit(row.printerLaundryId)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <ModeEditOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                      </StyledTableCell>

                      <StyledTableCell align="left" sx={{ width: 200 }}>
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        sx={{ width: '300px', flexWrap: 'wrap', overflowWrap: 'break-word' }}
                      >
                        {row.zpl}
                      </StyledTableCell>
                      <StyledTableCell align="left" sx={{ width: 200 }}>
                        <img src={row.image} alt={row.name} style={{ maxWidth: 100 }} />
                      </StyledTableCell>
                      <StyledTableCell align="left" sx={{ width: 200 }}>
                        {row.active ? 'Sim' : 'Não'}
                      </StyledTableCell>
                      <StyledTableCell align="left" sx={{ width: 200 }}>
                        {new Date(row.created).toLocaleString()}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={laundry.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default PrinterLaundry
