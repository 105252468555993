import { useContext, useEffect, useRef, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
  Tooltip,
} from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ViewElearning from '../../ViewElearning'
import HelpIcon from '@mui/icons-material/Help'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import EmailGroupEdit from '../../Edit/EmailGroupEdit'
import EmailGroupCreate from '../../Create/EmailGroupCreate'
import { AiFillPlusCircle } from 'react-icons/ai'
import { EmailGroupCREATE, EmailGroupDELETE, EmailGroupEDIT } from '../../../const/RoleConstants'
import { setPermission } from '../../../permissions/userPermissions'
import { colorTableDefault } from '../../TemplateDefault'
import {
  deleteEmailGroup,
  getEmailGroupService,
  getUserGroupEmailService,
} from '../../../services/groupEmailService'
import { Edit } from '@mui/icons-material'
import PermissionComponent from '../../../permissions'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const EmailGroup = () => {
  const token = localStorage.getItem('token')
  const [emailGroupList, setEmailGroupList] = useState([])
  const { subCompany, userRoles, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const [pagination, setPagination] = useState({})
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('error')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const [viewElearning, setViewElearning] = useState(false)
  const [editing, setEditing] = useState(false)

  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [idEditing, setIdEditing] = useState('')
  const [originalList, setOriginalList] = useState([])
  const [loading, setLoading] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [deletedEmailGroup, setDeletedEmailGroup] = useState(null)

  const requestSearch = searched => {
    setEmailGroupList(
      originalList.filter(item => item.name.toLowerCase().includes(searched.toLowerCase()))
    )
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const fetchData = async values => {
    if (!values || values === '') {
      return
    }

    setSubCompanyId(values)
    setLoading(true)
    try {
      const data = {
        subCompanyId: values,
        limit: 500,
        offset: 0,
      }

      const response = await getUserGroupEmailService(token, nameUrl, data)

      setEmailGroupList(response.data.result)
      setOriginalList(response.data.result)
      setPagination(response.data)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (subCompanyId) {
      fetchData(subCompanyId)
    }
  }, [subCompanyId])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleEdit = id => {
    setIdEditing(id)
    setExpanded(true)
    setEditing(true)
    window.scrollTo(0, 0)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    try {
      await deleteEmailGroup(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Grupo de Email deletado com sucesso!')

      setAlerta(true)
      setLoading(false)
      setDeletedEmailGroup(idDelete)
      getEmailGroupService()
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }
  const deleteButtonRef = useRef(null)

  useEffect(() => {
    if (deletedEmailGroup) {
      setEmailGroupList(prevList =>
        prevList.filter(group => group.emailGroupId !== deletedEmailGroup)
      )
    }
  }, [deletedEmailGroup])

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <ViewElearning pageName={'EmailGroup'} open={viewElearning} setOpen={setViewElearning} />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Grupo de Email</Typography>
          <IconButton onClick={() => setViewElearning(true)} sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon />
          </IconButton>
        </Box>

        {/* Criar  */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>
              {editing ? 'Editando Grupo de Email' : 'Cadastrar Grupo de Email'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="EmailGroupEdit">
                <EmailGroupEdit
                  setEditing={setEditing}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  emailGroupId={idEditing}
                  handleFetchData={fetchData}
                  setExpanded={setExpanded}
                  StyledTableCell={StyledTableCell}
                  StyledTableRow={StyledTableRow}
                  setLoading={setLoading}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="EmailGroupCreate">
                <EmailGroupCreate
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={fetchData}
                  StyledTableCell={StyledTableCell}
                  StyledTableRow={StyledTableRow}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>

        <SelectSubCompany
          value={subCompanyId}
          onChange={e => {
            fetchData(e.target.value)
          }}
        />
        <Box
          sx={{
            mt: 3,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', ml: 1 }}
          >
            <PermissionComponent role="EmailGroupCreate">
              <Button
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
                variant="contained"
                endIcon={<AiFillPlusCircle />}
              >
                Criar
              </Button>
            </PermissionComponent>
          </Box>
        </Box>
        {/* Deletar */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>
        <Box
          sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
        ></Box>
        <TextField
          variant="outlined"
          placeholder="Procurar por Nome"
          type="search"
          onInput={e => requestSearch(e.target.value)}
        />
        <TableContainer component={Paper} sx={{ mt: '2rem' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" width="10%"></StyledTableCell>
                <StyledTableCell align="center">Nome</StyledTableCell>
                <StyledTableCell align="center">Criado</StyledTableCell>
                <StyledTableCell align="center" width="15%">
                  Ativo
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {emailGroupList.length > 0 && (
              <TableBody>
                {emailGroupList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        <PermissionComponent role="EmailGroupDelete">
                          <Tooltip title="Apagar" arrow>
                            <IconButton onClick={() => deleteAlert(row.emailGroupId)}>
                              <DeleteForeverIcon sx={{ cursor: 'pointer' }} />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>

                        <PermissionComponent role="EmailGroupEdit">
                          <Tooltip title="Apagar" arrow>
                            <IconButton onClick={() => handleEdit(row.emailGroupId)}>
                              <Edit sx={{ cursor: 'pointer' }} />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                      </StyledTableCell>

                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {new Date(row.createDate).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="center" sx={{ width: 200 }}>
                        {row.active ? 'Sim' : 'Não'}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            )}
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={emailGroupList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default EmailGroup
