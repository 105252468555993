import { Backdrop, Box, Card, CardContent, CircularProgress } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { getElearningIdService } from '../../services/elearningService'
import CancelIcon from '@mui/icons-material/Cancel'
import { Context } from '../../context/AuthContext'

const ViewElearning = ({ pageName, open, setOpen }) => {
  const [code, setCode] = useState('')
  const { nameUrl } = useContext(Context)

  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    handleGetElearning()
  }, [open])

  const handleGetElearning = async () => {
    setLoading(true)
    const data = {
      pageName,
    }
    try {
      const response = await getElearningIdService(token, nameUrl, data)
      setCode(response.data.htmlCode)
      setLoading(false)
    } catch (e) {
      //console.log(e)
      setCode('<center><h1>Ainda não há ajuda disponível para esta página</h1></center>')
      setLoading(false)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (open) {
    return (
      <>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={open}>
          <Box
            // width={{xs:'90%',sm:"90%", md:'75%', lg:'70%', xl:'65%'}}
            // height={{xs:'80%',sm:"80%", md:'50%', lg:'50%', xl:'50%'}}
            sx={{
              width: '90%',
              height: '90%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Card
              sx={{ width: '100%', height: '100%' }}
              style={{ '&::WebkitScrollbar': { width: 0, height: 0 } }}
            >
              <CardContent>
                <CancelIcon
                  onClick={handleClose}
                  sx={{ cursor: 'pointer', position: 'fixed', alignSelf: 'baseline' }}
                />
              </CardContent>
              <CardContent
                sx={{
                  position: 'relative',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '90%',
                  width: '100%',
                  overflowY: 'scroll',
                }}
              >
                <Box
                  dangerouslySetInnerHTML={{ __html: code }}
                  sx={{
                    //alignItems:'center',
                    //justifyContent:'center',
                    //textAlign:'center',
                    display: 'flex',
                    flexDirection: 'column',
                    width: 1,
                    minWidth: 1,
                  }}
                />
              </CardContent>
            </Card>
          </Box>
        </Backdrop>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    )
  }
}

export default ViewElearning
