import {
  Backdrop,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { colorTableDefault } from '../../TemplateDefault'
import {
  DeleteForever,
  Drafts,
  ExpandLess,
  ExpandMore,
  Help,
  Inbox,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Message,
  ModeEditOutline,
  Person,
  Send,
  StarBorder,
} from '@mui/icons-material'
import PermissionComponent from '../../../permissions'
import { getOrders } from '../../../services/helpdeskService'
import { useForm } from 'react-hook-form'
import { TabContext, TabList, TabPanel } from '@mui/lab'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    width: 500,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const order = [
  {
    orderId: '#45612312',
    company: 'Bihands',
    User: {
      userId: 'UUID',
      name: 'Felipe Fernandes',
    },
    Status: {
      estimatedDate: 'data estimada',
      lastUpdate: 'última atualização',
    },
    items: [
      {
        equipamentId: 'ID do Equipamento 1',
        name: 'Nome do Equipamento 1',
        description: 'Nome do Equipamento 1',
        lastUpdate: '23-10-12',
        status: 'Em processo',
      },
      {
        tagsId: 'ID da Tag 1',
        name: 'Nome da Tag 1',
        description: 'Nome da Tag 1',
        lastUpdate: '23-10-12',
        status: 'Concluido',
      },
    ],

    createDate: 'data de criação',
    lastUpdate: '23-10-12',
  },
]

const ticketOrder = [{ orderId: 'seu-UUID-aqui' }, { company: 'Bihands' }]

export const DetailsOnboarding = () => {
  const token = localStorage.getItem('token')
  const [orders, setOrders] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)
  const [receivedOrders, setReceivedOrders] = useState([])
  const [progressOrders, setProgressOrders] = useState([])
  const [finishOrders, setFinishOrders] = useState([])
  const [valueRow, setValueRow] = useState('1')
  const [equipmentId, setEquipmentId] = useState('1')
  const [tagId, setTagId] = useState('1')
  const [openTag, setOpenTag] = useState(false)
  const [openEquipment, setOpenEquipment] = useState(false)

  const handleClickEquipment = () => {
    setOpenEquipment(!openEquipment)
  }

  const handleClickTag = () => {
    setOpenTag(!openTag)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleGetOrders = async () => {
    try {
      const response = await getOrders(token)
      const allOrders = response.data

      const receivedEquipments = allOrders.flatMap(order =>
        order.equipament.filter(equipment => equipment.status === 1)
      )

      const receivedOrders = allOrders.filter(
        order =>
          order.tags.some(tag => tag.status === 1) ||
          order.equipament.some(equipment => equipment.status === 1)
      )

      const progressItemOrders = allOrders.filter(
        order =>
          order.tags.some(tag => tag.status === 2 || tag.status === 3) ||
          order.equipament.some(equipment => equipment.status === 2 || equipment.status === 3)
      )

      const finishItemOrders = allOrders.filter(
        order =>
          order.tags.some(tag => tag.status === 4) ||
          order.equipament.some(equipment => equipment.status === 4)
      )
      setReceivedOrders(receivedOrders)
      setProgressOrders(progressItemOrders)
      setFinishOrders(finishItemOrders)
      console.log(progressItemOrders, 'progressIstemOrders')
      setOrders(allOrders)
    } catch (error) {}
  }

  useEffect(() => {
    handleGetOrders()
  }, [])

  const getOrderByEquipmentAndTag = async id => {
    try {
      const orderWithEquipment = orders.find(order =>
        order.equipament.some(equipment => equipment.templateEquipmentId === id)
      )

      if (!orderWithEquipment) {
        throw new Error('Ordem não encontrada para o equipamento fornecido.')
      }

      // Encontrar o equipamento com o ID fornecido dentro da ordem encontrada
      const equipment = orderWithEquipment.equipament.find(
        equipment => equipment.templateEquipmentId === id
      )

      if (!equipment) {
        throw new Error('Equipamento não encontrado para o ID fornecido.')
      }
      console.log(equipment, 'tassga')

      return { order: orderWithEquipment, equipment }
    } catch (error) {
      console.error('Erro ao obter ordem por equipamento e tag:', error.message)
      throw error
    }
  }

  const LinearProgressWithLabel = ({ value }) => (
    <Box display="flex" flexDirection="column" alignItems="start" width="100%">
      <Box width="100%">
        <Box display="flex" justifyContent="center">
          <Box width="100%" mr={0.5}>
            <LinearProgress variant="determinate" value={value} sx={{ height: '5px' }} />
          </Box>
        </Box>
      </Box>
    </Box>
  )

  function Row(props) {
    const { row } = props
    const [open, setOpen] = useState(false)

    const totalProgressEquipment = row.equipament.map(item =>
      item.status === 1
        ? 25
        : item.status === 2
        ? 50
        : item.status === 3
        ? 75
        : item.status === 4 && 100
    )

    const totalProgressTags = row.tags.map(item =>
      item.status === 1
        ? 25
        : item.status === 2
        ? 50
        : item.status === 3
        ? 75
        : item.status === 4 && 100
    )
    const sumTotalProgressEquipment = totalProgressEquipment.reduce(
      (total, progress) => total + progress,
      0
    )
    const sumTotalProgressTags = totalProgressTags.reduce((total, progress) => total + progress, 0)

    const totalSum = sumTotalProgressEquipment + sumTotalProgressTags

    const result = totalSum / 4

    return (
      <>
        <StyledTableRow key={row.id} sx={{ '& > *': { borderBottom: 'unset' } }}>
          <StyledTableCell style={{ width: '20px' }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell>
            <a href="#" key={row.id}>
              {row.id}
            </a>
          </StyledTableCell>
          <StyledTableCell>{row.company}</StyledTableCell>
          <StyledTableCell>Varios Itens</StyledTableCell>
          <StyledTableCell>
            <Box display="flex" justifyContent="start">
              <Typography variant="body2" color="textSecondary">
                {Math.round(result) < 100 ? 'Em andamento' : 'Concluido'}
              </Typography>
            </Box>
            <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
              {[1, 2, 3, 4].map((status, index) => (
                <LinearProgressWithLabel
                  key={status}
                  value={result >= status * 25 ? 100 : 0}
                  sx={{ height: '5px' }}
                />
              ))}
              <Box>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                  result
                )}%`}</Typography>
              </Box>
            </Box>
          </StyledTableCell>
          <StyledTableCell>{row.lastUpdate}</StyledTableCell>
          <StyledTableCell>{row.createDate}</StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell style={{ padding: 0, width: '100%' }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ width: 300 }}>
                      Item
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ width: 300 }}>
                      Descrição
                    </StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>
                    <StyledTableCell align="left" sx={{ width: 200 }}>
                      Estimativa de Conclusão
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ width: 200 }}>
                      Última atualização
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {row.equipament.map((equipment, index) => (
                    <StyledTableRow>
                      <StyledTableCell>
                        <Typography width="150px">{equipment.name}</Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography width="150px">{equipment.name}</Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box display="flex" justifyContent="start">
                          <Typography variant="body2" color="textSecondary">
                            {equipment.status === 1
                              ? 'Criado'
                              : equipment.status === 2
                              ? 'Aceito'
                              : equipment.status === 3
                              ? 'Em andamento'
                              : equipment.status === 4 && 'Concluído'}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          width="100%"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <LinearProgressWithLabel
                            value={equipment.status > 0 ? 100 : 0}
                            sx={{ height: '5px' }}
                          />
                          <LinearProgressWithLabel
                            value={equipment.status > 1 ? 100 : 0}
                            sx={{ height: '5px' }}
                          />
                          <LinearProgressWithLabel
                            value={equipment.status > 2 ? 100 : 0}
                            sx={{ height: '5px' }}
                          />
                          <LinearProgressWithLabel
                            value={equipment.status > 3 ? 100 : 0}
                            sx={{ height: '5px' }}
                          />
                          <Box>
                            <Typography variant="body2" color="text.secondary">
                              {equipment.status === 1
                                ? '25%'
                                : equipment.status === 2
                                ? '50%'
                                : equipment.status === 3
                                ? '75%'
                                : equipment.status === 4 && '100%'}
                            </Typography>
                          </Box>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>{equipment.lastUpdate}</StyledTableCell>
                      <StyledTableCell>{equipment.lastUpdate}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                  {row.tags.map((tag, index) => (
                    <StyledTableRow>
                      <StyledTableCell>{tag.name}</StyledTableCell>
                      <StyledTableCell>{tag.name}</StyledTableCell>
                      <StyledTableCell>
                        <Box display="flex" justifyContent="start">
                          <Typography variant="body2" color="textSecondary">
                            {tag.status === 1
                              ? 'Criado'
                              : tag.status === 2
                              ? 'Aceito'
                              : tag.status === 3
                              ? 'Em andamento'
                              : tag.status === 4 && 'Concluído'}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          width="100%"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <LinearProgressWithLabel
                            value={tag.status > 0 ? 100 : 0}
                            sx={{ height: '5px' }}
                          />
                          <LinearProgressWithLabel
                            value={tag.status > 1 ? 100 : 0}
                            sx={{ height: '5px' }}
                          />
                          <LinearProgressWithLabel
                            value={tag.status > 2 ? 100 : 0}
                            sx={{ height: '5px' }}
                          />
                          <LinearProgressWithLabel
                            value={tag.status > 3 ? 100 : 0}
                            sx={{ height: '5px' }}
                          />
                          <Box>
                            <Typography variant="body2" color="text.secondary">
                              {tag.status === 1
                                ? '25%'
                                : tag.status === 2
                                ? '50%'
                                : tag.status === 3
                                ? '75%'
                                : tag.status === 4 && '100%'}
                            </Typography>
                          </Box>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>{tag.lastUpdate}</StyledTableCell>
                      <StyledTableCell>{tag.lastUpdate}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      </>
    )
  }
  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Box
          sx={{
            width: 1,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="body1">Detalhes de Implementações</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <Help />
          </IconButton>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card sx={{ maxWidth: '300px', width: '100%', height: '100%' }}>
              <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    Nested List Items
                  </ListSubheader>
                }
              >
                <>
                  <ListItemButton onClick={handleClickEquipment}>
                    <ListItemIcon>
                      <Inbox />
                    </ListItemIcon>
                    <ListItemText primary="Equipamentos" />
                    {openEquipment ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  {orders.map((item, index) => (
                    <>
                      {item.equipament.map((equipment, indexEquipment) => (
                        <>
                          <Collapse in={openEquipment} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() =>
                                  getOrderByEquipmentAndTag(equipment.templateEquipmentId)
                                }
                              >
                                <ListItemIcon>
                                  <StarBorder />
                                </ListItemIcon>
                                <ListItemText primary={equipment.name} />
                              </ListItemButton>
                            </List>
                          </Collapse>
                        </>
                      ))}
                    </>
                  ))}
                  <ListItemButton onClick={handleClickTag}>
                    <ListItemIcon>
                      <Inbox />
                    </ListItemIcon>
                    <ListItemText primary="Etiquetas" />
                    {openTag ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  {orders.map((item, index) => (
                    <>
                      {item.tags.map((tag, indexEquipment) => (
                        <>
                          <Collapse in={openTag} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => getOrderByEquipmentAndTag(tag.labelTemplateId)}
                              >
                                <ListItemIcon>
                                  <StarBorder />
                                </ListItemIcon>
                                <ListItemText primary={tag.name} />
                              </ListItemButton>
                            </List>
                          </Collapse>
                        </>
                      ))}
                    </>
                  ))}
                </>
              </List>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
