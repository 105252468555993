import HelpIcon from '@mui/icons-material/Help'
import {
  Box,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Context } from '../../../context/AuthContext'
import { getAllPhotos } from '../../../services/reportServices'
import { colorTableDefault } from '../../TemplateDefault'

const PhotoReport = () => {
  const token = localStorage.getItem('token')
  const componentRef = useRef(null)
  const { subCompany, nameUrl, urlLogo } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [dataProducts, setDataProducts] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [openDialog, setOpenDialog] = useState(false)
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('')
  const [filteredProducts, setFilteredProducts] = useState([])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    documentTitle: 'Relatório de Fotos',
    pageStyle: `
      @page {
        size: portrait;
        margin: 10mm;
      }

      @media print {
        body {
          -webkit-print-color-adjust: exact;
          margin: 0;
          padding: 0;
        }

        img {
          max-width: 100%;
        }

        table {
          width: 100%;
          table-layout: fixed;
          border-collapse: collapse;
          table-layout: auto;
        }

        th, td {
          padding: 5px;
          word-wrap: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .header {
          text-align: center;
          font-size: 16px;
          margin-bottom: 10mm;
        }

        .footer {
          position: fixed;
          bottom: 0;
          width: 100%;
          text-align: center;
          font-size: 12px;
        }

        tr:nth-child(7n) {
          page-break-after: always;
        }

      }

    `,
  })

  const generateDate = () => {
    const today = new Date()
    const day = String(today.getDate()).padStart(2, '0')
    const month = String(today.getMonth() + 1).padStart(2, '0')
    const year = today.getFullYear()
    return `${day}/${month}/${year}`
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handeleGetProductsForReport = async () => {
    try {
      const products = await getAllPhotos(token, nameUrl, subCompanyId)
      setDataProducts(products)
      setFilteredProducts(products)
    } catch (error) {
      console.error('Erro ao buscar produtos:', error)
    }
  }

  useEffect(() => {
    handeleGetProductsForReport()
  }, [subCompanyId])

  useEffect(() => {
    if (dataProducts.length > 0) {
      const uniqueCategories = [...new Set(dataProducts.map(product => product.name))]
      setCategories(uniqueCategories)
    } else {
      setCategories([])
    }
  }, [dataProducts])

  const handleFilter = () => {
    if (selectedCategory) {
      const filtered = dataProducts.filter(product => product.name === selectedCategory)
      setFilteredProducts(filtered)
    } else {
      setFilteredProducts(dataProducts)
    }
  }

  return (
    <>
      <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between', mt: 10 }}>
        <Typography variant="h5">Relatório de Fotos Cadastradas</Typography>
        <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => console.log('')}>
          <HelpIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'flex-end',
          mb: 2,
          mt: 2,
        }}
        display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
      >
        <Grid container spacing={2} display={'flex'} alignItems={'flex-end'}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormControl sx={{ width: '100%', mt: 0 }}>
                <InputLabel id="demo-simple-select-label">Filial</InputLabel>
                <Select
                  value={subCompanyId}
                  onChange={event => setSubCompanyId(event.target.value)}
                  sx={{ width: '100%' }}
                  label="Filial"
                  disabled={subCompany.length >= 1 ? false : true}
                >
                  {subCompany.map((item, index) => (
                    <MenuItem value={item.subCompanyId} key={item.subCompanyId}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormControl sx={{ width: '100%', mt: 0 }}>
                <InputLabel id="category-select-label">Categoria</InputLabel>
                <Select
                  value={selectedCategory}
                  onChange={event => setSelectedCategory(event.target.value)}
                  label="Categoria"
                  sx={{ width: '100%' }}
                  disabled={!subCompanyId}
                >
                  {categories.map((category, index) => (
                    <MenuItem value={category} key={index}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="contained" onClick={handleFilter} disabled={!subCompanyId}>
              Filtrar
            </Button>

            <Button variant="contained" onClick={handleOpenDialog}>
              Imprimir / Exportar PDF
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          justifyContent: 'space-between',
          mt: 2,
          mb: 2,
          overflow: 'auto',
        }}
      >
        <Typography variant="h6">{filteredProducts.length} Resultados</Typography>
        <Box ref={componentRef}>
          {filteredProducts?.length > 0 && (
            <TableContainer sx={{ mt: '2rem' }}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Codigo do produto</StyledTableCell>
                    <StyledTableCell align="center">Nome</StyledTableCell>
                    <StyledTableCell align="center">Descricao</StyledTableCell>
                    <StyledTableCell align="center">Tamanho</StyledTableCell>
                    <StyledTableCell align="center">Cor</StyledTableCell>
                    <StyledTableCell align="center">Fabricante</StyledTableCell>
                    <StyledTableCell align="center">Serial</StyledTableCell>
                    <StyledTableCell align="center">Preço(R$)</StyledTableCell>
                    <StyledTableCell align="center">Fotos</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProducts
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">{row.idProductPartner}</StyledTableCell>
                        <StyledTableCell align="center">{row.name}</StyledTableCell>
                        <StyledTableCell align="center">{row.description}</StyledTableCell>
                        <StyledTableCell align="center">{row.size}</StyledTableCell>
                        <StyledTableCell align="center">{row.color}</StyledTableCell>
                        <StyledTableCell align="center">{row.supplier}</StyledTableCell>
                        <StyledTableCell align="center">{row.serial}</StyledTableCell>
                        <StyledTableCell align="center">
                          {'R$: ' +
                            (!isNaN(Number(row.price))
                              ? new Intl.NumberFormat('pt-BR', {
                                  style: 'decimal',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(Number(row.price))
                              : '0,00')}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Box
                            height="150px"
                            width="150px"
                            overflow="hidden"
                            position="relative"
                            borderRadius="10px"
                          >
                            <a href={row.urlPhoto} target="_blank" rel="noreferrer">
                              <CardMedia
                                component="img"
                                alt="Sem Imagem"
                                image={row.urlPhoto}
                                sx={{
                                  position: 'absolute',
                                  top: '0',
                                  left: '0',
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'contain',
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center',
                                  objectFit: 'cover',
                                }}
                              />
                            </a>
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100, 400, 1000]}
                component="div"
                count={filteredProducts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Box>
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog} fullScreen maxWidth={false}>
        <DialogTitle>Imprimir / Exportar PDF</DialogTitle>
        <DialogContent sx={{ padding: 2 }}>
          <Typography variant="h6">{filteredProducts.length} Resultados</Typography>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Fechar</Button>
            <Button onClick={handlePrint} variant="contained">
              Imprimir / Exportar PDF
            </Button>
          </DialogActions>
          <Box ref={componentRef}>
            <Box
              backgroundColor={'#c1c1c1'}
              display={'flex'}
              justifyContent={'flex-start'}
              padding={1}
              width={'100%'}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <img src={urlLogo} alt="Logo" style={{ width: '100px', height: 'auto' }} />
              </Box>
              <Box sx={{ textAlign: 'end', flex: 1 }}>
                <h3 style={{ color: '#FFF' }}>Relatório de Fotos</h3>
                <p style={{ color: '#FFF' }}>Data: {generateDate()}</p>
              </Box>
            </Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Código do Produto</StyledTableCell>
                    <StyledTableCell align="center">Nome</StyledTableCell>
                    <StyledTableCell align="center">Descrição</StyledTableCell>
                    <StyledTableCell align="center">Tamanho</StyledTableCell>
                    <StyledTableCell align="center">Cor</StyledTableCell>
                    <StyledTableCell align="center">Fabricante</StyledTableCell>
                    <StyledTableCell align="center">Serial</StyledTableCell>
                    <StyledTableCell align="center">Preço(R$)</StyledTableCell>
                    <StyledTableCell align="center">Fotos</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProducts.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">{row.idProductPartner}</StyledTableCell>
                      <StyledTableCell align="center">{row.name}</StyledTableCell>
                      <StyledTableCell align="center">{row.description}</StyledTableCell>
                      <StyledTableCell align="center">{row.size}</StyledTableCell>
                      <StyledTableCell align="center">{row.color}</StyledTableCell>
                      <StyledTableCell align="center">{row.supplier}</StyledTableCell>
                      <StyledTableCell align="center">{row.serial}</StyledTableCell>
                      <StyledTableCell align="center">
                        {'R$: ' +
                          (!isNaN(Number(row.price))
                            ? new Intl.NumberFormat('pt-BR', {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(Number(row.price))
                            : '0,00')}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Box
                          height="150px"
                          width="150px"
                          overflow="hidden"
                          position="relative"
                          borderRadius="10px"
                        >
                          <a href={row.urlPhoto} target="_blank" rel="noreferrer">
                            <CardMedia
                              component="img"
                              alt="Sem Imagem"
                              image={row.urlPhoto}
                              sx={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                              }}
                            />
                          </a>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}

                  <StyledTableRow>
                    <StyledTableCell colSpan={8} align="right">
                      <strong>Total(R$):</strong>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <strong>
                        {'R$: ' +
                          filteredProducts
                            .reduce((acc, row) => acc + (Number(row.price) || 0), 0)
                            .toLocaleString('pt-BR', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </strong>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
export default PhotoReport
