import { useContext, useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ListItemText from '@mui/material/ListItemText'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import { formatNumbers } from '../../../../../utils/formatNumbers'
import { getGenerateTagRfid } from '../../../../../services/productService'
import { Context } from '../../../../../context/AuthContext'

export default function ComponentQuantityCreate({
  open,
  onClose,
  product,
  message,
  onSaveQuantity,
}) {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const [loading, setLoading] = useState('')
  const [valueText, setValueText] = useState('')
  const [title, setTitle] = useState('')
  const [messageError, setMessageError] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [register, setRegister] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const inputRef = useRef(null)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current.focus()
      }
    }, 100)

    return () => clearTimeout(timer)
  }, [open])

  const handleSave = async () => {
    setLoading(true)
    const quantity = parseInt(valueText)
    if (isNaN(quantity) || quantity <= 0) {
      setMessageError('Digite uma quantidade válida.')
      setLoading(false)
      return
    }
    if (message) {
      setTitle('Produto ja existe na lista')
      setSeverity('error')
      setRegister(true)
      setLoading(false)
      return
    }

    const data = [
      {
        companyId: subCompany[0].companyId,
        productId: product.productId,
        quantity: valueText,
      },
    ]

    try {
      const response = await getGenerateTagRfid(token, nameUrl, data)

      response.data.forEach(item => {
        onSaveQuantity({
          idProductPartner: product.idProductPartner,
          productId: product.productId,
          name: product.name,
          quantityTags: parseInt(quantity),
          barCode: product.barCode,
          serial: item.serial,
        })
      })

      // onSaveQuantity({
      //   idProductPartner: product.idProductPartner,
      //   productId: product.productId,
      //   name: product.name,
      //   quantityTags: parseInt(quantity),
      //   barCode: product.barCode,
      //   tagsRfid: response.data,
      // })
      onClose()
      setLoading(false)
      setValueText('')
    } catch (error) {
      onClose()
      setLoading(false)
    }
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSave()
    }
  }

  return (
    <>
      {' '}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={register}
        onClose={() => setRegister(false)}
        key={vertical + horizontal}
      >
        <Alert onClose={() => setRegister(false)} severity={severity} sx={{ width: '100%' }}>
          {title}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={() => {
          onClose()
          setValueText('')
        }}
        fullWidth
        onEnter={() => inputRef.current.focus()}
      >
        {loading ? (
          <Box
            width="100%"
            maxHeight="500px"
            height="500px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogTitle>Gerar Código de Etiqueta</DialogTitle>
            <DialogContent>
              <ListItemText primary="Produto" secondary={product.name} />
              <Divider sx={{ my: 2 }} />
              <DialogContentText>
                Digite a quantidade de etiquetas que deseja gerar:
              </DialogContentText>
              <TextField
                inputRef={inputRef}
                sx={{ my: 2 }}
                autoFocus
                margin="dense"
                id="quantity"
                label="Quantidade"
                type="text"
                onKeyDown={handleKeyPress}
                value={valueText}
                error={!!messageError}
                helperText={messageError}
                onChange={e => setValueText(formatNumbers(e.target.value))}
                fullWidth
                variant="outlined"
                inputProps={{ min: 1 }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  onClose()
                  setValueText('')
                }}
              >
                Cancelar
              </Button>
              <Button type="submit" color="primary" variant="contained" onClick={handleSave}>
                Salvar
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  )
}
