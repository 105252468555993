import axios from 'axios'

//get paginação
export const getDepositService = async (token, nameUrl, data) => {
  let orderToString
  if (data.order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }
  const response = await axios.get(
    `/${nameUrl}/api/Deposit/GetAll?SubCompanyId=${data.subCompanyId}&limit=${data.limit}&offset=${data.offset}&order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//get deposit especifico
export const getDepositIdService = async (token, nameUrl, id) => {
  const response = await axios.get(`/${nameUrl}/api/Deposit/Get/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//criação
export const createDepositService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Deposit/Create`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//delete
export const deleteDepositService = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/Deposit/Delete`,
    {
      depositId: data,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//edit
export const editDepositService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Deposit/Edit`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}
