import HelpIcon from '@mui/icons-material/Help'
import React, { useContext, useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import { Context } from '../../../context/AuthContext'
import { getLoteReportService } from '../../../services/loteReportServices'

import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'

//icones react-icons
import { AiOutlineFileExcel } from 'react-icons/ai'
import '../../../assets/global.css'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const LoteReport = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const token = localStorage.getItem('token')
  const [loteReport, setLoteReport] = useState([])
  const [limit, setLimit] = useState('500')
  const [loading, setLoading] = useState(false)
  const [quantityDay, setQuantityDay] = useState('60')

  const [totalPagesDownload, setTotalPagesDownload] = useState('')
  const [progress, setProgress] = useState(0)
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [viewElearning, setViewElearning] = useState(false)
  const [filterProduct, setFilterProduct] = useState('')
  const [backupData, setBackupData] = useState([])
  const [notSearch, setNotSearch] = useState(false)
  const [idPartener, setIdPartener] = useState('')
  const [location, setLocation] = useState(false)
  const [result, setResult] = useState({})

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [viewAlocation, setViewAlocation] = useState(true)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  // useEffect(() => {
  //   getReport()
  // }, [page])

  // useEffect(() => {
  //   setPage(1)
  //   getReport()
  // }, [location, rowsPerPage])

  // useEffect(() => {
  //   if (filterProduct === '') {
  //     const checagem = setTimeout(() => {
  //       setIdPartener(filterProduct)
  //     }, 1000)
  //     return () => clearTimeout(checagem)
  //   } else {
  //     const checagem = setTimeout(() => {
  //       setIdPartener(filterProduct)
  //     }, 1000)
  //     return () => clearTimeout(checagem)
  //   }
  // }, [filterProduct])

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const getReport = async () => {
    setLoading(true)
    const data = {
      limit: 500,
      offset: 0,
      idPartener: idPartener.length === 0 ? undefined : idPartener,
      location,
      Order: 'desc',
      SubCompanyId: subCompanyId,
      QuantityDay: parseInt(quantityDay),
    }
    try {
      const response = await getLoteReportService(token, nameUrl, data)
      setResult(response.data)
      if (response.data.result.length === 0) {
        setLoteReport([])
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setLoteReport(response.data.result)
        setBackupData(response.data.result)
        // setPageCount(response.data.totalpage + 1)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const downloadData = async () => {
    setDownloadMessage('Inicializando')
    setDownloading(true)
    // const dados = {
    //   limit: parseInt(limit),
    //   offset: 0,
    //   Order: 'desc',
    //   SubCompanyId: subCompanyId,
    //   QuantityDay: parseInt(quantityDay),
    // }
    const dados = {
      limit: 500,
      offset: 0,
      idPartener: idPartener.length === 0 ? undefined : idPartener,
      location,
      Order: 'desc',
      SubCompanyId: subCompanyId,
      QuantityDay: parseInt(quantityDay),
    }
    await getLoteReportService(token, nameUrl, dados).then(r => {
      setTotalPagesDownload(r.data.totalpage + 1)
      finishDownload(r.data.totalpage)
    })
  }

  const finishDownload = async pages => {
    let result = []

    for (let i = 0; i <= pages; i++) {
      setDownloadMessage(`Baixando ${i} de ${pages}...`)
      setProgress((i / pages) * 100)
      const dados = {
        limit: 500,
        offset: i,
        SubCompanyId: subCompanyId,
        idPartener: idPartener.length === 0 ? undefined : idPartener,
        location,
        QuantityDay: parseInt(quantityDay),
      }
      const response = await getLoteReportService(token, nameUrl, dados)
      result = result.concat(response.data.result)
    }
    setDownloadMessage(`Seu arquivo está pronto.`)
    setDownloading(false)
    const relatorioFinal = []
    console.log('result ', result)
    result.map(item => {
      relatorioFinal.push({
        'Data Criação': item.requestDate ? new Date(item.requestDate).toLocaleString() : '',
        Descrição: item.description,
        Lote: item.lote,
        Palete: item.numberPallet,
        'Posição de baixa': item.picking ? item.picking : '',
        Quantidade: item.quantitys,
        ' Data Validade': new Date(item.dataExpiration).toLocaleString(),
        'Dias Restantes': item.daysleft,
        Alocado: item.nameAlocation,
        Depósito: item.depositName,
        Picking: item.picking,
        Serial: item.serial,
        'Código de Barras': item.barCode,
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório de Lote')
    XLSX.writeFile(workbook, `Relatório de Lote.xlsx`)
    setProgress(0)
  }

  const handleClose = () => {
    setAlerta(false)
  }

  const handleClearFilter = () => {
    setFilterProduct('')
  }

  useEffect(() => {
    const handleFilter = () => {
      if (filterProduct !== '') {
        setNotSearch(false)
        setLoading(true)
        const newData = backupData
        const filter = newData.filter(data =>
          data.description.toLowerCase().includes(filterProduct.toLowerCase())
        )
        setLoading(false)
        if (filter.length > 0) {
          setLoteReport(filter)
          setPage(0)
        } else {
          setNotSearch(true)
          setLoteReport(backupData)
          setPage(0)
        }
      } else {
        setLoteReport(backupData)
        setPage(0)
      }
    }
    setLoteReport([])
    handleFilter()
  }, [filterProduct])

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog open={downloading} keepMounted aria-describedby="alert-dialog-slide-description">
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessage}</DialogTitle>
            <LinearProgressWithLabel value={progress} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <ViewElearning pageName={'LoteReport'} open={viewElearning} setOpen={setViewElearning} />
          <Typography variant="h5">Relatório de Lote</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box
          component="form"
          // onSubmit={fetchData}>
          onSubmit={e => {
            e.preventDefault()
            getReport()
          }}
        >
          <Box
            sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <SelectSubCompany
                value={subCompanyId}
                onChange={e => {
                  setSubCompanyId(e.target.value)
                }}
              />
            </Box>
            <Box
              width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
              marginTop={{ xs: '1rem', sm: '1rem', md: '2.3rem', lg: '2.3rem', xl: '2.3rem' }}
            >
              <TextField
                variant="outlined"
                label="Quantidade Dias"
                value={quantityDay}
                onChange={values => setQuantityDay(values.target.value)}
                sx={{ width: '100%' }}
              />
            </Box>
          </Box>
          <Button variant="contained" type="submit">
            Procurar
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            mb: 2,
          }}
        >
          <Typography variant="h6"></Typography>
          {loteReport.length > 0 && (
            <Button
              variant="contained"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: ' space-between',
                fontSize: '.9em',
                width: '6.5rem',
              }}
              onClick={downloadData}
            >
              Excel <AiOutlineFileExcel size="2em" />
            </Button>
          )}
        </Box>
        <Box
          sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box width={{ xs: '100%', sm: '100%', md: '35%', lg: '35%', xl: '35%' }} sx={{ mt: 2 }}>
            <TextField
              variant="outlined"
              label={!notSearch ? 'Filtrar Produto' : 'Produto Não encontrado'}
              value={filterProduct}
              onChange={values => setFilterProduct(values.target.value)}
              sx={{ width: '100%' }}
              error={notSearch}
              type="text"
            />
          </Box>
          <Button variant="contained" onClick={() => handleClearFilter()} sx={{ mt: 2 }}>
            Limpar filtros
          </Button>
        </Box>
        {loteReport && (
          <>
            <Box>
              <FormControlLabel
                control={<Switch checked={location} onClick={() => setLocation(!location)} />}
                label="Mostrar só itens que tem alocação"
              />
            </Box>
            <TableContainer component={Paper} sx={{ mt: '2rem' }}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Data Criação</StyledTableCell>
                    <StyledTableCell align="left">Descrição</StyledTableCell>
                    <StyledTableCell align="left">Lote</StyledTableCell>
                    <StyledTableCell align="left">Palete</StyledTableCell>
                    <StyledTableCell align="left">Posição de baixa</StyledTableCell>
                    <StyledTableCell align="left">Quantidade</StyledTableCell>
                    <StyledTableCell align="left">Data Validade</StyledTableCell>
                    <StyledTableCell align="left">Dias Restantes</StyledTableCell>
                    <StyledTableCell align="left">Alocado</StyledTableCell>
                    <StyledTableCell align="left">Depósito</StyledTableCell>
                    <StyledTableCell align="left">Picking</StyledTableCell>
                    <StyledTableCell align="left">Serial</StyledTableCell>
                    <StyledTableCell align="left">Código de Barras</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loteReport.length > 0 &&
                    loteReport
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="left">
                            {row.requestDate ? new Date(row.requestDate).toLocaleString() : ''}
                          </StyledTableCell>
                          <StyledTableCell align="left">{row.description}</StyledTableCell>
                          <StyledTableCell align="left">{row.lote}</StyledTableCell>
                          <StyledTableCell align="left">{row.numberPallet}</StyledTableCell>
                          <StyledTableCell align="left">{row.picking}</StyledTableCell>
                          <StyledTableCell align="left">{row.quantitys}</StyledTableCell>
                          <StyledTableCell align="left">
                            {new Date(row.dataExpiration).toLocaleDateString()}
                          </StyledTableCell>
                          <StyledTableCell align="left">{row.daysleft}</StyledTableCell>
                          <StyledTableCell align="left">{row.nameAlocation}</StyledTableCell>
                          <StyledTableCell align="left">{row.depositName}</StyledTableCell>
                          <StyledTableCell align="left">{row.picking}</StyledTableCell>
                          <StyledTableCell align="left">{row.serial}</StyledTableCell>
                          <StyledTableCell align="left">{row.barCode}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={loteReport.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default LoteReport
