import axios from 'axios'

export const getDashStockActual = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Dashboard/StockActual?SubCompanyId=${data.SubCompanyId}&CreateDateTime=${data.CreateDateTime}&EndDateTime=${data.EndDateTime}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )

  return response
}

export const getDashStockEntry = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Dashboard/TotalStockEntry?SubCompanyId=${data.SubCompanyId}&CreateDateTime=${data.CreateDateTime}&EndDateTime=${data.EndDateTime}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )

  return response
}

export const getDashInventoryActual = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Report/GetDashBoardCoilOnline?SubCompanyId=${data.SubCompanyId}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )

  return response
}

export const getDashCarretesiActual = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Report/GetDashBoardCoil?SubCompanyId=${data.SubCompanyId}&StartDate=${data.StartDate}&FinishDate=${data.FinishDate}&EnumMovimentations=${data.EnumMovimentations}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )

  return response
}

export const getDashStockOut = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Dashboard/TotalStockOut?SubCompanyId=${data.SubCompanyId}&CreateDateTime=${data.CreateDateTime}&EndDateTime=${data.EndDateTime}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )

  return response
}

export const getEntryYear = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Dashboard/MovimentationsStockEntryByDay?SubCompanyId=${data.SubCompanyId}&CreateDateTime=${data.CreateDateTime}&EndDateTime=${data.EndDateTime}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}
export const getOutYear = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Dashboard/MovimentationsStockOutByDay?SubCompanyId=${data.SubCompanyId}&CreateDateTime=${data.CreateDateTime}&EndDateTime=${data.EndDateTime}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getEntryOutYear = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Dashboard/MovimentationEntryOutYear?SubCompanyId=${data.SubCompanyId}&CreateDateTime=${data.CreateDateTime}&EndDateTime=${data.EndDateTime}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getEntryOutMonth = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Dashboard/MovimentationsStockEntryAndOut30Days?SubCompanyId=${data.SubCompanyId}&CreateDateTime=${data.CreateDateTime}&EndDateTime=${data.EndDateTime}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getEntryOutYears = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Dashboard/MovimentationEntryOutYear?SubCompanyId=${data.SubCompanyId}&CreateDateTime=${data.CreateDateTime}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )

  return response
}

export const getDashStockTopEntry = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Dashboard/ProductMostEntryTop10?SubCompanyId=${data.SubCompanyId}&CreateDateTime=${data.CreateDateTime}&EndDateTime=${data.EndDateTime}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )

  return response
}

export const getDashStockTopOut = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Dashboard/ProductMostOutTop10?SubCompanyId=${data.SubCompanyId}&CreateDateTime=${data.CreateDateTime}&EndDateTime=${data.EndDateTime}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getDashStockTopUserEntry = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Dashboard/MovimentationEntrUser?SubCompanyId=${data.SubCompanyId}&CreateDateTime=${data.CreateDateTime}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getDashStockTopUserOut = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Dashboard/MovimentationOutUser?SubCompanyId=${data.SubCompanyId}&CreateDateTime=${data.CreateDateTime}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getMostOutTop = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/Dashboard/ProductMostOutTop10?SubCompanyId=${data.SubCompanyId}&CreateDateTime=${data.CreateDateTime}&EndDateTime=${data.EndDateTime}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getTotalParticipe = async (token, nameUrl, subCompanyId, startDate, finishDate) => {
  const response = await axios.get(
    `/${nameUrl}/api/DashboardVendor/TotalPartcipe?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getTotalInadiplent = async (token, nameUrl, subCompanyId, startDate, finishDate) => {
  const response = await axios.get(
    `/${nameUrl}/api/DashboardVendor/TotalBlackClientxClientName?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getTotalNewClient = async (token, nameUrl, subCompanyId, startDate, finishDate) => {
  const response = await axios.get(
    `/${nameUrl}/api/DashboardVendor/TotalNewClient?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getTotalNewClientReceive = async (
  token,
  nameUrl,
  subCompanyId,
  startDate,
  finishDate
) => {
  const response = await axios.get(
    `/${nameUrl}/api/DashboardVendor/TotalNewClientReceive?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getGraphBarParticpacaoxRazasocial = async (
  token,
  nameUrl,
  subCompanyId,
  startDate,
  finishDate
) => {
  const response = await axios.get(
    `/${nameUrl}/api/DashboardVendor/Top10GraphBarParticpacaoxRazasocial?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getGraphBarParticpacaoxCidade = async (
  token,
  nameUrl,
  subCompanyId,
  startDate,
  finishDate
) => {
  const response = await axios.get(
    `/${nameUrl}/api/DashboardVendor/Top10GraphBarParticpacaoxCidade?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getTabelaTop10ClientBlack = async (
  token,
  nameUrl,
  subCompanyId,
  startDate,
  finishDate
) => {
  const response = await axios.get(
    `/${nameUrl}/api/DashboardVendor/TabelaTop10ClientBlack?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getAreceberclient = async (token, nameUrl, subCompanyId, startDate, finishDate) => {
  const response = await axios.get(
    `/${nameUrl}/api/DashboardVendor/Top10Areceberclient?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getTotalBlackClient = async (token, nameUrl, subCompanyId, startDate, finishDate) => {
  const response = await axios.get(
    `/${nameUrl}/api/DashboardVendor/TotaBlackClient?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getTableTop10Cidade = async (token, nameUrl, subCompanyId, startDate, finishDate) => {
  const response = await axios.get(
    `/${nameUrl}/api/DashboardVendor/TableTop10Cidade?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getAllVendor = async (token, nameUrl, subCompanyId) => {
  const response = await axios.get(
    `/${nameUrl}/api/DashboardVendor/GetAllVendor?SubCompanyId=${subCompanyId}`,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}

export const getCityvsCityActive = async (
  token,
  nameUrl,
  subCompanyId,
  startDate,
  finishDate,
  vendorId
) => {
  let url = `/${nameUrl}/api/DashboardVendor/CityvsCityActive?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`
  if (vendorId !== '0') {
    url += `&SellerId=${vendorId}`
  }

  const response = await axios.get(url, {
    headers: { Authorization: 'Bearer ' + token },
  })

  return response
}

export const getCityvsCityActivePorgentage = async (
  token,
  nameUrl,
  subCompanyId,
  startDate,
  finishDate,
  vendorId
) => {
  let url = `/${nameUrl}/api/DashboardVendor/CityvsCityActivePorgentage?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`

  if (vendorId !== '0') {
    url += `&SellerId=${vendorId}`
  }

  const response = await axios.get(url, {
    headers: { Authorization: 'Bearer ' + token },
  })

  return response
}

export const getStorevsStoreActive = async (
  token,
  nameUrl,
  subCompanyId,
  startDate,
  finishDate,
  vendorId
) => {
  let url = `/${nameUrl}/api/DashboardVendor/StorevsStoreActive?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`

  if (vendorId !== '0') {
    url += `&SellerId=${vendorId}`
  }

  const response = await axios.get(url, {
    headers: { Authorization: 'Bearer ' + token },
  })

  return response
}

export const getCityvsCityMaps = async (
  token,
  nameUrl,
  subCompanyId,
  startDate,
  finishDate,
  vendorId
) => {
  let url = `/${nameUrl}/api/DashboardVendor/CityvsCityMaps?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`
  if (vendorId !== '0') {
    url += `&SellerId=${vendorId}`
  }

  const response = await axios.get(url, {
    headers: { Authorization: 'Bearer ' + token },
  })

  return response
}

export const getNumberTotalStoreRouteevsStoreActive = async (
  token,
  nameUrl,
  subCompanyId,
  startDate,
  finishDate,
  vendorId
) => {
  let url = `/${nameUrl}/api/DashboardVendor/NumberTotalStoreRouteevsStoreActive?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`

  if (vendorId !== '0') {
    url += `&SellerId=${vendorId}`
  }

  const response = await axios.get(url, {
    headers: { Authorization: 'Bearer ' + token },
  })

  return response
}

export const getNumberTotalStoreActiveevsStoreInaActive = async (
  token,
  nameUrl,
  subCompanyId,
  startDate,
  finishDate,
  vendorId
) => {
  let url = `/${nameUrl}/api/DashboardVendor/NumberTotalStoreActiveevsStoreInaActive?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`

  if (vendorId !== '0') {
    url += `&SellerId=${vendorId}`
  }

  const response = await axios.get(url, {
    headers: { Authorization: 'Bearer ' + token },
  })

  return response
}

export const getTicketmedioSeller = async (
  token,
  nameUrl,
  subCompanyId,
  startDate,
  finishDate,
  vendorId
) => {
  let url = `/${nameUrl}/api/DashboardVendor/TicketmedioSeller?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`

  if (vendorId !== '0') {
    url += `&SellerId=${vendorId}`
  }

  const response = await axios.get(url, {
    headers: { Authorization: 'Bearer ' + token },
  })

  return response
}

export const getGrowmixProductByStore = async (
  token,
  nameUrl,
  subCompanyId,
  startDate,
  finishDate,
  vendorId
) => {
  let url = `/${nameUrl}/api/DashboardVendor/GrowmixProductByStore?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`

  if (vendorId !== '0') {
    url += `&SellerId=${vendorId}`
  }

  const response = await axios.get(url, {
    headers: { Authorization: 'Bearer ' + token },
  })

  return response
}

export const getGrowmixProductByFamily = async (
  token,
  nameUrl,
  subCompanyId,
  startDate,
  finishDate,
  vendorId
) => {
  let url = `/${nameUrl}/api/DashboardVendor/GrowmixProductByFamily?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`
  if (vendorId !== '0') {
    url += `&SellerId=${vendorId}`
  }

  const response = await axios.get(url, {
    headers: { Authorization: 'Bearer ' + token },
  })

  return response
}

export const getNewCityPeriod = async (
  token,
  nameUrl,
  subCompanyId,
  startDate,
  finishDate,
  vendorId
) => {
  let url = `/${nameUrl}/api/DashboardVendor/NewCityPeriod?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`
  if (vendorId !== '0') {
    url += `&SellerId=${vendorId}`
  }

  const response = await axios.get(url, {
    headers: { Authorization: 'Bearer ' + token },
  })

  return response
}

export const getNumberStoreOpen = async (
  token,
  nameUrl,
  subCompanyId,
  startDate,
  finishDate,
  vendorId
) => {
  let url = `/${nameUrl}/api/DashboardVendor/NumberStoreOpen?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`
  if (vendorId !== '0') {
    url += `&SellerId=${vendorId}`
  }

  const response = await axios.get(url, {
    headers: { Authorization: 'Bearer ' + token },
  })

  return response
}

export const getABCReSeller = async (
  token,
  nameUrl,
  subCompanyId,
  startDate,
  finishDate,
  vendorId
) => {
  let url = `/${nameUrl}/api/DashboardVendor/ABCReSeller?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`
  if (vendorId !== '0') {
    url += `&SellerId=${vendorId}`
  }

  const response = await axios.get(url, {
    headers: { Authorization: 'Bearer ' + token },
  })

  return response
}

export const getTotalSale = async (
  token,
  nameUrl,
  subCompanyId,
  startDate,
  finishDate,
  vendorId
) => {
  let url = `/${nameUrl}/api/DashboardVendor/TotalSale?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`
  if (vendorId !== '0') {
    url += `&SellerId=${vendorId}`
  }

  const response = await axios.get(url, {
    headers: { Authorization: 'Bearer ' + token },
  })

  return response
}

export const getLastOrder = async (
  token,
  nameUrl,
  subCompanyId,
  startDate,
  finishDate,
  vendorId
) => {
  let url = `/${nameUrl}/api/DashboardVendor/LastOrder?SubCompanyId=${subCompanyId}&CreateDateTime=${startDate}&EndDateTime=${finishDate}`
  if (vendorId !== '0') {
    url += `&SellerId=${vendorId}`
  }

  const response = await axios.get(url, {
    headers: { Authorization: 'Bearer ' + token },
  })

  return response
}

export const getTotalProduct = async (token, nameUrl, companyId) => {
  const response = await axios.get(
    `/${nameUrl}/api/Dashboard/TotalProducts?CompanyId=${companyId}`,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )

  return response
}

export const getTotalProductItens = async (token, nameUrl, companyId) => {
  const response = await axios.get(
    `/${nameUrl}/api/Dashboard/TotalProductItens?CompanyId=${companyId}`,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )

  return response
}

export const getTotalProductItensCreated = async (token, nameUrl, companyId) => {
  const response = await axios.get(
    `/${nameUrl}/api/Dashboard/Top10ProductItensLastCreated?CompanyId=${companyId}`,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )

  return response
}

export const getTotalProductItensLastUpdate = async (token, nameUrl, companyId) => {
  const response = await axios.get(
    `/${nameUrl}/api/Dashboard/Top10ProductItensLastUpdated?CompanyId=${companyId}`,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )

  return response
}

export const getLastInventory = async (token, nameUrl, companyId) => {
  const response = await axios.get(
    `/${nameUrl}/api/Dashboard/LastInventory?CompanyId=${companyId}`,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )

  return response
}

export const getTotalDepreciacao = async (token, nameUrl, companyId) => {
  const response = await axios.get(
    `/${nameUrl}/api/Dashboard/TotalDepreciacao?CompanyId=${companyId}`,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )

  return response
}
