import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Context } from '../../../context/AuthContext'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { getConference, getConferenceErp } from '../../../services/requestServices'
import { setPermission } from '../../../permissions/userPermissions'
import Checkbox from '@mui/material/Checkbox'
import HelpIcon from '@mui/icons-material/Help'
import {
  ConferenceCREATE,
  ConferenceDELETE,
  ConferenceEDIT,
  PICKTOLIGHT,
} from '../../../const/RoleConstants'
import {
  pickToLightAdd,
  pickToLightCancel,
  pickToLightControl,
  pickToLightPDF,
  pickToLightUndo,
  sendConference,
} from '../../../services/pickToLightServices'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Backdrop,
  CircularProgress,
  IconButton,
} from '@mui/material'
import { useLocation } from 'react-router-dom'
import ViewElearning from '../../ViewElearning'

const PickToLight = () => {
  const { subCompany, userId, userRoles, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(subCompany[0].subCompanyId)
  const token = localStorage.getItem('token')
  const [disableInput, setDisableInput] = useState(true)
  const [stage, setStage] = useState('start')
  const [code, setCode] = useState('')
  const [table, setTable] = useState(true)
  const [codeError, setCodeError] = useState(false)
  const [removeVerific, setRemoveVerific] = useState(true)
  const [text, setText] = useState()
  const [orders, setOrders] = useState([])
  const [orderSelect, setOrderSelect] = useState('')
  const [dataConference, setDataConference] = useState([])
  const [verific, setVerific] = useState([])
  const [open, setOpen] = useState(false)
  const [verificFinaly, setVerificFinaly] = useState(false)
  const [title, setTitle] = useState()
  const [page, setPage] = useState(0)
  const [authorized, setAuthorized] = useState(false)
  const [loading, setLoading] = useState(false)
  const [countShipping, setCountShipping] = useState(0)
  const [inCount, setInCount] = useState(false)
  const [running, setRunning] = useState(true)

  const location = useLocation()
  const [viewElearning, setViewElearning] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCheck = e => {
    e.preventDefault()

    const item = {
      serial: code,
      movimentationOrderId: orderSelect,
    }

    pickToLightAdd(token, nameUrl, item)
    setCode('')
    setCountShipping(countShipping + 1)
    setRunning(true)
  }

  const handleFinish = () => {
    let hasLack = false
    let hasError = false
    dataConference.forEach(data => {
      if (data.status === 'Falta') {
        hasLack = true
      }
      if (data.status === 'Erro') {
        hasError = true
      }
    })
    if (hasLack) {
      setText('Existe produto(s) em falta')
      setTitle('Tem certeza que deseja finalizar o pedido?')
      setOpen(true)
    } else if (hasError) {
      setText('Existe produto(s) com quantidade acima do que foi pedido')
      setTitle('Tem certeza que deseja finalizar o pedido?')
      setOpen(true)
    } else {
      handleSendFinish()
    }
  }

  const handleSendFinish = async () => {
    setOpen(false)

    try {
      await sendConference(token, nameUrl, orderSelect)
      setTitle('Verificação finalizada com sucesso')
      setText('')
      setVerificFinaly(true)
      setOpen(true)
    } catch (e) {
      console.log(e)
      setVerificFinaly(true)
      setTitle('Erro na finalização')
      setText('')
      setOpen(true)
    }
  }

  useEffect(() => {
    const autorizathion = () => {
      setAuthorized(setPermission(userRoles, PICKTOLIGHT))
    }

    fetchConference()
    autorizathion()
  }, [])

  const fetchConference = async () => {
    setLoading(true)
    try {
      const response = await getConference(token, nameUrl)
      setOrders(response.data)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const reload = async () => {
    setCountShipping(0)

    if (orderSelect) {
      const response = await pickToLightControl(token, nameUrl, orderSelect)
      setDataConference(response.data)
      setTable(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    reload()
  }, [orderSelect])

  const handleUndo = async () => {
    setLoading(true)
    try {
      await pickToLightUndo(token, nameUrl, orderSelect)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const handleCancel = async () => {
    setLoading(true)
    setRunning(false)
    try {
      await pickToLightCancel(token, nameUrl, orderSelect)
      setDataConference([])
      setTable(false)
      setLoading(false)
      window.location.reload()
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          fullWidth
        >
          {!verificFinaly ? (
            <DialogTitle style={{ fontSize: '1.2em' }} id="alert-dialog-title">
              {title}
            </DialogTitle>
          ) : (
            <DialogTitle style={{ fontSize: '1.2em' }} id="alert-dialog-title">
              {title}
            </DialogTitle>
          )}
          <DialogContent>
            <DialogContentText style={{ fontSize: '1.2em' }} id="alert-dialog-slide-description">
              {text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {!verificFinaly ? (
              <>
                <Button style={{ fontSize: '1em' }} onClick={handleClose}>
                  Cancelar
                </Button>
                <Button style={{ fontSize: '1em' }} onClick={handleSendFinish}>
                  Finalizar
                </Button>
              </>
            ) : (
              <Button
                style={{ fontSize: '1em' }}
                onClick={() => {
                  setOpen(false)
                  setDataConference([])
                }}
              >
                Ok
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <ViewElearning pageName={'PickToLight'} open={viewElearning} setOpen={setViewElearning} />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Conferência de peças</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon onClick={() => setViewElearning(true)} />
          </IconButton>
        </Box>
        <FormControl sx={{ width: '100%', mt: 2 }}>
          <InputLabel id="demo-simple-select-label">Pedido</InputLabel>
          <Select
            label="Selecione..."
            value={orderSelect}
            onChange={e => setOrderSelect(e.target.value)}
          >
            {orders.map((item, index) => {
              return (
                item.movimentationERPitemsCollection.length > 0 && (
                  <MenuItem value={item.movimentationOrderId} key={index}>
                    {item.movimentationOrderNumberOtherSystem}
                  </MenuItem>
                )
              )
            })}
          </Select>
        </FormControl>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
          }}
        >
          <Box>
            <Box
              component="form"
              sx={{ display: 'flex', alignItems: 'center' }}
              onSubmit={handleCheck}
            >
              <TextField
                onChange={e => setCode(e.target.value)}
                value={code}
                name="code"
                label="Código produto"
              />
              <Button type="submit" variant="contained" sx={{ ml: 2 }}>
                Salvar
              </Button>
            </Box>
            {codeError && (
              <Typography sx={{ color: 'red' }} variant="body1">
                Código incorreto
              </Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={removeVerific}
              onChange={() => setRemoveVerific(!removeVerific)}
              label="Remover concluído"
            />
            <Typography variant="body2">Remover concluído</Typography>
          </Box>
        </Box>
        {dataConference.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Nome</StyledTableCell>
                  <StyledTableCell align="center">Qtd Total</StyledTableCell>
                  <StyledTableCell align="center">Qtd Lida</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">Tamanho</StyledTableCell>
                  <StyledTableCell align="center">Cor</StyledTableCell>
                  <StyledTableCell align="center">Referencia</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataConference
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow
                      key={index}
                      style={{
                        backgroundColor:
                          row.quantityFound < row.quantity
                            ? '#ef5350'
                            : row.quantityFound > row.quantity
                            ? '#ff9800'
                            : '#4caf50',
                        display: removeVerific && row.quantityFound === row.quantity && 'none',
                        color:
                          row.quantityFound < row.quantity || row.quantityFound === row.quantity
                            ? 'white'
                            : 'black',
                      }}
                    >
                      <StyledTableCell align="center">{row.productName}</StyledTableCell>
                      <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                      <StyledTableCell align="center" style={{ visibility: !table && 'hiden' }}>
                        {row.quantityFound}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.quantityFound < row.quantity
                          ? 'Falta'
                          : row.quantityFound > row.quantity
                          ? 'Erro'
                          : 'OK'}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.size}</StyledTableCell>
                      <StyledTableCell align="center">{row.color}</StyledTableCell>
                      <StyledTableCell align="center">{row.reference}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={dataConference.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '1rem',
          }}
        >
          {dataConference.length > 0 && (
            <>
              <Button onClick={() => handleUndo()} variant="contained">
                Refazer
              </Button>
              <Button onClick={() => handleCancel()} variant="contained">
                Cancelar
              </Button>
              <Button onClick={() => handleFinish()} variant="contained">
                Finalizar
              </Button>
            </>
          )}
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default PickToLight
