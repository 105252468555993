import axios from 'axios'

export const postOpenAiGpt = async (token, data) => {
  const response = await axios.post(
    `/api/OpenAIChat/Chat`,
    { data },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}
