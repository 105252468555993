import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import * as XLSX from 'xlsx'
import { deleteFaction, getFaction } from '../../../services/factionService'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import PropTypes from 'prop-types'
import HelpIcon from '@mui/icons-material/Help'
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  Paper,
  InputLabel,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
  Snackbar,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tooltip,
} from '@mui/material'
import FaccaoCreate from '../../Create/FaccaoCreate'
import FactionEdit from '../../Edit/FactionEdit'

//icones react-icons
import { AiFillFileExcel } from 'react-icons/ai'
import { AiFillPlusCircle } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'
import PermissionComponent from '../../../permissions'

const ReportFaccao = () => {
  const { userRoles, subCompany, nameUrl } = useContext(Context)
  const [companyId, setCompanyId] = useState()
  const [permissionFaccaoView, setPermissionFaccaoView] = useState(false)
  const [loadingSearch, setLoadingSearch] = useState(true)
  const token = localStorage.getItem('token')
  const [faction, setFaction] = useState([])
  const [productOrder, setProductOrder] = useState(false)

  const [loading, setLoading] = useState(false)
  const [downloadingCount, setDownloadingCount] = useState(false)

  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('error')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [idEditing, setIdEditing] = useState('')

  const [totalPagesDownload, setTotalPagesDownload] = useState('')
  const [totalFaction, setTotalFaction] = useState(0)
  const [progress, setProgress] = useState(0)
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [viewElearning, setViewElearning] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleGetFaction = async e => {
    setLoading(true)
    setCompanyId(e.target.value)
    const data = {
      limit: 10,
      offset: 1,
      companyId: e.target.value,
    }
    const response = await getFaction(token, nameUrl, data)
    setTotalPagesDownload(response.data.totalpage + 1)
    setTotalFaction(response.data.total)
    setFaction(response.data.result)
    setLoading(false)
    setLoadingSearch(false)
  }

  const getFactionHandle = async () => {
    if (companyId) {
      setLoading(true)
      const data = {
        limit: 10,
        offset: 1,
        companyId: companyId,
      }
      const response = await getFaction(token, nameUrl, data)
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setTotalPagesDownload(response.data.totalpage + 1)
        setTotalFaction(response.data.total)
        setFaction(response.data.result)
      }
      setLoading(false)
      setLoadingSearch(false)
    }
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const downloadData = async () => {
    setDownloadMessage('Inicializando')
    setDownloading(true)
    setDownloadingCount(true)
    const data = {
      companyId: companyId,
      limit: 500,
      offset: 1,
    }

    const resp = await getFaction(token, nameUrl, data)
    finishDownload(resp.data.totalpage + 1)
  }

  const finishDownload = async pages => {
    let result = []

    for (let i = 1; i <= pages; i++) {
      setDownloadMessage(`Baixando ${i} de ${pages}...`)
      setProgress((i / pages) * 100 + '%')
      const data = {
        limit: 500,
        offset: i,
        companyId: companyId,
      }
      const response = await getFaction(token, nameUrl, data)
      result = result.concat(response.data.result)
    }
    setDownloadMessage(`Seu arquivo está pronto.`)
    setDownloading(false)

    const relatorioFinal = []
    result.map(item => {
      relatorioFinal.push({
        Nome: item.name,
        CNPJ: item.cnpj,
        Compania: item.companyName,
        Sequência: item.sequence,
        Ativo: item.active ? 'Sim' : 'Não',
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Fábrica')
    XLSX.writeFile(workbook, `Fábrica.xlsx`)
    setProgress(0)
  }

  useEffect(() => {}, [productOrder])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleEdit = id => {
    setIdEditing(id)
    setExpanded(true)
    setEditing(true)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    try {
      await deleteFaction(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Fábrica deletada com sucesso!')

      setAlerta(true)
      setLoading(false)
      getFactionHandle()
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <PermissionComponent role="FaccaoVIEW">
          <Dialog
            open={permissionFaccaoView}
            // TransitionComponent={Transition}
            // keepMounted
            //onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            fullWidth
          >
            <DialogTitle style={{ fontSize: '1.2em' }} id="alert-dialog-title">
              Voce não tem permissão para acessar esses dados.
            </DialogTitle>
            <DialogContent>
              <DialogContentText style={{ fontSize: '1.2em' }} id="alert-dialog-slide-description">
                Verifique suas permissões
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button style={{ fontSize: '1em' }} onClick={() => window.history.back()}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </PermissionComponent>

        <Dialog open={downloading} keepMounted aria-describedby="alert-dialog-slide-description">
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessage}</DialogTitle>
            <LinearProgressWithLabel value={progress} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <ViewElearning pageName={'ReportFaccao'} open={viewElearning} setOpen={setViewElearning} />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Relatório de Fábricas</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>

        {/* Criar  */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>{editing ? 'Editar Fábrica' : 'Cadastrar Fábrica'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="FaccaoEDIT">
                <FactionEdit
                  setEditing={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  factionId={idEditing}
                  handleFetchData={getFactionHandle}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="FaccaoCREATE">
                <FaccaoCreate
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={getFactionHandle}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>

        {/* Deletar */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>

        <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
            {faction.length > 0 && (
              <Button onClick={downloadData} variant="contained" endIcon={<AiFillFileExcel />}>
                Excel
              </Button>
            )}
          </Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', ml: 1 }}
          >
            <PermissionComponent role="FaccaoCREATE">
              <Button
                variant="contained"
                endIcon={<AiFillPlusCircle />}
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
              >
                Criar
              </Button>
            </PermissionComponent>
          </Box>
        </Box>

        <Box
          sx={{ alignItems: 'center', justifyContent: 'space-between', mt: 2 }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box sx={{ width: '100%' }}>
            <InputLabel>Empresa</InputLabel>
            <Select
              label="Empresa"
              value={companyId}
              onChange={e => handleGetFaction(e)}
              sx={{ width: '100%' }}
            >
              {subCompany.map((item, index) => {
                return (
                  <MenuItem value={item.companyId} key={index}>
                    {item.nameCompany} - {item.name}
                  </MenuItem>
                )
              })}
            </Select>
          </Box>
        </Box>
        {faction.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="left">Nome</StyledTableCell>
                  <StyledTableCell align="left">CNPJ</StyledTableCell>
                  <StyledTableCell align="left">Compania</StyledTableCell>
                  <StyledTableCell align="left">Ativo</StyledTableCell>
                  <StyledTableCell align="left">Sequência</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {faction
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={row.factionId}>
                      <StyledTableCell align="left" sx={{ width: 100 }}>
                        <PermissionComponent role="FaccaoDELETE">
                          <Tooltip title="Apagar" arrow>
                            <IconButton
                              onClick={() => deleteAlert(row.factionId)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                      </StyledTableCell>
                      <StyledTableCell align="left" sx={{ width: 100 }}>
                        <PermissionComponent role="FaccaoEDIT">
                          <Tooltip title="Editar" arrow>
                            <IconButton
                              onClick={() => handleEdit(row.factionId)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <ModeEditOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                      </StyledTableCell>

                      <StyledTableCell align="left" sx={{ width: 200 }}>
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="left" sx={{ width: 200 }}>
                        {row.cnpj}
                      </StyledTableCell>
                      <StyledTableCell align="left" sx={{ width: 200 }}>
                        {row.companyName}
                      </StyledTableCell>
                      <StyledTableCell align="left" sx={{ width: 200 }}>
                        {row.active ? 'Sim' : 'Não'}
                      </StyledTableCell>
                      <StyledTableCell align="left" sx={{ width: 200 }}>
                        {row.sequence}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={faction.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ReportFaccao
