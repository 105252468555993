import HelpIcon from '@mui/icons-material/Help'
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControl,
  IconButton,
  LinearProgress,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import '../../../assets/global.css'

//icones react-icons
import { AiFillFilePdf } from 'react-icons/ai'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'
import { getConferenceFinish } from '../../../services/requestServices'
import { pickToLightPDF } from '../../../services/pickToLightServices'

const ReportConference = () => {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const [loading, setLoading] = useState(false)
  const [orderSelect, setOrderSelect] = useState()

  const [progressCount, setProgressCount] = useState(0)
  const [downloadMessageCount, setDownloadMessageCount] = useState('Pronto')
  const [downloadingCount, setDownloadingCount] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [viewElearning, setViewElearning] = useState(false)
  const [orders, setOrders] = useState([])
  const [itemsPDF, setItemsPDF] = useState()

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const handleClose = () => {
    setAlerta(false)
  }

  useEffect(() => {
    fetchConference()
  }, [])

  const fetchConference = async () => {
    setLoading(true)
    try {
      const response = await getConferenceFinish(token, nameUrl)
      setOrders(response.data)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (orderSelect) {
      fetchPDF()
    }
  }, [orderSelect])

  const fetchPDF = async () => {
    setLoading(true)
    try {
      const response = await pickToLightPDF(token, nameUrl, orderSelect)
      setItemsPDF(response.data)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
      setTitle('Ordem não encontrada. Tente novamente.')
      setSeverity('error')
      setAlerta(true)
    }
  }

  const handleDownload = async () => {
    const blob = new Blob([itemsPDF], { type: 'application/pdf' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = 'Relatorio de Conferencia.pdf'
    link.click()
    URL.revokeObjectURL(link)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={downloadingCount}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessageCount}</DialogTitle>
            <LinearProgressWithLabel value={progressCount} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <ViewElearning
          pageName={'ReportDissassociation'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Relatório de conferência</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box>
          <Box
            sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <FormControl sx={{ width: '100%', mt: 2 }}>
              <Autocomplete
                disablePortal
                id="lista-produtos"
                options={orders}
                //sx={{ width: 300 }}
                label="Pedido"
                noOptionsText="Nenhuma ordem encontrada"
                getOptionLabel={option => option.movimentationOrderNumberOtherSystem}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.movimentationOrderId}>
                      {option.movimentationOrderNumberOtherSystem}
                    </li>
                  )
                }}
                renderInput={params => <TextField {...params} label="Procurar ordem" />}
                //onChange={(e, v) => setProductId(v)}
                onChange={(e, v) => {
                  setOrderSelect(v.movimentationOrderId)
                }}
              />
            </FormControl>
          </Box>
          {itemsPDF && (
            <Box
              sx={{
                alignItems: 'center',
                textAlign: 'center',
                mt: 2,
                width: { xs: 1, md: 0.3 },
                minWidth: { xs: 1, md: 0.3 },
                p: 2,
              }}
            >
              <Button
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: ' space-between',
                  fontSize: '.9em',
                  width: '6.5rem',
                }}
                variant="contained"
                onClick={handleDownload}
              >
                PDF <AiFillFilePdf size="2em" />
              </Button>
            </Box>
          )}
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ReportConference
