import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../context/AuthContext'
import {
  getProductAll,
  getProductAssociationSeearchService,
  getProductAssociationService,
} from '../../services/productService'
import { ContactlessOutlined } from '@mui/icons-material'
import axios from 'axios'
import { uploadPhotos } from '../../services/uploadPhotos'
import ArrowDropDownIcom from '@mui/icons-material/ArrowDropDown'
import debounce from 'lodash.debounce'

const UploadImage = () => {
  const { subCompany, userId, nameUrl } = useContext(Context)
  const token = localStorage.getItem('token')
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState('')
  const [previewUrl, setPreviewUrl] = useState(null)
  const [file, setFile] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [uploadSuccess, setUploadSuccess] = useState(null)
  const [filial, setFilial] = useState('')
  const [product, setProduct] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [loadingMore, setLoadingMore] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [offset, setOffset] = useState(0)
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('asc')
  const [idProduct, setIdProduct] = useState('')
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const limit = 20

  // /api/ProductItens/UploadPhoto endpoint para fazer o upload ****************

  // const fetchProducts = async subCompanyId => {
  //   try {
  //     const response = await axios.get(`${nameUrl}/api/ProductItens/GetAll/${subCompanyId}`, {
  //       headers: {
  //         Authorization: 'Bearer ' + token,
  //       },
  //     })
  //     console.log(response.data, 'DEU BOM')
  //     return response.data
  //   } catch (error) {
  //     console.error('Erro ao buscar produtos:', error)
  //     throw error
  //   }
  // }

  const handleFileChange = event => {
    const selectedFile = event.target.files[0]
    if (selectedFile) {
      setFile(selectedFile)
      setPreviewUrl(URL.createObjectURL(selectedFile))
    }
  }

  const getProduct = useCallback(
    async selectedFilialId => {
      console.log(selectedFilialId, 'SUBCOMPANY')
      setLoading(true)
      console.log('OIIOIOIOIOIOIOIOI')
      try {
        const response = await getProductAssociationSeearchService(
          token,
          nameUrl,
          limit,
          offset,
          '',
          selectedFilialId,
          search
        )
        console.log(response, 'DEU CERTO')
        setProduct(response.data.result)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    },
    [token, nameUrl]
  )
  console.log(filial, 'FILIAL')

  const fetchProducts = useCallback(
    async (searchTerm = '') => {
      if (!filial?.subCompanyId) return
      setLoading(true)
      console.log(filial.subCompanyId, 'SOU A SUB COMPANY')
      try {
        const response = await getProductAssociationSeearchService(
          token,
          nameUrl,
          limit,
          offset,
          order,
          filial.subCompanyId,
          searchTerm
        )
        setProduct(response.data.result)
      } catch (error) {
        console.error('Erro ao buscar produtos:', error)
        setProduct([])
      } finally {
        setLoading(false)
      }
    },
    [token, nameUrl, limit, offset, order, filial]
  )

  useEffect(() => {
    const debouncedFetch = debounce(() => {
      fetchProducts(search)
    }, 300)

    debouncedFetch()

    return () => debouncedFetch.cancel()
  }, [search, fetchProducts])

  const loadProducts = async () => {
    if (filial) {
      console.log(filial, 'FILIAL AQUII')
      try {
        const fetchedProducts = await getProduct(filial.subCompanyId)
        console.log(fetchedProducts.data.result, 'FETCCH')
        setProduct(fetchedProducts)
      } catch (error) {
        console.error('Erro ao carregar produtos:', error)
      }
    }
  }

  // useEffect(() => {
  //   loadProducts()
  // }, [filial])

  useEffect(() => {
    if (filial?.subCompanyId) {
      fetchProducts()
    }
  }, [filial, fetchProducts])

  console.log(product, 'PRODUTOS CHEGOU')
  const handleChange = event => {
    setFilial(event.target.value)
    console.log(event.target.value)
  }

  const filteredProducts = product.filter(
    item =>
      item.productName.toLowerCase().includes(selectedProduct.toLowerCase()) ||
      item.sku.includes(selectedProduct.toLowerCase()) ||
      item.serial.toLowerCase().includes(selectedProduct.toLowerCase())
  )

  const handleSubmit = async event => {
    event.preventDefault()
    if (!filial || !idProduct || !file) {
      console.error('Por favor, selecione todos os campos.')
      return
    }
    const formData = new FormData()
    console.log(selectedProduct, 'EU AQUI')
    formData.append('image', file)
    // formData.append('subcompanyID', filial.subCompanyId)
    formData.append('productItensId', idProduct)

    formData.forEach((value, key) => {
      console.log(key, value)
    })

    setUploading(true)
    try {
      const response = await uploadPhotos({
        token,
        formData,
        nameUrl,
      })

      const successMessage = response?.message || 'Upload realizado com sucesso!'
      setOpenSnackbar(true)
      setSnackbarMessage(successMessage)
      setSelectedProduct('')
      setPreviewUrl(null)
      setFilial('')
      setIdProduct('')
      setSearch('')
    } catch (error) {
      console.error('Erro ao enviar os dados:', error)
      let errorMessage = 'Falha no upload, tente novamente.'

      if (error.response?.status === 500) {
        errorMessage =
          error.response?.data?.message || 'Erro interno do servidor, tente novamente mais tarde.'
      } else if (error.response?.data?.message) {
        errorMessage = error.response.data.message
      }

      setOpenSnackbar(true)
      setSnackbarMessage(errorMessage)
    } finally {
      setUploading(false)
    }
  }
  console.log(selectedProduct, 'SELECTED')
  return (
    <Box sx={{ mt: 7.5 }}>
      <Paper sx={{ elevation: 3 }}>
        <Typography variant="h5" padding={1}>
          Carregamento de fotos
        </Typography>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        />
        <Box
          component="form"
          sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2 }}
          onSubmit={getProduct}
        >
          <FormControl sx={{ width: '100%', mt: 0 }}>
            <InputLabel id="filial-select-label">Escolha a filial</InputLabel>
            <Select
              labelId="filial-select-label"
              label="Escolher a filial"
              value={filial || ''}
              onChange={handleChange}
            >
              {subCompany?.map(item => (
                <MenuItem key={item.subCompanyId} value={item}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: '100%' }}>
            <Autocomplete
              freeSolo
              disableClearable
              options={product}
              getOptionLabel={option =>
                `${option.idProductPartner} - ${option.productName} - ${option.serial}`
              }
              inputValue={search}
              onInputChange={(event, newInputValue) => setSearch(newInputValue)}
              onChange={(event, newValue) => {
                if (newValue) {
                  setIdProduct(newValue.productItensId)
                }
              }}
              loading={loading}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Pesquisar produto"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={24} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.productId}>
                  {`${option.sku} - ${option.productName} - ${option.serial}`}
                </li>
              )}
            />
          </FormControl>
          <Grid sx={{ display: 'Flex', gap: 2 }}>
            {/* <Button variant="contained" fullWidth component="label"> */}

            <TextField
              sx={{ width: '100%' }}
              variant="outlined"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            />
            {/* Carregar foto para preview */}
            {/* <input type="file" hidden accept="image/*"  /> */}
            {/* </Button> */}
            <Button
              variant="contained"
              fullWidth
              type="submit"
              disabled={uploading}
              onClick={handleSubmit}
            >
              {uploading ? 'Enviando...' : 'Carregar Foto'} <CloudUploadIcon />
            </Button>
          </Grid>
        </Box>
      </Paper>
      {previewUrl && (
        <Paper elevation={3} sx={{ mt: 4, p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Preview:
          </Typography>
          <Box
            component="img"
            sx={{
              maxWidth: { xs: '100%', sm: '80%', md: '60%' },
              height: 'auto',
              borderRadius: 1,
              display: 'block',
              // margin: '0 auto',
            }}
            src={previewUrl}
            alt="Preview"
          />
        </Paper>
      )}
    </Box>
  )
}
export default UploadImage
