import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getUpdateService } from '../../../services/admServices'
import { Context } from '../../../context/AuthContext'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import HelpIcon from '@mui/icons-material/Help'
import ViewElearning from '../../ViewElearning'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  Paper,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tooltip,
} from '@mui/material'
import UpdateCreated from '../../Create/UpdateCreate'
import PermissionComponent from '../../../permissions'

const Update = () => {
  const token = localStorage.getItem('token')
  const [update, setUpdate] = useState([])
  const [pagination, setPagination] = useState({})
  const [updateLimit, setUpdateLimit] = useState(10)
  const [updateOffset, setUpdateOffset] = useState(0)
  const [viewElearning, setViewElearning] = useState(false)

  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [idEditing, setIdEditing] = useState('')

  const { userRoles, nameUrl } = useContext(Context)
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getUpdate = useCallback(async () => {
    try {
      const response = await getUpdateService(token, nameUrl, updateLimit, updateOffset)
      setUpdate(response.data.result)
      setPagination(response.data)
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(() => {
    getUpdate()
  }, [])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  return (
    <>
      <Box sx={{ mt: 7 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <ViewElearning pageName={'Update'} open={viewElearning} setOpen={setViewElearning} />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Atualizar Versão</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>

        <PermissionComponent role="UpdateCreate">
          <Accordion expanded={expanded}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => setExpanded(!expanded)}
            >
              <Typography>Cadastrar Atualização de Versão</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <UpdateCreated
                setExpanded={setExpanded}
                setAlerta={setAlerta}
                setSeverity={setSeverity}
                setTitle={setTitle}
                handleFetchData={getUpdate}
              />
            </AccordionDetails>
          </Accordion>
        </PermissionComponent>

        {update.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center"></StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                  <StyledTableCell align="center">Nome</StyledTableCell>
                  <StyledTableCell align="center">Versão</StyledTableCell>
                  <StyledTableCell align="center">Diretório</StyledTableCell>
                  <StyledTableCell align="center">Arquivo</StyledTableCell>
                  <StyledTableCell align="center">Criado</StyledTableCell>
                  <StyledTableCell align="center">Link</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {update.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell align="center" sx={{ width: 50 }}>
                      <PermissionComponent role="UpdateDelete">
                        <Tooltip title="Excluir" arrow>
                          <IconButton
                            onClick={() => navigate(`/SegmentGroupDelete/${row.segmentGroupId}`)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </Tooltip>
                      </PermissionComponent>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ width: 50 }}>
                      <PermissionComponent role="UpdateDelete">
                        <Tooltip title="Editar" arrow>
                          <IconButton
                            onClick={() => navigate(`/SegmentGroupEdit/${row.segmentGroupId}`)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <ModeEditOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </PermissionComponent>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ width: 200 }}>
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ width: 200 }}>
                      {row.version}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ width: 200 }}>
                      {row.directory}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ width: 200 }}>
                      {row.file}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ width: 200 }}>
                      {row.created}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ width: 200 }}>
                      {row.active ? 'Sim' : 'Não'}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={update.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default Update
