import React, { useContext, useEffect } from 'react'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Context } from '../../../context/AuthContext'
import { searchProductService, updateTimeExpirationService } from '../../../services/productService'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import HelpIcon from '@mui/icons-material/Help'
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import PermissionComponent from '../../../permissions'

const TimeExpiration = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const [statusServ, setStatusServ] = useState('Pronto')

  const [produtos, setProdutos] = useState([])
  const [singleProd, setSingleProd] = useState()
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)
  const [produtosList, setProdutosList] = useState([])

  const [updateList, setUpdateList] = useState([])

  const [saving, setSaving] = useState(false)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [viewElearning, setViewElearning] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const addToList = () => {
    const repetido = updateList.find(x => x.productId === singleProd.productId)
    if (repetido) {
      return
    } else {
      const prod = singleProd
      prod.quantityTime = parseInt(singleProd.quantityTime)
      setUpdateList(curr => [...curr, singleProd])
    }
  }

  const changeProd = id => {
    const prod = produtos.find(x => x.productId === id)
    const finalProd = { ...prod }
    setSingleProd(finalProd)
  }

  useEffect(() => {
    if (search === '') {
      const checagem = setTimeout(() => {
        setProdutos([{ label: '', value: '' }])
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearching(true)
      const checagem = setTimeout(() => {
        const dados = {
          companyId: subCompany[0].companyId,
          SearchWord: search,
          limit: 10,
          offset: 0,
        }
        searchProductService(token, nameUrl, dados).then(response => {
          setProdutosList(response.data.result)
          const prodsFinal = []
          response.data.result.map(produto => {
            const obj = produto
            obj.value = produto.productId
            obj.label = `${produto.name}`
            prodsFinal.push(obj)
          })
          setProdutos(prodsFinal)
          setSearching(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [search])

  const saveList = async () => {
    setStatusServ('Salvando...')
    setSaving(true)
    const keysToKeep = ['productId', 'quantityTime', 'typeTime', 'companyId']
    const redux = array =>
      array.map(o =>
        keysToKeep.reduce((acc, curr) => {
          acc[curr] = o[curr]
          return acc
        }, {})
      )
    const finalList = redux(updateList)
    try {
      console.log(finalList, 'finalIst')
      const request = await updateTimeExpirationService(token, nameUrl, finalList)
      setStatusServ(`${finalList.length} produtos salvos. Processando produtos...`)
      setSaving(false)
      setUpdateList([])
      setSingleProd()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <ViewElearning
          pageName={'TimeExpiration'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Data de Validade</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon onClick={() => setViewElearning(true)} />
          </IconButton>
        </Box>
        <Box
          sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-evenly', mb: 2 }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box sx={{ mb: 1 }} width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
            <InputLabel id="demo-simple-select-label">Produto</InputLabel>
            {/* <Select onChange={e => changeProd(e.target.value)} sx={{ width: '100%' }}>
              {produtos.map(item => {
                return (
                  <MenuItem value={item.productId}>
                    ({validadeReturn(item.typeTime, item.quantityTime)}) - {item.name}
                  </MenuItem>
                )
              })}
            </Select> */}
            {/* <Autocomplete
              disablePortal
              id="lista-produtos"
              options={produtos}
              //sx={{ width: 300 }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.productId}>
                    {option.label}
                  </li>
                )
              }}
              renderInput={params => <TextField {...params} label="Produto" />}
              onChange={(e, v) => {
                changeProd(v.value)
              }}
            /> */}
            <Autocomplete
              disablePortal
              id="lista-produtos"
              options={produtos}
              //sx={{ width: 300 }}
              loading={searching}
              loadingText="Procurando..."
              noOptionsText="Nenhum produto encontrado"
              isOptionEqualToValue={(option, value) => option.productId === value.value}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.productId}>
                    {option.label}
                  </li>
                )
              }}
              renderInput={params => <TextField {...params} label="Procurar produto" />}
              onInputChange={(e, v) => {
                setSearch(v)
              }}
              onChange={(e, v) => changeProd(v.value)}
            />
          </Box>
          <Box
            sx={{ mb: 1, mt: 2 }}
            width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
          >
            <TextField
              value={statusServ}
              disabled
              variant="outlined"
              label="Status"
              sx={{ width: '100%' }}
            />
          </Box>
        </Box>
        {singleProd && (
          <>
            <Box
              sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-evenly', mb: 2 }}
              display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
            >
              <Box width={{ xs: '100%', sm: '100%', md: '30%', lg: '30%', xl: '30%' }}>
                <TextField
                  value={singleProd.name}
                  type="text"
                  disabled
                  label="Nome"
                  sx={{ mb: 1, width: '100%' }}
                />
              </Box>
              <Box width={{ xs: '100%', sm: '100%', md: '30%', lg: '30%', xl: '30%' }}>
                <TextField
                  value={singleProd.referencia}
                  type="text"
                  disabled
                  label="Referência"
                  sx={{ mb: 1, width: '100%' }}
                />
              </Box>
              <Box width={{ xs: '100%', sm: '100%', md: '30%', lg: '30%', xl: '30%' }}>
                <TextField
                  value={singleProd.sku}
                  type="text"
                  disabled
                  label="SKU"
                  sx={{ mb: 1, width: '100%' }}
                />
              </Box>
            </Box>
            <Box
              sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-evenly', mb: 2 }}
              display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
            >
              <Box width={{ xs: '100%', sm: '100%', md: '30%', lg: '30%', xl: '30%' }}>
                <InputLabel id="demo-simple-select-label">Vencimento</InputLabel>

                <TextField
                  value={singleProd.typeTime === 0 ? 'Sem vencimento' : singleProd.quantityTime}
                  onChange={e =>
                    setSingleProd(curr => ({
                      ...curr,
                      quantityTime: e.target.value,
                    }))
                  }
                  sx={{ width: '100%' }}
                  disabled={singleProd.typeTime === 0 ? true : false}
                />
              </Box>
              <Box width={{ xs: '100%', sm: '100%', md: '30%', lg: '30%', xl: '30%' }}>
                <InputLabel id="demo-simple-select-label">Intervalo</InputLabel>
                <Select
                  value={singleProd.typeTime}
                  onChange={e =>
                    setSingleProd(current => ({
                      ...current,
                      typeTime: parseInt(e.target.value),
                    }))
                  }
                  sx={{ width: '100%' }}
                >
                  <MenuItem value="0">Sem Vencimento</MenuItem>
                  <MenuItem value="1">Dias</MenuItem>
                  <MenuItem value="3">Meses</MenuItem>
                  <MenuItem value="2">Anos</MenuItem>
                </Select>
              </Box>
            </Box>
            <Button variant="contained" onClick={addToList}>
              Adicionar à lista
            </Button>
          </>
        )}
        {updateList.length > 0 && (
          <>
            <TableContainer component={Paper} sx={{ mt: '2rem' }}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell> </StyledTableCell>
                    <StyledTableCell align="center">Nome Produto</StyledTableCell>
                    <StyledTableCell align="center">Referência</StyledTableCell>
                    <StyledTableCell align="center">SKU</StyledTableCell>
                    <StyledTableCell align="center">Vencimento</StyledTableCell>
                    <StyledTableCell> </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {updateList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell component="th" scope="row">
                          <PermissionComponent role="TimeExpirationDELETE">
                            <Tooltip title="Excluir" arrow>
                              <IconButton
                                onClick={() =>
                                  setUpdateList(curr => curr.filter((v, i) => i !== index))
                                }
                                sx={{ cursor: 'pointer' }}
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                            </Tooltip>
                          </PermissionComponent>
                        </StyledTableCell>

                        <StyledTableCell align="center">{row.name}</StyledTableCell>
                        <StyledTableCell align="center">{row.referencia}</StyledTableCell>
                        <StyledTableCell align="center">{row.sku}</StyledTableCell>
                        <StyledTableCell align="center">{row.quantityTime}</StyledTableCell>
                        <StyledTableCell align="center">
                          {row.typeTime == '0' && 'meses'}
                          {row.typeTime == '1' && 'dias'}
                          {row.typeTime == '2' && 'anos'}
                          {row.typeTime == '3' && 'Sem vencimento'}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={updateList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
            <Button variant="contained" onClick={saveList} disabled={saving} sx={{ mt: 2 }}>
              Salvar
            </Button>
          </>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default TimeExpiration
