import axios from 'axios'

export const getAllMovOrderService = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrder/GetAll?SubCompanyId=${data.SubCompanyId}&limit=${data.limit}&offset=${data.offset}&Typeorder=17`,

    { headers: { Authorization: 'Bearer ' + token } }
  )

  return response
}

export const getAllMovOrderType = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrder/GetAll?SubCompanyId=${data.SubCompanyId}&limit=${data.limit}&offset=${data.offset}&Typeorder=${data.typeOrder}&Order=desc`,

    { headers: { Authorization: 'Bearer ' + token } }
  )

  return response
}

export const getAllMovOrderList = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrder/GetAllOrder?SubCompanyId=${data.SubCompanyId}&TypeOrder=${data.typeOrder}&Order=desc`,

    { headers: { Authorization: 'Bearer ' + token } }
  )

  return response
}

export const updateQuantityStock = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/UpdateQuantityStock`,
    data,

    { headers: { Authorization: 'Bearer ' + token } }
  )

  return response
}

export const createProductsUpload = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/ProductItens/UplaodCreateAssets`,
    data,

    { headers: { Authorization: 'Bearer ' + token } }
  )

  return response
}

export const deleteProductsUpload = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/ProductItens/UplaodDeleteAssets`,
    data,

    { headers: { Authorization: 'Bearer ' + token } }
  )

  return response
}

export const getAllMovOrderBreakOnGoingService = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrder/GetAll?SubCompanyId=${data.SubCompanyId}&limit=${data.limit}&offset=${data.offset}&Typeorder=17&movimentationOrderStatus=${data.movimentationOrderStatus}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getAllMovBreakService = async (token, nameUrl, data, order) => {
  let orderToString = ''
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderBreak/GetAll?MovimentationOrderId=${data.movimentationOrderId}&limit=${data.limit}&offset=${data.offset}&Order=${orderToString}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getMovBreakService = async (token, nameUrl, breakId) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderBreak/Get?MovimentationOrderBreakId=${breakId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getInfoKanbanService = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderBreak/GeInfoKamban?MovimentationOrderId=${data.movimentationOrderId}&SubCompanyId=${data.subCompanyId}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getMovBreakListService = async (token, nameUrl, breakId) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderBreak/GetList?MovimentationOrderId=${breakId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const createMovBreakService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/MovimentationOrderBreak/Create`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const movBreakProgressService = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrderBreak/InProgress`,
    {
      movimentationOrderBreakId: data,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const movBreakCompletedService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/MovimentationOrderBreak/Completed`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const movBreakCancelService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/MovimentationOrderBreak/Cancel`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getMovimentationOrderBreakAllInfoService = async (token, nameUrl, breakId) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderBreak/lMovimentationOrderBreakAllInfoGet?MovimentationOrderBreakId=${breakId}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const createOrderProcessFullService = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/CreateOrderProcessFull`,
    data,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )

  return response
}

export const getBreakInfosService = async (token, nameUrl, movId) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderBreak/GetBreakInfos?MovimentationOrderId=${movId}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const deleteMovBreakItemProcess = async (token, nameUrl, movId) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderBreakItemProcess/Delete?MovimentationBreakOrderItemProcessId=${movId}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const resetProcess = async (token, nameUrl, movId) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderBreak/ResetProcess?MovimentationOrderBreakId=${movId}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const updateCicle = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderBreak/UpdateCicle?movimentationBreakOrderItemProcessId=${data.movimentationBreakOrderItemProcessId}&Cicle=${data.cicle}`,
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}
