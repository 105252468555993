import axios from 'axios'

export const createPickingListService = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/CreateOrderFullItens`,
    data,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}

export const getAllPickingListService = async (token, nameUrl, data, typeOrder) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrder/GetOrderPagination?SubCompanyId=${data.SubCompanyId}&Typeorder=${typeOrder}&limit=${data.limit}&offset=${data.offset}&Order=${data.Order}`,

    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getAllPickingListServiceSearch = async (token, nameUrl, data) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrder/GetOrderPagination?SubCompanyId=${data.SubCompanyId}&Typeorder=${data.typeOrder}&limit=${data.limit}&offset=${data.offset}&Order=${data.order}&Search=${data.text}`,

    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getAllPickingListERPService = async (token, nameUrl, id) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderItemERP/Get?MovimentationOrderId=${id}`,

    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const pickingListSuccessService = async (token, nameUrl, id) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderItemERP/SeparatioonItensERPSuccess?OrderMovimentationERPItemId=${id}`,

    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const pickingListNoSuccessService = async (token, nameUrl, id) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderItemERP/SeparatioonItensERPNoSuccess?OrderMovimentationERPItemId=${id}`,

    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const movimentationOrderItemERPDelete = async (token, nameUrl, id) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderItemERP/Delete?OrderMovimentationERPItemId=${id}`,

    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const pickingListFinish = async (token, nameUrl, id) => {
  const response = await axios.get(
    `/${nameUrl}/api/MovimentationOrderItemERP/SeparatioonFinish?MovimentationOrderId=${id}`,

    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const getOrderService = async (token, nameUrl, id) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/GetId`,
    { movimentationOrderId: id },
    { headers: { Authorization: 'Bearer ' + token } }
  )
  return response
}

export const sendConfirmConference = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/SendConfirmIntegrationOrder`,
    data,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}

export const orderReceiveService = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/MovimentationOrder/CreateOrderReceiveLabel`,
    data,
    {
      headers: { Authorization: 'Bearer ' + token },
    }
  )
  return response
}
