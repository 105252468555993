import axios from 'axios'

export const getAllMachines = async (token, nameUrl) => {
  const response = await axios.get(`/api/MachineReceipt/GetAll`, {
    headers: { Authorization: 'Bearer ' + token },
  })
  return response
}

export const createRecipeMachine = async (token, data) => {
  console.log(data, 'estou aqui')
  const response = await axios.post(`/api/MachineReceipt/CreateBulk`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
  return response
}

export const createBulkOfRecipeMachine = async (token, nameUrl, data) => {
  console.log(data, 'data Bulk')
  const response = await axios.post(`/api/MachineReceipt/CreateBulk`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
  return response
}
