import axios from 'axios'

//get sem paginação
export const getAllZplDefault = async (token, nameUrl) => {
  const response = await axios.get(`/${nameUrl}/api/PrinterZPLDefault/GetAll`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//get paginação
export const getZplDefault = async (token, nameUrl, data) => {
  let orderToString
  if (data.order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }
  const response = await axios.get(
    `/${nameUrl}/api/PrinterZPLDefault/GetAllPagination?CompanyId=${data.companyId}&limit=${data.limit}&offset=${data.offset}&order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//get ZPLDefault especifico
export const getZPLDefaultId = async (token, nameUrl, id) => {
  const response = await axios.get(`/${nameUrl}/api/PrinterZPLDefault/Get/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//criação
export const createZPLDefault = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/PrinterZPLDefault/Create`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//delete
export const deleteZPLDefault = async (token, nameUrl, data) => {
  const response = await axios.post(
    `/${nameUrl}/api/PrinterZPLDefault/Delete`,
    {
      printerZPLDefaultId: data,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//edit
export const editZPLDefault = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/PrinterZPLDefault/Edit`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//=================
//ZPLRef
//=================
export const generateViewZPLService = async (token, nameUrl, data) => {
  try {
    const response = await axios.post(`/${nameUrl}/api/PrinterZplReference/GenerateView`, data, {
      headers: { Authorization: 'Bearer ' + token },
    })

    return response
  } catch (err) {
    console.log(err)
  }
}

export const createZplReferenceService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/PrinterZplReference/Create`, data, {
    headers: { Authorization: 'Bearer ' + token },
  })
  console.log(response)

  return response
}

export const getAllZPLReferenceService = async (token, nameUrl) => {
  try {
    const response = await axios.get(`/${nameUrl}/api/PrinterZplReference/GetAll`, {
      headers: { Authorization: 'Bearer ' + token },
    })
    return response
  } catch (err) {
    console.log(err)
  }
}

export const getAllZPLReferencePag = async (token, nameUrl, data) => {
  try {
    let orderToString
    if (data.order) {
      orderToString = 'asc'
    } else {
      orderToString = 'desc'
    }
    const response = await axios.get(
      `/${nameUrl}/api/PrinterZplReference/GetAllPagination?CompanyId=${data.companyId}&limit=${data.limit}&offset=${data.offset}&order=${orderToString}`,
      { headers: { Authorization: 'Bearer ' + token } }
    )
    return response
  } catch (e) {
    console.log(e)
  }
}

export const deleteZplReferenceService = async (token, nameUrl, printerZplReferenceId) => {
  try {
    const response = await axios.post(
      `/${nameUrl}/api/PrinterZplReference/Delete`,
      { printerZplReferenceId },
      { headers: { Authorization: 'Bearer ' + token } }
    )
    return response
  } catch (e) {
    console.log(e)
  }
}

export const editZplReferenceService = async (token, nameUrl, data) => {
  try {
    const response = await axios.post(`/${nameUrl}/api/PrinterZplReference/Edit`, data, {
      headers: { Authorization: 'Bearer ' + token },
    })
    return response
  } catch (e) {
    console.log(e)
  }
}

export const getSingleZplReferenceService = async (token, nameUrl, id) => {
  try {
    const response = await axios.get(`/${nameUrl}/api/PrinterZplReference/Get/${id}`, {
      headers: { Authorization: 'Bearer ' + token },
    })
    return response
  } catch (e) {
    console.log(e)
  }
}

export const labelaryService = async data => {
  try {
    const response = await axios.post(
      `/v1/printers/8dpmm/labels/4x6/0/`,
      // 'http://api.labelary.com/label/v1/printers/8dpmm/labels/4x6/0/`,
      data,
      { responseType: 'arraybuffer' }
    )

    return response
  } catch (e) {
    console.log(e)
  }
}

export const printCodeZPl = async data => {
  try {
    const response = await axios.post(
      'http://192.168.2.107:9100',
      // 'http://api.labelary.com/label/v1/printers/8dpmm/labels/4x6/0/',
      data,
      { responseType: 'arraybuffer' }
    )

    return response
  } catch (e) {
    console.log(e)
  }
}

export const printZpl = async (url, data) => {
  try {
    const response = await axios.post(url, data, { responseType: 'arraybuffer' })

    return response
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const labelaryPDFService = async data => {
  try {
    const response = await axios.post(
      '/v1/printers/8dpmm/labels/4x2/0/',
      // 'http://api.labelary.com/label/v1/printers/8dpmm/labels/4x6/0/',
      data,
      {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf',
        },
      }
    )
    return response
  } catch (e) {
    console.log(e)
  }
}
