import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Select, InputLabel, MenuItem, Box } from '@mui/material'

const soldCitie = [
  {
    data: '2024-01-15T12:00:00',
    visity: 3,
    visityNot: 2,
    allCity: 1,
    countall: 5,
    porcentage: 0.8,
    price: 120.5,
    name: 'City1',
  },
  {
    data: '2024-01-20T18:30:00',
    visity: 7,
    visityNot: 4,
    allCity: 2,
    countall: 2,
    porcentage: 0.4,
    price: 45.8,
    name: 'City2',
  },
  {
    data: '2024-02-05T08:45:00',
    visity: 1,
    visityNot: 1,
    allCity: 4,
    countall: 3,
    porcentage: 0.6,
    price: 87.2,
    name: 'City3',
  },
  {
    data: '2024-01-10T22:15:00',
    visity: 6,
    visityNot: 3,
    allCity: 0,
    countall: 7,
    porcentage: 0.9,
    price: 150.0,
    name: 'City4',
  },
  {
    data: '2024-01-25T14:20:00',
    visity: 2,
    visityNot: 5,
    allCity: 3,
    countall: 4,
    porcentage: 0.2,
    price: 62.3,
    name: 'City5',
  },
  {
    data: '2024-02-10T03:10:00',
    visity: 9,
    visityNot: 0,
    allCity: 2,
    countall: 6,
    porcentage: 0.7,
    price: 105.9,
    name: 'City6',
  },
  {
    data: '2024-02-20T21:45:00',
    visity: 0,
    visityNot: 6,
    allCity: 4,
    countall: 1,
    porcentage: 0.3,
    price: 75.4,
    name: 'City7',
  },
  {
    data: '2024-01-03T05:30:00',
    visity: 5,
    visityNot: 1,
    allCity: 0,
    countall: 8,
    porcentage: 0.5,
    price: 98.1,
    name: 'City8',
  },
  {
    data: '2024-02-15T11:00:00',
    visity: 4,
    visityNot: 3,
    allCity: 1,
    countall: 2,
    porcentage: 0.8,
    price: 120.5,
    name: 'City9',
  },
  {
    data: '2024-02-25T17:15:00',
    visity: 8,
    visityNot: 4,
    allCity: 2,
    countall: 4,
    porcentage: 0.4,
    price: 45.8,
    name: 'City10',
  },
  {
    data: '2024-01-07T09:30:00',
    visity: 0,
    visityNot: 1,
    allCity: 4,
    countall: 3,
    porcentage: 0.6,
    price: 87.2,
    name: 'City11',
  },
  {
    data: '2024-02-01T23:45:00',
    visity: 6,
    visityNot: 3,
    allCity: 0,
    countall: 7,
    porcentage: 0.9,
    price: 150.0,
    name: 'City12',
  },
  {
    data: '2024-02-10T13:00:00',
    visity: 2,
    visityNot: 5,
    allCity: 3,
    countall: 4,
    porcentage: 0.2,
    price: 62.3,
    name: 'City13',
  },
  {
    data: '2024-01-20T03:50:00',
    visity: 9,
    visityNot: 0,
    allCity: 2,
    countall: 6,
    porcentage: 0.7,
    price: 105.9,
    name: 'City14',
  },
  {
    data: '2024-01-15T20:25:00',
    visity: 0,
    visityNot: 6,
    allCity: 4,
    countall: 1,
    porcentage: 0.3,
    price: 75.4,
    name: 'City15',
  },
  {
    data: '2024-02-05T08:30:00',
    visity: 5,
    visityNot: 1,
    allCity: 0,
    countall: 8,
    porcentage: 0.5,
    price: 98.1,
    name: 'City16',
  },
  {
    data: '2024-01-25T11:45:00',
    visity: 4,
    visityNot: 3,
    allCity: 1,
    countall: 2,
    porcentage: 0.8,
    price: 120.5,
    name: 'City17',
  },
  {
    data: '2024-02-15T19:00:00',
    visity: 8,
    visityNot: 4,
    allCity: 2,
    countall: 4,
    porcentage: 0.4,
    price: 45.8,
    name: 'City18',
  },
  {
    data: '2024-02-25T07:15:00',
    visity: 0,
    visityNot: 1,
    allCity: 4,
    countall: 3,
    porcentage: 0.6,
    price: 87.2,
    name: 'City19',
  },
  {
    data: '2024-01-10T15:30:00',
    visity: 6,
    visityNot: 3,
    allCity: 0,
    countall: 7,
    porcentage: 0.9,
    price: 150.0,
    name: 'City20',
  },
]

const GraficsoldCities = ({ soldCities, lastDay, firstDay }) => {
  const [selectValue, setSelectValue] = useState('-1')
  const [selectValueTwo, setSelectValueTwo] = useState('')
  const [data, setData] = useState([])
  const [dataCity, setDataCity] = useState([])
  const [isShowTop10, setIsShowTop10] = useState(false)

  useEffect(() => {
    if (!soldCities || soldCities.length === 0) {
      return
    }
    if (soldCities.length > 0 && selectValue === '') {
      setSelectValue('-1')
    }

    handleFilter()
  }, [soldCities, selectValue, selectValueTwo])

  useEffect(() => {
    if (selectValue === '-1' && selectValueTwo === '' && dataCity.length > 0) {
      setSelectValueTwo('')
    }
  }, [dataCity])

  const handleFilter = () => {
    let filter

    const selectedItems = soldCities.filter(item => {
      const itemDate = new Date(item.data)
      const currentDate = new Date()

      switch (selectValue) {
        case '0': // Dia atual
          console.log(
            itemDate.getDate() === currentDate.getDate() &&
              itemDate.getMonth() === itemDate.getMonth() &&
              itemDate.getFullYear() === itemDate.getFullYear(),
            'currentDate'
          )

          return lastDay
        case '1': // Mês atual
          return (
            itemDate.getFullYear() === itemDate.getFullYear() &&
            itemDate.getMonth() === itemDate.getMonth()
          )
        case '2': // Ano atual
          return itemDate.getFullYear() === itemDate.getFullYear()
        case '3': // Semana atual
          const firstDayOfWeek = itemDate.getDate() - itemDate.getDay()
          const lastDayOfWeek = firstDayOfWeek + 6
          const itemDay = itemDate.getDate()
          return itemDay >= firstDayOfWeek && itemDay <= lastDayOfWeek
        case '4': // Trimestre
          const currentQuarter = Math.floor((itemDate.getMonth() + 3) / 3)
          const itemQuarter = Math.floor((itemDate.getMonth() + 3) / 3)
          return itemQuarter === currentQuarter
        case '5': // Semestre
          const currentSemester = itemDate.getMonth() < 6 ? 1 : 2
          const itemSemester = itemDate.getMonth() < 6 ? 1 : 2
          return itemSemester === currentSemester
        default:
          return true // Considerando 'Dia' como o padrão
      }
    })

    if (selectValue === '-1') {
      filter = soldCities.map(item => ({ name: item.name, price: item.price }))
      setIsShowTop10(false)
    } else {
      filter = [
        {
          name: 'Top 10',
          price: soldCities.slice(0, 10).reduce((sum, item) => sum + (item.price || 0), 0),
        },
      ]
      setIsShowTop10(true)
    }

    const totalSum = selectedItems
      .filter(item => (selectValueTwo === '' ? true : item.name === selectValueTwo))
      .reduce((sum, item) => {
        return sum + (item.price || 0)
      }, 0)

    filter = [{ name: selectValueTwo, price: totalSum }]
    console.log(selectedItems, 'selectedItems')
    setDataCity(selectedItems) // Aqui, configuramos dataCity com os itens filtrados
    setIsShowTop10(false)

    setData(filter)
  }

  const getCategoriesByPeriod = () => {
    const currentDate = new Date()

    switch (selectValue) {
      case '0': // Dia
        return [currentDate.toLocaleDateString(undefined, { weekday: 'long' })]
      case '1': // Mês
        return [currentDate.toLocaleDateString(undefined, { month: 'long' })]
      case '2': // Ano
        return [currentDate.getFullYear().toString()]
      case '3': // Semana
        return ['Semana Atual']
      case '4': // Trimestre
        const currentQuarter = Math.floor((currentDate.getMonth() + 3) / 3)
        return [`${currentQuarter}º Trimestre`]
      case '5': // Semestre
        const currentSemester = currentDate.getMonth() < 6 ? 1 : 2
        return [`${currentSemester}º Semestre`]
      default:
        return dataCity.map(item => item.name).slice(0, 10)
    }
  }

  const options = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: isShowTop10 ? '80%' : '50%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(val)
      },
      offsetX: 0,
      offsetY: 0,

      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },
    stroke: {
      show: true,
      width: 4,
      colors: ['transparent'],
    },
    xaxis: {
      categories: getCategoriesByPeriod(),
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(val)
        },
        style: {
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      shared: true,
      intersect: false,

      y: {
        formatter: function (val) {
          return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(val)
        },
      },
    },
  }

  const series = [
    {
      name: 'Cidade nova que vendeu',
      data:
        selectValue === '-1'
          ? soldCities.slice(0, 10).map(item => item.price)
          : data.map(item => item.price),
    },
  ]

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        gap={2}
        my={2}
      >
        <Box width="100%">
          <InputLabel id="demo-simple-select-label">Selecione o período</InputLabel>
          <Select
            size="small"
            sx={{ width: '100%' }}
            label="Escolher o período"
            value={selectValue}
            onChange={e => {
              setSelectValue(e.target.value)
            }}
          >
            <MenuItem value="-1">Todos</MenuItem>
            <MenuItem value="0">Dia</MenuItem>
            <MenuItem value="1">Mês</MenuItem>
            <MenuItem value="2">Ano</MenuItem>
            <MenuItem value="3">Semana</MenuItem>
            <MenuItem value="4">Trimestre</MenuItem>
            <MenuItem value="5">Semestre</MenuItem>
          </Select>
        </Box>
        <Box width="100%">
          <InputLabel id="demo-simple-select-label">Selecione a cidade</InputLabel>
          <Select
            size="small"
            sx={{ width: '100%' }}
            label="Escolher o período"
            value={selectValueTwo}
            disabled={selectValue === '-1'}
            onChange={e => setSelectValueTwo(e.target.value)}
          >
            {dataCity.map((item, index) => (
              <MenuItem value={item.name} key={index}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>

      <div id="grafico">
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  )
}

export default GraficsoldCities
