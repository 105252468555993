export const formatIP = value => {
  const cleanedValue = value.replace(/[^\d.]/g, '')

  // Adiciona ponto automaticamente enquanto o usuário digita
  if (cleanedValue.length < 15) {
    const lastDotIndex = cleanedValue.lastIndexOf('.')

    // Permite adicionar zeros nos octetos
    if (lastDotIndex > -1 && cleanedValue.length - lastDotIndex <= 4) {
      const octet = cleanedValue.slice(lastDotIndex + 1)
      if (octet.length === 3 && octet !== '000' && cleanedValue.slice(-1) !== '0') {
        return cleanedValue + '.'
      }
    }

    // Adiciona ponto quando necessário
    if (
      cleanedValue.slice(-1) !== '.' &&
      (cleanedValue.length === 3 || cleanedValue.length === 7 || cleanedValue.length === 11)
    ) {
      return cleanedValue + '.'
    }
  }

  // Remove ponto automaticamente ao apagar
  if (cleanedValue.slice(-1) === '.') {
    return cleanedValue.slice(0, -1)
  }

  return cleanedValue
}
