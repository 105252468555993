import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  deleteMovimentationOrderService,
  getOrderPaginationService,
  returnOrderStatus,
} from '../../../services/productService'
import * as XLSX from 'xlsx'
import { RESULTS_PER_PAGE } from '../../../utils/excel'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TransferOrderCreate from '../../Create/TransferOrderCreate'
import TransferOrderItem from '../../Edit/TransferOrderItem'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Grid,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { useNavigate } from 'react-router-dom'
//icones react-icons
import { AiFillFileExcel } from 'react-icons/ai'
import { AiFillPlusCircle } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import { returnStatus } from '../../../assets/statusTypes'
import PermissionComponent from '../../../permissions'
import { AcUnitTwoTone, Add, Api, Delete, Visibility } from '@mui/icons-material'

const MovimentationType = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const [transferOrder, setTransferOrder] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [transferOrderOrder, setTransferOrderOrder] = useState(false)

  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [viewElearning, setViewElearning] = useState(false)
  const [originalList, setOriginalList] = useState([])

  const [totalPagesDownload, setTotalPagesDownload] = useState('')
  const [progress, setProgress] = useState(0)
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [transferOrderEdit, setTransferOrderEdit] = useState()
  const [totalData, setTotalData] = useState(0)
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const fetchData = async values => {
    if (!values || values === '') {
      return
    }
    setSubCompanyId(values.target.value)
    setLoading(true)
    try {
      const response = await getOrderPaginationService(
        token,
        nameUrl,
        { subCompanyId: values ? values.target.value : subCompanyId, typeOrder: 5 },
        rowsPerPage,
        page,
        transferOrderOrder
      )
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
        setTransferOrder([])
      } else {
        setTransferOrder(response.data.result)
        setOriginalList(response.data.result)
        setPageCount(response.data.totalpage + 1)
        setTotalData(response.data.total)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setTitle('Erro no servidor, tente novamente.')
      setAlerta(true)
      setSeverity('error')
      setLoading(false)
    }
  }

  useEffect(() => {
    if (subCompanyId !== '') {
      fetchData()
    }
  }, [page, rowsPerPage])

  useEffect(() => {
    handleFetchData()
  }, [search])

  const handleFetchData = async () => {
    if (subCompanyId) {
      setSearching(true)
      const response = await getOrderPaginationService(
        token,
        nameUrl,
        { subCompanyId: subCompanyId, typeOrder: 5 },
        rowsPerPage,
        0,
        transferOrderOrder,
        search
      )
      setTransferOrder(response.data.result)
      setOriginalList(response.data.result)
      setPageCount(response.data.totalpage + 1)
      setPage(0)
      setTotalData(response.data.total)
      setSearching(false)
    }
  }

  const downloadData = async () => {
    setDownloadMessage('Inicializando')
    setDownloading(true)
    await getOrderPaginationService(
      token,
      nameUrl,
      { subCompanyId, typeOrder: 5 },
      RESULTS_PER_PAGE,
      0,
      transferOrderOrder
    ).then(r => {
      setTotalPagesDownload(r.data.totalpage + 1)
      finishDownload(r.data.totalpage + 1)
    })
  }

  const finishDownload = async pages => {
    let result = []

    for (let i = 0; i <= pages; i++) {
      setDownloadMessage(`Baixando ${i} de ${pages}...`)
      setProgress((i / pages) * 100)
      const response = await getOrderPaginationService(
        token,
        nameUrl,
        { subCompanyId: subCompanyId, typeOrder: 5 },
        RESULTS_PER_PAGE,
        i,
        transferOrderOrder
      )
      result = result.concat(response.data.result)
    }
    setDownloadMessage(`Seu arquivo está pronto.`)
    setDownloading(false)
    const relatorioFinal = []
    result.map(item => {
      relatorioFinal.push({
        'Ordem Bihands': item.movimentationOrderNumberBihands,
        'Ordem (Outro Sistema)': item.movimentationOrderNumberOtherSystem,
        'Filial Saída': item.subCompanyNameFrom,
        'Filial Entrada': item.subCompanyNameTo,

        Criado: new Date(item.createRequestMovimentationOrderDate).toLocaleString(),
        Status: returnOrderStatus(item.movimentationOrderStatus),
        Recebido: item.active ? 'Sim' : 'Não',
      })
    })
    setProgress(0)
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Pedido de Transferencia')
    XLSX.writeFile(workbook, `Pedido de Transferência.xlsx`)
  }

  useEffect(() => {}, [subCompanyId])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleSelectEdit = id => {
    setTransferOrderEdit(id)
    setExpanded(true)
    setEditing(true)
  }

  const deleteMovimentationOrder = async () => {
    setLoading(true)
    try {
      await deleteMovimentationOrderService(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Transferencia deletada com sucesso!')
      setLoading(false)
      setAlerta(true)
      setOpen(false)
      fetchData()
    } catch (err) {
      console.log(err)
      setSeverity('error')
      setTitle('Erro ao deletar, tente novamente.')
      setLoading(false)
      setOpen(false)
      setAlerta(true)
    }
  }

  const handleDelete = id => {
    setIdDelete(id)
    setOpen(true)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Box width={{ xs: '90%', sm: '90%', md: '50%', lg: '50%', xl: '50%' }}>
          <Dialog
            open={downloading}
            keepMounted
            //onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <Box
              sx={{
                height: '10rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyConent: 'center',
              }}
              width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
            >
              <DialogTitle>{downloadMessage}</DialogTitle>
              <LinearProgressWithLabel value={progress} sx={{ width: '90%' }} />
            </Box>
          </Dialog>
        </Box>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <ViewElearning pageName={'TransferOrder'} open={viewElearning} setOpen={setViewElearning} />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Transferências Entre Filiais</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => deleteMovimentationOrder()}>Sim</Button>
          </DialogActions>
        </Dialog>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl sx={{ width: '100%', mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select label="Selecione a empresa" onChange={fetchData} value={subCompanyId}>
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl sx={{ width: '100%', mt: 2 }}>
              <TextField id="outlined-basic" label="Pedido" disabled variant="outlined" />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControl sx={{ width: '100%', mt: 2 }}>
              <TextField id="outlined-basic" label="Codigo RFID" variant="outlined" />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, my: 2 }}
              gap={2}
            >
              <Button
                variant="contained"
                endIcon={<Add />}
                sx={{ width: { xs: '100%', md: '170px' } }}
              >
                Adiciona
              </Button>
              <Button
                variant="contained"
                endIcon={<Api />}
                sx={{ width: { xs: '100%', md: '170px' } }}
              >
                Enviar dados
              </Button>
              <Button
                variant="contained"
                endIcon={<Delete />}
                sx={{ width: { xs: '100%', md: '170px' } }}
              >
                Limpar
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TextField
            variant="outlined"
            placeholder="Procurar por Ordem"
            type="search"
            onInput={e => setSearch(e.target.value)}
          />
          {searching && <Typography>Buscando...</Typography>}
        </Box>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700, my: 2 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Quantidade</StyledTableCell>
                <StyledTableCell align="left">ID do Item do Produto</StyledTableCell>
                <StyledTableCell align="left">Serial</StyledTableCell>
                <StyledTableCell align="left">Nome da Subcompanhia</StyledTableCell>
                <StyledTableCell align="left">Nome do Produto</StyledTableCell>
                <StyledTableCell align="left">Preço</StyledTableCell>
                <StyledTableCell align="left">SKU</StyledTableCell>
                <StyledTableCell align="left">Código de Barras</StyledTableCell>
                <StyledTableCell align="left">Referência</StyledTableCell>
                <StyledTableCell align="left">Cor</StyledTableCell>
                <StyledTableCell align="left">Tamanho</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left">Valor da Quantidade</StyledTableCell>
                <StyledTableCell align="left">Valor do Serial</StyledTableCell>
                <StyledTableCell align="left">Valor do Nome da Subcompanhia</StyledTableCell>
                <StyledTableCell align="left">Valor do Nome do Produto</StyledTableCell>
                <StyledTableCell align="left">Valor do Preço</StyledTableCell>
                <StyledTableCell align="left">Valor do SKU</StyledTableCell>
                <StyledTableCell align="left">Valor do Código de Barras</StyledTableCell>
                <StyledTableCell align="left">Valor da Referência</StyledTableCell>
                <StyledTableCell align="left">Valor da Cor</StyledTableCell>
                <StyledTableCell align="left">Valor do Tamanho</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default MovimentationType
