import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { useCallback, useContext, useEffect, useState } from 'react'
import { DeleteForever, ExpandMore, Message, ModeEditOutline, Person } from '@mui/icons-material'
import ApexChart from './Components/ColumnChartBasic '
import ColumnChartBasic from './Components/ColumnChartBasic '
import { Context } from '../../../context/AuthContext'
import PermissionComponent from '../../../permissions'
import { TicketEdit } from '../../Edit/TicketEdit'
import { TicketCreate } from '../../Create/TicketCreate'
import { AiFillPlusCircle } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import { getAttendantsAll, getOrders, getTicketsAll } from '../../../services/helpdeskService'
import { deepOrange } from '@mui/material/colors'
import { AttendantsCreate } from '../../Create/AttendantsCreate'
import {
  deleteCompanyService,
  getCompanyService,
  getSegmentService,
} from '../../../services/admServices'
import OrderCreate from '../../Create/OrderCreate'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '8px',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export const Order = () => {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [segmentId, setSegmentId] = useState('')
  const [segment, setSegment] = useState([])
  const [company, setCompanny] = useState([])
  const [companyLimit, setCompanyLimit] = useState(500)
  const [companyOffset, setCompanyOffset] = useState(0)
  const [companyOrder, setCompanyOrder] = useState(false)
  const [alerta, setAlerta] = useState(false)

  const [viewElearning, setViewElearning] = useState(false)
  const [attendants, setAttendants] = useState([])

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [title, setTitle] = useState('')
  const [ticket, setTiket] = useState([])
  const [severity, setSeverity] = useState('warning')
  const [register, setRegister] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const [idEditing, setIdEditing] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [deleting, setDeleting] = useState(false)
  const [name, setName] = useState('')
  const [ticketId, setTicketId] = useState('')
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)
  const [searchR, setSearchR] = useState('')
  const [errorEmpresa, setErrorEmpresa] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteTicket, setDeleteTicket] = useState({ ticketName: '', ticketId: '' })
  const [loading, setLoading] = useState(false)
  const [notSearch, setNotSearch] = useState(false)
  const [filterName, setFilterName] = useState('')
  const [filterCNPJ, setFilterCNPJ] = useState('')
  const [backupData, setBackupData] = useState([])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleClearFilter = () => {
    setFilterName('')
    setFilterCNPJ('')
    setCompanny(backupData)
  }

  const getCompany = useCallback(async () => {
    setLoading(true)
    try {
      //const response = await getCompanyService(token, nameUrl, companyLimit, companyOffset, companyOrder)
      const response = await getOrders(token)
      {
        /*if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setCompanny(response.data.result)
        setBackupData(response.data.result)
      } */
      }
      console.log(response, 'dasdadassd')
      setCompanny(response.data)
      setBackupData(response.data)

      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [companyOrder])

  useEffect(() => {
    getCompany()
  }, [companyOrder])

  const onEditClick = e => {
    e.stopPropagation()
  }

  const handleEditing = id => {
    setTicketId(id)
    setEditing(true)
    setExpanded(true)
  }

  const handleConfirmDelete = id => {
    setTicketId(id)
    setDeleting(true)
  }

  const handleClose = () => {
    setRegister(false)
    setEditing(false)
    setDeleting(false)
  }

  const fetchData = async values => {
    if (!values || values === '') {
      return
    }

    setSubCompanyId(values.target.value)
    setLoading(true)
    try {
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const getSegment = useCallback(async () => {
    try {
      const response = await getSegmentService(token, nameUrl, 100, 0, true)
      setSegment(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }, [])

  const handleGetListTickets = async () => {
    try {
      const response = await getTicketsAll(token)
      console.log(response.data)
      setTiket(response.data)
      const fila = response.data.filter(item => item.status === '1')
      const canceled = response.data.filter(item => item.status === '4')
      const finished = response.data.filter(item => item.status === '5')
      const delay = response.data.filter(item => item.status === '3')
      const progress = response.data.filter(item => item.status === '2')
      const active = response.data.filter(
        item => item.status === '1' || item.status === '2' || item.status === '3'
      )
    } catch (error) {}
  }

  const handleGetListAttendants = async () => {
    try {
      const response = await getAttendantsAll(token)
      console.log(response.data)
      setAttendants(response.data)
      const active = response.data.filter(
        item => item.status === 1 || item.status === 2 || item.status === 3
      )
    } catch (error) {}
  }

  useEffect(() => {
    handleGetListAttendants()
    handleGetListTickets()
  }, [])

  const handleEdit = id => {
    setIdEditing(id)
    setExpanded(true)
    setEditing(true)
    window.scrollTo(0, 0)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    try {
      await deleteCompanyService(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Empresa deletada com sucesso!')

      setAlerta(true)
      setLoading(false)
      getCompany()
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    const handleFilter = () => {
      if (filterName !== '') {
        setNotSearch(false)
        const newData = backupData
        const filter = newData.filter(data =>
          data.name.toLowerCase().includes(filterName.toLowerCase())
        )
        if (filter.length > 0) {
          setCompanny(filter)
        } else {
          setNotSearch(true)
        }
      } else setCompanny(backupData)
    }
    handleFilter()
  }, [filterName])

  useEffect(() => {
    const handleFilter = () => {
      if (filterCNPJ !== '') {
        setNotSearch(false)
        const newData = backupData
        console.log(newData)
        const filter = newData.filter(data => data.cnpj.toString().includes(filterCNPJ))
        if (filter.length > 0) {
          setCompanny(filter)
        } else {
          setNotSearch(true)
        }
      } else setCompanny(backupData)
    }
    handleFilter()
  }, [filterCNPJ])

  return (
    <Box sx={{ mt: 7.5 }}>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          width: 1,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="body1">Pedidos</Typography>
        <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
          <HelpIcon />
        </IconButton>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Accordion expanded={expanded} onClick={() => setExpanded(!expanded)} disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {editing ? (
                <Typography>Editar Empresa</Typography>
              ) : (
                <Typography>Cadastrar Empresa</Typography>
              )}
            </AccordionSummary>
            <AccordionDetails onClick={e => onEditClick(e)}>
              {editing ? (
                <PermissionComponent role="AttendantsEDIT">
                  <TicketEdit
                    setName={setName}
                    setExpanded={setExpanded}
                    id={ticketId}
                    setTitle={setTitle}
                    setSeverity={setSeverity}
                    setRegister={setRegister}
                    setEditing={setEditing}
                    //getAllProducts={getAllProducts}
                  />
                </PermissionComponent>
              ) : (
                <PermissionComponent role="OrderCREATE">
                  <OrderCreate
                    setExpanded={setExpanded}
                    setTitle={setTitle}
                    setSeverity={setSeverity}
                    setRegister={setRegister}
                    handleGetListAttendants={handleGetListAttendants}
                    //getAllProducts={getAllProducts}
                  />
                </PermissionComponent>
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Box width="100%" my={2} height="55px" display="flex" justifyContent="flex-end">
          <PermissionComponent role="OrderCREATE">
            <Button
              sx={{ px: 4, py: 1 }}
              onClick={() => {
                setEditing(false)
                setExpanded(true)
              }}
              variant="contained"
              endIcon={<AiFillPlusCircle />}
            >
              Criar
            </Button>
          </PermissionComponent>
        </Box>
        <Grid item xs={12} sm={5}>
          <InputLabel id="demo-simple-select-label">
            {!notSearch ? 'Filtrar Nome' : 'Empresa não encontrada'}
          </InputLabel>
          <TextField
            variant="outlined"
            value={filterName}
            onChange={values => setFilterName(values.target.value)}
            sx={{ width: '100%' }}
            // name="endDate"
            type="text"
            error={notSearch}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <InputLabel id="demo-simple-select-label">
            {!notSearch ? 'Filtrar CNPJ' : 'CNPJ não encontrada'}
          </InputLabel>

          <TextField
            variant="outlined"
            value={filterCNPJ}
            onChange={values => setFilterCNPJ(values.target.value)}
            sx={{ width: '100%' }}
            // name="endDate"
            type="text"
            error={notSearch}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Box sx={{ display: 'flex', alignItems: 'end', height: '100%', width: '100%' }}>
            <Button variant="contained" onClick={() => handleClearFilter()} sx={{ width: '100%' }}>
              Limpar filtros
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell> </StyledTableCell>
                  <StyledTableCell align="left">Nome</StyledTableCell>
                  <StyledTableCell align="left">Segmento</StyledTableCell>
                  <StyledTableCell align="left">CNPJ</StyledTableCell>
                  <StyledTableCell align="left">Status</StyledTableCell>
                  <StyledTableCell align="left">Ativo</StyledTableCell>
                  <StyledTableCell align="left">Criado em</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {company
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'left',
                            justifyContent: 'space-between',
                            width: '80px',
                          }}
                        >
                          <PermissionComponent role="CompanyDELETE">
                            <Tooltip title="Apagar" arrow>
                              <IconButton
                                onClick={() => deleteAlert(row.id)}
                                sx={{ cursor: 'pointer' }}
                              >
                                <DeleteForever />
                              </IconButton>
                            </Tooltip>
                          </PermissionComponent>
                          <PermissionComponent role="CompanyEDIT">
                            <Tooltip title="Editar" arrow>
                              <IconButton
                                onClick={() => handleEdit(row.id)}
                                sx={{ cursor: 'pointer' }}
                              >
                                <ModeEditOutline />
                              </IconButton>
                            </Tooltip>
                          </PermissionComponent>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.company}</StyledTableCell>
                      <StyledTableCell align="left">{row.name}</StyledTableCell>
                      <StyledTableCell align="left">{row.cnpj}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row.name ? 'Concluído' : 'Envio parcial'}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.name ? 'Sim' : 'Não'}</StyledTableCell>
                      <StyledTableCell align="left">
                        {new Date(row.createDate).toLocaleString()}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={company.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  )
}
