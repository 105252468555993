import HelpIcon from '@mui/icons-material/Help'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import { Context } from '../../../context/AuthContext'
import { handleMovimentationLabel } from '../../../services/reportServices'

//icones react-icons
import { AiOutlineFileExcel } from 'react-icons/ai'
import '../../../assets/global.css'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'
import moment from 'moment'

const ReportEtiqueta = () => {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const [loading, setLoading] = useState(false)
  const [dissassociationData, setDissassociationData] = useState([])
  const [table, setTable] = useState(false)
  const [progressCount, setProgressCount] = useState(0)
  const [downloadMessageCount, setDownloadMessageCount] = useState('Pronto')
  const [downloadingCount, setDownloadingCount] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [viewElearning, setViewElearning] = useState(false)
  const [filterProduct, setFilterProduct] = useState('')
  const [backupData, setBackupData] = useState([])
  const [filteringProduct, setFilteringProduct] = useState(false)
  const [notSearch, setNotSearch] = useState(false)
  const currentMonth = moment().startOf('month')
  const firstDay = currentMonth.format('YYYY-MM-DD')
  const lastDay = currentMonth.endOf('month').format('YYYY-MM-DD')
  const [startDate, setStartDate] = useState(firstDay)
  const [finishDate, setFinishDate] = useState(lastDay)

  const getFirstDayOfYear = () => {
    const firstDay = moment().startOf('year')
    return firstDay.format('YYYY-MM-DD')
  }

  const firstDayOfYear = getFirstDayOfYear()

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const handleGetReport = async () => {
    setLoading(true)
    setTable(false)
    try {
      const response = await handleMovimentationLabel(
        token,
        nameUrl,
        subCompanyId,
        startDate,
        finishDate
      )
      if (response.data.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
        setDissassociationData([])
      } else {
        setBackupData(response.data)
        setDissassociationData(response.data)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const downloadDataCount = async () => {
    setDownloadMessageCount('Inicializando')
    setDownloadingCount(true)
    // let result = []
    // const data = {
    //   subCompanyId: subCompanyId,
    //   startDate: startDate,
    //   finishDate: finishDate,
    //   limit: 10,
    //   offset: 1,
    //   order: orderSelect,
    // }

    // const resp = await handleDissassociation(token, nameUrl, data)
    finishDownloadCount()
  }

  const finishDownloadCount = async () => {
    let result = []

    for (let i = 0; i <= 100; i++) {
      setDownloadMessageCount(`Baixando ${i} de ${100}...`)
      setProgressCount((i / dissassociationData.length) * 100)
      // const data = {
      //   subCompanyId: subCompanyId,
      //   startDate: startDate,
      //   finishDate: finishDate,
      //   limit: 10,
      //   offset: i,
      //   order: orderSelect,
      // }
    }
    const resp = await handleMovimentationLabel(token, nameUrl, subCompanyId)
    result = result.concat(resp.data)
    setDownloadMessageCount(`Seu arquivo está pronto.`)
    setDownloadingCount(false)
    const relatorioFinalDissassociacao = []

    result.map(item => {
      relatorioFinalDissassociacao.push({
        'Data Requisição': item.requestDate ? new Date(item.requestDate).toLocaleString() : '',
        Descrição: item.description,
        Lote: item.lote,
        'Posição de baixa': item.picking,
        Quantidade: item.quantitys,
        ' Data Validade': new Date(item.dataExpiration).toLocaleString(),
        Referência: item.referencia,
        'Número Palete': item.numberPallet ? item.numberPallet : '',
        Depósito: item.depositName,
        Serial: item.serial,
        'Ordem Bihands': item.movimentationOrderNumberBihands,
        'Ordem Outro Sistema': item.movimentationOrderNumberOtherSystem,
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinalDissassociacao)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório de etiqueta impressa')
    XLSX.writeFile(workbook, `Relatório de etiqueta impressa.xlsx`)
    setProgressCount(0)
  }

  const handleClose = () => {
    setAlerta(false)
  }

  useEffect(() => {
    const handleFilter = () => {
      if (filterProduct !== '') {
        setNotSearch(false)
        setFilteringProduct(true)
        const newData = backupData
        const filter = newData.filter(data =>
          data.description.toLowerCase().includes(filterProduct.toLowerCase())
        )
        setFilteringProduct(false)
        if (filter.length > 0) {
          setDissassociationData(filter)
          setPage(0)
        } else {
          setNotSearch(true)
          setDissassociationData(backupData)
          setPage(0)
        }
      } else {
        setDissassociationData(backupData)
        setPage(0)
      }
    }
    setDissassociationData([])
    setTimeout(() => {
      handleFilter()
    }, 10)
  }, [filterProduct])

  const handleClearFilter = () => {
    setFilterProduct('')
    setDissassociationData(backupData)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={downloadingCount}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessageCount}</DialogTitle>
            <LinearProgressWithLabel value={progressCount} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <ViewElearning
          pageName={'ReportDissassociation'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Relatório de Etiqueta Impressa</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box mt={1}>
              <SelectSubCompany
                value={subCompanyId}
                onChange={e => {
                  setSubCompanyId(e.target.value)
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel style={{ color: '#fff' }} id="demo-simple-select-label">
              Data de criação
            </InputLabel>
            <TextField
              style={{ backgroundColor: '#fff', width: '100%' }}
              variant="outlined"
              value={startDate}
              onChange={values => {
                setStartDate(values.target.value)
              }}
              sx={{ width: '100%' }}
              name="createDate"
              type="date"
              disabled={loading}
              inputProps={{
                min: firstDayOfYear,
                max: finishDate,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel style={{ color: '#fff' }} id="demo-simple-select-label">
              Data final
            </InputLabel>
            <TextField
              style={{ backgroundColor: '#fff', width: '100%' }}
              variant="outlined"
              value={finishDate}
              disabled={loading}
              onChange={values => setFinishDate(values.target.value)}
              sx={{ width: '100%' }}
              name="endDate"
              type="date"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              disabled={subCompanyId === ''}
              variant="contained"
              onClick={() => handleGetReport()}
              size="large"
              sx={{ mt: 4 }}
              fullWidth
            >
              Pesquisar
            </Button>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            mb: 2,
          }}
        >
          <Typography variant="h6">Resultados</Typography>
          {dissassociationData.length > 0 && (
            <Button
              variant="contained"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: ' space-between',
                fontSize: '.9em',
                width: '6.5rem',
              }}
              onClick={downloadDataCount}
            >
              Excel <AiOutlineFileExcel size="2em" />
            </Button>
          )}
        </Box>

        <Box
          sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box width={{ xs: '100%', sm: '100%', md: '35%', lg: '35%', xl: '35%' }} sx={{ mt: 2 }}>
            <TextField
              variant="outlined"
              label={!notSearch ? 'Filtrar Produto' : 'Produto Não encontrado'}
              value={filterProduct}
              onChange={values => setFilterProduct(values.target.value)}
              sx={{ width: '100%' }}
              error={notSearch}
              type="text"
            />
          </Box>
          <Button variant="contained" onClick={() => handleClearFilter()} sx={{ mt: 2 }}>
            Limpar filtros
          </Button>
        </Box>
        <TableContainer component={Paper} sx={{ mt: '2rem' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {' '}
                {/*data entrada - descrição - lote - picking(Posição de baixa) - quantidade - validade */}
                <StyledTableCell align="left">Data Requisição</StyledTableCell>
                <StyledTableCell align="left">Descrição</StyledTableCell>
                <StyledTableCell align="left">Lote</StyledTableCell>
                <StyledTableCell align="left">Posição de baixa</StyledTableCell>
                <StyledTableCell align="left">Quantidade</StyledTableCell>
                <StyledTableCell align="left">Data Validade</StyledTableCell>
                <StyledTableCell align="left">Referência</StyledTableCell>
                <StyledTableCell align="left">Número Palete</StyledTableCell>
                <StyledTableCell align="left">Depósito</StyledTableCell>
                <StyledTableCell align="left">Serial</StyledTableCell>
                <StyledTableCell align="left">Ordem Bihands</StyledTableCell>
                <StyledTableCell align="left">Ordem Outro Sistema</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dissassociationData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left">
                      {row.requestDate ? new Date(row.requestDate).toLocaleString() : ''}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.description}</StyledTableCell>
                    <StyledTableCell align="left">{row.lote}</StyledTableCell>
                    <StyledTableCell align="left">{row.picking}</StyledTableCell>
                    <StyledTableCell align="left">{row.quantitys}</StyledTableCell>
                    <StyledTableCell align="left">
                      {new Date(row.dataExpiration).toLocaleString()}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.referencia}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.numberPallet ? row.numberPallet : ''}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.depositName}</StyledTableCell>
                    <StyledTableCell align="left">{row.serial}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.movimentationOrderNumberBihands}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.movimentationOrderNumberOtherSystem}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={dissassociationData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ReportEtiqueta
