import React, { useContext } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { setPermission } from '../../../permissions/userPermissions'
import { TagLogisticCreate, TagLogistic } from '../../../const/RoleConstants'
import { Context } from '../../../context/AuthContext'
import HelpIcon from '@mui/icons-material/Help'
import { getPrinterZplService } from '../../../services/configServices'
import {
  Box,
  Typography,
  TableCell,
  tableCellClasses,
  TableRow,
  styled,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
} from '@mui/material'
import ViewElearning from '../../ViewElearning'
import TagLogisticsCreate from '../../Create/TagLogisticsCreate'
import PermissionComponent from '../../../permissions'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const TagLogistics = () => {
  const { userRoles, nameUrl, subCompany } = useContext(Context)
  //constantes crud
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const [expanded, setExpanded] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'

  const token = localStorage.getItem('token')
  const [printerZpl, setPrinterZpl] = useState([])
  const [pagination, setPagination] = useState({})
  const [printerZplLimit, setPrinterZplLimit] = useState(10)
  const [printerZplOffset, setPrinterZplOffset] = useState(0)
  const [printerZplOrder, setPrinterZplOrder] = useState(false)
  const [loading, setLoading] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)
  const [permissionTagLogisticView, setPermissionTagLogisticView] = useState(false)
  const [permissionTagLogisticCreate, setPermissionTagLogisticCreate] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'rgb(89, 164, 214)',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const getPermissions = useCallback(async () => {
    setPermissionTagLogisticView(setPermission(userRoles, TagLogistic))
    setPermissionTagLogisticCreate(setPermission(userRoles, TagLogisticCreate))
  }, [])

  const getPrinterZpl = async () => {
    setLoading(true)
    try {
      const response = await getPrinterZplService(
        token,
        nameUrl,
        subCompanyId,
        printerZplLimit,
        printerZplOffset,
        printerZplOrder,
        ''
      )
      setPrinterZpl(response.data)
      setPagination(response.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (subCompanyId) {
      getPrinterZpl()
    }
  }, [printerZplOrder, subCompanyId])

  useEffect(() => {
    getPermissions()
  }, [])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  return (
    <>
      {permissionTagLogisticView && (
        <Box sx={{ mt: 7.5 }}>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={alerta}
            onClose={handleClose}
            key={vertical + horizontal}
          >
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
              {title}
            </Alert>
          </Snackbar>
          <ViewElearning pageName={'PrinterZpl'} open={viewElearning} setOpen={setViewElearning} />
          <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h5">Etiqueta Logística</Typography>
            <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
              <HelpIcon />
            </IconButton>
          </Box>
          <Box>
            <Box>
              <SelectSubCompany
                value={subCompanyId}
                onChange={e => {
                  setSubCompanyId(e.target.value)
                }}
              />
            </Box>
            <PermissionComponent role="TagLogisticsCreate">
              <TagLogisticsCreate
                setExpanded={setExpanded}
                setAlerta={setAlerta}
                setSeverity={setSeverity}
                setTitle={setTitle}
                handleFetchData={printerZpl}
              />
            </PermissionComponent>
          </Box>
          <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}
    </>
  )
}

export default TagLogistics
