import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { getExtractCountService, getOnlyOrder } from '../../../services/reportServices'
import { createMovimentationOrderService } from '../../../services/productService'
import HelpIcon from '@mui/icons-material/Help'
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import ViewElearning from '../../ViewElearning'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const ReportExtractCountStock = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const [linkDownload, setLinkDownload] = useState('')
  const [linkModal, setLinkModal] = useState(false)

  const token = localStorage.getItem('token')
  const [pagination, setPagination] = useState({})
  const [productAssociationOrder, setProductAssociationOrder] = useState(false)
  const [orders, setOrders] = useState()
  const [loading, setLoading] = useState(false)
  const [orderSelect, setOrderSelect] = useState()
  const [viewElearning, setViewElearning] = useState(false)

  const getExtractCount = async () => {
    setLoading(true)
    const extract = {
      subCompanyId: subCompanyId,
      //startDate: createDateTime,
      movimentationOrderId: orderSelect,
      //finishDate: endDateTime,
      formatAchieve: 'txt',
    }

    try {
      const response = await getExtractCountService(token, nameUrl, extract)
      setLoading(false)
      setLinkModal(true)
      setLinkDownload(response.data.urL_Download)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const getOrders = async e => {
    setSubCompanyId(e.target.value)
    //criaOrdem()
    try {
      const response = await getOnlyOrder(token, nameUrl, e.target.value)
      setOrders(response.data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {}, [productAssociationOrder])

  return (
    <>
      <Box sx={{ mt: 7 }}>
        <Dialog open={linkModal} keepMounted aria-describedby="alert-dialog-slide-description">
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>Link para o download do Arquivo TXT</DialogTitle>
            <DialogActions
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                width: '100%',
              }}
            >
              <Button variant="contained" onClick={() => setLinkModal(false)}>
                Cancelar
              </Button>
              <Button
                variant="contained"
                onClick={() => setLinkModal(false)}
                target="_blank"
                rel="noreferrer"
                download="Extract"
                href={linkDownload}
                autoFocus
              >
                Baixar
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
        <ViewElearning
          pageName={'ReportExtractCountStock'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5" sx={{ mb: 5 }}>
            Contagem em TXT
          </Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon onClick={() => setViewElearning(true)} />
          </IconButton>
        </Box>
        <Box
          sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
            <SelectSubCompany
              value={subCompanyId}
              onChange={e => {
                setSubCompanyId(e.target.value)
              }}
            />
          </Box>
          {orders && (
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Pedido</InputLabel>
              <Select
                label="Pedido"
                value={orderSelect}
                onChange={values => {
                  setOrderSelect(values.target.value)
                }}
                sx={{ width: '100%' }}
              >
                {orders.map(item => {
                  return (
                    <MenuItem value={item.movimentationOrderId}>
                      {item.movimentationOrderNumberOtherSystem}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
          )}
        </Box>
        <Button variant="contained" onClick={getExtractCount}>
          Pesquisar
        </Button>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ReportExtractCountStock
