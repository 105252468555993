import { useState, useContext } from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Context } from '../../../context/AuthContext'
import Alert from '@mui/material/Alert'
import OrdemDetalhada from '../../../assets/OrdemProducaoDetalhadaReportDesign.xlsx'
import { uploadConference } from '../../../services/pickToLightServices'

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import ViewElearning from '../../ViewElearning'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const UploadConferenceParts = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const [file, setFile] = useState(null)
  const token = localStorage.getItem('token')
  const [severity, setSeverity] = useState('warning')
  const [loading, setLoading] = useState(false)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const vertical = 'top'
  const horizontal = 'center'
  let formData = new FormData()
  const [viewElearning, setViewElearning] = useState(false)

  const sendFile = async () => {
    setLoading(true)
    formData.append('file', file)
    formData.append('subCompanyId', subCompanyId)
    try {
      await uploadConference(token, nameUrl, formData)
      setSeverity('success')
      setLoading(false)
    } catch (error) {
      setSeverity('error')
      setLoading(false)
    }
  }

  const handleSelectFile = e => {
    const types = ['xls', 'xlsx', 'ods']
    const inputType = e.target.files[0].name.split('.').pop()
    const validation = types.filter(type => type === inputType)
    if (validation.length > 0) {
      setFile(e.target.files[0])
    } else {
      setSeverity('error')
    }
  }

  const handleClose = () => {
    setSeverity()
  }
  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={severity === 'error'}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Verifique o formato do arquivo, a extensão deve ser xlsx ou xls
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={severity === 'success'}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Arquivo enviado com sucesso
          </Alert>
        </Snackbar>
        <ViewElearning
          pageName={'UploadConferenceParts'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Upload de conferência de peças</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon onClick={() => setViewElearning(true)} />
          </IconButton>
        </Box>
        <Box sx={{ width: '100%', mt: 2 }}>
          <SelectSubCompany
            value={subCompanyId}
            onChange={e => {
              setSubCompanyId(e.target.value)
            }}
          />
        </Box>
        <Box
          sx={{ justifyContent: 'space-evenly', w: 1, minWidth: 1 }}
          display={{ xs: 'column', sm: 'column', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box
            sx={{
              alignItems: 'center',
              textAlign: 'center',
              mt: 2,
              border: '1px solid black',
              width: { xs: 1, md: 0.3 },
              minWidth: { xs: 1, md: 0.3 },
              p: 2,
            }}
          >
            <InputLabel>Baixe aqui o arquivo exemplo</InputLabel>
            <a className="dropzone" target="_blank" rel="noreferrer" href={OrdemDetalhada} download>
              <FileDownloadIcon sx={{ fontSize: 100 }} />
            </a>
          </Box>

          <Box
            sx={{ mt: 2, border: { md: '1px solid black' }, p: 2 }}
            width={{ xs: '100%', sm: '100%', md: '65%', lg: '65%', xl: '65%' }}
          >
            {' '}
            <InputLabel>Insira aqui o documento para enviar</InputLabel>
            <TextField
              sx={{ width: '100%', mt: 1 }}
              id="standard-basic"
              //label="Nome"
              variant="standard"
              //value={name}
              onChange={handleSelectFile}
              type="file"
              accept=".xls, .xlsx, .ods"
            />
            <Box
              sx={{
                mt: 4,
                alignItems: 'center',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>Apenas arquivos .XLS ou .XLSX</Typography>
              <Button onClick={() => sendFile()} variant="contained">
                Enviar
              </Button>
            </Box>
          </Box>
        </Box>
        {/* <Box sx={{ mt: 4, alignItems: 'center', textAlign: 'center' }}>
          <Button onClick={() => sendFile()} variant="contained">
            Enviar
          </Button>
        </Box> */}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default UploadConferenceParts
