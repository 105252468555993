import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { colorTableDefault } from '../../TemplateDefault'
import Alert from '@mui/material/Alert'
import HelpIcon from '@mui/icons-material/Help'
import { Add, DeleteForever, ExpandMore, Replay } from '@mui/icons-material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Context } from '../../../context/AuthContext'
import {
  createBulkOfRecipeMachine,
  createRecipeMachine,
  getAllMachines,
} from '../../../services/recipeMachine'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const ChargeRecipeMachine = () => {
  const { subCompany, company, nameUrl } = useContext(Context)
  const token = localStorage.getItem('token')
  const [recipe, setRecipe] = useState('')
  const [statusInfo, setStatusInfo] = useState(false)
  const [progress, setProgress] = useState(0)
  const [totaProdsSelectedCount, setTotaProdsSelectedCount] = useState(0)
  const [numberItem, setNumberItem] = useState([])
  const [sentProdsCount, setSentProdsCount] = useState(0)
  const [open, setOpen] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [titleDialog, setTitleDialog] = useState('')
  const [dialogAction, setDialogAction] = useState(null)
  const vertical = 'top'
  const horizontal = 'center'
  const [severity, setSeverity] = useState('error')
  const [title, setTitle] = useState('')
  const [viewElearning, setViewElearning] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [edit, setEdit] = useState(false)
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [statusTable, setStatusTable] = useState(false)
  const [listOrderExit, setListOrderExist] = useState([])
  const [failedProds, setFailedProds] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedStatus, setSelectedStatus] = useState('')
  const [statusMessage, setStatusMessage] = useState({})
  const [page, setPage] = useState(0)
  const [loadingMap, setLoadingMap] = useState({})
  const [deleteId, setDeleteId] = useState('')
  const [itemMachine, setItemMachine] = useState([])
  const [itemProd, setItensProd] = useState([])
  const [status, setStatus] = useState('Pronto')
  const [currentBatchIndex, setCurrentBatchIndex] = useState(0)

  const [singleProd, setSingleProd] = useState({
    sequenceReceipt: '',
    description: '',
    create: '',
    internalParNumber: '',
    subCompanyId: '',
    isDelete: true,
  })

  const handleClose = () => {
    setOpen(false)
    setAlerta(false)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
    setPage(0)
    setSelectAll(event.target.value === 'All')
  }
  const fetchMachines = async () => {
    try {
      const listOfMachines = await getAllMachines(token, nameUrl)
      setItemMachine(listOfMachines.data)
    } catch (error) {
      console.error('Error fetching machines:', error)
    }
  }

  const handleDeleteItem = () => {
    const updatedItems = itemProd.filter(item => item.sequenceReceipt !== deleteId)
    setSeverity('success')
    setTitle('Item apagado com sucesso!')
    setAlerta(true)
    setItensProd(updatedItems)
    setOpen(false)
  }

  const handleActionList = action => {
    if (selectedItems.length === 0) {
      setSeverity('error')
      setTitle('Selecione um item')
      setAlerta(true)
    } else {
      setOpen(true)
      setTitleDialog('Tem certeza que enviar esses itens?')
      setDialogAction(action)
    }
  }

  const handleSelectAll = event => {
    const isChecked = event.target.checked

    setSelectAll(isChecked)
    setSelectedItems(prevSelectedItems => {
      if (!selectAll) {
        return filter.map((item, index) => item.sequenceReceipt)
      } else {
        return []
      }
    })
    setRowsPerPage(isChecked ? filter.length + 1 : 10)
  }

  const handleItemSelect = itemId => {
    setSelectedItems(prevSelectedItems => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter(id => id !== itemId)
      } else {
        return [...prevSelectedItems, itemId]
      }
    })
  }

  const deleteDialog = (machineId, del) => {
    setDeleteId(machineId)
    setOpen(true)
    setTitleDialog('Tem certeza que deseja excluir este item?')
    setDialogAction(del)
  }

  const addToList = async data => {
    console.log(data, 'data add list')
    const { id, sequenceReceipt, description, create, internalParNumber, subCompanyId, isDelete } =
      data

    const orderExists = itemMachine.some(order => order.sequenceReceipt === sequenceReceipt)

    const repeat = itemMachine.find(item => item.id == id)

    if (repeat) {
      const updateMachine = {
        ...repeat,
        sequenceReceipt,
        internalParNumber,
        description,
        subCompanyId,
        isDelete,
      }

      const updatedIndex = itemMachine.findIndex(item => item.id === id)

      if (updatedIndex !== -1) {
        const updatedItemClient = [...itemMachine]
        updatedItemClient[updatedIndex] = updateMachine
        setItensProd(updatedItemClient)
      }

      setStatus('Máquina atualizada')
      resetFields()
      setExpanded(false)
    } else {
      if (!sequenceReceipt) {
        setStatus('Código inválido')
        return
      }

      const newMachine = {
        sequenceReceipt,
        description,
        internalParNumber,
        create,
        subCompanyId,
        isDelete,
      }

      setItensProd(curr => [...curr, newMachine])
      setStatus('Cliente adicionado')
      resetFields()
      setExpanded(false)
    }
  }

  console.log(selectedItems, 'aqui')
  console.log(itemProd, 'prod')

  const handleSaveMachinesSelected = async () => {
    setStatusInfo(true)
    setStatusTable(true)
    setSentProdsCount(0)
    setFailedProds([])
    setProgress(0)

    const selectedMachines = itemProd.filter(prod => selectedItems.includes(prod.idProductPartner))
    setTotaProdsSelectedCount(selectedMachines.length)
    console.log(selectedMachines.length)
    for (const selectMachine of selectedMachines) {
      try {
        const updatedFilter = filter.map(row => {
          if (row.sequenceReceipt === selectMachine.sequenceReceipt) {
            return {
              ...row,
              loading: true,
            }
          }
          return row
        })

        setFilter(updatedFilter)
        await Promise.resolve()
        await new Promise(resolve => setTimeout(resolve, 1000))
        const response = await createBulkOfRecipeMachine(token, nameUrl, [selectedMachines])

        const updatedFilterWithStatus = updatedFilter.map(row => {
          if (row.idProductPartner === selectMachine.sequenceReceipt) {
            return {
              ...row,
              loading: false,
              status: response.data.message,
            }
          }
          return row
        })

        setFilter(updatedFilterWithStatus)
        setSeverity('success')
        await new Promise(resolve => setTimeout(resolve, 1000))

        const updatedFilterWithoutSuccess = updatedFilterWithStatus.filter(
          row => row.sequenceReceipt !== selectMachine.sequenceReceipt
        )

        setSentProdsCount(prevSentProdsCount => prevSentProdsCount + 1)

        setItensProd(prevItemProd =>
          prevItemProd.filter(item => item.sequenceReceipt !== selectMachine.sequenceReceipt)
        )

        setFilter(updatedFilterWithoutSuccess)
      } catch (error) {
        const updatedFilter = filter.map(row => {
          if (row.sequenceReceipt === selectMachine.sequenceReceipt) {
            return {
              ...row,
              loading: false,
              status: 'Falha ao enviar, tente novamente!',
            }
          }
          return row
        })

        setSeverity('error')
        setFilter(updatedFilter)
        setFailedProds(prevSentProdsCount => prevSentProdsCount + 1)
      }
    }

    setStatusTable(false)
    setSelectedItems([])
    setStatusInfo(false)
  }

  const handleSaveMachine = async () => {
    setStatusInfo(true)
    setStatusTable(true)
    setSentProdsCount(0)
    setFailedProds([])
    setProgress(0)

    const totalItems = itemProd.length
    const batchSize = 1000
    setNumberItem(totalItems)
    const numBatches = Math.ceil(totalItems / batchSize)
    let updatedFilter = [...itemProd]

    for (let batchIndex = 0; batchIndex < numBatches; batchIndex++) {
      try {
        await new Promise(resolve => setTimeout(resolve, 1000))
        setCurrentBatchIndex(batchIndex + 1)
        const batchStart = batchIndex * batchSize
        const batchEnd = Math.min((batchIndex + 1) * batchSize, totalItems)
        const batchMachines = itemProd.slice(batchStart, batchEnd)

        const newProgress = ((batchIndex + 1) / numBatches) * 100
        setProgress(newProgress)
        setFilter(prevFilter => {
          return prevFilter.map(row => {
            if (batchMachines.some(product => product.sequenceReceipt === row.sequenceReceipt)) {
              return {
                ...row,
                loading: true,
              }
            }
            return row
          })
        })

        await new Promise(resolve => setTimeout(resolve, 1000))
        try {
          const response = await createBulkOfRecipeMachine(token, nameUrl, batchMachines)
          setFilter(prevFilter => {
            return prevFilter.map(row => {
              if (batchMachines.some(machine => machine.sequenceReceipt === row.sequenceReceipt)) {
                return {
                  ...row,
                  loading: false,
                  //status: response.data.message,
                }
              }
              return row
            })
          })

          setSeverity('success')
          await new Promise(resolve => setTimeout(resolve, 1000))

          updatedFilter = updatedFilter.filter(
            row => !batchMachines.some(machine => machine.sequenceReceipt === row.sequenceReceipt)
          )
          setFilter(updatedFilter)
          setSentProdsCount(prevSentProdsCount => prevSentProdsCount + batchMachines.length)
        } catch (error) {
          setFilter(prevFilter => {
            return prevFilter.map(row => {
              if (batchMachines.some(product => product.sequenceReceipt === row.sequenceReceipt)) {
                return {
                  ...row,
                  loading: false,
                  status: 'Falha ao enviar, tente novamente!',
                }
              }
              return row
            })
          })
          setSeverity('error')
          setFilter(updatedFilter)
          setFailedProds(prevSentProdsCount => prevSentProdsCount + batchMachines.length)
        }
      } catch (error) {
        setFilter(prevFilter => {
          return prevFilter.map(row => {
            if (itemProd.some(product => product.idProductPartner === row.idProductPartner)) {
              return {
                ...row,
                loading: false,
                status: 'Falha ao enviar, tente novamente!',
              }
            }
            return row
          })
        })
        setSeverity('error')
        setFilter(updatedFilter)
        setFailedProds(prevSentProdsCount => prevSentProdsCount + itemProd.length)
      }
    }

    setStatusTable(false)
    setStatusInfo(false)
  }

  const handleSaveUnity = () => {}

  const resetFields = () => {
    setEdit(false)
    setSingleProd({
      sequenceReceipt: '',
      description: '',
      internalParNumber: '',
      subCompanyId: '',
    })
  }

  const filterRow = () => {
    const filtered = itemProd.filter(
      row =>
        row.sequenceReceipt.toLowerCase().includes(search.toLowerCase()) ||
        row.description.toLowerCase().includes(search.toLowerCase()) ||
        row.internalParNumber.toLowerCase().includes(search.toLowerCase())
    )
    setFilter(filtered)
  }

  useEffect(() => {
    filterRow()
  }, [search, itemProd])

  return (
    <Box sx={{ mt: 7.5 }}>
      {statusInfo && (
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={statusInfo}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            position="relative"
          >
            <CircularProgress value={progress} sx={{ color: colorTableDefault }} size={60} />
            <Box
              sx={{
                position: 'absolute',
                top: '26%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <Typography>{Math.round(progress)}%</Typography>
            </Box>
            <Box textAlign="center" p={2}>
              {[].length > 0 ? (
                <Typography>
                  {totaProdsSelectedCount.toLocaleString()} de {Math.floor(sentProdsCount)} Produtos
                  Enviadas...
                </Typography>
              ) : (
                <Typography>
                  {numberItem.toLocaleString()} de {sentProdsCount.toLocaleString()} Produtos
                  Enviadas...
                </Typography>
              )}
            </Box>
          </Box>
        </Backdrop>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{ textAlign: 'center' }}
      >
        <DialogTitle>{titleDialog}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            onClick={() => {
              handleClose()
              if (dialogAction === 'delete') {
                handleDeleteItem()
              } else if (dialogAction === 'save') {
                handleSaveMachinesSelected()
              }
              setDialogAction(null)
            }}
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={alerta}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {title}
        </Alert>
      </Snackbar>

      <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">Carga de receita Maquina</Typography>
        <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
          <HelpIcon />
        </IconButton>
      </Box>
      <Accordion expanded={expanded} sx={{ mt: 2 }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => setExpanded(!expanded)}
        >
          <Typography variant="body1">
            {edit ? 'Editar receita de maquina' : 'Cadastrar receita de maquina'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              width: 1,
              mt: 2,
              display: 'flex',
              gap: 2,
              border: '1px solid black',
              borderRadius: 2,
              p: 2,
              flexDirection: 'column',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={singleProd.sequenceReceipt}
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    label="Codigo da receita de maquina"
                    onChange={e =>
                      setSingleProd({ ...singleProd, sequenceReceipt: e.target.value })
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={singleProd.description}
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    label="Descrição"
                    onChange={e => setSingleProd({ ...singleProd, description: e.target.value })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl sx={{ width: 1 }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={singleProd.internalParNumber}
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    label="Número interno"
                    onChange={e =>
                      setSingleProd({ ...singleProd, internalParNumber: e.target.value })
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl sx={{ width: 1 }}>
                  {/* <TextField
                    sx={{ width: '100%' }}
                    value={''}
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    label="Preço"
                    // onChange={e => {
                    //   setSingleProd({ ...singleProd, price: e.target.value })
                    // }}
                  /> */}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
              >
                <Button variant="outlined" onClick={() => addToList(singleProd)}>
                  {edit ? 'Atualizar Máquina' : 'Salvar'}
                </Button>
                {edit && (
                  <Button
                    variant="contained"
                    sx={{ ml: 1, display: 'flex', alignItems: 'center' }}
                    onClick={() => {
                      resetFields()
                    }}
                  >
                    Adicionar nova máquina <Add sx={{ ml: 1 }} />
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Box
        sx={{ justifyContent: 'space-between', width: 1, minWidth: 1 }}
        display={{ xs: 'column', sm: 'column', md: 'flex', lg: 'flex', xl: 'flex' }}
      >
        <Box
          sx={{
            alignItems: 'center',
            textAlign: 'center',
            mt: 2,
            border: '1px solid black',
            width: { xs: 1, md: 0.34 },
            minWidth: { xs: 1, md: 0.3 },
            p: 2,
          }}
        >
          <a
            className="dropzone"
            target="_blank"
            rel="noreferrer"
            href="https://file.bihands.com/template/UploadAssociationProduct.xlsx"
            download
          >
            <FileDownloadIcon sx={{ fontSize: 100 }} />
          </a>
          <InputLabel>Faça o download do exemplo de carga</InputLabel>
        </Box>
        <Box
          sx={{
            mt: 2,
            border: { md: '1px solid black' },
            p: 2,
          }}
          width={{ xs: '100%', sm: '100%', md: '65%', lg: '65%', xl: '65%' }}
        >
          <InputLabel>Insira aqui o documento para enviar</InputLabel>
          <TextField
            sx={{ width: '100%', mt: 1 }}
            id="standard-basic"
            variant="standard"
            // value={valueFile}
            // onChange={handleSelectFile}
            // ref={ref}
            type="file"
            accept=".xls, .xlsx, .ods"
          />
          <Box
            sx={{
              mt: 4,
              alignItems: 'center',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography>Apenas arquivos .XLS ou .XLSX</Typography>
          </Box>
        </Box>
      </Box>

      <Box>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  sx={{ width: '100%', mb: 1 }}
                  id="standard-basic"
                  variant="outlined"
                  label="Procurar"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  type="text"
                />
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="space-between" alignItems="end">
              <Box>
                <Typography variant="body" sx={{ marginBottom: 2, mr: 0 }}>
                  {filter.length <= 1
                    ? `${filter.length} Resultado`
                    : `${filter.length} Resultados`}
                </Typography>
                {selectedItems.length > 0 && (
                  <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                    {selectedItems.length <= 1
                      ? `${selectedItems.length} Item Selecionado`
                      : `${selectedItems.length} Itens Selecionados`}
                  </Typography>
                )}
                {statusTable && (
                  <>
                    <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                      {listOrderExit.length > 0 && `${listOrderExit.length} ordens existentes`}
                    </Typography>
                  </>
                )}
                {statusTable && (
                  <>
                    <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                      {sentProdsCount <= 1
                        ? `${sentProdsCount} Itens Enviado`
                        : `${sentProdsCount} Itens Enviados`}
                    </Typography>
                    <Typography variant="body" sx={{ marginBottom: 2, ml: 2 }}>
                      {failedProds.length > 1
                        ? `${failedProds.length} Itens não enviados`
                        : `${failedProds.length} Iten não enviados`}
                    </Typography>
                  </>
                )}
              </Box>
              {selectedItems.length > 0 ? (
                <Button
                  variant="contained"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: ' space-between',
                    fontSize: '.9em',
                  }}
                  onClick={() => handleActionList('save')}
                >
                  Enviar Selecionados
                </Button>
              ) : (
                <Button
                  variant="contained"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: ' space-between',
                    fontSize: '.9em',
                  }}
                  onClick={() => handleSaveMachine()}
                >
                  Enviar Lista
                </Button>
              )}
            </Box>
          </Box>

          <Table sx={{ minWidth: 700, mt: 1 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Tooltip title="Selecionar Todos" arrow>
                      <span>
                        <Checkbox checked={selectAll} onChange={handleSelectAll} color="primary" />
                      </span>
                    </Tooltip>
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="center">Codigo da receita</StyledTableCell>
                <StyledTableCell align="center">Descrição</StyledTableCell>
                <StyledTableCell align="center">Número interno</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>

                <StyledTableCell align="center" width="10%">
                  Ação
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filter
                .filter(
                  row =>
                    selectedStatus === '' || statusMessage[row.sequenceReceipt] === selectedStatus
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center">
                      <Checkbox
                        checked={selectedItems.includes(row.sequenceReceipt)}
                        onChange={() => handleItemSelect(row.sequenceReceipt)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.sequenceReceipt}</StyledTableCell>
                    <StyledTableCell align="center">{row.description}</StyledTableCell>
                    <StyledTableCell align="center">{row.internalParNumber}</StyledTableCell>
                    <StyledTableCell align="center">
                      {loadingMap[row.sequenceReceipt] ? (
                        <>
                          <Box display="flex" alignItems="center" justifyContent="center">
                            <CircularProgress
                              value={0}
                              sx={{ color: colorTableDefault }}
                              size={30}
                            />
                            <Typography ml={2} variant="caption">
                              Enviando...
                            </Typography>
                          </Box>
                        </>
                      ) : (
                        <>
                          {(() => {
                            const status = statusMessage[row.idProductPartner]

                            if (status === 'Codigo do produto invalido') {
                              return <Chip color="error" variant="filled" label={status || ''} />
                            } else if (status === 'Falha ao enviar') {
                              return <Chip color="error" variant="filled" label={status || ''} />
                            } else if (status === 'Registro feito com sucesso') {
                              return (
                                <Chip color="success" variant="filled" label={`${status}` || ''} />
                              )
                            } else {
                              return (
                                <Chip color="info" variant="filled" label={`Pronto para enviar`} />
                              )
                            }
                          })()}
                        </>
                      )}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Tooltip title="Reenviar" arrow>
                          <IconButton onClick={() => handleSaveUnity(row)}>
                            <Replay sx={{ cursor: 'pointer' }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Apagar" arrow>
                          <IconButton onClick={() => deleteDialog(row.sequenceReceipt, 'delete')}>
                            <DeleteForever sx={{ cursor: 'pointer' }} />
                          </IconButton>
                        </Tooltip>

                        {/* <Tooltip title="Editar" arrow>
                            <IconButton onClick={() => handleGetEdit(row)}>
                              <Edit sx={{ cursor: 'pointer' }} />
                            </IconButton>
                          </Tooltip> */}
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filter.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </Box>
  )
}
export default ChargeRecipeMachine
