import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getPlanService } from '../../../services/admServices'
import { Context } from '../../../context/AuthContext'
import HelpIcon from '@mui/icons-material/Help'
import ViewElearning from '../../ViewElearning'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AiFillPlusCircle } from 'react-icons/ai'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Tooltip,
} from '@mui/material'

//icones react-icons
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import PlanEdit from '../../Edit/PlanEdit'
import PlanCreated from '../../Create/PlanCreate'
import PermissionComponent from '../../../permissions'

const Plan = () => {
  const token = localStorage.getItem('token')
  const [plan, setPlan] = useState([])
  const [pagination, setPagination] = useState({})
  const [planLimit, setPlanLimit] = useState(10)
  const [planOffset, setPlanOffset] = useState(0)
  const [planOrder, setPlanOrder] = useState(false)
  const navigate = useNavigate()
  const [viewElearning, setViewElearning] = useState(false)

  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('')
  const [idEditing, setIdEditing] = useState('')

  const { userRoles, nameUrl } = useContext(Context)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getPlan = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getPlanService(token, nameUrl, planLimit, planOffset, planOrder)
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setPlan(response.data.result)
        setPagination(response.data)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [planOrder])

  const getPlanPage = useCallback(async (offset, order) => {
    try {
      const response = await getPlanService(token, nameUrl, planLimit, offset, order)
      setPlan(response.data.result)
      setPagination(response.data)
      console.log(response)
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(() => {
    getPlan()
  }, [planOrder])

  const handleEdit = id => {
    setIdEditing(id)
    setExpanded(true)
    setEditing(true)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <ViewElearning pageName={'Plan'} open={viewElearning} setOpen={setViewElearning} />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Planos</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>

        {/* Criar  */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>{editing ? 'Editando Plano' : 'Cadastrar Plano'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="PlanEDIT">
                <PlanEdit
                  setEditing={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  planId={idEditing}
                  setExpanded={setExpanded}
                  handleFetchData={getPlan}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="PlanCreate">
                <PlanCreated
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={getPlan}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>

        <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PermissionComponent role="PlanCreate">
              <Button
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
                variant="contained"
                endIcon={<AiFillPlusCircle />}
              >
                Criar
              </Button>
            </PermissionComponent>
          </Box>
        </Box>
        {plan.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell> </StyledTableCell>
                  <StyledTableCell align="left">Código do Produto</StyledTableCell>
                  <StyledTableCell align="left">Nome do Produto</StyledTableCell>
                  <StyledTableCell align="left">Preço</StyledTableCell>
                  <StyledTableCell align="left">Url Checkout</StyledTableCell>
                  <StyledTableCell align="left">Recorrente</StyledTableCell>
                  <StyledTableCell align="left">Ativo</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {plan
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'left',
                            justifyContent: 'space-between',
                            width: '80px',
                          }}
                        >
                          <PermissionComponent role="PlanDELETE">
                            <Tooltip title="Apagar" arrow>
                              <IconButton
                                onClick={() => navigate(`/PlanDelete/${row.plansId}`)}
                                sx={{ cursor: 'pointer' }}
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                            </Tooltip>
                          </PermissionComponent>
                          <PermissionComponent role="PlanEDIT">
                            <Tooltip title="Editar" arrow>
                              <IconButton
                                onClick={() => handleEdit(row.plansId)}
                                sx={{ cursor: 'pointer' }}
                              >
                                <ModeEditOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </PermissionComponent>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.plansCode}</StyledTableCell>
                      <StyledTableCell align="left">{row.plansName}</StyledTableCell>
                      <StyledTableCell align="left">{row.price}</StyledTableCell>
                      <StyledTableCell align="left">{row.urlCheckout}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row.recurrence ? 'Sim' : 'Não'}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.active ? 'Sim' : 'Não'}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={plan.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default Plan
