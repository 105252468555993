import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import HttpsIcon from '@mui/icons-material/Https'
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
} from '@mui/material'
import { styled } from '@mui/system'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  getDataLockService,
  receiveManualEntryMobileService,
  receiveOrderPickingMobileService,
  searchProductService,
} from '../../../services/productService'

import HelpIcon from '@mui/icons-material/Help'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import { AiFillFileExcel } from 'react-icons/ai'
import PropTypes from 'prop-types'
import * as XLSX from 'xlsx'
import { useForm } from 'react-hook-form'
import { getAlocationStockGetAll } from '../../../services/alocationServices'

const ReceiveManualEntry = () => {
  const { userRoles, subCompany, userId, nameUrl } = useContext(Context)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const vertical = 'top'
  const horizontal = 'center'
  const [hasSave, setHasSave] = useState(false)
  const [saveText, setSaveText] = useState('')
  const [saveStatus, setSaveStatus] = useState('warning')

  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)
  const [statusServ, setStatusServ] = useState('Pronto')
  const [statusServColor, setStatusServColor] = useState('success')
  const [numberPallet, setNumberPallet] = useState('')
  const [lote, setLote] = useState('')

  const [produtos, setProdutos] = useState([])
  const [produtosList, setProdutosList] = useState([])
  const [alocationStock, setAlocationStock] = useState([])
  const [alocationStockId, setAlocationStockId] = useState('')
  const [singleProd, setSingleProd] = useState()
  const [viewElearning, setViewElearning] = useState(false)

  const [loadingList, setLoadingList] = useState(false)
  const [progress, setProgress] = useState(0)
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)
  const [updateList, setUpdateList] = useState([])

  const [saving, setSaving] = useState(false)

  const [itemStatus, setItemStatus] = useState([])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: 'white',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      //backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const addToList = async () => {
    const p = { ...singleProd }
    p.lote = lote
    p.numberPallet = numberPallet
    const repetido = updateList.find(x => x.productId === p.productId)
    const repetido2 = updateList.find(x => x.numberPallet === p.numberPallet)
    const repetido3 = updateList.find(x => x.lote === p.lote)
    if (repetido && repetido2 && repetido3) {
      setStatusServ('Produto já está na lista')
      setStatusServColor('warning')
      return
    } else {
      setLoadingList(true)
      setStatusServ('Adicionando produto...')
      setStatusServColor('info')
      const dados = {
        productId: p.productId,
        subCompanyId: subCompany[0].subCompanyId,
        lote: p.lote,
        numberPallet: p.numberPallet,
      }

      try {
        const checkBlocked = await getDataLockService(token, nameUrl, dados)
        if (checkBlocked.data.length > 0) {
          checkBlocked.data.map(c => {
            if (
              updateList.find(x => x.productId === c.productId) &&
              updateList.find(x => x.numberPallet === c.numberPallet) &&
              updateList.find(x => x.lote === c.lote)
            ) {
              return
            } else {
              c.send = true
              setUpdateList(curr => [...curr, c])
            }
          })
          setStatusServ('Produto adicionado')
          setStatusServColor('success')
        } else {
          setStatusServ('Produto não encontrado')
          setStatusServColor('warning')
        }
      } catch (error) {
        setHasSave(true)
        setSaveText(error.response.data.message)

        setStatusServColor('warning')
      }

      setLoadingList(false)
    }
  }

  const changeProd = id => {
    const prod = produtosList.find(x => x.productId === id)
    const finalProd = prod
    setSingleProd(finalProd)
  }

  const changeCheck = (id, val) => {
    const newList = updateList.map(item => {
      if (item.productItensId == id) {
        return { ...item, checked: val }
      }
      return item
    })
    setUpdateList(newList)
  }

  const saveList = async () => {
    setOpen(false)
    setSaving(true)
    setStatusServ('Salvando...')
    setStatusServColor('info')

    const removeChecked = updateList.map(item => {
      return { ...item, checked: false }
    })

    setUpdateList(removeChecked)

    try {
      let itSent = false
      const keysToKeep = ['serial', 'lockProduct']
      const redux = array =>
        array.map(o =>
          keysToKeep.reduce((acc, curr) => {
            acc[curr] = o[curr]
            return acc
          }, {})
        )
      const fin = updateList.filter(item => item.checked)

      const finalList = redux(fin)
      finalList.forEach(i => {
        i.subCompanyId = subCompany[0].subCompanyId
        i.usersId = userId
      })

      const newItemStatus = itemStatus

      for (const item of finalList) {
        console.log(item, 'item')

        const response = await receiveManualEntryMobileService(token, nameUrl, [item])
        console.log(response, 'response')
        const message = response.data.message

        const isItemExists = itemStatus.some(status => status.serial === item.serial)

        if (!isItemExists) {
          if (!isItemExists) {
            newItemStatus.push({ serial: item.serial, message: message })
            setItemStatus(newItemStatus)
          } else {
            itSent = true
          }
        }
      }

      if (itSent) {
        setHasSave(true)
        setSaveStatus('success')
        setSaveText('Produto enviado com sucesso!')
      } else {
      }

      setSaving(false)
      setStatusServ(
        `Finalizado. ${
          finalList.filter(x => x.lockProduct === false).length
        } produtos atualizados (${
          finalList.length - finalList.filter(x => x.lockProduct === false).length
        } bloqueados)`
      )
      setStatusServColor('success')
    } catch (e) {}
  }

  const handlAlocationStock = async () => {
    const data = {
      subCompanyId: subCompany[0].subCompanyId,
      limit: 500,
      offset: 1,
    }
    try {
      const response = await getAlocationStockGetAll(token, nameUrl, data)
      setAlocationStock(response.data.result)
      console.log(response.data.result, 'resu')
    } catch (error) {}
  }

  useEffect(() => {
    setLoading(true)
    handlAlocationStock()
  }, [])

  useEffect(() => {
    if (search === '') {
      const checagem = setTimeout(() => {
        setProdutos([{ label: '', value: '' }])
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearching(true)
      const checagem = setTimeout(() => {
        const dados = {
          companyId: subCompany[0].companyId,
          SearchWord: search,
          limit: 10,
          offset: 0,
        }
        searchProductService(token, nameUrl, dados).then(response => {
          setProdutosList(response.data.result)
          const prodsFinal = []
          response.data.result.map(produto => {
            const obj = produto
            obj.value = produto.productId
            obj.label = `${produto.name}`
            prodsFinal.push(obj)
          })
          setProdutos(prodsFinal)
          setSearching(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [search])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleClose = () => {
    setHasSave(false)
  }

  const [open, setOpen] = React.useState(false)

  const handleDialogOpen = () => {
    setOpen(true)
  }

  const handleDialogClose = () => {
    setOpen(false)
  }

  const downloadData = async () => {
    setDownloadMessage('Inicializando')
    setDownloading(true)
    const dados = {
      productId: singleProd.productId,
      subCompanyId: subCompany[0].subCompanyId,
      lote: lote,
      numberPallet: numberPallet,
    }

    const resp = await getDataLockService(token, nameUrl, dados)
    finishDownload(dados)
  }

  const finishDownload = async dados => {
    let result = []
    let checkBlocked = []

    for (let i = 0; i <= 100; i++) {
      setDownloadMessage(`Baixando ${i} de ${100}...`)
      setProgress((i / 100) * 100 + '%')
      if (i < 50 || i > 50) {
        setTimeout(() => {}, 500)
      } else {
        checkBlocked = await getDataLockService(token, nameUrl, dados)
      }
    }
    if (checkBlocked.data.length > 0) {
      checkBlocked.data.map(c => {
        result = result.concat(c)
      })
    }

    setDownloadMessage(`Seu arquivo está pronto.`)
    setDownloading(false)

    const relatorioFinal = []
    result.map(item => {
      relatorioFinal.push({
        'Nome Produto': item.name,
        Referência: item.referencia,
        Lote: item.lote,
        Pallet: item.numberPallet,
        Quantidade: item.quantitys,
        Local: item.address,
        'Data Validade': new Date(item.dataExpiration).toLocaleDateString(),
      })
    })

    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Entrada Manual')
    XLSX.writeFile(workbook, `Entrada Manual.xlsx`)
    setProgress(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={hasSave}
          onClose={handleClose}
          //message="Erro no login, verifique seu e-mail e senha"
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={saveStatus} sx={{ width: '100%' }}>
            {saveText}
          </Alert>
        </Snackbar>
        <ViewElearning
          pageName={'ReceiveOrderPicking'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Deseja mesmo dar entrada em{' '}
            {updateList.filter(x => x.lockProduct === false && x.checked).length} produtos?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {updateList.filter(x => x.lockProduct === true && x.checked).length} produtos estão
              travados e não podem dar entrada.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} autoFocus>
              Cancelar
            </Button>
            <Button onClick={saveList}>Confirmar</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={downloading} keepMounted aria-describedby="alert-dialog-slide-description">
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessage}</DialogTitle>
            <LinearProgressWithLabel value={progress} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Entrada Manual de Produtos</Typography>{' '}
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box
          sx={{ width: '100%', justifyContent: 'space-evenly', mt: 2 }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box sx={{ width: '100%' }}>
            <FormControl sx={{ width: '100%' }}>
              {/* <Autocomplete
                  disablePortal
                  id="lista-produtos"
                  options={produtos}
                  loading={loadingOptions}
                  //sx={{ width: 300 }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.productId}>
                        {option.label}
                      </li>
                    );
                  }}
                  renderInput={params => <TextField {...params} label="Produto" />}
                  onChange={(e,v) => {
                    changeProd(v.value)
                  }}
                /> */}
              <Autocomplete
                disablePortal
                id="lista-produtos"
                options={produtos}
                //sx={{ width: 300 }}
                loading={searching}
                loadingText="Procurando..."
                noOptionsText="Nenhum produto encontrado"
                isOptionEqualToValue={(option, value) => option.productId === value.value}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.productId}>
                      {option.label}
                    </li>
                  )
                }}
                renderInput={params => <TextField {...params} label="Procurar produto" />}
                onInputChange={(e, v) => {
                  setSearch(v)
                }}
                onChange={(e, v) => {
                  if (v) {
                    changeProd(v.value)
                  }
                }}
              />
            </FormControl>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 3 }}>
            {singleProd && (
              <TextField
                sx={{ width: '100%' }}
                value={singleProd.name}
                id="standard-basic"
                label="Nome Produto"
                variant="standard"
                type="text"
                disabled
              />
            )}
          </Grid>
          {singleProd && (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                sx={{ width: '100%' }}
                value={singleProd.referencia}
                id="standard-basic"
                label="Referência"
                variant="standard"
                type="text"
                disabled
              />
            </Grid>
          )}

          {singleProd && (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                sx={{ width: '100%' }}
                value={singleProd.sku}
                id="standard-basic"
                label="SKU"
                variant="standard"
                type="text"
                disabled
              />
            </Grid>
          )}
          {singleProd && (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                sx={{ width: '100%' }}
                value={lote}
                id="standard-basic"
                label="Lote"
                variant="standard"
                type="text"
                onChange={e => setLote(e.target.value)}
              />
            </Grid>
          )}
          {singleProd && (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                sx={{ width: '100%' }}
                value={numberPallet}
                id="standard-basic"
                label="Número Pallet"
                variant="standard"
                type="text"
                onChange={e => setNumberPallet(e.target.value)}
              />
            </Grid>
          )}
        </Grid>

        {singleProd && (
          <Box
            sx={{
              width: '100%',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              mt: 4,
              mb: 2,
            }}
          >
            <Box
              width={{ xs: '100%', sm: '100%', md: '40%', lg: '40%', xl: '40%' }}
              sx={{ mt: '1rem', alignItems: 'center', display: 'flex', gap: 2 }}
            >
              <Button variant="contained" sx={{ width: '100%' }} type="button" onClick={addToList}>
                {loadingList ? 'Adicionando...' : 'Pesquisar produtos'}
              </Button>
              <Button
                variant="contained"
                sx={{ width: '100%' }}
                type="button"
                onClick={() => setUpdateList([])}
                disabled={loadingList}
              >
                Limpar lista
              </Button>
            </Box>
          </Box>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          {updateList.length > 0 && (
            <Button onClick={downloadData} variant="contained" endIcon={<AiFillFileExcel />}>
              Excel
            </Button>
          )}
        </Box>
        {updateList.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <button
                      type="button"
                      onClick={() => setOpen(true)}
                      disabled={saving}
                      className="buttonDocs"
                      sx={{ justifyContent: 'center', fontSize: '1.2em' }}
                    >
                      Enviar Informações
                    </button>
                    <Box sx={{ display: 'flex', mt: 2 }}>
                      <HttpsIcon fontSize="small" /> = Produto Bloqueado
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center">Nome Produto</StyledTableCell>
                  <StyledTableCell align="center">Referência</StyledTableCell>
                  <StyledTableCell align="center">Lote</StyledTableCell>
                  <StyledTableCell align="center">Pallet</StyledTableCell>
                  <StyledTableCell align="center">Quantidade</StyledTableCell>
                  <StyledTableCell align="center">Local</StyledTableCell>
                  <StyledTableCell align="center">Data Validade</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {updateList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{ cursor: 'pointer' }}
                        //</StyledTableRow>onClick={()=>navigate(`/ProductDelete/${row.productId}`)}
                      >
                        <Checkbox
                          checked={row.checked}
                          onChange={e => changeCheck(row.productItensId, e.target.checked)}
                        />
                        {row.lockProduct ? <HttpsIcon /> : ''}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.name}</StyledTableCell>
                      <StyledTableCell align="center">{row.referencia}</StyledTableCell>
                      <StyledTableCell align="center">{row.lote}</StyledTableCell>
                      <StyledTableCell align="center">{row.numberPallet}</StyledTableCell>
                      <StyledTableCell align="center">{row.quantitys}</StyledTableCell>
                      <StyledTableCell align="center">{row.address}</StyledTableCell>
                      <StyledTableCell align="center">
                        {new Date(row.dataExpiration).toLocaleDateString()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {itemStatus.map(item => (item.serial === row.serial ? item.message : ''))}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={updateList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={saving}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ReceiveManualEntry
