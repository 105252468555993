import { createContext, useCallback, useEffect, useState } from 'react'
import { getCompany, getUser } from '../services/api'
import logo from '../assets/5f5c53cedfc5133b6c705c51_Ativo-2@4x-300x64.png.png'
import { Helmet } from 'react-helmet'
import favicon from '../assets/favicon-16x16.png'
import Cookies from 'js-cookie'
import { getListUserRoles } from '../services/admServices'
import logoBihands from '../assets/bihands.png'
const Context = createContext()

function AuthProvider({ children }) {
  const token = localStorage.getItem('token')
  const companySelected = localStorage.getItem('companyId')

  const nameUrlLocal = localStorage.getItem('url')
  const [loading, setLoading] = useState(true)
  const [authenticated, setAuthenticated] = useState(false)
  const [user, setUser] = useState('')
  const [userId, setUserId] = useState([])
  const [userName, setUserName] = useState('')
  const [subCompany, setSubCompany] = useState([])
  const [userRoles, setUserRoles] = useState([])
  const [userRolesNew, setUserRolesNew] = useState([])
  const [company, setCompany] = useState([])
  const [userEmail, setUserEmail] = useState('')
  const [colorPrimary, setColorPrimary] = useState('#3D5D84')
  const [colorSecondary, setColorSecondary] = useState('#1976d2')
  const [colorMenu, setColorMenu] = useState('#F1F9FF')
  const [letterPrimary, setLetterPrimary] = useState('#212121')
  const [letterSecondary, setLetterSecondary] = useState('#212121')
  const [fontColorMenuPrimary, setFontColorMenuPrimary] = useState('#212121')
  const [fontColorMenuSecondary, setFontColorMenuSecondary] = useState('#212121')
  const [sizeLogoMenu, setSizeLogoMenu] = useState('200')
  const [sizeLogoSideMenu, setSizeLogoSideMenu] = useState('200')
  const [companyName, setCompanyName] = useState('Inventário')
  const [urlLogo, setUrlLogo] = useState(logoBihands)
  const [faviconLink, setFaviconLink] = useState(favicon)
  const [nameUrl, setNameUrl] = useState('')

  const handleLogin = async response => {
    setUserId(response.data.user.id)
    setUser(response.data.user)

    setUserName(response.data.user.fullName)
    setSubCompany(response.data.user.listSubCompany)

    if (response.data.user.listSubCompany[0].nameUrl) {
      setNameUrl(response.data.user.listSubCompany[0].nameUrl.toLowerCase())
      localStorage.setItem('url', response.data.user.listSubCompany[0].nameUrl.toLowerCase())
    } else if (response.data.user.listSubCompany[0].nameUrl === null) {
      setNameUrl('1')
      localStorage.setItem('url', '1')
    } else {
      setNameUrl('1')
      localStorage.setItem('url', '1')
    }

    setUserRoles(response.data.user.roles)
    setAuthenticated(true)

    const filterPermission = response.data.user.roles.filter(item => item.includes('ZPLLogistica'))
    await getRolesUserId(
      response.data.token,
      response.data.user.id,
      response.data.user.listSubCompany[0].nameUrl
        ? response.data.user.listSubCompany[0].nameUrl.toLowerCase()
        : '1'
    )

    await handleSetStyles(response)
  }

  useEffect(() => {}, [userId, userName, userRoles, subCompany, authenticated])

  const handleSetStyles = async response => {
    try {
      const templates = await getCompany(
        response.data.token,
        response.data.user.listSubCompany[0].nameUrl
          ? response.data.user.listSubCompany[0].nameUrl.toLowerCase()
          : '1',
        response.data.user.listSubCompany[0].companyId
      )
      if (templates.data.urlLogo) {
        setUrlLogo(templates.data.urlLogo)
      }
      if (templates.data.colorPrimary) {
        setColorPrimary(templates.data.colorPrimary)
      }
      if (templates.data.colorSecondary) {
        setColorSecondary(templates.data.colorSecondary)
      }
      if (templates.data.colorMenu) {
        setColorMenu(templates.data.colorMenu)
      }
      if (templates.data.letterPrimary) {
        setLetterPrimary(templates.data.letterPrimary)
      }
      if (templates.data.letterSecondary) {
        setLetterSecondary(templates.data.letterSecondary)
      }

      if (templates.data.sizeLogoMenu) {
        setSizeLogoMenu(templates.data.sizeLogoMenu)
      }
      if (templates.data.sizeLogoSidemenu) {
        setSizeLogoSideMenu(templates.data.sizeLogoSideMenu)
      }
      if (templates.data.fontColorMenuPrimary) {
        setFontColorMenuPrimary(templates.data.fontColorMenuPrimary)
      }
      if (templates.data.fontColorMenuSecondary) {
        setFontColorMenuSecondary(templates.data.fontColorMenuSecondary)
      }
      if (templates.data.faviconUrl) {
        setFaviconLink(templates.data.faviconUrl)
      }
      setCompanyName(templates.data.name)
    } catch (error) {
      setLoading(false)
    }
  }

  const removeAllCookies = () => {
    const cookies = Cookies.get()

    Object.keys(cookies).forEach(cookieName => {
      Cookies.remove(cookieName)
    })
  }

  const clearAllCaches = () => {
    caches.keys().then(cacheNames => {
      cacheNames.forEach(cacheName => {
        caches.delete(cacheName)
      })
    })
  }

  const handleLogout = useCallback(async () => {
    setCompanyName('Inventário')
    setFaviconLink(favicon)

    localStorage.removeItem('token')
    localStorage.removeItem('url')
    localStorage.removeItem('selectedList')
    localStorage.removeItem('companyId')

    removeAllCookies()
    window.addEventListener('beforeunload', clearAllCaches)
    setAuthenticated(false)
  }, [])

  const getToken = async () => {
    if (token) {
      try {
        const response = await getUser(token, nameUrlLocal)
        if (response.data.listSubCompany[0].nameUrl) {
          setNameUrl(response.data.listSubCompany[0].nameUrl.toLowerCase())
        } else {
          setNameUrl('1')
          localStorage.setItem('url', '1')
        }

        await getRolesUserId(token, response.data.id, nameUrlLocal ? nameUrlLocal : 'bihands')
        setUserEmail(response.data.email)
        setUserId(response.data.id)
        setUserName(response.data.fullName)
        setSubCompany(response.data.listSubCompany)
        setUserRoles(response.data.roles)
        setAuthenticated(true)

        const templates = await getCompany(
          token,
          response.data.listSubCompany[0].nameUrl
            ? response.data.listSubCompany[0].nameUrl.toLowerCase()
            : '1',
          companySelected ? companySelected : response.data.listSubCompany[0].companyId
        )

        setCompany(templates.data)
        console.log(templates.data, 'dadsaddsadsa')

        if (templates.data.colorPrimary) {
          setColorPrimary(templates.data.colorPrimary)
        }
        if (templates.data.colorSecondary) {
          setColorSecondary(templates.data.colorSecondary)
        }
        if (templates.data.colorMenu) {
          setColorMenu(templates.data.colorMenu)
        }
        if (templates.data.letterPrimary) {
          setLetterPrimary(templates.data.letterPrimary)
        }
        if (templates.data.letterSecondary) {
          setLetterSecondary(templates.data.letterSecondary)
        }
        if (templates.data.urlLogo) {
          setUrlLogo(templates.data.urlLogo)
        }
        if (templates.data.sizeLogoMenu) {
          setSizeLogoMenu(templates.data.sizeLogoMenu)
        }
        if (templates.data.sizeLogoSideMenu) {
          setSizeLogoSideMenu(templates.data.sizeLogoSideMenu)
        }

        if (templates.data.fontColorMenuPrimary) {
          setFontColorMenuPrimary(templates.data.fontColorMenuPrimary)
        }
        if (templates.data.fontColorMenuSecondary) {
          setFontColorMenuSecondary(templates.data.fontColorMenuSecondary)
        }
        if (templates.data.faviconUrl) {
          setFaviconLink(templates.data.faviconUrl)
        }
        setCompanyName(templates.data.name)
        setLoading(false)
      } catch (err) {
        setAuthenticated(false)
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }

  const getRolesUserId = useCallback(
    async (token, userId, nameUrl) => {
      try {
        const response = await getListUserRoles(token, nameUrl, userId)
        const dataRole = response.data.rolesUserList.map(role => ({
          userId: '799a55c2-d06b-4eee-9f6e-6360f246047a',
          rolesId: role.rolesId,
        }))
        console.log(dataRole, 'dataRoledatasRole')
        setUserRolesNew(response.data.rolesUserList)
        setAuthenticated(true)
      } catch (error) {
        setLoading(false)
      }
    },
    [userId, setUserRolesNew]
  )

  useEffect(() => {
    getToken()
  }, [getRolesUserId])

  return (
    <Context.Provider
      value={{
        loading,
        authenticated,
        handleLogin,
        handleLogout,
        userId,
        userName,
        subCompany,
        userRoles,
        logo,
        company,
        userRolesNew,
        userEmail,
        colorPrimary,
        colorSecondary,
        colorMenu,
        letterPrimary,
        letterSecondary,
        sizeLogoMenu,
        sizeLogoSideMenu,
        fontColorMenuPrimary,
        fontColorMenuSecondary,
        urlLogo,
        nameUrl,
        setNameUrl,
      }}
    >
      <Helmet>
        <title>{companyName === 'Inventário' ? companyName : companyName + ' - Inventário'}</title>
        <link id="favicon" rel="icon" type="image/x-icon" sizes="16x16" href={faviconLink} />
      </Helmet>
      {children}
    </Context.Provider>
  )
}

export { Context, AuthProvider }
