import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import * as XLSX from 'xlsx'
import { Context } from '../../../context/AuthContext'
import {
  deleteProductService,
  reloadProductService,
  searchProductService,
  searchProductSubCompanyService,
} from '../../../services/productService'

import '../../../assets/global.css'

//icones react-icons
import { AiFillFileExcel } from 'react-icons/ai'

import { Search } from '@mui/icons-material'
import HelpIcon from '@mui/icons-material/Help'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Skeleton,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import LinearProgress from '@mui/material/LinearProgress'
import PropTypes from 'prop-types'
import { useSearchParams } from 'react-router-dom'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import { useDebounce } from './Components/useDebounce'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '8px',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const ProductClient = () => {
  const { subCompany, company, nameUrl } = useContext(Context)
  const { debounce } = useDebounce(1000, true)
  const [companies, setCompanies] = useState([])
  const [companyId, setCompanyId] = useState('')
  const [subCompanyId, setSubCompanyId] = useState('')
  const [loadingSearch, setLoadingSearch] = useState(true)
  const token = localStorage.getItem('token')
  const [product, setProduct] = useState([])
  const [productSearch, setProductSeach] = useState([])
  const [filteredData, setFilteredData] = useState([])

  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [totalPagesDownload, setTotalPagesDownload] = useState('')
  const [progress, setProgress] = useState(0)
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)
  const [errorEmpresa, setErrorEmpresa] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteProd, setDeleteProd] = useState({ productName: '', productId: '' })
  const [editing, setEditing] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [saving, setSaving] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)

  // variaveis Criação
  const [name, setName] = useState('')
  const [register, setRegister] = useState(false)
  const [importProducts, setImportProducts] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [expanded, setExpanded] = useState(false)
  const [searching, setSearching] = useState(false)
  const [searchR, setSearchR] = useState('')
  const [codReference, setCodReference] = useState('')
  const [productId, setProductId] = useState('')
  const [search, setSearch] = useState('')
  const [viewElearning, setViewElearning] = useState(false)
  const [isLoadingExcel, setIsLoadingExcel] = useState(false)
  const [isExternalLoading, setIsExternalLoading] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalProducts, setTotalProducts] = useState(0)
  const [selectedId, setSelectedId] = useState(undefined)
  const [searchParams, setSearchParams] = useSearchParams()
  const [row, setRow] = useState()

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1')
  }, [searchParams])

  const handleImportProducts = () => {
    setImportProducts(true)
  }

  const handleImportProductsApi = async () => {
    handleClose()

    setSaving(true)
    try {
      const response = await reloadProductService(token, nameUrl, codReference, subCompanyId)
      setTitle(response.data.message)
      setSeverity('success')
      setRegister(true)
    } catch (e) {
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setRegister(true)
    }
    setSaving(false)
  }

  const handleDelete = async () => {
    setLoading(true)
    try {
      await deleteProductService(token, nameUrl, deleteProd.productId)
      setLoading(false)
      setDeleteOpen(false)
      handleGetAllProductsSearch()
    } catch (err) {
      setLoading(false)
    }
  }

  const onEditClick = e => {
    e.stopPropagation()
  }

  const handleGetAllProductsSearch = async () => {
    setIsLoading(true)
    const dataProduct = {
      subCompanyId: subCompanyId,
      limit: rowsPerPage,
      offset: page,
      order: 'desc',
      SearchWord: search,
    }
    try {
      searchProductSubCompanyService(token, nameUrl, dataProduct).then(response => {
        const data = response.data.result
        setProduct(data)
        if (selectedId) {
          setTotalProducts(response.data.result.length)
          setSearchR(response.data.result.length)
        } else {
          setTotalProducts(response.data.total)
          setSearchR(response.data.total)
        }
        setIsLoading(false)
      })
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setCurrentPage(0)
    if (subCompanyId) {
      setIsLoading(true)
      debounce(() => {
        handleGetAllProductsSearch()
      })
    }
  }, [search, page, selectedId, subCompanyId, rowsPerPage])

  useEffect(() => {}, [product])

  const downloadData = async () => {
    setDownloadMessage('Inicializando')
    setDownloading(true)
    const dataProduct = {
      subCompanyId: subCompanyId,
      limit: 500,
      offset: 1,
      order: 'desc',
      SearchWord: '',
    }
    await searchProductSubCompanyService(token, nameUrl, dataProduct).then(r => {
      setTotalPagesDownload(r.data.totalpage + 1)
      finishDownload(r.data.totalpage + 1)
    })
  }

  const finishDownload = async pages => {
    let result = []
    const totalPages = pages

    for (let i = 0; i <= totalPages; i++) {
      const dataProduct = {
        subCompanyId: subCompanyId,
        limit: 500,
        offset: i + 1,
        order: 'desc',
        SearchWord: '',
      }
      setDownloadMessage(`Baixando...`)
      const progress = Math.min(((i + 1) / totalPages) * 100, 100)
      setProgress(progress)

      const response = await searchProductSubCompanyService(token, nameUrl, dataProduct)
      result = result.concat(response.data.result)
    }

    setDownloadMessage(`Seu arquivo está pronto.`)
    setDownloading(false)

    const relatorioFinal = []
    result.map(item => {
      relatorioFinal.push({
        Nome: item.name,
        'Cód. Barras': item.barCode,
        'Codigo do Local': item.picking,
        'Responsável matricula': item.responsibleEmployee,
      })
    })

    // Cria o arquivo Excel
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Produtos')
    XLSX.writeFile(workbook, `Produtos.xlsx`)

    // Redefine o progresso para 0
    setProgress(0)
  }

  useEffect(() => {
    fetchCompanies()
  }, [])

  const fetchCompanies = () => {
    let arrayCompanies = []
    subCompany.forEach(element => {
      const filter = arrayCompanies.some(company => company.companyId === element.companyId)
      if (!filter) {
        arrayCompanies.push(element)
      }
    })
    setCompanies(arrayCompanies)
  }

  const handleClose = () => {
    setErrorEmpresa(false)
    setRegister(false)
    setImportProducts(false)
    setEditing(false)
    setDeleting(false)
  }

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1)
    setSearchParams({ ...searchParams, page: newPage + 1 })
  }

  const handleChangeRowsPerPage = event => {
    const newRowsPerPage = +event.target.value
    setRowsPerPage(newRowsPerPage)
    setCurrentPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress color="secondary" variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  }

  const handleEditing = id => {
    setProductId(id)
    setEditing(true)
    setExpanded(true)
  }

  const handleConfirmDelete = id => {
    setProductId(id)
    setDeleting(true)
  }

  const deleteProduct = useCallback(async () => {
    setDeleting(false)
    setLoading(true)

    try {
      await deleteProductService(token, nameUrl, productId)
      setTitle('Produto deletado com sucesso!')
      setSeverity('success')
      setLoading(false)
      setRegister(true)
      //getAProducts()
    } catch (err) {
      setTitle('Desculpe, ocorreu um erro interno no servidor. Tente novamente mais tarde.')
      setSeverity('error')
      setRegister(true)
      setLoading(false)
      //getAProducts()
    }
  }, [productId])

  const autoCompleteSelectedOptions = useMemo(() => {
    if (!selectedId) return undefined

    const selectedOptions = product.find(item => item.id === selectedId)

    return selectedOptions
  }, [selectedId, product])

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Dialog open={downloading} aria-describedby="alert-dialog-description">
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessage}</DialogTitle>
            <LinearProgressWithLabel value={progress} sx={{ width: '100%' }} />
          </Box>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={errorEmpresa}
          onClose={handleClose}
          //message="Erro no login, verifique seu e-mail e senha"
          //key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={register}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Modal
          open={importProducts}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, maxWidth: 600, width: '100%' }}>
            <Typography my={2} variant="h6" textAlign="center">
              Insira o codigo do produto ou a referência
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-label">Filial</InputLabel>
                  <Select
                    label="Selecione a Filial"
                    onChange={e => setSubCompanyId(e.target.value)}
                    value={subCompanyId}
                  >
                    {subCompany.map((item, index) => {
                      return (
                        <MenuItem value={item.subCompanyId} key={index}>
                          {item.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignContent="center" justifyContent="center">
                  <TextField
                    disabled={!subCompanyId}
                    sx={{ width: '100%' }}
                    required
                    id="outlined-required"
                    label="Codigo/Referência"
                    value={codReference}
                    onChange={e => setCodReference(e.target.value)}
                  />
                </Box>
              </Grid>
            </Grid>

            <Box mt={4} display="flex" alignContent="center" justifyContent="center">
              <Button
                sx={{ maxWidth: '200px', width: '50%' }}
                variant="contained"
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <Button
                sx={{ ml: 2, maxWidth: '200px', width: '50%' }}
                variant="contained"
                onClick={() => handleImportProductsApi()}
              >
                Salvar
              </Button>
            </Box>
          </Box>
        </Modal>

        <Dialog
          open={downloading}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{downloadMessage}</DialogTitle>
          <LinearProgressWithLabel value={progress} />
        </Dialog>
        <ViewElearning pageName={'products'} open={viewElearning} setOpen={setViewElearning} />
        <Box
          sx={{
            width: 1,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h5">Produtos</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>

        {/* Criar Produto */}

        {/* Deletar Produto */}
        <Dialog
          open={deleting}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Tem certeza que deseja excluir esse produto?
          </DialogTitle>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Não
            </Button>
            <Button onClick={deleteProduct}>Sim</Button>
          </DialogActions>
        </Dialog>
        <Box
          sx={{
            mt: 2,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <SelectSubCompany
            value={subCompanyId}
            onChange={e => {
              setSubCompanyId(e.target.value)
            }}
          />
        </Box>
        <Grid container spacing={2} mt={2}>
          {/* <Grid item xs={12}>
            <Accordion expanded={expanded} onClick={() => setExpanded(!expanded)} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                {editing ? (
                  <Typography>Editando produto - {name}</Typography>
                ) : (
                  <Typography>Cadastrar Produto</Typography>
                )}
              </AccordionSummary>
              <AccordionDetails onClick={e => onEditClick(e)}>
                {editing ? (
                  <PermissionComponent role="Edit_Product">
                    <ProductEdit
                      setName={setName}
                      setExpanded={setExpanded}
                      id={productId}
                      setTitle={setTitle}
                      setSeverity={setSeverity}
                      setRegister={setRegister}
                      setEditing={setEditing}
                      getAllProducts={handleGetAllProductsSearch}
                    />
                  </PermissionComponent>
                ) : (
                  <PermissionComponent role="Create_Product">
                    <ProductCreate
                      setExpanded={setExpanded}
                      setTitle={setTitle}
                      setSeverity={setSeverity}
                      setRegister={setRegister}
                      getAllProducts={handleGetAllProductsSearch}
                    />
                  </PermissionComponent>
                )}
              </AccordionDetails>
            </Accordion>
          </Grid> */}
          {/* <Grid item xs={12}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
              <Select
                label="Selecione a empresa"
                onChange={e => setCompanyId(e.target.value)}
                value={companyId}
              >
                <MenuItem disabled selected value="">
                  <em>Empresa</em>
                </MenuItem>
                {companies.map((item, index) => {
                  return (
                    <MenuItem value={item.companyId} key={index}>
                      {item.nameCompany}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <Box width="100%">
              <TextField
                sx={{ width: '100%', maxWidth: '250px' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: isLoading && (
                    <InputAdornment position="end">
                      <CircularProgress color="info" size={20} />
                    </InputAdornment>
                  ),
                }}
                label="Pesquisar"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box width="100%" height="55px" display="flex" justifyContent="flex-end">
              {product.length > 0 && (
                <Button
                  sx={{ width: '100%', maxWidth: '150px', height: '100%' }}
                  onClick={downloadData}
                  variant="contained"
                  endIcon={<AiFillFileExcel />}
                >
                  {'Excel'}
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>

        <>
          <TableContainer component={Paper}>
            <Typography sx={{ width: 1, my: 2 }}>
              {product.length > 1
                ? `${searchR.toLocaleString('pt-BR')} Resultados`
                : `${searchR.toLocaleString('pt-BR')} Resultado`}
            </Typography>

            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Descrição do ativo</StyledTableCell>
                  <StyledTableCell align="left">Cód. Barras</StyledTableCell>
                  <StyledTableCell align="left">Codigo do endereço</StyledTableCell>
                  <StyledTableCell align="left">Responsável matricula</StyledTableCell>
                  <StyledTableCell align="left">Valor de depreciação</StyledTableCell>
                </TableRow>
              </TableHead>
              {isLoading ? (
                Array.from({ length: 10 }).map((_, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {Array.from({ length: 5 }).map((_, colIndex) => (
                      <TableCell key={colIndex}>
                        <Skeleton animation="wave" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableBody>
                  {product.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{row.name}</StyledTableCell>
                      <StyledTableCell align="left">{row.barCode}</StyledTableCell>
                      <StyledTableCell align="left"> {row.picking}</StyledTableCell>
                      <StyledTableCell align="left"> {row.responsibleEmployee}</StyledTableCell>
                      <StyledTableCell align="left"> {row.depreciationValue}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            <TablePagination
              labelRowsPerPage={'Produtos por página'}
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={totalProducts}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Dialog
          open={deleteOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Apagar Produto?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Confirme que você quer apagar o produto "{deleteProd.productName}"
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleDelete} autoFocus>
              Apagar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  )
}

export default ProductClient
