import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import * as XLSX from 'xlsx'
import PropTypes from 'prop-types'

import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { useRef } from 'react'
import '../../../assets/global.css'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import { ArrowBack, CheckCircle, Edit, Help, Save } from '@mui/icons-material'
import { getDepositService } from '../../../services/depositService'
import {
  confirmOrderErp,
  getCountStockInventoryPostService,
  getOnlyOrder,
  getQuantityProduct,
  resetAuditory,
  updateAuditory,
} from '../../../services/reportServices'
import PermissionComponent from '../../../permissions'
import { AiFillFileExcel } from 'react-icons/ai'
import { searchProductService } from '../../../services/productService'

const productList = [
  {
    idProductPartner: '1010001Teste',
    productName: 'PINGENT TEST2024',
    quantity: 0,
    stockCounts: {
      '36d999b6-1d25-4cd9-b37b-1b9f4761192c': 27,
      '5685608b-744d-4425-9bce-43e5e5d517f2': 2,
    },
  },
  {
    idProductPartner: '12312321321321321',
    productName: 'TesteTeste',
    quantity: 0,
    stockCounts: {
      '36d999b6-1d25-4cd9-b37b-1b9f4761192c': 12,
      '5685608b-744d-4425-9bce-43e5e5d517f2': 2,
    },
  },
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

function LinearProgressWithLabel(props) {
  return (
    <Box
      sx={{
        alignItems: 'center',
        textalign: 'center',
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        justifyConent: 'center',
      }}
    >
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  )
}
LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
}

const StockAudit = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [subCompanyCnpj, setSubCompanyCnpj] = useState('')
  const [depositId, setDepositId] = useState('')
  const [orders, setOrders] = useState()
  const [orderSelectedDialog, setOrderSelectedDialog] = useState('')
  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')
  const [isLoadingDialog, setIsLoadingDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [orderSelect, setOrderSelect] = useState([])
  const [deposit, setDeposit] = useState([])
  const token = localStorage.getItem('token')
  const [countStock, setCountStock] = useState([])
  const [pagination, setPagination] = useState({})
  const [product, setProduct] = useState([])
  const [listReportCount, setListReportCount] = useState([])
  const [listCompare, setListCompare] = useState([])
  const [productTotal, setProductTotal] = useState(10)
  const [countStockOrder, setCountStockOrder] = useState(false)
  const [openDialogCompar, setOpenDialogCompar] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')

  const [progressCount, setProgressCount] = useState(0)
  const [downloadMessageCount, setDownloadMessageCount] = useState('Pronto')
  const [downloadingCount, setDownloadingCount] = useState(false)
  const [openResetReprocess, setOpenResetReprocess] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [errorMessage, setErrorMessage] = useState('')
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)
  const [openDialogErp, setOpenDialogErp] = useState(false)
  const [filter, setFilter] = useState('')
  const [filterOrder, setFilterOrder] = useState('')
  const [valueQuantity, setValueQuantity] = useState('')
  const [filterIdProductPartner, setFilterIdProductPartner] = useState('')
  const [editedValues, setEditedValues] = useState({})
  const [editingId, setEditingId] = useState(null)
  const inputRefs = useRef({})
  const [savedItems, setSavedItems] = useState({})
  const [errorMessages, setErrorMessages] = useState({})
  const [loadingInput, setLoadingInput] = useState({})

  const handleClose = () => {
    setAlerta(false)
    setOpenDialogErp(false)
    setOpenResetReprocess(false)
  }

  const handleInputChange = (movimentationOrderId, row, value) => {
    setEditedValues(prev => ({
      ...prev,
      [movimentationOrderId]: {
        ...prev[movimentationOrderId],
        [row.idProductPartner]: value,
      },
    }))
  }

  const handleEditClick = (movimentationOrderId, productId) => {
    setEditingId(productId + movimentationOrderId)
    // Focar no input correspondente
    setTimeout(() => {
      if (inputRefs.current[movimentationOrderId]) {
        inputRefs.current[movimentationOrderId].focus()
      }
    }, 0) // Delay de 0 para garantir que o foco ocorra após a renderização
  }

  const handleSave = async (row, movimentationOrderId) => {
    const productId = row.idProductPartner
    const valueToSave = editedValues[movimentationOrderId]?.[productId]
    console.log(productId, 'productId')
    console.log(movimentationOrderId, 'movimentationOrderId')
    setLoadingInput(prev => ({
      ...prev,
      [productId + movimentationOrderId]: true, // Inicia o loading
    }))

    try {
      const response = await updateAuditory(
        token,
        nameUrl,
        subCompanyId,
        movimentationOrderId,
        valueToSave,
        productId
      )

      console.log()
      // Atualiza a lista após sucesso
      setListCompare(prevList =>
        prevList.map(product => {
          if (product.idProductPartner === productId) {
            return {
              ...product,
              country: product.country,
              stockCounts: {
                ...product.stockCounts,
                [movimentationOrderId]: valueToSave,
              },
            }
          }
          return product
        })
      )

      setSavedItems(prev => ({
        ...prev,
        [productId + movimentationOrderId]: true,
      }))

      setEditedValues(prev => ({
        ...prev,
        [movimentationOrderId]: {
          ...prev[movimentationOrderId],
          [productId]: null,
        },
      }))

      setEditingId(null) // Fecha o input de edição

      // Limpa qualquer erro anterior para este produto/movimentação
      setErrorMessages(prev => ({
        ...prev,
        [productId + movimentationOrderId]: null,
      }))
    } catch (error) {
      console.error('Erro ao salvar:', error)

      // Define a mensagem de erro no estado
      setErrorMessages(prev => ({
        ...prev,
        [productId + movimentationOrderId]: 'Erro ao salvar. Tente novamente.',
      }))
    } finally {
      setLoadingInput(prev => ({
        ...prev,
        [productId + movimentationOrderId]: false, // Termina o loading
      }))
    }
  }
  const handleGetAllProductsSearch = async () => {
    setPage(0)
    setLoading(true)
    const dataProduct = {
      companyId: subCompany[0].companyId,
      limit: 10,
      offset: 0,
      order: 'desc',
      SearchWord: '',
    }
    try {
      searchProductService(token, nameUrl, dataProduct).then(response => {
        const data = response.data.result
        setProduct(data)
        setProductTotal(response.data.total)
        setLoading(false)
      })
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetAllProductsSearch()
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleChange = (event, newValue) => {
    setOrderSelect(newValue)
    setErrorMessage('')
  }

  const getDepositAll = async subCompanyId => {
    const data = {
      subCompanyId: subCompanyId,
      limit: 500,
      offset: 1,
    }
    try {
      const response = await getDepositService(token, nameUrl, data)
      setDeposit(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }

  const getOrders = async e => {
    await getDepositAll(e.target.value)
    setSubCompanyId(e.target.value)

    const subCompanyCnpj = subCompany.find(sub => sub.subCompanyId === e.target.value)
    setSubCompanyCnpj(subCompanyCnpj.cnpj)
    try {
      const response = await getOnlyOrder(token, nameUrl, e.target.value)
      const sortedOrders = response.data.sort((a, b) => {
        return a.movimentationOrderNumberOtherSystem.localeCompare(
          b.movimentationOrderNumberOtherSystem
        ) // Troque 'orderName' pelo campo desejado
      })

      setOrders(sortedOrders)
    } catch (e) {
      console.log(e)
    }
  }

  const handleGetQuantityProduct = async () => {
    try {
      const response = await getQuantityProduct(token, nameUrl, subCompanyId, depositId)
      return response.data
    } catch (error) {}
  }

  const getCountStock = async () => {
    setLoading(true)

    const quantityProduct = await handleGetQuantityProduct()

    const countStockDTO = {
      subCompanyId: subCompanyId,
      movimentationOrderIds: orderSelect.map(item => item.movimentationOrderId),
    }

    try {
      const response = await getCountStockInventoryPostService(token, nameUrl, countStockDTO)
      setLoading(false)

      if (response.data.length > 0) {
        const uniqueProductsArray = Object.values(response.data)

        const updatedListCompare = uniqueProductsArray.map(mockProduct => {
          const matchingProduct = quantityProduct.find(
            product => product.idProductPartner === mockProduct.idProductPartner
          )
          if (matchingProduct) {
            return {
              ...mockProduct,
              quantity: matchingProduct.quantity,
            }
          } else {
            return {
              ...mockProduct,
              quantity: 0,
            }
          }
        })

        updatedListCompare.sort((a, b) => b.quantity - a.quantity)

        const productMap = {}

        updatedListCompare.forEach(product => {
          const {
            idProductPartner,
            productName,
            country,
            stockCount,
            quantity,
            movimentationOrderId,
          } = product

          if (!productMap[idProductPartner]) {
            productMap[idProductPartner] = {
              idProductPartner,
              productName,
              quantity: quantity,
              stockCounts: {},
              country,
            }
          }

          productMap[idProductPartner].stockCounts[movimentationOrderId] = stockCount || 0
        })

        Object.values(productMap).forEach(product => {
          orderSelect.forEach(order => {
            if (!(order.movimentationOrderId in product.stockCounts)) {
              product.stockCounts[order.movimentationOrderId] = 0
            }
          })
        })
        setCountStock(updatedListCompare)
        setListCompare(Object.values(productMap))
        setPagination(updatedListCompare)
      }
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const processStockData = (products, orders) => {
    const productMap = {}

    products.forEach(product => {
      const { idProductPartner, productName, stockCount, quantity, movimentationOrderId } = product

      if (!productMap[idProductPartner]) {
        productMap[idProductPartner] = {
          idProductPartner,
          productName,
          quantity: quantity,
          stockCounts: {},
        }
      }

      productMap[idProductPartner].stockCounts[movimentationOrderId] = stockCount || 0
    })

    Object.values(productMap).forEach(product => {
      orders.forEach(order => {
        if (!(order.movimentationOrderId in product.stockCounts)) {
          product.stockCounts[order.movimentationOrderId] = 0
        }
      })
    })

    return Object.values(productMap)
  }

  const filteredLists = processStockData(listCompare, orderSelect)
  console.log(filteredLists, 'listCompare')

  const filteredList = listCompare.filter(item => {
    const matchesOrder = filterOrder
      ? item.movimentationOrderNumberOtherSystem === filterOrder
      : true
    const matchesIdProductPartner = filterIdProductPartner
      ? item.idProductPartner.includes(filterIdProductPartner)
      : true
    return matchesOrder && matchesIdProductPartner
  })

  const handleFilterChange = event => {
    setFilter(event.target.value)
  }

  const downloadData = async () => {
    setDownloadMessage(`Seu arquivo está pronto.`)
    setDownloading(false)

    const relatorioFinal = filteredList.map(item => {
      const relatorioItem = {
        'Código ERP': item.idProductPartner,
        'Nome do Produto': item.productName,
        'Quantidade no ERP': item.quantity,
        Pais: item.country,
      }

      orderSelect
        .filter(order => !filter || order.movimentationOrderNumberOtherSystem === filter)
        .forEach(order => {
          relatorioItem[order.movimentationOrderNumberOtherSystem] =
            item.stockCounts[order.movimentationOrderId] || 0
        })

      return relatorioItem
    })

    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Contagem de Estoque')
    XLSX.writeFile(workbook, `Contagem de Estoque.xlsx`)
  }

  const handleMovimentationConfirmOrder = async () => {
    setOpenDialogErp(true)
    setIsLoadingDialog(true)
    const data = {
      movimentationOrderNumberOtherSystem: orderSelectedDialog,
      subCompanyCNPJ: subCompanyCnpj,
    }
    try {
      const response = await confirmOrderErp(
        token,
        nameUrl,
        movimentationOrderNumberOtherSystem,
        data
      )
      //setOrders(response.data)
      setIsLoadingDialog(false)
      setSeverity('success')
      setTitle(response.data)
      setAlerta(true)
      setOpenDialogErp(false)
    } catch (e) {
      setIsLoadingDialog(false)
      console.log(e)
      setSeverity('error')
      setTitle('Falha ao enviar, tente novamente!')
      setAlerta(true)
      setOpenDialogErp(true)
    }
  }
  const handleResetProcess = async () => {
    setIsLoadingDialog(true)
    let failedOrders = []
    let successCount = 0

    try {
      for (const item of orderSelect) {
        try {
          const response = await resetAuditory(
            token,
            nameUrl,
            subCompanyId,
            item.movimentationOrderId
          )
          console.log(item, 'successfully reset') // Ação em sucesso
          successCount++
        } catch (error) {
          console.error(`Erro ao resetar ${item.movimentationOrderId}:`, error)
          failedOrders.push(item.movimentationOrderNumberOtherSystem) // Adiciona ordem falhada à lista
        }
      }

      if (failedOrders.length > 0) {
        // Se houver falhas, exibe mensagem de erro
        setSeverity('error')
        setTitle(`Falha ao resetar as seguintes ordens: ${failedOrders.join(', ')}`)
        setAlerta(true)
      } else {
        // Se todas as ordens foram bem-sucedidas
        setSeverity('success')
        setTitle('Operação realizada com sucesso')
        setAlerta(true)
        setIsLoadingDialog(false)
      }
    } catch (error) {
      console.error('Erro geral no processo:', error)
      setSeverity('error')
      setTitle('Error! Tente novamente.')
      setAlerta(true)
    } finally {
      setIsLoadingDialog(false)
    }
  }

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullScreen
        sx={{ m: 2, position: 'relative' }}
      >
        <DialogTitle id="scroll-dialog-title">Auditoria de estoque</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <TextField />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ m: 2 }}>
          <Button variant="outlined" startIcon={<ArrowBack />} onClick={() => setOpenDialog(false)}>
            Voltar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialogErp} maxWidth="xs">
        <DialogTitle>Enviar contagem para o ERP</DialogTitle>
        <DialogContent>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2, my: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Selecione o pedido</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Selecione o pedido"
                value={orderSelectedDialog}
                onChange={e => {
                  setOrderSelectedDialog(e.target.value)
                }}
              >
                {orderSelect?.map(order => (
                  <MenuItem
                    value={order.movimentationOrderNumberOtherSystem}
                    key={order.movimentationOrderNumberOtherSystem}
                  >
                    {order.movimentationOrderNumberOtherSystem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              autoFocus
              required
              margin="dense"
              id="number"
              name="number"
              label="Informe o numero da ordem(ERP)"
              type="text"
              fullWidth
              variant="outlined"
              value={movimentationOrderNumberOtherSystem}
              onChange={e => setMovimentationOrderNumberOtherSystem(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={handleMovimentationConfirmOrder} variant="contained" type="submit">
            Enviar
          </Button>
        </DialogActions>
        {isLoadingDialog && (
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              color: '#fff',
              zIndex: theme => theme.zIndex.drawer + 1,
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
              }}
            >
              <CircularProgress color="primary" />
              <Typography color="primary">Enviando...</Typography>
            </Box>
          </Box>
        )}
      </Dialog>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={downloadingCount}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessageCount}</DialogTitle>
            <LinearProgressWithLabel value={progressCount} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <Dialog
          open={openResetReprocess}
          onClose={() => setOpenResetReprocess(false)}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <Typography variant="h6">Deseja reprocessar a contagem?</Typography>{' '}
            <Typography variant="body2">
              Este processo fará uma limpeza nas quantidades editada para envio ao ERP.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button disabled={isLoadingDialog} variant="outlined" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              disabled={isLoadingDialog}
              startIcon={isLoadingDialog && <CircularProgress size={20} />}
              onClick={handleResetProcess}
              variant="contained"
              type="submit"
            >
              {isLoadingDialog ? 'Enviando...' : 'Enviar'}
            </Button>
          </DialogActions>
        </Dialog>
        <ViewElearning
          pageName={'ReportCountStock'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Auditoria de estoque</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <Help onClick={() => setViewElearning(true)} />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: '100%',
            alignItems: 'start',
            justifyContent: 'space-between',
            mb: 2,
            mt: 2,
            gap: 2,
          }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                label="Selecione a filial"
                value={subCompanyId}
                onChange={e => {
                  getOrders(e)
                  setSubCompanyId(e.target.value)
                }}
                sx={{ width: '100%' }}
              >
                {subCompany.map((item, index) => {
                  return (
                    <MenuItem value={item.subCompanyId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel id="demo-simple-select-label">Deposito</InputLabel>
              <Select
                label="Selecione a filial"
                value={depositId}
                onChange={e => setDepositId(e.target.value)}
                sx={{ width: '100%' }}
              >
                {deposit.map((item, index) => {
                  return (
                    <MenuItem value={item.depositId} key={index}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <Grid item xs={12} md={12}>
              {orders && (
                <>
                  <InputLabel id="demo-simple-select-label">Pedido</InputLabel>
                  <Autocomplete
                    multiple
                    value={orderSelect}
                    onChange={handleChange}
                    options={orders}
                    noOptionsText={'Nenhum resultado encontrado'}
                    getOptionLabel={option => option.movimentationOrderNumberOtherSystem || ''}
                    renderInput={params => (
                      <TextField
                        {...params}
                        error={errorMessage !== ''}
                        helperText={errorMessage}
                      />
                    )}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ flexDirection: { xs: 'column', md: 'row' }, gap: 2 }}
        >
          <Button
            sx={{ width: { xs: '100%', md: 'inherit' } }}
            variant="contained"
            onClick={getCountStock}
            disabled={!orderSelect}
          >
            Pesquisar
          </Button>
          {orders && (
            <PermissionComponent role="Button_Confirmar_SendStock">
              <Box display="flex" gap={2}>
                <Button
                  sx={{ width: { xs: '100%', md: 'inherit' } }}
                  variant="outlined"
                  onClick={() => setOpenResetReprocess(true)}
                >
                  Reprocessar contagem RFID
                </Button>
                <Button
                  sx={{ width: { xs: '100%', md: 'inherit' } }}
                  variant="contained"
                  onClick={() => setOpenDialogErp(true)}
                >
                  Enviar contagem para o ERP
                </Button>
              </Box>
            </PermissionComponent>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            mb: 2,
          }}
        >
          {listCompare.length > 0 && (
            <>
              <Grid container spacing={2} my={2}>
                <Grid item xs={12} md={12}>
                  <Typography variant="h6">
                    {filteredList.length > 1
                      ? filteredList.length + ' Resultados'
                      : filteredList.length + ' Resultado'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField
                    label="Filtrar por Código ERP"
                    value={filterIdProductPartner}
                    onChange={e => setFilterIdProductPartner(e.target.value)}
                    variant="outlined"
                    size="small"
                    sx={{ minWidth: 200 }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="filter-select-label">Filtrar por Ordem</InputLabel>
                    <Select
                      labelId="filter-select-label"
                      id="filter-select"
                      value={filter}
                      size="small"
                      label="Filtrar por Ordem"
                      fullWidth
                      onChange={handleFilterChange}
                    >
                      <MenuItem value="">
                        <em>Todos</em>
                      </MenuItem>
                      {orderSelect.map((item, idx) => (
                        <MenuItem key={idx} value={item.movimentationOrderNumberOtherSystem}>
                          {item.movimentationOrderNumberOtherSystem}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    endIcon={<AiFillFileExcel />}
                    onClick={downloadData}
                  >
                    Excel
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
        <TableContainer component={Paper} sx={{ mt: '2rem' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Código ERP</StyledTableCell>
                <StyledTableCell align="left">Nome do Produto</StyledTableCell>
                <StyledTableCell align="center">Pais</StyledTableCell>
                <StyledTableCell align="center">Quantidade no ERP</StyledTableCell>
                {orderSelect
                  .filter(
                    item => !filterOrder || item.movimentationOrderNumberOtherSystem === filterOrder
                  )
                  .map((item, index) => (
                    <StyledTableCell key={index} align="center">
                      {item.movimentationOrderNumberOtherSystem}
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            {filteredList.length > 0 && (
              <TableBody>
                {filteredList.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left">{row.idProductPartner}</StyledTableCell>
                    <StyledTableCell align="left">{row.productName}</StyledTableCell>
                    <StyledTableCell align="center">{row.country}</StyledTableCell>
                    <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                    {orderSelect
                      .filter(
                        item =>
                          !filterOrder || item.movimentationOrderNumberOtherSystem === filterOrder
                      )
                      .map((item, idx) => (
                        <StyledTableCell key={idx} align="center">
                          {editingId === row.idProductPartner + item.movimentationOrderId ? (
                            <>
                              <TextField
                                inputRef={el => (inputRefs.current[item.movimentationOrderId] = el)}
                                type="number"
                                size="small"
                                value={
                                  editedValues[item.movimentationOrderId]?.[
                                    row.idProductPartner
                                  ] !== undefined
                                    ? editedValues[item.movimentationOrderId][row.idProductPartner]
                                    : row.stockCounts[item.movimentationOrderId] ?? 0
                                }
                                onChange={e =>
                                  handleInputChange(
                                    item.movimentationOrderId,
                                    row,
                                    Number(e.target.value)
                                  )
                                }
                                inputProps={{ min: 0 }}
                                error={
                                  !!errorMessages[row.idProductPartner + item.movimentationOrderId]
                                } // Mostra erro se existir
                                helperText={
                                  errorMessages[row.idProductPartner + item.movimentationOrderId]
                                } // Exibe a mensagem de erro
                                disabled={
                                  loadingInput[row.idProductPartner + item.movimentationOrderId]
                                } // Desabilita o campo se estiver enviando
                                InputProps={{
                                  endAdornment: loadingInput[
                                    row.idProductPartner + item.movimentationOrderId
                                  ] ? (
                                    <CircularProgress color="info" size={20} />
                                  ) : null,
                                }}
                              />

                              <IconButton
                                onClick={() => handleSave(row, item.movimentationOrderId)}
                              >
                                <Save />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              {row.stockCounts[item.movimentationOrderId] || 0}
                              <IconButton
                                onClick={() =>
                                  handleEditClick(item.movimentationOrderId, row.idProductPartner)
                                }
                              >
                                <Edit />
                              </IconButton>

                              {/* Exibir ícone de check se o item foi salvo */}
                              {savedItems[row.idProductPartner + item.movimentationOrderId] && (
                                <IconButton disabled>
                                  <CheckCircle color="success" sx={{ marginLeft: 8 }} />
                                </IconButton>
                              )}
                            </>
                          )}
                        </StyledTableCell>
                      ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default StockAudit
