import axios from 'axios'

export const userLogin = async user => {
  const response = await axios.post(`/api/Account/Login`, {
    email: user.email,
    password: user.password,
  })

  return response
}

export const userLoginSubdomain = async (user, nameUrl) => {
  const response = await axios.post(`${nameUrl}/api/Account/Login`, {
    email: user.email,
    password: user.password,
  })

  return response
}

export const forgotPassword = async (token, nameUrl, user) => {
  const response = await axios.post(
    `${nameUrl}/api/Account/ForgotPassword`,
    {
      email: user.email,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const resetPassword = async (nameUrl, user) => {
  const response = await axios.post(
    `${nameUrl}/api/Account/ResetPassword`,
    {
      token: user.token,
      email: user.email,
      password: user.password,
      confirmPassword: user.confirmPassword,
    },
    {
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
    }
  )
  return response
}

export const getUser = async (token, nameUrl) => {
  const response = await axios.get(`/${nameUrl}/api/Account/Get`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const userUpdate = async (token, nameUrl, user) => {
  const response = await axios.post(`/${nameUrl}/api/Account/Update`, user, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
  return response
}

export const consultaCEPService = async cep => {
  const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
  return response
}

export const consultaCEPGeo = async cep => {
  const response = await axios.get(
    `https://nominatim.openstreetmap.org/search?format=json&postalcode=${cep}`
  )
  return response
}

export const getCompany = async (token, nameUrl, companyId) => {
  const response = await axios.get(`/${nameUrl}/api/Company/Get/${companyId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const editCompany = async (token, nameUrl, values) => {
  const response = await axios.post(`/${nameUrl}/api/Company/Edit`, values, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}
