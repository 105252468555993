import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useContext, useEffect, useState } from 'react'
import { editCompany, getCompany } from '../../../services/api'
import { Context } from '../../../context/AuthContext'
import { useFormik } from 'formik'

const MyCompany = () => {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const vertical = 'top'
  const horizontal = 'center'
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('')
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      address: '',
      cep: '',
      city: '',
      cnpj: '',
      colorMenu: '',
      colorPrimary: '',
      colorSecondary: '',
      ddd: '',
      email: '',
      mobile: '',
      name: '',
      urlLogo: '',
      code: '',
      segmentId: '',
      planId: '',
      sizeLogoMenu: '',
      sizeLogoSideMenu: '',
      fontColorMenuPrimary: '',
      fontColorMenuSecondary: '',
      faviconUrl: '',
    },
    onSubmit: async values => {
      setLoading(true)
      values.companyId = subCompany[0].companyId
      console.log(values, 'valor')
      try {
        await editCompany(token, nameUrl, values)
        setTitle('Dados atualizados com sucesso!')
        setSeverity('success')
        setOpen(true)
        handleGetCompany()
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      } catch (e) {
        setTitle('Erro ao atualizar, verifique os dados e tente novamente')
        setSeverity('error')
        setOpen(true)
        console.log(e)
      }
      setLoading(false)
    },
  })

  useEffect(() => {
    handleGetCompany()
  }, [])

  const handleGetCompany = async () => {
    setLoading(true)
    try {
      const response = await getCompany(token, nameUrl, subCompany[0].companyId)
      formik.setValues({
        address: response.data.address,
        cep: response.data.cep,
        city: response.data.city,
        cnpj: response.data.cnpj,
        colorMenu: response.data.colorMenu ? response.data.colorMenu : '#F1F9FF',
        colorPrimary: response.data.colorPrimary ? response.data.colorPrimary : '#3D5D84',
        colorSecondary: response.data.colorSecondary ? response.data.colorSecondary : '#1976d2',
        ddd: response.data.ddd,
        email: response.data.email,
        mobile: response.data.mobile,
        name: response.data.name,
        urlLogo: response.data.urlLogo,
        code: response.data.sigla ? response.data.sigla : response.data.code,
        segmentId: response.data.segmentId,
        planId: response.data.planId,
        sizeLogoMenu: response.data.sizeLogoMenu,
        sizeLogoSideMenu: response.data.sizeLogoSideMenu,
        faviconUrl: response.data.faviconUrl,
        letterPrimary: response.data.letterPrimary ? response.data.letterPrimary : '#212121',
        letterSecondary: response.data.letterSecondary ? response.data.letterSecondary : '#212121',
        fontColorMenuPrimary: response.data.fontColorMenuPrimary
          ? response.data.fontColorMenuPrimary
          : '#212121',
        fontColorMenuSecondary: response.data.fontColorMenuSecondary
          ? response.data.fontColorMenuSecondary
          : '#212121',
      })

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box sx={{ mt: 7 }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {title}
        </Alert>
      </Snackbar>
      <Typography variant="h5">Minha Empresa</Typography>
      <Box
        sx={{ alignItems: 'center', justifyContent: 'space-evenly', width: '100%', mt: 2 }}
        flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
      >
        <Box
          width={{ xs: '90%', sm: '90%', md: '60%', lg: '60%', xl: '60%' }}
          sx={{
            mt: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
          }}
        >
          <Paper
            elevation={3}
            sx={{
              textAlign: 'center',
              padding: 2,
              width: '100%',
            }}
          >
            <Typography variant="h5" sx={{ mb: 5 }}>
              Alterar dados
            </Typography>
            <Box component="form" sx={{ mt: '2rem' }} onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  display: 'flex',
                }}
                flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
              >
                <Box
                  sx={{ mt: 2 }}
                  width={{ xs: '90%', sm: '90%', md: '45%', lg: '45%', xl: '45%' }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={formik.values.name}
                    name="name"
                    onChange={formik.handleChange}
                    id="outlined-basic"
                    label="Nome"
                    variant="outlined"
                    type="text"
                  />
                </Box>
                <Box
                  sx={{ mt: 2 }}
                  width={{ xs: '90%', sm: '90%', md: '45%', lg: '45%', xl: '45%' }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={formik.values.cnpj}
                    name="cnpj"
                    onChange={formik.handleChange}
                    id="outlined-basic"
                    label="CNPJ"
                    variant="outlined"
                    type="text"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  display: 'flex',
                }}
                flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
              >
                <Box
                  sx={{ mt: 2 }}
                  width={{ xs: '90%', sm: '90%', md: '45%', lg: '45%', xl: '45%' }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={formik.values.address}
                    name="address"
                    onChange={formik.handleChange}
                    id="outlined-basic"
                    label="Endereço"
                    variant="outlined"
                    type="text"
                  />
                </Box>
                <Box
                  sx={{ mt: 2 }}
                  width={{ xs: '90%', sm: '90%', md: '45%', lg: '45%', xl: '45%' }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={formik.values.cep}
                    name="cep"
                    onChange={formik.handleChange}
                    id="outlined-basic"
                    label="Cep"
                    variant="outlined"
                    type="text"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  display: 'flex',
                }}
                flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
              >
                <Box
                  sx={{ mt: 2 }}
                  width={{ xs: '90%', sm: '90%', md: '45%', lg: '45%', xl: '45%' }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={formik.values.city}
                    name="city"
                    onChange={formik.handleChange}
                    id="outlined-basic"
                    label="Cidade"
                    variant="outlined"
                    type="text"
                  />
                </Box>
                <Box
                  sx={{ mt: 2 }}
                  width={{ xs: '90%', sm: '90%', md: '45%', lg: '45%', xl: '45%' }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={formik.values.email}
                    name="email"
                    onChange={formik.handleChange}
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    type="text"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  display: 'flex',
                }}
                flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
              >
                <Box
                  sx={{ mt: 2 }}
                  width={{ xs: '90%', sm: '90%', md: '45%', lg: '45%', xl: '45%' }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={formik.values.ddd}
                    name="ddd"
                    onChange={formik.handleChange}
                    id="outlined-basic"
                    label="DDD"
                    variant="outlined"
                    type="text"
                  />
                </Box>
                <Box
                  sx={{ mt: 2 }}
                  width={{ xs: '90%', sm: '90%', md: '45%', lg: '45%', xl: '45%' }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={formik.values.mobile}
                    name="mobile"
                    onChange={formik.handleChange}
                    id="outlined-basic"
                    label="Celular"
                    variant="outlined"
                    type="text"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  display: 'flex',
                }}
                flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
              >
                <Box
                  sx={{ mt: 2 }}
                  width={{ xs: '90%', sm: '90%', md: '45%', lg: '45%', xl: '45%' }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={formik.values.colorPrimary}
                    name="colorPrimary"
                    onChange={formik.handleChange}
                    id="outlined-basic"
                    label="Cor primária"
                    variant="outlined"
                    type="color"
                    helperText="Header, Footer, Inputs"
                  />
                </Box>
                <Box
                  sx={{ mt: 2 }}
                  width={{ xs: '90%', sm: '90%', md: '45%', lg: '45%', xl: '45%' }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={formik.values.colorSecondary}
                    name="colorSecondary"
                    onChange={formik.handleChange}
                    id="outlined-basic"
                    label="Cor secundária"
                    variant="outlined"
                    type="color"
                    helperText="Botões"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  display: 'flex',
                }}
                flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
              >
                <Box
                  sx={{ mt: 2 }}
                  width={{ xs: '90%', sm: '90%', md: '45%', lg: '45%', xl: '45%' }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={formik.values.colorMenu}
                    name="colorMenu"
                    onChange={formik.handleChange}
                    id="outlined-basic"
                    label="Cor do menu"
                    variant="outlined"
                    type="color"
                    helperText="Cor de fundo menu lateral"
                  />
                </Box>
                <Box
                  sx={{ mt: 2 }}
                  width={{ xs: '90%', sm: '90%', md: '45%', lg: '45%', xl: '45%' }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={formik.values.urlLogo}
                    name="urlLogo"
                    onChange={formik.handleChange}
                    id="outlined-basic"
                    label="Logo"
                    variant="outlined"
                    type="text"
                    helperText="Logo da empresa"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  display: 'flex',
                }}
                flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
              >
                <Box
                  sx={{ mt: 2 }}
                  width={{ xs: '90%', sm: '90%', md: '45%', lg: '45%', xl: '45%' }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={formik.values.letterPrimary}
                    name="letterPrimary"
                    onChange={formik.handleChange}
                    id="outlined-basic"
                    label="Letra Primária"
                    variant="outlined"
                    type="color"
                    helperText="Letras principais do sistema"
                  />
                </Box>
                <Box
                  sx={{ mt: 2 }}
                  width={{ xs: '90%', sm: '90%', md: '45%', lg: '45%', xl: '45%' }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={formik.values.letterSecondary}
                    name="letterSecondary"
                    onChange={formik.handleChange}
                    id="outlined-basic"
                    label="Letra Secundária"
                    variant="outlined"
                    type="color"
                    helperText="Cor das letras dos inputs não selecionados"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  display: 'flex',
                }}
                flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
              >
                <Box
                  sx={{ mt: 2 }}
                  width={{ xs: '90%', sm: '90%', md: '45%', lg: '45%', xl: '45%' }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={formik.values.fontColorMenuPrimary}
                    name="fontColorMenuPrimary"
                    onChange={formik.handleChange}
                    id="outlined-basic"
                    label="Letra primaria menu"
                    variant="outlined"
                    type="color"
                    helperText="Cor das letras menu lateral"
                  />
                </Box>
                <Box
                  sx={{ mt: 2 }}
                  width={{ xs: '90%', sm: '90%', md: '45%', lg: '45%', xl: '45%' }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={formik.values.fontColorMenuSecondary}
                    name="fontColorMenuSecondary"
                    onChange={formik.handleChange}
                    id="outlined-basic"
                    label="Cor letra botões"
                    variant="outlined"
                    type="color"
                    helperText="Cor das letras dos botões"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  display: 'flex',
                }}
                flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
              >
                <Box
                  sx={{ mt: 2 }}
                  width={{ xs: '90%', sm: '90%', md: '45%', lg: '45%', xl: '45%' }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={formik.values.sizeLogoMenu}
                    name="sizeLogoMenu"
                    onChange={formik.handleChange}
                    id="outlined-basic"
                    label="Tamanho logo header"
                    variant="outlined"
                    type="text"
                  />
                  <Typography textAlign="start" variant="caption" color="#ccc">
                    OBS: Valor maximo 150px
                  </Typography>
                </Box>
                <Box
                  sx={{ mt: 2 }}
                  width={{ xs: '90%', sm: '90%', md: '45%', lg: '45%', xl: '45%' }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={formik.values.sizeLogoSideMenu}
                    name="sizeLogoSideMenu"
                    onChange={formik.handleChange}
                    id="outlined-basic"
                    label="Tamanho logo menu lateral"
                    variant="outlined"
                    type="text"
                  />
                  <Typography textAlign="start" variant="caption" color="#ccc">
                    OBS: Valor maximo 70px
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  display: 'flex',
                }}
                flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
              >
                <Box
                  sx={{ mt: 2 }}
                  width={{ xs: '90%', sm: '90%', md: '45%', lg: '45%', xl: '45%' }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={formik.values.faviconUrl}
                    name="faviconUrl"
                    onChange={formik.handleChange}
                    id="outlined-basic"
                    label="Favicon"
                    variant="outlined"
                    type="text"
                  />
                </Box>
                <Box
                  sx={{ mt: 2 }}
                  width={{ xs: '90%', sm: '90%', md: '45%', lg: '45%', xl: '45%' }}
                >
                  <TextField
                    sx={{ width: '100%' }}
                    value={formik.values.code}
                    name="code"
                    onChange={formik.handleChange}
                    id="outlined-basic"
                    label="Sigla"
                    variant="outlined"
                    type="text"
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                  mt: 3,
                  justifyContent: 'space-evenly',
                }}
              >
                <Button type="submit" variant="contained" sx={{ width: '100%', maxWidth: '400px' }}>
                  Enviar
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}

export default MyCompany
