import { useCallback, useContext, useEffect, useState } from 'react'
import { deleteParamGroupService, getParamGroupService } from '../../../services/admServices'
import { Context } from '../../../context/AuthContext'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import HelpIcon from '@mui/icons-material/Help'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Paper,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Tooltip,
} from '@mui/material'
import ParametersGroupCreate from '../../Create/ParametersGroupCreate'
import ParametersGroupEdit from '../../Edit/ParametersGroupEdit'
import { AiFillPlusCircle } from 'react-icons/ai'

//icones react-icons
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'
import PermissionComponent from '../../../permissions'

const ParametersGroup = () => {
  const token = localStorage.getItem('token')
  const [paramGroup, setParamGroup] = useState([])
  const [pagination, setPagination] = useState({})
  const [paramGroupLimit, setParamGroupLimit] = useState(10)
  const [paramGroupOffset, setParamGroupOffset] = useState(0)
  const [paramGroupOrder, setParamGroupOrder] = useState(false)

  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [idEditing, setIdEditing] = useState('')
  const [viewElearning, setViewElearning] = useState(false)

  const { userRoles, nameUrl } = useContext(Context)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getParamGroup = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getParamGroupService(
        token,
        paramGroupLimit,
        paramGroupOffset,
        paramGroupOrder
      )
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setParamGroup(response.data.result)
        setPagination(response.data)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [paramGroupOrder])

  useEffect(() => {
    getParamGroup()
  }, [paramGroupOrder])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleEdit = id => {
    setIdEditing(id)
    setExpanded(true)
    setEditing(true)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    try {
      await deleteParamGroupService(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Grupo de Parametros deletado com sucesso!')

      setAlerta(true)
      setLoading(false)
      getParamGroup()
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <ViewElearning
          pageName={'ParametersGroup'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Grupo de Parâmetros</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon onClick={() => setViewElearning(true)} />
          </IconButton>
        </Box>

        {/* Criar  */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>
              {editing ? 'Editando Grupo de Parâmetros' : 'Cadastrar Grupo de Parâmetros'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="ParametersGroupEDIT">
                <ParametersGroupEdit
                  setEditing={setEditing}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  paramGroupId={idEditing}
                  setExpanded={setExpanded}
                  handleFetchData={getParamGroup}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="ParametersGroupCREATE">
                <ParametersGroupCreate
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={getParamGroup}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>
        <Box
          sx={{
            mt: 3,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', ml: 1 }}
          >
            <PermissionComponent role="ParametersGroupCREATE">
              <Button
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
                variant="contained"
                endIcon={<AiFillPlusCircle />}
              >
                Criar
              </Button>
            </PermissionComponent>
          </Box>
        </Box>
        {/* Deletar */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>

        <Box
          sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
        ></Box>
        {paramGroup.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell> </StyledTableCell>
                  <StyledTableCell align="left">Nome</StyledTableCell>
                  <StyledTableCell align="left">Descrição</StyledTableCell>
                  <StyledTableCell align="left">Ativo</StyledTableCell>
                  <StyledTableCell align="left">Criado</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paramGroup
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row" sx={{ width: 100 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'left',
                            justifyContent: 'space-between',
                            width: '80px',
                          }}
                        >
                          <PermissionComponent role="ParametersGroupDELETE">
                            <Tooltip title="Apagar" arrow>
                              <IconButton
                                onClick={() => deleteAlert(row.parameterGroupId)}
                                sx={{ cursor: 'pointer' }}
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                            </Tooltip>
                          </PermissionComponent>
                          <PermissionComponent role="ParametersGroupEDIT">
                            <Tooltip title="Editar" arrow>
                              <IconButton
                                onClick={() => handleEdit(row.parameterGroupId)}
                                sx={{ cursor: 'pointer' }}
                              >
                                <ModeEditOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </PermissionComponent>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.name}</StyledTableCell>
                      <StyledTableCell align="left">{row.description}</StyledTableCell>
                      <StyledTableCell align="left">{row.active ? 'Sim' : 'Não'}</StyledTableCell>
                      <StyledTableCell align="left">
                        {new Date(row.created).toLocaleString()}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={paramGroup.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ParametersGroup
