import React, { useContext, useState, useRef } from 'react'
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Modal,
  Snackbar,
  Divider,
} from '@mui/material'
import { Search, Add, Remove, ShoppingCart } from '@mui/icons-material'
import { styled } from '@mui/system'
import { createSalesBucket, getProductCodService } from '../../../services/productService'
import { Context } from '../../../context/AuthContext'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${TableCell.head}`]: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
  },
  [`&.${TableCell.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const formatToBRL = value => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value)
}

const Pdv = () => {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [subCompanyCNPJ, setSubCompanyCNPJ] = useState('')
  const [product, setProduct] = useState('')
  const [products, setProducts] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [cart, setCart] = useState([])
  const [rfidList, setRfidList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')
  const [responseMessageInput, setResponseMessageInput] = useState('')

  const searchFieldRef = useRef(null)

  const handleSearchChange = e => {
    setSearchTerm(e.target.value)
  }

  const calculateTotal = () => {
    return products
      .reduce(
        (total, item) =>
          total + item.quantity * parseFloat(item.price.replace('R$ ', '').replace(',', '.')),
        0
      )
      .toFixed(2)
  }

  const handleGetProductCodService = async event => {
    try {
      setIsLoading(true)
      const data = {
        subCompanyId: subCompanyId,
        rfidCode: searchTerm,
      }

      const response = await getProductCodService(token, nameUrl, data)
      if (response.data) {
        const productWithQuantity = { ...response.data, quantity: 1 }
        setProducts(prev => [...prev, productWithQuantity])
        handleCloseModal()
      }

      setRfidList(prevList => [...prevList, searchTerm])
      setSearchTerm('')
      searchFieldRef.current.focus()
    } catch (error) {
      setResponseMessageInput('Codigo RFID não associado!')

      console.error('Erro ao buscar o produto:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleRemoveFromCart = id => {
    setProducts(products.filter(item => item.productItensId !== id))
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleGetProductCodService()
    }
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const groupProductsById = products => {
    const groupedProducts = products.reduce((acc, item) => {
      const existingProduct = acc.find(p => p.idProductPartner === item.idProductPartner)
      if (existingProduct) {
        existingProduct.quantity += item.quantity
      } else {
        acc.push({ ...item })
      }
      return acc
    }, [])

    return groupedProducts
  }

  const handleFinish = async () => {
    setLoading(true)
    const data = products.map(item => ({
      dateProcess: new Date().toISOString(),
      orderPdv: '-',
      cashPaymentValue: 0,
      creditCardPaymentValue: 0,
      debitCardPaymentValue: 0,
      priceDiscountValue: 0,
      priceUnitValue: calculateTotal(),
      subTotal: calculateTotal(),
      paymentMethod: '-',
      storeAttendantName: '-',
      typeSale: '-',
      paymentCondition: '-',
      status: '-',
      customer: '-',
      productId: item.productId,
      productName: item.productName,
      changeAmount: '-',
      subCompanyCNPJ: subCompanyCNPJ,
      codeRFID: item.serial,
    }))

    try {
      const response = await createSalesBucket(token, nameUrl, data)
      setResponseMessage(response.message || 'Venda finalizada com sucesso!')
      setProducts([])
    } catch (error) {
      setResponseMessage('Erro ao finalizar a venda!')
      console.error('Erro ao finalizar a venda:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box sx={{ mt: 5.5 }}>
      <Typography variant="h4" gutterBottom>
        Ponto de Venda (PDV)
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <InputLabel id="demo-simple-select-label">Filial</InputLabel>
          <Select
            label="Filial Saída"
            value={subCompanyId}
            onChange={values => {
              const cnpj = subCompany.find(item => item.subCompanyId === values.target.value)
              setSubCompanyCNPJ(cnpj.cnpjCompany, 'cnsda')
              setSubCompanyId(values.target.value)
            }}
            sx={{ width: '100%' }}
          >
            {subCompany.map((item, index) => (
              <MenuItem value={item.subCompanyId} key={index}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardContent sx={{ p: 0 }}>
              <Typography variant="h6" gutterBottom p={2}>
                Codigo Rfid
              </Typography>
              <Box px={2}>
                <TextField
                  error={!!responseMessageInput}
                  helperText={responseMessageInput}
                  inputRef={searchFieldRef}
                  autoFocus
                  fullWidth
                  variant="outlined"
                  placeholder="Pesquisar produto"
                  value={searchTerm}
                  disabled={isLoading || !subCompanyId}
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyPress}
                  InputProps={{
                    endAdornment: isLoading ? (
                      <CircularProgress size={24} color="warning" />
                    ) : (
                      <IconButton>
                        <Search />
                      </IconButton>
                    ),
                  }}
                />
              </Box>
              {open && (
                <Box px={2}>
                  <Card
                    variant="outlined"
                    sx={{
                      bgcolor: 'background.paper',
                      borderRadius: 1,
                      p: 4,
                      mt: 2,
                    }}
                  >
                    {selectedProduct && (
                      <>
                        <Typography variant="h6" gutterBottom>
                          {selectedProduct.productName}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          Preço: R$ {selectedProduct.price}
                        </Typography>
                      </>
                    )}
                  </Card>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <Typography variant="h6" gutterBottom p={2}>
              Carrinho de Compras
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Código</StyledTableCell>
                    <StyledTableCell>Produto</StyledTableCell>
                    <StyledTableCell>Quantidade</StyledTableCell>
                    <StyledTableCell>Preço</StyledTableCell>
                    <StyledTableCell>Ação</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupProductsById(products).map(item => (
                    <StyledTableRow key={item.idProductPartner}>
                      <StyledTableCell>{item.idProductPartner}</StyledTableCell>
                      <StyledTableCell>{item.productName}</StyledTableCell>
                      <StyledTableCell>{item.quantity}</StyledTableCell>
                      <StyledTableCell>
                        {formatToBRL(
                          parseFloat(item.price.replace('R$ ', '').replace(',', '.')) *
                            item.quantity
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton
                          onClick={() => handleRemoveFromCart(item.productItensId)}
                          color="error"
                        >
                          <Remove />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider />
            <Box
              bgcolor="#f5f5f5"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={2}
            >
              <Typography variant="h6">Total: {formatToBRL(calculateTotal())}</Typography>

              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={
                  loading ? <CircularProgress size={24} color="inherit" /> : <ShoppingCart />
                }
                onClick={handleFinish}
                disabled={loading || products.length === 0}
              >
                {loading ? 'Finalizando...' : 'Finalizar Compra'}
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        open={Boolean(responseMessage)}
        autoHideDuration={3000}
        onClose={() => setResponseMessage('')}
        message={responseMessage}
      />
    </Box>
  )
}

export default Pdv
