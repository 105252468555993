import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { ClientDelete, ClientEdit, ClientCreate } from '../../../const/RoleConstants'
import HelpIcon from '@mui/icons-material/Help'
import { setPermission } from '../../../permissions/userPermissions'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Tooltip,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Skeleton,
  InputAdornment,
} from '@mui/material'
import { AiFillPlusCircle } from 'react-icons/ai'

//icones react-icons
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import { deleteClient, getClient } from '../../../services/clientService'
import ClientPCreate from '../../Create/ClientCreate'
import ClientPEdit from '../../Edit/ClientPEdit'
import PermissionComponent from '../../../permissions'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'
import CreateSensorAndPerson from '../../Create/CreateSensorAndPerson'
import {
  deleteSensorAndPerson,
  getAllGatewayLocation,
  searchProductSubCompanyService,
} from '../../../services/productService'
import { useDebounce } from '../Product/Components/useDebounce'
import { DeleteForever, ModeEditOutline, Search } from '@mui/icons-material'
import SensorAndPersonEdit from '../../Edit/SensorAndPersonEdit'
import GatewayLocationCreate from '../../Create/GatewayLocationCreate'
import GatewayLocationEdit from '../../Edit/GatewayLocationEdit'
import { deleteAlocationStockService } from '../../../services/alocationServices'

const GatewayLocation = () => {
  const token = localStorage.getItem('token')

  const { subCompany, userRoles, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [idEditing, setIdEditing] = useState({})

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)
  const [filterTable, setFilterTable] = useState('')
  const [filterCNPJ, setFilterCNPJ] = useState('')
  const [clientList, setClientList] = useState([])
  const [filterRow, setFilterRow] = useState([])
  const [notSearch, setNotSearch] = useState(false)
  const [search, setSearch] = useState('')
  const [gatwayLocation, setGatwayLocation] = useState([])
  const [totalProducts, setTotalProducts] = useState(0)
  const [searchR, setSearchR] = useState('')
  const [selectedId, setSelectedId] = useState(undefined)
  const [currentPage, setCurrentPage] = useState(0)
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleGetAll = async subCompanyId => {
    setSubCompanyId(subCompanyId)
    setLoading(true)
    const dataProduct = {
      subCompanyId: subCompanyId,
      limit: 500,
      offset: 0,
      order: 'desc',
    }

    try {
      const response = await getAllGatewayLocation(token, nameUrl, dataProduct)
      const data = response.data.result
      setGatwayLocation(data)
      setTotalProducts(response.data.result.length)
    } catch (error) {
      console.error('Error fetching gatwayLocation:', error)
      setGatwayLocation([])
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setCurrentPage(0)
    if (subCompanyId) {
      handleGetAll(subCompanyId)
    }
  }, [subCompanyId])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleEdit = item => {
    setIdEditing(item)
    setExpanded(true)
    setEditing(true)
    window.scrollTo(0, 0)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)

    try {
      await deleteAlocationStockService(token, nameUrl, idDelete)

      handleGetAll(subCompanyId)
      setTitle('Item apagado com sucesso.')
      setSeverity('success')
      setAlerta(true)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  const filter = () => {
    const filtered = gatwayLocation.filter(row => {
      const address = row.address ? row.address.toLowerCase() : ''
      const serial = row.serial ? row.serial.toLowerCase() : ''
      return address.includes(search.toLowerCase()) || serial.includes(search.toLowerCase())
    })

    setPage(0)
    setFilterRow(filtered)
  }

  useEffect(() => {}, [idEditing])

  useEffect(() => {
    filter()
  }, [gatwayLocation, search])

  return (
    <>
      <Box sx={{ mt: 7.5, height: 'fit-content' }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Localização do gateway</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon />
          </IconButton>
        </Box>
        {/* Criar  */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>
              {editing ? 'Editando de pessoa e sensor' : 'Cadastrar de pessoa e sensor'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="RTLS">
                <GatewayLocationEdit
                  setEditing={setEditing}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  setExpanded={setExpanded}
                  subCompanyIdItem={subCompanyId}
                  id={idEditing}
                  handleFetchData={() => handleGetAll(subCompanyId)}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="RTLS">
                <GatewayLocationCreate
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>

        <SelectSubCompany
          value={subCompanyId}
          onChange={e => {
            handleGetAll(e.target.value)
          }}
        />
        <Box
          sx={{
            mt: 3,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              ml: 1,
            }}
          >
            <PermissionComponent role="RTLS">
              <Button
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
                variant="contained"
                endIcon={<AiFillPlusCircle />}
              >
                Criar
              </Button>
            </PermissionComponent>
          </Box>
        </Box>

        {/* Deletar */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>
        <Box width="100%">
          <TextField
            sx={{ width: '100%', maxWidth: '250px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: loading && (
                <InputAdornment position="end">
                  <CircularProgress color="info" size={20} />
                </InputAdornment>
              ),
            }}
            label="Pesquisar"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </Box>
        <TableContainer component={Paper}>
          <Typography sx={{ width: 1, my: 2 }}>
            {filterRow?.length > 1
              ? `${filterRow.length} Resultados`
              : `${filterRow.length} Resultado`}
          </Typography>

          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">MAC</StyledTableCell>
                <StyledTableCell align="left">Serial</StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              Array.from({ length: 10 }).map((_, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Array.from({ length: 3 }).map((_, colIndex) => (
                    <TableCell key={colIndex}>
                      <Skeleton animation="wave" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableBody>
                {filterRow
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{row.address}</StyledTableCell>
                      <StyledTableCell align="left">{row.serial}</StyledTableCell>
                      <StyledTableCell align="left" sx={{ width: '120px' }}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <Tooltip title="Apagar" arrow>
                            <IconButton>
                              <DeleteForever
                                onClick={() => deleteAlert(row.alocationStockId)}
                                sx={{ cursor: 'pointer' }}
                              />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Editar" arrow>
                            <IconButton>
                              <ModeEditOutline
                                onClick={() => handleEdit(row)}
                                sx={{ cursor: 'pointer' }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            )}
          </Table>
          <TablePagination
            labelRowsPerPage={'Itens por página'}
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filterRow.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default GatewayLocation
