import HelpIcon from '@mui/icons-material/Help'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import { Context } from '../../../context/AuthContext'
import {
  getCountStockService,
  getMovimentationConferenceService,
  getOnlyOrder,
} from '../../../services/reportServices'

import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputLabel,
  LinearProgress,
  Paper,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
//icones react-icons
import { ArrowBack } from '@mui/icons-material'
import { AiOutlineFileExcel } from 'react-icons/ai'
import '../../../assets/global.css'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'

const ReportConferenceActions = () => {
  const { subCompany, userRolesNew, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const [orders, setOrders] = useState()
  const [loading, setLoading] = useState(false)
  const [picking, setPicking] = useState('')
  const [orderSelect, setOrderSelect] = useState('')
  const token = localStorage.getItem('token')
  const [countStock, setCountStock] = useState([])
  const [pagination, setPagination] = useState({})
  const [listReportCount, setListReportCount] = useState([])
  const [countStockLimit, setCountStockLimit] = useState(10)
  const [countStockOrder, setCountStockOrder] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')

  const [progressCount, setProgressCount] = useState(0)
  const [downloadMessageCount, setDownloadMessageCount] = useState('Pronto')
  const [downloadingCount, setDownloadingCount] = useState(false)
  const [viewElearning, setViewElearning] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  useEffect(() => console.log(orderSelect, 'orderSelect'), [orderSelect])

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  }

  const downloadDataCount = async () => {
    setDownloadMessageCount('Inicializando')
    setDownloadingCount(true)
    finishDownloadCount(countStock.length)
  }

  const downloadDataCountList = async () => {
    setDownloadMessageCount('Inicializando')
    setDownloadingCount(true)
    finishDownloadCountList(listReportCount.length)
  }

  const finishDownloadCountList = async pages => {
    let result = []

    for (let i = 0; i <= pages; i++) {
      setDownloadMessageCount(`Baixando ${i} de ${pages}...`)
      setProgressCount((i / pages) * 100)
    }

    setDownloadMessageCount(`Seu arquivo está pronto.`)
    setDownloadingCount(false)
    const relatorioFinalContagem = []
    listReportCount.map(item => {
      relatorioFinalContagem.push({
        'Código ERP': item.idProductPartner,
        'Cód. Barras': item.barCode,
        'Nome do Produto': item.productName,
        Quantidade: item.quantityTotal,
        // Cor: item.color,
        // Tamanho: item.size,
        // Referencia: item.reference,
        // 'Número do palete': item.numberPallet,
        // 'Quantidade de palete contato': item.quantityPallet,
        // Picking: item.picking,
        // Lote: item.lote,
        // Posição: item.locationName,
        CNPJ: item.subCompanyCNPJ,
        // 'Data da Leitura': new Date(item.createDate).toLocaleString(),
        // 'Data de Expiração': new Date(item.createDate).toLocaleString(),
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinalContagem)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório de contagem')
    XLSX.writeFile(workbook, `Relatório de contagem.xlsx`)
    setProgressCount(0)
  }

  const finishDownloadCount = async pages => {
    let result = []

    for (let i = 0; i <= pages; i++) {
      setDownloadMessageCount(`Baixando ${i} de ${pages}...`)
      setProgressCount((i / pages) * 100)
    }
    const countStockDTO = {
      subCompanyId: subCompanyId,
      movimentationOrderId: orderSelect.movimentationOrderId,
    }
    const resp = await getCountStockService(token, nameUrl, countStockDTO)
    result = result.concat(resp.data)
    setDownloadMessageCount(`Seu arquivo está pronto.`)
    setDownloadingCount(false)
    const relatorioFinalContagem = []
    result.map(item => {
      const hasPermission = userRolesNew.some(
        permission => permission.rolesName === 'Fields_Report_CountStock'
      )

      const nomeProduto = hasPermission ? item.productName.split(' - ')[0] : item.productName

      const codigoBarras = hasPermission ? item.barCode : ''
      console.log(item, 'codigo')
      relatorioFinalContagem.push({
        'Nome do Produto': nomeProduto,
        'Codigo de barras': item.barCode,
        'Codigo do Local': item.picking,
        'Responsável matricula': item.responsibleEmployee,
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinalContagem)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório de contagem')
    XLSX.writeFile(workbook, `Relatório de contagem.xlsx`)
    setProgressCount(0)
  }

  const getCountStock = async () => {
    setLoading(true)
    const data = {
      subCompanyId: subCompanyId,
      picking: picking,
    }
    try {
      const response = await getMovimentationConferenceService(token, nameUrl, data)
      if (response.data.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setCountStock(response.data.movimentationOrderItemCollection)
        setPagination(response.data.movimentationOrderItemCollection)

        const productMapper = response.data.reduce((acc, curr) => {
          if (acc[curr.idProductPartner]) {
            acc[curr.idProductPartner].quantityTotal += curr.stockcount
          } else {
            acc[curr.idProductPartner] = { ...curr, quantityTotal: curr.stockcount }
          }
          return acc
        }, {})
        const listaFinal = Object.values(productMapper)
        setListReportCount(listaFinal)
        const total = listaFinal.reduce(
          (accumulator, currentValue) => accumulator + currentValue.stockcount,
          0
        )
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const getOrders = async value => {
    setSubCompanyId(value)
    try {
      const response = await getOnlyOrder(token, nameUrl, value)
      setOrders(response.data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (subCompanyId) {
      getOrders(subCompanyId)
    }
  }, [subCompanyId])

  const handleOrder = (event, newValue) => {
    setOrderSelect(newValue)

    if (newValue !== undefined && newValue !== null) {
      getOnlyOrder(token, nameUrl, newValue)
        .then(response => {
          setOrders(response.data)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  const handleClose = () => {
    setAlerta(false)
  }

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullScreen
        sx={{ m: 2 }}
      >
        <DialogTitle id="scroll-dialog-title">Relatório de contagem de ativos</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <TableContainer>
              <Box display="flex" alignItems="center" justifyContent="space-between" gap={2} my={2}>
                <Box display="flex" alignItems="center" justifyContent="start" gap={2} width="100%">
                  <Typography variant="h6" fontWeight={700}>
                    {`Total de produtos ${listReportCount.length}`}
                  </Typography>
                  <Typography variant="h6" fontWeight={700}>
                    {`Contagem total ${listReportCount.reduce(
                      (total, item) => total + item.quantityTotal,
                      0
                    )}`}
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: ' space-between',
                    fontSize: '.9em',
                    width: '150px',
                  }}
                  onClick={downloadDataCountList}
                >
                  Excel <AiOutlineFileExcel size="2em" />
                </Button>
              </Box>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Código ERP</StyledTableCell>
                    <StyledTableCell align="left">Nome do produto</StyledTableCell>
                    <StyledTableCell align="center">Quantidade total</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listReportCount
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="left">{row.idProductPartner}</StyledTableCell>
                        <StyledTableCell align="left">{row.productName}</StyledTableCell>
                        <StyledTableCell align="center">{row.quantityTotal}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={listReportCount.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ m: 2 }}>
          <Button variant="outlined" startIcon={<ArrowBack />} onClick={() => setOpenDialog(false)}>
            Voltar
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={downloadingCount}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessageCount}</DialogTitle>
            <LinearProgressWithLabel value={progressCount} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <ViewElearning
          pageName={'ReportCountStock'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Relatório de Contagem de ativos</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon onClick={() => setViewElearning(true)} />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 2,
            mt: 2,
            gap: 2,
          }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box width={{ xs: '100%', sm: '100%', md: '50%', lg: '50%', xl: '50%' }}>
            <SelectSubCompany
              value={subCompanyId}
              onChange={e => {
                getOrders(e.target.value)
              }}
            />
          </Box>

          <Box width={{ xs: '100%', sm: '100%', md: '50%', lg: '50%', xl: '50%' }}>
            <InputLabel id="demo-simple-select-label">Picking </InputLabel>
            <TextField fullWidth value={picking} onChange={e => setPicking(e.target.value)} />
          </Box>
        </Box>
        <Button variant="contained" onClick={getCountStock} disabled={!picking}>
          Pesquisar
        </Button>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
          }}
        >
          <Typography variant="h6">
            {countStock.length > 1
              ? `${countStock.length} Resultados`
              : `${countStock.length} Resultado`}
          </Typography>
          {countStock.length > 0 && (
            <Box display="flex" alignItems="center" justifyContent="end" gap={2}>
              <Button
                variant="contained"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: ' space-between',
                  fontSize: '.9em',
                  width: '150px',
                }}
                onClick={downloadDataCount}
              >
                Excel <AiOutlineFileExcel size="2em" />
              </Button>
            </Box>
          )}
        </Box>
        {countStock.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Nome do Produto</StyledTableCell>
                  <StyledTableCell align="left">Processo</StyledTableCell>
                  <StyledTableCell align="left">Quantidade</StyledTableCell>
                  <StyledTableCell align="left">Serial</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {countStock.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell align="left">{row.productName}</StyledTableCell>
                    <StyledTableCell align="left">{row.process}</StyledTableCell>
                    <StyledTableCell align="left">{row.quantity}</StyledTableCell>
                    <StyledTableCell align="left">{row.serial}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={countStock.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ReportConferenceActions
