import React, { useContext } from 'react'
import { useCallback, useEffect, useState } from 'react'
import HelpIcon from '@mui/icons-material/Help'
import {
  deleteMonitoringEquipmentService,
  getMonitoringEquipmentService,
} from '../../../services/configServices'
import { Context } from '../../../context/AuthContext'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'

import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  Paper,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
} from '@mui/material'
import MonitorCreate from '../../Create/MonitorCreate'
import MonitorEdit from '../../Edit/MonitorEdit'

//icones react-icons
import { AiFillPlusCircle } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'
import PermissionComponent from '../../../permissions'

const MonitoringEquipmentAutomatic = () => {
  const token = localStorage.getItem('token')
  const { nameUrl } = useContext(Context)
  const [monitor, setMonitor] = useState([])
  const [pagination, setPagination] = useState({})
  const [monitorLimit, setmonitorLimit] = useState(10)
  const [monitorOffset, setmonitorOffset] = useState(0)
  const [monitorOrder, setmonitorOrder] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)

  //constantes crud
  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [idEditing, setIdEditing] = useState('')
  const [viewElearning, setViewElearning] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getMonitor = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getMonitoringEquipmentService(
        token,
        monitorLimit,
        monitorOffset,
        monitorOrder
      )
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setMonitor(response.data.result)
        setPagination(response.data)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }, [monitorOrder])

  useEffect(() => {
    getMonitor()
  }, [monitorOrder])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const handleEdit = id => {
    setIdEditing(id)
    setExpanded(true)
    setEditing(true)
  }

  const deleteAlert = data => {
    setOpen(true)
    setIdDelete(data)
  }

  const handleDelete = async () => {
    setLoading(true)
    setOpen(false)
    try {
      await deleteMonitoringEquipmentService(token, nameUrl, idDelete)
      setSeverity('success')
      setTitle('Monitor Automático deletado com sucesso!')

      setAlerta(true)
      setLoading(false)
      getMonitor()
    } catch (e) {
      console.log(e)
      setSeverity('error')
      setTitle('Erro no sistema, tente novamente mais tarde.')
      setAlerta(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <ViewElearning
          pageName={'MonitoringEquipmentAutomatic'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Monitores Automáticos</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon onClick={() => setViewElearning(true)} />
          </IconButton>
        </Box>

        {/* Criar  */}
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Typography>
              {editing ? 'Editar Monitor Automático' : 'Cadastrar Monitores Automáticos'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {editing ? (
              <PermissionComponent role="Edit_MonitoringEquipment">
                <MonitorEdit
                  setEditing={setEditing}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  monitorId={idEditing}
                  handleFetchData={getMonitor}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent role="Create_MonitoringEquipment">
                <MonitorCreate
                  setExpanded={setExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={getMonitor}
                />
              </PermissionComponent>
            )}
          </AccordionDetails>
        </Accordion>

        {/* Deletar */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja excluir esse item?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDelete()}>Sim</Button>
          </DialogActions>
        </Dialog>
        <PermissionComponent role="Create_MonitoringEquipment">
          <Box
            sx={{
              mt: 2,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', ml: 1 }}
            >
              <button
                className="buttonDocs"
                onClick={() => {
                  setEditing(false)
                  setExpanded(true)
                }}
              >
                Criar <AiFillPlusCircle size="2em" className="iconMore" />
              </button>
            </Box>
          </Box>
        </PermissionComponent>

        {monitor.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="left">IP</StyledTableCell>
                  <StyledTableCell align="left">Porta</StyledTableCell>
                  <StyledTableCell align="left">Local</StyledTableCell>
                  <StyledTableCell align="left">Ativo</StyledTableCell>
                  <StyledTableCell align="left">Criado</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {monitor.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                  <StyledTableRow key={row.name}>
                    <PermissionComponent role="Delete_MonitoringEquipment">
                      <StyledTableCell align="left" sx={{ width: 100 }}>
                        <DeleteForeverIcon
                          onClick={() => deleteAlert(row.equipmentId)}
                          sx={{ cursor: 'pointer' }}
                        />
                      </StyledTableCell>{' '}
                    </PermissionComponent>
                    <PermissionComponent role="Edit_MonitoringEquipment">
                      <StyledTableCell align="left" sx={{ width: 100 }}>
                        <ModeEditOutlineIcon
                          onClick={() => handleEdit(row.equipmentId)}
                          sx={{ cursor: 'pointer' }}
                        />
                      </StyledTableCell>{' '}
                    </PermissionComponent>
                    <StyledTableCell align="left" sx={{ width: 200 }}>
                      {row.ip}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ width: 200 }}>
                      {row.port}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ width: 200 }}>
                      {row.location}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ width: 200 }}>
                      {row.active ? 'Sim' : 'Não'}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ width: 200 }}>
                      {new Date(row.createDate).toLocaleDateString()}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={monitor.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default MonitoringEquipmentAutomatic
