import {
  ArrowBack,
  Cancel,
  Check,
  CheckCircleOutline,
  DoneAll,
  Engineering,
  GroupAdd,
  Help,
  LockClock,
  Message,
  MoreVert,
  Person,
  QueryBuilder,
} from '@mui/icons-material'
import {
  Alert,
  Box,
  IconButton,
  Snackbar,
  Typography,
  Stepper,
  Step,
  StepLabel,
  styled,
  StepConnector,
  stepConnectorClasses,
  List,
  ListItem,
  Stack,
  ListItemText,
  Chip,
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  Menu,
  MenuItem,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  Skeleton,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  createResponseTicket,
  getCancelTicket,
  getFinishTicket,
  getIdTikeckt,
} from '../../../../../services/admServices'
import PropTypes from 'prop-types'
import { colorTableDefault } from '../../../../TemplateDefault'
import { Context } from '../../../../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { formatarDataHoraParaBRL } from '../../../../../utils/formatDate'
import { format, addHours } from 'date-fns'
import { assignTicketToAttendant } from '../../../../../services/helpdeskService'

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}))

function QontoStepIcon(props) {
  const { active, completed, className } = props

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  )
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: 'secondary',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: 'secondary',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}))

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  animation: ownerState.active ? 'pulse 1.5s ease-out infinite' : 'none', // Alteração aqui

  '&::after': {
    content: '""',
    position: 'absolute',
    top: '0',
    left: '0',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    border: `2px solid ${'#fff'}`,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    opacity: 0,
    zIndex: 2,
  },
  ...(ownerState.active && {
    backgroundColor: colorTableDefault,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundColor: colorTableDefault,
  }),
  '@keyframes pulse': {
    '0%': {
      opacity: 1,
      transform: 'scale(1)',
    },
    '100%': {
      opacity: 1,
      transform: 'scale(1.2)',
    },
  },
}))

export const TicketViewResponse = () => {
  const token = localStorage.getItem('token')
  const navigate = useNavigate()
  const { userId, subCompany, nameUrl } = useContext(Context)
  const params = useParams()
  const [viewElearning, setViewElearning] = useState(false)
  const [errorEmpresa, setErrorEmpresa] = useState(false)
  const [status, setStatus] = useState(0)
  const [ticket, setTicket] = useState('')
  const [createUserName, setCreateUserName] = useState('')
  const [createUserId, setCreateUserId] = useState('')
  const [subCompanyName, setSubCompanyName] = useState('')
  const [assistentUserId, setAssistentUserId] = useState('')
  const [subject, setSubject] = useState('')
  const [movimentationOrderTicketId, setMovimentationOrderTicketId] = useState('')
  const [movimentationOrderTicketDetailsId, setMovimentationOrderTicketDetailsId] = useState('')
  const [createDate, setCreateDate] = useState('')
  const [completionDate, setCompletionDate] = useState('')
  const [lastUpdateDate, setLastUpdateDate] = useState('')
  const [completionDateFinished, setCompletionDateFinished] = useState('')
  const [clientUserId, setClientUserId] = useState('')
  const [message, setMenssage] = useState('')
  const [priority, setPriority] = useState(0)
  const [collectionMessage, setCollectionMessage] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingButton, setLoadingButton] = useState(false)
  const [openDialog, setoOpenDialog] = useState(false)
  const [disabledAction, setDisabledAction] = useState(false)
  const [openDialogFinish, setOpenDialogFinish] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [openAssign, setOpenAssign] = useState(false)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setoOpenDialog(false)
    setOpenDialogFinish(false)
    setOpenAssign(false)
    setAlerta(false)
  }

  function ColorlibStepIcon(props) {
    const { active, completed, className, lastStepRed } = props

    const icons = {
      1: <QueryBuilder />,
      2: <Engineering />,
      3: <Message />,
      4: <CheckCircleOutline />,
      5: <Cancel />,
    }
    console.log(active, 'actisve')
    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active, lastStepRed }}
        className={className}
        sx={{
          bgcolor: active ? '#FFD700' : lastStepRed ? 'red' : '',
        }}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    )
  }

  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    icon: PropTypes.node,
    lastStepRed: PropTypes.bool,
  }

  const steps = [
    'Aguardando atendimento',
    'Em progresso',
    `Aguardando retorno ${status === 5 ? 'do Cliente' : status === 4 ? 'do Suporte' : ''}`,
    'Concluído',
    'Cancelado',
  ]

  const handleTicketGetId = async () => {
    setLoading(true)

    try {
      const response = await getIdTikeckt(token, params.movimentationOrderTicketId)
      const data = response.data
      const sortedMessages = data.movimentationOrderTicketDetailsCollection.sort(
        (a, b) => new Date(a.createDate).getTime() - new Date(b.createDate).getTime()
      )

      console.log(data, 'sdadadds')
      setTicket(data.ticket)
      setCreateUserName(data.createUserName)
      setCreateUserId(data.createUserId)
      setSubCompanyName(data.subCompanyName)
      setAssistentUserId(data.assistentUserId)

      setSubject(data.subject)
      setPriority(data.priority)
      setMovimentationOrderTicketId(data.movimentationOrderTicketId)
      setMovimentationOrderTicketDetailsId(
        data.movimentationOrderTicketDetailsCollection[0].movimentationOrderTicketDetailsId
      )
      setCreateDate(data.createDate)
      setStatus(data.status)
      setClientUserId(data.subCompanyId)
      console.log(data, 'datadatadata')
      console.log(subCompany[0].subCompanyId, 'datadatadata')
      setCollectionMessage(sortedMessages)

      const completionDate = addHours(
        new Date(data.createDate),
        calculateCompletionHours(data.priority)
      )
      const lastUpdateDate = addHours(
        new Date(data.lastUpdateDate),
        calculateCompletionHours(data.priority)
      )
      setCompletionDate(format(completionDate, 'dd/MM/yyyy HH:mm:ss'))
      setLastUpdateDate(format(new Date(data.lastUpdateDate), 'dd/MM/yyyy HH:mm:ss'))
      setCompletionDateFinished(format(new Date(data.finishDate), 'dd/MM/yyyy HH:mm:ss'))
      setLoading(false)
      setDisabledAction(true)
    } catch (error) {
      setLoading(false)
    }
  }

  const calculateTimeElapsed = creationDate => {
    const currentDate = new Date()
    const differenceInMilliseconds = currentDate - new Date(creationDate)
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60)
    return differenceInHours
  }

  const getStatusChip = (status, timeElapsed, priority) => {
    if (!isTicketOverdue(priority, timeElapsed)) {
      if (status === 0) {
        return (
          <Chip
            label="Aguardando atendimento"
            style={{
              background: '#E0E7FF',
              color: '#7A76DD',
              borderRadius: '5px',
            }}
          />
        )
      } else if (status === 1) {
        return (
          <Chip
            label="Em progresso"
            style={{
              background: '#FEF08A',
              color: '#A26308',
              borderRadius: '5px',
            }}
          />
        )
      } else if (status === -999) {
        return (
          <Chip
            label="Cancelado"
            style={{
              background: '#FEE2E2',
              color: '#B91C1C',
              borderRadius: '5px',
            }}
          />
        )
      } else if (status === 3) {
        return (
          <Chip
            label="Finalizado"
            style={{
              background: '#F0FDF4',
              color: '#3EB469',
              borderRadius: '5px',
            }}
          />
        )
      } else if (status === 2) {
        return (
          <Chip
            label="Transferência de atendentes"
            style={{
              background: '#F0FDF4',
              color: '#3EB469',
              borderRadius: '5px',
            }}
          />
        )
      } else if (status === 5) {
        return (
          <Chip
            label="Aguardando retorno do cliente"
            style={{
              background: '#FEF08A',
              color: '#A26308',
              borderRadius: '5px',
            }}
          />
        )
      } else if (status === 4) {
        return (
          <Chip
            label="Aguardando retorno do suporte"
            style={{
              background: '#FEF08A',
              color: '#A26308',
              borderRadius: '5px',
            }}
          />
        )
      }
    } else {
      if (status === -999 && isTicketOverdue(priority, timeElapsed)) {
        return (
          <Chip
            label="Cancelado"
            style={{
              background: '#FEE2E2',
              color: '#B91C1C',
              borderRadius: '5px',
            }}
          />
        )
      } else if (status === 3 && isTicketOverdue(priority, timeElapsed)) {
        return (
          <Chip
            label="Finalizado"
            style={{
              background: '#F0FDF4',
              color: '#3EB469',
              borderRadius: '5px',
            }}
          />
        )
      } else {
        return (
          <Chip
            label="Em atraso"
            style={{
              background: '#FEE2E2',
              color: '#B91C1C',
              borderRadius: '5px',
            }}
          />
        )
      }
    }

    return null
  }

  const isTicketOverdue = (priority, timeElapsed) => {
    return (
      (priority === 1 && timeElapsed > 1) ||
      (priority === 2 && timeElapsed > 12) ||
      (priority === 3 && timeElapsed > 24)
    )
  }

  const calculateCompletionHours = priority => {
    switch (priority) {
      case 3:
        return 24
      case 2:
        return 12
      case 1:
        return 6
      default:
        return 0
    }
  }

  const handleFinish = async () => {
    setLoading(true)
    handleClose()

    try {
      await getFinishTicket(token, movimentationOrderTicketId)
      setSeverity('success')
      setAlerta(true)
      setTitle('Ticket finalizado com sucesso!')
      handleTicketGetId()
      setLoading(false)
    } catch (error) {
      setSeverity('error')
      setAlerta(true)
      setTitle('Erro tente novamente mais tarde!')
      setLoading(false)
    }
  }

  const handleCancel = async () => {
    setLoading(true)
    handleClose()
    try {
      await getCancelTicket(token, movimentationOrderTicketId)
      setSeverity('success')
      setAlerta(true)
      setTitle('Ticket cancelado com sucesso!')
      handleTicketGetId()
      setLoading(false)
    } catch (error) {
      setSeverity('error')
      setAlerta(true)
      setTitle('Erro tente novamente mais tarde!')
      setLoading(false)
    }
  }

  useEffect(() => {
    const element = document.getElementById('pulsating-circle')
    if (element) {
      element.classList.add('pulsating')
    }
  }, [status, movimentationOrderTicketId, clientUserId])

  useEffect(() => {
    if (params.movimentationOrderTicketId) {
      handleTicketGetId()
    }
  }, [params])

  const handleDialogCancel = () => {
    setoOpenDialog(true)
  }

  const handleDialogFinish = () => {
    setOpenDialogFinish(true)
  }

  const handleResponseTicket = async () => {
    setLoading(true)
    setLoadingButton(true)
    const data = {
      movimentationOrderTicketId: movimentationOrderTicketId,
      message: message,
      createUserId: userId,
      file: '-',
      visibility: false,
      //movimentationOrderTicketDetailsId: movimentationOrderTicketDetailsId,
    }
    try {
      await createResponseTicket(token, data)
      setSeverity('success')
      setAlerta(true)
      setTitle('Mensagem salva com sucesso!')
      setMenssage('')
      handleTicketGetId()
      setLoading(false)
      setLoadingButton(false)
    } catch (error) {
      setSeverity('error')
      setAlerta(true)
      setTitle('Erro tente novamente mais tarde!')
      setLoading(false)
      setLoadingButton(false)
    }
  }

  const handleDialogAssign = (usertId, movimentationOrderTicketId) => {
    setoOpenDialog(true)
    setClientUserId(usertId)
    setMovimentationOrderTicketId(movimentationOrderTicketId)
  }

  const handleAssignTicketToAttendant = async () => {
    setLoading(true)
    handleClose()
    const data = {
      userId: userId,
      movimentationOrderTicketId: movimentationOrderTicketId,
    }
    console.log(data, 'datas')
    try {
      const response = await assignTicketToAttendant(token, data)
      setSeverity('success')
      setAlerta(true)
      setTitle('Atendimento iniciado com sucesso!')
      await handleTicketGetId()
      setLoading(false)
    } catch (error) {
      setSeverity('error')
      setAlerta(true)
      setTitle('Erro tente novamente mais tarde!')
      setLoading(false)
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar anchorOrigin={{ vertical, horizontal }} open={alerta} onClose={handleClose}>
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja cancelar este ticket?</DialogTitle>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancelar
            </Button>{' '}
            <Button variant="contained" onClick={() => handleCancel()}>
              Sim
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDialogFinish}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja finalizar este ticket?</DialogTitle>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="contained" onClick={() => handleFinish()}>
              Sim
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openAssign}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: 'center' }}
        >
          <DialogTitle>Tem certeza que deseja iniciar este atendimento?</DialogTitle>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="contained" onClick={() => handleAssignTicketToAttendant()}>
              Sim
            </Button>
          </DialogActions>
        </Dialog>
        <Box
          sx={{
            width: 1,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h5">Visualizar Ticket - Chamado: </Typography>
            <Stack direction="row" spacing={1}>
              <Chip
                label={ticket}
                style={{
                  background: 'gray',
                  color: 'white',
                  borderRadius: '5px',
                  marginLeft: '5px',
                }}
              />
            </Stack>
          </Box>

          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <Help />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Button
            startIcon={<ArrowBack />}
            sx={{ my: 2 }}
            variant="contained"
            onClick={() => navigate('/TicketSuport')}
          >
            Voltar
          </Button>
          {status === 0 && disabledAction && (
            <Button
              startIcon={<Person />}
              sx={{ ml: 2 }}
              variant="contained"
              onClick={() => setOpenAssign(true)}
            >
              Atribuir Ticket
            </Button>
          )}
          {disabledAction && status !== 3 && status !== -999 && assistentUserId === userId && (
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              variant="contained"
              sx={{ ml: 2 }}
              endIcon={<MoreVert />}
            >
              Ações
            </Button>
          )}

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleDialogCancel}>Cancelar ticket</MenuItem>
            <MenuItem disabled={status === 0} onClick={handleDialogFinish}>
              Finalizar ticket
            </MenuItem>
          </Menu>

          <Box
            sx={{
              width: '100%',
              maxWidth: '100%',
              overflowX: 'auto',
            }}
          >
            <Stepper sx={{ my: 2 }} alternativeLabel activeStep={status}>
              {steps.map((label, index) => (
                <Step
                  key={label}
                  completed={
                    (status === -999 && index === steps.length - 1) ||
                    (status === 4 && index <= 2 && index !== 2) ||
                    (status === 5 && index < 2) ||
                    (index < status && status !== 4 && status !== 5)
                  }
                  disabled={
                    index < status || (status === 4 && index === 2) || (status === 5 && index === 2)
                  }
                >
                  <StepLabel
                    StepIconComponent={props => (
                      <ColorlibStepIcon
                        {...props}
                        lastStepRed={status === -999 && index === steps.length - 1}
                        active={
                          (status !== 4 && status !== 5 && index === status) ||
                          (status === 4 && index === 2) ||
                          (status === 5 && index === 2)
                        }
                      />
                    )}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <Box
            sx={{
              width: '100%',
              backgroundColor: '#F1F1F1',
              my: 2,
              borderRadius: 2,
            }}
          >
            <List
              sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary="Chamado" secondary={ticket} />
              </ListItem>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary="Assunto" secondary={subject} />
              </ListItem>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary="Cliente" secondary={subCompanyName} />
              </ListItem>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary="Usuário" secondary={createUserName} />
              </ListItem>
              <ListItem sx={{ py: 0 }}>
                <ListItemText
                  primary="Data do ticket"
                  secondary={formatarDataHoraParaBRL(createDate)}
                />
              </ListItem>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary="Ultima atualização" secondary={lastUpdateDate} />
              </ListItem>
              <ListItem sx={{ py: 0 }}>
                <ListItemText primary="Previsão de Conclusão" secondary={completionDate} />
              </ListItem>
              {completionDateFinished !== '01/01/0001 00:00:00' && (
                <ListItem sx={{ py: 0 }}>
                  <ListItemText primary="Data de Conclusão" secondary={completionDateFinished} />
                </ListItem>
              )}
              <ListItem sx={{ py: 0 }}>
                <ListItemText
                  primary={getStatusChip(status, calculateTimeElapsed(createDate), priority)}
                />
              </ListItem>
            </List>
          </Box>

          <Box
            sx={{
              width: '100%',
              backgroundColor: '#F1F1F1',
              my: 2,
              borderRadius: 2,
              p: 2,
            }}
          >
            <Typography variant="body2" fontWeight={700}>
              Mensagens
            </Typography>
            <List
              sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              {collectionMessage.map((item, index) => {
                const isFirstMessage =
                  index === 0 ||
                  item.createDate.slice(0, 10) !==
                    collectionMessage[index - 1].createDate.slice(0, 10)

                const isCurrentUser = item.createUserId === createUserId

                return (
                  <React.Fragment key={index}>
                    {isFirstMessage && (
                      <ListItem
                        sx={{
                          p: 1,
                          borderRadius: 5,
                          my: 1,
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          bgcolor: '#ccc',
                        }}
                      >
                        <Typography variant="subtitle2" textAlign="center">
                          {new Date(item.createDate).toLocaleDateString([], {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          })}
                        </Typography>
                      </ListItem>
                    )}
                    <ListItem
                      sx={{
                        p: 0,
                        my: 1,
                        display: 'flex',
                        alignItems: 'start',
                        width: '100%',
                        justifyContent: isCurrentUser ? 'flex-end' : 'flex-start',
                      }}
                    >
                      {loading ? (
                        <Skeleton width={200} height={150} sx={{ borderRadius: 5 }} />
                      ) : (
                        <Box
                          sx={{
                            px: 2,
                            py: 2,
                            m: 0,
                            borderRadius: 2,
                            maxWidth: '80%',
                            minWidth: '40%',
                            position: 'relative',
                            background: isCurrentUser ? '#DCF8C6' : '#FFFFFF',
                            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
                            marginBottom: 2,
                          }}
                        >
                          <ListItemText
                            primary={
                              <Typography
                                sx={{
                                  fontWeight: 700,
                                  color: theme =>
                                    isCurrentUser
                                      ? theme.palette.primary.light
                                      : theme.palette.secondary.dark,
                                }}
                              >
                                {isCurrentUser ? 'Cliente' : 'Suporte'}
                              </Typography>
                            }
                            secondary={item.message}
                            sx={{ fontWeight: 700 }}
                          />

                          <Box
                            sx={{
                              position: 'absolute',
                              top: isCurrentUser ? -10 : -10,
                              left: isCurrentUser ? 'auto' : -8,
                              right: isCurrentUser ? -8 : 5,
                              width: '20px',
                              height: '20px',
                              background: isCurrentUser ? '#DCF8C6' : '#fff',
                              transform: isCurrentUser ? 'rotate(135deg)' : 'rotate(-135deg)',
                              clipPath: isCurrentUser
                                ? 'polygon(0 0, 100% 0, 100% 100%)'
                                : 'polygon(0 0, 100% 0, 0 100%)',
                            }}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              bottom: 2,
                              right: 10,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              gap: 0.5,
                            }}
                          >
                            <Typography variant="caption">
                              {new Date(item.createDate).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                              })}
                            </Typography>
                            <DoneAll
                              sx={{
                                color: 'green',
                                fontSize: 16,
                              }}
                            />
                          </Box>
                        </Box>
                      )}
                    </ListItem>
                  </React.Fragment>
                )
              })}
            </List>

            <Card sx={{ mt: 2 }}>
              <CardContent>
                <TextField
                  disabled={
                    status === 3 ||
                    status === 0 ||
                    status === -999 ||
                    status === 3 ||
                    status === 0 ||
                    (status === -999 && assistentUserId === userId)
                  }
                  id="outlined-multiline-static"
                  label="Escreva sua mensagem"
                  multiline
                  rows={4}
                  value={message}
                  onChange={e => setMenssage(e.target.value)}
                  sx={{ width: '100%' }}
                />
              </CardContent>
              <CardActions>
                <Button
                  disabled={
                    status === 3 ||
                    status === 0 ||
                    status === -999 ||
                    status === 3 ||
                    status === 0 ||
                    (status === -999 && assistentUserId === userId)
                  }
                  onClick={handleResponseTicket}
                  variant="contained"
                  startIcon={loadingButton && <CircularProgress size={25} />}
                >
                  {loadingButton ? 'Carregando...' : 'Enviar mensagem'}
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Box>
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}
