import {
  CircularProgress,
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
  Button,
  LinearProgress,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { colorTableDefault } from '../../TemplateDefault'
import moment from 'moment'
import { AiFillFileExcel } from 'react-icons/ai'
import {
  getABCReSeller,
  getAllVendor,
  getCityvsCityActive,
  getCityvsCityActivePorgentage,
  getCityvsCityMaps,
  getGrowmixProductByFamily,
  getGrowmixProductByStore,
  getLastOrder,
  getNewCityPeriod,
  getNumberStoreOpen,
  getNumberTotalStoreActiveevsStoreInaActive,
  getNumberTotalStoreRouteevsStoreActive,
  getStorevsStoreActive,
  getTicketmedioSeller,
  getTotalSale,
} from '../../../services/dashboardServices'
import PropTypes from 'prop-types'
import BrazilMapChart from './Components/EChartsComponent'
import GraficoApex from './Components/GraficoApex'
import GraficStoreRouterIntinerary from './Components/GraficStoreRouterIntinerary'
import GraficStoreRouterAndVisitors from './Components/GraficStoreRouterAndVisitors'
import GraficSoldCities from './Components/GraficSoldCities'
import GraficStores from './Components/GraficStores'
import GraficSalesAveragePerSeller from './Components/GraficSalesAveragePerSeller'
import GraficProductMixGrowth from './Components/GraficProductMixGrowth'
import GraficRevenueAndProductMixGrowth from './Components/GraficRevenueAndProductMixGrowth'
import NumberTotalStoreRouteevsStoreActive from './Components/NumberTotalStoreRouteevsStoreActive'
import GraficABCReSeller from './Components/GraficABCReSeller'
import GraficNumberStoreOpen from './Components/GraficNumberStoreOpen'
import GraficTotalSale from './Components/GraficTotalSale'
import GraficLastOrder from './Components/GraficLastOrder'
import * as XLSX from 'xlsx'
import { ExpandCircleDown } from '@mui/icons-material'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTableDefault,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '8px',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const cityVsCityActiveColumns = [
  { label: 'Data', value: 'data' },
  { label: 'Visitados', value: 'visity' },
  { label: 'Não Visitados', value: 'visityNot' },
  { label: 'Total na Cidade', value: 'allCity' },
  { label: 'Total Geral', value: 'countall' },
  { label: 'Porcentagem', value: 'porcentage' },
  { label: 'Preço', value: 'price' },
  { label: 'Nome', value: 'name' },
  { label: 'Nome 2', value: 'name2' },
  { label: 'Nome 3', value: 'name3' },
  { label: 'Nome 4', value: 'name4' },
  { label: 'Nome 5', value: 'name5' },
  { label: 'Nome 6', value: 'name6' },
  { label: 'Nome 7', value: 'name7' },
]

const sheetTranslations = {
  CityvsCityActive: 'Atividade da Cidade vs. Cidade',
  CityvsCityActivePorgentage: 'Atividade da Cidade vs. Cidade - Porcentagem',
  VisitedAndRoutesStore: 'Lojas Visitadas e Rotas',
  NumberTotalStoreRouteevsStoreActive: 'Número Total de Rotas de Loja vs. Atividade da Loja',
  Stores: 'Lojas',
  TicketmedioSeller: 'Ticket Médio por Vendedor',
  GrowmixProductByStore: 'Produtos Growmix por Loja',
  GrowmixProductByFamily: 'Produtos Growmix por Família',
  NewCityPeriod: 'Novas Cidades no Período',
  NumberStoreOpen: 'Número de Lojas Abertas',
  ABCReSeller: 'ABC ReVendedor',
  TotalSale: 'Venda Total',
  LastOrder: 'Último Pedido',
  CityvsCityMaps: 'Mapas da Cidade vs. Cidade',
}

function LinearProgressWithLabel(props) {
  return (
    <Box
      sx={{
        alignItems: 'center',
        textalign: 'center',
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        justifyConent: 'center',
      }}
    >
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
}

export const DashboardSales = () => {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(subCompany[0].subCompanyId)
  const currentMonth = moment().startOf('month')
  const firstDay = currentMonth.format('YYYY-MM-DD')
  const lastDay = currentMonth.endOf('month').format('YYYY-MM-DD')
  const [startDate, setStartDate] = useState(firstDay)
  const [finishDate, setFinishDate] = useState(lastDay)
  const [vendorId, setVendorId] = useState('0')
  const [vendors, setVendors] = useState([])
  const [abcReSeller, setAbcReSeller] = useState([])
  const [numberStoreOpen, setNumberStoreOpen] = useState([])
  const [totalSale, setTotalSale] = useState([])
  const [totalCitiesRoute, setTotalCitiesRoute] = useState([])
  const [storesRoute200, setStoresRoute200] = useState([])
  const [visitedStores, setVisitedStores] = useState([])
  const [cityMap, setCityMaps] = useState([])
  const [newStores, setNewStores] = useState([])
  const [lastOrder, setLastOrder] = useState([])
  const [stores, setStores] = useState([])
  const [salesAveragePerSeller, setSalesAveragePerSeller] = useState([])
  const [totalStoreRouterIntinerary, setTotalStoreRouterIntinerary] = useState([])
  const [growmixProductByFamily, setGrowmixProductByFamily] = useState([])
  const [growmixProductByStore, setGrowmixProductByStore] = useState([])
  const [loadingCityvsCityActive, setLoadingCityvsCityActive] = useState(false)
  const [loadingCityvsCityActivePorgentage, setLoadingCityvsCityActivePorgentage] = useState(false)
  const [loadingVisitedAndRoutesStore, setLoadingVisitedAndRoutesStore] = useState(false)
  const [loadingCityvsCityMaps, setLoadingCityvsCityMaps] = useState(false)
  const [
    loadingNumberTotalStoreRouteevsStoreActive,
    setLoadingNumberTotalStoreRouteevsStoreActive,
  ] = useState(false)
  const [loadingStores, setLoadingStores] = useState(false)
  const [loadingTicketmedioSeller, setLoadingTicketmedioSeller] = useState(false)
  const [loadingGrowmixProductByStore, setLoadingGrowmixProductByStore] = useState(false)
  const [loadingGrowmixProductByFamily, setLoadingGrowmixProductByFamily] = useState(false)
  const [loadingNewCityPeriod, setLoadingNewCityPeriod] = useState(false)
  const [loadingNumberStoreOpen, setLoadingNumberStoreOpen] = useState(false)
  const [loadingABCReSeller, setLoadingABCReSeller] = useState(false)
  const [loadingTotalSale, setLoadingTotalSale] = useState(false)
  const [loadingLastOrder, setLoadingLastOrder] = useState(false)

  const generateExcelFile = () => {
    const workbook = XLSX.utils.book_new()

    const sheets = [
      {
        name: 'CityvsCityActive',
        data: { columns: cityVsCityActiveColumns, rows: totalCitiesRoute },
      },
      {
        name: 'CityvsCityActivePorgentage',
        data: { columns: cityVsCityActiveColumns, rows: totalStoreRouterIntinerary },
      },
      {
        name: 'VisitedAndRoutesStore',
        data: { columns: cityVsCityActiveColumns, rows: visitedStores },
      },
      {
        name: 'NumberTotalStoreRouteevsStoreActive',
        data: { columns: cityVsCityActiveColumns, rows: storesRoute200 },
      },
      { name: 'Stores', data: { columns: cityVsCityActiveColumns, rows: stores } },
      {
        name: 'TicketmedioSeller',
        data: { columns: cityVsCityActiveColumns, rows: salesAveragePerSeller },
      },
      {
        name: 'GrowmixProductByStore',
        data: { columns: cityVsCityActiveColumns, rows: growmixProductByStore },
      },
      {
        name: 'GrowmixProductByFamily',
        data: { columns: cityVsCityActiveColumns, rows: growmixProductByFamily },
      },
      { name: 'NewCityPeriod', data: { columns: cityVsCityActiveColumns, rows: newStores } },
      {
        name: 'NumberStoreOpen',
        data: { columns: cityVsCityActiveColumns, rows: numberStoreOpen },
      },
      { name: 'ABCReSeller', data: { columns: cityVsCityActiveColumns, rows: abcReSeller } },
      { name: 'TotalSale', data: { columns: cityVsCityActiveColumns, rows: totalSale } },
      { name: 'LastOrder', data: { columns: cityVsCityActiveColumns, rows: lastOrder } },
      { name: 'CityvsCityMaps', data: cityMap },
    ]

    sheets.forEach(sheet => {
      const truncatedName = sheet.name.substring(0, 31)

      // Adiciona abas de dados
      if (sheet.data.columns && sheet.data.rows) {
        const worksheet = XLSX.utils.json_to_sheet(
          [
            sheet.data.columns.map(column => column.label),
            ...sheet.data.rows.map(row => sheet.data.columns.map(column => row[column.value])),
          ],
          { skipHeader: true }
        )
        XLSX.utils.book_append_sheet(workbook, worksheet, truncatedName)
      } else {
        // Adiciona abas sem dados (por exemplo, 'CityvsCityMaps')
        XLSX.utils.book_append_sheet(workbook, XLSX.utils.aoa_to_sheet([]), truncatedName)
      }
    })

    XLSX.writeFile(workbook, 'resultados.xlsx')
  }

  const handleGetAllVendor = async () => {
    try {
      const response = await getAllVendor(token, nameUrl, subCompanyId)
      setVendors(response.data)
    } catch (error) {}
  }

  const handleCityvsCityActive = async () => {
    setLoadingCityvsCityActive(true)

    try {
      const response = await getCityvsCityActive(
        token,
        subCompanyId,
        startDate,
        finishDate,
        vendorId
      )
      setTotalCitiesRoute(response.data)
      console.log(response.data, 'response.data')

      setLoadingCityvsCityActive(false)
    } catch (error) {
      setLoadingCityvsCityActive(false)
      console.log(error)
    }
  }

  // const handleCityvsCityActive = async () => {
  //   setLoadingCityvsCityActive(true)

  //   try {
  //     const response = await getCityvsCityActive(
  //       token,
  //       subCompanyId,
  //       startDate,
  //       finishDate,
  //       vendorId
  //     )

  //     const uniqueCities = {}

  //     response.data.forEach(cityData => {
  //       if (uniqueCities[cityData.route]) {
  //         // Se a cidade já existe, soma os valores de visity e visityNot
  //         uniqueCities[cityData.route].visity += cityData.visity
  //         uniqueCities[cityData.route].visityNot += cityData.visityNot
  //       } else {
  //         // Se a cidade ainda não existe, adiciona-a ao objeto uniqueCities
  //         uniqueCities[cityData.route] = {
  //           ...cityData,
  //         }
  //       }
  //     })

  //     // Converte o objeto de volta em uma matriz de objetos
  //     const consolidatedCities = Object.values(uniqueCities)

  //     setTotalCitiesRoute(consolidatedCities)
  //     setLoadingCityvsCityActive(false)
  //   } catch (error) {
  //     setLoadingCityvsCityActive(false)
  //     console.log(error)
  //   }
  // }

  const handleCityvsCityActivePorgentage = async () => {
    setLoadingCityvsCityActivePorgentage(true)

    try {
      const response = await getCityvsCityActivePorgentage(
        token,
        subCompanyId,
        startDate,
        finishDate,
        vendorId
      )
      setTotalStoreRouterIntinerary(response.data)
      setLoadingCityvsCityActivePorgentage(false)
    } catch (error) {
      setLoadingCityvsCityActivePorgentage(false)

      console.log(error)
    }
  }

  const handleVisitedAndRoutesStore = async () => {
    setLoadingVisitedAndRoutesStore(true)

    try {
      const response = await getStorevsStoreActive(
        token,
        subCompanyId,
        startDate,
        finishDate,
        vendorId
      )
      setVisitedStores(response.data)
      setLoadingVisitedAndRoutesStore(false)
    } catch (error) {
      setLoadingVisitedAndRoutesStore(false)

      console.log(error)
    }
  }

  const handleCityvsCityMaps = async () => {
    setLoadingCityvsCityMaps(true)

    try {
      const response = await getCityvsCityMaps(
        token,
        nameUrl,
        subCompanyId,
        startDate,
        finishDate,
        vendorId
      )

      setCityMaps(response.data)
      setLoadingCityvsCityMaps(false)
    } catch (error) {
      setLoadingCityvsCityMaps(false)

      console.log(error)
    }
  }

  const handleNumberTotalStoreRouteevsStoreActive = async () => {
    setLoadingNumberTotalStoreRouteevsStoreActive(true)

    try {
      const response = await getNumberTotalStoreRouteevsStoreActive(
        token,
        subCompanyId,
        startDate,
        finishDate,
        vendorId
      )
      setStoresRoute200(response.data)
      setLoadingNumberTotalStoreRouteevsStoreActive(false)
    } catch (error) {
      setLoadingNumberTotalStoreRouteevsStoreActive(false)

      console.log(error)
    }
  }

  const handleStores = async () => {
    setLoadingStores(true)

    try {
      const response = await getNumberTotalStoreActiveevsStoreInaActive(
        token,
        subCompanyId,
        startDate,
        finishDate,
        vendorId
      )
      setStores(response.data)
      setLoadingStores(false)
    } catch (error) {
      console.log(error)
      setLoadingStores(false)
    }
  }

  const handleTicketmedioSeller = async () => {
    setLoadingTicketmedioSeller(true)

    try {
      const response = await getTicketmedioSeller(
        token,
        subCompanyId,
        startDate,
        finishDate,
        vendorId
      )
      const sortedSalesAveragePerSeller = response.data.sort((a, b) => b.price - a.price)
      setSalesAveragePerSeller(sortedSalesAveragePerSeller)
      setLoadingTicketmedioSeller(false)
    } catch (error) {
      setLoadingTicketmedioSeller(false)

      console.log(error)
    }
  }

  const handleGrowmixProductByStore = async () => {
    setLoadingGrowmixProductByStore(true)

    try {
      const response = await getGrowmixProductByStore(
        token,
        subCompanyId,
        startDate,
        finishDate,
        vendorId
      )
      setGrowmixProductByStore(response.data)
      setLoadingGrowmixProductByStore(false)
    } catch (error) {
      setLoadingGrowmixProductByStore(false)

      console.log(error)
    }
  }

  const handleGrowmixProductByFamily = async () => {
    setLoadingGrowmixProductByFamily(true)

    try {
      const response = await getGrowmixProductByFamily(
        token,
        subCompanyId,
        startDate,
        finishDate,
        vendorId
      )
      setGrowmixProductByFamily(response.data)
      setLoadingGrowmixProductByFamily(false)
    } catch (error) {
      setLoadingGrowmixProductByFamily(false)

      console.log(error)
    }
  }

  const handleNewCityPeriod = async () => {
    setLoadingNewCityPeriod(true)

    try {
      const response = await getNewCityPeriod(
        token,
        nameUrl,
        subCompanyId,
        startDate,
        finishDate,
        vendorId
      )
      const newStores = response.data.sort((a, b) => b.price - a.price)
      setNewStores(newStores)
      setLoadingNewCityPeriod(false)
    } catch (error) {
      setLoadingNewCityPeriod(false)

      console.log(error)
    }
  }

  const handleNumberStoreOpen = async () => {
    setLoadingNumberStoreOpen(true)

    try {
      const response = await getNumberStoreOpen(
        token,
        subCompanyId,
        startDate,
        finishDate,
        vendorId
      )
      const numberStoreOpen = response.data.sort((a, b) => b.countall - a.countall)

      setNumberStoreOpen(numberStoreOpen)
      setLoadingNumberStoreOpen(false)
    } catch (error) {
      setLoadingNumberStoreOpen(false)

      console.log(error)
    }
  }

  const handleABCReSeller = async () => {
    setLoadingABCReSeller(true)

    try {
      const response = await getABCReSeller(
        token,
        nameUrl,
        subCompanyId,
        startDate,
        finishDate,
        vendorId
      )
      setAbcReSeller(response.data)
      setLoadingABCReSeller(false)
    } catch (error) {
      setLoadingABCReSeller(false)

      console.log(error)
    }
  }

  const handleTotalSale = async () => {
    setLoadingTotalSale(true)

    try {
      const response = await getTotalSale(
        token,
        nameUrl,
        subCompanyId,
        startDate,
        finishDate,
        vendorId
      )
      const sortedSalesAveragePerSeller = response.data.sort((a, b) => b.price - a.price)

      setTotalSale(sortedSalesAveragePerSeller)
      setLoadingTotalSale(false)
    } catch (error) {
      console.log(error)
      setLoadingTotalSale(false)
    }
  }

  const handleLastOrder = async () => {
    setLoadingLastOrder(true)

    try {
      const response = await getLastOrder(
        token,
        nameUrl,
        subCompanyId,
        startDate,
        finishDate,
        vendorId
      )
      const sortedData = response.data.sort((a, b) => b.price - a.price)
      setLastOrder(sortedData)
      setLoadingLastOrder(false)
    } catch (error) {
      setLoadingLastOrder(false)

      console.log(error)
    }
  }

  useEffect(() => {
    handleGetAllVendor()
  }, [])

  const handleFunctions = async () => {
    await handleCityvsCityActive()

    await handleCityvsCityActivePorgentage()

    await handleVisitedAndRoutesStore()

    await handleCityvsCityMaps()

    await handleNumberTotalStoreRouteevsStoreActive()

    await handleStores()

    await handleTicketmedioSeller()

    await handleGrowmixProductByStore()

    await handleGrowmixProductByFamily()

    await handleNewCityPeriod()

    await handleNumberStoreOpen()

    await handleABCReSeller()

    await handleTotalSale()

    await handleLastOrder()
  }
  useEffect(() => {
    handleFunctions()
  }, [subCompanyId, startDate, finishDate, vendorId])

  function formatBRL(valor) {
    const formatoMoeda = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })

    return formatoMoeda.format(valor)
  }

  return (
    <Box sx={{ mt: 7.5 }}>
      <Box
        sx={{
          width: 1,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h5" sx={{ mb: 5 }}>
          Dashboard de vendas
        </Typography>
        <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => {}}>
          <HelpIcon />
        </IconButton>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <InputLabel id="demo-simple-select-label">Selecione a filial</InputLabel>
          <Select
            value={subCompanyId}
            sx={{ width: '100%' }}
            label="Escolher o Usuário"
            onChange={event => {
              setSubCompanyId(event.target.value)
            }}
          >
            {subCompany.map((item, index) => {
              return (
                <MenuItem value={item.subCompanyId} key={index}>
                  {item.name}
                </MenuItem>
              )
            })}
          </Select>
        </Grid>
        <Grid item xs={12} md={2}>
          <InputLabel id="demo-simple-select-label">Data inicial</InputLabel>
          <TextField
            style={{ backgroundColor: '#fff' }}
            variant="outlined"
            value={startDate}
            onChange={values => {
              setStartDate(values.target.value)
            }}
            sx={{ width: '100%' }}
            name="createDate"
            type="date"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <InputLabel id="demo-simple-select-label">Data final</InputLabel>
          <TextField
            style={{ backgroundColor: '#fff' }}
            variant="outlined"
            value={finishDate}
            onChange={values => setFinishDate(values.target.value)}
            sx={{ width: '100%' }}
            name="endDate"
            type="date"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <InputLabel id="demo-simple-select-label">Selecione o vendedor</InputLabel>
          <Select
            value={vendorId}
            sx={{ width: '100%' }}
            label="Selecione o vendedor"
            onChange={event => setVendorId(event.target.value)}
          >
            <MenuItem value="0">Todos</MenuItem>
            {vendors.map((item, index) => {
              return (
                <MenuItem value={item.vendorId} key={index}>
                  {item.vendorName}
                </MenuItem>
              )
            })}
          </Select>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            sx={{ mt: 2.9, width: '100%', height: '55px' }}
            size="large"
            variant="contained"
            endIcon={<AiFillFileExcel />}
            onClick={generateExcelFile}
          >
            Excel
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: { xs: 'auto', md: '600px' },
            }}
          >
            {loadingCityvsCityActive && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1500',
                  bgcolor: '#fff',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
            <Typography variant="h6" textAlign="center" fontWeight={700} m={2}>
              Total de cidades que estão no roteiro de visitação vs Total de cidades que temos loja
              ativa
            </Typography>
            <CardContent sx={{ py: '20px !Important', px: '24px !Important', width: '100%' }}>
              <GraficoApex totalCitiesRoute={totalCitiesRoute} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: { xs: 'auto', md: '600px' },
            }}
          >
            {loadingCityvsCityActivePorgentage && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1500',
                  bgcolor: '#fff',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
            <Typography variant="h6" textAlign="center" fontWeight={700} m={2}>
              Lojas do roteiro que faz visita vs Lojas do roteiro existente que não faz visita
            </Typography>
            <CardContent sx={{ py: '20px !Important', px: '24px !Important', width: '100%' }}>
              <GraficStoreRouterIntinerary
                totalStoreRouterIntinerary={totalStoreRouterIntinerary}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: { xs: 'auto', md: '600px' },

              justifyContent: 'space-between',
            }}
          >
            {loadingVisitedAndRoutesStore && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1500',
                  bgcolor: '#fff',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
            <Typography variant="h6" textAlign="center" fontWeight={700} m={2}>
              Lojas visitantes vs Total de lojas do roteiro.
            </Typography>
            <CardContent sx={{ py: '20px !Important', px: '24px !Important', width: '100%' }}>
              <GraficStoreRouterAndVisitors visitedStores={visitedStores} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: { xs: 'auto', md: '600px' },

              justifyContent: 'space-between',
            }}
          >
            {loadingNewCityPeriod && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1500',
                  bgcolor: '#fff',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
            <Typography variant="h6" textAlign="center" fontWeight={700} m={2}>
              Cidade nova que vendeu no período.
            </Typography>
            <CardContent sx={{ py: '20px !Important', px: '24px !Important', width: '100%' }}>
              <GraficSoldCities firstDay={firstDay} lastDay={lastDay} soldCities={newStores} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: { xs: 'auto', md: '600px' },
            }}
          >
            {loadingCityvsCityMaps && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1500',
                  bgcolor: '#fff',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
            <Typography variant="h6" textAlign="center" fontWeight={700} m={2}>
              Cobertura geográfica.
            </Typography>
            <CardContent sx={{ py: '20px !Important', px: '24px !Important', width: '100%' }}>
              <BrazilMapChart cities={cityMap} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: { xs: 'auto', md: '600px' },
            }}
          >
            {loadingNumberTotalStoreRouteevsStoreActive && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1500',
                  bgcolor: '#fff',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
            <Typography variant="h6" textAlign="center" fontWeight={700} m={2}>
              Total de lojas no roteiro 200 para visitação vs Lojas ativas 119 60% 80%.
            </Typography>
            <CardContent sx={{ py: '20px !Important', px: '24px !Important', width: '100%' }}>
              <NumberTotalStoreRouteevsStoreActive storesRoute200={storesRoute200} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: { xs: 'auto', md: '600px' },
            }}
          >
            {loadingStores && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1500',
                  bgcolor: '#fff',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
            <Typography variant="h6" textAlign="center" fontWeight={700} m={2}>
              Número de lojas ativas vs Total de lojas inativas.
            </Typography>
            <CardContent sx={{ py: '20px !Important', px: '24px !Important', width: '100%' }}>
              <GraficStores stores={stores} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: { xs: 'auto', md: '600px' },
            }}
          >
            {loadingNumberStoreOpen && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1500',
                  bgcolor: '#fff',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
            <Typography variant="h6" textAlign="center" fontWeight={700} m={2}>
              Número de lojas aberta no período.
            </Typography>
            <CardContent sx={{ py: '20px !Important', px: '24px !Important', width: '100%' }}>
              <GraficNumberStoreOpen numberStoreOpen={numberStoreOpen} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: { xs: 'auto', md: '600px' },
            }}
          >
            {loadingTicketmedioSeller && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1500',
                  bgcolor: '#fff',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
            <Typography variant="h6" textAlign="center" fontWeight={700} m={2}>
              Ticket médio de vendas no período, por vendedor em ranking.
            </Typography>
            <CardContent sx={{ py: '20px !Important', px: '24px !Important', width: '100%' }}>
              <GraficSalesAveragePerSeller salesAveragePerSeller={salesAveragePerSeller} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: { xs: 'auto', md: '600px' },
            }}
          >
            {loadingGrowmixProductByFamily && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1500',
                  bgcolor: '#fff',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
            <Typography variant="h6" textAlign="center" fontWeight={700} m={2}>
              Crescimento de Mix de produtos por família de produtos.
            </Typography>
            <CardContent sx={{ py: '20px !Important', px: '24px !Important', width: '100%' }}>
              <GraficProductMixGrowth productMixGrowth={growmixProductByFamily} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: { xs: 'auto', md: '600px' },
            }}
          >
            {loadingGrowmixProductByStore && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1500',
                  bgcolor: '#fff',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
            <Typography variant="h6" textAlign="center" fontWeight={700} m={2}>
              Crescimento de faturamento e de Mix de produtos por loja e região de visitação.
            </Typography>
            <CardContent sx={{ py: '20px !Important', px: '24px !Important', width: '100%' }}>
              <GraficRevenueAndProductMixGrowth
                revenueAndProductMixGrowth={growmixProductByStore}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: { xs: 'auto', md: '600px' },
            }}
          >
            {loadingABCReSeller && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1500',
                  bgcolor: '#fff',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
            <Typography variant="h6" textAlign="center" fontWeight={700} m={2}>
              Curva ABC por revenda e por vendedor.
            </Typography>
            <CardContent sx={{ py: '20px !Important', px: '24px !Important', width: '100%' }}>
              <GraficABCReSeller abcReSeller={abcReSeller} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: { xs: 'auto', md: '600px' },
            }}
          >
            {loadingTotalSale && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1500',
                  bgcolor: '#fff',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
            <Typography variant="h6" textAlign="center" fontWeight={700} m={2}>
              Venda total por Estado, Cidade, produto e familia.
            </Typography>
            <CardContent sx={{ py: '20px !Important', px: '24px !Important', width: '100%' }}>
              <GraficTotalSale totalSale={totalSale} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0.5px',
              borderRadius: '10px',
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'start',
              height: { xs: 'auto', md: '600px' },
            }}
          >
            {loadingLastOrder && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1500',
                  bgcolor: '#fff',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
            <Typography variant="h6" fontWeight={700} m={2}>
              Ultimo pedidos realizados por loja.
            </Typography>
            <CardContent sx={{ py: '20px !Important', px: '0px !Important', width: '100%' }}>
              <TableContainer component={Paper}>
                <Table sx={{ width: '100%' }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">Nome</StyledTableCell>
                      <StyledTableCell align="left" width={100}>
                        Valor
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lastOrder.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="left">{row.name}</StyledTableCell>
                        <StyledTableCell align="left" sx={{ width: 100 }}>
                          {formatBRL(row.price)}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
    </Box>
  )
}
