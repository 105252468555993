import axios from 'axios'

//Grupo de Parâmetros
export const getParamGroupService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/ParametersGroup/GetAll?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getUniqueParamGroupService = async (token, nameUrl, paramGroupId) => {
  const response = await axios.get(`/${nameUrl}/api/ParametersGroup/Get/${paramGroupId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createParamGroupService = async (token, nameUrl, paramGroup) => {
  const response = await axios.post(
    `/${nameUrl}/api/ParametersGroup/Create`,
    {
      active: paramGroup.active,
      created: paramGroup.created,
      description: paramGroup.description,
      name: paramGroup.name,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const deleteParamGroupService = async (token, nameUrl, paramGroupId) => {
  const response = await axios.post(
    `/${nameUrl}/api/ParametersGroup/Delete`,
    {
      parameterGroupId: paramGroupId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const editParamGroupService = async (token, nameUrl, paramGroup) => {
  let date = new Date()

  const response = await axios.post(
    `/${nameUrl}/${nameUrl}/api/ParametersGroup/Edit`,
    {
      parameterGroupId: paramGroup.parameterGroupId,
      name: paramGroup.name,
      description: paramGroup.description,
      active: paramGroup.active,
      created: date,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Parâmetros
export const getParamService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/Parameters/GetAll?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getUniqueParamService = async (token, nameUrl, paramId) => {
  const response = await axios.get(`${nameUrl}/api/Parameters/Get/${paramId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createParamService = async (token, nameUrl, param) => {
  const response = await axios.post(
    `/${nameUrl}/api/Parameters/Create`,
    {
      active: param.active,
      created: param.created,
      description: param.description,
      name: param.name,
      parameterGroupId: param.parameterGroupId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const deleteParamService = async (token, nameUrl, paramId) => {
  const response = await axios.post(
    `/${nameUrl}/api/Parameters/Delete`,
    {
      parameterId: paramId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const editParamService = async (token, nameUrl, param) => {
  let date = new Date()

  const response = await axios.post(
    `/${nameUrl}/api/Parameters/Edit`,
    {
      parameterId: param.parameterId,
      parameterGroupId: param.parameterGroupId,
      name: param.name,
      description: param.description,
      active: param.active,
      created: date,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Parâmetros de Email
export const getEmailParamService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/EmailParameters/GetAll?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getUniqueEmailParamService = async (token, nameUrl, emailParamId) => {
  const response = await axios.get(`${nameUrl}/api/EmailParameters/Get/${emailParamId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createEmailParamService = async (token, nameUrl, emailParam) => {
  const response = await axios.post(
    `/${nameUrl}/api/EmailParameters/Create`,
    {
      name: emailParam.name,
      subject: emailParam.subject,
      mensage: emailParam.mensage,
      isDelete: emailParam.isDelete,
      active: emailParam.active,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const deleteEmailParamService = async (token, nameUrl, emailParamId) => {
  const response = await axios.post(
    `/${nameUrl}/api/EmailParameters/Delete`,
    {
      emailParametersId: emailParamId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const editEmailParamService = async (token, nameUrl, emailParam) => {
  const response = await axios.post(
    `/${nameUrl}/api/EmailParameters/Edit`,
    {
      emailParametersId: emailParam.emailParametersId,
      name: emailParam.name,
      subject: emailParam.subject,
      mensage: emailParam.mensage,
      isDelete: emailParam.isDelete,
      active: emailParam.active,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Integrações Externas
export const getIntegrationDefaultService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/IntegrationDefault/GetAll?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getUniqueIntegrationDefaultService = async (token, nameUrl, intDefaultId) => {
  const response = await axios.get(`${nameUrl}/api/IntegrationDefault/Get/${intDefaultId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createIntegrationDefaultService = async (token, nameUrl, intDefault) => {
  const response = await axios.post(
    `/${nameUrl}/api/IntegrationDefault/Create`,
    {
      name: intDefault.name,
      urlRedirect: intDefault.urlRedirect,
      urlCalback: intDefault.urlCalback,
      urlApi: intDefault.urlApi,
      scopeList: intDefault.scopeList,
      active: intDefault.active,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const deleteIntegrationDefaultService = async (token, nameUrl, intDefaultId) => {
  const response = await axios.post(
    `/${nameUrl}/api/IntegrationDefault/Delete`,
    {
      integrationDefaultId: intDefaultId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const editIntegrationDefaultService = async (token, nameUrl, intDefault) => {
  const response = await axios.post(
    `/${nameUrl}/api/IntegrationDefault/Edit`,
    {
      integrationDefaultId: intDefault.integrationDefaultId,
      name: intDefault.name,
      urlRedirect: intDefault.urlRedirect,
      urlCalback: intDefault.urlCalback,
      urlApi: intDefault.urlApi,
      scopeList: intDefault.scopeList,
      active: true,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Regras
export const getRolesService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'desc'
  } else {
    orderToString = 'asc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/Role/GetAll?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Regras
export const getRolesCategoryService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'desc'
  } else {
    orderToString = 'asc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/RoleCategory/GetAll?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const createRoleService = async (token, nameUrl, role) => {
  const response = await axios.post(
    `/${nameUrl}/api/Role/Create`,
    {
      name: role.name,
      description: role.description,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getRolesCategoryIdService = async (token, nameUrl, roleCategoryId) => {
  const response = await axios.get(`${nameUrl}/api/RoleCategory/Get/${roleCategoryId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getListRolesService = async (token, nameUrl, limit, offset, order) => {
  const response = await axios.get(`${nameUrl}/api/Role/GetAll?limit=500&offset=0&Order=desc`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createRoleCategoryService = async (token, nameUrl, roleCategory) => {
  const response = await axios.post(`/${nameUrl}/api/RoleCategory/Create`, roleCategory, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const editRoleCategoryService = async (token, nameUrl, roleCategory) => {
  const response = await axios.post(`/${nameUrl}/api/RoleCategory/Edit`, roleCategory, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const deleteRoleService = async (token, nameUrl, roleId) => {
  const response = await axios.post(
    `/${nameUrl}/api/Role/Delete`,
    {
      id: roleId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const deleteRoleCategoryService = async (token, nameUrl, roleCategoryId) => {
  const response = await axios.post(
    `/${nameUrl}/api/RoleCategory/Delete`,
    {
      roleCategoryId: roleCategoryId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const editRoleService = async (token, nameUrl, role) => {
  const response = await axios.post(`/${nameUrl}/api/Role/Edit`, role, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const removeRoleService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Employee/RoleUserDelete`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const addRoleService = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/Employee/RoleUserAdd`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//Segmentos
export const getSegmentService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/Segment/GetAll?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getSegmentIdService = async (token, nameUrl, limit, offset, order, segmentId) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }
  const response = await axios.get(
    `/${nameUrl}/api/SegmentGroup/GetAll?limit=${limit}&offsett=${offset}&Order=${order}&SegmentId=${segmentId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getUniqueSegmentService = async (token, nameUrl, segmentId) => {
  const response = await axios.get(`${nameUrl}/api/Segment/Get/${segmentId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getRoleUser = async (token, nameUrl, company, userId) => {
  const response = await axios.get(
    `/${nameUrl}/api/Employee/GetRoleUser?CompanyId=${company}&UserId=${userId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )
  console.log(response, 'list2')

  return response
}

export const getListUserRoles = async (token, nameUrl, userId) => {
  const response = await axios.get(`/${nameUrl}/api/Employee/RoleUserList?UserId=${userId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getAllRoles = async (token, nameUrl) => {
  const response = await axios.get(`/${nameUrl}/api/Role/GetAll?limit=80000&offset=0&Order=desc`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createSegmentGroup = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/SegmentGroup/Create`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getCategoryRolesID = async (token, nameUrl, segmentId) => {
  const response = await axios.get(`/${nameUrl}/api/SegmentGroup/Get/${segmentId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createSegmentService = async (token, nameUrl, segment) => {
  const response = await axios.post(
    `/${nameUrl}/api/Segment/Create`,
    {
      name: segment.name,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const deleteSegmentService = async (token, nameUrl, segmentId) => {
  const response = await axios.post(
    `/${nameUrl}/api/Segment/Delete`,
    {
      segmentId: segmentId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const editSegmentService = async (token, nameUrl, segment) => {
  const response = await axios.post(
    `/${nameUrl}/api/Segment/Edit`,
    {
      segmentId: segment.segmentId,
      name: segment.name,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Grupo de Segmentos
export const getSegmentGroupService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/SegmentGroup/GetAll?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getSegmentGroup = async (token, nameUrl, limit, offset, order, segmentId) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/SegmentGroup/GetAll?limit=${limit}&offset=${offset}&Order=${orderToString}&SegmentId=${segmentId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getUniqueSegmentGroupService = async (token, nameUrl, segmentGroupId) => {
  const response = await axios.get(`${nameUrl}/api/SegmentGroup/Get/${segmentGroupId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getSegmentSelected = async (token, segmentGroupId) => {
  const response = await axios.get(`/api/SegmentGroup/Get/${segmentGroupId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createSegmentGroupService = async (token, nameUrl, segmentGroup) => {
  const response = await axios.post(
    `/${nameUrl}/api/SegmentGroup/Create`,
    {
      name: segmentGroup.name,
      segmentId: segmentGroup.segmentId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const deleteSegmentGroupService = async (token, nameUrl, segmentGroupId) => {
  const response = await axios.post(
    `/${nameUrl}/api/SegmentGroup/Delete`,
    {
      segmentGroupId: segmentGroupId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const editSegmentGroupService = async (token, nameUrl, segmentGroup) => {
  const response = await axios.post(
    `/${nameUrl}/api/SegmentGroup/Edit`,
    {
      segmentGroupId: segmentGroup.segmentGroupId,
      name: segmentGroup.name,
      active: segmentGroup.active,
      segmentId: segmentGroup.segmentId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const editSegmentGroupData = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/SegmentGroup/Edit`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

//Regras dos Grupos de Segmentos
export const getSegmentGroupRoleService = async (token, nameUrl, segmentGroupId) => {
  const response = await axios.get(`${nameUrl}/api/SegmentGroupRole/Get/${segmentGroupId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createSegmentGroupRoleService = async (token, nameUrl, segmentGroupId, roleId) => {
  const response = await axios.post(
    `/${nameUrl}/api/SegmentGroupRole/Create`,
    {
      segmentGroupId: segmentGroupId,
      roleId: roleId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const deleteSegmentGroupRoleService = async (token, nameUrl, segmentGroupId, roleId) => {
  const response = await axios.post(
    `/${nameUrl}/api/SegmentGroupRole/Delete?SegmentGroupId=${segmentGroupId}&RoleId=${roleId}`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Atualizar Versão
export const getUpdateService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/Update/GetAll?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const createUpdateService = async (token, nameUrl, formData) => {
  var tokenFinal = 'Bearer ' + token

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
      Authorization: tokenFinal,
    },
  }

  const response = await axios.post(`/${nameUrl}/api/Update/Create`, formData, config)

  return response
}

//Planos
export const getPlanService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/Plan/GetAll?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getUniquePlanService = async (token, nameUrl, planId) => {
  const response = await axios.get(`${nameUrl}/api/Plan/Get/${planId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createPlanService = async (token, nameUrl, plan) => {
  const response = await axios.post(
    `/${nameUrl}/api/Plan/Create`,
    {
      plansCode: plan.plansCode,
      plansName: plan.plansName,
      active: plan.active,
      roleGroupId: plan.roleGroupId,
      recurrence: plan.recurrence,
      urlCheckout: plan.urlCheckout,
      price: plan.price,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const deletePlanService = async (token, nameUrl, planId) => {
  const response = await axios.post(
    `/${nameUrl}/api/Plan/Delete`,
    {
      plansId: planId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const updatesPrintTag = async (token, nameUrl, data) => {
  const response = await axios.post(`/${nameUrl}/api/AssociationItens/UpdatePrint`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const editPlanService = async (token, nameUrl, plan) => {
  const response = await axios.post(
    `/${nameUrl}/api/Plan/Edit`,
    {
      roleGroupId: plan.roleGroupId,
      plansId: plan.plansId,
      plansCode: plan.plansCode,
      plansName: plan.plansName,
      active: plan.active,
      recurrence: plan.recurrence,
      price: plan.price,
      urlCheckout: plan.urlCheckout,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Empresas
export const getCompanyService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/Company/GetAll?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getCountTicketCompany = async (token, limit, offset, order, filterDate) => {
  const response = await axios.get(
    `/api/MovimentationOrderTicket/GetCountTicketCompany?limit=${limit}&offset=${offset}&Order=${order}&filterDate=${filterDate}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const removeDuplicated = async (token, nameUrl, companyId) => {
  const response = await axios.get(
    `/${nameUrl}/api/AssociationItens/RemoveTagDuplicate?CompanyId=${companyId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}
export const getIdTikeckt = async (token, movimentationOrderTicket) => {
  const response = await axios.get(
    `/api/MovimentationOrderTicket/GetId?MovimentationOrderTicket=${movimentationOrderTicket}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getFinishTicket = async (token, movimentationOrderTicket) => {
  const response = await axios.get(
    `/api/MovimentationOrderTicket/Finish?MovimentationOrderTicketId=${movimentationOrderTicket}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getCancelTicket = async (token, movimentationOrderTicket) => {
  const response = await axios.get(
    `/api/MovimentationOrderTicket/Cancel?MovimentationOrderTicketId=${movimentationOrderTicket}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const createResponseTicket = async (token, data) => {
  const response = await axios.post(`/api/MovimentationOrderTicket/SendMessage`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createResponseTicketClient = async (token, data) => {
  console.log(data, 'datadata')
  const response = await axios.post(`/api/MovimentationOrderTicket/SendMessageClient`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getUniqueCompanyService = async (token, nameUrl, companyId) => {
  const response = await axios.get(`/${nameUrl}/api/Company/Get/${companyId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createCompanyService = async (token, nameUrl, company) => {
  const response = await axios.post(`/${nameUrl}/api/Company/Create`, company, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const deleteCompanyService = async (token, nameUrl, companyId) => {
  const response = await axios.post(
    `/${nameUrl}/api/Company/Delete`,
    {
      companyId: companyId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const editCompanyService = async (token, nameUrl, company) => {
  const response = await axios.post(
    `/${nameUrl}/api/Company/Edit`,
    {
      companyId: company.companyId,
      code: company.code,
      cnpj: company.cnpj,
      name: company.name,
      mobile: company.mobile,
      email: company.email,
      cep: company.cep,
      city: company.city,
      address: company.address,
      ddd: company.ddd,
      numberAddress: company.numberAddress,
      active: company.active,
      urlSend: company.urlSend,
      plansId: company.plansId,
      segmentId: company.segmentId,
      segment: company.segment,
      created: company.created,
      quantitySubCompanyActual: company.quantitySubCompanyActual,
      quantitySubCompanyTotal: company.quantitySubCompanyTotal,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

//Rezões para Pedidos
export const getAllReasonService = async (token, nameUrl) => {
  const response = await axios.get(`/${nameUrl}/api/ReasonForOrder/GetAll`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const getReasonService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/ReasonForOrder/GetAll?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getUniqueReasonService = async (token, nameUrl, reasonfororderId) => {
  const response = await axios.get(`${nameUrl}/api/ReasonForOrder/Get?id=${reasonfororderId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return response
}

export const createReasonService = async (token, nameUrl, reason) => {
  const response = await axios.post(
    `/${nameUrl}/api/ReasonForOrder/Create`,
    {
      name: reason.name,
      description: reason.description,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const deleteReasonService = async (token, nameUrl, reasonfororderId) => {
  const response = await axios.post(
    `/${nameUrl}/api/ReasonForOrder/Delete`,
    {
      reasonfororderId: reasonfororderId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const editReasonService = async (token, nameUrl, reason) => {
  const response = await axios.post(
    `/${nameUrl}/api/ReasonForOrder/Edit`,
    {
      reasonfororderId: reason.reasonfororderId,
      name: reason.name,
      description: reason.description,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}

export const getEmailGroupService = async (token, nameUrl, limit, offset, order) => {
  let orderToString
  if (order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }

  const response = await axios.get(
    `/${nameUrl}/api/ParametersGroup/GetAll?limit=${limit}&offset=${offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}
