import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  confirmOrder,
  getProductAssociationService,
  returnOrderStatus,
} from '../../../services/productService'
import { Context } from '../../../context/AuthContext'
import {
  editTransferOrderService,
  getUniqueTransferOrderService,
} from '../../../services/requestServices'

import * as XLSX from 'xlsx'
import PropTypes from 'prop-types'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import '../../../assets/global.css'
import { AiOutlineFileExcel } from 'react-icons/ai'

const SalesOrderEdit = ({
  setEditing,
  setAlerta,
  setSeverity,
  setTitle,
  labelOrderId,
  handleFetchData,
}) => {
  const { subCompany, nameUrl } = useContext(Context)
  const subCompanyId = subCompany[0].subCompanyId

  const token = localStorage.getItem('token')
  const [product, setProduct] = useState([])
  const [requestProductsConverted, setRequestProductsConverted] = useState([])
  const [selectProductId, setSelectProductId] = useState('')
  let requestProductsModel = {}
  const navigate = useNavigate()

  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')
  const [subCompanyIdFrom, setSubCompanyIdFrom] = useState('')
  const [movimentationOrderNumberBihands, setMovimentationOrderNumberBihands] = useState('')
  const [receiveMovimentationOrderDate, setReceiveMovimentationOrderDate] = useState('')
  const [createRequestMovimentationOrderDate, setCreateRequestMovimentationOrderDate] = useState('')
  const [typeOrderName, setTypeOrderName] = useState('')
  const [subCompanyNameFrom, setSubCompanyNameFrom] = useState('')
  const [movimentationOrderStatus, setMovimentationOrderStatus] = useState()
  const [isReceived, setIsReceived] = useState()
  const [finalizado, setFinalizado] = useState()

  const [progress, setProgress] = useState(0)
  const [downloadMessage, setDownloadMessage] = useState('Pronto')
  const [downloading, setDownloading] = useState(false)

  let dateTime = new Date()

  const [alertSuccess, setAlertSuccess] = useState(false)
  const [alertFailed, setAlertFailed] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [newItem, setNewItem] = useState(false)
  const [loading, setLoading] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const handleClose = () => {
    setAlertSuccess(false)
    setAlertFailed(false)
  }

  const requestProductsConverter = useCallback(rp => {
    let array = []

    rp.forEach(element => {
      requestProductsModel = {
        serial: element.serial,
        createDate: element.createDate,
        productName: element.productName,
        price: element.price,
        sku: element.sku,
        movimentationOrderId: element.movimentationOrderId,
        productItensId: element.productItensId,
        createDateRead: dateTime.toISOString(),
      }

      array.push(requestProductsModel)
    })

    setRequestProductsConverted(array)
  }, [])

  const downloadData = async () => {
    setDownloadMessage('Inicializando')
    setDownloading(true)
    finishDownload()
  }

  const finishDownload = async () => {
    let result = requestProductsConverted

    setDownloadMessage(`Seu arquivo está pronto.`)
    setDownloading(false)
    const relatorioFinal = []
    result.map(item => {
      relatorioFinal.push({
        Serial: item.serial,
        'Nome Produto': item.productName,
        Preço: parseFloat(item.price).toLocaleString('pt-br', { minimumFractionDigits: 2 }),
        SKU: item.sku,
        Quantidade: item.quantity,
        'Movimentação Bihands': item.movimentationOrderNumberBihands,
        'Movimentação Outro sistema': item.movimentationOrderNumberOtherSystem,
        Criado: new Date(item.createDate || item.created).toLocaleString(),
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Entrada de Estoque')
    XLSX.writeFile(workbook, `Entrada de estoque.xlsx`)
  }

  const getSalesOrder = async () => {
    setLoading(true)
    try {
      const response = await getUniqueTransferOrderService(token, nameUrl, labelOrderId)
      requestProductsConverter(response.data.movimentationOrderItemCollection)
      setSubCompanyIdFrom(response.data.subCompanyIdFrom)
      setMovimentationOrderNumberOtherSystem(response.data.movimentationOrderNumberOtherSystem)
      setMovimentationOrderNumberBihands(response.data.movimentationOrderNumberBihands)
      setReceiveMovimentationOrderDate(response.data.receiveMovimentationOrderDate)
      setCreateRequestMovimentationOrderDate(response.data.createRequestMovimentationOrderDate)
      setTypeOrderName('Entrada de Estoque')
      setSubCompanyNameFrom(response.data.subCompanyNameFrom)
      setMovimentationOrderStatus(returnOrderStatus(response.data.movimentationOrderStatus))
      if (response.data.movimentationOrderStatus === 2) {
        setFinalizado(true)
      }
      setIsReceived(response.data.isReceived)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const editSalesOrder = useCallback(async () => {
    setLoading(true)

    try {
      const response = await editTransferOrderService(token, nameUrl, requestProductsConverted)
      setLoading(false)
      setSeverity('success')
      setTitle('Entrada editada com sucesso!')
      setEditing(false)
      setAlerta(true)
      handleFetchData()
    } catch (err) {
      console.log(err)
      setLoading(false)
      setSeverity('error')
      setTitle('Erro ao editar, verifique os dados e tente novamente.')
      setAlerta(true)
    }
  }, [movimentationOrderNumberOtherSystem, subCompanyIdFrom, requestProductsConverted])

  const getProduct = useCallback(async () => {
    try {
      const response = await getProductAssociationService(
        token,
        nameUrl,
        10000,
        0,
        false,
        subCompanyId
      )
      setProduct(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }, [])

  const setTable = useCallback(async () => {
    try {
      const script = document.createElement('script')
      script.src = `../js/table.js`
      script.async = true

      script.onerror = () => {
        console.error('Erro ao carregar o script table.js')
      }

      document.body.appendChild(script)
      console.log('table.js carregado com sucesso')
    } catch (error) {
      console.error('Erro ao adicionar o script table.js', error)
    }
  }, [])

  const setTreeView = useCallback(async () => {
    try {
      const script = document.createElement('script')
      script.src = `../js/treeView.js`
      script.async = true

      script.onerror = () => {
        console.error('Erro ao carregar o script treeView.js')
      }

      document.body.appendChild(script)
      console.log('treeView.js carregado com sucesso')
    } catch (error) {
      console.error('Erro ao adicionar o script treeView.js', error)
    }
  }, [])

  const addRequestProducts = useCallback(() => {
    const result = product.find(item => item.productItensId === selectProductId)

    setRequestProductsConverted([...requestProductsConverted, result])
    setNewItem(false)
  }, [product, requestProductsConverted, selectProductId])

  const removeRequestProducts = useCallback(
    productItensId => {
      setRequestProductsConverted(
        requestProductsConverted.filter(item => item.productItensId !== productItensId)
      )
    },
    [product, requestProductsConverted, selectProductId]
  )

  useEffect(() => {
    getProduct()

    getSalesOrder()
  }, [labelOrderId])

  const handleConfirm = async () => {
    try {
      await confirmOrder(token, nameUrl, labelOrderId)
      navigate('/SalesOrder')
    } catch (e) {
      console.log(e)
      setAlertSuccess(false)
      setAlertFailed(true)
    }
  }

  return (
    <>
      <Box>
        <Dialog open={downloading} keepMounted aria-describedby="alert-dialog-slide-description">
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessage}</DialogTitle>
            <LinearProgressWithLabel value={progress} sx={{ width: '90%' }} />
          </Box>
        </Dialog>

        {/* Add Product */}
        <Dialog
          open={newItem}
          keepMounted
          //onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
            sx={{ padding: 3 }}
          >
            <Box>
              <InputLabel id="demo-simple-select-label">Produtos</InputLabel>
              <Select
                label="Produtos"
                value={selectProductId}
                onChange={values => setSelectProductId(values.target.value)}
                sx={{ width: '100%' }}
              >
                {product.map(item => {
                  return <MenuItem value={item.productItensId}>{item.productName}</MenuItem>
                })}
              </Select>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Button type="button" variant="contained" onClick={() => setNewItem(false)}>
                Fechar
              </Button>
              <Button
                type="button"
                variant="contained"
                sx={{ ml: 1 }}
                onClick={() => addRequestProducts()}
              >
                Salvar
              </Button>
            </Box>
          </Box>
        </Dialog>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alertSuccess}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Itens Salvos com Sucesso!
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alertFailed}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Algo deu Errado!
          </Alert>
        </Snackbar>
        <Box component="form" sx={{ mt: '2rem' }}>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                value={movimentationOrderNumberBihands}
                name="movimentationOrderNumberBihands"
                id="standard-basic"
                label="Ordem Bihands"
                variant="outlined"
                disabled
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="outlined-basic"
                label="Ordem Outro Sistema"
                variant="outlined"
                name="MovimentationOrderNumberOtherSystem"
                value={movimentationOrderNumberOtherSystem}
                disabled
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                name="ReceiveMovimentationOrderDate"
                value={receiveMovimentationOrderDate}
                id="outlined-basic"
                label="Data do Recebimento"
                variant="outlined"
                disabled
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="outlined-basic"
                label="Data da Solicitação"
                variant="outlined"
                name="CreateRequestMovimentationOrderDate"
                value={createRequestMovimentationOrderDate}
                disabled
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                name="TypeOrder"
                value={typeOrderName}
                id="outlined-basic"
                label="Tipo de Pedido"
                variant="outlined"
                disabled
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="outlined-basic"
                label="Filial"
                variant="outlined"
                name="SubCompanyNameFrom"
                value={subCompanyNameFrom}
                disabled
                type="text"
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                name="MovimentationOrderStatus"
                value={movimentationOrderStatus}
                id="outlined-basic"
                label="Status da movimentação"
                variant="outlined"
                disabled
                type="text"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}>
              <TextField
                sx={{ width: '100%', mt: '1rem' }}
                id="outlined-basic"
                label="Recebido"
                variant="outlined"
                name="IsReceived"
                value={isReceived ? 'Sim' : 'Não'}
                disabled
                type="text"
              />
            </Box>
          </Box>
          <Box
            width={{ xs: '40%', sm: '40%', md: '20%', lg: '20%', xl: '20%' }}
            sx={{ mt: '1rem', alignItems: 'center' }}
          >
            <Button
              variant="contained"
              sx={{ width: '100%' }}
              type="button"
              onClick={() => setEditing(false)}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
        <Box sx={{ mt: 3 }}>
          {!finalizado && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ width: '80%' }}>
                {movimentationOrderStatus === 'Aberto' ? (
                  <Box>
                    <Button
                      variant="contained"
                      width={{ xs: '40%', sm: '40%', md: '20%', lg: '20%', xl: '20%' }}
                      onClick={() => setNewItem(true)}
                    >
                      + Novo Item
                    </Button>
                    <Button
                      sx={{ ml: 1 }}
                      onClick={() => editSalesOrder()}
                      variant="contained"
                      width={{ xs: '40%', sm: '40%', md: '20%', lg: '20%', xl: '20%' }}
                    >
                      Salvar Itens
                    </Button>
                  </Box>
                ) : (
                  <Button onClick={() => handleConfirm()} variant="contained">
                    Confirmar
                  </Button>
                )}
              </Box>
              {requestProductsConverted && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                  <button className="buttonDocs" onClick={downloadData}>
                    Excel <AiOutlineFileExcel size="2em" />
                  </button>
                </Box>
              )}
            </Box>
          )}
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell> </StyledTableCell>
                  <StyledTableCell align="center">Serial</StyledTableCell>
                  <StyledTableCell align="center">Data de Criação</StyledTableCell>
                  <StyledTableCell align="center">Data do Recebimento</StyledTableCell>
                  <StyledTableCell align="center">Nome do Produto</StyledTableCell>
                  <StyledTableCell align="center">Preço</StyledTableCell>
                  <StyledTableCell align="center">SKU</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {requestProductsConverted
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => (
                    <StyledTableRow key={row.serial}>
                      <StyledTableCell component="th" scope="row">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '80px',
                          }}
                        >
                          <DeleteForeverIcon
                            onClick={() => removeRequestProducts(row.productItensId)}
                            sx={{ cursor: 'pointer' }}
                          />
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.serial}</StyledTableCell>
                      <StyledTableCell align="center">{row.created}</StyledTableCell>
                      <StyledTableCell align="center">{'-'}</StyledTableCell>
                      <StyledTableCell align="center">{row.productName}</StyledTableCell>
                      <StyledTableCell align="center">{row.price}</StyledTableCell>
                      <StyledTableCell align="center">{row.sku}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={requestProductsConverted.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default SalesOrderEdit
