import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { ClientDelete, ClientEdit, ClientCreate } from '../../../const/RoleConstants'
import HelpIcon from '@mui/icons-material/Help'
import { setPermission } from '../../../permissions/userPermissions'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Tooltip,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Skeleton,
  InputAdornment,
  Grid,
} from '@mui/material'
import { AiFillPlusCircle } from 'react-icons/ai'

//icones react-icons
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import { deleteClient, getClient } from '../../../services/clientService'
import ClientPCreate from '../../Create/ClientCreate'
import ClientPEdit from '../../Edit/ClientPEdit'
import PermissionComponent from '../../../permissions'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'
import CreateSensorAndPerson from '../../Create/CreateSensorAndPerson'
import {
  deleteSensorAndPerson,
  getAllGatewayLocation,
  getReportPeople,
  searchProductSubCompanyService,
} from '../../../services/productService'
import { useDebounce } from '../Product/Components/useDebounce'
import { DeleteForever, ModeEditOutline, Search } from '@mui/icons-material'
import SensorAndPersonEdit from '../../Edit/SensorAndPersonEdit'
import GatewayLocationCreate from '../../Create/GatewayLocationCreate'
import GatewayLocationEdit from '../../Edit/GatewayLocationEdit'
import { deleteAlocationStockService } from '../../../services/alocationServices'
import moment from 'moment'
import { convertToMeters } from '../../../utils/convertToMeters'

const ReportPeople = () => {
  const token = localStorage.getItem('token')

  const { subCompany, userRoles, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  //constantes crud
  const currentMonth = moment().startOf('month')
  const firstDay = currentMonth.format('YYYY-MM-DD')
  const lastDay = currentMonth.endOf('month').format('YYYY-MM-DD')
  const [startDate, setStartDate] = useState(firstDay)
  const [finishDate, setFinishDate] = useState(lastDay)

  const [expanded, setExpanded] = useState(false)
  const [editing, setEditing] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [open, setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('')
  const vertical = 'top'
  const horizontal = 'center'
  const [idEditing, setIdEditing] = useState({})

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)
  const [filterTable, setFilterTable] = useState('')
  const [filterCNPJ, setFilterCNPJ] = useState('')
  const [clientList, setClientList] = useState([])
  const [filterRow, setFilterRow] = useState([])
  const [notSearch, setNotSearch] = useState(false)
  const [search, setSearch] = useState('')
  const [reportsPeople, setReportsPeople] = useState([])
  const [totalProducts, setTotalProducts] = useState(0)
  const [searchR, setSearchR] = useState('')
  const [selectedId, setSelectedId] = useState(undefined)
  const [currentPage, setCurrentPage] = useState(0)
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const getFirstDayOfYear = () => {
    const firstDay = moment().startOf('year')
    return firstDay.format('YYYY-MM-DD')
  }

  const firstDayOfYear = getFirstDayOfYear()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const handleGetAll = async subCompanyId => {
    setSubCompanyId(subCompanyId)
    setLoading(true)

    try {
      const response = await getReportPeople(token, nameUrl, subCompanyId, startDate, finishDate)
      const data = response.data
      setReportsPeople(data)
      setTotalProducts(response.data.length)
    } catch (error) {
      console.error('Error fetching reportsPeople:', error)
      setReportsPeople([])
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (subCompanyId) {
      handleGetAll(subCompanyId)
    }
  }, [subCompanyId])

  const handleClose = () => {
    setAlerta(false)
    setOpen(false)
  }

  const filter = () => {
    const filtered = reportsPeople.filter(
      row =>
        row.people.toLowerCase().includes(search.toLowerCase()) ||
        row.serial.toLowerCase().includes(search.toLowerCase())
    )
    setPage(0)
    setFilterRow(filtered)
  }

  useEffect(() => {}, [idEditing])

  useEffect(() => {
    filter()
  }, [reportsPeople, search])

  return (
    <>
      <Box sx={{ mt: 8.5, height: 'fit-content' }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Relatório de localidade</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon />
          </IconButton>
        </Box>
        {/* Criar  */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <SelectSubCompany
              value={subCompanyId}
              onChange={e => {
                handleGetAll(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} mt={2}>
            <TextField
              style={{ backgroundColor: '#fff', width: '100%' }}
              variant="outlined"
              value={startDate}
              onChange={values => {
                setStartDate(values.target.value)
              }}
              sx={{ width: '100%' }}
              name="createDate"
              type="date"
              label="Data de criação"
              disabled={loading}
              inputProps={{
                min: firstDayOfYear,
                max: finishDate,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} mt={2}>
            <TextField
              style={{ backgroundColor: '#fff', width: '100%' }}
              variant="outlined"
              value={finishDate}
              disabled={loading}
              onChange={values => setFinishDate(values.target.value)}
              sx={{ width: '100%' }}
              name="endDate"
              label="Data final"
              type="date"
            />
          </Grid>
        </Grid>

        {/* Deletar */}

        <Box width="100%" mt={2}>
          <TextField
            sx={{ width: '100%', maxWidth: '250px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: loading && (
                <InputAdornment position="end">
                  <CircularProgress color="info" size={20} />
                </InputAdornment>
              ),
            }}
            label="Pesquisar"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </Box>
        <TableContainer component={Paper}>
          <Typography sx={{ width: 1, my: 2 }}>
            {filterRow?.length > 1
              ? `${filterRow.length} Resultados`
              : `${filterRow.length} Resultado`}
          </Typography>

          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow sx={{ bgcolor: '#F4F4F6' }}>
                <TableCell align="left" width="200px" sx={{ fontWeight: 700 }}>
                  Pessoa
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 700 }}>
                  Serial
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 700 }}>
                  Localidade
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 700 }}>
                  Media em metros
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 700 }}>
                  Tempo de permanência
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 700 }}>
                  Data de inicio
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 700 }}>
                  Data final
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              Array.from({ length: 10 }).map((_, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Array.from({ length: 7 }).map((_, colIndex) => (
                    <TableCell key={colIndex}>
                      <Skeleton animation="wave" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableBody>
                {filterRow
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <TableCell align="left">{row.people}</TableCell>
                      <TableCell align="center">{row.serial}</TableCell>
                      <TableCell align="center">{row.location}</TableCell>
                      <TableCell align="center">{convertToMeters(row.metros, 'm')}</TableCell>
                      <TableCell align="center">{row.totalVisibleTimeInMinutes}</TableCell>
                      <TableCell align="center">
                        {new Date(row.intervalStart).toLocaleString()}
                      </TableCell>
                      <TableCell align="center">
                        {new Date(row.intervalEnd).toLocaleString()}
                      </TableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            )}
          </Table>
          <TablePagination
            labelRowsPerPage={'Itens por página'}
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filterRow.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ReportPeople
