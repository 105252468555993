import HelpIcon from '@mui/icons-material/Help'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  FormControl,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import { Context } from '../../../context/AuthContext'
import { handleInventoryMovement } from '../../../services/reportServices'

//icones react-icons
import { AiOutlineFileExcel } from 'react-icons/ai'
import '../../../assets/global.css'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import SelectSubCompany from '../../ComponentList/SelectSubCompany'

const ReportInventoryMovement = () => {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState(
    subCompany.length === 1 ? subCompany[0].subCompanyId : ''
  )
  const [startDate, setStartDate] = useState('2020-01-01')
  const [finishDate, setFinishDate] = useState(new Date().toISOString().substring(0, 10))
  const [loading, setLoading] = useState(false)
  const [dissassociationData, setDissassociationData] = useState([])
  const [table, setTable] = useState(false)

  const [progressCount, setProgressCount] = useState(0)
  const [downloadMessageCount, setDownloadMessageCount] = useState('Pronto')
  const [downloadingCount, setDownloadingCount] = useState(false)
  const [totalPagesDownload, setTotalPagesDownload] = useState()
  const [totalData, setTotalData] = useState()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [viewElearning, setViewElearning] = useState(false)
  const [filterDeposit, setFilterDeposit] = useState('')
  const [filterProduct, setFilterProduct] = useState('')
  const [filterMovimentation, setFilterMovimentation] = useState('')
  const [backupData, setBackupData] = useState([])
  const [notSearch, setNotSearch] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const handleGetDissassociation = async e => {
    e.preventDefault()
    setLoading(true)
    setTable(false)
    const data = {
      subCompanyId: subCompanyId,
      startDate: startDate,
      finishDate: finishDate,
      limit: 50,
      offset: 1,
    }
    try {
      const response = await handleInventoryMovement(token, nameUrl, data)
      if (!response || response.data.length === 0) {
        setTitle('Não existem dados para esse período.')
        setAlerta(true)
        setSeverity('error')
        setDissassociationData([])
        setBackupData([])
      } else {
        setDissassociationData(response.data)
        setBackupData(response.data)
        setTotalPagesDownload(response.data.length + 1)
        setTotalData(response.data.total + 1)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const downloadDataCount = async () => {
    setDownloadMessageCount('Inicializando')
    setDownloadingCount(true)
    finishDownloadCount()
  }

  const finishDownloadCount = async () => {
    let result = []
    for (let i = 0; i <= 50; i++) {
      setTimeout(() => {
        setDownloadMessageCount(`Baixando ${i} de ${page + 1}...`)
        setProgressCount((i / 100) * 100)
      }, 1000)
    }
    const data = {
      subCompanyId: subCompanyId,
      startDate: startDate,
      finishDate: finishDate,
    }
    const resp = await handleInventoryMovement(token, nameUrl, data)
    result = resp.data
    for (let i = 50; i <= 100; i++) {
      setTimeout(() => {
        setDownloadMessageCount(`Baixando ${i} de ${page + 1}...`)
        setProgressCount((i / 100) * 100)
      }, 1000)
    }
    setDownloadMessageCount(`Seu arquivo está pronto.`)
    setDownloadingCount(false)
    const relatorioFinal = []

    result.map(item => {
      relatorioFinal.push({
        'Data criação': new Date(item.createDate).toLocaleString(),
        Produto: item.productName,
        Quantidade: item.quantity,
        'Número Palete': item.numberPallet,
        Locação: item.locationName,
        Lote: item.lote,
        Depósito: item.depositName,
        Filial: item.subCompanyName,
        Movimentação: item.typeMovimentations,
        Cliente: item.clientName,
        Usuário: item.createUserName,
        Serial: item.serial,
        Picking: item.picking,
        'Ordem Bihands': item.movimentationOrderNumberBihands,
        'Ordem Outro Sistema': item.movimentationOrderNumberOtherSystem,
      })
    })

    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Movimentação de estoque')
    XLSX.writeFile(workbook, `Movimentação de estoque.xlsx`)
    setProgressCount(0)
  }

  const handleClose = () => {
    setAlerta(false)
  }

  useEffect(() => {
    const handleFilter = () => {
      if (filterDeposit !== '') {
        setNotSearch(false)
        const newData = backupData
        const filter = newData.filter(data =>
          data.depositName.toLowerCase().includes(filterDeposit.toLowerCase())
        )
        if (filter.length > 0) {
          setDissassociationData(filter)
          setPage(0)
        } else {
          setNotSearch(true)
        }
      } else setDissassociationData(backupData)
    }
    handleFilter()
  }, [filterDeposit])

  useEffect(() => {
    const handleFilter = () => {
      if (filterProduct !== '') {
        setNotSearch(false)
        const newData = backupData
        const filter = newData.filter(data =>
          data.productName.toLowerCase().includes(filterProduct.toLowerCase())
        )
        if (filter.length > 0) {
          setDissassociationData(filter)
          setPage(0)
        } else {
          setNotSearch(true)
        }
      } else setDissassociationData(backupData)
    }
    handleFilter()
  }, [filterProduct])

  useEffect(() => {
    const handleFilter = () => {
      if (filterMovimentation !== '') {
        setNotSearch(false)
        const newData = backupData
        if (filterMovimentation === 'ALL') {
          setDissassociationData(newData)
          setPage(0)
        } else {
          const filter = newData.filter(data =>
            data.typeMovimentations.toLowerCase().includes(filterMovimentation.toLowerCase())
          )
          if (filter.length > 0) {
            setDissassociationData(filter)
            setPage(0)
          } else {
            setDissassociationData(filter)
            setPage(0)
            setNotSearch(true)
          }
        }
      } else setDissassociationData(backupData)
    }
    handleFilter()
  }, [filterMovimentation])

  const handleClearFilter = () => {
    setFilterDeposit('')
    setFilterProduct('')
    setFilterMovimentation('')
    setDissassociationData(backupData)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={downloadingCount}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessageCount}</DialogTitle>
            <LinearProgressWithLabel value={progressCount} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <ViewElearning
          pageName={'ReportDissassociation'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Relatório de movimentação de estoque</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box component="form" onSubmit={handleGetDissassociation}>
          <Box
            sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box sx={{ mt: 2, width: '100%' }}>
              <SelectSubCompany
                value={subCompanyId}
                onChange={e => {
                  setSubCompanyId(e.target.value)
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Data de criação</InputLabel>
              <TextField
                variant="outlined"
                value={startDate}
                onChange={values => {
                  setStartDate(values.target.value)
                }}
                sx={{ width: '100%' }}
                name="createDate"
                type="date"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Data final</InputLabel>
              <TextField
                variant="outlined"
                value={finishDate}
                onChange={values => setFinishDate(values.target.value)}
                sx={{ width: '100%' }}
                name="endDate"
                type="date"
              />
            </Box>
          </Box>
          <Button variant="contained" type="submit">
            Pesquisar
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            mb: 2,
          }}
        >
          <Typography variant="h6">Resultados</Typography>
          {dissassociationData.length > 0 && (
            <Button
              variant="contained"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: ' space-between',
                fontSize: '.9em',
                width: '6.5rem',
              }}
              onClick={downloadDataCount}
            >
              Excel <AiOutlineFileExcel size="2em" />
            </Button>
          )}
        </Box>

        <Box
          sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box width={{ xs: '100%', sm: '100%', md: '35%', lg: '35%', xl: '35%' }} sx={{ mt: 2 }}>
            <TextField
              variant="outlined"
              label={!notSearch ? 'Filtrar Produto' : 'Produto Não encontrado'}
              value={filterProduct}
              onChange={values => setFilterProduct(values.target.value)}
              sx={{ width: '80%' }}
              error={notSearch}
              type="text"
            />
          </Box>
          <Box width={{ xs: '100%', sm: '100%', md: '35%', lg: '35%', xl: '35%' }} sx={{ mt: 2 }}>
            <TextField
              variant="outlined"
              label={!notSearch ? 'Filtrar Depósito' : 'Depósito não encontrado'}
              value={filterDeposit}
              onChange={values => setFilterDeposit(values.target.value)}
              sx={{ width: '80%' }}
              error={notSearch}
              type="text"
            />
          </Box>
          <Box width={{ xs: '100%', sm: '100%', md: '35%', lg: '35%', xl: '35%' }} sx={{ mt: 2 }}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-label">Filtrar Movimentação</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterMovimentation}
                onChange={e => setFilterMovimentation(e.target.value)}
                sx={{ width: '80%' }}
              >
                <MenuItem value="ALL">Todos</MenuItem>
                <MenuItem value="STOCKENTRY">Entrada do Estoque</MenuItem>
                <MenuItem value="STOCKOUT">Saída do Estoque</MenuItem>
                <MenuItem value="TRANFERENCE_LOCATION_OUT">
                  Transferência de Locação (Saída)
                </MenuItem>
                <MenuItem value="RANFERENCE_LOCATION_IN">
                  Transferência de Locação (Entrada)
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Button variant="contained" onClick={() => handleClearFilter()} sx={{ mt: 2 }}>
            Limpar filtros
          </Button>
        </Box>
        <TableContainer component={Paper} sx={{ mt: '2rem' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Data Requisição</StyledTableCell>
                <StyledTableCell align="left">Produto</StyledTableCell>
                <StyledTableCell align="left">Quantidade</StyledTableCell>
                <StyledTableCell align="left">Número Palete</StyledTableCell>
                <StyledTableCell align="left">Locação</StyledTableCell>
                <StyledTableCell align="left">Lote</StyledTableCell>
                <StyledTableCell align="left">Depósito</StyledTableCell>
                <StyledTableCell align="left">Filial</StyledTableCell>
                <StyledTableCell align="left">Movimentação</StyledTableCell>
                <StyledTableCell align="left">Cliente</StyledTableCell>
                <StyledTableCell align="left">Usuário</StyledTableCell>
                <StyledTableCell align="left">Serial</StyledTableCell>
                <StyledTableCell align="left">Picking</StyledTableCell>
                <StyledTableCell align="left">Ordem Bihands</StyledTableCell>
                <StyledTableCell align="left">Ordem Outro Sistema</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dissassociationData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left">
                      {new Date(row.createDate).toLocaleString()}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.productName}</StyledTableCell>
                    <StyledTableCell align="left">{row.quantity}</StyledTableCell>
                    <StyledTableCell align="left">{row.numberPallet}</StyledTableCell>
                    <StyledTableCell align="left">{row.locationName}</StyledTableCell>
                    <StyledTableCell align="left">{row.lote}</StyledTableCell>
                    <StyledTableCell align="left">{row.depositName}</StyledTableCell>
                    <StyledTableCell align="left">{row.subCompanyName}</StyledTableCell>
                    <StyledTableCell align="left">{row.typeMovimentations}</StyledTableCell>
                    <StyledTableCell align="left">{row.clientName}</StyledTableCell>
                    <StyledTableCell align="left">{row.createUserName}</StyledTableCell>
                    <StyledTableCell align="left">{row.serial}</StyledTableCell>
                    <StyledTableCell align="left">{row.picking}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.movimentationOrderNumberBihands}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.movimentationOrderNumberOtherSystem}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={dissassociationData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ReportInventoryMovement
