import { Add, ExpandMore, Remove, Search, ShoppingCart } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { styled } from '@mui/system'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import {
  createSalesBucket,
  getProductCodService,
  searchProductService,
} from '../../../services/productService'
import { getClient, getClientSearch } from '../../../services/clientService'
import PermissionComponent from '../../../permissions'
import ClientPCreate from '../../Create/ClientCreate'
import { useDebounce } from '../Product/Components/useDebounce'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${TableCell.head}`]: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
  },
  [`&.${TableCell.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const formatToBRL = value => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value)
}

const PdvClient = () => {
  const token = localStorage.getItem('token')
  const { subCompany, nameUrl, company, userId } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [subCompanyCNPJ, setSubCompanyCNPJ] = useState('')
  const [product, setProduct] = useState()
  const { debounce } = useDebounce(1000, true)

  const [products, setProducts] = useState([])
  const [listProducts, setListProducts] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedProduct, setSelectedProduct] = useState(undefined)
  const [cart, setCart] = useState([])
  const [rfidList, setRfidList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')
  const [responseMessageInput, setResponseMessageInput] = useState('')
  const [priceDiscountValue, setPriceDiscountValue] = useState('')
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)
  const [searchingProduct, setSearchingProduct] = useState(false)
  const [clientSelected, setClientSelected] = useState({})
  const [client, setClient] = useState({})
  const [productSelected, setProductSelected] = useState({})
  const [clients, setClients] = useState([])
  const [expanded, setExpanded] = useState(false)
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [searchProduct, setSearchProduct] = useState('')
  const [severity, setSeverity] = useState('warning')
  const searchFieldRef = useRef(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalProducts, setTotalProducts] = useState(0)
  const [searchR, setSearchR] = useState('')
  const [quantities, setQuantities] = useState([])
  const [accordionExpanded, setAccordionExpanded] = useState(false)

  useEffect(() => {
    if (products.length > 0) {
      setQuantities(products.map(() => 0))
    }
  }, [products])

  const handleSearchChange = e => {
    setSearchTerm(e.target.value)
  }

  const handleIncrease = index => {
    setQuantities(prev => ({
      ...prev,
      [index]: (prev[index] || 0) + 1,
    }))
  }

  const handleDecrease = index => {
    setQuantities(prev => ({
      ...prev,
      [index]: Math.max((prev[index] || 0) - 1, 0),
    }))
  }

  const handleInputChange = (index, value) => {
    const parsedValue = parseInt(value, 10)
    setQuantities(prevQuantities => {
      // Se prevQuantities for undefined, inicialize como um array vazio
      const newQuantities = Array.isArray(prevQuantities) ? [...prevQuantities] : []
      newQuantities[index] = isNaN(parsedValue) ? 0 : parsedValue
      return newQuantities
    })
  }
  const handleAddProduct = index => {
    const quantity = quantities[index] || 0

    const prod = {
      quantidade: quantity,
      valor: products[index].price,
      descricao: products[index].name,
      produto: {
        id: products[index].productId,
      },
    }

    setListProducts(prevList => {
      const existingProductIndex = prevList.findIndex(item => item.produto.id === prod.produto.id)

      if (existingProductIndex !== -1) {
        const updatedList = [...prevList]
        updatedList[existingProductIndex].quantidade += quantity
        return updatedList
      } else {
        return [...prevList, prod]
      }
    })
  }

  const calculateTotal = () => {
    return listProducts.reduce((total, item) => total + item.quantidade * item.valor, 0).toFixed(2)
  }
  const handleGetProductCodService = async event => {
    try {
      setIsLoading(true)
      const data = {
        subCompanyId: subCompanyId,
        rfidCode: searchTerm,
      }

      const response = await getProductCodService(token, nameUrl, data)
      if (response.data) {
        const productWithQuantity = { ...response.data, quantity: 1 }
        setProducts(prev => [...prev, productWithQuantity])
        handleCloseModal()
      }

      setRfidList(prevList => [...prevList, searchTerm])
      setSearchTerm('')
      searchFieldRef.current.focus()
    } catch (error) {
      setResponseMessageInput('Codigo RFID não associado!')

      console.error('Erro ao buscar o produto:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleRemoveFromCart = id => {
    setListProducts(listProducts.filter(item => item.produto.id !== id))
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleGetProductCodService()
    }
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const groupProductsById = products => {
    const groupedProducts = products.reduce((acc, item) => {
      const existingProduct = acc.find(p => p.idProductPartner === item.idProductPartner)
      if (existingProduct) {
        existingProduct.quantity += item.quantity
      } else {
        acc.push({ ...item })
      }
      return acc
    }, [])

    return groupedProducts
  }

  const handleGetAllProductsSearch = async () => {
    setIsLoading(true)
    const dataProduct = {
      companyId: company.companyId,
      limit: 10,
      offset: 0,
      order: 'desc',
      SearchWord: searchProduct,
    }
    try {
      searchProductService(token, nameUrl, dataProduct).then(response => {
        const data = response.data.result
        setProducts(data)
        setTotalProducts(response.data.total)
        setSearchR(response.data.total)
        setIsLoading(false)
      })
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setCurrentPage(0)
    if (company.companyId) {
      setIsLoading(true)
      debounce(() => {
        handleGetAllProductsSearch()
      })
    }
  }, [searchProduct, page, company, rowsPerPage])

  const handleFinish = async () => {
    // Verificar se as informações necessárias estão definidas
    if (!client || !userId || !subCompanyId) {
      console.error('Informações necessárias não estão definidas', { client, userId, subCompanyId })
      return // Impede a execução do código se as informações estiverem ausentes
    }

    const data = {
      data: new Date().toISOString(),
      dataSaida: new Date().toISOString(),
      dataPrevista: new Date().toISOString(),
      contato: {
        id: client.id, // Acessa client.id apenas se client estiver definido
      },
      loja: {
        id: subCompanyId, // Acessa subCompanyId apenas se estiver definido
      },
      categoria: {
        id: null, // Assumindo que isso está correto para o seu caso
      },
      vendedor: {
        id: userId, // Acessa user.id apenas se user estiver definido
      },
      desconto: {
        valor: priceDiscountValue, // Certifique-se que priceDiscountValue também esteja definido
        unidade: '%',
      },
    }

    console.log(data, 'dados para enviar')
    // Aqui você pode adicionar a lógica para enviar `data` para sua API
  }

  const fetchData = async subCompanyId => {
    if (!subCompanyId) {
      return
    }

    setSubCompanyId(subCompanyId)
    setLoading(true)
    try {
      const response = await getClientSearch(token, nameUrl, subCompanyId, search)

      if (response.data.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
        setAccordionExpanded(true)
      } else {
        setClients(response.data)
        setAccordionExpanded(false)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  return (
    <Box sx={{ mt: 5.5 }}>
      <Typography variant="h4" gutterBottom>
        Ponto de Venda (PDV)
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <InputLabel id="demo-simple-select-label">Filial</InputLabel>
          <Select
            label="Filial Saída"
            value={subCompanyId}
            onChange={values => {
              const cnpj = subCompany.find(item => item.subCompanyId === values.target.value)
              setSubCompanyCNPJ(cnpj.cnpjCompany, 'cnsda')
              setSubCompanyId(values.target.value)
            }}
            sx={{ width: '100%' }}
          >
            {subCompany.map((item, index) => (
              <MenuItem value={item.subCompanyId} key={index}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Cliente
          </Typography>
          <Autocomplete
            disablePortal
            id="lista-clientes"
            options={clients}
            autoHighlight
            disabled={isLoading || !subCompanyId}
            autoSelect
            value={client}
            loading={searching}
            loadingText="Procurando..."
            noOptionsText="Nenhum cliente"
            renderOption={(props, option) => (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            )}
            renderInput={params => <TextField {...params} label="Procurar cliente" />}
            onInputChange={(e, value) => {
              setSearch(value)
            }}
            onChange={(e, value) => {
              if (value === null) {
                setClient(null)
                setClientSelected(null)
              } else {
                setClient(value)
                setClientSelected(value.id)
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Descontos
          </Typography>
          <Box>
            <TextField
              autoFocus
              fullWidth
              variant="outlined"
              value={priceDiscountValue}
              disabled={isLoading || !subCompanyId}
              onChange={e => setPriceDiscountValue(e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Accordion
            expanded={accordionExpanded}
            onChange={() => setAccordionExpanded(!accordionExpanded)}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => setAccordionExpanded(!accordionExpanded)}
            >
              <Typography>{'Cadastrar Cliente'}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PermissionComponent role="Create_Client">
                <ClientPCreate
                  setExpanded={setAccordionExpanded}
                  setAlerta={setAlerta}
                  setSeverity={setSeverity}
                  setTitle={setTitle}
                  handleFetchData={fetchData}
                />
              </PermissionComponent>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardContent sx={{ p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Typography variant="h6" gutterBottom>
                    Produtos
                  </Typography>
                  <TextField
                    sx={{ width: '100%' }}
                    disabled={isLoading || !subCompanyId}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                      endAdornment: isLoading && (
                        <InputAdornment position="end">
                          <CircularProgress color="info" size={20} />
                        </InputAdornment>
                      ),
                    }}
                    label="Pesquisar"
                    value={searchProduct}
                    onChange={e => setSearchProduct(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">Nome</StyledTableCell>

                          <StyledTableCell align="left">Preço</StyledTableCell>
                          <StyledTableCell align="center">Quantidade</StyledTableCell>
                          <StyledTableCell align="center">Ações</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {isLoading ? (
                        Array.from({ length: 10 }).map((_, rowIndex) => (
                          <TableRow key={rowIndex}>
                            {Array.from({ length: 5 }).map((_, colIndex) => (
                              <TableCell key={colIndex}>
                                <Skeleton animation="wave" />
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      ) : (
                        <TableBody>
                          {products.map((row, index) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell component="th" scope="row">
                                {row.name}
                              </StyledTableCell>
                              <StyledTableCell>{formatToBRL(row.price)}</StyledTableCell>

                              <StyledTableCell align="center" width="200px">
                                <IconButton
                                  onClick={() => handleDecrease(index)}
                                  aria-label="decrease"
                                  disabled={quantities[index] === 0 || isLoading || !subCompanyId}
                                >
                                  <Remove />
                                </IconButton>
                                <TextField
                                  value={quantities[index] || 0}
                                  onChange={e => handleInputChange(index, e.target.value)}
                                  size="small"
                                  style={{ width: 50, textAlign: 'center' }}
                                  inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                  disabled={isLoading || !subCompanyId}
                                />
                                <IconButton
                                  onClick={() => handleIncrease(index)}
                                  aria-label="increase"
                                  disabled={isLoading || !subCompanyId}
                                >
                                  <Add />
                                </IconButton>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  disabled={quantities[index] === 0 || isLoading || !subCompanyId}
                                  onClick={() => handleAddProduct(index)}
                                >
                                  Adicionar
                                </Button>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item xs={12} md={6}>
                  {open && (
                    <Box px={2}>
                      <Card
                        variant="outlined"
                        sx={{
                          bgcolor: 'background.paper',
                          borderRadius: 1,
                          p: 4,
                          mt: 2,
                        }}
                      >
                        {selectedProduct && (
                          <>
                            <Typography variant="h6" gutterBottom>
                              {selectedProduct.productName}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                              {formatToBRL(selectedProduct.price)}
                            </Typography>
                          </>
                        )}
                      </Card>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <Typography variant="h6" gutterBottom p={2}>
              Carrinho de Compras
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Produto</StyledTableCell>
                    <StyledTableCell>Quantidade</StyledTableCell>
                    <StyledTableCell>Preço</StyledTableCell>
                    <StyledTableCell>Ação</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listProducts.map(item => (
                    <StyledTableRow key={item.produto.id}>
                      <StyledTableCell>{item.descricao}</StyledTableCell>
                      <StyledTableCell>{item.quantidade}</StyledTableCell>
                      <StyledTableCell>{formatToBRL(item.valor)}</StyledTableCell>
                      <StyledTableCell>
                        <IconButton
                          onClick={() => handleRemoveFromCart(item.produto.id)}
                          color="error"
                        >
                          <Remove />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider />
            <Box
              bgcolor="#f5f5f5"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={2}
            >
              <Typography variant="h6">Total: {formatToBRL(calculateTotal())}</Typography>

              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={
                  loading ? <CircularProgress size={24} color="inherit" /> : <ShoppingCart />
                }
                onClick={handleFinish}
                disabled={loading || listProducts.length === 0 || !subCompanyId}
              >
                {loading ? 'Finalizando...' : 'Finalizar Compra'}
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        open={Boolean(responseMessage)}
        autoHideDuration={3000}
        onClose={() => setResponseMessage('')}
        message={responseMessage}
      />
    </Box>
  )
}

export default PdvClient
