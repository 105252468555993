import { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { getSalesService, getSalesServicePagination } from '../../../services/reportServices'
import { RESULTS_PER_PAGE } from '../../../utils/excel'
import * as XLSX from 'xlsx'
import PropTypes from 'prop-types'
import HelpIcon from '@mui/icons-material/Help'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'

//icones react-icons
import { AiFillFileExcel } from 'react-icons/ai'
import { colorTableDefault } from '../../TemplateDefault'
import '../../../assets/global.css'
import ViewElearning from '../../ViewElearning'

const ReportPurchase = () => {
  const { subCompany, nameUrl } = useContext(Context)
  const [subCompanyId, setSubCompanyId] = useState('')
  const [createDateTime, setCreateDateTime] = useState('2020-01-01')
  const [endDateTime, setEndDateTime] = useState(new Date().toISOString().substring(0, 10))
  const [movimentationOrderNumberOtherSystem, setMovimentationOrderNumberOtherSystem] = useState('')

  const token = localStorage.getItem('token')
  const [reportPurchase, setReportPurchase] = useState([])
  const [pagination, setPagination] = useState({})
  const [reportPurchaseLimit, setReportPurchaseLimit] = useState(10)

  const [totalPagesDownloadCount, setTotalPagesDownloadCount] = useState('')
  const [progressCount, setProgressCount] = useState(0)
  const [downloadMessageCount, setDownloadMessageCount] = useState('Pronto')
  const [downloadingCount, setDownloadingCount] = useState(false)
  const [title, setTitle] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [severity, setSeverity] = useState('warning')
  const [viewElearning, setViewElearning] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  }

  const getReportPurchase = useCallback(async () => {
    setLoading(false)
    const sales = {
      subCompanyId: subCompanyId,
      createDateTime: createDateTime,
      movimentationOrderNumberOtherSystem: movimentationOrderNumberOtherSystem,
      endDateTime: endDateTime,
    }

    try {
      const response = await getSalesService(token, nameUrl, sales)
      if (response.data.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setReportPurchase(response.data)
        setPagination(response.data)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }, [subCompanyId, createDateTime, endDateTime, movimentationOrderNumberOtherSystem])

  const getReportPurchasePage = useCallback(async (offset, order) => {
    try {
      const response = await getSalesService(token, nameUrl, reportPurchaseLimit, offset, order)
      setReportPurchase(response.data.result)
      setPagination(response.data)
    } catch (err) {
      console.log(err)
    }
  }, [])

  //excel
  const downloadDataCount = async () => {
    setDownloadMessageCount('Inicializando')
    setDownloadingCount(true)
    const sales = {
      subCompanyId: subCompanyId,
      createDateTime: createDateTime,
      limit: RESULTS_PER_PAGE,
      offset: 1,
      endDateTime: endDateTime,
    }
    await getSalesServicePagination(token, nameUrl, sales).then(r => {
      setTotalPagesDownloadCount(r.data.totalpage + 1)
      finishDownloadCount(r.data.totalpage + 1)
      console.log(r)
    })
    //finishDownloadCount(reportPurchase.length);
  }

  const finishDownloadCount = async pages => {
    let result = []

    for (let i = 0; i <= pages; i++) {
      const sales = {
        subCompanyId: subCompanyId,
        createDateTime: createDateTime,
        limit: RESULTS_PER_PAGE,
        offset: i,
        endDateTime: endDateTime,
      }
      setDownloadMessageCount(`Baixando ${i} de ${pages}...`)
      setProgressCount((i / pages) * 100)
      const resp = await getSalesServicePagination(token, nameUrl, sales)
      result = result.concat(resp.data)
      console.log('resp ', resp)
    }
    setDownloadMessageCount(`Seu arquivo está pronto.`)
    setDownloadingCount(false)
    const relatorioFinalContagem = []
    console.log('result ', result)
    result.map(item => {
      relatorioFinalContagem.push({
        'Número da venda': item.numberSale,
        'Montante total': item.totalAmount,
        Nome: item.productName,
        Preço: item.productPrice,

        'Data da emissão': new Date(item.emissionDate).toLocaleString(),
      })
    })
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinalContagem)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório de venda')
    XLSX.writeFile(workbook, `Relatório de venda.xlsx`)
  }
  // fim excel

  useEffect(() => {}, [])

  const handleClose = () => {
    setAlerta(false)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={downloadingCount}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessageCount}</DialogTitle>
            <LinearProgressWithLabel value={progressCount} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <ViewElearning
          pageName={'ReportPurchase'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Relatório de vendas</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }}>
            <HelpIcon onClick={() => setViewElearning(true)} />
          </IconButton>
        </Box>
        <Box
          sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
            <TextField
              value={movimentationOrderNumberOtherSystem}
              onChange={values => setMovimentationOrderNumberOtherSystem(values.target.value)}
              type="text"
              variant="outlined"
              label="Número da Ordem (opcional)"
              sx={{ width: '100%' }}
            />
          </Box>
          <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
            <InputLabel id="demo-simple-select-label">Filial</InputLabel>
            <Select
              label="Selecione a filial"
              value={subCompanyId}
              onChange={values => {
                setSubCompanyId(values.target.value)
              }}
              sx={{ width: '100%' }}
            >
              {subCompany.map((item, index) => {
                return (
                  <MenuItem value={item.subCompanyId} key={index}>
                    {item.name}
                  </MenuItem>
                )
              })}
            </Select>
          </Box>
        </Box>
        <Box
          sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
            <TextField
              label="Início"
              variant="outlined"
              value={createDateTime}
              onChange={values => {
                setCreateDateTime(values.target.value)
              }}
              sx={{ width: '100%' }}
              type="date"
            />
          </Box>
          <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
            <TextField
              label="Final"
              variant="outlined"
              value={endDateTime}
              onChange={values => {
                setEndDateTime(values.target.value)
              }}
              sx={{ width: '100%' }}
              type="date"
            />
          </Box>
        </Box>
        <Button
          variant="contained"
          onClick={getReportPurchase}
          disabled={!subCompanyId || subCompanyId === ''}
        >
          Pesquisar
        </Button>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            mb: 2,
          }}
        >
          <Typography variant="h6">Resultados</Typography>
          {reportPurchase.length > 0 && (
            <Button onClick={downloadDataCount} variant="contained" endIcon={<AiFillFileExcel />}>
              Excel
            </Button>
          )}
        </Box>
        {reportPurchase.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Número da Venda</StyledTableCell>
                  <StyledTableCell align="center">Montante Total</StyledTableCell>
                  <StyledTableCell align="center">Nome</StyledTableCell>
                  <StyledTableCell align="center">Preço</StyledTableCell>
                  <StyledTableCell align="center">Data da Emissão</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportPurchase
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">{row.numberSale}</StyledTableCell>
                      <StyledTableCell align="center">{row.totalAmount}</StyledTableCell>
                      <StyledTableCell align="center">{row.productName}</StyledTableCell>
                      <StyledTableCell align="center">{row.productPrice}</StyledTableCell>
                      <StyledTableCell align="center">
                        {new Date(row.emissionDate).toLocaleString()}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={reportPurchase.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ReportPurchase
