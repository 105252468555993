import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Select, InputLabel, MenuItem } from '@mui/material'

const GraficStores = ({ stores }) => {
  const [selectValue, setSelectValue] = useState('Todos')
  const [data, setData] = useState([])
  const [isShowTop10, setIsShowTop10] = useState(false)

  useEffect(() => {
    if (!stores || stores.length === 0) {
      return
    }
    if (stores.length > 0 && selectValue === '') {
      setSelectValue('Todos')
    }
  }, [stores, selectValue])

  useEffect(() => {
    handleFilter()
  }, [selectValue])

  const handleFilter = () => {
    let filter

    if (selectValue === 'Todos') {
      filter = stores.slice(0, 10)
      setIsShowTop10(true)
    } else {
      filter = stores.filter(item => item.route === selectValue)
      if (filter.length > 10) {
        filter = filter.slice(0, 10)
      }
      setIsShowTop10(false)
    }

    setData(filter)
  }

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: isShowTop10 ? '80%' : '50%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: 0,

      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },
    stroke: {
      show: true,
      width: 4,
      colors: ['transparent'],
    },
    xaxis: {
      categories: isShowTop10
        ? stores.slice(0, 10).map(item => item.name)
        : data.map(item => item.name),
    },
    yaxis: {},
    fill: {
      opacity: 1,
    },
    tooltip: {
      shared: true,
      intersect: false,

      y: {
        formatter: function (val) {
          return val
        },
      },
    },
  }

  const series = [
    {
      name: 'Lojas ativas',
      data: isShowTop10
        ? stores.slice(0, 10).map(item => item.visity)
        : data.map(item => item.visity),
    },
    {
      name: 'Lojas Inativas',
      data: isShowTop10
        ? stores.slice(0, 10).map(item => item.visityNot)
        : data.map(item => item.visityNot),
    },
  ]

  const uniqueRoutes = [...new Set(stores.map(item => item.route))]

  return (
    <div>
      <div>
        <InputLabel id="demo-simple-select-label">Selecione o roteiro</InputLabel>
        <Select
          size="small"
          sx={{ width: '50%' }}
          label="Selecione o roteiro"
          value={selectValue}
          onChange={e => setSelectValue(e.target.value)}
        >
          <MenuItem value="Todos">Todos</MenuItem>
          {uniqueRoutes.map((name, index) => (
            <MenuItem value={name} key={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div id="grafico">
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  )
}

export default GraficStores
