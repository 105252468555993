import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Select, InputLabel, MenuItem, Box } from '@mui/material'

const GraficRevenueAndProductMixGrowth = ({ revenueAndProductMixGrowth }) => {
  const [selectValue, setSelectValue] = useState('')
  const [selectValueTwo, setSelectValueTwo] = useState('')
  const [data, setData] = useState([])
  const [isShowTop10, setIsShowTop10] = useState(false)

  useEffect(() => {
    if (!revenueAndProductMixGrowth || revenueAndProductMixGrowth.length === 0) {
      return
    }
    if (revenueAndProductMixGrowth.length > 0 && selectValue === '' && selectValueTwo === '') {
      setSelectValue('1')
      setSelectValueTwo('2')
    }
  }, [revenueAndProductMixGrowth])

  useEffect(() => {
    if (selectValue !== '') {
      handleFilter()
    }

    if (selectValueTwo !== '') {
      handleFilterTwo()
    }
  }, [selectValue, selectValueTwo])

  const handleFilter = () => {
    setSelectValueTwo('')
    let filter

    if (selectValue === '1') {
      filter = revenueAndProductMixGrowth.sort((a, b) => b.price - a.price).slice(0, 10)
      setIsShowTop10(true)
    } else {
      const selectedItems = revenueAndProductMixGrowth.filter(item => item.name3 === selectValue)

      filter = selectedItems.slice(0, 10).map(item => ({ name: item.name, price: item.price || 0 }))
      setIsShowTop10(false)
    }
    setData(filter)
  }

  const handleFilterTwo = () => {
    setSelectValue('')
    let filter

    if (selectValueTwo === '2') {
      filter = revenueAndProductMixGrowth.slice(0, 10)
      setIsShowTop10(true)
    } else {
      const selectedItems = revenueAndProductMixGrowth.filter(item => item.route === selectValueTwo)

      filter = selectedItems.slice(0, 10).map(item => ({ name: item.name, price: item.price || 0 }))

      console.log(selectedItems, 'selectedItems')
      setIsShowTop10(false)
    }

    setData(filter)
  }

  const handleSelectChange = (value, type) => {
    if (type === 'loja') {
      setSelectValue(value)
      setSelectValueTwo('')
    } else if (type === 'regiao') {
      setSelectValueTwo(value)
      setSelectValue('')
    }
  }

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: isShowTop10 ? '80%' : '50%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(val)
      },
      offsetX: 0,
      offsetY: 0,

      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },
    xaxis: {
      categories: isShowTop10
        ? revenueAndProductMixGrowth.slice(0, 10).map(item => item.name)
        : data.map(item => item.name),
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(val)
        },
        style: {
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (val) {
          return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(val)
        },
      },
    },
  }

  const series = [
    {
      name: 'Valor',
      data: isShowTop10
        ? revenueAndProductMixGrowth.slice(0, 10).map(item => item.price)
        : data.map(item => item.price),
    },
  ]

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        gap={2}
        my={2}
      >
        <Box width="100%">
          <InputLabel id="demo-simple-select-label">Selecione a loja</InputLabel>
          <Select
            size="small"
            sx={{ width: '100%' }}
            label="Escolher a loja"
            value={selectValue}
            onChange={e => handleSelectChange(e.target.value, 'loja')}
          >
            <MenuItem value="1">Todos</MenuItem>
            {[...new Set(revenueAndProductMixGrowth.map(item => item.name3))].map((name, index) => (
              <MenuItem value={name} key={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box width="100%">
          <InputLabel id="demo-simple-select-label">Selecione a região</InputLabel>
          <Select
            size="small"
            sx={{ width: '100%' }}
            label="Escolher a região"
            value={selectValueTwo}
            onChange={e => handleSelectChange(e.target.value, 'regiao')}
          >
            <MenuItem value="2">Todos</MenuItem>
            {[...new Set(revenueAndProductMixGrowth.map(item => item.route))].map((name, index) => (
              <MenuItem value={name} key={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <div id="combined-chart">
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
    </div>
  )
}

export default GraficRevenueAndProductMixGrowth
