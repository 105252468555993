import React, { createRef, useEffect, useRef, useState } from 'react'
import CanvasDraw from 'react-canvas-draw'

import classNames from 'classnames'
import { Box, Button } from '@mui/material'

const defaultProps = {
  loadTimeOffset: 5,
  lazyRadius: 0,
  brushRadius: 2,
  catenaryColor: '#0a0302',
  gridColor: 'rgba(150,150,150,0.17)',
  hideGrid: true,
  canvasWidth: 400,
  canvasHeight: 200,
  disabled: false,
  imgSrc: '',
  saveData: '',
  immediateLoading: false,
  hideInterface: false,
}

// const colors = [
//   '#B80000',
//   '#DB3E00',
//   '#FCCB00',
//   '#008B02',
//   '#006B76',
//   '#1273DE',
//   '#004DCF',
//   '#5300EB',
//   '#000000',
//   '#EB9694',
//   '#FAD0C3',
//   '#FEF3BD',
//   '#C1E1C5',
//   '#BEDADC',
//   '#C4DEF6',
//   '#BED3F3',
//   '#D4C4FB',
//   '#CCCCCC',
// ]

// const width = `${Math.ceil(colors.length / 2) * 32}px`

const Canva = ({ assing, setAssing }) => {
  const canvasRef = createRef(null)
  const [brushColor, setBrushColor] = useState('#000000')
  const [showColor, setShowColor] = useState(false)
  // const [saveData, setAssing] = useState('')

  const getImg = () => canvasRef.current.canvasContainer.children[1].toDataURL()

  const paletteRef = useClickAway(() => {
    setShowColor(false)
  })

  const handleClear = () => {
    canvasRef.current.clear()
    setAssing('')
  }

  const handleCanvasChange = () => {
    const saveData = getImg()
    setAssing(saveData)
  }

  const props = {
    ...defaultProps,
    className: classNames('canvas'),
    onChange: handleCanvasChange,
    ref: canvasRef,
    brushColor,
    catenaryColor: brushColor,
  }

  function useClickAway(callback) {
    const innerRef = useRef()
    const callbackRef = useRef()

    useEffect(() => {
      callbackRef.current = callback
    })

    useEffect(() => {
      document.addEventListener('mousedown', handleMouseDown)
      return () => document.removeEventListener('mousedown', handleMouseDown)

      function handleMouseDown(e) {
        if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) {
          callbackRef.current(e)
        }
      }
    }, [])

    useEffect(() => {
      document.addEventListener('click', handleClick)
      return () => document.removeEventListener('click', handleClick)

      function handleClick(e) {
        if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) {
          callbackRef.current(e)
        }
      }
    }, [])

    return innerRef
  }

  return (
    <Box>
      <CanvasDraw {...props} style={{ width: '100%', height: 200, border: '1px solid black' }} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Box ref={paletteRef}>
          {showColor && (
            <CanvasDraw
              ref={canvasDraw => (this.modify = canvasDraw)}
              brushColor={'#FCA5A5'}
              brushRadius={50}
              hideGrid={true}
            />
          )}
        </Box>
        {/* <button
          type="button"
          className="undo"
          onClick={() => {
            canvasRef.current.undo()
          }}
        >
          <span role="img" aria-label="">
            ↩️
          </span>{' '}
          undo
        </button> */}
        <Button type="button" variant="contained" onClick={handleClear} sx={{ mt: 1 }}>
          Apagar
        </Button>
      </Box>
    </Box>
  )
}

export default Canva
