import axios from 'axios'

//get paginação
export const getLoteReportService = async (token, nameUrl, data) => {
  let orderToString
  if (data.order) {
    orderToString = 'asc'
  } else {
    orderToString = 'desc'
  }
  let location = false
  if (data.location === true) {
    location = true
  }
  let idPartener = ''
  if (typeof data.idPartener === 'undefined') {
    idPartener = ''
  }
  const response = await axios.get(
    `/${nameUrl}/api/Report/GetDateValidationAndLote?SubCompanyId=${data.SubCompanyId}&QuantityDay=${data.QuantityDay}&idPartener=${idPartener}&location=${location}&limit=${data.limit}&offset=${data.offset}&Order=${orderToString}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  )

  return response
}
