import HelpIcon from '@mui/icons-material/Help'
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
  Grid,
  tableCellClasses,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import { Context } from '../../../context/AuthContext'
import { handleMovimentationStockByProduct } from '../../../services/reportServices'

//icones react-icons
import { AiOutlineFileExcel } from 'react-icons/ai'
import '../../../assets/global.css'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import {
  getProductAssociationService,
  searchProductService,
} from '../../../services/productService'
import { useForm } from 'react-hook-form'

const ReportMovimentationStockByProduct = () => {
  const token = localStorage.getItem('token')
  const [subCompanyId, setSubCompanyId] = useState('')
  const { subCompany, nameUrl } = useContext(Context)
  const [startDate, setStartDate] = useState('2020-01-01')
  const [finishDate, setFinishDate] = useState(new Date().toISOString().substring(0, 10))
  const [loading, setLoading] = useState(false)
  const [dissassociationData, setDissassociationData] = useState([])
  const [table, setTable] = useState(false)

  const [progressCount, setProgressCount] = useState(0)
  const [downloadMessageCount, setDownloadMessageCount] = useState('Pronto')
  const [downloadingCount, setDownloadingCount] = useState(false)
  const [totalPagesDownload, setTotalPagesDownload] = useState()
  const [totalData, setTotalData] = useState()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const vertical = 'top'
  const horizontal = 'center'
  const [alerta, setAlerta] = useState(false)
  const [title, setTitle] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [viewElearning, setViewElearning] = useState(false)
  const [filterDeposit, setFilterDeposit] = useState('')
  const [filterLote, setFilterLote] = useState('')
  const [filterProduct, setFilterProduct] = useState('')
  const [backupData, setBackupData] = useState([])
  const [notSearch, setNotSearch] = useState(false)
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)
  const [listProducts, setListProducts] = useState()
  const [productSelected, setProductSelected] = useState()
  const [produtos, setProdutos] = useState([])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          textalign: 'center',
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyConent: 'center',
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  }

  const handleGetDissassociation = async e => {
    e.preventDefault()
    setLoading(true)
    setTable(false)
    const data = {
      subCompanyId: subCompanyId,
      startDate: startDate,
      finishDate: finishDate,
      productId: productSelected,
    }
    try {
      const response = await handleMovimentationStockByProduct(token, nameUrl, data)
      if (!response || response.data.length === 0) {
        setTitle('Não existem dados para esse período.')
        setAlerta(true)
        setSeverity('error')
        setDissassociationData([])
        setBackupData([])
      } else {
        console.log(response.data)

        setDissassociationData(response.data)
        setBackupData(response.data)
        setTotalPagesDownload(response.data.totalpage + 1)
        setTotalData(response.data.total + 1)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const downloadDataCount = async () => {
    setDownloadMessageCount('Inicializando')
    setDownloadingCount(true)
    finishDownloadCount()
  }

  const finishDownloadCount = async () => {
    let result = []
    for (let i = 0; i <= 50; i++) {
      setTimeout(() => {
        setDownloadMessageCount(`Baixando ${i} de ${page + 1}...`)
        setProgressCount((i / 100) * 100)
      }, 1000)
    }
    const data = {
      subCompanyId: subCompanyId,
      startDate: startDate,
      finishDate: finishDate,
      productId: productSelected,
    }
    const resp = await handleMovimentationStockByProduct(token, nameUrl, data)
    result = resp.data
    for (let i = 50; i <= 100; i++) {
      setTimeout(() => {
        setDownloadMessageCount(`Baixando ${i} de ${page + 1}...`)
        setProgressCount((i / 100) * 100)
      }, 1000)
    }
    setDownloadMessageCount(`Seu arquivo está pronto.`)
    setDownloadingCount(false)
    const relatorioFinal = []

    result.map(item => {
      relatorioFinal.push({
        'Data criação': new Date(item.createDate).toLocaleString(),
        Produto: item.productName,
        Quantidade: item.quantity,
        'Número Palete': item.numberPallet,
        Locação: item.locationName,
        Lote: item.lote,
        Depósito: item.depositName,
        Filial: item.subCompanyName,
        Movimentação: item.typeMovimentations,
        'Ordem Bihands': item.movimentationOrderNumberBihands,
        'Ordem Outro Sistema': item.movimentationOrderNumberOtherSystem,
      })
    })

    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(relatorioFinal)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Estoque por produto')
    XLSX.writeFile(workbook, `Estoque por produto.xlsx`)
    setProgressCount(0)
  }

  const handleClose = () => {
    setAlerta(false)
  }

  const filteredLote = () => {
    const filtered = backupData.filter(row =>
      row.lote.toLowerCase().includes(filterLote.toLowerCase())
    )

    setDissassociationData(filtered)
  }

  useEffect(() => {
    filteredLote()
  }, [filterLote])

  const filteredDeposit = () => {
    const filtered = backupData.filter(row =>
      row.depositName.toLowerCase().includes(filterDeposit.toLowerCase())
    )

    setDissassociationData(filtered)
  }

  useEffect(() => {
    filteredDeposit()
  }, [filterDeposit])

  const filteredProduct = () => {
    const filtered = backupData.filter(row =>
      row.productName.toLowerCase().includes(filterProduct.toLowerCase())
    )

    setDissassociationData(filtered)
  }

  useEffect(() => {
    filteredProduct()
  }, [filterProduct])

  const handleClearFilter = () => {
    setFilterDeposit('')
    setFilterProduct('')
    setFilterLote('')
    setDissassociationData(backupData)
  }

  useEffect(() => {
    if (search === '') {
      const checagem = setTimeout(() => {
        setProdutos([{ label: '', value: '' }])
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearching(true)
      const checagem = setTimeout(() => {
        const dados = {
          companyId: subCompany[0].companyId,
          SearchWord: search,
          limit: 10,
          offset: 0,
        }
        searchProductService(token, nameUrl, dados).then(response => {
          const prodsFinal = []
          response.data.result.map(produto => {
            const obj = produto
            obj.value = produto.productId
            obj.label = `${produto.name}`
            prodsFinal.push(obj)
          })
          setProdutos(prodsFinal)
          setSearching(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [search])

  const handleGetProducts = async id => {
    setLoading(true)
    setSubCompanyId(id)
    try {
      const response = await getProductAssociationService(token, nameUrl, 100, 0, 'desc', id)
      if (response.data.result.length === 0) {
        setTitle('Não existem dados para essa consulta.')
        setAlerta(true)
        setSeverity('error')
      } else {
        setListProducts(response.data.result)
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={alerta}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {title}
          </Alert>
        </Snackbar>
        <Dialog
          open={downloadingCount}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            sx={{
              height: '10rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyConent: 'center',
            }}
            width={{ xs: '20rem', sm: '20rem', md: '30rem', lg: '30rem', xl: '30rem' }}
          >
            <DialogTitle>{downloadMessageCount}</DialogTitle>
            <LinearProgressWithLabel value={progressCount} sx={{ width: '90%' }} />
          </Box>
        </Dialog>
        <ViewElearning
          pageName={'ReportDissassociation'}
          open={viewElearning}
          setOpen={setViewElearning}
        />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Relatório de movimentação de estoque por produto</Typography>
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box component="form" onSubmit={handleGetDissassociation}>
          <Box
            sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Filial</InputLabel>
              <Select
                label="Selecione a filial"
                onChange={e => handleGetProducts(e.target.value)}
                value={subCompanyId}
                name="companySelect"
                sx={{ width: '100%' }}
              >
                {subCompany &&
                  subCompany.map((item, index) => {
                    return (
                      <MenuItem value={item.subCompanyId} key={index}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
              </Select>
            </Box>
            {listProducts && (
              <Box
                width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }}
                sx={{ mt: 2 }}
              >
                <InputLabel id="demo-simple-select-label">Produto</InputLabel>

                <Autocomplete
                  disablePortal
                  id="lista-produtos"
                  options={produtos}
                  autoHighlight
                  autoSelect
                  //sx={{ width: 300 }}
                  loading={searching}
                  loadingText="Procurando..."
                  noOptionsText="Nenhum produto"
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.productId}>
                        {option.label}
                      </li>
                    )
                  }}
                  renderInput={params => <TextField {...params} label="Procurar produto" />}
                  onInputChange={(e, v) => {
                    setSearch(v)
                  }}
                  //onChange={(e, v) => setProductId(v)}
                  onChange={(e, v) => {
                    if (v === null) {
                      setProductSelected()
                    } else {
                      setProductSelected(v.productId)
                    }
                  }}
                />
              </Box>
            )}
          </Box>

          <Box
            sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Data de criação</InputLabel>
              <TextField
                variant="outlined"
                value={startDate}
                onChange={values => {
                  setStartDate(values.target.value)
                }}
                sx={{ width: '100%' }}
                name="createDate"
                type="date"
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">Data final</InputLabel>
              <TextField
                variant="outlined"
                value={finishDate}
                onChange={values => setFinishDate(values.target.value)}
                sx={{ width: '100%' }}
                name="endDate"
                type="date"
              />
            </Box>
          </Box>
          <Button variant="contained" type="submit">
            Pesquisar
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
            mb: 2,
          }}
        >
          <Typography variant="h6">Resultados</Typography>
          {dissassociationData.length > 0 && (
            <Button
              variant="contained"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: ' space-between',
                fontSize: '.9em',
                width: '6.5rem',
              }}
              onClick={downloadDataCount}
            >
              Excel <AiOutlineFileExcel size="2em" />
            </Button>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3.33}>
            {' '}
            <TextField
              variant="outlined"
              label={!notSearch ? 'Filtrar Produto' : 'Produto Não encontrado'}
              value={filterProduct}
              onChange={values => setFilterProduct(values.target.value)}
              sx={{ width: '100%' }}
              error={notSearch}
              type="text"
            />
          </Grid>
          <Grid item xs={12} md={3.33}>
            {' '}
            <TextField
              variant="outlined"
              label={!notSearch ? 'Filtrar Depósito' : 'Depósito não encontrado'}
              value={filterDeposit}
              onChange={values => setFilterDeposit(values.target.value)}
              sx={{ width: '100%' }}
              error={notSearch}
              type="text"
            />
          </Grid>
          <Grid item xs={12} md={3.33}>
            {' '}
            <TextField
              variant="outlined"
              label={!notSearch ? 'Filtrar Lote' : 'Lote não encontrado'}
              value={filterLote}
              onChange={values => setFilterLote(values.target.value)}
              sx={{ width: '100%' }}
              error={notSearch}
              type="text"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              variant="contained"
              onClick={() => handleClearFilter()}
              sx={{ mt: 2, width: '100%' }}
            >
              Limpar filtros
            </Button>
          </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ mt: '2rem' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Data criação</StyledTableCell>
                <StyledTableCell align="left">Produto</StyledTableCell>
                <StyledTableCell align="left">Quantidade</StyledTableCell>
                <StyledTableCell align="left">Número Palete</StyledTableCell>
                <StyledTableCell align="left">Locação</StyledTableCell>
                <StyledTableCell align="left">Lote</StyledTableCell>
                <StyledTableCell align="left">Depósito</StyledTableCell>
                <StyledTableCell align="left">Filial</StyledTableCell>
                <StyledTableCell align="left">Movimentação</StyledTableCell>
                <StyledTableCell align="left">Serial</StyledTableCell>
                <StyledTableCell align="left">Ordem Bihands</StyledTableCell>
                <StyledTableCell align="left">Ordem Outro Sistema</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dissassociationData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left">
                      {new Date(row.createDate).toLocaleString()}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.productName}</StyledTableCell>
                    <StyledTableCell align="left">{row.quantity}</StyledTableCell>
                    <StyledTableCell align="left">{row.numberPallet}</StyledTableCell>
                    <StyledTableCell align="left">{row.locationName}</StyledTableCell>
                    <StyledTableCell align="left">{row.lote}</StyledTableCell>
                    <StyledTableCell align="left">{row.depositName}</StyledTableCell>
                    <StyledTableCell align="left">{row.subCompanyName}</StyledTableCell>
                    <StyledTableCell align="left">{row.typeMovimentations}</StyledTableCell>
                    <StyledTableCell align="left">{row.serial}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.movimentationOrderNumberBihands}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.movimentationOrderNumberOtherSystem}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={dissassociationData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default ReportMovimentationStockByProduct
