import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import { Context } from '../../../context/AuthContext'
import { searchProductService, updatePieceListService } from '../../../services/productService'
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material'
import { styled } from '@mui/system'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import HelpIcon from '@mui/icons-material/Help'
import { colorTableDefault } from '../../TemplateDefault'
import ViewElearning from '../../ViewElearning'
import PermissionComponent from '../../../permissions'

const QuantityBox = () => {
  const { userRoles, nameUrl, subCompany } = useContext(Context)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const vertical = 'top'
  const horizontal = 'center'
  const [hasSave, setHasSave] = useState(false)
  const [saveText, setSaveText] = useState('')
  const [saveStatus, setSaveStatus] = useState()

  const token = localStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)
  const [statusServ, setStatusServ] = useState('Pronto')

  const [produtos, setProdutos] = useState([])
  const [produtosList, setProdutosList] = useState([])
  const [singleProd, setSingleProd] = useState()
  const [viewElearning, setViewElearning] = useState(false)

  const [updateList, setUpdateList] = useState([])

  const [saving, setSaving] = useState(false)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: colorTableDefault,
      color: 'white',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: '8px',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      //backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const addToList = () => {
    const repetido = updateList.find(x => x.productId === singleProd.productId)
    if (repetido) {
      return
    } else {
      const prod = singleProd
      prod.piece = parseInt(singleProd.piece)
      setUpdateList(curr => [...curr, singleProd])
    }
  }

  const changeProd = id => {
    const prod = produtosList.find(x => x.productId === id)
    const finalProd = prod
    console.log(finalProd)
    setSingleProd(finalProd)
  }

  const saveList = async () => {
    setStatusServ('Salvando...')
    setSaving(true)
    const keysToKeep = ['productId', 'piece', 'typePiece', 'companyId']
    const redux = array =>
      array.map(o =>
        keysToKeep.reduce((acc, curr) => {
          acc[curr] = o[curr]
          return acc
        }, {})
      )
    const finalList = redux(updateList)
    try {
      const request = await updatePieceListService(token, nameUrl, finalList)
      setStatusServ(`${finalList.length} produtos salvos. Processando produtos...`)
      setSaving(false)
      setUpdateList([])
      setSaveStatus('success')
      setSaveText('Salvo com sucesso')
      setHasSave(true)
      setSingleProd()
    } catch (e) {
      console.log(e)
      setSaveStatus('error')
      setSaveText('Erro no sistema, tente novamente mais tarde')
      setHasSave(true)
    }
  }

  useEffect(() => {
    setLoading(true)
  }, [])

  useEffect(() => {
    if (search === '') {
      const checagem = setTimeout(() => {
        setProdutos([{ label: '', value: '' }])
      }, 1000)
      return () => clearTimeout(checagem)
    } else {
      setSearching(true)
      const checagem = setTimeout(() => {
        const dados = {
          companyId: subCompany[0].companyId,
          SearchWord: search,
          limit: 10,
          offset: 0,
        }
        searchProductService(token, nameUrl, dados).then(response => {
          setProdutosList(response.data.result)
          const prodsFinal = []
          response.data.result.map(produto => {
            const obj = produto
            obj.value = produto.productId
            obj.label = `${produto.name}`
            prodsFinal.push(obj)
          })
          setProdutos(prodsFinal)
          setSearching(false)
        })
      }, 1000)
      return () => clearTimeout(checagem)
    }
  }, [search])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleClose = () => {
    setHasSave(false)
  }

  return (
    <>
      <Box sx={{ mt: 7.5 }}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={hasSave}
          onClose={handleClose}
          //message="Erro no login, verifique seu e-mail e senha"
          key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={saveStatus} sx={{ width: '100%' }}>
            {saveText}
          </Alert>
        </Snackbar>
        <ViewElearning pageName={'QuantityBox'} open={viewElearning} setOpen={setViewElearning} />
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Fator de Conversão</Typography>{' '}
          <IconButton sx={{ justifySelf: 'flex-end' }} onClick={() => setViewElearning(true)}>
            <HelpIcon />
          </IconButton>
        </Box>
        <Box
          sx={{ width: '100%', justifyContent: 'space-evenly' }}
          display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
        >
          <Box sx={{ width: '100%' }}>
            <FormControl sx={{ width: '100%' }}>
              {/* <Autocomplete
                  disablePortal
                  id="lista-produtos"
                  options={produtos}
                  loading={loadingOptions}
                  //sx={{ width: 300 }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.productId}>
                        {option.label}
                      </li>
                    );
                  }}
                  renderInput={params => <TextField {...params} label="Produto" />}
                  onChange={(e,v) => {
                    changeProd(v.value)
                  }}
                /> */}
              <Autocomplete
                disablePortal
                id="lista-produtos"
                options={produtos}
                //sx={{ width: 300 }}
                loading={searching}
                loadingText="Procurando..."
                noOptionsText="Nenhum produto encontrado"
                isOptionEqualToValue={(option, value) => option.productId === value.value}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.productId}>
                      {option.label}
                    </li>
                  )
                }}
                renderInput={params => <TextField {...params} label="Procurar produto" />}
                onInputChange={(e, v) => {
                  setSearch(v)
                }}
                onChange={(e, v) => changeProd(v.value)}
              />
            </FormControl>
          </Box>
        </Box>
        {singleProd && (
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                value={statusServ}
                id="standard-basic"
                label="Status"
                variant="standard"
                type="text"
                sx={{ width: '100%' }}
                disabled
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={singleProd.name}
                id="standard-basic"
                label="Nome Produto"
                variant="standard"
                type="text"
                disabled
              />
            </Box>
          </Box>
        )}
        {singleProd && (
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={singleProd.referencia}
                id="standard-basic"
                label="Referência"
                variant="standard"
                type="text"
                disabled
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={singleProd.sku}
                id="standard-basic"
                label="SKU"
                variant="standard"
                type="text"
                disabled
              />
            </Box>
          </Box>
        )}
        {singleProd && (
          <Box
            sx={{ width: '100%', justifyContent: 'space-evenly' }}
            display={{ xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' }}
          >
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: '100%' }}
                value={singleProd.piece}
                id="standard-basic"
                label="Quantidade por caixa"
                variant="standard"
                type="text"
                onChange={e =>
                  setSingleProd(curr => ({
                    ...curr,
                    piece: e.target.value,
                  }))
                }
              />
            </Box>
            <Box width={{ xs: '100%', sm: '100%', md: '45%', lg: '45%', xl: '45%' }} sx={{ mt: 3 }}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                <Select
                  value={singleProd.typePiece}
                  onChange={e =>
                    setSingleProd(current => ({
                      ...current,
                      typePiece: parseInt(e.target.value),
                    }))
                  }
                >
                  <MenuItem value="0">Peça</MenuItem>
                  <MenuItem value="1">Caixa</MenuItem>
                  <MenuItem value="2">KG</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
        {singleProd && (
          <Box
            sx={{
              width: '100%',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              mt: 4,
            }}
          >
            <Box
              width={{ xs: '80%', sm: '80%', md: '40%', lg: '40%', xl: '40%' }}
              sx={{ mt: '1rem', alignItems: 'center' }}
            >
              <Button variant="contained" sx={{ width: '100%' }} type="button" onClick={addToList}>
                Adicionar a lista
              </Button>
            </Box>
          </Box>
        )}
        {updateList.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: '2rem' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={saveList}
                      disabled={saving}
                      className="buttonDocs"
                      sx={{ justifyContent: 'center', fontSize: '1.2em' }}
                    >
                      Salvar
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell align="center">Nome Produto</StyledTableCell>
                  <StyledTableCell align="center">Referência</StyledTableCell>
                  <StyledTableCell align="center">SKU</StyledTableCell>
                  <StyledTableCell align="center">Qtd/Caixa</StyledTableCell>
                  <StyledTableCell align="center">Tipo</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {updateList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row" sx={{ cursor: 'pointer' }}>
                        <PermissionComponent role="QuantityBoxDELETE">
                          <Tooltip title="Apagar" arrow>
                            <IconButton
                              onClick={() =>
                                setUpdateList(curr => curr.filter((v, i) => i !== index))
                              }
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Tooltip>
                        </PermissionComponent>
                      </StyledTableCell>

                      <StyledTableCell align="center">{row.name}</StyledTableCell>
                      <StyledTableCell align="center">{row.referencia}</StyledTableCell>
                      <StyledTableCell align="center">{row.sku}</StyledTableCell>
                      <StyledTableCell align="center">{row.piece}</StyledTableCell>
                      <StyledTableCell align="center">{row.typePiece}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={updateList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={saving}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}

export default QuantityBox
